
import React, { useState, useEffect, useRef, useContext } from "react";
import { ClickAwayListener } from "@material-ui/core";
import Highlighter from "react-highlight-words";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { NavLink, useLocation } from "react-router-dom";
import "./KeywordSearch.css";
import { messageService } from "../../../Shared/Message";
import { useSelector } from "react-redux";
import ClearFilterContext from "../../../Store/ContextApi/ClearFilterContext";
import { Provider, History, Trigger } from 'react-history-search';
import "./KeySearch.css";
import SearchBar from "../SearchBar/SearchBar";



export default function KeywordSearch(props) {
  const { clearBool, setClearBool } = useContext(ClearFilterContext);
  const search = useLocation().search;
  const user_search_id = new URLSearchParams(search).get("user_search_id");
  const keywordText = new URLSearchParams(search).get("keyword");
  const menuOptions = [
    "All",
    "Variable Names",
    "Variable Descriptions",
    "Survey Questions",
    "GSS Tags",
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const userSearchesDetails = useSelector((state) => state.userSearchesReducer);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    props.menuClick(index);
  };
  const [showSearches, setShowSearches] = useState(false);
  const [inputVal, setInputValue] = useState("");
  const countRef = useRef(inputVal);
  countRef.current = inputVal;
  const [namesArr, setNamesArr] = useState([]);
  const [descArr, setDescArr] = useState([]);
  const [surveyQuesArr, setSurveyQuesArr] = useState([]);
  const [gssTagsArr, setGssTagsArr] = useState([]);

  const options = props.data;
  const subjectsData = props.subjectsData;
  useEffect(() => {
    props.searchKeyword(inputVal);
    if (inputVal && inputVal.length > 1) {
      searchAutoComp();
    }
    messageService.onMessage().subscribe((message) => {
      if (message.text == "clear") {
        setSelectedIndex(0);
        setInputValue("");
        setShowSearches(false);
      }
    });

    /* View Edit User Searches*/
    if (
      userSearchesDetails?.usersearches_data?.usersearches_data?.id &&
      user_search_id
    ) {
      setShowSearches(false);
    }
    if (keywordText) {
      setShowSearches(false);
    }
  }, [inputVal]);

  useEffect(() => {
    if (user_search_id) {
      let mock_data =
        userSearchesDetails?.usersearches_data?.usersearches_data?.criteria;
      setInputValue(mock_data?.keyword);
    }
  }, [user_search_id]);

  useEffect(() => {
    if (keywordText) {
      setInputValue(keywordText);
    }
  }, [keywordText]);
  useEffect(() => {
    if (clearBool) {
      setSelectedIndex(0);
      setShowSearches(false);
      setInputValue("");
    }
  }, [clearBool]);

  /* AUTOCOMPLETE LOGIC */
  const searchAutoComp = () => {
    setNamesArr([]);
    setDescArr([]);
    setSurveyQuesArr([]);
    setGssTagsArr([]);
    if (!inputVal) {
      setShowSearches(false);
    } else {
      options.filter((item) => {
        if (
          item.var_name &&
          item.var_name.toLowerCase().indexOf(inputVal.toLowerCase()) > -1
        ) {
          setNamesArr((state) => [...state, item]);
        }
        if (
          item.var_description &&
          item.var_description.toLowerCase().indexOf(inputVal.toLowerCase()) >
            -1
        ) {
          setDescArr((state) => [...state, item]);
        }
        if (
          item.question &&
          item.question.toLowerCase().indexOf(inputVal.toLowerCase()) > -1
        ) {
          setSurveyQuesArr((state) => [...state, item]);
        }
      });

      subjectsData.filter((item) => {
        if (
          item.description &&
          item.description.toLowerCase().indexOf(inputVal.toLowerCase()) > -1
        ) {
          setGssTagsArr((state) => [...state, item]);
        }
      });
      setShowSearches(true);
    }
  };

  /* On Click of View All from Autocomplete */
  const clickViewAll = (index) => {
    setShowSearches(false);
    setSelectedIndex(index);
    props.menuClick(index, true);
  };

  const handleClickAway = () => {
    setShowSearches(false);
  };
  const handleSearch = (value) => {
    setInputValue(value);
  };
  


  useEffect(()=>{
    var input = document.getElementById("keyword");
    var btn = document.getElementById("searchBtn");
    input?.addEventListener("keyup", function(event) {  
      if (event.keyCode === 13 ) {   
         event.preventDefault();     
         btn?.click();
     }
     });
  },[]);
  return (
    <>
 
      <div className="row gss-search-kcriteria">
        
        <div className="menu-label col-lg-4 col-md-12 col-sm-12"></div>
        <div className="col-12 text-left">
          <a
            aria-controls="simple-menu"
            className="username orange"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {menuOptions[selectedIndex]}
            <i className="fa fa-caret-down ml-1"></i>
          </a>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {menuOptions.map((option, index) => (
              <MenuItem
                key={option}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <div className="gss-keyword-search">
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="no-border form-group" >
          <Provider   value={{ 
            searchKeywords: true,
           }}>
    
          <History isHint isTabFill isRemoveHistory onClick={handleSearch}>
            <input
              value={inputVal?.replace(/^\s+/,"").split(/ +/).join(' ')}
              autoComplete="off"
              className=" form-control"
              maxLength={40}
              id="keyword"
              name="keyword"
              placeholder="Type Keyword"
              type="text"
              onChange={(event) => {
                if (event.target.value == "") {
                  setShowSearches(false);
                }
                setInputValue(event.target.value);
              }}
            />
            </History>
            
      </Provider>

            {showSearches && (
              <ul className="list-unstyled gss-autocomplete-dd">
                {(selectedIndex === 0 || selectedIndex === 1) && (
                  <React.Fragment>
                    <div className="keyword-count">
                      <div>VARIABLE NAMES</div>
                      <div className="variableNames">
                        {namesArr.length > (selectedIndex === 0 ? 5 : 15) ? (
                          <span>
                            Showing {selectedIndex === 0 ? 5 : 15} of{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {namesArr.length} matches
                      </div>
                    </div>
                    {namesArr
                      .slice(0, selectedIndex === 0 ? 5 : 15)
                      .map((item) => (
                        <li>
                          <NavLink
                            className="keyword-results d-inline-block"
                            to={"/variables/" + item.var_id + "/vshow"}
                          >
                            <i className="align-middle mr-3 keyword-y-text">
                              y
                            </i>
                            <div className="d-inline-block align-middle">
                              <div className="font-medium">
                                <Highlighter
                                  highlightClassName="highlightText"
                                  searchWords={[inputVal]}
                                  autoEscape={false}
                                  textToHighlight={item.var_name}
                                />
                              </div>
                              <div className="font-small">
                                <Highlighter
                                  highlightClassName="highlightText"
                                  searchWords={[inputVal]}
                                  autoEscape={false}
                                  textToHighlight={item.var_description}
                                />
                              </div>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    <li className="view-all">
                      {namesArr.length ? (
                        <a href="#" onClick={() => clickViewAll(1)}>
                          <strong>View All Variable Name Matches ›</strong>
                        </a>
                      ) : (
                        ""
                      )}
                    </li>
                  </React.Fragment>
                )}

                {(selectedIndex === 0 || selectedIndex === 2) && (
                  <React.Fragment>
                    <div className="keyword-count">
                      <div>VARIABLE DESCRIPTIONS</div>
                      <div>
                        {descArr.length > (selectedIndex === 0 ? 5 : 15) ? (
                          <span>
                            Showing {selectedIndex === 0 ? 5 : 15} of{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {descArr.length} matches
                      </div>
                    </div>
                    {descArr
                      .slice(0, selectedIndex === 0 ? 5 : 15)
                      .map((item) => (
                        <li>
                          <NavLink
                            className="keyword-results"
                            to={"/variables/" + item.var_id + "/vshow"}
                          >
                            <i className="fa fa-file-text-o align-middle mr-3"></i>
                            <div className="d-inline-block align-middle">
                              <div className="font-medium">
                                <Highlighter
                                  highlightClassName="highlightText"
                                  searchWords={[inputVal]}
                                  autoEscape={false}
                                  textToHighlight={item.var_description}
                                />
                              </div>
                              <div className="font-small">
                                <Highlighter
                                  highlightClassName="highlightText"
                                  searchWords={[inputVal]}
                                  autoEscape={false}
                                  textToHighlight={item.var_name}
                                />
                              </div>
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    <li className="view-all">
                      {descArr.length ? (
                        <a href="#" onClick={() => clickViewAll(2)}>
                          <strong>View All Description Matches ›</strong>
                        </a>
                      ) : (
                        ""
                      )}
                    </li>
                  </React.Fragment>
                )}

                {(selectedIndex === 0 || selectedIndex === 3) && (
                  <React.Fragment>
                    <div className="keyword-count">
                      <div>SURVEY QUESTIONS</div>
                      <div>{surveyQuesArr.length} matches</div>
                    </div>
                    {surveyQuesArr.length ? (
                      <li className="font-small">
                        Due to the length of the survey questions, results are
                        available on the next page.
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="view-all">
                      {surveyQuesArr.length ? (
                        <a href="#" onClick={() => clickViewAll(3)}>
                          <strong>View All Survey Question Matches ›</strong>
                        </a>
                      ) : (
                        ""
                      )}
                    </li>
                  </React.Fragment>
                )}

                {(selectedIndex === 0 || selectedIndex === 4) && (
                  <React.Fragment>
                    <div className="keyword-count">
                      <div>GSS Tags</div>
                      <div className="variableNames">
                        {gssTagsArr.length > (selectedIndex === 0 ? 5 : 15) ? (
                          <span>
                            Showing {selectedIndex === 0 ? 5 : 15} of{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {gssTagsArr.length} matches
                      </div>
                    </div>
                    {gssTagsArr
                      .slice(0, selectedIndex === 0 ? 5 : 15)
                      .map((item) => (
                        <li>
                          <div className="keyword-results d-inline-block">
                            <i className="align-middle mr-3 keyword-y-text invisible">
                              y
                            </i>
                            <div className="d-inline-block align-middle">
                              <div className="font-medium">
                                <Highlighter
                                  highlightClassName="highlightText"
                                  searchWords={[inputVal]}
                                  autoEscape={false}
                                  textToHighlight={item.description}
                                />
                              </div>
                              <div className="font-small">GSS Team</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    <li className="view-all">
                      {gssTagsArr.length ? (
                        <a href="#" onClick={() => clickViewAll(4)}>
                          <strong>View All GSS Tag Matches ›</strong>
                        </a>
                      ) : (
                        ""
                      )}
                    </li>
                  </React.Fragment>
                )}

                <li className="font-weight-bold">
                  <i className="fa fa-search margin-right-5"></i>
                  <a href="#" onClick={() => clickViewAll(0)}>
                    View all results for {"'" + inputVal + "'"}
                  </a>
                </li>
              </ul>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
}
