import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";


const useStyles = makeStyles((theme) => ({
  root: {
    width: 500
  },
  typography: {
    padding: theme.spacing(2)
  }
}));

export default function PositionedPopper() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const classes = useStyles();

  const handleClick =  (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
   // setPlacement(newPlacement);
  };

  return (
    <div className={classes.root}>
      <Popper id="del" open={open} anchorEl={anchorEl} >
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
          <div
            className="gss-popover gss-dismiss"
            data-original-title=""
            title=""
          >
            <div className="gss-dd-content" id="confirm-delete-project-88155">
              <div
                className="confirm-delete-project gss-dd"
                data-original-title=""
                title=""
              >
                <div className="dd-header">
                  <div className="pull-left">
                    Are You Sure You Want to Delete This Project?
                  </div>
                  <a className="pull-right fa fa-times" href="#"></a>
                </div>
                <div className="dd-body narrow">
                  <div className="fa-gss-norc-orange bold">Warning!</div>
                  <p>
                    If you delete this project, you will no longer have access
                    to it. Are you sure you want to remove this project?
                  </p>
                </div>
                <div className="gss-dd-footer">
                  <a className="btn btn-orange" href="/projects/88155/remove">
                    DELETE
                  </a>
                </div>
              </div>
            </div>
          </div>
          </Fade>
        )}
      </Popper>

      <i aria-describedby="del"  className="fa fa-trash-o gss-dd-target gss-dismiss trash_icon" onClick={handleClick}></i>
    </div>
  );
}
