import React from "react";
/* import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'; */
import { NavLink } from "react-router-dom";
import { Auth, button } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import "./MenuDropdown.css";

export default function MenuDropdown(props) {
  /* const [anchorEl, setAnchorEl] = React.useState(null); */
  const history = useHistory();
  /* const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }; */

  /* const clearSession = () =>{
    localStorage.clear();
    sessionStorage.clear();
    history.push("/");
    window.location.reload();
  } */

  const clickLogout = () => {
    Auth.signOut();
    // try {
    //   Auth.signOut()
    //     .then((data) => {
    //       //clearSession();
    //     })
    //     .catch((err) => {
    //       if (err === "The user is not authenticated" || err.code == "NotAuthorizedException" || err.code == "NetworkError") {
    //         localStorage.clear();
    //       }
    //       clearSession();
    //     }).finally(() => {
    // 			alert('error');
    // 		});
    // } catch (error) {
    // }

    // Collect confirmation code and new password, then
  };
  return (
    <div>
      <NavDropdown
        title={<span className="orange">{props.name}</span>}
        className="navbar-dropdown"
        id="navbarScrollingDropdown"
      >
        {/* <NavDropdown.Item as={NavLink} exact to="/user/project">
          <i className="fa fa-lg fa-th"></i>Old GSS
        </NavDropdown.Item> 
        <NavDropdown.Divider />*/}
        <NavDropdown.Item className="w-100" as={NavLink} exact to="/MyGSS">
          <i className="fa fa-lg fa-th"></i>My GSS
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item
          className="w-100"
          as={NavLink}
          exact
          to="/preferences"
        >
          <i className="fa fa-lg fa-cog"></i>Settings
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item className="w-100" as={button} onClick={clickLogout}>
          <i className="fa fa-lg fa-sign-out"></i>Logout
        </NavDropdown.Item>

        {localStorage.isAdmin == "true" && (
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item className="w-100" as={NavLink} exact to="/users">
              {/* <i className="fa fa-lg fa-users"></i> */}
              Users
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/analysisHistory"
            >
              {/* <i className="fa fa-lg fa-bar-chart"></i> */}
              Tabulations
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/extractHistory"
            >
              {/* <i className="fa fa-lg fa-share-square-o"></i> */}
              Extracts
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/bannerMessage"
            >
              {/* <i className="fa fa-lg fa-share-square-o"></i> */}
              Temporary Banner Message
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/popularTabs"
            >
              {/* <i className="fa fa-lg fa-share-square-o"></i> */}
              Popular Variables
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/NewRegisteredUser"
            >
              {/* <i className="fa fa-lg fa-share-square-o"></i> */}
              New Registered Users
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/DataloadTesting"
            >
              {/* <i className="fa fa-lg fa-share-square-o"></i> */}
              Dataload Testing
            </NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item
              className="w-100"
              as={NavLink}
              exact
              to="/TicketTracker"
            >
      
              Ticket Tracker
            </NavDropdown.Item> */}
          </>
        )}
      </NavDropdown>
    </div>
  );
}
