import React, { useEffect } from 'react';
/* import { makeStyles } from '@material-ui/core/styles'; */
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import arrow from '../../../Images/back_arrow.jpg'
import axios from 'axios';
import BallotInfo from '../BallotInfo/BallotInfo'
import { environment } from '../../../environment';
import { Tooltip } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import './ViewAllCartItems.css'
import { updateService } from '../../../Shared/UpdateCart';
/* const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },

}); */

export default function ViewAllCart() {
  /* const classes = useStyles(); */
  const [data, setData] = React.useState([]);
  useEffect(() => {
    if (localStorage.getItem("isSignedIn") == "true") {
      viewAll()
      updateService.sendAlert('varData');
    }

  }, []);

  const viewAll = () => {
    let reqBody = {
      "workspace_id": localStorage.defaultProjectNo ? parseInt(localStorage.defaultProjectNo) : '',
      "year_info": 1,
      "cartType": 'analysis'
    };
    axios.post(`${environment.getCartItem}`, reqBody)
      .then((resp) => {
        console.log(resp, resp.data.response.cart_items);
        setData(resp.data.response.cart_items)
        //  setData(resp.data.response.cart_items)

      })
      .catch((error) => {
        //props.history.push("/error");
        console.log(error);
      });
  }
  return (
    <div className="noscroll data-cart" id="ws-content" style={{"padding": "50px 40px"}}>
      <div className="gss-menu-bar back-link-bar" >
        <a className="no-decoration" href="/variables/vfilter">
          <img src={arrow} alt=""/>
          Back to previous page
        </a>
      </div>
      <div className="gss-large-title">
        <h2>Cart:&nbsp;
        {data.length} Variables
        </h2>
      </div>
      <TableContainer size="small" component={Paper}  className="gss_material_table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell >Name</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Years</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.orignlName}>
                <TableCell component="th" scope="row">
                  <Tooltip title="View variable detail">
                    <NavLink
                      className="tt-name"
                      to={"/variables/" + row.vID + "/vshow"}
                    >
                      {row.orignlName}
                    </NavLink>
                  </Tooltip>
                </TableCell>
                <TableCell >{row.label}</TableCell>
                <TableCell > <BallotInfo
                  years={row.yrBallots}
                  isVarDetailPage={false}
                ></BallotInfo></TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
