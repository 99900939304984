import { MenuItem, Select } from "@material-ui/core";

const QuestionDropDown = (props) => {
  const handleChange = (event) => {
    props.changeHandler(event.target.value, true);
  };
  return (
    <>
      <Select
        variant="outlined"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.dropDownValue}
        onChange={handleChange}
      >
        {props.dropDownData.map((val) => (
            <MenuItem key={val} value={val}>{val}</MenuItem>
        ))}
      </Select>
    </>
  );
};
export default QuestionDropDown;
