import React, { useEffect, useState } from 'react'
import { MenuItem, Select } from "@material-ui/core";
import axios from "axios";
/* import { environment } from "../../../environment"; */

const OrganizationDDL = (props) => {

    const [orgArr, setOrgArr] = useState([])

    const createSelectOptions = (values) => {
        return values.map((val) => ({ value: val, label: val }));
    }

    useEffect(() => {
        axios
            .get(`${process.env.PUBLIC_URL}/config/organisations_og.json`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "",
                }
            })
            .then((res) => {
                let orgDDl = res.data.org.map(d => d.name)
                setOrgArr(orgDDl)
                // const index = res.data.org.indexOf('Other');
                // if(index > -1){
                //     const rm_element = res.data.org.splice(index, 1);
                //     res.data.org.push(rm_element);                                        
                //     setOrgArr(res.data.org)
                // } else {                    

                // }

            })
    }, [])

    return (
        <>
            <Select
                {...props}
                /* value = {props.value != ''? (orgArr.includes(props.value) ? props.value: 'Other'): ''} */
                className="form-control customize-input"
                name="organization"
                placeholder="Nothing Selected"
                options={createSelectOptions(
                    orgArr
                )}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: "lightgrey",
                        primary: "#80bdff",
                    },
                })}
            >
                <MenuItem value={""}>Nothing Selected</MenuItem>
                {orgArr.map((name) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </>
    )
}

export default OrganizationDDL
