import VariableActionTypes from '../types/variable.types';
import { addItemsToTag, removeItemsFromTag, GetTagCategories } from '../utils/variable.utils';

const INITIAL_STATE = {
    variables: [],
    variableByCategory: []
};

const variableReducer = (state = INITIAL_STATE, action) => {
    let variables = [];
    switch (action.type) {
        case VariableActionTypes.VARIABLE_REQUEST:
            return { loading: true, variables: [], variableByCategory: [] }
        case VariableActionTypes.VARIABLE_FAIL:
            return { loading: false, error: action.payload }
        case VariableActionTypes.VARIABLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                variables: action.payload,
                variableByCategory: GetTagCategories(action.payload)
            };
        case VariableActionTypes.TAG_REQUEST:
            return { ...state, tagLoading: true }
        case VariableActionTypes.TAG_FAIL:
            return { ...state, tagLoading: false, error: action.payload }
        case VariableActionTypes.CREATE_TAG_SUCCESS:
            return {
                ...state,
                tagLoading: false,
            };
        case VariableActionTypes.ADD_TO_TAG_REQUEST:
            return { ...state, tagLoading: true }
        case VariableActionTypes.ADD_TO_TAG_FAIL:
            return { ...state, tagLoading: false, error: action.payload }
        case VariableActionTypes.ADD_TO_TAG_SUCCESS:
            return {
                ...state,
                tagLoading: false,
            };
        case VariableActionTypes.ADD_TO_TAG:
            variables = addItemsToTag(state.variables, action.payload);
            return {
                ...state,
                variables,
                variableByCategory: GetTagCategories([...variables])
            };
        case VariableActionTypes.REMOVE_FROM_MYGSS:
            variables = removeItemsFromTag(state.variables, action.payload);
            return {
                ...state,
                variables,
                variableByCategory: GetTagCategories([...variables])
            };
        case VariableActionTypes.RESET_VARIABLE: 
            return {
                ...state,
                variables: [],
                variableByCategory: []
            }    
        default:
            return state;
    }
};

export default variableReducer;