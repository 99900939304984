import React from 'react';
import classNames from 'classnames';

import './BigButton.css';
import { NavLink } from "react-router-dom";

const BigButton = ({
    htmlId,
    name,
    type,
    size,
    classname,
    onClick,
    children,
    eleType,
    ...props
}) => {

    let myClass = classNames('btn-bigbtn big-button', {
        [size]: size,
        [classname]: classname
    });

    if (eleType == "link") {
        myClass += '';
        return (
            <NavLink className={myClass} to={props.url}> {children}</NavLink>
        );
    }

    delete props.url;
    return (
        <button
            className={myClass}
            id={htmlId}
            name={name}
            type={type}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};

BigButton.defaultProps = {
    type: 'button',
    children: 'Submit',
    size: '',
    classname: '',
    eleType: 'button'
};

export default BigButton;