import React, { useEffect, useState } from "react";
/* import { makeStyles } from "@material-ui/core/styles"; */
import Popover from "@material-ui/core/Popover";
/* import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from 'react-router-dom';
import Typography from "@material-ui/core/Typography"; */
import Button from "@material-ui/core/Button";
import "./AddVariables.css";
import { environment } from "../../../environment";
import Actions from '../../SearchManagement/Actions/Actions'
import Axios from "axios";
/* import { indexOf } from "lodash"; */
import { messageService } from '../../../Shared/Message';
import { updateService } from '../../../Shared/UpdateCart'
import { connect } from "react-redux";
/* const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    width: "500px"
  },
})); */

 function SimplePopover(props) {
  /* const classes = useStyles(); */
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [data, setData] = useState([]);
  const [removeTxt, setRemoveTxt] = useState("Remove All")
  const [isShowCart, setShowCart] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  

  function sendMessage() {
    // send message to subscribers via observable subject
    messageService.sendMessage('getData');
  }
  /* const handleClickAction = (event) => {
    setAnchorEl1(event.currentTarget)
  } */
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl1(null)
  };


  // useEffect(() => {

  // })

  useEffect(() => {
    if (localStorage.getItem("isSignedIn") == "true") {

      const subscription = updateService.onAlert().subscribe(message => {
        if (message.text == 'varData') {
          getData();
          setShowCart(true);
        }else{
          setShowCart(false);
        }
      })

      return subscription.unsubscribe;
    }

    

  }, []);

  // Run! Like go get some data from an API.

  const getData = () => {
    let reqBody = {
      "workspace_id": localStorage.defaultProjectNo ? parseInt(localStorage.defaultProjectNo) : '',
      "year_info": 0,
      "cartType": 'analysis'
    };
    Axios.post(`${environment.getCartItem}`, reqBody)
      .then((resp) => {
        //   this.setState({ password: "", passwordConfirmation: "" });
        if(resp.data.response?.cart_items){

        setData(resp.data.response.cart_items)
        setShowCart(true)
        }
        
      })
      .catch((error) => {
        setShowCart(false)
        
        //props.history.push("/error");
      });
  }

  const remove = (input, vId) => {
    let cartData = []
    let remove_all = true
    if (input == 'each') {
      cartData.push(vId)
      remove_all = false
    } else {
      setRemoveTxt("Removing...")
    }
    let reqBody = {
      "workspace_id": localStorage.defaultProjectNo ? parseInt(localStorage.defaultProjectNo) : '',
      "remove_all": remove_all,
      "cartType": 'analysis',
      "cart_items": cartData
    };

    Axios.delete(`${environment.removeFromCart}`, { data: reqBody })
      .then((resp) => {
        setRemoveTxt("Remove All")
        sendMessage()

      })
      .catch((error) => {
        //props.history.push("/error");
      });
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  return (
    <div>
    {isShowCart && (
      <div id="command-bar" className="text-right variables-actions">
     
      <div>
        <Button aria-describedby={id} color="primary" onClick={handleClick}>
          <div
            className="navcart-wrapper gss-dd-target gss-dismiss"
            data-target="#dd_cart"
            data-width="400px"

            data-original-title=""
            title=""
          >
            <div className="navcart-text cart">
              <div className="navcaret">
                <a href="#">
                  <i className="fa fa-shopping-cart fa-lg"></i>
                  <span id="varselnum">{data.length}</span>
                  <span id="variables">VARIABLES</span>
                </a> <i className="fa fa-caret-down"></i>
              </div>

            </div>
          </div>
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >

         
            <div className="popover-content gss ">
              <div className="dd-cart">
                <div className="dd-header">
                  <div className="dd-title">
                    <div className="pull-left">Variable Cart ({data.length})</div>
                    {data.length > 3 ?
                      <a className="pull-right remove-all no-decoration" href="#" onClick={remove.bind(this, 'All', '')}>{removeTxt == "Remove All" ? <i className="fa fa-times"></i> : ''}
                        <span className="removeAll">{removeTxt}</span></a> : ''}
                    <div className="clearfix"></div>
                  </div>
                  <div className="dd-subtitle">
                    Years selected:
                  <strong>all years</strong>
                  </div>
                </div>
                <div className="dd-body">
                  <table className="table">
                    <tbody>
                      {data.slice(0, 5).map((item, index) =>
                        <tr>
                          <td>
                            <a href="/variables/5574/vshow">{item.orignlName == 'id_' ? 'id' : item.orignlName}</a>
                          </td>
                          <td>{item.label}</td>
                          <td className="dd-remove">
                            { (item.vID > 2 && item.vID != 5574) ? <i className="fa fa-times" onClick={remove.bind(this, 'each', item.vID)}></i> : ''}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="dd-footer">
                  <div className="pull-right margin-top-10">
                    {data.length > 5 ? <span >
                      Showing  {data.length > 5 ? 5 : data.length} of {data.length} variables</span> : ''}
                    {data.length > 5 ? <span className="view-all">
                      <a className="no-decoration" href="/variables/data_cart">VIEW ALL</a>
                    </span> : ""}
                  </div>
                </div>
              </div>
            </div>
        
        </Popover>
        {data.length > 5 ? <Actions></Actions> : ''}
      </div>
      
    </div>
    )}

    </div>

  );
}

const mapStateToProps = (state) => ({
  data: state.reducer,
});
export default connect(mapStateToProps)(SimplePopover);