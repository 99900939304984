import React from 'react'
import { Card, Carousel } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import banner2 from "../../Images/2018_banner_2.png";
import banner from "../../Images/2018_banner.png";
import './HomeCarousel.css';
import BigButton from '../UI/BigButton/BigButton'
import { useSelector } from 'react-redux';

const HomeCarousel = () => {
  const authDetails = useSelector(state => state.authReducer);
  
  return (
    <section style={{height:"auto"}}>
      <Card className="slider_text_container">
        <Card.Body>
          <Card.Title as='h1'>Access and Analyze GSS Data</Card.Title>
          <Card.Text className="card-text">
            Since 1972, the General Social Survey (GSS) has been monitoring societal change and studying the growing complexity of American society.
            GSS Data Explorer,
            from NORC at the University of Chicago,
            makes it easier than ever to use the data collected by the GSS.
          </Card.Text>
          <div className="row m-0 p-0 btn-container">
            <div className='col-5 d-flex justify-content-center'>
            <BigButton classname="dark" eleType="link" url="/variables/vfilter"><p className='btn-p m-0'>SEARCH VARIABLES</p></BigButton>
            </div>
            
            <span className="box-divider"></span>
          
            <div className='col-5 d-flex justify-content-center'>
            {authDetails.isSignedIn || localStorage.isSignedIn === 'true' ? 
            <BigButton classname="btn-orange" eleType="link" url="/MyGSS"><p className='btn-p m-0'>CREATE AN ACCOUNT</p></BigButton>
          :
            <BigButton classname="btn-orange" eleType="link" url="/user/sign_up"><p className='btn-p m-0'>CREATE AN ACCOUNT</p></BigButton>
        }
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="journalist-box">
        <Card.Title className="journalist-header"> <div className="fa fa-line-chart fa-x"></div>New 2022 Data</Card.Title>

        <Card.Body className="journalist-text">
          <Card.Text >
            2022 data is now available through the GSS Data Explorer. Explore this new data and
            &nbsp;<NavLink to="/trends">
              updated Key
               Trends</NavLink>&nbsp;today!
          </Card.Text>
        </Card.Body>
      </Card>

      <Carousel data-interval="false" style={{height:"auto"}}>
        <Carousel.Item>
          <img alt="slider  1" src={banner} className="d-block w-100 h-80"  />
          <div className="trends-button">
            <BigButton classname="dark" eleType="link" url="/trends">VIEW TRENDS</BigButton>
          </div>
          <Carousel.Caption>
            {/* text */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img alt="slider 2" src={banner2} className="d-block w-100 h-80"  />
          <div className="trends-button">
            <BigButton classname="dark" eleType="link" url="/trends">VIEW TRENDS</BigButton>
          </div>
          <Carousel.Caption>
            {/* <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>

  )
}


export default HomeCarousel