import React from "react";
/* import Axios from "axios"; 
import { environment } from "../../../environment";*/
import "./YearsTable.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const YearsTable = ({ variables, menuSelected, filteredYears, forceFilter }) => {


  /* const [localState, setLocalState] = useState({
    isExpanded: false,
    variables: {
      response: {
        response: {
          summary_by_year: [],
        },
      },
    },
    statistics: [
      "Minimum",
      "1st Quartile",
      " Median",
      " Mean",
      "3rd Quartile",
      "Maximum",
      "Standard deviation",
    ],
  }) */

  if (variables.response.response.variable_type == "discrete") {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Code &nbsp;
              <Tooltip interactive
                title={
                  <div>
                  For 2021 variables, the reserve codes will appear as .n, .d, and .s in data extracts instead of as negative codes used in previous years (-99, -98, -97, etc.).
                  </div>
                }
              >
                 <InfoIcon
                        style={{ fontSize: "10px"}}
                        fontSize="small"
                        className="plus-icon"
                      />
              </Tooltip>
              </TableCell>
              <TableCell>Label</TableCell>
              {variables.response.response?.summary_by_year[0]?.years_info.map((row) => {
                return (
                  (menuSelected == "Filtered Years" && filteredYears.length && !forceFilter) ?
                    ((filteredYears.indexOf(+row.year)) !== -1 ? <TableCell align="right" > {row.year}</TableCell> : "")
                    : <TableCell align="right" > {row.year}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {variables.response.response.summary_by_year
              ? variables.response.response.summary_by_year.map(
                (row) => {
                  return (
                    <TableRow key={row.year}>
                      <TableCell scope="row">{row.code_id}</TableCell>
                      <TableCell scope="row">{row.code_label}</TableCell>
                      {variables.response.response?.summary_by_year[0]?.years_info.map(({ year }) => {
                        let rowdata = row.years_info.find(d => d.year == year);
                        return (
                          (menuSelected == "Filtered Years" && filteredYears.length && !forceFilter) ?
                            ((filteredYears.indexOf(+rowdata.year)) !== -1 ? <TableCell align="right" scope="row">
                              {rowdata?.count}
                            </TableCell> : "") : <TableCell align="right" scope="row">
                              {rowdata?.count}
                            </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                }
              )
              : ""}
          </TableBody>
        </Table>
      </TableContainer >
    );
  } else {
    return (
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {variables.response.response.summary_by_year ? (
              <div>
                <TableHead>
                  <TableCell scope="row">Statistics</TableCell>
                  {variables.response.response.summary_by_year.min.map((row) => (
                    <TableCell scope="row">{row?.year}</TableCell>
                  ))}
                </TableHead>
                <TableRow>
                  <TableCell scope="row">Minimum</TableCell>
                  {variables.response.response.summary_by_year.min.map(
                    (row) => (
                      <TableCell scope="row">{row?.value}</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell scope="row">1st Quartile</TableCell>
                  {variables.response.response.summary_by_year.quart_1.map(
                    (row) => (
                      <TableCell scope="row">{row.value}</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Median</TableCell>
                  {variables.response.response.summary_by_year.median.map(
                    (row) => (
                      <TableCell scope="row">{row?.value}</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Mean</TableCell>
                  {variables.response.response.summary_by_year.mean.map(
                    (row) => (
                      <TableCell scope="row">{row?.value}</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell scope="row">3rd Quartile</TableCell>
                  {variables.response.response.summary_by_year.quart_3.map(
                    (row) => (
                      <TableCell scope="row">{row?.value}</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Maximum</TableCell>
                  {variables.response.response.summary_by_year.max.map(
                    (row) => (
                      <TableCell scope="row">{row?.value}</TableCell>
                    )
                  )}
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Standard deviation</TableCell>
                  {variables.response.response.summary_by_year?.std.map(
                    (row) => (
                      <TableCell scope="row">{row?.value}</TableCell>
                    )
                  )}
                </TableRow>
              </div>
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}


export default YearsTable;
