export const checkIsRequired = (name, value) => {
    if (value === null || value === '' || value.trim() === '' || value === undefined ) {
        /* let field = name.charAt(0).toUpperCase() + name.slice(1); */
        if(name == 'passwordConfirmation'){
            name = 'password confirmation';
        }else if(name == 'firstname') {
            name = 'first name';
        }

        if(name == 'reCAPTCHA') {
            return {
                errorMessage: `CAPTCHA verification failed, please try again`,
                errorStatus: true
            }
        } else {
            return {
                errorMessage: `Please enter the ${name}`,
                errorStatus: true
            }
        }        
    } else {
         return {
             errorMessage: '',
             errorStatus: false
         }
    }
}

const regLetter = /^[A-Za-z ]+$/;
export const checkIsAllLetter = (name, value) => {
    let isValid = regLetter.test(value);
    return {
        errorMessage: isValid ? "" : `Please enter characters only`,
        errorStatus: isValid ? false : true
    }
}

const regSpace = /.*([ \t]).*/;
export const checkSpace = (name, value) => {
    let isValid = regSpace.test(value?.trim());
    return {
        errorMessage: isValid ? "Spaces are not allowed" : "",
        errorStatus: isValid ? true : false
    }
}

//const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const checkIsValidEmail = (name, value) => {
    let field = name.charAt(0).toUpperCase() + name.slice(1);
    let isValid = regEmail.test(value);
    return {
        errorMessage: isValid ? "" : ((value === null || value === '' || value.trim() === '' || value === undefined ) ? `Please enter the ${name}`:`${field} is invalid`),
        errorStatus: isValid ? false : true
    }
}

export const checkPasswordLength = (name, value) => {
    if (value.length < 8) {
        let field = name.charAt(0).toUpperCase() + name.slice(1);
        return {
            errorMessage: `${field} is too short`,
            errorStatus: true
        }
    } else {
         return {
             errorMessage: '',
             errorStatus: false
         }
    }
}

//const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]+$/;
const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[\s])(?=.*?([^\w]|[_])).+$/;

export const checkPasswordCombination = (name, value) => {
    let isValid = regPassword.test(value)
    return {
        errorMessage: isValid ? "" : `Must have at least one uppercase, one lowercase, a number and a special character`,
        errorStatus: isValid ? false : true
    }
} 


export const setLocalStorage = (data) => {
    localStorage.setItem(
        "token",
        data.signInUserSession.idToken.jwtToken
      );   
    localStorage.setItem("isSignedIn", true);
    localStorage.setItem("username", data.username);
    localStorage.setItem("isAdmin", data.signInUserSession.accessToken.payload['cognito:groups'][0] == 'norc-admins');
}
