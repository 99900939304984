import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Dialog,
  TextareaAutosize,
} from "@material-ui/core";
import React, { useState } from "react";
/* import Modal from "react-bootstrap/Modal"; */
import "./CustomModal.css";
import BigButton from "../BigButton/BigButton"

const CustomModal = (props) => {
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isEdit, SetEdit] = useState('')
  React.useEffect(() => {
    // component updated Temporary Code
    if (props.isOpen && !open) {
      handleClickOpen();
      // set isOpen as false to prevent erasing your data again.
      props.setIsOpen();
      SetEdit(props.isEdit)
      if (props.isEdit) {
        setInputText(props.comment.comment)
      }
      else {
        if(props.inputText ) {
          setInputText(props.inputText);
        } else {
          setInputText('');
        }
        
      }
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (props.title == 'Success') {
      props.setClose();
    }
  };

  const onSave = () => {
    if(props.title == "Save Search" && inputText == '') {
      return false;
    }
    setOpen(false);
    props.handleSubmit(inputText);
    setInputText('');
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      className={"custom-modal " + props.class_name}
    >
      <DialogTitle id="max-width-dialog-title" className="modal_title">
        {props.title == 'Error' ? props.titleText : props.title}
      </DialogTitle>
      <DialogContent className="modal_content">
        <DialogContentText>{props.subtitle}</DialogContentText>
        {props.isInput && (
          <div className="form-group username form-inline">
            {props.inputLabel ? <div className="col-sm-3">
              <label className="control-label">{props.inputLabel}</label>
            </div> : ''}
            <div className="col-sm-9">
              <input
                className="form-control"
                autoFocus="autofocus"
                type="text"
                name={props.inputLabel}
                id={props.inputLabel}
                value={inputText}
                onChange={(event) => setInputText(event.target.value)}
                disabled={props.inputText? true:false}
              />
            </div>
          </div>
        )}
        {!props.isInput && (
          props.title == 'Error' || props.title == 'Success' ? (
            <div className="form-group username">
              <div className="col-sm-12">
                <p className="ml-3">{props.inputLabel}</p>
              </div>
            </div>
          ) : (
            <div className="form-group username">
              <div className="col-sm-9">
                <TextareaAutosize
                  className="form-control"
                  autoFocus="autofocus"
                  type="text"
                  rowsMin={4}
                  rowsMax={4}
                  name={props.inputLabel}
                  id={props.inputLabel}
                  value={inputText}
                  onChange={(event) => setInputText(event.target.value)}
                />
              </div>
            </div>
          )
        )}
      </DialogContent>

      <DialogActions className="modal_footer">
        {props.title == 'Error' || props.title == 'Success' ? (
          <div className="col-md-12 btn-two">
            <BigButton onClick={handleClose}>
              OK
            </BigButton>

          </div>
        ) : (
          <>
            <div className="col-md-6 btn-one" onClick={onSave}>
              <Button classes={{ label: "btn-orange primary-link" }}>
                {props.btnText}
              </Button>
            </div>
            <div className="col-md-6 btn-two" id="custom-cancel-btn">
              <Button onClick={handleClose} classes={{ label: "secondary-link" }} >
                CANCEL
              </Button>
            </div>
          </>
        )}

      </DialogActions>
    </Dialog>
  );
};
export default CustomModal;

CustomModal.protoTypes = {};
