import React, { useState, useEffect } from 'react'
import BigButton from '../BigButton/BigButton';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { Col } from 'react-bootstrap';
/* import EnhancedTableCheckbox from '../EnhancedTableCheckbox/EnhancedTableCheckbox'; */
import ClearIcon from '@material-ui/icons/Clear';
import DropBoxSVG from '../../../SVGs/icons8-dropbox.svg';
import GDriveSVG from '../../../SVGs/icons8-google-drive.svg';
import { gapi } from 'gapi-script';

import './SaveToCloud.css';
import { environment } from '../../../environment';
import axios from 'axios';
import moment from 'moment';
import { ExportDropbox } from '../../../Store/utils/common.utils';




const SaveToCloud = ({ modalShow, selected, ...props }) => {

    const [shareType, setShareType] = useState("")
    const [isSaving, setIsSaving] = useState(false);
    /* const [listDocumentsVisible, setListDocumentsVisibility] = useState(false); */
    /* const [documents, setDocuments] = useState([]); */
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
    /* const [isFetchingGoogleDriveFiles, setIsFetchingGoogleDriveFiles] = useState(false); */
    const [signedInUser, setSignedInUser] = useState({ Ca: null, wt: null, xc: null });
    const [refreshToken, setRefreshToken] = useState("");

    // Client ID and API key from the Developer Console
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const TYPE_GDRIVE = "gdrive";
    


    // Array of API discovery doc URLs for APIs
    const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/drive.file';



    //================ G-Drive Upload ================

    /**
    *  Sign in the user upon button click.
    */
    const handleAuthClick = (event) => {
        gapi.auth2.getAuthInstance().signIn();
    };

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */

    const formatTimeStamp = (date) => {
        return moment(date).utc().format();
    }

    useEffect(() => {
        if (signedInUser && signedInUser.xc) {
            saveToCloud()
          
        }
    }, [signedInUser.xc])

    const saveToCloud = () => {

        let scopes = "";
        if (shareType == TYPE_GDRIVE) {
            scopes = signedInUser.xc.scope.includes("auth/drive.file")
        }

        if (scopes || shareType !== TYPE_GDRIVE) {
            setIsSaving(true);
            setIsLoadingGoogleDriveApi(true);
            // console.log("api called")

            let reqPayload = {
                "token": {},
                "upload_to": shareType
            }
            reqPayload[(props.isAnalysis ? 'analysis_id_list' : 'extract_id_list')] = [...selected];
            if (shareType == TYPE_GDRIVE) {
                let gdriveDetails = {
                    "token": signedInUser.xc.access_token,
                    "refresh_token": refreshToken,
                    "token_uri": "https://oauth2.googleapis.com/token",
                    "client_id": "",
                    "client_secret": "",
                    "scopes": [],
                    "expiry": formatTimeStamp(signedInUser.xc.expires_at),
                    
                }
                reqPayload.token = { ...gdriveDetails }
            }

            axios.post(
                environment.uploadToDrive,
                reqPayload
            ).then((response) => {
                props.setSelected([])
                setIsLoadingGoogleDriveApi(false);
                setIsSaving(false);
                if (shareType == TYPE_GDRIVE) {
                    
                    alert("Success! Files saved to your Google Drive");
                } else {
                    if (response.data.presigned_url_list) {
                        ExportDropbox(response.data.presigned_url_list);
                    } else {
                        alert("Some error Occurred.");
                    }
                }
                onClose();
            }).catch((err) => {
                if (err.response) {
                    //alert(err.response.data.message);
                    onClose()
                    gapi.auth2.getAuthInstance().signOut();
                    let isTryAgain = true;
                    handleClientLoad(isTryAgain)

                    // client received an error response (5xx, 4xx)
                } else if (err.request) {
                    alert('Some Error Occurred');
                    // client never received a response, or request never left
                } else {
                    alert('Some Error Occurred');
                    // anything else
                }
            });
        } else {
            alert("Permission Error! Please try again.")

            onClose()
            gapi.auth2.getAuthInstance().signOut();
        }


    }

    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn === false){
            alert('Give permission to G-drive and export again')
        }
        if (isSignedIn) {
           
            // Set the signed in user
            let userDetails = gapi.auth2.getAuthInstance().currentUser.get();
            // console.log("userDetails", userDetails)
            setSignedInUser(userDetails);
          

            // list files if user is authenticated
        } else {
            // prompt user to sign in
            handleAuthClick();
        }
    };

    /**
     *  Sign out the user upon button click.
     */
    /* const handleSignOutClick = (event) => {
        setListDocumentsVisibility(false);
        gapi.auth2.getAuthInstance().signOut();
    }; */

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
        setIsLoadingGoogleDriveApi(true);
        setIsSaving(true);
        let GoogleAuth; // Google Auth object.
        gapi.client
            .init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
                grant_type: "refresh_token",
               
            })
            .then(
                () => {
                  
                    setIsLoadingGoogleDriveApi(false);
                    setIsSaving(false);
                    // Listen for sign-in state changes.
                    // console.log("listen multiple times")
                    GoogleAuth = gapi.auth2.getAuthInstance();
                    //  GoogleAuth.grantOfflineAccess()
                    // Listen for sign-in state changes.
                    GoogleAuth.isSignedIn.listen(updateSigninStatus);
                    console.log('gdrive signedin status',GoogleAuth.isSignedIn.get())

                    // Handle the initial sign-in state.
                    updateSigninStatus(GoogleAuth.isSignedIn.get());
                    props.setSelected([]);
                    onClose();
                   
                },
                (error) => {                
                    alert(error)
                }
            ).catch(function (error) {               
                alert(error)
            });;
    };

    const handleClientLoad = (isUploadAgain) => {
        gapi.load('client:auth2', initClient);       
    };

    /* const showDocuments = () => {
        setListDocumentsVisibility(true);
    }; */


    //================ G-Drive Upload ================

    const onExportData = () => {
        if (shareType == TYPE_GDRIVE) {
            handleClientLoad()
        } else {
            saveToCloud()
        }

    }

    const onClose = () => {
        props.setModalShow(false)
    }


    return (
        <Dialog
            open={modalShow}
            style={{ overflow: "scroll" }}
            maxWidth="xs"
            aria-labelledby="max-width-dialog-title"
            className="share-data-model h-auto"
            disableBackdropClick={true}
            fullWidth={true}
        >
            <DialogTitle>
                <Col as="span" className="px-3 py-1 m-0">   Select  where you want to export</Col>
                <Col as="span" className="px-3 py-1 m-0">
                    <ClearIcon className="float-right clear-icon" onClick={onClose} />
                </Col>

            </DialogTitle>
            <DialogContent className="h-auto">
                <div>
                    <span className={'storage-icons mr-3' + (shareType == TYPE_GDRIVE ? ' selected' : "")} onClick={() => setShareType(TYPE_GDRIVE)}><img height="45" width="45" src={GDriveSVG} alt="" /></span>
                    <span className={'storage-icons' + (shareType == "dropbox" ? ' selected' : "")} onClick={() => setShareType("dropbox")}><img height="45" width="45" src={DropBoxSVG} alt="" /></span>

                </div>

            </DialogContent>
            <DialogActions className="">
                <BigButton classname="btn-cancel" onClick={onClose}>CANCEL</BigButton>
                <BigButton classname="btn-orange btn-save" disabled={(shareType === "" || isLoadingGoogleDriveApi)} onClick={onExportData}>
                    {isSaving &&  
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>}
                    SUBMIT</BigButton>
            </DialogActions>
        </Dialog>
    )
}

export default SaveToCloud
