import React, { useEffect, useState } from "react";
import KeywordSearch from "../KeywordSearch/KeywordSearch";
import "./SearchBar.css";
import axios from "axios";
import YearDropdown from "../KeywordSearch/YearDropdown/YearDropdown";
import { messageService } from "../../../Shared/Message";
import { useDispatch, useSelector } from "react-redux";
import SearchByTags from "../SearchByTags/SearchByTags";
import { setFiltered } from "../../../Store/actions";
import ClearFilterContext from "../../../Store/ContextApi/ClearFilterContext";
import { Trigger } from "react-history-search";
const SearchBar = (props) => {
  const dispatch = useDispatch();
  const [clearBool, setClearBool] = useState(false);
  const contextValue = { clearBool, setClearBool };
  const authDetails = useSelector((state) => state.authReducer);
  const [value, setValue] = useState([]);
  const [range, setRange] = useState([]);
  const [years, setYears] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [options, setOptions] = useState([]);
  const [finalSubMod, setFinalSubMod] = useState({ subjects: [], modules: [] });
  const [finalSearchKeywod, setFinalSearchKeywod] = useState("");
  const [keywordData, setKeywordData] = useState([]);
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [isRangeSliderSel, setRangeSliderSel] = useState(true);
  const [isSpecific, setSpecific] = useState(true);
  const [selectedTags, setTags] = useState(new Set());
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [ballotInfo, setBallotInfo] = useState([]);
  const [finalYourSharedTags, setFinalYourSharedTags] = useState({
    myTags: [],
    sharedTags: [],
  });
  const [subjectsSearch, setSubjectsSearch] = useState([]);
  const {
    min_max_year: { min_max_year = [] },
  } = useSelector((state) => state.minmaxYearsReducer);
  useEffect(() => {
    fetchSubjectModules();
    fetchAutocomplete();
  }, []);

  const checkBallotVal = () => {};

  const setYearRangeSlider = (data) => {
    setValue(data);
  };

  const setSpecificYear = (data) => {
    setYears(data);
  };

  const setBallotYear = (data) => {
    setBallotInfo(data);
  };

  const isRangeSliderSelFn = (data) => {
    setRangeSliderSel(data);
  };

  const setSearchKeyword = (data) => {
    setFinalSearchKeywod(data);
  };

  const setSpecificFlag = (bool) => {
    setSpecific(bool);
  };

  const setMenuClick = (data, isCallSubmit) => {
    setSelectedMenu(data);
    if (isCallSubmit) {
      handleSearchSubmit();
    }
  };
  const setSubMod = (data) => {
    setFinalSubMod(data);
  };
  const setYourSharedTags = (data) => {
    setFinalYourSharedTags(data);
  };
  const setSelectedTags = (val) => {
    setTags(new Set(val));
  };

  const handleSearchSubmit = () => {
    let yearsArray = [];
    let tempArray = ballotInfo ? ballotInfo : [];
    let arr = [];

    tempArray.map((obj) => {
      let ballot = [];
      if (obj.isCheckedRowAll && obj.ballotInfo.length == 0) {
        yearsArray.push(obj.year);
      }

      obj.ballotInfo.map((ballots) => {
        if (ballots.isChecked) {
          ballot.push(ballots.ballotInfo);
        }
      });
      if (ballot.length) {
        arr.push({ year: obj.year, ballots: ballot });
        yearsArray.push(obj.year);
      }
    });

    let shareData = {};
    shareData.filteredYears = isSpecific ? value : null;
    shareData.finalSubMod = finalSubMod;
    shareData.finalYourSharedTags = finalYourSharedTags;
    shareData.keyword = finalSearchKeywod;
    shareData.selectedMenu = selectedMenu;
    shareData.yearBallot = !isSpecific && arr && arr.length ? arr : null;
    shareData.years =
      !isSpecific && yearsArray && yearsArray.length ? yearsArray : null;
    shareData.isSpecific = !isSpecific;
    shareData.isRangeSliderSel = isRangeSliderSel;
    shareData.isRangeUpdated =
      JSON.stringify(range) != JSON.stringify(shareData.filteredYears);
    shareData.enableSaveSearchBtn = true;
    props.clickSearch(shareData);
    let filteredYears = isSpecific
      ? shareData?.filteredYears
      : shareData?.years;
    shareData.isClear = false;
    dispatch(setFiltered({ filteredYears }));
    setShowClearFilter(true);
  };

  const fetchSubjectModules = () => {
    axios
      .get(process.env.PUBLIC_URL + "/config/subjects_and_modules.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      })
      .then((response) => {
        if (!(response.data.models && response.data.models.length)) {
          return;
        }

        if (response.data.models && response.data.models.length > 0) {
          setSubjectsSearch(response.data.subjects);
          response.data.models.map((arr) => {
            arr["key"] = "Modules";
          });
          response.data.subjects.map((arr) => {
            arr["key"] = "Subjects";
          });
          const result = response.data.models.concat(response.data.subjects);
          setOptions(result);
        }
      });
  };

  const fetchAutocomplete = () => {
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-uat.norc.org/"
    ) {
      axios
        .get(
          process.env.PUBLIC_URL +
            "/config/variable_details_autocomplete_uat.json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          if (!(response.data.var_detail && response.data.var_detail.length)) {
            return;
          }
          if (response.data.var_detail && response.data.var_detail.length > 0) {
            setKeywordData(response.data.var_detail);
          }
        });
    }
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-tst.norc.org/"
    ) {
      axios
        .get(
          process.env.PUBLIC_URL +
            "/config/variable_details_autocomplete_qa.json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          if (!(response.data.var_detail && response.data.var_detail.length)) {
            return;
          }
          if (response.data.var_detail && response.data.var_detail.length > 0) {
            setKeywordData(response.data.var_detail);
          }
        });
    }
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL ==
      "https://gssdataexplorer.norc.org/"
    ) {
      axios
        .get(
          process.env.PUBLIC_URL + "/config/variable_details_autocomplete.json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          if (!(response.data.var_detail && response.data.var_detail.length)) {
            return;
          }
          if (response.data.var_detail && response.data.var_detail.length > 0) {
            setKeywordData(response.data.var_detail);
          }
        });
    }
  };

  keywordData?.sort((a, b) => {
    var maxYearA = Math.max.apply(0, a.max_year);

    var maxYearB = Math.max.apply(0, b.max_year);

    if (b.years_count == a.years_count) {
      return maxYearB - maxYearA;
    } else return b.years_count - a.years_count;
  });

  // console.log("sort Arr: ", keywordData);

  const clearAll = () => {
    setShowClearFilter(false);
    messageService.sendMessage("clear");
    let years =
      min_max_year && min_max_year.length > 0 ? min_max_year : [1972, 2018];
    setValue(years);
    setFinalSubMod(null);
    setFinalYourSharedTags([]);
    setFinalSearchKeywod("");
    setBallotInfo(null);
    setSelectedMenu(null);
    setTags(new Set());
    setSubMod([]);
    setClearBool(true);
    fetchSubjectModules();
    setSpecific(true);
    setRangeSliderSel(true);
    let shareData = {};
    shareData.filteredYears = [1972, 2018];
    shareData.finalSubMod = [];
    shareData.finalYourSharedTags = [];
    shareData.keyword = "";
    shareData.selectedMenu = null;
    shareData.yearBallot = null;
    shareData.years = null;
    shareData.isSpecific = true;
    shareData.isRangeSliderSel = isRangeSliderSel;
    shareData.isRangeUpdated =
      JSON.stringify(range) != JSON.stringify(shareData.filteredYears);
    shareData.isClear = true;
    props.clickSearch(shareData);
  };

  return (
    <div className="noscroll gss-vfilter pb-0" id="ws-content">
      <ClearFilterContext.Provider value={contextValue}>
        <div className="container">
          <div className="row mb-4">
            <div className="col">
              <YearDropdown
                isSpecificYears={setSpecificFlag}
                data={yearsData}
                updateRangeYears={setYearRangeSlider}
                updateYears={setSpecificYear}
                updateBallot={setBallotYear}
                isRangeSliderSelFn={isRangeSliderSelFn}
                checkBallotVal={checkBallotVal}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <KeywordSearch
                data={keywordData}
                subjectsData={subjectsSearch}
                menuClick={setMenuClick}
                searchKeyword={setSearchKeyword}
                callSubmit={handleSearchSubmit}
              />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-auto d-flex align-items-center">
                  <span>
                    <strong>Filter by:</strong>
                  </span>
                </div>
                <div className="col">
                  <SearchByTags
                    selectedTags={selectedTags}
                    finalSubMod={props.finalSubMod}
                    finalYourSharedTags={props.finalYourSharedTags}
                    setSelectedTags={setSelectedTags}
                    setSubMod={setSubMod}
                    setYourSharedTag={setYourSharedTags}
                    showClearFilter={showClearFilter}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
              <div className="row justify-content-around m-3 m-lg-0 m-xl-0">
                <Trigger dataId="keyword">
                  <button
                    id="searchBtn"
                    type="button"
                    name="commit"
                    className="gss-btn btn-orange"
                    onClick={handleSearchSubmit}
                  >
                    Search
                  </button>
                </Trigger>
                {authDetails.isSignedIn ? (
                  showClearFilter && (
                    <button
                      type="button"
                      name="commit"
                      className="gss-btn "
                      onClick={props.openSaveSrchDialog}
                    >
                      Save
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    name="commit"
                    className="gss-btn "
                    onClick={props.openSaveSrchDialog}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
          {showClearFilter ? (
            <div className="row mb-1" style={{ marginTop: "-15px" }}>
              <div className="col">
                <span
                  onClick={clearAll}
                  className="font-small custom-modal-title cursor-pointer-hand"
                >
                  Clear Filters
                </span>
              </div>
            </div>
          ) : undefined}
        </div>
      </ClearFilterContext.Provider>
    </div>
  );
};

export default SearchBar;
