import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { Tooltip, FormControlLabel } from "@material-ui/core";
import { GreenCheckbox } from '../../Store/utils/common.utils';
import BallotInfo from '../../Containers/SearchManagement/BallotInfo/BallotInfo';
import AssociatedQuestions from '../AssociatedQuestions/AssociatedQuestions';

const VariableItems = (props) => {

  const isSelected = (id) => props.selectedVar.findIndex(sv=> sv.id == id) !== -1;
  const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
  return (
    <>
    {props.variables.map((variable, index) => {
      const isItemSelected = isSelected(variable.id);
      return (
    <div className="row data cutomize-padding mb-1" key={index}>
      <div className="col-0.5 pl-0 form-check" >
        <FormControlLabel
          control={
            <GreenCheckbox
              checked={isItemSelected}
              onChange={(event) => props.changeCheckbox(
                variable
              )}
              id={index + ""}
              name={"checbox_" + index}
            />
          }

        />
      </div>
      <div className="col pl-0">
        <div className="row mr-0 ml-0">
          <div className="col-2">
            <Tooltip title="View variable detail">
              <NavLink
                className="tt-name"
                to={"/variables/" + variable.id + "/vshow"}
              >
                {variable.name}
              </NavLink>
            </Tooltip>
          </div>
          <div className="col-3">{variable.description}</div>
          <div className="col-5">
            <BallotInfo
              years={variable.years}
              isVarDetailPage={false}
              yearsRange={props.filteredYears && props.filteredYears.length >0?props.filteredYears:min_max_year} 
              selectedYears={props.selectedYears}
              isRangeSelected={props.isRangeSelected}
            ></BallotInfo>
          </div>
          <div className=" col-sm-12 col-md-12 col-lg-2  d-flex flex-column ">
            {variable.isAdded ? (
              <>
                <span className="d-flex flex-column align-items-center">
                  <span
                    className="var-add-extract px-2 d-flex align-items-center justify-content-evenly">
                    <CheckIcon fontSize="small" />Added
                  </span></span>
                  {variable.name != 'year' && variable.name != 'id' && variable.name != 'ballot' && (
                    <button className="tag-action-btn text-nowrap text-10 remove-gss" onClick={() => props.handleMyGSS(
                  variable.id,
                  variable.name,
                  index,
                  'remove'
                )}>Remove From MyGSS</button>
                  )}
                
              </>
            ) : (
              <span className="d-flex flex-column align-items-center" onClick={() => props.handleMyGSS(
                variable.id,
                variable.name,
                index,
                'add'

              )} >
                <span
                  className="var-add-to-mygss px-2 d-flex align-items-center justify-content-evenly">
                  <AddIcon fontSize="small" className="plus-icon" />Add to MyGSS
                </span></span>
              /* <button type="button"
              className="btn tag-model-btn-outline  px-2 mb-2 d-flex align-items-center"
              onClick={() => props.handleMyGSS(
                  props.variable.id,
                  props.variable.name,
                  props.index,
                'add'
                
              )}  >
             <AddIcon fontSize="small" className="plus-icon" />Add to MyGSS
              </button> */
            )}

          </div>
        </div>
        {/* {props.type == 1 && ( */}
          
          <div className="d-flex">
              <div className="var-label">
                  {(variable.expanded) ?
                      (variable.tags_info.map((tagInfoItem, index) => (
                          <Chip title={tagInfoItem.name} variant="outlined" size="small" label={tagInfoItem.name} key={index} className="mr-1"/>
                      )))
                      :
                      (variable.tags_info.slice(0, 3).map((tagInfoItem, index) => (
                          <Chip title={tagInfoItem.name} variant="outlined" size="small" label={tagInfoItem.name} key={index} className="mr-1"/>
                      )))
                  }
              </div>
              <div className="ml-2">
                  {(variable.tags_info.length > 3) ?
                      (<span className="list-group-item rounded-0 border-0 p-0 m-0 text-nowrap tag-trans">
                          <button className={"button-to-link"} onClick={(e) => props.toggleTags(e, variable.vID)}>{(variable.expanded) ? "See less" : "+" + (variable.tags_info.length - 3) + " More"}</button>
                      </span>) : ""}
              </div>
          </div>
         {/*  )} */}
          <div className="row mb-2 mr-0 ml-0">
            <div className="col">
              <AssociatedQuestions variable={variable} isExpanded={props.isExpanded} />
            </div>
          </div>
      </div>
    </div>
      )  
  })
}
</>
  );
};

export default VariableItems;
