import VariableActionTypes from '../types/variable.types';
import axios from 'axios'
import { environment } from '../../environment';
import { GetTagCategories } from '../utils/variable.utils';

/* function createData(vID, name, group, tags_info) {
    return { vID, name, group, tags_info };
} */
/* let localVariables = [
    createData(1, 'genZ1', "Gen Z variable", [{ id: 24, name: "GenZ" }, { id: 23, name: "Home Life" }], "", ""),
    createData(2, 'genZ2', "Gen Z variable", [{ id: 24, name: "GenZ" }, { id: 26, name: "Tag3 test" }], "", ""),
    createData(3, 'genZ3', "Gen Z variable", [{ id: 24, name: "GenZ" }, { id: 26, name: "Tag3 test" }], "", ""),
    createData(4, 'genZ4', "Gen Z variable", [{ id: 24, name: "GenZ" }], "", ""),
    createData(5, 'genZ5', "Gen Z variable", [{ id: 24, name: "GenZ" }], "", ""),
    createData(6, 'genZ6', "Gen Z variable", [{ id: 24, name: "GenZ" }], "", ""),
    createData(7, 'hlife1', "home life variable", [{ id: 24, name: "GenZ" }, { id: 23, name: "Home Life" }], "", ""),
    createData(8, 'hlife2', "home life variable", [{ id: 24, name: "GenZ" }, { id: 23, name: "Home Life" }], "", ""),
    createData(9, 'hlife3', "home life variable", [{ id: 23, name: "Home Life" }], "", ""),
    createData(10, 'hlife4', "home life variable", [{ id: 23, name: "Home Life" }], "", ""),
    createData(11, 'hlife5', "home life variable", [{ id: 23, name: "Home Life" }], "", ""),
]; */


export const getVariables = () => async (dispatch) => {
    try {
        let reqBody = {
            workspace_id: localStorage.defaultProjectNo
                ? parseInt(localStorage.defaultProjectNo)
                : "",
            year_info: 0,
            cartType: "analysis",
            isTagInfoRequired: 1
        };
        dispatch({ type: VariableActionTypes.VARIABLE_REQUEST })

        // setTimeout(() => {
        //     dispatch({
        //         type: VariableActionTypes.VARIABLE_LIST_SUCCESS,
        //         payload: [...localVariables],
        //     })
        // }, 1000);
        // return Promise.resolve({ data: [...localVariables] });
        const { data } = await axios.post(`${environment.getCartItem}`, reqBody)

        let tempData = data.response.cart_items ?? [];
        if (tempData.length) tempData = tempData.filter(d => d.name !== "id_")

        dispatch({
            type: VariableActionTypes.VARIABLE_LIST_SUCCESS,
            payload: tempData,
        })

        return Promise.resolve({ data: tempData, variableByCategory: GetTagCategories(tempData) });
    } catch (error) {

        // dispatch({
        //     type: VariableActionTypes.VARIABLE_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject({ error });
    }
}


export const createTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.TAG_REQUEST })
        const { data } = await axios.post(`${environment.createTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.CREATE_TAG_SUCCESS
        })
        return Promise.resolve(data);
    } catch (error) {
        console.error("error", error);
        console.error("error.response", error.response);
        let message = error.response && error.response.data.error_message
            ? error.response.data.error_message
            : error.message;
        // dispatch({
        //     type: VariableActionTypes.TAG_FAIL,
        //     payload: message
        // })
        return Promise.reject(message);

    }
}

export const addToTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.addToTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve();
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject();

    }
}

export const copyToDiffTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.copyToDiffTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve();
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject();

    }
}

export const moveToTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.moveToTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve();
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject();

    }
}

export const removeTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.removeTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve();
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject();

    }
}

export const deleteTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.deleteTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })

    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject();

    }
}



export const shareTag = (reqObj) => async (dispatch) => {
    try {

        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.shareTag}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })

    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject(error);

    }
}

export const getTagCategories = () => ({
    type: VariableActionTypes.GET_TAG_CATEGORIES,
})

// export const addToTag = (items) => ({
//     type: VariableActionTypes.ADD_TO_TAG,
//     payload: items
// });

export const removeFromMyGSS = (reqObj, removeType) => async (dispatch) => {
    try {
        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        let { data } = await axios({
            method: "DELETE",
            url: `${environment.removeFromCart}`,
            data: {
                "cart_items": reqObj,
                "workspace_id": parseInt(localStorage.defaultProjectNo),
                "cartType": removeType === 'extract' ? removeType : 'analysis',
                "remove_all": false
            }
        })
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve({ data: data });
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject(error);

    }
}

export const addToExtract = (reqObj) => async (dispatch) => {
    try {
        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.addToCart}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve({ data: data });
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject(error);

    }
}

export const addToGSS = (reqObj) => async (dispatch) => {
    try {
        dispatch({ type: VariableActionTypes.ADD_TO_TAG_REQUEST })
        const { data } = await axios.post(`${environment.addToCart}`, reqObj)
        dispatch({
            type: VariableActionTypes.ADD_TO_TAG_SUCCESS
        })
        return Promise.resolve({ data: data });
    } catch (error) {
        // dispatch({
        //     type: VariableActionTypes.ADD_TO_TAG_FAIL,
        //     payload:
        //         error.response && error.response.data.message
        //             ? error.response.data.message
        //             : error.message,
        // })
        return Promise.reject(error);

    }
}