import React from 'react'
import { Container } from 'react-bootstrap'
import BigButton from '../UI/BigButton/BigButton'
import './InfoBanner.css'

const InfoBanner = () => {
    return (
        <Container className="info-banner d-flex justify-content-around align-items-center pt-3 pb-3">
            <h4 className="text-white">MY GSS</h4>
            <span className="divider"></span>
            <h4 className="text-white">Create a MyGSS account to access, analyze, and save GSS data.
            </h4>
            <BigButton classname="btn-orange btn-shadow" eleType="link" url="/user/sign_up">CREATE AN ACCOUNT</BigButton>
        </Container>
    )
}

export default InfoBanner
