import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import CustomModal from "../../../../Components/UI/Modal/CustomModal";
import { environment } from "../../../../environment";
import "./EditUserInfo.css";

class EditUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      role: "user",
      user_id: "",
      checkedValue: false,
      error: {
        status: false,
        message: null,
        variant: null,
      },
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  createSelectOptions(values) {
    return values.map((val) => {
      return { value: val, label: val };
    });
  }
  handleChange(e) {
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }
  componentDidMount() {
    if (localStorage.isAdmin != "true") {
      this.props.history.push("/error");
      return;
    }
    if (this.props.data.data) {
      this.setState({
        role: this.props.data.data.role,
        username: this.props.data.data.username,
        email: this.props.data.data.email,
        user_id: this.props.data.data.userId,
        checkedValue: this.props.data.data.role == "admin" ? true : false,
      });
    }
  }
  handleSelectChange(e) {
    let fields = this.state;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  handleCheck(e) {
    this.setState({ checkedValue: e.target.checked });
    if (e.target.checked) {
      this.setState({ role: "admin" });
    } else {
      this.setState({ role: "user" });
    }
  }
  validateForm() {
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Can't be blank.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Can't be blank.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Can't be blank.";
    }
    if (!fields["passwordConfirmation"]) {
      formIsValid = false;
      errors["passwordConfirmation"] = "Can't be blank.";
    }
    if (
      typeof fields["password"] !== "undefined" &&
      typeof fields["passwordConfirmation"] !== "undefined"
    ) {
      if (fields["password"] !== fields["passwordConfirmation"]) {
        formIsValid = false;
        errors["passwordConfirmation"] = "Passwords don't match.";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    let request_json = {
      username: this.state.username,
      email: this.state.email,
      role: this.state.role,
      password: this.state.password,
      allow_email: true,
    };
    Axios.put(`${environment.userDetails + this.state.user_id}`, request_json)
      .then((response) => {
        this.setState({
          password: "",
          passwordConfirmation: "",
        });
        this.props.history.push("/users/" + this.state.user_id);
      })
      .catch((err) => {
        if (err.response) {
          let error = {
            status: true,
            message: err.response.data.message
              ? err.response.data.message
              : "Error Occured",
            variant: "Error",
          };
          this.setState({ error: error });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          let error = {
            status: true,
            message: "Something went wrong !",
            variant: "Error",
          };
          this.setState({ error: error });
          // client never received a response, or request never left
        } else {
          let error = {
            status: true,
            message: "Something went wrong !",
            variant: "Error",
          };
          this.setState({ error: error });
          // anything else
        }
      });
  }

  resetToDefaults() {
    if (
      window.confirm(
        "This will reset your settings to defaults. User account settings (username, email and password) will not be affected. Continue?"
      )
    ) {
      alert("User settings have been reset to defaults.");
    }
  }

  setIsOpen() {
    this.setState(({ error }) => ({
      error: { ...error, status: !this.state.error.status },
    }));
  }

  setClose() {
    //close dialog
  }
  render() {
    return (
      <div>
        <CustomModal
          title={this.state.error.variant}
          isOpen={this.state.error.status}
          setIsOpen={this.setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
          inputLabel={this.state.error.message}
          setClose={this.setClose}
        ></CustomModal>
        <form className="simple_form form-horizontal or-edit" id="edit_user">
          <div className="form" id="maincontent">
            <div className="admin-show" id="mainbar">
              <div className="right or-toolbar">
                <span
                  className="or-toolbar-item or-left-item"
                  onClick={this.resetToDefaults}
                >
                  <a>
                    <i className="fa fa-mail-reply fa-lg"></i>
                    <span className="icon-label">Reset to defaults</span>
                  </a>
                </span>
                <span className="or-toolbar-item">
                  <NavLink className="cancel_link" exact to="/users">
                    <i className="fa fa-ban fa-lg"></i>
                    <span className="icon-label">Cancel</span>
                  </NavLink>
                </span>
                <span className="or-toolbar-item" onClick={this.handleSubmit}>
                  <a className="submit_link" href="#">
                    <i className="fa fa-save fa-lg"></i>
                    <span className="icon-label">Save</span>
                  </a>
                </span>
              </div>
              <div className="stitle">User {this.state.username}</div>
            </div>
            <div className="admin-show" id="maincenter">
              <div className="">
                <div className="form-group string required user_username">
                  <div className="col-sm-3">
                    <label
                      className="string required control-label"
                      htmlFor="username"
                    >
                      <abbr title="required">*</abbr> Username
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      className="string required  form-control"
                      value={this.state.username}
                      type="text"
                      name="username"
                      id="username"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group string required user_username">
                  <div className="col-sm-3">
                    <label
                      className="string required control-label"
                      htmlFor="email"
                    >
                      <abbr title="required">*</abbr>Email
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      className="string required  form-control"
                      value={this.state.email}
                      type="text"
                      name="email"
                      id="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="form-group select required user_role">
                  <div className="col-sm-3">
                    <label
                      className="select required control-label"
                      htmlFor="user_role"
                    >
                      <abbr title="required">*</abbr> Role
                    </label>
                  </div>
                  <div
                    className="col-sm-9"
                    style={{
                      bottom: "25px",
                      left: "56px",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="Admin"
                      onChange={this.handleCheck}
                      checked={this.state.checkedValue}
                    />{" "}
                    &nbsp;
                    <span style={{ top: "6px", position: "absolute" }}>
                      Admin
                    </span>
                  </div>
                </div>
                <div className="form-group string required user_username">
                  <div className="col-sm-3">
                    <label
                      className="string required control-label"
                      htmlFor="password"
                    >
                      Password
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      className="string required  form-control"
                      value={this.state.password}
                      type="password"
                      name="password"
                      id="password"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group string required user_username">
                  <div className="col-sm-3">
                    <label
                      className="string required control-label"
                      htmlFor="passwordConfirmation"
                    >
                      Password Confirmation
                    </label>
                  </div>
                  <div className="col-sm-9">
                    <input
                      className="string required  form-control"
                      value={this.state.passwordConfirmation}
                      type="password"
                      name="passwordConfirmation"
                      id="passwordConfirmation"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.reducer,
  };
}

export default connect(mapStateToProps)(EditUserInfo);
