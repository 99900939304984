import React, { useEffect, useState } from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { Accordion, Card, Button, Dropdown } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import axios from "axios";
import SelectableContext from "react-bootstrap/SelectableContext";
import Loader from "../../../Components/UI/Loader/Loader";
import { environment } from "../../../environment";
import { Checkbox, Tooltip } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { Link, useHistory } from "react-router-dom";
import { setAnalysis, setAnalysisName } from "../../../Store/actions";
import { useDispatch } from "react-redux";

import "./ViewAllTabulations.css";
import { dateTimeFormat } from "../../../Store/utils/common.utils";
import SaveToCloud from "../../../Components/UI/SaveToCloud/SaveToCloud";
import InfoIcon from "@material-ui/icons/Info";

const ViewAllTabulations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [tabulations, setTabulations] = useState({ queue: [], completed: [] });
  const [rows, setRows] = useState(tabulations);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [uploadDataModel, setUploadDataModel] = useState(false);
  const [signedInUser, setSignedInUser] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSelectAllClick = () => {
    let holdData = tabulations.completed;
    if (!selectAll) {
      const newSelecteds = holdData.map((n) => n.function_ID);
      setSelected(newSelecteds);
      setSelectAll(!selectAll);
      return false;
    }
    setSelectAll(!selectAll);
    setSelected([]);
  };

  const getAllData = (callingAgain = null) => {
    setIsLoading(true);
    axios
      .get(environment.getUserTabulations)
      .then((response) => {
        if (response.data) {
          console.log(response.data.final_response);
          setTabulations(response.data.final_response);
          setRows(response.data.final_response);
          setIsLoading(false);
          setError(null);
          // setSelected([])
          setSelectAll(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    getAllData();
    const timeout20 = setTimeout(() => {
      getAllData();
    }, 20000);

    const timeout50 = setTimeout(() => {
      getAllData();
    }, 50000);
    return () => {
      clearTimeout(timeout20);
      clearTimeout(timeout50);
    };
  }, []);

  const onFilter = ({ target: { value } }) => {
    const queueFiltered = tabulations["queue"].filter((obj) => {
      return (
        obj.name.toLowerCase().includes(value.toLowerCase()) ||
        obj.status.toLowerCase().includes(value.toLowerCase())
      );
    });
    const completedFiltered = tabulations["completed"].filter((obj) => {
      return obj.name.toLowerCase().includes(value.toLowerCase());
    });
    setRows({ queue: queueFiltered, completed: completedFiltered });
    setFilterData(value);
  };

  const handleCheck = (e, id) => {
    let selected_data = [...selected];
    if (e.target.checked) {
      selected_data.push(id);
    } else {
      let index = selected_data.indexOf(id);
      selected_data.splice(index, 1);
    }

    setSelected(selected_data);
  };

  const deleteCheckedCompletedData = () => {
    let analysisIds = [...selected];
    if (analysisIds.length > 0) {
      removeAnalysis(analysisIds);
    } else {
      alert("At least 1 Analysis needs to be selected");
    }
  };

  const downloadCheckedCompletedData = () => {
    let analysisIds = [...selected];
    if (analysisIds.length > 0) {
      if (analysisIds.length > 5) {
        alert("Exceeds the maximum 5 selection limit");
        return;
      }
      axios
        .post(environment.exportExcel, {
          analysisIds,
          extractType: "excel",
          user_id: "",
        })
        .then((response) => {
          window.location.href = response.data.objectUrl;
          setSelected([]);
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
          }
        });
    } else {
      alert("At least 1 Analysis needs to be selected");
    }
  };

  const removeAnalysis = (reqPayload) => {
    if (window.confirm("Are you sure you want to remove?")) {
      let analysis_id_list = { ids: reqPayload };

      axios
        .post(environment.removeAnalysis, analysis_id_list)
        .then((response) => {
          if (response.status) {
            getAllData();
          }
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
          }
        });
    }
  };

  const retryAnalysis = (id) => {
    let analysis_id_obj = { retry_flag: true, id };
    axios
      .post(environment.createAnalysis, analysis_id_obj)
      .then((response) => {
        console.log(response);
        if (response.status) {
          getAllData();
        }
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.message);
        }
      });
  };

  const redirectToAnalysisSummary = (e, data) => {
    console.log("redirectToAnalysisSummary called", data);
    onAnalysisClick(data.Function_name);
    data.analysisId = data.function_ID;
    data.creatorName = data.creator_name;
    // dispatch(setAnalysis({ analysis_data: data }));
    history.push(
      `/analysis_summary/${data.function_ID}?creatorId=${data.creatorId}&back=tabulation`
    );
  };

  const onAnalysisClick = (name) => {
    dispatch(setAnalysisName(name));
  };
  return (
    <Container className="mt-4 h-100">
      <Row>
        <Col className="p-0">
          <span className="float-right mr-5">
            <Button
              onClick={getAllData}
              variant="transperent"
              className="px-2 py-1 mygss-export-btn"
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>&nbsp;Refresh
            </Button>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12">
          <AccordionComponent
            title="Queue"
            data={rows.queue}
            isLoading={isLoading}
            error={error}
            onRetry={getAllData}
            removeAnalysis={removeAnalysis}
            retryAnalysis={retryAnalysis}
          />
          <AccordionComponent
            title="Completed"
            data={rows.completed}
            isLoading={isLoading}
            error={error}
            onRetry={getAllData}
            handleCheck={handleCheck}
            selected={selected}
            redirectToAnalysisSummary={redirectToAnalysisSummary}
            handleSelectAllClick={handleSelectAllClick}
            selectAll={selectAll}
            deleteCheckedCompletedData={deleteCheckedCompletedData}
            downloadCheckedCompletedData={downloadCheckedCompletedData}
            setUploadDataModel={setUploadDataModel}
          />
        </Col>
      </Row>
      {uploadDataModel && (
        <SaveToCloud
          modalShow={uploadDataModel}
          selected={selected}
          setSelected={setSelected}
          setModalShow={setUploadDataModel}
          setSignedInUser={setSignedInUser}
          isAnalysis={true}
        />
      )}
    </Container>
  );
};

export default ViewAllTabulations;

function ContextAwareToggle({ eventKey }) {
  const [expand, setExpand] = React.useState(true);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    setExpand(!expand)
  );

  if (expand) {
    return <ExpandMoreIcon onClick={decoratedOnClick} />;
  }
  return <ChevronRightIcon onClick={decoratedOnClick} />;
}

function AccordionComponent(props) {
  const [loader, setLoader] = useState(props.isLoading);
  const [title, setTitle] = useState(props.title);
  const [data, setData] = useState(props.data);
  const [error, setError] = useState(props.error);
  const history = useHistory();

  useEffect(() => {
    setLoader(props.isLoading);
    setTitle(props.title);
    setData(props.data);
    setError(props.error);
  }, [props]);

  const goToAnalysis = () => {
    history.push("/createAnalysis");
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ fontSize: 10 }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  const clickExport = () => {
    if (props.selected.length > 5) {
      alert("Exceeds the maximum 5 selection limit");
    } else {
      props.setUploadDataModel(true);
    }
  };

  return (
    <Row className="ml-1 mt-2">
      <Accordion defaultActiveKey="0" className="w-100 my-3">
        <ContextAwareToggle eventKey="0"></ContextAwareToggle>
        <span className="grid-heading">{title}</span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span className="grid-heading-cnt">{data.length} items</span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {title == "Queue" ? (
          <span className="float-right mr-5">
            <Button
              onClick={goToAnalysis}
              variant="transperent"
              className="px-2 py-1 mygss-export-btn"
            >
              Create Tabulation
            </Button>{" "}
          </span>
        ) : (
          ""
        )}
        {title != "Queue" ? (
          <span className="float-right mr-5">
            <Button
              variant="transperent"
              className="px-2 py-1 mb-1 mygss-export-btn mr-2"
              disabled={!props.selected.length}
              onClick={() => clickExport()}
            >
              <Tooltip
                interactive
                title={
                  <div>
                    Allows you to export your completed tabulations and
                    summaries to third party cloud storage services (Google
                    Drive or Dropbox).
                  </div>
                }
              >
                <InfoIcon
                  style={{ height: "16px", marginRight: "3px" }}
                  fontSize="small"
                  className="plus-icon"
                />
              </Tooltip>
              Export
            </Button>
            <SelectableContext.Provider value={false}>
              <Dropdown className="d-inline-block custom-ddl bp-2">
                <Dropdown.Toggle variant="light" className="mygss-action-btn">
                  <Tooltip
                    interactive
                    title={
                      <div>
                        <strong>Select/Deselect All</strong>
                        <ul>
                          <li>
                            Allows you to select/deselect all the tabulations
                            listed.
                          </li>
                        </ul>
                        <strong>Download</strong>
                        <ul>
                          <li>
                            Allows you to download tabulation/s summary in Excel
                            format.
                          </li>
                        </ul>
                        <strong>Remove from MyGSS</strong>
                        <ul>
                          <li>
                            Allows you to delete tabulation/s from MyGSS
                            dashboard.
                          </li>
                        </ul>
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{ height: "16px", marginRight: "3px" }}
                      fontSize="small"
                      className="plus-icon"
                    />
                  </Tooltip>
                  <span>Actions</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={props.handleSelectAllClick}>
                    {props.selectAll ? `Deselect All` : `Select All`}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={props.downloadCheckedCompletedData}
                    disabled={!props.selected.length}
                  >
                    Download
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={props.deleteCheckedCompletedData}
                    disabled={!props.selected.length}
                  >
                    Remove From MyGSS
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </SelectableContext.Provider>
          </span>
        ) : (
          ""
        )}
        <Accordion.Collapse eventKey="0">
          <Card.Body className="tab-card-body">
            {title == "Queue" ? (
              loader ? (
                <Loader />
              ) : error == "" || error == null ? (
                <>
                  {data.length > 0 && (
                    <TabulationQueueList
                      data={data}
                      removeAnalysis={props.removeAnalysis}
                      retryAnalysis={props.retryAnalysis}
                    />
                  )}
                  {data.length === 0 && (
                    <Col xs="12" md="12" lg="12" className="text-center my-2">
                      <div className="mx-1 p-2 gss-no-data">
                        No analysis in the queue
                      </div>
                    </Col>
                  )}
                </>
              ) : (
                <Container>
                  <Row className="justify-content-md-center">
                    <p>Something went wrong. Try Again!!</p>
                  </Row>
                  <Row className="justify-content-md-center">
                    <button onClick={props.onRetry}>Try Again</button>
                  </Row>
                </Container>
              )
            ) : loader ? (
              <Loader />
            ) : error == "" || error == null ? (
              <>
                {data.length > 0 && (
                  <TabulationCompletedList
                    data={data}
                    handleCheck={props.handleCheck}
                    selected={props.selected}
                    redirectToAnalysisSummary={props.redirectToAnalysisSummary}
                  />
                )}
                {data.length === 0 && (
                  <Col xs="12" md="12" lg="12" className="text-center my-2">
                    <div className="mx-1 p-2 gss-no-data">No analysis</div>
                  </Col>
                )}
              </>
            ) : (
              <Container>
                <Row className="justify-content-md-center">
                  <p>Something went wrong. Try Again!!</p>
                </Row>
                <Row className="justify-content-md-center">
                  <button onClick={props.onRetry}>Try Again</button>
                </Row>
              </Container>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Row>
  );
}

function TabulationQueueList(props) {
  const retry = (analysis_id) => {
    //call retry extracts api to retry queue data
    props.retryAnalysis(analysis_id);
  };

  const remove = (analysis_id) => {
    // console.log("remove row===>", analysis_id);
    //call remove extracts api to remove queue data
    // removeAnalysis([analysis_id]);D
    props.removeAnalysis([analysis_id]);
  };
  return (
    <ListGroup>
      {props.data.map((queue, index) => (
        <Container fluid key={index}>
          <Row>
            <Col
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              xl={0.5}
              className="d-flex align-items-center invisible"
            >
              <Checkbox
                inputProps={{ "aria-labelledby": "enhanced-table-checkbox-1" }}
              />
            </Col>
            <Col>
              <ListGroup.Item className="d-flex justify-content-md-between">
                {queue.status.toLocaleLowerCase() === "failed" ? (
                  <>
                    <span className="tab-queue-list-title">
                      {/* {queue.status != 'Processing' ? <Error style={{color:"red"}}/>: <></>} */}
                      <ErrorIcon
                        className="mr-2 align-top cursor-default"
                        style={{ fontSize: "2rem" }}
                      />
                      <span style={{ display: "inline-block" }}>
                        {queue.Function_name}
                        <br />
                        <span className="tab-err-msg">{queue.error}</span>
                      </span>
                    </span>

                    <span className="d-flex align-items-center">
                      <a
                        className="tabulations-actions"
                        onClick={() => retry(queue.function_ID)}
                      >
                        Retry
                      </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        className="tabulations-actions"
                        onClick={() => remove(queue.function_ID)}
                      >
                        Remove
                      </a>
                    </span>
                  </>
                ) : queue.status.toLocaleLowerCase() === "submitted" ||
                  queue.status.toLocaleLowerCase() === "started" ? (
                  <>
                    <span>
                      <AutorenewIcon
                        style={{
                          backgroundColor: "#B98A13",
                          borderRadius: "50%",
                          color: "white",
                          fontSize: "1.9rem",
                        }}
                        className="mr-2 cursor-default"
                      />{" "}
                      {queue.Function_name}
                    </span>
                    <span className="tabulations-actions-process d-flex align-items-center">
                      Processing
                    </span>
                  </>
                ) : (
                  <span>{queue.Function_name}</span>
                )}
              </ListGroup.Item>
            </Col>
          </Row>
        </Container>
      ))}
    </ListGroup>
  );
}

function TabulationCompletedList(props) {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <ListGroup>
      {/* <p
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          border: "1px solid rgba(0, 0, 0, .125)",
          padding: "10px",
          margin: "0 15px 10px",
        }}
      >
        <abbr>Note: </abbr> Crosstabulations in the GSS Data Explorer that are
        constructed using continuous variables that are mostly or entirely
        unlabeled (e.g. those with numeric values, such as AGE, RANK, and
        WORDSUM) are not working correctly. Labels are being assigned
        alphabetically rather than based on values, with label-less values
        placed before labels with values (e.g. 2 before .d “Don’t know, or 89
        “89 or older” after .i “inapplicable”). Do not use such crosstabs from
        the tabulation tool without looking at the associate variable page to
        gauge the extent of this error on your findings. We expect this issue to
        be resolved by 9/13/2022.
      </p> */}
      {data.map((completed, index) => (
        <Container fluid key={index}>
          <Row>
            <Col
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              xl={0.5}
              className="d-flex align-items-center"
            >
              <Checkbox
                name="tabulation_completed"
                value={completed.function_ID}
                // defaultChecked={completed.isSelected}
                checked={props.selected.indexOf(completed.function_ID) !== -1}
                onChange={(e) => props.handleCheck(e, completed.function_ID)}
              />
            </Col>
            <Col>
              <ListGroup.Item className="tab-list-group-item">
                <span
                  className="tab-list-title"
                  onClick={(e) => props.redirectToAnalysisSummary(e, completed)}
                >
                  {completed.Function_name}
                </span>
                <br />
                <span className="tab-list-desc">
                  Created on {dateTimeFormat(completed.created_at)} by{" "}
                  {completed.creator_name}
                </span>
              </ListGroup.Item>
            </Col>
          </Row>
        </Container>
      ))}
    </ListGroup>
  );
}
