import { Auth } from "aws-amplify";
import Axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import { environment } from "../../../environment";
import { NavLink } from "react-router-dom";
import { checkIsValidEmail } from "../../../Shared/helpers";
import ConfirmCode from "./ConfirmCode/ConfirmCode";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import "./ResendConfirm.css";

function ResendConfirm(props) {
  const firstRender = useRef(true);
  const [disable, setDisabled] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [holdEmail, setHoldEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });
  const checkDisabled = () => {
    if (!(emailError == "")) return false;

    return true;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setDisabled(!checkDisabled());
  }, [emailError]);

  useEffect(() => {
    if (username != "") {
      Auth.resendSignUp(username)
        .then((data) => {
          setIsConfirm(true);
        })
        .catch((err) => {
          setIsConfirm(false);
          if (err.response) {
            //alert(err.response.data.message);
            setIsServerError({
              ...isServerError,
              status: true,
              message: err.response.data.message,
              variant: "Error",
            });
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            setIsServerError({
              ...isServerError,
              status: true,
              message: "Something went wrong. Try Again!!",
              variant: "Error",
            });
            // client never received a response, or request never left
          } else {
            setIsServerError({
              ...isServerError,
              status: true,
              message: "Something went wrong. Try Again!!",
              variant: "Error",
            });
            // anything else
          }
        });
    }
  }, [username]);

  const resendConfirmationCode = (e) => {
    e.preventDefault();
    setDisabled(true);
    setHoldEmail(email);
    isValidUser();
  };

  const isValidUser = () => {
    Axios({
      method: "GET",
      url: environment.getUserByEmail,
      params: {
        email: email,
      },
    })
      .then((response) => {
        setDisabled(true);
        setEmail("");
        setEmailError(null);
        if (
          response.data.user_status == "CONFIRMED" ||
          response.data.user_status == "FORCE_CHANGE_PASSWORD" ||
          response.data.user_status == "EXTERNAL_PROVIDER"
        ) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "User already Confirmed",
            variant: "Error",
          });
        } else {
          if (response.data.exists) {
            setUsername(response.data.username);
          }
        }
      })
      .catch((err) => {
        setDisabled(false);
        if (err.response) {
          //alert(err.response.data.message);
          setEmailError(err.response.data.message);
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };

  const handleChange = async (e) => {
    setEmail(e.target.value);
    let emailError = await checkIsValidEmail(e.target.name, e.target.value);
    if (emailError.errorStatus) {
      setEmailError(emailError.errorMessage);
    } else {
      setEmailError(emailError.errorMessage);
    }
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };
  const setClose = () => {
    //closing dialog
  };
  if (isConfirm) {
    let fields = { username: username.trim(), email: holdEmail };
    return <ConfirmCode field={fields} requestFrom={""} />;
  } else {
    return (
      <>
        <div className="mb-3">
          <CustomModal
            title={isServerError.variant}
            isOpen={isServerError.status}
            setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
            inputLabel={isServerError.message}
            setClose={setClose}
          ></CustomModal>
          <span className="standard-text create-account-container">
            <NavLink className="create-account-text" to="/user/sign_up">
              Don't have an account? Create one here
            </NavLink>
          </span>
          <FormCard title="Request Code">
            <form
              className="simple_form form-horizontal"
              name="userRequestCodeForm"
              onSubmit={resendConfirmationCode}
              noValidate
            >
              <div className="row justify-content-md-center ">
                <div className="col-auto">
                  <p className="a-content">
                    Enter email address to receive a code to complete your
                    registration.
                  </p>
                </div>
              </div>
              <div className="row justify-content-md-center customize-username-height">
                <div className="col-auto customize-label">
                  <label htmlFor="email" className="col-form-label">
                    Email Address
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="form-control customize-input"
                    aria-describedby="email"
                    value={email}
                    onChange={handleChange}
                    autoComplete="off"
                    autoFocus
                  />
                  {emailError ? (
                    <div className="errorMsg">{emailError}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-auto">
                  <button
                    type="submit"
                    className="gss-btn btn-orange mt-2"
                    variant="secondary"
                    disabled={disable}
                  >
                    RESEND CODE
                  </button>
                </div>
              </div>
            </form>
          </FormCard>
        </div>
      </>
    );
  }
}

export default ResendConfirm;
