import React, { useState, useEffect } from 'react'
import {
    Table, TableBody, TableCell, TableContainer,
    TableRow,
    Checkbox,
    Chip
} from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import "./EnhancedTableCheckbox.css";
import { useHistory } from 'react-router-dom';
import { defaultVariables } from '../../../Store/utils/variable.utils';

const EnhancedTableCheckbox = ({ rows, selected, rowsPerPage, selectedObj,
    page, showPagination, disableCheckBox, varList, ...props }) => {

    const [varData, setVarData] = useState([...rows])
    const history = useHistory();

    useEffect(() => {
        setVarData([...rows])
    }, [rows])

    const stableSort = (array) => {
        if (showPagination) {
            const stabilizedThis = array.map((el, index) => [el, index]);

            return stabilizedThis.map((el) => el[0]).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        } else {
            return array;
        }

    }

    const addItemToArr = (newSelected) => {
        return rows.filter(d => (newSelected.indexOf(d.vID) !== -1));
    }

    const removeItemFromArr = (newSelected) => {
        return selectedObj.filter(d => (newSelected.indexOf(d.vID) == -1));
    }


    const handleClick = (event, row) => {

        const selectedIndex = selected.indexOf(row.vID);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.vID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        let newSelectedObj = [];
        if (newSelected.length > selected.length) {
            newSelectedObj = addItemToArr(newSelected.filter(x => !selected.includes(x)));
            props.setSelectedObj([...selectedObj, ...newSelectedObj]);
        } else {
            newSelectedObj = removeItemFromArr(selected.filter(x => !newSelected.includes(x)))
            props.setSelectedObj(newSelectedObj);
        }

        props.setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const setRemoveSelected = (event, row, removeType) => {
        event.stopPropagation();
        if (defaultVariables.indexOf(row.name) !== -1 && removeType !='extract') {
            alert("Default variables can't be removed")
            return;
        }
        props.setRemoveSelected([row])
        props.removeFromMyGSS(removeType, row.vID)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    /* const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage); */

    const styles = theme => ({
        tableRow: {
            "&$hover:hover": {
                backgroundColor: "blue"
            }
        },
        tableCell: {
            "$hover:hover &": {
                color: "pink"
            }
        },
        hover: {}
    });

    const toggleTags = (e, id) => {
        e.stopPropagation();

        let filterData = varData.map(d => {
            if (d.vID === id) {
                d.expanded = !d.expanded;
            }
            return d;
        })
        setVarData(filterData)
    }

    const variableDetails = (e, id) => {
        if (!disableCheckBox) {
            e.stopPropagation();
            history.push(`/variables/${id}/vshow?back=variableList`)
        }
    }

    return (
        <>
            <TableContainer>
                <Table
                    className={`main-root ${props.dClassName} custom-table`}
                    aria-labelledby="tableTitle"
                    size={'small'}
                    aria-label="enhanced table"

                >

                    <TableBody>
                        {stableSort(varData)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.vID);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                let randnum = Math.floor(Math.random() * 100);
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.vID + '-' + randnum}
                                        selected={isItemSelected}
                                        classes={{ hover: styles.hover }}
                                        className={styles.tableRow}
                                    >
                                        <TableCell className={styles.tableCell} padding="checkbox">
                                            <Checkbox
                                                disabled={disableCheckBox}
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row" padding="none" width="10%">
                                            <span className="tag-table-header" onClick={(e) => variableDetails(e, row.vID)}>
                                                {row.name}
                                            </span>
                                        </TableCell>
                                        <TableCell width="30%">{row.label}</TableCell>
                                        <TableCell width="35%">
                                            {/* {row.tags_info.map((s, i) => <Chip key={s.id} className={(i > 0) ? "ht-20 ml-1 tag-item" : "ht-20 tag-item show"} label={s.name} variant="outlined" />)} */}

                                            <div className="d-flex">
                                                <div className="var-label">
                                                    {(row.expanded) ?
                                                        (row.tags_info.map((s) => (
                                                            <Chip
                                                                key={s.id}
                                                                className="ht-20 ml-2"
                                                                label={s.name}
                                                                variant="outlined"
                                                                title={s.name}
                                                            />
                                                        )))
                                                        :
                                                        (row.tags_info.slice(0, 3).map((s) => (
                                                            <Chip
                                                                key={s.id}
                                                                className="ht-20 ml-2"
                                                                label={s.name}
                                                                variant="outlined"
                                                                title={s.name}
                                                            />
                                                        )))
                                                    }
                                                </div>
                                                <div className="ml-2">
                                                    {(row.tags_info.length > 3) ?
                                                        (<span className="list-group-item rounded-0 border-0 p-0 m-0 text-nowrap tag-trans">
                                                            <button className={"button-to-link"} onClick={(e) => toggleTags(e, row.vID)}>{(row.expanded) ? "See less" : "+" + (row.tags_info.length - 3) + " More"}</button>
                                                        </span>) : ""}
                                                </div>
                                            </div>

                                        </TableCell>
                                        <TableCell align="right" width="5%">{``}</TableCell>
                                        <TableCell align="right" width="5%">{``}</TableCell>
                                        <TableCell align="right" width="10%">
                                            {((varList && row.isAlreadyInExtract) ? (
                                                <span className="d-flex flex-column align-items-center">
                                                    <span
                                                        className="var-add-extract px-2 mb-2 d-flex align-items-center justify-content-evenly">
                                                        <CheckIcon fontSize="small" />Added to Extract
                                                    </span>
                                                    <button 
                                                    className="tag-action-btn text-nowrap text-10" 
                                                    onClick={(e) => setRemoveSelected(e, row, 'extract')}
                                                    disabled={selected?.length > 1}
                                                    >
                                                        
                                                        Remove From Extract</button>
                                                </span>
                                            ) : "")}
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 33 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {
                (showPagination) ? <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                /> : ""
            }

        </>
    )
}


EnhancedTableCheckbox.defaultProps = {
    varList: false,
    showPagination: true,
    disableCheckBox: false,
    dClassName: "",
    selectedObj: [],
    setSelected: () => "",
    setSelectedObj: () => "",
    setRowsPerPage: () => "",
    setPage: () => "",
    setRemoveSelected: () => "",
    removeFromMyGSS: () => "",
};

export default EnhancedTableCheckbox
