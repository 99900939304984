import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Chip, FormControl, NativeSelect, Tooltip } from "@material-ui/core";
import axios from "axios";
import { environment } from "../../../environment";
import CartItem from "../../../Components/UI/CartItem/CartItem";

import "./ExtractCartList.css";



const ExtractCartList = (props) => {

    const [searchText, setSearchText] = useState('');
    const [variablesList, setVariablesList] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getCartListData()
    }, [])

    const getCartListData = () => {
        if (localStorage.defaultProjectNo) {
            let reqBody = {
                workspace_id: localStorage.defaultProjectNo
                    ? parseInt(localStorage.defaultProjectNo)
                    : "",
                year_info: 0,
                cartType: 'extract'
            };
            axios.post(`${environment.getCartItem}`, reqBody)
                .then((resp) => {
                    if (resp.data.response.result != "error") {
                        let cartItemsArr = [...resp.data.response.cart_items];
                        setVariablesList(cartItemsArr ?? []);
                    }
            })
                .catch((error) => {
                    //props.history.push("/error");
                    console.log(error);
                });
        }

    };


    const goToExtract = (e) => {
        props.setExpanded(!props.expanded)
        history.push("/createExtract");
    
    }


    const cartItemAction = (event, variableId) => {
        let tempList = [...variablesList];
        let index = tempList.findIndex((item) => item.vID == variableId);
        tempList[index].isChecked = event.target.checked;
        setVariablesList(tempList);
    }

    const toggleTags = (id) => {
        let filterData = variablesList.map(d => {
            if (d.vID === id) {
                d.expanded = !d.expanded;
            }
            return d;
        })

        setVariablesList(filterData)
    }


    return (
        <div>
            <div className="cart var-drag">
            <div className="cart-footer">
                <span  className='cursor-pointer-hand' onClick={goToExtract}>Create Extract</span>
            </div>
                <div className="tab-section-2">
                    <span className="completed-tabulation"> Extract Variables ({variablesList.length})</span>
                </div>
                <hr />
                <div className="search p-2">
                    <input
                        type="text"
                        name="search_cart"
                        id="search_cart"
                        className="search_cart"
                        value={searchText}
                        placeholder={"Search through variables"}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                    /><span className="fa fa-search"></span>

                </div>
                <div className="extract-list-section">
                    <div className="tab-extract-list">
                        <div className="contents">
                            <ul className="drag-item">
                                {variablesList.filter(obj => (searchText == '') || (obj &&
                                    obj.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || obj.orignlName.toLowerCase().indexOf(searchText.toLowerCase()) > -1)).map((data, index) => (
                                        <React.Fragment>
                                            <CartItem toggleTags={toggleTags} cartItemAction={cartItemAction} data={data}  index={index} />
                                        </React.Fragment>
                                    ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
           
        </div>
    );
}

export default ExtractCartList;
