import * as React from "react";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import { environment } from "../../../environment";
import { useEffect } from "react";
import ReportsGrid from "./ReportsGrid";
import { useContext } from "react";
import ReportsContext from "../../../Store/ContextApi/ReportsContext";

export default function ReportsTabs(props) {
  const [categoryList, setCategoryList] = useState([]);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });

  useEffect(() => {
    getCategories();
  }, []);

  const { value1 } = useContext(ReportsContext);
  const [inputs, setInputs] = value1;
  const [value, setValue] = useState(inputs.category ? inputs.category : 1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCategories = () => {
    axios
      .get(`${environment.getMediaCategories}`)
      .then((response) => {
        if (response.data) {
          setCategoryList(response.data.category_list);
        }
      })
      .catch((err) => {
        if (err.response) {
          //alert(err.response.data.message);
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // alert('Some Error Occurred');
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // client never received a response, or request never left
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // anything else
        }
      });
  };

  const refreshFlag = () => {
    props.handleChild();
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="gss-reports-tab"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
          >
            {categoryList.map((category) => (
              <Tab label={category.category_name} value={category.id} />
            ))}
          </Tabs>
        </Box>
        {categoryList.map((category) => (
          <TabPanel value={category.id}>
            <ReportsGrid
              categoryId={category.id}
              isUpdateGrid={props.isUpdateGrid}
              refreshFlag={refreshFlag}
            />
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}