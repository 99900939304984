// import React from "react";
// import { Card } from 'react-bootstrap'

// function Tabulations() {
//     return (
//         <Card style={{textAlign:"center"}} className='my-1 p-3 rounded border-0'>
//             <Card.Body>
//                 <Card.Text>
//                     <h3>Page is under Construction</h3>
//                 </Card.Text>
//             </Card.Body>
//         </Card>
//     )
// }

// export default Tabulations;

import React from "react";
import { Col, Row } from "react-bootstrap";
//import BigButton from "../../Components/UI/BigButton/BigButton";
import BigButton from "../../Components/UI/BigButton/BigButton";
//import "./MediaRoom.css";
import "./Tabulations.css";
import { NavLink } from "react-router-dom";
import Tabulation_1 from "../../Images/Tabulation_1.png";
import Tabulation_2 from "../../Images/Tabulation_2.png";
import Tabulation_3 from "../../Images/Tabulation_3.png";
import Tabulation_4 from "../../Images/Tabulation_4.png";
import Tabulation_5 from "../../Images/Tabulation_5.jpg";
import Tabulation_6 from "../../Images/Tabulation_6.png";

const Tabulations = () => {
  /* const [data, setData] = useState([]); */

  return (
    <>
      <div className="gss-Tabulations">
        {" "}
        <React.Fragment>
          <div>
            <div className="gss-large-title unaligned_title">
              Create Tabulations
            </div>
          </div>
        </React.Fragment>
        <div className="my-4 mx-5 ">
          <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
              <div className="Container">
                <p>
                  GSS Data Explorer helps you tabulate data with an easy-to-use
                  interface. Create tabulations with your chosen variables and
                  case selection. The analysis program applies default weighting
                  for the variables you include in your cross-tabulation but
                  gives you the option to select another available weighting
                  variable, if preferred.
                </p>
              </div>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Tabulations_circle">1</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Select the green “Tabulations” menu on the upper right and
                      click “Create New Tabulation.” You can also start a new
                      tabulation from the Tabulations tab in your MyGSS
                      dashboard.
                    </p>
                    <div>
                      <img
                        src={Tabulation_1}
                        className="Tabulations_media"
                        alt="Tabulation1"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Tabulations_circle">2</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                    Select the type of tabulation you would like to perform; use Cross Tabulation for a simple comparison of two variables, or Multi-Level Tabulation for an analysis with up to 5 column variables.
                    </p>
                    <div>
                      <img
                        src={Tabulation_2}
                        className="Tabulations_media"
                        alt="Tabulation_2"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Tabulations_circle">3</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>Customize your tabulation.</p>
                    <ul class="a">
                      <li>
                        Add a descriptive name and (for multi-level tabulation)
                        select the years you would like your analysis to
                        include.
                      </li>
                      <li>
                        Then drag the variables you would like to analyze from
                        the list on the right to the “row” or “column” field.
                      </li>
                      <li>
                        Case selection (optional): If you would like to limit
                        your tabulation to a subset, drag one or more variables
                        to the “Case Selection” field and use the checkboxes to
                        choose which values will be included, or enter a range.
                      </li>
                      <li>
                        {" "}
                        Weighting: The weight variable WTSSCOMP is selected by
                        default, as this works for most samples and domains of
                        interest, but you can select different weights. Please
                        see the{" "}
                        <NavLink className="green-link" to="/gssweighting">
                          {" "}
                          Weighting Help
                        </NavLink>{" "}
                        page for more detailed guidance on which weights to use
                        to analyze the full range of GSS samples.
                      </li>
                    </ul>
                    <div>
                      <img
                        src={Tabulation_3}
                        className="Tabulations_media"
                        alt="Tabulation_3"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Tabulations_circle">4</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Click the “Create Tabulation” button to trigger your
                      tabulation to begin processing. Note that it may take some
                      time to complete processing.
                    </p>
                    <div>
                      <img
                        src={Tabulation_4}
                        className="Tabulations_media"
                        alt="Tabulation_4"
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Tabulations_circle">5</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      As the tabulation processes, you can press refresh to see
                      if it is finished processing yet. Once finished, the
                      tabulation will move down to your “Completed tabulation”
                      section (as shown in step 5). You may need to click
                      “Refresh” button as shown for the tabulation to finish
                      processing.
                    </p>
                    <div>
                      <img
                        src={Tabulation_5}
                        className="Tabulations_media"
                        alt="Tabulation_5"
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Tabulations_circle">6</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Your completed tabulations will be viewable at any time in
                      the MyGSS Tabulations tab. You can download completed
                      tabulations as an Excel file (use the Actions drop-down
                      menu option) or export them to Google Drive or Dropbox, as
                      shown below.
                    </p>
                    <div>
                      <img
                        src={Tabulation_6}
                        className="Tabulations_media"
                        alt="Tabulation_6"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} lg={4} xl={4}>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and
                  how to use the data.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100">
                  <a style={{ color: "white" }} href="/FAQ">
                    VIEW FAQ
                  </a>
                </BigButton>
              </div>

              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>Submit your question or issue to the experts at GSS.</p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact">ASK AN EXPERT</BigButton>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Tabulations;
