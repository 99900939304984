import React, { useEffect, useState } from "react";
import "./BallotInfo.css";
import { Tooltip } from "@material-ui/core";
export default function BallotInfo(props) {
  const [isVarDetailPage, setIsVarDetailPage] = useState(props.isVarDetailPage);
  /*  constructor(props) {
     super(props);
     this.state = {
       years: [],
       isVarDetailPage: props.isVarDetailPage,
       yearsRange: props.yearsRange
     };
   } */
  useEffect(() => { 
  }, []);
  /* componentDidMount() {
    console.log()
    this.setState({ years: this.props.years })
  } */

  return (
    <React.Fragment>
      {props?.years.map((yearInfo, index) => {
        if (props.isRangeSelected == undefined ||
          props.isRangeSelected && props.yearsRange == undefined ||
          !props.isRangeSelected && props.selectedYears == undefined ||
          (props.isRangeSelected && props.yearsRange && yearInfo.year >= props.yearsRange[0] && yearInfo.year <= props.yearsRange[1]) || // For year Range
          (!props.isRangeSelected && props.selectedYears && props.selectedYears.indexOf(yearInfo.year) > -1)) { //Select Specific year
          return (<Tooltip
            title={
              <div>
                <div className="text-center">{yearInfo.year}</div>
                {yearInfo.ballotInfo.map((info) => (
                  <div>
                    <span>Ballot {info}</span>
                  </div>
                ))}
                {yearInfo.ballotInfo.length === 0 &&
                  yearInfo.isQuestionAvailable != "unavailable" && (
                    <div>No ballot Information.</div>
                  )}
              </div>
            }
          >

            <div className="ballot-strips-parent">
              <div
                className={
                  "ballot-strips" +
                  (yearInfo.isQuestionAvailable == "full"
                    ? " bg-green"
                    : yearInfo.isQuestionAvailable == "unavailable"
                      ? " bg-lgray"
                      : " bg-partial") + (isVarDetailPage ? " ballot-strips-big" : " ballot-strips-small")
                }
              ></div>
              {isVarDetailPage && (
                <div>{yearInfo.year.toString().slice(2)}</div>
              )}
            </div>


          </Tooltip>)
        }
      })}
    </React.Fragment>
  );

}
