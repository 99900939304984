import React from "react";
import { Col, Row } from "react-bootstrap";
//import BigButton from "../../Components/UI/BigButton/BigButton";
import BigButton from "../../../Components/UI/BigButton/BigButton";
//import "./MediaRoom.css";
import "./ViewTrends.css"
import trend_1 from "../../../Images/view_trends_1.png";
import trend_2 from "../../../Images/view_trends_2.png";
import trend_3 from "../../../Images/view_trends_3.jpg";
import trend_3_1 from "../../../Images/view_trends_3.1.jpg";
import trend_4 from "../../../Images/view_trends_4.png";
import trend_5 from "../../../Images/view_trends_5.png";
import trend_5_1 from "../../../Images/view_trends_5.1.png";
import trend_6 from "../../../Images/view_trends_6.png";


const ViewTrends = () => {
    /* const [data, setData] = useState([]); */

    return (
        <>
            <div className="gss-trends">
                {" "}
                <React.Fragment>
                    <div>
                        <div className="gss-large-title unaligned_title">View Trends</div>
                    </div>

                </React.Fragment>

                <div className="my-4 mx-5 ">
                    <Row>

                        <Col sm={12} md={8} lg={8} xl={8}>
                            <div className="Container">
                                <p >With the dynamic visualization feature, you may create a graphic to view key trends and user responses over time. You may then share, print, or export your visualizations.
                                </p>
                            </div>

                            <Row>
                                <Col sm={12} md={1} lg={1} xl={1}>
                                    <div>
                                        <span className="trends_circle">1</span>
                                    </div>
                                </Col>
                                <Col sm={12} md={11} lg={11} xl={11}>
                                    <div >
                                        <p className = "trends_steps"> Navigate to Key Trends main page</p>
                                        <p>First, from the home page, select the “View Trends” or “Key Trends” buttons. This will direct you to a list of five different modules and subtopics within those modules available for visualization.
                                        </p>
                                        <div>
                                            <img src={trend_1} className="trends_media" alt="trend1"/>
                                        </div>
                                    </div>
                                </Col>



                            </Row>
                            <Row>
                                <Col sm={12} md={1} lg={1} xl={1}>
                                    <div>
                                        <span className="trends_circle">2</span>
                                    </div>
                                </Col>
                                <Col sm={12} md={11} lg={11} xl={11}>
                                    <div >
                                        <p className = "trends_steps">Select a Module</p>

                                        <p>
                                        Decide which module or topic is of interest. Click on a module subtopic.
                                        </p>
                                        <div>
                                            <img src={trend_2} className="trends_media" alt="trend_2"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={1} lg={1} xl={1}>
                                    <div>
                                        <span className="trends_circle">3</span>
                                    </div>
                                </Col>
                                <Col sm={12} md={11} lg={11} xl={11}>
                                    <div>
                                        <p className = "trends_steps"> Choose your variable of interest</p>

                                        <p>
                                        Once you choose your subtopic, you will be able to choose your variable of interest by clicking on the drop down menu to the right of the variable summary. 
                                        </p>
                                        <div>
                                            <img src={trend_3} className="trends_media" alt="trend_3"/>
                                        </div>
                                        <p>Using this drown down, you can locate different variables that all belong to the same topic and subtopic.</p>
                                        
                                        <div>
                                            <img src={trend_3_1} className="trends_media" alt="trend_3_1"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={1} lg={1} xl={1}>
                                    <div>
                                        <span className="trends_circle">4</span>
                                    </div>
                                </Col>
                                <Col sm={12} md={11} lg={11} xl={11}>
                                    <div >
                                        <p className = "trends_steps">View Various Breakdowns</p>

                                        <p>
                                        From here, use the drop-down menus to select the question of interest. You may also select how you would like your responses to be broken down. For example, you could view the number of Democrats, Republicans, and Independents that stated that national spending on law enforcement was "too little" over time. At any time, you may change the question, question response, or respondent breakdown to view different trends. 
                                        </p>
                                        <div>
                                            <img src={trend_4} className="trends_media" alt="trend_4"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} md={1} lg={1} xl={1}>
                                    <div>
                                        <span className="trends_circle">5</span>
                                    </div>
                                </Col>
                                <Col sm={12} md={11} lg={11} xl={11}>
                                    <div >
                                        <p className = "trends_steps">Learn About the Selected Trend Variable</p>

                                        <p>
                                        To understand more about the variables you have selected, view the “About This Trend” section which details a variable’s question text and allows you to select the variable for detailed summary statistics.
                                        </p>
                                        <div>
                                            <img src={trend_5} className="trends_media" alt="trend_5"/>
                                        </div>
                                        <p>For detail on how variables were recoded or weighted to create the visualization tables, view the “Notes” section at the bottom of the page.</p>
                                        <div>
                                            <img src={trend_5_1} className="trends_media" alt="trend_5_1"/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm={12} md={1} lg={1} xl={1}>
                                    <div>
                                        <span className="trends_circle" >6</span>
                                    </div>
                                </Col>
                                <Col sm={12} md={11} lg={11} xl={11}>
                                    <div >
                                        <p className = "trends_steps">View, Print, or Export the Trend Chart or Table</p>

                                        <p>
                                        Finally, once you have created your visualization, you may share, print, or export it using the buttons above your graphic. Additionally, you may select the “Table” button to view the variables you have selected in table, rather than graphic form.
                                        </p>
                                        <div>
                                            <img src={trend_6} className="trends_media" alt=""/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>                       
                        </Col>

                        <Col sm={12} md={4} lg={4} xl={4}>

                            <div className="pt-5 pl-4 pb-4 pr-4 slide">
                                <h5>Can’t find what you’re looking for?</h5>
                                <p>
                                    Check out our FAQ Page for more information about the GSS and how to use the data.
                                </p>
                                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"><a style={{color:"white"}}  href="/FAQ">VIEW FAQ</a></BigButton>
                            </div>

                            <div className="pt-5 pl-4 pb-4 pr-4 slide">
                                <h5>Still need help?</h5>
                                <p>
                                    Submit your question or issue to the experts at GSS.
                                </p>
                                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact">ASK AN EXPERT</BigButton>
                            </div>


                        </Col>
                    </Row>

                </div>


            </div>

        </>
    );
};

export default ViewTrends;
