import { FormControlLabel, Tooltip } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { environment } from "../../../environment";
import ReCAPTCHA from "react-google-recaptcha";
import "./Registration.css";
import { Auth } from "aws-amplify";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import { NavLink } from "react-router-dom";
import {
  checkIsRequired,
  checkIsAllLetter,
  checkIsValidEmail,
  checkPasswordLength,
  checkPasswordCombination,
  checkSpace,
} from "../../../Shared/helpers";
import OrganizationDDL from "../../../Components/UI/OrganizationDDL/OrganizationDDL";
import DepratmentDDL from "../../../Components/UI/DepratmentDDL/DepratmentDDL";
import Modal from "react-bootstrap/Modal";
import ConfirmCode from "../Confirmation/ConfirmCode/ConfirmCode";
import SocialLoginButton from "../../../Components/UI/SociaLoginButton/SocialLoginButton";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { GreenCheckbox } from "../../../Store/utils/common.utils";
import InfoIcon from "@material-ui/icons/Info";

export default function Registration(props) {
  const firstRender = useRef(true);
  const recaptchaRef = React.createRef();
  const [isCaptcha, setCaptcha] = useState(false);
  const [disable, setDisabled] = useState(true);
  const [fields, setFields] = useState({
    username: "",
    firstname: "",
    lastName: "",
    email: "",
    organization: "",
    otherOrganization: "",
    department: "",
    otherDepartment: "",
    password: "",
    passwordConfirmation: "",
    role: "user",
    confirmationCode: "",
    shareYourTags: true,
    optCommunication: true,
    recaptchaToken: "",
    verified: false,
    resendConfirm: false,
  });
  const [errors, setErrors] = useState({
    firstname: null,
    username: null,
    email: null,
    password: null,
    confirmPassword: null,
    recaptcha: null,
  });
  const [show, setShow] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
  });
  
  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(() => {
      recaptchaRef.current.reset();
  }, []);
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]:
        name == "shareYourTags" || name == "optCommunication"
          ? e.target.checked
          : value,
    });
    let validationError = {};
    if (name == "firstname" || name == "username") {
      validationError = await checkIsRequired(name, value);
    }

    switch (name) {
      case "firstname":
        if (!validationError.errorStatus) {
          validationError = await checkIsAllLetter(name, value);
        }
        break;
      case "username":
        if (!validationError.errorStatus) {
          validationError = await checkSpace(name, value);
        }
        break;
      case "email":
        validationError = await checkIsValidEmail(name, value);
        break;
      case "password":
        let passwordError = await checkIsRequired(name, value);
        if (passwordError.errorStatus) {
          setErrors({ ...errors, password: passwordError.errorMessage });
        } else {
          passwordError = await checkPasswordLength(name, value);
          if (passwordError.errorStatus) {
            setErrors({ ...errors, password: passwordError.errorMessage });
          } else {
            passwordError = await checkPasswordCombination(name, value);
            if (passwordError.errorStatus) {
              setErrors({ ...errors, password: passwordError.errorMessage });
            } else {
              if (
                fields.passwordConfirmation !== "" &&
                fields.passwordConfirmation !== undefined
              ) {
                if (value === fields.passwordConfirmation) {
                  setErrors({
                    ...errors,
                    password: passwordError.errorMessage,
                    confirmPassword: passwordError.errorMessage,
                  });
                } else {
                  setErrors({
                    ...errors,
                    password: passwordError.errorMessage,
                    confirmPassword:
                      "Passwords do not match, Please try again.",
                  });
                }
              } else {
                setErrors({ ...errors, password: passwordError.errorMessage });
              }
            }
          }
        }
        break;
      case "passwordConfirmation":
        let confirmPasswordError = await checkIsRequired(name, value);
        if (confirmPasswordError.errorStatus) {
          setErrors({
            ...errors,
            confirmPassword: confirmPasswordError.errorMessage,
          });
        } else {
          if (fields.password !== "" && fields.password !== undefined) {
            if (value === fields.password) {
              setErrors({
                ...errors,
                confirmPassword: confirmPasswordError.errorMessage,
              });
            } else {
              setErrors({
                ...errors,
                confirmPassword: "Password do not match, Please try again",
              });
            }
          } else {
            setErrors({
              ...errors,
              confirmPassword: confirmPasswordError.errorMessage,
            });
          }
        }
        break;
      default:
        break;
    }

    if (name == "username" || name == "email" || name == "firstname") {
      setErrors({ ...errors, [name]: validationError.errorMessage });
    }
  };

  const signUp = () => {
    Auth.signUp({
      username: fields.username.trim(),
      password: fields.password,

      attributes: {
        email: fields.email.trim(),
        name: fields.firstname,
        family_name: fields.lastName,
        "custom:organization":
          fields.organization == "Other"
            ? fields.otherOrganization
            : fields.organization,
        "custom:departments":
          fields.department == "Other"
            ? fields.otherDepartment
            : fields.department,
        "custom:role": "user",
        "custom:shareTag": String(fields.shareYourTags),
        "custom:communicationMail": String(fields.optCommunication),
      },
      validationData: {
        recaptchaToken: fields.recaptchaToken,
      },
    })
      .then((response) => {
        setIsSaving(false);
        setDisabled(true);
        setErrors({
          firstname: null,
          username: null,
          email: null,
          password: null,
          confirmPassword: null,
          recaptcha: null,
        });
        setFields({ ...fields, verified: true });
        window.grecaptcha.reset();
        if(recaptchaRef?.current)
        recaptchaRef.current.reset();
      })
      .catch((e) => {
        console.log(e)
        window.grecaptcha.reset();
        if(recaptchaRef?.current)
        recaptchaRef.current.reset();
       // recaptchaRef.current.reset();
        setIsSaving(false);
        setDisabled(true);
        setFields({ ...fields, recaptchaToken: "" });
        let msg = e.message ? e.message : "Something went wrong. Try Again!!";
        if (e.code == "InvalidParameterException") {
          setErrors({ ...errors, recaptcha: null, username: msg });
        } else if (e.code == "InternalErrorException") {
          setErrors({ ...errors, recaptcha: null });
        } else {
          let extractErrorMsg = msg.slice(28, msg.length - 1);
          let extractErrorMsgJson = JSON.parse(extractErrorMsg);
          if (Object.keys(extractErrorMsgJson) == "username") {
            setErrors({
              ...errors,
              recaptcha: null,
              username: extractErrorMsgJson.username,
            });
          } else if (Object.keys(extractErrorMsgJson) == "email") {
            setErrors({
              ...errors,
              recaptcha: null,
              email: extractErrorMsgJson.email,
            });
          } else if (Object.keys(extractErrorMsgJson) == "recaptcha") {
            setErrors({ ...errors, recaptcha: extractErrorMsgJson.recaptcha });
          }
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setDisabled(true);
    signUp();
  };

  const checkDisabled = () => {
    for (var key in errors) if (!(errors[key] == "")) return false;

    return true;
  };
  const onChange = async (value) => {
    setFields({ ...fields, recaptchaToken: value });
    let validationError = await checkIsRequired("reCAPTCHA", value);
    setErrors({ ...errors, recaptcha: validationError.errorMessage });
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setDisabled(!checkDisabled());
  }, [
    errors.firstname,
    errors.username,
    errors.email,
    errors.password,
    errors.confirmPassword,
    errors.recaptcha,
  ]);

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };

  if (fields.verified) {
    let tempFields = { ...fields, username: fields.username.trim() };
    return <ConfirmCode field={tempFields} requestFrom={"RequestCode"} />;
  } else {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="customize-modal-header">
            <Modal.Title className="customize-modal-title">
              Tag Sharing Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
            <br />
            <br />
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Modal.Body>
          <Modal.Footer className="customize-modal-footer">
            <button
              type="button"
              className="gss-btn btn-orange mt-1"
              variant="secondary"
              onClick={handleClose}
            >
              Exit
            </button>
          </Modal.Footer>
        </Modal>
        <CustomModal
          title={"Error"}
          titleText="Error"
          isOpen={isServerError.status}
          setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
          inputLabel={isServerError.message}
        ></CustomModal>
        <div>
          <span className="standard-text create-account-container">
            Not ready to register? You can still&nbsp;
            <NavLink className="reg-link" to="/gss_data">
              download the entire GSS dataset
            </NavLink>
            &nbsp;without an account.
          </span>
          <FormCard title="Registration">
            <div className="social-login-container">
              <SocialLoginButton providerType={"Google"} />
              <SocialLoginButton providerType={"Facebook"} />
            </div>

            <div className="divider-container">
              <div className="customize-divider"></div>
              <div className="divider-text">
                <span>or</span>
              </div>
              <div className="customize-divider"></div>
            </div>

            <form
              method="post"
              className="simple_form form-horizontal"
              name="userRegistrationForm"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="firstname" className="col-form-label">
                    First Name
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        className="form-control customize-input"
                        aria-describedby="firstname"
                        value={fields.firstname}
                        onInput={handleChange}
                        autoComplete="off"
                        autoFocus
                        maxLength={35}
                        disabled={isSaving}
                      />
                      {errors.firstname ? (
                        <div className="row">
                          <div className="col errText">{errors.firstname}</div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name
                  </label>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="form-control customize-input"
                        aria-describedby="lastName"
                        value={fields.lastName}
                        onInput={handleChange}
                        autoComplete="off"
                        maxLength={35}
                        disabled={isSaving}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="username" className="col-form-label">
                    Username
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="text"
                        name="username"
                        id="username"
                        className="form-control customize-input"
                        aria-describedby="username"
                        value={fields.username}
                        onInput={handleChange}
                        autoComplete="off"
                        disabled={isSaving}
                      />
                      {errors.username ? (
                        <div className="row">
                          <div className="col errText">{errors.username}</div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="email" className="col-form-label">
                    Email
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="form-control customize-input"
                        aria-describedby="email"
                        value={fields.email}
                        onInput={handleChange}
                        autoComplete="off"
                        disabled={isSaving}
                      />
                      {errors.email ? (
                        <div className="row">
                          <div className="col errText">{errors.email}</div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="organization" className="col-form-label">
                    Organization
                  </label>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <OrganizationDDL
                        value={fields.organization}
                        displayEmpty
                        onChange={handleChange}
                        disabled={isSaving}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {fields.organization == "Other" ? (
                <div className="row customize-username-height">
                  <div className="col-xs-12 col-sm-12 col-md-4 customize-label"></div>
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <div className="row">
                      <div className="col-auto">
                        <input
                          type="text"
                          name="otherOrganization"
                          id="otherOrganization"
                          className="form-control customize-input"
                          aria-describedby="otherOrganization"
                          value={fields.otherOrganization}
                          onInput={handleChange}
                          autoComplete="off"
                          maxLength={35}
                          disabled={isSaving}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="department" className="col-form-label">
                    Department
                  </label>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <DepratmentDDL
                        value={fields.department}
                        onChange={handleChange}
                        displayEmpty
                        disabled={isSaving}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {fields.department == "Other" ? (
                <div className="row customize-username-height">
                  <div className="col-xs-12 col-sm-12 col-md-4 customize-label"></div>
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <div className="row">
                      <div className="col-auto">
                        <input
                          type="text"
                          name="otherDepartment"
                          id="otherDepartment"
                          className="form-control customize-input"
                          aria-describedby="otherDepartment"
                          value={fields.otherDepartment}
                          onInput={handleChange}
                          autoComplete="off"
                          maxLength={35}
                          disabled={isSaving}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="password" className="col-form-label">
                    Password
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-auto">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control customize-input"
                        aria-describedby="password"
                        value={fields.password}
                        onInput={handleChange}
                        autoComplete="off"
                        disabled={isSaving}
                      />
                      {errors.password ? (
                        <div className="row">
                          <div className="col errText">{errors.password}</div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                    <Tooltip
                      title={
                        <div>
                          <strong>Your password must:</strong>
                          <ul>
                            <li>Be at least 8 characters</li>
                            <li>Have at least one number</li>
                            <li>
                              Have at least one symbol (special character)
                            </li>
                            <li>Have at least one upper case letter</li>
                            <li>Have at least one lower case letter</li>
                          </ul>
                        </div>
                      }
                    >
                      <InfoIcon fontSize="small" style={{ fontSize: "13px" }} className="plus-icon" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label
                    htmlFor="passwordConfirmation"
                    className="col-form-label"
                  >
                    Password Confirmation
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="password"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        className="form-control customize-input"
                        aria-describedby="passwordConfirmation"
                        value={fields.passwordConfirmation}
                        onInput={handleChange}
                        autoComplete="off"
                        disabled={isSaving}
                      />
                      {errors.confirmPassword ? (
                        <div className="row">
                          <div className="col errText">
                            {errors.confirmPassword}
                          </div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-4"></div>
                <div className="col-xs-12 col-sm-12 col-md-auto">
                  <div className="row mt-1">
                    <div className="col-auto">
                      <div className="form-check rm-pl">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={fields.shareYourTags}
                              onChange={handleChange}
                              id="shareYourTags"
                              name="shareYourTags"
                              disabled={isSaving}
                            />
                          }
                          label="Share your tags with the GSS team"
                        />
                        <Tooltip
                          title={<div>Tags let our users to improve our search engine and find relevant content. Consider sharing your tags with the GSS team.</div>}
                        >
                          <InfoIcon fontSize="small" style={{ fontSize: "13px", marginBottom:'5px' }} className="plus-icon" />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-4"></div>
                <div className="col-xs-12 col-sm-12 col-md-4">
                  <div className="row mt-1">
                    <div className="col-auto">
                      <div className="form-check rm-pl">
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={fields.optCommunication}
                              onChange={handleChange}
                              id="optCommunication"
                              name="optCommunication"
                              disabled={isSaving}
                            />
                          }
                          label="Allow emails from GSS"
                        />  <Tooltip
                        title={<div>
                         The GSS team will email you a few times a year about new data releases and major updates to the Data Explorer.</div>}
                      >
                        <InfoIcon fontSize="small" style={{ fontSize: "13px", marginBottom:'5px' }} className="plus-icon" />
                      </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 row justify-content-md-center mb-0">
                <div
                  className={
                    isCaptcha ? "reg-captchaError col-auto" : "col-auto"
                  }
                >
                  <ReCAPTCHA
                
                    ref={recaptchaRef}
                    sitekey={environment.captchaSiteKey}
                    onChange={onChange}
                   render="explicit"
                  />
                  {errors.recaptcha ? (
                    <div className="row">
                      <div className="col errText">{errors.recaptcha}</div>
                    </div>
                  ) : (
                    <div className="row"></div>
                  )}
                </div>
              </div>
              <div className="row mt-3 justify-content-md-center">
                <div className="col-auto">
                  {isSaving ? (
                    <button type="button" className="gss-btn btn-orange mt-1">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp; REGISTERING...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="gss-btn btn-orange mt-1"
                      variant="secondary"
                      disabled={disable}
                    >
                      REGISTER
                    </button>
                  )}
                </div>
              </div>
            </form>
          </FormCard>
        </div>
        <span>
          <NavLink
            className="confirmation-instruction"
            to="/users/confirmation"
            exact
          >
            Didn't receive confirmation instructions?
          </NavLink>
        </span>
      </>
    );
  }
}
