import { Chip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import VarInfo from "../../../Components/UI/VarInfo/VarInfo";
import { environment } from "../../../environment";
import { CheckIfItemExistsInArr, ItemTypes } from "../../../Store/utils/common.utils";
import "./VarSelectionDnd.css";

export default function VarSelectionDnd(props, ref) {

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => {
      if (item.data.isChecked && item.list.length > 1) {
        addMultipleToDrop(item.list);
      } else {
        addToDroppableArea(item.data);
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const isMaxAlreadyDropped = () => {
    return (props.maxDropCount === props.variableList.length)
  }

  const checkIfExistsInRowCol = (item, varType) => {
    return CheckIfItemExistsInArr((varType === "rowVariable" ? props.columnVariable : props.rowVariable), item.vID);
  }

  const isContinuousNotYr = (droppedObj) => {
    return (droppedObj.varType === 'continuous' && droppedObj.name !== 'year') && props.variableList.length < props.maxDropCount;
  }

  const addMultipleToDrop = (droppedArr) => {
    let currDraggedArr = [...props.variableList];
    droppedArr.map((droppedObj) => {
      if (isMaxAlreadyDropped() || (props.maxDropCount === currDraggedArr.length)) {
        return;
      }

      if (props.module === 'analysis') {
        if ((isContinuousNotYr(droppedObj)) || checkIfExistsInRowCol(droppedObj, props.variableType)) {
          return;
        }
      }

      if (!CheckIfItemExistsInArr(props.variableList, droppedObj.vID)) {
        currDraggedArr.push(droppedObj);
      }
    });

    props.onItemAction(currDraggedArr, props.variableType);
  }

  const addToDroppableArea = (droppedObj) => {
    let errorMsg = '',
      variableText = '',
      errorText = '';
    /* CHECK IF SECTION ALREADY CONTAINS MAX NUMBER OF VARIABLE ALLOWED */
    if (isMaxAlreadyDropped()) {
      variableText = ' Variable' + (props.maxDropCount === 1 ? '' : 's');
      errorText = 'You can specify a maximum of ' + props.maxDropCount + variableText + ' for ';
      if (props.module === 'analysis') {
        errorMsg = errorText + (props.variableType === 'columnVariable' ? 'Column' : 'Row') + variableText;
      } else {
        errorMsg = errorText + 'Choose' + variableText;
      }
      props.onItemAction('', props.variableType, errorMsg);
      return;
    }

    /* CHECK IF CONTINUOUS VARIABLE & NOT YEAR */
    if (props.module === 'analysis') {
      if (isContinuousNotYr(droppedObj)) {
        let errorMsg = 'Continuous Variables (Except Year) are not allowed';
        props.onItemAction('', props.variableType, errorMsg);
        return;
      }
    }

    /* CHECK IF VARIABLE ALREADY PRESENT IN SECTION */
    if (props.variableList.length !== 0 && CheckIfItemExistsInArr(props.variableList, droppedObj.vID)) {
      errorMsg = 'Variable Already added';
    }
    else if ( /* CHECK IF VARIABLE ALREADY PRESENT IN OTHER SECTION FOR ANALYSIS */
      props.module === 'analysis' && checkIfExistsInRowCol(droppedObj, props.variableType)) {
      errorMsg = 'Already added in a ' + (props.variableType === 'columnVariable' ? 'Row' : 'Column') + ' variable';
    }

    if (errorMsg) {
      props.onItemAction('', props.variableType, errorMsg);
    } else {
      let list = [...props.variableList];
      list.push(droppedObj);
      props.onItemAction(list, props.variableType);
    }
  };

  const removeFromDroppable = (index) => {
    let tempList = [...props.variableList];
    tempList.splice(index, 1);
    props.onItemAction(tempList, props.variableType);
  };

  return (
    <React.Fragment>
      {/* Variable Selection Section start */}
      <div
        className={"droparea case-selection" + (isOver ? ' add-border' : '')}
        ref={drop}
      >
        {props.variableList.length === 0 && (
          <div className="help text-center">
            <div className="font-weight-bold">
              {'ADD VARIABLE' + (props.maxDropCount === 1 ? '' : 'S')}
            </div>
            <div>Simply drag &amp; drop from variable cart.</div>
            {props.maxDropCount === 5 && ( /* Drop count 5 means multi tabulation */
              <div>NOTE: Users may add one or more variables for multi-level tabulation</div>
            )}
          </div>
        )}
        <ul className="dropped-items">
          {props.variableList.map((data, caseVarindex) => (
            <React.Fragment>
              <li className="var-item" key={props.module + '_' + caseVarindex} id={props.module + '_' + caseVarindex} >
                {data && <VarInfo data={data} toggleTags={props.toggleTags} />}
                <a
                  className="fa fa-times-circle remove-var"
                  onClick={(event) => removeFromDroppable(caseVarindex)}
                ></a>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
      {/* Variable Selection Section end */}
    </React.Fragment>
  );
}
