import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import arrow from '../../../Images/back_arrow.jpg'
import axios from 'axios';
import { environment } from '../../../environment';

import { NavLink } from "react-router-dom";
import './RevisionHistory.css'
import { updateService } from '../../../Shared/UpdateCart';
import { ToggleOffRounded } from '@material-ui/icons';
const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },

});

export default function RevisionHistory() {
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [isChanges,setChanges] = React.useState(false);
  useEffect(() => {
      viewAll()
    // if (localStorage.getItem("isSignedIn") == "true") {
    //   viewAll()
    //   updateService.sendAlert('varData');
    // }

  }, []);

  const viewAll = () => {
    // let reqBody = {
    //   "workspace_id": localStorage.defaultProjectNo ? parseInt(localStorage.defaultProjectNo) : '',
    //   "year_info": 1
    // };
    // axios.post(`${environment.getCartItem}`, reqBody)
    //   .then((resp) => {
    //     console.log(resp, resp.data.response.cart_items);
    //    
    //     
    //     setData(resp.data.response.cart_items)

    //   })
    //   .catch((error) => {
    //     //props.history.push("/error");
    //     console.log(error);
    //   });
    let data = [{"id":2,"createdAt":"March 01, 2021 05:06","revisions":{"name":"Confidence in congress","variables":[{"v_id":1,"v_name":"year","v_original_name":"year","v_label":"Gss year for this respondent","dci_id":1,"dci_created_at":"2015-04-04 16:07:52","dci_updated_at":"2015-04-04 16:07:52"},{"v_id":2,"v_name":"id_","v_original_name":"id","v_label":"Respondent id number","dci_id":2,"dci_created_at":"2015-04-04 16:07:53","dci_updated_at":"2015-04-04 16:07:53"}],"yearsIncluded":[1990,1991,1993,1994,1996,1998,2000,2002,2004,2006,2008,2010,2012,2014,2016,2018],"formats":"Stata (Control + System)"},"previousRevisions":{"name":"Confidence in congress","variables":[{"v_id":1,"v_name":"year","v_original_name":"year","v_label":"Gss year for this respondent","dci_id":1,"dci_created_at":"2015-04-04 16:07:52","dci_updated_at":"2015-04-04 16:07:52"},{"v_id":2,"v_name":"id_","v_original_name":"id","v_label":"Respondent id number","dci_id":2,"dci_created_at":"2015-04-04 16:07:53","dci_updated_at":"2015-04-04 16:07:53"}],"yearsIncluded":"ALL","formats":"Stata (Control + System)"}}]
    setData(data)
  }

const toggleFn = ()=>{
setChanges(!isChanges)
}
  return (
    <div
      className="noscroll data-cart"
      id="ws-content"
      style={{ padding: "50px 40px" }}
    >
      <div className="gss-large-title">
        <h2>Revision History</h2>
      </div>
      <TableContainer
        size="small"
        component={Paper}
        className="gss_material_table"
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Revision details</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>
                  <div className="definition" style={{"display": isChanges? "none":"block"}}>
                    <div className="param">
                      <strong>Name:</strong>
                      Confidence in congress
                    </div>
                    <div className="param">
                      <strong>Variables:</strong>
                      <div className="extract-tab">
                        YEAR
                        <span className="extract-doubletab">
                          (Gss year for this respondent )
                        </span>
                      </div>
                      <div className="extract-tab">
                        ID_
                        <span className="extract-doubletab">
                          (Respondent id number)
                        </span>
                      </div>
                      <div className="extract-tab">
                        AGE
                        <span className="extract-doubletab">
                          (Age of respondent)
                        </span>
                      </div>
                      <div className="extract-tab">
                        EDUC
                        <span className="extract-doubletab">
                          (Highest year of school completed)
                        </span>
                      </div>
                      <div className="extract-tab">
                        SEX
                        <span className="extract-doubletab">(Respondents sex)</span>
                      </div>
                      <div className="extract-tab">
                        RACE
                        <span className="extract-doubletab">
                          (Race of respondent)
                        </span>
                      </div>
                      <div className="extract-tab">
                        PARTYID
                        <span className="extract-doubletab">
                          (Political party affiliation)
                        </span>
                      </div>
                      <div className="extract-tab">
                        CONLEGIS
                        <span className="extract-doubletab">
                          (Confidence in congress)
                        </span>
                      </div>
                      <div className="extract-tab">
                        SATJOB
                        <span className="extract-doubletab">
                          (Job or housework)
                        </span>
                      </div>
                      <div className="extract-tab">
                        MEMVET
                        <span className="extract-doubletab">
                          (Membership in veteran group)
                        </span>
                      </div>
                      <div className="extract-tab">
                        MEMPROF
                        <span className="extract-doubletab">
                          (Membership in professional society)
                        </span>
                      </div>
                      <div className="extract-tab">
                        REALINC
                        <span className="extract-doubletab">
                          (Family income in constant $)
                        </span>
                      </div>
                      <div className="extract-tab">
                        BALLOT
                        <span className="extract-doubletab">
                          (Ballot used for interview)
                        </span>
                      </div>
                    </div>
                    <div className="param">
                      <strong>Years Included:</strong>
                      <div className="extract-tab">
                        1990, 1991, 1993, 1994, 1996, 1998, 2000, 2002, 2004,
                        2006, 2008, 2010, 2012, 2014, 2016, 2018
                      </div>
                    </div>
                    <div className="param">
                      <strong>Formats:</strong>
                      <div className="extract-tab">Stata (Control + System)</div>
                    </div>
                  </div>
                  <div className="changes" style={{ display: isChanges? "block": "none" }}>
                    <table className="changeset">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Previous revision</th>
                          <th>This revision</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                      <tbody>
                        <tr>
                          <td className="attr">
                            <b>Years</b>
                          </td>
                          <td>All</td>
                          <td>
                            1990, 1991, 1993, 1994, 1996, 1998, 2000, 2002,
                            2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018
                          </td>
                        </tr>
                        <tr>
                          <td className="attr">
                            <b>Variables</b>
                          </td>
                          <td>
                            year, id_, age, educ, sex, race, partyid, conlegis,
                            satjob, memvet, memprof, realinc, ballot
                          </td>
                          <td>
                            year, id_, age, educ, sex, race, partyid, conlegis,
                            satjob, memvet, memprof, realinc, ballot
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </TableCell>
                <TableCell>
                  <a href="#" onClick={toggleFn}> {isChanges? "Show Defination": "Show Changes"}</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
