import React, { Component } from "react";
import Axios from "axios";
import { environment } from "../../../environment";
import "./UserSearchTable.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { NavLink } from "react-router-dom";
import { updateService } from "../../../Shared/UpdateCart";
import MoveCopyModal from "../MoveCopy/MoveCopy";
import { connect } from "react-redux";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ProjectCreate from "../../ProjectManagement/ProjectCreate/ProjectCreate";
/* import { messageService } from "../../../Shared/Message"; */
class UserSearchTable extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      data: [],
      projects: [],
      anchorEl: null,
      isOpen: false,
      selectedID: "",
      isMove: true,
      searchName: "",
      selectedRow: {},
    };
    this.getUserSearch = this.getUserSearch.bind(this);
    this.deleteSearch = this.deleteSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
   
  }
  componentDidMount() {
    this.getUserSearch();
    updateService.sendAlert("varData");
  }
  handleClick(event, row) {
    this.setState({ selectedRow: row });
    this.setState({ anchorEl: event.currentTarget });
  }
  handleClose() {
    this.setState({ anchorEl: null });
  }
  getUserSearch() {
    let prgData = this.props.data.prgdata;
    if (this.props.data.prgdata) {
      this.setState({ projects: prgData });
    }
    const id = Number(this.props.location.pathname.split("/user_searches/")[1]);
    Axios.get(`${environment.getUserSearch}` + id).then((response) => {
      this.setState({ data: response.data.response.user_searches });
    });
  }
  deleteSearch(id) {
   /*  let reqBody = {
      id_: id,
    }; */
    if (window.confirm("Are you sure you want to delete this UserSearch?")) {
      Axios.delete(`${environment.deleteSearchURL}`, {
        data: {
          id_: id,
        },
      })
        .then((response) => {
          this.getUserSearch();
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
          } else {
            // anything else
          }
        });
    } else {
    }
  }
  submitMoveCopy = (data) => {
    if (data == "new") {
      this.setState({ isNew: true });
      let text = this.state.isMove ? "move" : "copy";
      let arr = { id: this.state.selectedID, text: text };
      this.props.dispatch({
        type: "NEW_PROJECT",
        payload: arr,
      });
    } else {
      this.getSearchData(data);
    }
  };
  getSearchData(id) {
    let reqBody = {
      workspace_id: id,
      id_: this.state.selectedID,
    };
    let url = this.state.isMove ? environment.moveCart : environment.copyCart;
    axios
      .post(`${url}`, reqBody)
      .then((resp) => {
        this.getUserSearch();
      })
      .catch((error) => {
        //props.history.push("/error");
      });
  }
  setIsOpen() {
    this.setState({ isOpen: !this.state.isOpen }, () => {});
  }
  openDialog(event, seltext, id, name) {
    event.preventDefault();
    let boolVal = seltext == "move" ? true : false;
    this.setState({ selectedID: this.state.selectedRow.us__id });
    this.setState({
      isMove: boolVal,
      searchName: this.state.selectedRow.us__name,
    });
    this.setIsOpen();
  }
  render() {
    return (
      <div
        className="noscroll display-page"
        id="ws-content"
        style={{ "padding-top": "20px" }}
      >
        <MoveCopyModal
          title="Test"
          isOpen={this.state.isOpen}
          setIsOpen={this.setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
          handleSubmit={this.submitMoveCopy}
          isMove={this.state.isMove}
          isInput={true}
          search={this.state?.searchName}
          list={this.props.data?.prgdata}
          inputLabel="Name"
        ></MoveCopyModal>
        {this.state.isNew ? <ProjectCreate></ProjectCreate> : ""}
        <div
          className="gss-center index pull-left wide"
          style={{ width: "68%", padding: "35px 20px" }}
        >
          <div className="gss-large-title" style={{ "padding-right": "0" }}>
            <div
              className="pull-left"
              style={{ position: "relative", bottom: "4px" }}
            >
              User Searches ({this.state.data.length})
            </div>
          </div>
          <a className="btn btn-orange pull-right" href="/variables/vfilter">
            + CREATE NEW{" "}
          </a>
          <a
            className="btn  pull-right"
            href="/projects/88247/variables/vfilter"
          >
            Export List{" "}
          </a>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data.map((row) => (
                  <TableRow id={row.us__id}>
                    <TableCell scope="row">
                      <NavLink
                        exact
                        to={"/user_searches/" + row.us__id + "/search_summary"}
                      >
                        {row.us__name}
                      </NavLink>
                    </TableCell>
                    <TableCell scope="row">{row.us__created_at}</TableCell>
                    <TableCell scope="row">
                      {row.u__first_name}&nbsp; {row.u__last_name}
                    </TableCell>
                    <TableCell className="actions">
                      <a className="action" href="/variables/vfilter/">
                        <i className="fa fa-pencil"></i>
                      </a>
                      <a
                        className="action"
                        rel="nofollow"
                        data-method="delete"
                        href="#"
                        onClick={this.deleteSearch.bind(this, row.us__id)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </a>
                      <a
                        aria-controls="simple-menu"
                        className="dropdown-link gss-dismiss action"
                        aria-haspopup="true"
                        onClick={(event) => this.handleClick(event, row)}
                      >
                        <i className="fa fa-cog"></i>
                      </a>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleClose}
                      >
                        <MenuItem onClick={this.handleClose}>
                          {" "}
                          View details
                        </MenuItem>
                        <MenuItem onClick={this.handleClose}>
                          {" "}
                          <NavLink
                            exact
                            to={
                              "/user_searches/" +
                              this.state.selectedRow.us__id +
                              "/search_summary"
                            }
                          >
                            {" "}
                            Add/View comments
                          </NavLink>
                        </MenuItem>
                        <MenuItem
                          value={row.us__id}
                          onClick={(event) =>
                            this.openDialog(
                              event,
                              "move",
                              row.us__id,
                              row.us__name
                            )
                          }
                        >
                          {" "}
                          Move to...
                        </MenuItem>
                        <MenuItem
                          value={row.us__id}
                          onClick={(event) =>
                            this.openDialog(
                              event,
                              "copy",
                              row.us__id,
                              row.us__name
                            )
                          }
                        >
                          {" "}
                          Copy to...
                        </MenuItem>
                      </Menu>
                      <NavLink
                        className="action chat"
                        exact
                        to={"/user_searches/" + row.us__id + "/search_summary"}
                      >
                        <span className="comments-count">
                          {row.commentCount}
                        </span>
                        <i className="fa fa-comment-o"></i>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div className="sidebar pull-right" style={{ bottom: "10px" }}>
          <div className="content">
            <div className="sidebar-box">
              <h4>User Search Help</h4>
              <p>
                Find answers to common questions about querying data on our help
                page.
              </p>
              <a
                className="btn btn-dark"
                href="/pages/show?page=gss%2Fhelp_variables"
              >
                SEARCH HELP
              </a>
            </div>
            <div className="sidebar-box">
              <h4>Tips for creating user searches</h4>
              <ul className="fa-ul clearfix">
                <li className="heading">
                  <div className="circle">
                    <span>1</span>
                  </div>
                  Create Search
                </li>
                <p className="pal">
                  Create a data search by entering your search criteria.
                </p>
                <li className="heading">
                  <div className="circle">
                    <span>2</span>
                  </div>
                  Save Search
                </li>
                <p className="pal">Save your search under a memorable name.</p>
                <li className="heading">
                  <div className="circle">
                    <span>3</span>
                  </div>
                  Search Again
                </li>
                <p className="pal">
                  Perform similar searches by adjusting your saved search
                  parameters.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    data: state.reducer,
  };
}
export default connect(mapStateToProps)(UserSearchTable);
