import React, { useState, useEffect } from "react";
import { Row, Container, Col, Dropdown, Form } from "react-bootstrap";
import SelectableContext from "react-bootstrap/esm/SelectableContext";
import EnhancedTableCheckbox from "../../../Components/UI/EnhancedTableCheckbox/EnhancedTableCheckbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getVariables,
  addToExtract,
  removeFromMyGSS,
} from "../../../Store/actions/variable";
import AddTagModel from "../../../Components/UI/AddTagModel/AddTagModel";
import CreateTagModel from "../../../Components/UI/CreateTagModel/CreateTagModel";
import TagCategories from "../TagCategories/TagCategories";
import SelectedVariablesContext from "../../../Store/ContextApi/SelectedVariablesContext";
import ConfirmModal from "../../../Components/UI/ConfirmModal/ConfirmModal";
import "./SharedTag.css";
import { Message } from "@material-ui/icons";
import Loader from "../../../Components/UI/Loader/Loader";
import { environment } from "../../../environment";
import { defaultVariables } from "../../../Store/utils/variable.utils";
import InfoIcon from "@material-ui/icons/Info";

const SharedTag = () => {
  const allVariables = useSelector((state) => state.variable);
  const { loading, error, variables = [] } = allVariables;
  const rowsCat =
    useSelector((state) => state.variable.variableByCategory) ?? {};
  const dispatch = useDispatch();
  const [selectAll, setSelectAll] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [rows, setRows] = useState([]);
  const [tagCategories, setTagCategories] = useState([]);
  const [type, setType] = useState(2);
  const [addTagModal, setAddTagModal] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [removeSelected, setRemoveSelected] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedObj, setSelectedObj] = useState([]);

  const contextValue = { selected, setSelected, selectedObj, setSelectedObj };

  const handleSelectAllClick = (e) => {
    if (!selectAll) {
      const newSelecteds = rows.map((n) => n.vID);
      const selectedObj = rows.map((n) => n);
      setSelected(newSelecteds);
      setSelectedObj(selectedObj);
      setSelectAll(!selectAll);
      return false;
    }
    setSelectAll(!selectAll);
    setSelected([]);
    setSelectedObj([]);
  };

  useEffect(() => {
    getVariablelist();
  }, []);

  useEffect(() => {
    setSelected([]);
    setSelectedObj([]);
    onSearchData();
  }, [type]);

  useEffect(() => {
    setRows(variables ?? []);
    setTagCategories(Object.entries(rowsCat ?? {}));
  }, [loading]);

  const getVariablelist = () => {
    setSearchData("");
    setSelected([]);
    setSelectedObj([]);
    dispatch(getVariables());
  };

  const onSearchData = (e = "") => {
    let value = "";
    if (e !== "") {
      value = e.target.value;
    } else {
      value = searchData;
    }

    if (type == 1) {
      const filtered = variables.filter(({ name, tags_info }) => {
        return (
          name.toLowerCase().includes(value.toLowerCase()) ||
          tags_info.find((d) =>
            d.name.toLowerCase().includes(value.toLowerCase())
          )
        );
      });

      setRows(filtered);
    } else {
      let rowKeys = Object.entries(rowsCat).filter(
        (obj) =>
          value == "" ||
          obj[1].tagName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );

      setTagCategories(rowKeys);
    }
    setSearchData(value);
    return;
  };

  const changeType = ({ target: { value } }) => {
    setType(value);
    return;
  };

  const onRemoveFromMyGssSubmit = (removeType, id) => {
    setShowConfirm(false);
    if (
      selectedObj.length ||
      removeSelected.length ||
      removeType === "extract"
    ) {
      let ids;
      if (removeType === "extract") {
        ids = removeSelected?.length == 1 ? [id] : selected;
      } else {
        ids = removeSelected.length ? [removeSelected[0].vID] : selected;
      }
      if (removeType !== "extract") {
        let isDefaultVars = true;
        selectedObj.map((data) => {
          if (defaultVariables.indexOf(data.name) == -1) {
            isDefaultVars = false;
          }
        });

        if (isDefaultVars) {
          alert("Default variables can't be removed");
          return;
        }
      }

      dispatch(removeFromMyGSS(ids, removeType)).then(() => {
        alert("Removed successfully!")
        getVariablelist();
        if (removeType != "extract") {
          setSelected([]);
          setSelectedObj([]);
        }
      });
    }
  };

  const onAddToExtractSubmit = () => {
    setShowConfirm(false);
    if (selectedObj.length) {
      let filter = selectedObj
        .filter((d) => d.isAlreadyInExtract != 1)
        .map((d) => d.vID);
      if (filter.length) {
        let reqObj = {
          cart_items: filter,
          workspace_id: parseInt(localStorage.defaultProjectNo),
          cartType: "extract",
        };
        dispatch(addToExtract(reqObj)).then(() => {
          getVariablelist();
          setSelected([]);
          setSelectedObj([]);
        });
      }
    }
  };

  const onRemoveFromMyGSS = () => {
    setRemoveSelected([]);
    setDynamicAction("Remove");
  };

  const onRemoveFromExtract = () => {
    setRemoveSelected([]);
    setDynamicAction("Remove", "extract",)
  }
  const onAddToExtract = () => {
    setRemoveSelected([]);
    setDynamicAction("Add");
  };

  const actionsObj = {
    Add: onAddToExtractSubmit,
    Remove: onRemoveFromMyGssSubmit,
  };

  const setDynamicAction = (val, removeType, id) => {
    if (val == "Remove" && removeType === "extract") {
      onRemoveFromMyGssSubmit(removeType, id);
      return;
    }
    setShowConfirm(true);
    setModalTitle(val);
    if (val == "Remove") {
      setModalMessage("Remove From MyGSS");
      return { onSubmit: onRemoveFromMyGssSubmit };
    } else if (val == "Add") {
      setModalMessage("Add To Extract");
      return { onSubmit: onAddToExtractSubmit };
    }
  };

  return (
    <Container className="h-100">
      <Row className="w-100 m-0 p-0">
        {rows.length == 2 && variables.length == 2 && (
          <Col xs="12" md="12" lg="12" className="text-center my-2">
            <div className="mx-1 border-grey letter-spacing-0 p-4">
              {environment.myGssDefault}
            </div>
          </Col>
        )}
        <Col >
          <Row className="m-0 d-flex align-items-center">
            <Col xs="12" md="12" lg="4" sm="12" xl="4" className="p-0 flex-grow-1">
              <TextField
                type="search"
                variant="outlined"
                margin="normal"
                placeholder={type == 1 ? "Find variable" : "Find tags"}
                onChange={onSearchData}
                value={searchData}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col  className="flex-grow-1 p-0">
              <Form.Row className="mt-2">
                {/* <Form.Label column="sm"  lg={2} >
                
                </Form.Label> */}
                <Col xs="12" md="12" lg="4" sm="12" xl="4">
                <p className="hlabel p-3 d-none">
                <Form.Control
                    lg={2}
                    fullWidth={true}
                    // defaultValue={type}
                    onChange={changeType}
                    className="hlabel "
                    as="select"
                    defaultValue="Choose..."
                  >
                    <option value="1">Variable List</option>
                    <option value="2">Tag Categories</option>
                  </Form.Control></p>
                 
                </Col>
              </Form.Row>
            </Col>

            
          </Row>
          <Row className="ml-1 mt-3">
            {type == 1 ? (
              <>
                <span className="inline-block">
                  {rows.length + " item" + (rows.length > 1 ? "s" : "")}{" "}
                </span>
              </>
            ) : (
              <span className="inline-block">
                {tagCategories.length +
                  " item" +
                  (tagCategories.length > 1 ? "s" : "")}
              </span>
            )}
          </Row>
          <Row>
            {loading ? (
              <Row className="d-flex align-items-center justify-content-center w-100">
                <Loader />
              </Row>
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : type == 1 ? (
              <div className={"variable-table"}>
                <EnhancedTableCheckbox
                  varList={true}
                  showPagination={false}
                  rows={rows}
                  selected={selected}
                  selectedObj={selectedObj}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  setSelected={setSelected}
                  setSelectedObj={setSelectedObj}
                  setPage={setPage}
                  setRowsPerPage={setRowsPerPage}
                  setRemoveSelected={setRemoveSelected}
                  removeFromMyGSS={(type, id) => {
                    setDynamicAction("Remove", type, id);
                  }}
                />
              </div>
            ) : (
              <SelectedVariablesContext.Provider value={contextValue}>
                <TagCategories
                  searchData={searchData}
                  tagCategories={tagCategories}
                  getVariablelist={getVariablelist}
                  selectAll={selectAll}
                />
              </SelectedVariablesContext.Provider>
            )}
          </Row>
        </Col>
      </Row>
      {addTagModal ? (
        <AddTagModel
          rows={selectedObj}
          selected={selected}
          setSelected={setSelected}
          setSelectedObj={setSelectedObj}
          addTagModal={addTagModal}
          setAddTagModal={setAddTagModal}
          getVariablelist={getVariablelist}
        />
      ) : (
        ""
      )}
      {showConfirm ? (
        <ConfirmModal
          rows={removeSelected.length ? removeSelected : selectedObj}
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          title={modalTitle}
          message={modalMessage}
          onSubmit={actionsObj[modalTitle]}
        />
      ) : (
        ""
      )}
      {createTagModal ? (
        <CreateTagModel
          rows={selectedObj}
          selected={selected}
          modalShow={createTagModal}
          setAddTagModal={setCreateTagModal}
          getVariablelist={getVariablelist}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default SharedTag;
