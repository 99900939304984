import React, { useEffect /* , useRef */ } from "react";
import { Container } from "react-bootstrap";
import { Box, Tab, Tabs, Paper } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

const { tableau } = window;
var viz;
function TableauEmbed(props) {
  /* const ref = useRef(null); */

  function initViz() {
    if (viz != null) {
      viz.dispose();
    }
    var containerDiv = document.getElementById("vizContainer");
    viz = new tableau.Viz(containerDiv, props.url);
  }

  useEffect(() => {
    if (props.url) {
      initViz();
    }
  }, [props.url]);

  return (
    <>
      {/* <Container >
       
        </Container> */}
      <Paper style={{ overflow: "auto" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          <div className="viz-overlay1"></div>
          <div id="vizContainer" className="position-relative">
            <div className="viz-overlay"></div>
          </div>
        </Tabs>
      </Paper>
    </>
  );
}

export default TableauEmbed;
