import React from 'react'
import "./TrendsTable.css"

const TrendsTable = ({ data, dataKeys, years, selectedBreakDown }) => {


    return <>
        {
            //  <Loader />
            (dataKeys.length > 0) ?
                (<div className="results-container trends-table">
                    <div className="clearfix">
                        <div className="table-holder no-wrap w-100">
                            <div className="table-inner fixed">
                                <table className="gsstable multi">
                                    <tbody>
                                        <tr className="header">
                                            <td style={{ height: "72px" }} className="ath">
                                                {selectedBreakDown}
                                            </td>
                                        </tr>

                                        {dataKeys.map(d => (
                                            <tr key={d}>
                                                <th className="br var_value var-heading" rowspan="1" >
                                                    {d}
                                                </th>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                            <div className="table-inner">
                                <table className="gsstable inner multi table-right">
                                    <thead>
                                        <tr>
                                            <th className="year" colspan={data.length}>
                                                <b>Year</b>
                                            </th>
                                        </tr>
                                        <tr className="header">
                                            {
                                                years.map(val => (
                                                    <th key={val} className="bb var_value border">
                                                        {val}
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataKeys.map(k => (
                                                <tr key={k}>
                                                    {
                                                        !!(data[k]) && data[k].map(d => (
                                                            <td key={d.Year} className="customize-font-weight">
                                                                <div className="val">
                                                                    {d.RowPercent}
                                                                </div>
                                                                {d.RowStdErr &&
                                                                    <div className="err">
                                                                        ({d.RowStdErr})
                                                                    </div>
                                                                }
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>)
                : ""
        }
    </>
}

export default TrendsTable
