import React from 'react';
import './About.css';
import AboutImg1 from '../../Images/about-1.jpg';
import AboutImg2 from '../../Images/about-2.jpg';
/* import SearchByTags from '../../Containers/SearchManagement/SearchByTags/SearchByTags'; */

function AboutPage() {

    return (
        <React.Fragment>

            <div className="text-lg-left text-xs-center text-md-center text-sm-center " style={{  padding: "0 3%",  backgroundColor: "#ffffff" }}>
                <div className="gss-large-title  ">About GSS Data Explorer</div>
            </div>
            <div className=" row "
            >
                <div className='col-lg-6 col-md-12 col-sm-12'>
                <p className="standard-text p1">
                    The General Social Survey (GSS) is a project of NORC at the University of Chicago, with principal
                    funding from the National Science Foundation. Since 1972, the GSS has been monitoring societal change
                    and studying the growing complexity of American society.
                </p>
                <p className="standard-text p1">
                    The GSS is a publicly available national resource, and is one of the most frequently analyzed sources of
                    information in the social sciences. GSS Data Explorer is one of many ways that NORC supports the
                    dissemination of GSS data for use by legislators, policymakers, researchers, educators and others.
                </p>
               
                <p className="standard-text ">
                    GSS Data Explorer was designed to make the GSS more accessible and provide the tools necessary to transform
                    data into useful knowledge. Features include the most sophisticated and intuitive interface of any data
                    dissemination tool in current use and robust support through tutorials, FAQs, and a helpdesk. It also
                    includes public and private collaboration spaces that, among other things, allow teachers to use the
                    GSS Data Explorer as a virtual classroom.
                </p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 text-center '>
                <img src={AboutImg1}  alt=""/>
                <img src={AboutImg2}  alt=""/>
                </div>
            </div>
        </React.Fragment>
    );


}

export default AboutPage;