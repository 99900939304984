import React, { useContext, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  Paper,
  makeStyles,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AddIcon from "@material-ui/icons/Add";
import AccordianButton from "../AccordianButton/AccordianButton";
import CheckIcon from "@material-ui/icons/Check";
import SelectedVariablesContext from "../../../Store/ContextApi/SelectedVariablesContext";
import EnhancedTableCheckbox from "../EnhancedTableCheckbox/EnhancedTableCheckbox";
import "./CategoryAccordian.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
  },
}));

const CategoryAccordian = ({
  rows,
  tagId,
  title,
  parentClass,
  isModel,
  categorySelected,
  selectedcatType,
  isSelected,
  isThisTagShared,
  isSharedTag,
  createdBy,
  ...props
}) => {
  const [expand, setExpand] = useState({ 0: true, 1: false });
  const classes = useStyles();

  const { selected, setSelected, selectedObj, setSelectedObj } = useContext(
    SelectedVariablesContext
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const CustomToggle = ({ children, eventKey, defaultClass }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, () =>
      setExpand({ ...expand, ...{ [eventKey]: !expand[eventKey] } })
    );

    const renderButton = () => {
      if (expand[eventKey]) {
        return <ExpandMoreIcon />;
      }
      return <ChevronRightIcon />;
    };

    return (
      <AccordianButton
        className={`accordian-botton ${defaultClass}`}
        onClick={decoratedOnClick}
      >
        <Col className="p-0">
          {" "}
          {renderButton()}
          {children}
        </Col>
      </AccordianButton>
    );
  };

  const renderTagButton = () => {
    if (selectedcatType.indexOf(tagId) !== -1) {
      return (
        <div>
          <button
            type="button"
            disabled={categorySelected}
            className="btn tag-model-btn px-2 d-flex align-items-center justify-content-center"
          >
            <CheckIcon fontSize="small" />
            Added
          </button>
          <button
            className="tag-action-btn text-nowrap text-10 remove-gss"
            onClick={(e) => props.removeFromTag(tagId)}
          >
            Remove From Tag
          </button>
        </div>
      );
    }
    return (
      <button
        type="button"
        className="btn tag-model-btn-outline  px-2 mb-2 d-flex align-items-center"
        onClick={() => props.onAddToTag({ tagId, title })}
      >
        <AddIcon fontSize="small" className="plus-icon" />
        Add to Tag
      </button>
    );
  };

  return (
    <>
      <Accordion defaultActiveKey="0" className="w-100 mt-3">
        <Card className={`tag-container ${parentClass}`}>
          <Card.Header className="py-0">
            <Row className="bb-1">
              <Col
                sm={8}
                md={8}
                lg={8}
                className="p-0 d-flex justify-content-left  flex-grow-2 align-items-baseline"
              >
                <span className="inline-block ml-3 tag-header">{title}</span>
                {!isModel && title !== "None" && isSharedTag === 0 ? (
                  <span className="ml-3 px-4">
                    <button
                      className="tag-action-btn"
                      onClick={() => props.onShareTagModalShowTag(tagId, title)}
                    >
                      Share Tag
                    </button>
                    <button
                      className="tag-action-btn"
                      onClick={() => props.onEditTag(tagId, title)}
                    >
                      Edit Tag
                    </button>
                  </span>
                ) : (
                  ""
                )}
                {
                  isSharedTag === 1 && (
                    <span className="ml-2">Created and shared by <strong>{createdBy}</strong></span>
                  )
                }

              </Col>
              {title !== "None" ? (
                !isModel ? (
                  <Col
                    className="p-0 d-flex justify-content-lg-end justify-content-md-end  justify-content-sm-end align-items-center"
                    sm={4}
                  >
                    {isSharedTag === 1 && (
                      <button
                        title="Copy Tag"
                        className="float-end tag-action-btn"
                        onClick={() => props.onCopyToTag(tagId)}
                      >
                        Copy Tag
                      </button>
                    )}

                    {isSharedTag === 0 && (
                      <button
                        title={
                          isThisTagShared === 1
                            ? "Share Tag cannot be deleted"
                            : "Delete Tag"
                        }
                        disabled={isThisTagShared === 1}
                        className="float-end tag-action-btn"
                        onClick={() =>
                          isThisTagShared === 0 && props.onDeleteTag(tagId)
                        }
                      >
                        Delete Tag
                      </button>
                    )}

                    {isSharedTag === 1 && (
                      <button
                        title="Remove Tag"
                        className="float-end tag-action-btn"
                        onClick={() => props.onDeleteTag(tagId)}
                      >
                        Remove Tag
                      </button>
                    )}
                  </Col>
                ) : (
                  renderTagButton()
                )
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col
                sm={8}
                md={8}
                lg={8}
                className="p-0 d-flex justify-content-left  flex-grow-2 align-items-center"
              >
                <CustomToggle eventKey="1" defaultClass="tag-variables px-3">
                  {rows?.length + " variable" + (rows?.length > 1 ? "s" : "")}
                </CustomToggle>
              </Col>
            </Row>
          </Card.Header>

          <Accordion.Collapse eventKey="1">
            <Card.Body className="p-0">
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <div className={"variable-cat-table"}>
                    {isModel ? (
                      <EnhancedTableCheckbox
                        showPagination={false}
                        disableCheckBox={true}
                        rows={rows}
                        selected={selected}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                      />
                    ) : (
                      <EnhancedTableCheckbox
                        showPagination={false}
                        rows={rows}
                        selected={selected}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setSelected={setSelected}
                        selectedObj={selectedObj}
                        setSelectedObj={setSelectedObj}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                      />
                    )}
                  </div>
                </Paper>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

CategoryAccordian.defaultProps = {
  parentClass: "",
  isModel: false,
};
export default CategoryAccordian;
