import { DragLayer } from 'react-dnd';
import { BoxDragPreview } from '../BoxDragPreview/BoxDragPreview';
import VarInfo from '../VarInfo/VarInfo';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

function getItemStyles(props) {
    const { initialOffset, currentOffset } = props;
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

const CustomDragLayer = (props) => {
    const { item, isDragging } = props;
    function renderItem() {
        if (typeof (item.data) === 'undefined') {
            return;
        }
        if (item.data.isChecked && item.list.length > 1) {
            return <BoxDragPreview item={item} />;
        } else {
            return <VarInfo data={item.data} customClass="dragable-ele" />;
        }
    }
    if (!isDragging) {
        return null;
    }
    return (<div style={layerStyles}>
        <div style={getItemStyles(props)}>{renderItem()}</div>
    </div>);
};

export default DragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
}))(CustomDragLayer);
