import React from "react";
import { Card } from 'react-bootstrap'

function ProfGuide() {
    return (
        <Card style={{textAlign:"center"}} className='my-1 p-3 rounded border-0'>
            <Card.Body>
                <Card.Text>
                    <h3>Page is under Construction</h3>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ProfGuide;