import "./Homepage.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Button from "../../Components/UI/Button/Button";
import About from "../../Components/About/About";
import CustomModal from "../../Components/UI/Modal/CustomModal";
import Registration from "../UserManagement/user-create-page/Registration";
import Contact from "../UserManagement/user-contact-creation/Contact";
import ErrorDialog from "../../Components/UI/Modal/ErrorDialog/ErrorDialog/ErrorDialog";
import CenterSection from "../../Components/UI/CenterSection/CenterSection";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import SignIn from "../UserManagement/SignIn/SignIn";
import FormCard from "../../Components/UI/FormCard/FormCard";
import ProjectCreate from "../ProjectManagement/ProjectCreate/ProjectCreate";
import Projects from "../ProjectManagement/Projects/Projects";
import DevProjects from "../ProjectManagement/DevProjects/DevProjects";
import banner from "../../Images/2018_banner.png";
import Home from "../../Components/Home/Home";
import AlertToaster from "../../Components/UI/Modal/Snackbar/Snackbar";
import ResendConfirm from "../UserManagement/Confirmation/ResendConfirm";
import viewUserInfo from "../UserManagement/AdminUserInfo/GetUserInfo/ViewUserInfo";
import editUserInfo from "../UserManagement/AdminUserInfo/SetUserInfo/EditUserInfo";
import workspaces from "../ProjectManagement/Workspaces/Workspace";
import UserSettings from "../UserManagement/UserSettings/UserSettings";
import ForgotPassword from "../UserManagement/forgotPassword/ForgotPassword";
import AdminUsers from "../UserManagement/AdminUsers/AdminUsers";
import AdminCreateUser from "../UserManagement/AdminCreateUser/AdminCreateUser";
import ConfirmCode from "../UserManagement/Confirmation/ConfirmCode/ConfirmCode";
import ProjectTile from "../ProjectManagement/ProjectTile/ProjectTile";
import EditProject from "../ProjectManagement/EditProject/EditProject";
import SearchPage from "../SearchManagement/SearchPage/SearchPage";
import ErrorPage from "../ErrorManagement/ErrorPage";
import VariableDetails from "../VariableManagement/VariableDetails/VariableDetails";
import UserSearchTable from "../SearchManagement/UserSearchTable/UserSearchTable";
import AddVariables from "../VariableManagement/AddVariables/AddVariables";
import ViewAllCartItems from "../SearchManagement/ViewAllCartItems/ViewAllCartItems";
import UserSearchSummary from "../SearchManagement/UserSearchSummary/UserSearchSummary";
import ExtractManagement from "../ExtractManagement/ExtractManagement";
import ExtractSummary from "../ExtractManagement/ExtractSummary/ExtractSummary";
import Extracts from "../ExtractManagement/Extracts/Extracts";
import RevisionHistory from "../ExtractManagement/RevisionHistory/RevisionHistory";
import QuickDownloads from "../UserManagement/QuickDownloads/QuickDownloads";
import AnalysisHistory from "../AnalysisManagement/AnalysisHistory/AnalysisHistory";
import AnalysisSummary from "../AnalysisManagement/AnalysisSummary/AnalysisSummary";
import BreadCrumb from "./BreadCrumb/BreadCrumb";
import CreateExtract from "../ExtractManagement/CreateExtract/CreateExtract";
import CreateAnalysis from "../AnalysisManagement/CreateAnalysis/CreateAnalysis";
import ViewAllVariables from "../ProjectManagement/ViewAllVariables/ViewAllVariables";
import ViewAllTabulations from "../ProjectManagement/ViewAllTabulations/ViewAllTabulations";
import { Container, Spinner } from "react-bootstrap";
import CommanPopover from "../SharedFunctionality/CommanPopover/CommanPopover";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Store/actions";
import { useHistory } from "react-router-dom";
import { setLocalStorage } from "../../Shared/helpers";
import { Auth, Hub } from "aws-amplify";
import axios from "axios";
import Trends from "../TrendsManagement/Trends";
import Features from "../Features/Features";
import Resource from "../ResourcesManagement/Resource";
import BannerMessage from "../../Components/BannerMessage/BannerMessage"

/* import ReactGA from 'react-ga'; */
import { SignOut } from "../../Store/utils/common.utils";
import { useIdleTimer } from "react-idle-timer";
import Logout from "../UserManagement/Logout/Logout";
import { updateService } from "../../Shared/UpdateCart";
import moment from "moment";
import AnalysisExport from "../../Components/UI/AnalysisExportTemplate/AnalysisExport";
import { Refresh } from "@material-ui/icons";
import RefreshPopoverContext from "../../Store/ContextApi/RefreshPopoverContext";
import TrendsData from "../TrendsManagement/TrendsData/TrendsData";
import { TrendsPrint } from "../../Components/UI/TrendsPrint/TrendsPrint";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import FAQ from "../../Components/FAQ/FAQ";
import FaqTrends from "../../Components/FaqTrends/FaqTrends";
import UpdateDetails from "../UserManagement/UpdateDetails/UpdateDetails";
import MediaRoom from "../MediaRoom/MediaRoom";
import GssReports from "../Media/GssReports/GssReports";
import ViewTrends from "../TrendsManagement/ViewTrends/ViewTrends";
import CiteGSS from "../../Components/CiteGSS/CiteGSS"
import WeightingHelp from "../../Components/WeightingHelp/WeightingHelp"
import StdError from "../../Components/StdError/StdError";
import ProfGuide from "../../Components/ProfGuide/ProfGuide";
import SearchVariable from "../../Components/SearchVariable/SearchVariable";
import Tabulations from "../../Components/Tabulations/Tabulations";
import Extract from "../../Components/Extract/Extract"
import PopularVariables from "../../Components/PopularVaribales/PopularVariables";
import PopularTabs from "../../Components/PopularTabs/PopularTabs";
import NewRegisteredUser from "../../Components/NewRegisteredUser/NewRegisteredUser"
import DataloadTesting from "../../Components/DataloadTesting/DataloadTesting";
import TicketTracker from "../../Components/TicketTracker/TicketTracker";
const routes = [
  { path: "/", name: "None", Component: Home, breadcrumb: "None" },
  { path: "/home", name: "None", Component: Home, breadcrumb: "None" },
  { path: "/sign_in", name: "Sign In", Component: SignIn, breadcrumb: "None" },
  {
    path: "/user/contact",
    name: "Contact",
    Component: Contact,
    breadcrumb: "None",
  },
  {
    path: "/user/sign_up",
    name: "None",
    Component: Registration,
    breadcrumb: "None",
  },
  {
    path: "/about",
    Component: About,
    name: "About GSS Data Explorer",
    breadcrumb: "None",
  },
  {
    path: "/users/:id/workspaces",
    Component: workspaces,
    name: "Projects",
    breadcrumb: "None",
  },
  {
    path: "/confirmationCode",
    Component: ConfirmCode,
    name: "Confirmation Code",
    breadcrumb: "None",
  },
  {
    path: "/users/confirmation",
    Component: ResendConfirm,
    name: "",
    breadcrumb: "None",
  },
  {
    path: "/preferences",
    Component: UserSettings,
    name: "Preferences",
    breadcrumb: "None",
  },
  {
    path: "/users",
    Component: AdminUsers,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/users/new/:id?",
    Component: AdminCreateUser,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/users/:id",
    Component: viewUserInfo,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/users/edit/:id",
    Component: editUserInfo,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/user/password/new",
    Component: ForgotPassword,
    name: "Forgot Password",
    breadcrumb: "None",
  },
  {
    path: "/user/project",
    name: "None",
    Component: Projects,
    breadcrumb: "None",
  },

  {
    path: "/MyGSS",
    name: "None",
    Component: DevProjects,
    breadcrumb: "None",
  },

  {
    path: "/trends",
    name: "Trends",
    Component: Trends,
    breadcrumb: "None",
  },

  {
    path: "/user/ViewAllVariables",
    name: "None",
    Component: ViewAllVariables,
    breadcrumb: "None",
  },
  {
    path: "/user/ViewAllTabulations",
    name: "None",
    Component: ViewAllTabulations,
    breadcrumb: "None",
  },
  { path: "/projectCreate", Component: ProjectCreate, breadcrumb: "None" },

  {
    path: "/variables/vfilter",
    Component: SearchPage,
    name: "Search Data",
    breadcrumb: "None",
  },
  { path: "/error", Component: ErrorPage, name: "None", breadcrumb: "None" },
  {
    path: "/user_searches/:id",
    Component: UserSearchTable,
    name: "User Searches",
    breadcrumb: "None",
  },

  {
    path: "/variables/:id/vshow",
    Component: VariableDetails,
    name: "Search Data",
    breadcrumb: "Variable Detail",
  },
  {
    path: "/variables/data_cart",
    Component: ViewAllCartItems,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/user_searches/:id/search_summary",
    Component: UserSearchSummary,
    name: "Search Name",
    breadcrumb: "None",
  },
  {
    path: "/vtrends/:id",
    Component: TrendsData,
    name: "Trends",
    breadcrumb: "yes",
  },

  {
    path: "/resources",
    name: "Help and Resources",
    Component: Resource,
    breadcrumb: "None",
  },
  {
    path: "/mediaRoom",
    name: "Media Room",
    Component: MediaRoom,
    breadcrumb: "None",
  },

  {
    path: "/features",
    name: "GSS Features",
    Component: Features,
    breadcrumb: "None",
  },
  {
    path: "/extractHistory",
    Component: ExtractManagement,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/bannerMessage",
    name: "Banner Message",
    Component: BannerMessage,
    breadcrumb: "BannerMessage",
  },
  {
    path: "/popularVariables",
    name: "Popular Variables",
    Component: PopularVariables,
    breadcrumb: "PopularVariables",
  },
  {
    path: "/popularTabs",
    name: "Popular Tabs",
    Component: PopularTabs,
    breadcrumb: "PopularTabs",
  },
  {
    path: "/NewRegisteredUser",
    name: "New Registered User",
    Component: NewRegisteredUser,
    breadcrumb: "New Registered User",
  },
  {
    path: "/DataloadTesting",
    name: "Dataload Testing",
    Component: DataloadTesting,
    breadcrumb: "Dataload Testing",
  },
  // {
  //   path: "/TicketTracker",
  //   name: "Ticket Tracker",
  //   Component: TicketTracker,
  //   breadcrumb: "Ticket Tracker",
  // },
  {
    path: "/extract_summary/:id",
    name: "Extracts",
    Component: ExtractSummary,
    breadcrumb: "Name",
  },
  {
    path: "/extractlist",
    Component: Extracts,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/revisions",
    Component: RevisionHistory,
    name: "Revision History",
    breadcrumb: "None",
  },
  {
    path: "/gss_data",
    Component: QuickDownloads,
    name: "Quick Downloads",
    breadcrumb: "None",
  },
  {
    path: "/analysisHistory",
    Component: AnalysisHistory,
    name: "None",
    breadcrumb: "None",
  },
  {
    path: "/analysis_summary/:id",
    Component: AnalysisSummary,
    name: "Tabulations",
    breadcrumb: "Cross",
  },
  {
    path: "/createExtract",
    Component: CreateExtract,
    name: "Extracts",
    breadcrumb: "Create Extract",
  },
  {
    path: "/createAnalysis",
    Component: CreateAnalysis,
    name: "Tabulations",
    breadcrumb: "Create Tabulation",
  },
  { path: "/logout", Component: Logout, name: "Logout", breadcrumb: "Logout" },
  {
    path: "/analysis_summary_export",
    Component: AnalysisExport,
    name: "Tabulations",
    breadcrumb: "None",
  },
  {
    path: "/trends_print",
    Component: TrendsPrint,
    name: "Trends",
    breadcrumb: "None",
  },
  {
    path: "/terms_and_conditions",
    Component: TermsAndConditions,
    name: "Terms and Conditions",
    breadcrumb: "None",
  },
  {
    path: "/privacy_policy",
    Component: PrivacyPolicy,
    name: "GSS Data Explorer Site Privacy Policy",
    breadcrumb: "None",
  },
  {
    path: "/FAQ",
    Component: FAQ,
    name: "GSS Frequently Asked Questions",
    breadcrumb: "None",
  },
  {
    path: "/faq_trends",
    Component: FaqTrends,
    name: "GSS Data Explorer Key Trends Documentation",
    breadcrumb: "None",
  },
  {
    path: "/update_details",
    Component: UpdateDetails,
    name: "Update User Details",
    breadcrumb: "None",
  },
  {
    path: "/mediaRoom",
    Component: MediaRoom,
    name: "Media Room",
    breadcrumb: "None",
  },
  {
    path: "/gssReports",
    Component: GssReports,
    name: "GSS Reports",
    breadcrumb: "None",
  },
  {
    path: "/gssReports/:id",
    Component: GssReports,
    name: "GSS Reports",
    breadcrumb: "None",
  },
  {
    path: "/viewtrends",
    Component: ViewTrends,
    name: "View Trends",
    breadcrumb: "None",
  },
  {
    path: "/gsscite",
    Component: CiteGSS,
    name: "Cite the GSS",
    breadcrumb: "None",
  },
  {
    path: "/gssweighting",
    Component: WeightingHelp,
    name: "Weighting Help",
    breadcrumb: "None",
  },
  {
    path: "/gss_stdError",
    Component: StdError,
    name: "Calculating Design-Corrected Standard Errors",
    breadcrumb: "None",
  },
  {
    path: "/prof_guide",
    Component: ProfGuide,
    name: "Professor's Guide",
    breadcrumb: "None",

  },
  {
    path: "/search_variables",
    Component: SearchVariable,
    name: "Search Variables",
    breadcrumb: "None",
  },
  {
    path: "/tabulations",
    Component: Tabulations,
    name: "Tabulations",
    breadcrumb: "None",
  },
  {
    path: "/extracts",
    Component: Extract,
    name: "Extract",
    breadcrumb: "None",
  }
];
function HomePage(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const authDetails = useSelector((state) => state.authReducer);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          break;
        case "signOut":
          SignOut();
          break;
        case "oAuthSignOut":
          SignOut();
          break;
      }
    });

    if (!window.myVar && localStorage.isSignedIn === "true") {
      getCurrentSession();
    }
    authenticateUser();
  }, []);

  const [analysisReloaded, setAnalysisReloaded] = useState(true);
  const [extractReload, setExtractReload] = useState(true);
  const contextValue = {
    analysisReloaded,
    setAnalysisReloaded,
    extractReload,
    setExtractReload,
  };

  const handleOnIdle = (event) => {
    if (
      localStorage.getItem("isSignedIn") == "true" &&
      localStorage.staySignedIn === "false" &&
      !authDetails.isSocialLogin
    ) {
      history.push("/logout");
    }
  };

  const handleOnActive = (event) => {
    //console.log('last active handleOnActive', getLastActiveTime());
  };

  const handleOnAction = (event) => {
    //  console.log('last active handleOnAction', getLastActiveTime());
  };
  // const subscription = updateService.onAlert().subscribe(message => {
  //   if (message.text == 'signIn') {
  //     alert('sign In done');
  //   }else{
  //     alert('no');
  //   }
  // });

  // Start Idle Timer
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: localStorage.idleTimeout
      ? parseInt(localStorage.idleTimeout) * 60 * 1000
      : 24 * 60 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  async function authenticateUser() {
    await Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        dispatch(
          setAuth({
            token: user.signInUserSession.idToken.jwtToken,
            isSignedIn: true,
            username: user.username,
            fullName:
              user.signInUserSession.idToken.payload.name +
              " " +
              (user.signInUserSession.idToken.payload.family_name
                ? user.signInUserSession.idToken.payload.family_name
                : ""),
            email_verified:
              user.signInUserSession.idToken.payload.email_verified,
            email: user.signInUserSession.idToken.payload.email,
            firstName: user.signInUserSession.idToken.payload.name,
            lastName: user.signInUserSession.idToken.payload.family_name,
            department:
              user.signInUserSession.idToken.payload["custom:departments"],
            organization:
              user.signInUserSession.idToken.payload["custom:organization"],
            isSocialLogin:
              user.signInUserSession.idToken.payload.hasOwnProperty(
                "identities"
              ),
            createdAt: user.signInUserSession.idToken.payload.hasOwnProperty(
              "identities"
            )
              ? user.signInUserSession.idToken.payload.identities[0][
                  "dateCreated"
                ]
              : null,
            isAdmin:
              user.signInUserSession.accessToken.payload["cognito:groups"][0] ==
              "norc-admins",
            runSessionTimeout: false,
          })
        );
        setLocalStorage(user);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err === "The user is not authenticated") {
          // localStorage.clear();
        }
        if (/error_description/.test(window.location.href)) {
          const urlParams = new URLSearchParams(window.location.search);
          const myParam = urlParams.get("error_description");
          if (myParam.includes("access_denied")) {
            window.history.pushState(
              {},
              null,
              process.env.REACT_APP_AWS_SIGN_IN_URL
            );
          } else if (myParam.includes("[email]")) {
            window.history.pushState(
              {},
              null,
              process.env.REACT_APP_AWS_SIGN_IN_URL
            );
            alert(
              "Cannot create account using Facebook. As email is not associated with your Facebook account."
            );
          } else {
            let status = myParam.includes("PreSignUp");
            if (status) {
              let extractErrorMsg = myParam.slice(28, myParam.length - 1);
              window.history.pushState(
                {},
                null,
                process.env.REACT_APP_AWS_SIGN_IN_URL
              );
              alert(extractErrorMsg);
            } else {
              window.history.pushState(
                {},
                null,
                process.env.REACT_APP_AWS_SIGN_IN_URL
              );
              alert(myParam);
            }
          }
        }
        console.log(err);
      });
  }
  console.log(process.env.REACT_APP_AWS_SIGN_IN_URL,'process')

  async function getCurrentSession() {
    //alert('get curr called');
    const timeNow = moment();
    const idleTime = localStorage.idleTimeout
      ? parseInt(localStorage.idleTimeout) * 60 * 1000
      : 24 * 60 * 60 * 1000;
    //Check Session Timeout for user stay sign is false , if browser closed and open after 24 hours, then expire session
    if (
      localStorage.lastTokenExpTime &&
      localStorage.getItem("isSignedIn") == "true" &&
      localStorage.staySignedIn === "false" &&
      !authDetails.isSocialLogin
    ) {
      let lastExpTime = moment.unix(parseInt(localStorage.lastTokenExpTime));
      if (timeNow.diff(lastExpTime, "milliseconds") >= idleTime) {
        history.push("/logout");
      }
    }

    try {
      await Auth.currentSession()
        .then((userToken) => {
          if (
            parseInt(localStorage.lastTokenExpTime) !=
            userToken.idToken.payload.exp
          ) {
            localStorage.lastTokenExpTime = userToken.idToken.payload.exp;
          }
          axios.defaults.headers.common["Authorization"] =
            userToken.getIdToken().jwtToken;
          const expTime = moment.unix(userToken.idToken.payload.exp);
          const timeNow = moment();
          let refreshTime = expTime.diff(timeNow, "milliseconds");
          refreshTime = moment(refreshTime).add(1, "seconds");
          refreshTime = refreshTime > 0 ? refreshTime : 0;
          // refreshTime = 10000; temp
          setTimeout(() => {
            getCurrentSession();
          }, refreshTime);
        })
        .catch((err) => {
          console.log(err);
          if (err.code == "NetworkError") {
            alert("Network Error, Kindly Refresh");
          } else {
            history.push("/logout");
          }
        });
    } catch (err) {
      console.log(err);
      if (err.code == "NetworkError") {
        alert("Network Error, Kindly Refresh");
      } else {
        history.push("/logout");
      }
    }
  }

  if (authDetails.isSignedIn) {
    localStorage.userId = "";
    axios.defaults.headers.common["Authorization"] = authDetails.token;
    if (authDetails.runSessionTimeout) {
      getCurrentSession();
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#F3901D",
        }}
      >
        <Spinner animation="grow" />
      </div>
    );
  } else {
    return (
      <>
        <div
          className={
            window.location.pathname === "/analysis_summary_export" ||
            window.location.pathname === "/trends_print"
              ? ""
              : "or-content or-fixed"
          }
          id="orbody"
        >
          {window.location.pathname === "/analysis_summary_export" ||
          window.location.pathname === "/trends_print" ? null : (
            <div className="ui-layout-north">
              <Header />
            </div>
          )}
          <div
            className={
              window.location.pathname === "/analysis_summary_export" ||
              window.location.pathname === "/trends_print"
                ? ""
                : "ui-layout-center container-fluid"
            }
            id="ui-layout-center"
          >
            <RefreshPopoverContext.Provider value={contextValue}>
              <CenterSection>
                {window.location.pathname === "/analysis_summary_export" ||
                window.location.pathname === "/trends_print" ? null : (
                  <>
                    <BreadCrumb data={routes} />
                    {localStorage.getItem("isSignedIn") == "true" || authDetails.isSignedIn ||
                        authDetails.isSocialLogin ? (
                      <div>
                        <CommanPopover />
                      </div>
                    ) : undefined}
                  </>
                )}
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return authDetails.isSignedIn &&
                        authDetails.isSocialLogin &&
                        new Date(
                          new Date(+authDetails.createdAt).toUTCString()
                        ).getTime() +
                          1 * 20000 >
                          new Date().getTime() ? (
                        <Redirect to="preferences" />
                      ) : authDetails.isSignedIn &&
                        authDetails.isSocialLogin &&
                        new Date(
                          new Date(+authDetails.createdAt).toUTCString()
                        ).getTime() +
                          1 * 20000 <=
                          new Date().getTime() ? (
                        <Redirect to="/user/project" />
                      ) : (
                        <Home />
                      );
                    }}
                  />
                  {routes.map(({ path, Component }, key) => (
                    <Route exact path={path} key={key} component={Component} />
                  ))}
                </Switch>
              </CenterSection>
            </RefreshPopoverContext.Provider>
          </div>
          {window.location.pathname === "/analysis_summary_export" ||
          window.location.pathname === "/trends_print" ? null : (
            <div className="ui-layout-south">
              <Footer />
            </div>
          )}
        </div>
      </>
    );
  }
}

/* export default withRouter(HomePage); */
export default HomePage;