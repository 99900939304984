import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BigButton from "../../Components/UI/BigButton/BigButton";
import SaveToModel from "../../Components/UI/SaveToModel/SaveToModel";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory, useLocation } from "react-router-dom";
import "../../Containers/ProjectManagement/DevProjects/DevProjects.css";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useSelector } from "react-redux";
import PopularVariables from "../PopularVaribales/PopularVariables";
import PopularVaribalesAnalysis from "../PopularVaribalesAnalysis/PopularVaribalesAnalysis";
import MyGssVariables from "../MyGssVariables/MyGssVariables";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const PopularTabs = (props) => {
  const [toggelSaveToModel, setToggelSaveToModel] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search); // id=123
  let page = params.get("page"); // 123
  const history = useHistory();

  const handleChange = (event, newValue) => {
    if (page) {
      history.push("/PopularTabs");
    }
    setValue(newValue);
  };

  const authDetails = useSelector((state) => state.authReducer);
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (page == "Analysis Variabls") {
      setValue(0);
    } else if (page == "Extract Variables") {
      setValue(1);
    } else if (page == "myGSS Variables") {
      setValue(2);
    }
  }, [page]);
  useEffect(() => {
    if (localStorage.isAdmin != "true") {
      props.history.push("/error");
      return;
    }
  }, []);

  return (
    <Container className="mt-5 h-100 my-gss-page">
      <Row>
        <Col xs="12" md="12" lg="12" className="mt-2">
          <Row className="mb-4">
            <Col as="h3">Popular Variables</Col>
          </Row>

          <Row>
            <div className={classes.root + " mx-2"}>
              <AppBar position="static" color="default">
                <Tabs
                  className="mygss-tabs"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#4f977d",
                    },
                  }}
                  aria-label="full width tabs example"
                >
                  <Tab
                    label={
                      <Tooltip title={<div>Variables used in Analysis</div>}>
                        <span>
                          Analysed Variables{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Tooltip title={<div>Variables used in Extract</div>}>
                        <span>
                          {" "}
                          Extracted Variables{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <Tooltip title={<div>Variables present in Analysis and Extract Carts in MyGSS</div>}>
                        <span>
                          {" "}
                          MyGSS Cart Variables{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel
                  component={"span"}
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  {" "}
                  <PopularVaribalesAnalysis key={5} />
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  {" "}
                  <PopularVariables key={5} />
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  <MyGssVariables />
                </TabPanel>
              </SwipeableViews>
            </div>

            {toggelSaveToModel ? (
              <SaveToModel
                modalShow={toggelSaveToModel}
                setToggelSaveToModel={setToggelSaveToModel}
              />
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PopularTabs;
