import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { updateService } from "../../../Shared/UpdateCart";
import ReportsContext from "../../../Store/ContextApi/ReportsContext";
import ReportsTabs from "./ReportsTab";
import "./GssReports.css";
import ReportsYearsRange from "./ReportsYearsRange";
import ReportsYearsSpecific from "./ReportsYearsSpecific";
import axios from "axios";
// import ReportsYearDropdown from "./ReportsYearDropdown";
const GssReports = (props) => {
  useEffect(() => {
    fetchYearsJson();
  }, []);
  
  const [inputs, setInputValue] = useState({
    keyword: "",
    author: "",
    category: props.match ? parseInt(props.match.params.id) : 1,
    isCleared: false,
  });

  const handleChange = (e) => {
    setInputValue({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const [isUpdateGrid, setUpdateGrid] = useState(false);
  /* Years Filter Start */
  const [isRange, setIsRange] = useState(true);
  const [defaultYearRange, setDefaultYearRange] = useState([]);
  const [yearRange, setYearRange] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const fetchYearsJson = () => {
    axios
      .get(process.env.PUBLIC_URL + "/config/year_range.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        let data = response.data,
          defaultYearRange = [data?.min_year, response?.data?.max_year]; // Set default Min & Max Year
        setDefaultYearRange([...defaultYearRange]);
        setYearRange([...defaultYearRange]);
        setAllYears(Object.keys(response?.data?.year_available));
      });
  };
  /* Years Filter End */

  const handleSearchSubmit = () => {
    setUpdateGrid(true);
  };

  const clearFilters = () => {
    setInputValue({
      ...inputs,
      ["keyword"]: "",
      ["author"]: "",
      ["isCleared"]: true,
    });
    setYearRange(defaultYearRange);
  };

  const handleChild = () => {
    setUpdateGrid(false);
    setInputValue({
      ...inputs,
      ["isCleared"]: false,
    });
  };

  const clickYearsToggle = () => {
    setYearRange(defaultYearRange);
    setIsRange(!isRange);
    console.log("allYears: " + allYears);
  };

  return (
    <div className="noscroll gss-vfilter pb-0" id="ws-content">
      <div className="container">
        <div className="row my-3">
          <div className="col">
            {/* Year Dropdown */}
            {isRange ? (
              <ReportsYearsRange
                yearRange={yearRange}
                setYearRange={setYearRange}
              />
            ) : (
              <ReportsYearsSpecific allYears={allYears} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <span>
                <strong>Keyword</strong>
              </span>
              <input
                autoComplete="off"
                className="no-border form-control"
                id="keyword"
                name="keyword"
                placeholder="Type Keyword"
                type="text"
                value={inputs.keyword}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <span>
                <strong>Authors</strong>
              </span>
              <input
                autoComplete="off"
                className="no-border form-control"
                id="author"
                name="author"
                placeholder="Search by Author"
                type="text"
                value={inputs.author}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 align-self-center">
            <div className="row justify-content-around m-3 m-lg-0 m-xl-0 ">
              <button
                type="button"
                name="commit"
                className="gss-btn btn-orange"
                onClick={handleSearchSubmit}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {/* {showClearFilter ? ( */}
        {true ? (
          <div className="row mb-1" style={{ marginTop: "-15px" }}>
            <div className="col">
              <span
                onClick={clearFilters}
                className="font-small custom-modal-title cursor-pointer-hand"
              >
                Clear Filters
              </span>
            </div>
          </div>
        ) : undefined}
      </div>
      <hr className="mt-0 mx-2" />

      <ReportsContext.Provider
        value={{
          value1: [inputs, setInputValue],
          value2: [yearRange, setYearRange],
          value3: defaultYearRange,
        }}
      >
        <ReportsTabs isUpdateGrid={isUpdateGrid} handleChild={handleChild} />
      </ReportsContext.Provider>
    </div>
  );
};

export default GssReports;