export default function Validation(field, value) {

    const isRequired = () => {
        if (value === '' || value.trim() === '' || value === undefined) {
            let name = field.charAt(0).toUpperCase() + field.slice(1);
            return {
                errorMessage: `${name} is required`,
                errorStatus: true
            }
        }
    }

    const regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    const validateEmail = () => {
        /* if(value === '' || value === undefined){
            return {
                errorMessage: "Email can't be blank",
                errorStatus: true
            }
        }else{ */
        return {
            errorStatus: regEmail.test(value),
            errorMessage: regEmail.test(value) ? "" : "Email is Invalid",
        }
        /* } */
    }

    //const regPassword = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#&*])|((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[#&*]))|((?=.*[0-9])(?=.*[A-Z]))|((?=.*[#&*])(?=.*[A-Z]))|((?=.*[0-9])(?=.*[#&*])))(?=.{8,}).*$/g;
    //const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]+$/;
    const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[\s])(?=.*?([^\w]|[_])).+$/;
    const validatePassword = () => {
        /* if (value === '' || value === undefined) {
            return {
                errorMessage: "Password can't be blank",
                errorStatus: true
            }
        } else { */
        let isValid = regPassword.test(value);
        return {
            errorStatus: isValid,
            errorMessage: isValid ? "" : "Must have:At least 8 characters. At least two of the following:An upper case letter. A lowercase letter. A number. A special character (i.e #,&,*)",
        };
        /* } */
    }

    if (field === 'username') {
        return isRequired();
    }

    if (field === 'email') {
        let checkRequired = isRequired();
        if (checkRequired?.errorStatus) {
            return checkRequired;
        } else {
            return validateEmail();
        }
    }

    if (field === 'password') {
        let checkRequired = isRequired();
        if (checkRequired?.errorStatus) {
            return checkRequired;
        } else {
            return validatePassword();
        }
    }

    if (field === 'passwordConfirmation') {
        return isRequired();
    }

}


