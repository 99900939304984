import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Container } from "react-bootstrap";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import TableauEmbed from "../../../Components/TableauContainer/TableauEmbed";
import FormControl from "@material-ui/core/FormControl";
import { Link } from "react-router-dom";
import ShareButton from "../TrendsShare/ShareButton";
import { environment } from "../../../environment";
import TrendMeasures from "../TrendMeasures/TrendMeasures";
import "./TrendsDetails.css";
import { ClickAwayListener } from "@material-ui/core";
import TrendsTable from "../TrendsTable/TrendsTable";
import { AnalysisSummaryTemplate } from "../../../Components/UI/AnalysisSummaryTemplate/AnalysisSummaryTemplate";
import Loader from "../../../Components/UI/Loader/Loader";
import QuestionDropDown from "../QuestionDropdown";
import { encodeUri } from "../../../Store/utils/common.utils";
import { useSelector } from "react-redux";
import BreakDownDd from "../BreakDownDd";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
}));

const TrendsDetails = (props) => {
  const classes = useStyles();
  const authDetails = useSelector((state) => state.authReducer);
  const [selectedCategory, setCategory] = useState(
    props.category ? props.category : ''
  );
  const [selectedSubCategory, setSubCategory] = useState(
    props.subCategory ? props.subCategory : ''
  );
  const [tableauUrl, setTableauUrl] = useState('');
  const [tabUrlWithParam, setTabUrlWithParams] = useState('');
  const [tabUrlWithParamForPrint, setTabUrlWithParamsForPrint] = useState('');
  const [selectedQuesVal, setQuestionValue] = useState('');
  const [varName, setVarName] = useState('');
  const [selectedBreakDownVal, setBreakDownValue] = useState('');
  const [breakDownArr, setBreakdownArr] = useState([]);
  const [questionArr, setQuestionArr] = useState([]);
  const [variableArr, setVariableArr] = useState([]);
  const [notes, setNotes] = useState();
  const [measuresArr, setMeasuresArr] = useState();
  const [showCategories, setShowCategories] = useState(false);
  const [toggleTable, setToggleTable] = useState(false);
  const componentRef = useRef();
  const [selectedMeasure, setMeasure] = useState();
  const [exportFlag, setExportFlag] = useState(false);
  const [isShowActions, showActions] = useState(false);
  const [aboutInfo, setAboutInfo] = useState([]);


  const [data, setData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socialMediaUrl, setSocialMediaUrl] = useState('');

  useEffect(() => {
    if (selectedCategory) {
      getMeasures();
    }
  }, []);

  useEffect(() => {
    if (selectedBreakDownVal || selectedQuesVal || selectedMeasure) {
      createTableauUrl();
      createTableauUrlForPrint();
    }
  }, [selectedQuesVal, selectedBreakDownVal,selectedMeasure]);

  const getMeasures = () => {
    axios({
      method: "POST",
      url: environment.getMeasures,
      data: {
        category: selectedCategory ? selectedCategory.trim() : '',
      },
    }).then((response) => {
      if (response.data) {
        setMeasuresArr(response.data); /* Popover Measures Data */
        setTableauUrl(response.data.TableauUrl); /* Setting Tableau Url */

        /* Redirect from Social Media, Load Dropdown according to params */
        let isParams = false;
        if (props.paramsVal?.varName && props.isInitialLoad) {
          let getMsrData;
          for (let i = 0; i < response.data?.subCategories?.length; i++) {
            let ind = response.data.subCategories[i].subTopic?.findIndex(st => st.varName === props.paramsVal?.varName);
            if (ind > -1) {
              getMsrData = response.data.subCategories[i].subTopic[ind];
              setSubCategory(response.data.subCategories[i].name);
              isParams = true;
              break;
            }
          }
          if (getMsrData) {
            getMeasureInfo(getMsrData);
          }
        }
        /* End: Redirect from Social Media */

        if (!isParams) { /* If no params then setting first subcategory measure */
          let index;

          if (response.data) {
            index = response.data.subCategories.findIndex(
              (item) => item.name.trim() === selectedSubCategory
            );
          }
          index = index > -1 ? index : 0;
          if (selectedSubCategory === '') {
            setSubCategory(response.data.subCategories[index].name);
          }
          getMeasureInfo(response.data.subCategories[index].subTopic[0]);
        }
      }
    });
  };

  /* Pass Variable and Get Question , Breakdown, variable */
  const getMeasureInfo = (obj, subCategoryName) => {
    setShowCategories(false);
    setMeasure(obj.cat);
    setVarName(obj.varName);
    if (subCategoryName) {
      setSubCategory(subCategoryName);
    }
    let req = {
      var: obj.varName,
    };
    axios
      .post(`${environment.getMeasureInfo}`, req)
      .then((resp) => {
        if (resp.data.question && typeof resp.data.question[0] !== "string") {
          resp.data.question = resp.data.question.map((obj) => obj.name);
        }
        setBreakdownArr(resp.data?.breakdown);
        setQuestionArr(resp.data?.question);
        let brkDown, question;
        /* If params then setting Question and dropdown accordingly */
        if (props.isInitialLoad && Object.keys(props.paramsVal).length && props.paramsVal.breakdown) {
          let index = resp.data?.breakdown.findIndex((item) => item.label === props.paramsVal.breakdown);
          brkDown = resp.data.breakdown[index].label;
          question = props.paramsVal.questionResponse;

        } else {
          /* If no params then setting First value of Question and dropdown */
          brkDown = resp.data?.breakdown[0].label;
          question = resp.data?.question[0];
        }
        setBreakDownValue(brkDown);
        setQuestionValue(question);
        setVariableArr(resp.data?.variable);
        setNotes(resp.data?.notes);
        getChartData(obj.cat, brkDown, question);
        if (!isShowActions) {
          showActions(true);
        }
        getTrendsAbout(resp.data?.breakdown.find(item => item.label === brkDown).name);
        props.updateParamsCall(); //clear Params
      })
      .catch((error) => {
        alert(error);
      });
  };
  /* End: Pass Variable and Get Question , Breakdown, variable */

  const createTableauUrl = () => {
    const fixedParams = "?:embed=y&:showVizHome=n&:jsdebug=y&:tabs=n&:toolbar=n&:showShareOptions=n&";
    let measure_label = selectedMeasure,
      measure_category = selectedQuesVal,
      breakDownLevel = selectedBreakDownVal,
      parameters = "&Measure%20Category=" + encodeUri(measure_category) + "&Breakdown%20Label=" + encodeUri(breakDownLevel),
      urlForTableau = tableauUrl + fixedParams + parameters + "&Measure%20Label=" + encodeUri(measure_label),
      socialMediaUrl = window.location.protocol + "//" + window.location.host + "/trends?";
    socialMediaUrl = socialMediaUrl + "category=" + encodeUri(selectedCategory) + "&" + "measure=" + varName + parameters;
    setTabUrlWithParams(urlForTableau);
    setSocialMediaUrl(socialMediaUrl);
  };

  const createTableauUrlForPrint = () => {
    const fixedParams = "?:showVizHome=n&";
    let measure_label = selectedMeasure,
      measure_category = selectedQuesVal,
      breakDownLevel = selectedBreakDownVal,
      url = tableauUrl + fixedParams + "Measure%20Label=" + encodeUri(measure_label);
    url = url + "&Measure%20Category=" + encodeUri(measure_category);
    url = url + "&Breakdown%20Label=" + encodeUri(breakDownLevel);
    url = url + "&:format=png";
    setTabUrlWithParamsForPrint(url);
  };

  const changeQuestion = (val, isManual = false) => {
    setQuestionValue(val);
    if (isManual) {
      getChartData('', '', val);
    }
  };

  const changeBreakDown = (val, isManual = false) => {
    if (isManual) {
      getChartData('', val, '');
    }
    setBreakDownValue(val);
    getTrendsAbout(breakDownArr.find(item => item.label === val).name);
  };

  const getTrendsAbout = (val) => {
    let req = {
      breakdown: val,
    };
    axios
      .post(`${environment.getTrendsAbout}`, req)
      .then((resp) => {
        setAboutInfo(resp.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const toggleFn = () => {
    setShowCategories(!showCategories);
  };

  const handleClickAway = () => {
    setShowCategories(false);
  };

  const takePrint = () => {
    localStorage.setItem("dropdownvalue", JSON.stringify(selectedQuesVal));
    localStorage.setItem(
      "breakdropdownvalue",
      JSON.stringify(selectedBreakDownVal)
    );
    localStorage.setItem("url", JSON.stringify(tabUrlWithParamForPrint));
    window.open("/trends_print", "_blank");
  };

  const exportTrendsExcel = (type = "excel") => {
    setExportFlag(true);
    const saved = authDetails.isSignedIn ? authDetails.fullName : ''

    let req = {
      category: selectedCategory,
      measure_label: encodeUri(selectedMeasure),
      measure_category: encodeUri(selectedQuesVal),
      measure_breakdown: encodeUri(selectedBreakDownVal),
      measure_name: varName,
      creator_name: saved
    };
    let dynamicURL = `${environment.exportTrendsExcel}`;
    if (type === "pdf") dynamicURL = `${environment.exportTrendsPDF}`;
    if (type === "csv") dynamicURL = `${environment.exportTrendsCSV}`;

    axios
      .post(dynamicURL, req)
      .then((response) => {
        setExportFlag(false);
        if (type === "excel" || type === "csv") {
          window.open(response.data.presigned_url, "_self");
        } else {
          window.open(response.data.objectUrl, "_self");
        }
      })
      .catch((err) => {
        setExportFlag(false);
        alert("Something went wrong. Try Again!!");
      });
  };

  // useEffect(() => {
  //   if (toggleTable && selectedQuesVal && selectedBreakDownVal) getChartData()
  // }, [toggleTable])

  const groupByCatForTable = (data) => {
    let rData = {};
    let i = 0;
    let localYears = [];
    if (data.length) {
      data.forEach((d) => {
        if (!rData[d.BreakdownCategory]) {
          rData[d.BreakdownCategory] = [];
          ++i;
        }
        if (i === 1) {
          localYears.push(d.Year);
        }
        rData[d.BreakdownCategory].push(d);
      });
      setYears(localYears);
    }

    return rData;
  };

  const getChartData = (defaultSelectedMeasure, defaultSelectedBreakDownVal, defaultSelectedQuesVal) => {
    let req = {
      category: selectedCategory,
      measure_label:
        defaultSelectedMeasure === ''
          ? encodeUri(selectedMeasure)
          : encodeUri(defaultSelectedMeasure),
      measure_category:
        defaultSelectedQuesVal === ''
          ? encodeUri(selectedQuesVal)
          : encodeUri(defaultSelectedQuesVal),
      measure_breakdown:
        defaultSelectedBreakDownVal === ''
          ? encodeUri(selectedBreakDownVal)
          : encodeUri(defaultSelectedBreakDownVal),
    };
    setLoading(true);
    axios.post(`${environment.getChartData}`, req).then(({ data }) => {
      setData([]);
      setYears([]);
      setDataKeys([]);
      if (data.status) {
        let newData = groupByCatForTable(data.data_frame);
        setData(newData);
        setDataKeys(Object.keys(newData));
      }
      setLoading(false);
    })
      .catch((err) => {
        setData([]);
        setYears([]);
        setDataKeys([]);
        setLoading(false);
        if(err.response.data){
        alert(err.response.data.message);
        }else{
          alert("Something went wrong. Try Again!!")
        }
      });
  };

  const displayTable = () => {
    if (toggleTable) {
      return loading ? (
        <Loader />
      ) : (
        <TrendsTable
          data={data}
          dataKeys={dataKeys}
          years={years}
          selectedBreakDown={selectedBreakDownVal}
        />
      );
    }
    return false;
  };

  function variableDetails() {
    return (
      <>
        <Row className="mb-4 font-medium pt-1">GSS VARIABLES</Row>
        <Table className="mb-4">
          <Row className="text-muted font-weight-bold font-medium">
            <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pl-0">
              {" "}
              Variable{" "}
            </Col>
            <Col xs={9} sm={9} md={9} lg={9} xl={9}>
              {" "}
              Question Text{" "}
            </Col>
          </Row>
          {variableArr &&
            variableArr.map((el) => (
              <Row className="font-medium">
                <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pl-0">
                  <span>
                    <Link
                      /* to={"/variables/" + el.varId + "/vshow?back=trends"} */
                      onClick={() => props.redirectTo(el.varId)}
                    >
                      {el.name}
                    </Link>
                    <br />
                    {el.desc}
                  </span>
                </Col>
                <Col xs={9} sm={9} md={9} lg={9}
                  style={{ "whiteSpace": "pre-wrap" }}
                >
                  {el.ques?.replace("''","'")}
                </Col>
              </Row>
            ))}

          {aboutInfo?.variable_list &&
            aboutInfo?.variable_list.length > 0 &&
            aboutInfo?.variable_list?.map((el) => (
              <>
                <br />
                <Row className="font-medium">
                  <Col xs={3} sm={3} md={3} lg={3} xl={3} className="pl-0">
                    <span>
                      <Link onClick={() => props.redirectTo(el.varId)}
                      >
                        {el.name}
                      </Link>
                      <br />
                      {el.desc}
                    </span>
                  </Col>
                  <Col xs={9} sm={9} md={9} lg={9} xl={9}
                    style={{ "whiteSpace": "pre-wrap" }}
                  >
                    {el.ques}
                  </Col>
                </Row>
              </>
            ))}
        </Table>
      </>
    );
  }

  return (
    <div className="gss-trends-details">
      <div style={{ display: "none" }}>
        <AnalysisSummaryTemplate
          ref={componentRef}
          isTrends={true}
          dropdownvalue={selectedQuesVal}
          breakdropdownvalue={selectedBreakDownVal}
          url={tabUrlWithParamForPrint}
          toggleTable={toggleTable}
          isLoggedIn={authDetails.isSignedIn}
          creatorName={authDetails.isSignedIn ? authDetails.fullName : ''}
          createdAt={new Date()}
          analysisSummaryTbl={displayTable()}
          variableDetails={variableDetails()}
          selectedCategory={selectedCategory}
          selectedMeasure={selectedMeasure}
        />
      </div>
      <div className="row">
        {/* <div className="row headers"> */}

        <div className="col">
          {/* <div className="row">
            <div className="col "> */}
          <div className="mb-1">
           
              <>
                <div className='heading-16'>{selectedSubCategory}</div>
                <a className="measure" onClick={toggleFn}>
                  {selectedMeasure}
                  <i className="fa fa-caret-down ml-2"></i>
                </a>
              </>
             
            {showCategories && (
              <ClickAwayListener onClickAway={handleClickAway}>
              <>
                {" "}
                {measuresArr && (
                  <TrendMeasures
                    measuresArr={measuresArr}
                    getMeasureInfo={getMeasureInfo}
                    closeCat={toggleFn}
                  />
                )}
              </>
              </ClickAwayListener>
            )}
            
          </div>
          {/*  </div>
          </div> */}
          {/* <br /> */}

          <div className="row pull-left m-0">
            <div className="col-6 pl-1 text-secondary">
              <span className="font-medium">Question Response</span>
              <FormControl className={classes.formControl}>
                {questionArr && selectedQuesVal && questionArr.length > 0 && (
                  <QuestionDropDown
                    className={classes.menuPaper}
                    dropDownData={questionArr}
                    changeHandler={changeQuestion}
                    dropDownValue={selectedQuesVal}
                  />
                )}
              </FormControl>
            </div>

            <div className="col-6 pl-1 text-secondary">
              <span className="font-medium">Breakdown</span>

              <FormControl className={classes.formControl}>
                {breakDownArr && breakDownArr.length > 0 && (
                  <BreakDownDd
                    dropDownData={breakDownArr}
                    changeHandler={changeBreakDown}
                    dropDownValue={selectedBreakDownVal}
                  />
                )}
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      {isShowActions && (
        <>
          <ShareButton
            dropdownvalue={selectedQuesVal}
            selectedBreakDown={selectedBreakDownVal}
            takePrint={takePrint}
            componentRef={componentRef}
            setToggleTable={setToggleTable}
            toggleTable={toggleTable}
            exportTrendsExcel={exportTrendsExcel}
            exportFlag={exportFlag}
            setExportFlag={setExportFlag}
            url={tabUrlWithParamForPrint}
            socialMediaUrl={socialMediaUrl}
            years={years}
          />
          {toggleTable
            ? displayTable()
            : tabUrlWithParam && <TableauEmbed url={tabUrlWithParam} />}
          <Container className="mt-4">
            <Table>
              <Row className="text-muted font-weight-bold mb-3">
                About this trend
              </Row>
              {variableDetails()}
              {notes && (
                <>
                  <Row
                    style={{ "whiteSpace": "pre-wrap" }}
                    className="font-medium"
                  >
                    NOTES
                  </Row>
                  <Row style={{ "whiteSpace": "pre-wrap" }}>
                    {" "}
                    <div className="font-medium ">{notes}</div>
                  </Row>
                </>
              )}
              <br />
              {aboutInfo && aboutInfo?.notes && (
                <>
                  <Row
                    style={{ "whiteSpace": "pre-wrap" }}
                    className="font-medium"
                  >
                    NOTES for {selectedBreakDownVal}
                  </Row>
                  <Row style={{ "whiteSpace": "pre-wrap" }}>
                    <div className="font-medium">{aboutInfo?.notes}</div>
                  </Row>
                </>
              )}
            </Table>
          </Container>
        </>
      )}
    </div>
  );
};
export default TrendsDetails;
