const initialState = {    
        token: '',
        firstName: '',
        lastName: '',
        fullName: '',
        emailVerified: null,
        email: '',
        username: '',  
        department: '',
        organization: '',      
        isSignedIn: false,
        isSocialLogin: null,   
        createdAt: null,   
        isAdmin: false,
        runSessionTimeout: false
};
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_AUTH': {            
            return state = {
                ...state,
                token: action.payload.token,
                username: action.payload.username,
                emailVerified: action.payload.email_verified,
                email: action.payload.email,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                fullName: action.payload.fullName,
                department: action.payload.department,
                organization: action.payload.organization, 
                isSignedIn: action.payload.isSignedIn,       
                isSocialLogin: action.payload.isSocialLogin,   
                createdAt: action.payload.createdAt,       
                isAdmin: action.payload.isAdmin,
                runSessionTimeout: action.payload.runSessionTimeout
            };
        }
        default:
            return state;
    }
};

export default authReducer;