export const setAuth = (data) => {
    return {
        type: 'SET_AUTH',
        payload: data
    };
};

export const clearAuth = () => {
    return {
        type: 'USER_LOGOUT'
    }
};

export const setAnalysis =(analysis_data) =>{
    return {
        type: 'SET_ANALYSIS',
        payload: analysis_data
    };
}

export const removeAnalysis =(analysisId) =>{
    return {
        type: 'REMOVE_ANALYSIS',
        payload: analysisId
    };
}

export const setUserSearches = (usersearches_data) =>{
    return {
        type: 'SET_USERSEARCH',
        payload: usersearches_data
    };
}

export const setFiltered =(data) =>{
    return {
        type: 'SET_FILTERED_YEARS',
        payload: data
    };
}

export const setMinMax = (min_max_year) =>{
    return {
        type: 'SET_MIN_MAX_YEAR',
        payload: min_max_year
    };
}
export const setExtracts =(extract_data) =>{
    return {
        type: 'SET_EXTRACT',
        payload: extract_data
    };
}

export const setExtractName =(extract_name) =>{
    return {
        type: 'SET_EXTRACTNAME',
        payload: extract_name
    }; 
}

export const setAnalysisName =(analysis_name) =>{
    return {
        type: 'SET_ANALYSISNAME',
        payload: analysis_name
    }; 
}

export const setTrendName =(trend_name) =>{
    return {
        type: 'SET_TRENDNAME',
        payload: trend_name
    }; 
}