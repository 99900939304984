import { mergeAnalysisData, removeAnalysisData } from "../utils/variable.utils";

const initialState = {
    analysis_data: {}
};

const analysisReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ANALYSIS': {
            const { analysis_data } = action.payload;
            let newState = mergeAnalysisData(state, analysis_data);
            return {
                ...state,
                analysis_data: newState
            }
        }
        case 'REMOVE_ANALYSIS': {
            let newState = removeAnalysisData(state, action.payload);
            return {
                ...state,
                analysis_data: newState
            }
        }
        case 'SET_ANALYSISNAME': {
            const analysis_name = action.payload;
            return {
                ...state,
                analysis_name
            }
        }
        default:
            return state;
    }
};

export default analysisReducer;