import React, { useEffect, useState, useRef } from "react";
import { Auth } from "aws-amplify";
import { NavLink, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./SignIn.css";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import { checkIsRequired, setLocalStorage } from "../../../Shared/helpers";
import { setAuth } from "../../../Store/actions";
import SocialLoginButton from "../../../Components/UI/SociaLoginButton/SocialLoginButton";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { FormControlLabel } from "@material-ui/core";
import { GreenCheckbox } from "../../../Store/utils/common.utils";
import axios from "axios";
import { environment } from "../../../environment";

function SignIn(props) {
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const [disable, setDisabled] = useState(false);
  const [value, setValue] = useState({
    username: "",
    password: "",
    staySignedIn: false,
  });
  const [error, setError] = useState({ username: null, password: null });
  const [signedIn, setSignedIn] = useState(
    localStorage.isSignedIn === "true" ? true : false
  );
  const [firstTimeLoggedIn, setFirstTimeLoggedIn] = useState({
    status: null,
    data: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
  });
  const [showOldUserText, setShowOldUserText] = useState(false);
  useEffect(() => {
    if (firstTimeLoggedIn.data) {
      let response = firstTimeLoggedIn.data;

      setValue({ ...value, username: "", password: "", staySignedIn: false });
      setSignedIn(true);
      let isSocialLogin =
        response.signInUserSession.idToken.payload.hasOwnProperty("identities");

      if (!isSocialLogin) {
        if (value.staySignedIn) {
          localStorage.staySignedIn = true;
        } else {
          localStorage.staySignedIn = false;
        }
      }

      dispatch(
        setAuth({
          token: response.signInUserSession.getIdToken().jwtToken,
          isSignedIn: true,
          username: response.username,
          fullName:
            response.signInUserSession.idToken.payload.name +
            " " +
            (response.signInUserSession.idToken.payload.family_name
              ? response.signInUserSession.idToken.payload.family_name
              : ""),
          department:
            response.signInUserSession.idToken.payload["custom:departments"],
          organization:
            response.signInUserSession.idToken.payload["custom:organization"],
          email_verified:
            response.signInUserSession.idToken.payload.email_verified,
          email: response.signInUserSession.idToken.payload.email,
          firstName: response.signInUserSession.idToken.payload.name,
          lastName: response.signInUserSession.idToken.payload.family_name,
          isSocialLogin: isSocialLogin,
          createdAt: response.signInUserSession.idToken.payload.hasOwnProperty(
            "identities"
          )
            ? response.signInUserSession.idToken.payload.identities[0][
                "dateCreated"
              ]
            : null,
          isAdmin:
            response.signInUserSession.accessToken.payload[
              "cognito:groups"
            ][0] == "norc-admins",
          runSessionTimeout: true,
        })
      );
      setLocalStorage(firstTimeLoggedIn.data);
      if (firstTimeLoggedIn.status) {
        props.history.push("/preferences");
      } else {
        props.history.push("/user/project");
      }
    }
  }, [firstTimeLoggedIn.data]);

  const signIn = () => {
    Auth.signIn({
      username: value.username.split(" ").join(""),
      password: value.password,
    })
      .then((response) => {
        firstRender.current = true;
        setDisabled(false);
        setError({ ...error, username: null, password: null });
        setValue({ ...value, username: "", password: "" });
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(
            response, // the Cognito User Object
            value.password
          )
            .then((user) => {
              setIsSaving(false);
              // at this time the user is logged in if no MFA required
              //signInCallBack.call(this, user, true); //Third Param is sent as true if new user
              setFirstTimeLoggedIn({
                ...firstTimeLoggedIn,
                status: true,
                data: user,
              });
            })
            .catch((e) => {
              setIsSaving(false);
              let msg = e.message
                ? e.message
                : "Something went wrong. Try Again!!";
              setIsServerError({
                ...isServerError,
                status: true,
                message: msg,
              });
            });
        } else {
          setFirstTimeLoggedIn({
            ...firstTimeLoggedIn,
            status: false,
            data: response,
          });
        }
      })
      .catch((err) => {
        let msg = err.message
          ? err.message
          : "Something went wrong. Try Again!!";
        setIsServerError({ ...isServerError, status: true, message: msg });
        firstRender.current = true;
        setIsSaving(false);
        setDisabled(false);
        setError({ ...error, username: null, password: null });
        setValue({ ...value, username: "", password: "", staySignedIn: false });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setDisabled(true);
    isOldUser();
  };

  const handleChange = async (e) => {
    setValue({
      ...value,
      [e.target.name]:
        e.target.name == "staySignedIn" ? e.target.checked : e.target.value,
    });
    let formError = await checkIsRequired(e.target.name, e.target.value);
    setError({ ...error, [e.target.name]: formError.errorMessage });
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };

  const isOldUser = () => {
    axios
      .post(`${environment.isOldUser}`, { userName: value.username })
      .then((res) => {
        if (res?.data?.oldUser) {
          setShowOldUserText(true);
          setIsSaving(false);
          setDisabled(false);
        } else {
          setShowOldUserText(false);
          signIn();
        }
      });
  };
  if (signedIn) {
    return <Redirect to="/user/project" />;
  } else {
    return (
      <>
        <div className="gss-signin">
          <CustomModal
            title={"Error"}
            titleText="Error"
            isOpen={isServerError.status}
            setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
            inputLabel={isServerError.message}
          ></CustomModal>
          <span className="standard-text create-account-container">
            <NavLink to="/user/sign_up" className="create-account-text">
              Don't have an account? Create one here
            </NavLink>
          </span>
          <FormCard title="Sign In">
            <div className="social-login-container">
              <SocialLoginButton providerType={"Google"} />
              <SocialLoginButton providerType={"Facebook"} />
            </div>
            <div className="divider-container">
              <div className="customize-divider"></div>
              <div className="divider-text">
                <span>or</span>
              </div>
              <div className="customize-divider"></div>
            </div>

            <form
              method="post"
              className="simple_form form-horizontal"
              name="userSignInForm"
              onSubmit={handleSubmit}
              noValidate
            >
              {showOldUserText && (
                <div className="standard-text text-danger ml-4 mb-3">
                  An email with the temporary password has been sent to your
                  registered email address. Please sign in using temporary
                  password !
                </div>
              )}
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div className="row justify-content-md-center customize-username-height">
                    <div className="col-auto">
                      <label htmlFor="Username" className="col-form-label">
                        User Name
                      </label>
                    </div>
                    <div className="col-auto">
                      <div className="row">
                        <div className="col-auto">
                          <input
                            type="text"
                            name="username"
                            id="username"
                            className="form-control customize-input"
                            aria-describedby="username"
                            value={value.username}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={isSaving}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center customize-password-height">
                    <div className="col-auto">
                      <label htmlFor="Password" className="col-form-label">
                        Password
                      </label>
                    </div>
                    <div className="col-auto">
                      <div className="row customize-input-pwd-height">
                        <div className="col-auto">
                          <input
                            className="gss-input-medium form-control customize-input"
                            aria-describedby="password"
                            type="password"
                            name="password"
                            id="password"
                            value={value.password}
                            onChange={handleChange}
                            autoComplete="off"
                            disabled={isSaving}
                          />
                        </div>
                      </div>
                      <div className="row mt-2 mb-2">
                        <div className="col-auto additional-link">
                          <NavLink to="/user/password/new">
                            Forgot your password?
                          </NavLink>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-check">
                          <FormControlLabel
                            control={
                              <GreenCheckbox
                                checked={value.staySignedIn}
                                onChange={handleChange}
                                id="staySignedIn"
                                name="staySignedIn"
                                disabled={isSaving}
                              />
                            }
                            label="Stay signed in on this browser"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-auto">
                      {isSaving ? (
                        <button
                          type="button"
                          className="gss-btn btn-orange mt-4"
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          &nbsp; SIGNING IN...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="gss-btn btn-orange mt-4"
                          variant="secondary"
                          disabled={
                            disable ||
                            value.username == "" ||
                            value.username.trim() == "" ||
                            value.password == "" ||
                            value.password.trim() == ""
                          }
                        >
                          SIGN IN
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 ">
                  <p style={{ color: "#f3901d" }}>
                    FOR DATA EXPLORER (DE) 1.0 RETURNING USERS
                  </p>
                  <p>
                    Welcome to the DE 2.0! When signing in for the first time,
                    please do the following:
                  </p>
                  <ul class="a">
                    <li>
                      Use the same account username/password you previously used
                      to sign into the DE 1.0.
                    </li>
                    <li>
                      If you do not remember your password,<strong>do not use "Forgot
                      my password"</strong>. Instead, simply enter any password with your
                      previous username. This will trigger the DE 2.0 to email
                      you a temporary password you can use to sign into the DE
                      2.0. Then, you will be prompted to update your user
                      details and can set a new password. Once you've
                      successfully signed into the DE 2.0, subsequent logins
                      will use your new password.
                    </li>
                    <li>Once you've successfully signed into the DE 2.0, subsequent logins will use your new password.</li>
                  </ul>
                </div>
              </div>
            </form>
          </FormCard>
        </div>
        <span>
          <NavLink
            className="confirmation-instruction mt-2"
            to="/users/confirmation"
            exact
          >
            Didn't receive confirmation instructions?
          </NavLink>
        </span>
        <div className="clearfix"></div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.reducer,
  };
}

export default SignIn;
