import React, { useState, useEffect } from 'react'
import SelectableContext from "react-bootstrap/SelectableContext";
import { Button, Dropdown } from 'react-bootstrap'
import { Link, NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setUserSearches } from '../../../Store/actions'
import {
    Checkbox,
    makeStyles,
} from '@material-ui/core';
import { Row, Col } from "react-bootstrap";
import axios from 'axios'
import { environment } from '../../../environment';

import './UserSearches.css'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
    },
}));


const UserSearches = () => {

    const [selected, setSelected] = useState([]);
    const [userSearchData, setUserSearchData] = useState([]);
    const [expand, setExpand] = useState(true);
    const [selectAll, setSelectAll] = useState(false);
    const [checkBoxSelect, setCheckBoxSelect] = useState(false);
    const [ballotsInfo,setBallotsInfo] = useState([])
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        getUserSearch()

    }, [])

    const handleSelectAllClick = (e) => {

        if (!selectAll) {
            const newSelecteds = userSearchData.map((n) => n.id);
            setSelected(newSelecteds);
            setSelectAll(!selectAll);
            return false;
        }
        setSelectAll(!selectAll);
        setSelected([]);
    };

    const onClickSearch = (e, id) => {
        let selected_data = [...selected];
        if (e.target.checked) {
            selected_data.push(id);
        } else {
            let index = selected_data.indexOf(id);
            selected_data.splice(index, 1);
        }

        setSelected(selected_data)
    }

    const removeFromMyGSS = () => {
        // selected
         if (selected.length === 0) {
            alert("Invalid data");
            return;
        }
        axios.post(`${environment.removeMyGSS}`, { "id_list": selected }).then((res) => {
            if (res.data.status) {
                getUserSearch();
                setSelected([]);
            }
        });
    }

    const getUserSearch = () => {
        const id = +(localStorage.defaultProjectNo);
        axios.post(`${environment.getSavedUserSearch}`, { "workspace_id": id }).then((res) => {
            try{
                if (res.data.user_searches.length) {
                    let data = res.data.user_searches.map(d => {
                        if(d.criteria.subjects != null){
                        d.criteria.subjects = Array.isArray(d.criteria?.subjects) ?d.criteria?.subjects: d.criteria?.subjects.split(",")
                        }else{
                            d.criteria.subjects =[]  
                        }
                        if(d.criteria.module != null){
                        d.criteria.module = Array.isArray(d.criteria?.module) ?d.criteria?.module: d.criteria?.module.split(",")
                        }else{
                            d.criteria.module  =[]   
                        }
                        d.criteria.ballotsInfo =[]
                        if(d.criteria.years && d.criteria.years !=null && d.criteria.years.length>0 ){
                            d.criteria.years.map(item =>{
                                /* if(d.criteria.yearBallot && d.criteria.yearBallot !=null && d.criteria.yearBallot.length>0){ */
                                    // d.criteria.yearBallot.map(ballot =>{
                                    //     if(item != ballot.year){
                                    //         ballotsInfo.push({year: item, ballots:[]})
                                    //     }
                                    // }) 
                                   
                            if (!d.criteria.yearBallot?.some(el => el.year == item))
                                d.criteria.ballotsInfo?.push({year: item, ballots:[]})
                              /*   } */
                            })
                        }

                        d.expanded = false;
                        return d;
                    })
                    setUserSearchData(data);
                   // dispatch(setUserSearches({ usersearches_data: data }));
                    
                } else {
                    // if user deletes all their searches then need to set this empty else list won't refresh.
                    setUserSearchData([]);
                }
            }catch(e){
                console.log(e);                
            }
            
        });
    }

    const displayTags = (data) => {
        // .criteria.subjects
        let myTag =[]
        if(data?.criteria?.my_tags && data?.criteria?.my_tags.length > 0){
        data?.criteria?.my_tags.map((el)=>{
         myTag.push(el.name);
        }
        )}
        let sharedTag =[]
        if(data?.criteria?.shared_tags && data?.criteria?.shared_tags.length > 0){
        data?.criteria?.shared_tags.map((el)=>{
        sharedTag.push(el.name)
        })}
        let finalarr= [].concat(data?.criteria?.subjects,data?.criteria?.module,myTag,sharedTag)
        if (data.expanded) {
            return finalarr.join(", ")
        }
       let arr = finalarr.slice(0, 7).filter(function(val) { if(val) return val; }).join(", ");
       return arr
}

    const toggleTags = (id) => {
        let filterData = userSearchData.map(d => {
            if (d.id === id) {
                d.expanded = !d.expanded;
            }
            return d;
        })
        setUserSearchData(filterData)
    }

const viewEditUserSearch =(data) =>{
     dispatch(setUserSearches({ usersearches_data: data }));
   
}
    return (
        <>
            <Row className="ml-1 d-flex align-items-center ">
                <Col className="p-0 d-flex justify-content-left  flex-grow-1 align-items-center">
                    <span className="inline-block ml-3">{(userSearchData.length + ' item') + (userSearchData.length>1?'s':'')}</span>
                </Col>
                {/* <Col className="flex-grow-0">
                    <Button variant="secondary" className="px-2 py-1 mygss-export-btn">Export</Button>{' '}
                </Col> */}
                <Col className="flex-grow-0 mr-3">
                    <SelectableContext.Provider value={false}>
                        <Dropdown className="d-inline-block custom-ddl bp-2" >
                            <Dropdown.Toggle variant="light" className="mygss-action-btn">
                                <span >Actions</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as="button" className="ddl-border" onClick={(e) => handleSelectAllClick(e)}>{(selectAll) ? `Deselect All` : `Select All`}</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={removeFromMyGSS} disabled={!selected.length}>Remove from MyGSS</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </SelectableContext.Provider>
                </Col>
            </Row>

            {userSearchData.map(data => (
                <Row className="px-3 mt-2 flex-nowrap user-search-container" key={data.id}>
                    <span className="d-flex mr-3">
                        <Checkbox
                            checked={(selected.indexOf(data.id) !== -1)}
                            onClick={(event) => onClickSearch(event, data.id)}
                            inputProps={{ 'aria-labelledby': "enhanced-table-checkbox-1" }}
                        />
                    </span>

                    <ul className="list-group w-100">
                        <li className="list-group-item rounded-0">
                            <NavLink to={"/variables/vfilter?user_search_id="+data?.id}  onClick={() => viewEditUserSearch(data)}><h5>{data?.name}</h5></NavLink>
                            <ul className="list-group">
                                <li className="list-group-item rounded-0 border-0 p-0 m-0">
                                    Results
                                    <span className="details ml-1">{data?.result_count}  Variables</span>
                                   
                                </li>
                                <li className="list-group-item rounded-0 border-0 p-0 m-0">
                                    Keyword
                                    <span className="details ml-1">{data?.criteria.keyword}</span>
                                </li>
                                <li className="list-group-item rounded-0 border-0 p-0 m-0">
                                    Years Available
                                    {(data?.criteria?.years && data?.criteria?.years.length > 0 )  && 
                                     <span className="details ml-1">
                                         {data?.criteria?.ballotsInfo?.map((item, index) => item.year+ (data?.criteria?.yearBallot?.length > 0 ?',': ((data?.criteria?.ballotsInfo?.length - 1) == index ? '' : ',')))}
                                       {  data?.criteria?.yearBallot?.map(item =>
                                      ( item.year + (item.ballots.length > 0 ? (':'+item.ballots.join()) : '')) +";") }  
                                     </span>
                                   
                                        }
                                         {(data?.criteria?.yrmin || data?.criteria?.yrmax ) && <span className="details ml-1">{data?.criteria?.yrmin} to {data?.criteria?.yrmax}</span>}
                                </li>
                                <li className="list-group-item rounded-0 border-0 p-0 m-0">
                                    Tags
                                    <span className="details ml-1">{displayTags(data)}</span>
                                </li>
                                {(data?.criteria?.years && data.criteria.subjects.length > 7) ?
                                    (<li className="list-group-item rounded-0 border-0 p-0 m-0">
                                        <button className={"button-to-link"} onClick={() => toggleTags(data?.id)}>{(data.expanded) ? "See less" : "Show more"}</button>
                                    </li>) : ""}


                            </ul>
                        </li>

                    </ul>
                </Row>
            ))}


        </>
    )
}

export default UserSearches
