import React from "react";
import "./Input.css";

export default function StringInput(props) {

  const handleChange = (e) => {
    props.onChange && props.onChange(e);
  }

  const { className, value, name, placeholder } = props;
  return (
    <input
      className="form-control"
      type={props.type ? props.type : 'text'}
      value={value}
      onChange={handleChange}
      name={name}
      placeholder={placeholder}
    />
  );

}
