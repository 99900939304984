import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Dialog,
  } from "@material-ui/core";
 import React, { useState } from "react";
import "./MoveCopy.css";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth:500,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
  const MoveCopyModal = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [isMove,setMove] = useState(false)
    const [value, setValue] = React.useState('');
    React.useEffect(() => {
      // component updated Temporary Code
      console.log(props)
      if(props.isOpen && !open) {
         handleClickOpen();
         setMove(props.isMove)
         // set isOpen as false to prevent erasing your data again.
        props.setIsOpen();
      } 
    });
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
 
const copyFn =()=>{
    setMove(false)
}
  const handleChange = (event) => {
    setValue(event.target.value);
  };

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={"custom-modal " + props.class_name}
      >
        <DialogTitle id="max-width-dialog-title" className="modal_title">
          
          <h4 className="modal-title">
<span className="action">{isMove ? "Move":"Copy"}</span>  &nbsp;
<span className="name">{props.search}</span>
</h4>
        </DialogTitle>
        <DialogContent className="modal_content">
          <DialogContentText>{props.subtitle}</DialogContentText>
        {isMove ?  <div className="move" style={{"display": "block"}}>
<h5>Move this item to an existing project or a new project.</h5>
<p>
This item will no longer appear in the current project, only the project selected below. To keep the item in this project and add it to another project, you can
<a id="copy-item" href="#" onClick={copyFn}>copy</a>
it.
</p>
</div>:
<div className="copy" style={{"display": "block"}}>
<h5>Copy this item to an existing project or a new project.</h5>
<p>This item will appear both in the current project and the project selected below.</p>
</div>}
<div>
<FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select Project...</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
        >
            <MenuItem  value="new">New project...</MenuItem>
         {props.list.map(val =><MenuItem value={val.workspace_id}>{val.workspace_name}</MenuItem>)} 
       
        </Select>
      </FormControl>
</div>
        </DialogContent>
  
        <DialogActions className="modal_footer">
          <div className="col-md-6 btn-one" onClick={() => {
            setOpen(false);
            props.handleSubmit(value);
            setInputText('');
            }}>
            <Button classes={{ label: "btn-orange primary-link" }}>
            {isMove ? "Move":"Copy"}
            </Button>
          </div>
          <div className="col-md-6 btn-two">
            <Button onClick={handleClose} classes={{ label: "secondary-link" }}>
              CANCEL
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  };
  export default MoveCopyModal;
  
  MoveCopyModal.protoTypes = {};
  