import React from "react";
import BigButton from "../BigButton/BigButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import EnhancedTableCheckbox from "../EnhancedTableCheckbox/EnhancedTableCheckbox";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./EditTagModel.css";
import { removeTag } from "../../../Store/actions/variable";
import ClearIcon from "@material-ui/icons/Clear";

function getModalStyle() {
  return {
    overflow: "scroll",
  };
}

const EditTagModel = ({
  category,
  modalShow,
  selected,
  selectedObj,
  ...props
}) => {
  const dispatch = useDispatch();
  const variableByCategory = useSelector(
    (state) => state.variable.variableByCategory
  );
  const rows = variableByCategory[category.id];
  const [selectAll, setSelectAll] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleSelectAllClick = (e) => {
    if (!selectAll) {
      const newSelecteds = rows.variables.map((n) => n.vID);
      const selectedObj = rows.variables.map((n) => n);
      props.setSelected(newSelecteds);
      props.setSelectedObj(selectedObj);
      setSelectAll(!selectAll);
      return false;
    }
    setSelectAll(!selectAll);
    props.setSelected([]);
    props.setSelectedObj([]);
  };

  const onSubmit = () => {
    props.setModalShow(false);
  };

  const onCopyToTag = () => {
    props.setActionType("copyToDiffTag");
    props.setModalShow(false);
    props.setAddTagModal(true);
  };
  const onMoveToDiffTag = () => {
    props.setActionType("moveToTag");
    props.setIsMove(true);
    props.setModalShow(false);
    props.setAddTagModal(true);
  };

  const onRemoveFromTag = () => {
    let reqObj = { tag_id: category.id, variables: [...selected] };
    dispatch(removeTag(reqObj)).then(() => {
      props.getVariablelist();
    });
  };

  const onClose = () => {
    props.setSelected([]);
    props.setSelectedObj([]);
    props.setModalShow(false);
  };

  return (
    <Dialog
      open={modalShow}
      style={getModalStyle()}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      className="tag-model h-auto"
      disableBackdropClick={true}
      fullWidth={true}
    >
      <DialogTitle>
        <Col as="span" className="px-3 py-1 m-0">
          Edit Tag
        </Col>
        <Col as="span" className="px-3 py-1 m-0">
          <ClearIcon className="float-right clear-icon" onClick={onClose} />
        </Col>
      </DialogTitle>
      <DialogContent className="h-auto">
        <Row>
          <Col
            sm={8}
            md={8}
            lg={8}
            className="p-0 d-flex justify-content-left  flex-grow-2 align-items-center"
          >
            <Form.Control
              className="ml-3 mb-3 input"
              type="text"
              placeholder="Enter tag name"
              value={category.name}
              disabled
            />
          </Col>
          {/* <Col className="p-0 d-flex align-items-center" sm={4}>
                        <button className="tag-action-btn ml-2" onClick={onRemoveFromTag}>Delete Tag</button>
                    </Col> */}
        </Row>
        <Row className="ml-2 mb-1">
          {rows.variables.length +
            " item" +
            (rows.variables.length > 1 ? "s" : "")}
        </Row>
        <Row className="d-flex ml-1 mb-2">
          <button
            className="float-end tag-action-btn"
            onClick={handleSelectAllClick}
          >
            {selectAll ? `Deselect All` : `Select All`}
          </button>
          |
          <button
            className="float-end tag-action-btn"
            onClick={onMoveToDiffTag}
            disabled={selectedObj.length ? false : true}
          >
            Move to Different Tag
          </button>
          <button
            className="float-end tag-action-btn"
            onClick={onCopyToTag}
            disabled={selectedObj.length ? false : true}
          >
            Copy to Different Tag
          </button>
          <button
            className="float-end tag-action-btn"
            onClick={onRemoveFromTag}
            disabled={selectedObj.length ? false : true}
          >
            Remove from Tag
          </button>
        </Row>
        <div className={"custom-scroll mx-ht-300"}>
        <EnhancedTableCheckbox
          rows={rows.variables}
          showPagination={false}
          selected={selected}
          selectedObj={selectedObj}
          page={page}
          rowsPerPage={rowsPerPage}
          setSelected={props.setSelected}
          setSelectedObj={props.setSelectedObj}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        /></div>
      </DialogContent>
      <DialogActions className="">
        <BigButton classname="btn-cancel" onClick={onClose}>
          CANCEL
        </BigButton>
        <BigButton classname="btn-orange btn-save" onClick={onSubmit}>
          SAVE
        </BigButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditTagModel;
