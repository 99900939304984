import { React, useEffect, useState } from "react";
import Axios from "axios";
import { environment } from "../../environment";
import FormCard from "../UI/FormCard/FormCard";
import { Form } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import BootstrapTable from "react-bootstrap-table-next";
import "../PopularVaribales/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "./react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
const DataloadTesting = (props) => {
  const [data, setData] = useState([]);
  const [countL, setCountL] = useState([]);
  const [allVar, setAllVar] = useState([]);
  const [dataYB, setDataYB] = useState([]);
  const [countYB, setCountYB] = useState([]);
  const [DepData, setDepData] = useState([]);
  const [dataQ, setDataQ] = useState([]);
  const [countQ, setCountQ] = useState([]);
  const [dataID, setDataID] = useState([]);
  const [countID, setCountID] = useState([]);
  const [dataVC, setDataVC] = useState([]);
  const [countVC, setCountVC] = useState([]);
  const [dataMC, setDataMC] = useState([]);
  const [countMC, setCountMC] = useState([]);
  const [dataVNP, setDataVNP] = useState([]);
  const [dataMNP, setDataMNP] = useState([]);
  const [dataDEP, setDataDEP] = useState([]);
  const [dataGSS, setDataGSS] = useState([]);
  const [countMNP, setCountMNP] = useState([]);
  const [isShownDe, setIsShownDe] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingVNP, setLoadingVNP] = useState(true);
  const [loadingMNP, setLoadingMNP] = useState(true);
  const [loadingGSS, setLoadingGSS] = useState(true);
  const [type, setType] = useState(2022);
  const [typeD, setTypeD] = useState("Label Not Present");
  const [typeOr, setTypeOr] = useState("Advocacy Organization");
  const MyExportCSV = (props) => {
    const handleClick = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      props.onExport();
    };
    return (
      <Tooltip title="Export">
        <FileDownloadIcon style={{ color: "#50917E" }} onClick={handleClick} />
      </Tooltip>
    );
  };

  const CatagoriesJSON = () => {
    Axios.get(process.env.PUBLIC_URL + "/config/testingCatagories.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
      },
    }).then((response) => {
      setDepData(response.data);
    });
  };
  const allVariables = () => {
    Axios.get(
      process.env.PUBLIC_URL + "/config/variable_details_autocomplete.json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      }
    ).then((response) => {
      if (
        process.env.REACT_APP_AWS_SIGN_IN_URL ==
        "https://gssdataexplorer.norc.org/"
      ) {
        setAllVar(response.data.var_detail);
      }
    });
    Axios.get(
      process.env.PUBLIC_URL + "/config/variable_details_autocomplete_qa.json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      }
    ).then((response) => {
      if (
        process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-tst.norc.org/"
      ) {
        setAllVar(response.data.var_detail);
      }
    });
    Axios.get(
      process.env.PUBLIC_URL + "/config/variable_details_autocomplete_uat.json",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      }
    ).then((response) => {
      if (
        process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-uat.norc.org/"
      ) {
        setAllVar(response.data.var_detail);
      }
    });
  };
  const getNewUsers = () => {
    Axios.get(`${environment.testingDataload}`)
      .then((resp) => {
        if (resp.data) {
          let data1 = resp.data.label_not_present.map((e) => e);
          setData(data1);
          setLoading(false);
          let countl = resp.data.count_label_not_present.map((e) => e);
          setCountL(countl);
          let data2 = resp.data.years_ballots_not_present.map((e) => e);
          setDataYB(data2);
          let countYb = resp.data.count_years_ballots_not_present.map((e) => e);
          setCountYB(countYb);
          let data3 = resp.data.question_text_not_present.map((e) => e);
          setDataQ(data3);
          let countQ = resp.data.count_question_text_not_present.map((e) => e);
          setCountQ(countQ);
          let data4 = resp.data.id_not_present.map((e) => e);
          setDataID(data4);
          let countID = resp.data.count_id_not_present.map((e) => e);
          setCountID(countID);
          let data5 = resp.data.valid_cases_not_present.map((e) => e);
          setDataVC(data5);
          let countVC = resp.data.count_valid_cases_not_present.map((e) => e);
          setCountVC(countVC);
          let data6 = resp.data.missing_cases_not_present.map((e) => e);
          setDataMC(data6);
          let countMC = resp.data.count_missing_cases_not_present.map((e) => e);
          setCountMC(countMC);
          let data7 = resp.data.double_entry_present.map((e) => e);
          setDataDEP(data7);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get(`${environment.testingDataload1}`)
      .then((res) => {
        if (res.data) {
          let dataVNP =
            res.data.variables_notpresent_veriables_groupstable?.map((e) => e);
          setDataVNP(dataVNP);
          setLoadingVNP(false);
          let countVNP = res.data.Count_Module_notpresent_veriables_groupstable;
          setCountMNP(countVNP);
          let dataMNP = res.data.Module_notpresent_veriables_groupstable;
          setDataMNP(dataMNP);
          setLoadingMNP(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get(`${environment.testingDataload2}`)
      .then((res) => {
        if (res.data) {
          let dataGSS = res.data.issue_in_Gss_stats_load_and_year_ballot?.map(
            (e) => e
          );
          setDataGSS(dataGSS);
          setLoadingGSS(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log(dataVNP, "NVCGT");
  const id_VNP = [];

  for (var i = 0; i < dataVNP.length; i++) {
    id_VNP.push({ id: dataVNP[i] });
  }
  // console.log(id_VNP, "IDVNP");
  const matchedVNP = id_VNP.map((t1) => ({
    ...t1,
    ...allVar.find((t2) => t2.var_id === t1.id),
  }));
  const id_MNP = [];

  for (var i = 0; i < dataMNP.length; i++) {
    id_MNP.push({ id: dataMNP[i] });
  }
  // console.log(id_MNP, "IDMNP");

  const matchedMNP = id_MNP.map((t1) => ({
    ...t1,
    ...allVar.find((t2) => t2.var_id === t1.id),
  }));
  // console.log(matchedMNP, "matchedMNP");

  const id_GSS = [];
  for (var i = 0; i < dataGSS.length; i++) {
    id_GSS.push({ id: dataGSS[i] });
  }
  // console.log(id_GSS, "IDMNP");
  const matchedGSS = id_GSS.map((t1) => ({
    ...t1,
    ...allVar.find((t2) => t2.var_id === t1.id),
  }));
  // console.log(matchedGSS, "matchedGSS");

  useEffect(() => {
    if (localStorage.isAdmin != "true") {
      props.history.push("/error");
      return;
    }
  }, []);
  useEffect(() => {
    getNewUsers();
    setType(type);
    setTypeD(typeD);
    setTypeOr(typeOr);
    CatagoriesJSON();
    allVariables();
  }, []);
  useEffect(() => {
    setTypeD(typeD);
  }, [typeD]);
  useEffect(() => {
    setTypeOr(typeOr);
  }, [typeOr]);
  // console.log(loading, "loadin");
  const changeType = ({ target: { value } }) => {
    setType(value);
    // setTypeD(value);
    return;
  };
  const changeTypeD = ({ target: { value } }) => {
    setTypeD(value);
    return;
  };
  const changeTypeO = ({ target: { value } }) => {
    setTypeOr(value);
    return;
  };

  const columnLabel = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    { dataField: "id", text: "ID", filter: textFilter() },
    {
      dataField: "name",
      text: "NAME",
      filter: textFilter(),
    },
  ];
  const columnLabelVNP = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    { dataField: "id", text: "ID", filter: textFilter() },
    {
      dataField: "var_name",
      text: "NAME",
      filter: textFilter(),
    },
  ];

  return (
    <FormCard title="" header="Dataload Testing">
      <form className="row w-500 simple_form form-horizontal mb-4 m-0 ">
        {isShownDe && (
          <>
            <div className="col-6 d-flex justify-content-left m-0 mb-2">
              <p className="col-3 p-0 m-0">Testing Filter: </p>
              <Form.Control
                fullWidth={true}
                defaultValue={typeD}
                onChange={changeTypeD}
                className="hlabel w-auto col-7"
                as="select"
              >
                {DepData.map((e) => (
                  <option key={e.name} value={e.name}>
                    {e.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-12 d-flex justify-content-center m-0">
              <p className="d-flex align-content-center">
                <strong className="heading">{typeD}</strong>
              </p>
            </div>

            {typeD == "Label Not Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  {loading ? (
                    <CircularProgress
                      disableShrink
                      size="1.2rem"
                      thickness="10"
                      sx={{
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12 d-flex justify-content-center mb-2">
                  {!loading && (
                    <div className="row d-flex justify-content-center">
                      <h6>
                        Total Count:
                        <strong style={{ color: "red" }}>
                          {countL.map((e) => e.count)}
                        </strong>
                      </h6>
                      {countL.map((e) => e.count) == 0 ? (
                        <h6 style={{ color: "green" }}>No Error!!!</h6>
                      ) : (
                        <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                          <ToolkitProvider
                            bootstrap4
                            keyField="variable_id"
                            columns={columnLabel}
                            data={data}
                            exportCSV={{
                              fileName: `${typeD}.csv`,
                            }}
                          >
                            {(props) => (
                              <div className="row w-50 m-0 p-0">
                                <div className="col-12 p-0 m-0">
                                  <MyExportCSV {...props.csvProps} />
                                  Export to CSV
                                </div>
                                <div className="col-12 p-0 m-0 fixTableHead">
                                  <BootstrapTable
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </div>
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}

            {typeD == "Question Text Not Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row d-flex justify-content-center">
                    <h6>
                      Total Count:
                      <strong style={{ color: "red" }}>
                        {countQ.map((e) => e.count)}
                      </strong>
                    </h6>
                    {countQ.map((e) => e.count) == 0 ? (
                      <h6 style={{ color: "green" }}>No Error !!!</h6>
                    ) : (
                      <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnLabel}
                          data={dataQ}
                          exportCSV={{
                            fileName: `${typeD}.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-50 m-0 p-0">
                              <div className="col-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <div className="col-12 p-0 m-0 fixTableHead">
                                <BootstrapTable
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {typeD == "Years Ballots Not Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row d-flex justify-content-center">
                    <h6>
                      Total Count:
                      <strong style={{ color: "red" }}>
                        {countYB.map((e) => e.count)}
                      </strong>
                    </h6>
                    {countYB.map((e) => e.count) == 0 ? (
                      <h6 style={{ color: "green" }}>No Error!!!</h6>
                    ) : (
                      <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnLabel}
                          data={dataYB}
                          exportCSV={{
                            fileName: `${typeD}.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-50 m-0 p-0">
                              <div className="col-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <div className="col-12 p-0 m-0 fixTableHead">
                                <BootstrapTable
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {typeD == "ID Not Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row d-flex justify-content-center">
                    <h6>
                      Total Count:
                      <strong style={{ color: "red" }}>
                        {countID.map((e) => e.count)}
                      </strong>
                    </h6>
                    {countID.map((e) => e.count) == 0 ? (
                      <h6 style={{ color: "green" }}>No Error!!!</h6>
                    ) : (
                      <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnLabel}
                          data={dataID}
                          exportCSV={{
                            fileName: `${typeD}.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-50 m-0 p-0">
                              <div className="col-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <div className="col-12 p-0 m-0 fixTableHead">
                                <BootstrapTable
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {typeD == "Valid Cases Not Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row d-flex justify-content-center">
                    <h6>
                      Total Count:
                      <strong style={{ color: "red" }}>
                        {countVC.map((e) => e.count)}
                      </strong>
                    </h6>
                    {countVC.map((e) => e.count) == 0 ? (
                      <h6 style={{ color: "green" }}>No Error!!!</h6>
                    ) : (
                      <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnLabel}
                          data={dataVC}
                          exportCSV={{
                            fileName: `${typeD}.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-50 m-0 p-0">
                              <div className="col-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <div className="col-12 p-0 m-0 fixTableHead">
                                <BootstrapTable
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {typeD == "Missing Cases Not Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row d-flex justify-content-center">
                    <h6>
                      Total Count:
                      <strong style={{ color: "red" }}>
                        {countMC.map((e) => e.count)}
                      </strong>
                    </h6>
                    {countMC.map((e) => e.count) == 0 ? (
                      <h6 style={{ color: "green" }}>No Error!!!</h6>
                    ) : (
                      <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnLabel}
                          data={dataMC}
                          exportCSV={{
                            fileName: `${typeD}.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-50 m-0 p-0">
                              <div className="col-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <div className="col-12 p-0 m-0 fixTableHead">
                                <BootstrapTable
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {typeD == "Double Entry Present" && (
              <>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row d-flex justify-content-center">
                    <h6>
                      Total Count:
                      <strong style={{ color: "red" }}>
                        {dataDEP?.length}
                      </strong>
                    </h6>
                    {dataDEP?.length == 0 ? (
                      <h6 style={{ color: "green" }}>No Error!!!</h6>
                    ) : (
                      <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnLabel}
                          data={dataDEP}
                          exportCSV={{
                            fileName: `${typeD}.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-50 m-0 p-0">
                              <div className="col-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <div className="col-12 p-0 m-0 fixTableHead">
                                <BootstrapTable
                                  filter={filterFactory()}
                                  {...props.baseProps}
                                />
                              </div>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {typeD == "Variables Not Present In Variable_GroupTable" && (
              <>
                <div className="col-12 d-flex justify-content-center mb-2">
                  {loadingVNP ? (
                    <CircularProgress
                      disableShrink
                      size="1.2rem"
                      thickness="10"
                      sx={{
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  {!loadingVNP && (
                    <div className="row d-flex justify-content-center">
                      <h6>
                        Total Count:
                        <strong style={{ color: "red" }}>
                          {dataVNP?.length}
                        </strong>
                      </h6>
                      {dataVNP?.length == 0 ? (
                        <h6 style={{ color: "green" }}>No Error!!!</h6>
                      ) : (
                        <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                          <ToolkitProvider
                            bootstrap4
                            keyField="variable_id"
                            columns={columnLabelVNP}
                            data={matchedVNP}
                            exportCSV={{
                              fileName: `${typeD}.csv`,
                            }}
                          >
                            {(props) => (
                              <div className="row w-50 m-0 p-0">
                                <div className="col-12 p-0 m-0">
                                  <MyExportCSV {...props.csvProps} />
                                  Export to CSV
                                </div>
                                <div className="col-12 p-0 m-0 fixTableHead">
                                  <BootstrapTable
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </div>
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            {typeD == "Module Not Present In Variable_GroupTable" && (
              <>
                <div className="col-12 d-flex justify-content-center mb-2">
                  {loadingMNP ? (
                    <CircularProgress
                      disableShrink
                      size="1.2rem"
                      thickness="10"
                      sx={{
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  {!loadingMNP && (
                    <div className="row d-flex justify-content-center">
                      <h6>
                        Total Count:
                        <strong style={{ color: "red" }}>
                          {dataMNP?.length}
                        </strong>
                      </h6>
                      {dataMNP?.length == 0 ? (
                        <h6 style={{ color: "green" }}>No Error!!!</h6>
                      ) : (
                        <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                          <ToolkitProvider
                            bootstrap4
                            keyField="variable_id"
                            columns={columnLabelVNP}
                            data={matchedMNP}
                            exportCSV={{
                              fileName: `${typeD}.csv`,
                            }}
                          >
                            {(props) => (
                              <div className="row w-50 m-0 p-0">
                                <div className="col-12 p-0 m-0">
                                  <MyExportCSV {...props.csvProps} />
                                  Export to CSV
                                </div>
                                <div className="col-12 p-0 m-0 fixTableHead">
                                  <BootstrapTable
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </div>
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
            {typeD == "Issue In Gss_Stats_Load and Year_Ballot" && (
              <>
                <div className="col-12 d-flex justify-content-center mb-2">
                  {loadingGSS ? (
                    <CircularProgress
                      disableShrink
                      size="1.2rem"
                      thickness="10"
                      sx={{
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round",
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-6 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  {!loadingGSS && (
                    <div className="row d-flex justify-content-center">
                      <h6>
                        Total Count:
                        <strong style={{ color: "red" }}>
                          {dataGSS?.length}
                        </strong>
                      </h6>
                      {dataGSS?.length == 0 ? (
                        <h6 style={{ color: "green" }}>No Error!!!</h6>
                      ) : (
                        <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                          <ToolkitProvider
                            bootstrap4
                            keyField="variable_id"
                            columns={columnLabelVNP}
                            data={matchedGSS}
                            exportCSV={{
                              fileName: `${typeD}.csv`,
                            }}
                          >
                            {(props) => (
                              <div className="row w-50 m-0 p-0">
                                <div className="col-12 p-0 m-0">
                                  <MyExportCSV {...props.csvProps} />
                                  Export to CSV
                                </div>
                                <div className="col-12 p-0 m-0 fixTableHead">
                                  <BootstrapTable
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </div>
                              </div>
                            )}
                          </ToolkitProvider>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </form>
    </FormCard>
  );
};

export default DataloadTesting;
