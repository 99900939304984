import React from "react";
import Typography from "@material-ui/core/Typography";
import { Col, Row } from "react-bootstrap";
import BigButton from "../../Components/UI/BigButton/BigButton";
import "./Resource.css";
import CommonReportDatabase from "../../Components/UI/CommonReportDatabase/CommonReportDatabase";
import { NavLink } from 'react-router-dom';
import { environment } from '../../environment';
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const Resource = () => {
  const authDetails = useSelector((state) => state.authReducer);
  /* const [data, setData] = useState([]); */
  const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
       const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2018]
  return (
    <>
      <div className="gss-trends">
        {" "}
        <React.Fragment>
          <div>
            <div className="gss-large-title unaligned_title">Help and Resources
       
            </div>
          </div>
        </React.Fragment>

        <div className="my-4 mx-5">
          <Row>

            <Col sm={12} md={8} lg={8} xl={8}>
              <div className="Container p-2" style={{ backgroundColor: "#515151", color: "#cccccc" }}>
                <Typography variant="h5" style={{ textAlign: "center" }}>Quick Start Guides</Typography>
                <p style={{ textAlign: "center" }}>Our guides can answer most questions about how to access and use GSS data. Click on the guides below to get started.
                </p>

                <div className="d-none d-md-block d-lg-block d-xl-block">
                  <Row lg={12}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div>
                        <span className="circle">1</span>
                        <NavLink className="help-key" to={"/features"}>
                          Get Started
                        </NavLink>
                        <p className="help-text">Learn about what you can do with GSS data in our features overview.</p>
                      </div>
                      <div>
                        <span className="circle">3</span>
                        <NavLink className="help-key" to={"/tabulations"}>
                          Tabulations
                        </NavLink>
                        <p className="help-text">Learn more about using GSS Data Explorer to create different analyses of GSS data.</p>
                      </div>
                      
                      <div>
                        <span className="circle">5</span>
                        <NavLink className="help-key" to={"/viewtrends"}>
                          View Trends
                        </NavLink>
                        <p className="help-text">Learn how to use the visualization feature to view key trends and responses over time.</p>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div>
                        <span className="circle">2</span>

                        <NavLink className="help-key" to={"/search_variables"}>
                          Search & Variables
                        </NavLink>
                        <p className="help-text">Learn more about finding and saving data variables in the GSS dataset.</p>
                      </div>
                      <div>
                        <span className="circle">4</span>
                        <NavLink className="help-key" to={"/extracts"}>
                          Extracts
                        </NavLink>
                        <p className="help-text">Find out how to extract just the data you want to use in your own statistical software package.</p>
                      </div>
                      
                    </Col>
                  </Row>
                </div>

                <div className="d-md-none d-lg-none d-xl-none">
                  <Row lg={12}>
                    <Col sm={12} md={6} lg={6} xl={6}>
                      <div>
                        <span className="circle">1</span>
                        <NavLink className="help-key" to={"/features"}>
                          Get Started
                        </NavLink>
                        <p className="help-text">Learn about what you can do with GSS data in our features overview.</p>
                      </div>
                      <div>
                        <span className="circle">2</span>

                        <NavLink className="help-key" to={"/search_variables"}>
                          Search & Variables
                        </NavLink>
                        <p className="help-text">Learn more about finding and saving data variables in the GSS dataset.</p>
                      </div>
                      <div>
                        <span className="circle">3</span>
                        <NavLink className="help-key" to={"/tabulations"}>
                          Tabulations
                        </NavLink>
                        <p className="help-text">Learn more about using GSS Data Explorer to create different analyses of GSS data.</p>
                      </div>
                      <div>
                        <span className="circle">4</span>
                        <NavLink className="help-key" to={"/extracts"}>
                          Extracts
                        </NavLink>
                        <p className="help-text">Find out how to extract just the data you want to use in your own statistical software package.</p>
                      </div>
                      <div>
                        <span className="circle">5</span>
                        <NavLink className="help-key" to={"/viewtrends"}>
                          View Trends
                        </NavLink>
                        <p className="help-text">Learn how to use the visualization feature to view key trends and responses over time.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>


              <Row>
                <Col sm={12} md={6} lg={6} xl={6} >
                  <div className="card my-3 border-1" style={{ height: "470px" }}>
                    <div className="card-header" style={{ color: "#73a492" }}>
                      <span className="icon fa fa-book"></span>
                      <b>CodeBook</b>
                     &nbsp;&nbsp; <Tooltip
                      title={<div>Further information about how the GSS is asked, labeled, and presented for all years of the survey.</div>}
                    >
                      <InfoIcon fontSize="small" style={{"height":'14px'}} className="plus-icon" />
                    </Tooltip>
                    </div>

                 
                    <div className="card-body">
                      <p>The GSS Codebook provides information about how GSS survey questions are asked and how GSS data are labeled and presented in the GSS dataset, for all years of the survey.</p>
                      <NavLink className="green-link" to={"/gssReports/2"}>
                        <b>View GSS CodeBook</b>
                      </NavLink>
                      <br /><br />
                      <NavLink className="green-link" to={"/gssReports/3"}>
                        <b>View GSS Questionnaires</b>
                      </NavLink>
                    </div>
                  </div></Col>
                <Col sm={12} md={6} lg={6} xl={6} className="p-0" >
                  <CommonReportDatabase />
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} lg={4} xl={4}>
              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Want to learn more about the GSS?</h5>
                <p>
                  Read about the GSS, including its history, principal investigators, and other related surveys, at NORC’s main GSS website.
                </p>
                <a href={environment.mainSite} style={{"color": "white"}}>  <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  

                >
                 MAIN GSS WEBSITE
                </BigButton></a>
              </div>

              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and how to use the data.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/FAQ">VIEW FAQ</BigButton>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5 >Does the GSS data set include geocoded information?</h5>
                <p>
                  Learn how to access GSS geocoded and other sensitive data.
                </p>
                <a target="_blank"  href={environment.getSensitiveData}>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                >
                SENSITIVE GSS DATA
                </BigButton>
                </a>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>
                  Submit your question or issue to the experts at GSS.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact/">ASK AN EXPERT</BigButton>
              </div>

              <div className="pt-5 pl-4 pb-4 pr-4 slide" >
                <h5>GSS Cumulative Data {years[0]}-{years[1]}</h5>
                <p>
                  Want the entire GSS dataset to analyze in your own statistical software package without creating an account? Download the cumulative dataset for SPSS or Stata.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/gss_data">QUICK DOWNLOAD</BigButton>
              </div>
              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>GSS and SDA</h5>
                <p>
                Some users may find helpful the web-based analysis tool provided by the Survey Documentation and Analysis (SDA) at Berkeley.
                </p>
                <a eleType="link" href="https://sda.berkeley.edu/archive.htm" target="_blank" style={{"color": "white"}}>  <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  

                >
               SDA archive page
                </BigButton></a>
                <a eleType="link" href="https://sda.berkeley.edu/sdaweb/analysis/?dataset=gss21" target="_blank" style={{"color": "white"}}>  <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 mt-1 w-100"
                  

                >
               2021 GSS
                </BigButton></a>
              </div>

            </Col>
          </Row>

        </div>


      </div>

    </>
  );
};

export default Resource;
