/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    // "aws_project_region": "eu-central-1",
    // "aws_appsync_graphqlEndpoint": "https://a6km7ylhhzfj7dbkfypjqcv7za.appsync-api.eu-central-1.amazonaws.com/graphql",
    // "aws_appsync_region": "eu-central-1",
    // "aws_appsync_authenticationType": "API_KEY",
    // "aws_appsync_apiKey": "da2-qe42nfbmmrfx3ph3czsot5stem",
    // "aws_cognito_identity_pool_id": "eu-central-1:efbe638b-5b09-467b-b0ad-63320f83cefe",
    // "aws_cognito_region": "eu-central-1",
    // "aws_user_pools_id": "eu-central-1_IK8vpvHv8",
    // "aws_user_pools_web_client_id": "322ealerc23n0opqv3kmh6ec2r",
    // "oauth": {},
    // "recaptcha": {
    //     "secretKey": "6LftH-sZAAAAADPR6cY4uHhj1yfTgIteeog5OwiS",
    //   }
    // "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "us-east-1:19919a58-699f-4df2-9031-5cd361ec9794",
    // "aws_cognito_region": "us-east-1",
    // "aws_user_pools_id": "us-east-1_ptriLTZOP",
    // "aws_user_pools_web_client_id": "7ib357u8ar91aie97mef5s4fev",
    // "oauth": {}
     //"clientName":"norc_gss_data_explorer_with_secret",
     //"SECRET_HASH":"qojrf989v6khd3sakc06stn5v8gnlual4re7qs3be1j35363vo7",
    // "aws_client_secret":"qojrf989v6khd3sakc06stn5v8gnlual4re7qs3be1j35363vo7",
    // "aws_client_id":"5e0ricsi9382n1sb9icmbfbuu0",
   
    //NORC Original
    "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID, 
    "oauth": {
        "domain": process.env.REACT_APP_AWS_DOMAIN_NAME,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_AWS_SIGN_IN_URL,
        "redirectSignOut": process.env.REACT_APP_AWS_SIGN_OUT_URL,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"  

    // "aws_project_region": "us-east-1",
    // "aws_cognito_identity_pool_id": "us-east-1:0b493c8d-1cee-4fa6-9bd1-83508b0ca8ac",
    // "aws_cognito_region": "us-east-1",
    // "aws_user_pools_id": "us-east-1_JcRtNo2iK",
    // "aws_user_pools_web_client_id": "2vcus842pbvnk9i7tgppgl9kpm", 
    // "oauth": {}

    
};


export default awsmobile;
