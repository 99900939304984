import React, { memo } from 'react'
import AddBoxIcon from '@material-ui/icons/AddBox';

const styles = {
    display: 'flex',
    border: '1.5px solid #D8D8D8',
    backgroundColor: 'white',
    padding: '5px',
    boxShadow: '0 0 3px #9e9a95',
    width: '200px'
};

const iconStyle = {
    color: '#f3901d',
    width: '40px',
    height: '40px',
}

export const BoxDragPreview = memo(function BoxDragPreview({ item }) {
    return (<div style={styles} >
        <div className="mr-2"><AddBoxIcon style={iconStyle} /></div>
        <div>
            <div className="font-weight-bold">{item.list.length} Variables</div>
            <div>{item.list.map(d => (d.orignlName)
            ).join(", ")}</div>
        </div>
    </div>);
});