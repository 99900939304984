import React from "react";
import "./ReturnBack.css";
/* import { NavLink } from "react-router-dom"; */
import arrow from '../../../../Images/back_arrow.jpg';

export default function ReturnBack(props) {
  return (
    <>
    <img src={arrow} alt=""/>
    <span>Back To {'Create ' + (props.compName === 'analysis' ? "Tabulation" : "Extract")}</span>
    </>
  );
}
