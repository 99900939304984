import React, { useState, useEffect, isValidElement, useRef } from "react";
import axios from "axios";
import { environment } from "../../../environment";
import { connect, useDispatch, useSelector } from "react-redux";
import "./AnalysisSummary.css";
import { updateService } from "../../../Shared/UpdateCart";
import DisplayOptions from "../DisplayOptions/DisplayOptions";
import ExportAnalysis from "../ExportAnalysis/ExportAnalysis";
import Table from "react-bootstrap/Table";
import { deepCopy } from "../../../Store/utils/variable.utils";
import cloneDeep from "lodash/cloneDeep";
import { useHistory, useLocation, useParams } from "react-router";
import Alert from "react-bootstrap/Alert";
import { Col, Container, Row } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { AnalysisSummaryTemplate } from "../../../Components/UI/AnalysisSummaryTemplate/AnalysisSummaryTemplate";
import Loader from "../../../Components/UI/Loader/Loader";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { removeAnalysis } from "../../../Store/actions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Box, Tab, Tabs, Paper } from "@material-ui/core";
import gssLogo from "../../../Images/gss.png";
import { dateFormat } from "../../../Store/utils/common.utils";
function AnalysisSummary(props) {
  const dispatch = useDispatch();
  const authDetails = useSelector((state) => state.authReducer);
  const analysisReducer = useSelector((state) => state.analysisReducer);
  let { id } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search); // id=123
  let creatorId = params.get("creatorId"); // 123
  const [data, setData] = useState();
  const [cpdata, setCPData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isParent, setParent] = useState(true);
  const [searchId, setSearchId] = useState();
  const [commentData, setCommentData] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [decimal, setDecimal] = useState(0);
  const [show, setShow] = useState("count");
  const [showTotals, setShowTotals] = useState(true);
  const [finalTotal, setFinalTotal] = useState();
  const [finalStdTotal, setFinalStdTotal] = useState();
  const [column, setColumn] = useState();
  const [stdColumn, setStdColumn] = useState();
  const [height, setHeight] = useState("72px");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [colspan, setColspan] = useState();
  const [rowspan, setRowspan] = useState();
  const [basicDetails, setBasicDetails] = useState({});
  let history = useHistory();

  /*  const secondTbl = useRef();
   const [widthChk, setWidthChk] = useState(); */

  useEffect(() => {
    getData();
    updateService.sendAlert("varData");

    return () => {
      console.log("component unmount");
      localStorage.removeItem("height");
      localStorage.removeItem("showTotals");
      localStorage.removeItem("colspan");
      localStorage.removeItem("rowspan");
      localStorage.removeItem("show");
      localStorage.removeItem("decimal");
      localStorage.removeItem("column");
      localStorage.removeItem("finalTotal");
      localStorage.removeItem("creatorName");
      localStorage.removeItem("createdAt");
      localStorage.removeItem("analysisSummaryData");
    };
  }, [id]);

  const getData = () => {
    setShowError(false);
    let reqBody = {
      userId: creatorId,
      funcId: +id,
    };
    let rowVal;
    let final = 0;
    let rowStdVal;
    let finalStd = 0;
    axios
      .post(`${environment.getAdminAnalysisSummary}`, reqBody)
      .then((response) => {
        if (response.data.data) {
          response.data.data.counts.map((col) => {
            rowVal = col.count.reduce((a, v) => a + v, 0);
            final = final + rowVal;

            setFinalTotal(final);
            col.count.push(rowVal);
            /* */
            if (col.hasOwnProperty("stdErr")) {
              rowStdVal = col.stdErr.reduce((a, v) => a + v, 0);
              finalStd = finalStd + rowStdVal;
              setFinalStdTotal(finalStd);
              col.stdErr.push(rowStdVal);
            }
            /* */
          });
          /* */

          /* */
          let ht = 72 * response.data.data.col.length;

          setHeight(ht + "px");
          setRowspan(response.data.data.col.length * 2);
          response.data.data.row.headerValue.push("Total");
          let result;
          result = response.data.data.counts.reduce(function (r, a) {
            a.count.forEach(function (b, i) {
              r[i] = (r[i] || 0) + b;
            });
            return r;
          }, []);

          let stdResult;
          stdResult = response.data.data.counts.reduce(function (r, a) {
            if (a.hasOwnProperty("stdErr")) {
              a.stdErr.forEach(function (b, i) {
                r[i] = (r[i] || 0) + b;
              });
              return r;
            } else {
              return;
            }
          }, []);

          let lastRow =
            response.data.data.col[response.data.data.col.length - 1];
          let lastRowCount = lastRow.headerValue.length * lastRow.count;
          setColspan(lastRowCount);
          setColumn(result);
          setStdColumn(stdResult);

          response.data.data.counts.push({
            count: result,
            stdErr: stdResult,
          });
          // console.log()
        }

        if (response.data.info) {
          setBasicDetails(response.data.info);
        } else {
          setBasicDetails(response.data);
        }

        setData(response.data);
        let variables = cloneDeep(response.data);

        setCPData(variables);
        //console.log('data---->', data.data.counts)
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            //  alert(err.response.data.message);
            history.push("/error");
            return;
          } else {
            setShowError(true);
            setErrorMsg(err.response.data.message);
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          setShowError(true);
          setErrorMsg("Some Error Occured");
        } else {
          setShowError(true);
          setErrorMsg("Some Error Occured");
        }
      });
  };

  const replicateArray = (array, n) => {
    // Create an array of size "n" with undefined values
    var arrays = Array.apply(null, new Array(n));

    // Replace each "undefined" with our array, resulting in an array of n copies of our array
    arrays = arrays.map(function () {
      return array;
    });

    // Flatten our array of arrays
    return [].concat.apply([], arrays);
  };

  const showData = (param) => {
    let rowLength = data.rowData.length;
    if (param == "count") {
    } else if (param == "row_per") {
      data.rowData.map((val, i) => {
        val = val * 100;
      });
    } else if (param == "count_per") {
    } else {
    }
  };
  const displayOptions = () => {
    setIsOpen(true);
  };
  const editComment = (comment) => {
    setCommentData(comment);
    setIsOpen(true);
    setEdit(true);
  };
  const downloadZip = () => {
    axios
      .get(
        "http://releases.ubuntu.com/12.04.5/ubuntu-12.04.5-alternate-amd64.iso",
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);
      });
  };

  const submitSave = (showSelect, decimalNo, istotal) => {
    console.log(showSelect, decimalNo, istotal);
    setShow(showSelect);
    setDecimal(decimalNo);
    setShowTotals(istotal);
  };

  /*   useEffect(()=>{
      if(data || show || decimal || showTotals || !showTotals){
        setWidthChk(secondTbl?.current?.scrollWidth);
      }
    },[data, show, decimal, showTotals]) */

  const setOpenFn = () => {
    setIsOpen(!isOpen);
  };

  const Capitalize = (str) => {
    // return str.charAt(0).toUpperCase() + str.split('(').join(' (');
    if (str.indexOf("(") > -1) {
      return str.split("(")[0].toUpperCase() + " (" + str.split("(")[1];
    } else {
      return str.toUpperCase();
    }
  };

  const CamelCase = (str) => {
    if (typeof str == "string") {
      return str.charAt(0).toUpperCase() + str.slice(1).split("(").join(" (");
    } else {
      return str;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(removeAnalysis(id));
    };
  }, []);

  const componentRef = useRef();

  function analysisSummaryDetails(data) {
    return (
      <Container fluid className="analysis_info">
        <Row className="h4 hb" style={{ color: "#666666" }}>
          <div className="col customize-summary-value  pl-0">
            <strong>
              {basicDetails?.analysysName
                ? basicDetails?.analysysName
                : basicDetails?.name}
            </strong>
          </div>
          <div className="extract_links">
            {data?.status == "Started" ? (
              <span className="action">
                {" "}
                <i
                  className="fa fa-refresh fa-spin"
                  title="The analysis is running."
                ></i>
              </span>
            ) : (
              ""

              // <a title="Edit extract" href="/createAnalysis">
              //   <i className="fa fa-pencil fa-2x"></i>
              // </a>
            )}
          </div>
        </Row>
        <hr />
        {data?.info?.col && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>
                {basicDetails?.type == "Crosstab" ? "Column" : "Columns"}
              </strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              {CamelCase(data?.info?.col)}
            </div>
          </Row>
        )}
        {data?.info?.row && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>Row</strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              {CamelCase(data?.info?.row)}
            </div>
          </Row>
        )}
        {data?.info?.wght_var && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>Weight var</strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              {data?.info?.wght_var.toUpperCase()}
            </div>
          </Row>
        )}
        {data?.info?.caseSelection && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>Case selection</strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              {data?.info?.caseSelection}
            </div>
          </Row>
        )}
        <Row className="param">
          <div className="customize-summary-details">
            <strong>Sample design</strong>
          </div>
          <span className="customize-summary-value"> </span>
          <div className="col customize-summary-value pl-1 text-break">
            {data?.info?.design ? data?.info?.design : "None"}
          </div>
        </Row>
        {data?.info?.missing && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>Exclude missing</strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              Yes
            </div>
          </Row>
        )}
        {data?.info?.chi && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>Include chi square</strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              Yes
            </div>
          </Row>
        )}
        {data?.info?.StdErr && (
          <Row className="param">
            <div className="customize-summary-details">
              <strong>Include standard errors</strong>
            </div>
            <span className="customize-summary-value"> </span>
            <div className="col customize-summary-value pl-1 text-break">
              Yes
            </div>
          </Row>
        )}
      </Container>
    );
  }

  function analysisSummaryTbl() {
    return (
      <>
        {/* <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button"
      table="table-to-xls"
      filename="processed_excel"
      sheet="processed_excel"
      buttonText="Download as XLS"
    /> */}

        <Container>
          {data?.data ? (
            <div className="results-container">
              <div className="clearfix">
                <div className="row m-0  table-holder no-wrap customize-tbl-width">
                  <div className="col-3 overflow-hidden p-0 table-inner fixed">
                    <table className="gsstable multi rm-horizantal-scroll">
                      <thead>
                        <tr className="header">
                          <th
                            style={{ height: height }}
                            className="ath"
                            title={Capitalize(data?.data?.row?.headerName)}
                          >
                            {Capitalize(data?.data?.row?.headerName)}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(showTotals
                          ? data?.data?.row.headerValue
                          : data?.data?.row.headerValue.slice(0, -1)
                        ).map((row, i) => (
                          <tr>
                            <td className="br var_value" rowSpan="1">
                              {row}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* Export to Excel Table Starts here */}
                  <div className="col-9 p-0 overflow-auto ">
                    <table id="table-to-xls">
                      <span className="gsstable inner multi table-right">
                        <tr className="d-none">
                          <img
                            src="https://gssde-uat.norc.org/static/media/gss.d87acf6d.png"
                            width={"200px"}
                            height={"100px"}
                            alt=" "
                          ></img>
                        </tr>
                        {/* Hidden Export Items start */}
                        <Container className="d-none">
                          <tr className="param">
                            <th className="">
                              <strong> </strong>
                            </th>
                            <span className=""> </span>
                            <td className=""> </td>
                          </tr>
                          <tr className="param">
                            <th className="">
                              <strong> </strong>
                            </th>
                            <span className=""> </span>
                            <td className=""> </td>
                          </tr>
                          <tr className="param">
                            <th className="">
                              <strong> </strong>
                            </th>
                            <span className=""> </span>
                            <td className=""> </td>
                          </tr>
                          <tr className="param">
                            <th className="">
                              <strong> </strong>
                            </th>
                            <span className=""> </span>
                            <td className=""> </td>
                          </tr>
                          <tr className="param">
                            <th className="">
                              <strong> </strong>
                            </th>
                            <span className=""> </span>
                            <td className=""> </td>
                          </tr>
                          <tr className="param">
                            <th className="">
                              <strong>{"Analysis Summary"}</strong>
                            </th>
                            <span className=""> </span>
                            <td className=""> </td>
                          </tr>
                          <td className=""> </td>
                        </Container>
                        <Container fluid className="d-none analysis_info">
                          <tr className="h4 hb" style={{ color: "#666666" }}>
                            <th className="col customize-summary-value  pl-0">
                              <strong>
                                {basicDetails?.analysysName
                                  ? basicDetails?.analysysName
                                  : basicDetails?.name}
                              </strong>
                            </th>
                            <td className="extract_links">
                              {data?.status == "Started" ? (
                                <span className="action">
                                  {" "}
                                  <i
                                    className="fa fa-refresh fa-spin"
                                    title="The analysis is running."
                                  ></i>
                                </span>
                              ) : (
                                ""

                                // <a title="Edit extract" href="/createAnalysis">
                                //   <i className="fa fa-pencil fa-2x"></i>
                                // </a>
                              )}
                            </td>
                          </tr>
                          <hr />
                          {data?.info?.col && (
                            <tr className="param">
                              <th className="customize-summary-details">
                                <strong>
                                  {basicDetails?.type == "Crosstab"
                                    ? "Column"
                                    : "Columns"}
                                </strong>
                              </th>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                {CamelCase(data?.info?.col)}
                              </td>
                            </tr>
                          )}
                          {data?.info?.row && (
                            <tr className="param">
                              <th className="customize-summary-details">
                                <strong>Row</strong>
                              </th>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                {CamelCase(data?.info?.row)}
                              </td>
                            </tr>
                          )}
                          {data?.info?.wght_var && (
                            <tr className="param">
                              <th className="customize-summary-details">
                                <strong>Weight var</strong>
                              </th>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                {data?.info?.wght_var.toUpperCase()}
                              </td>
                            </tr>
                          )}
                          {data?.info?.caseSelection && (
                            <tr className="param">
                              <th className="customize-summary-details">
                                <strong>Case selection</strong>
                              </th>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                {data?.info?.caseSelection}
                              </td>
                            </tr>
                          )}
                          <tr className="param">
                            <th className="customize-summary-details">
                              <strong>Sample design</strong>
                            </th>
                            <span className="customize-summary-value"> </span>
                            <td className="col customize-summary-value pl-1 text-break">
                              {data?.info?.design ? data?.info?.design : "None"}
                            </td>
                          </tr>
                          {data?.info?.missing && (
                            <tr className="param">
                              <th className="customize-summary-details">
                                <strong>Exclude missing</strong>
                              </th>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                Yes
                              </td>
                            </tr>
                          )}
                          {data?.info?.chi && (
                            <tr className="param">
                              <th className="customize-summary-details">
                                <strong>Include chi square</strong>
                              </th>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                Yes
                              </td>
                            </tr>
                          )}
                          {data?.info?.StdErr && (
                            <tr className="param">
                              <td className="customize-summary-details">
                                <strong>Include standard errors</strong>
                              </td>
                              <span className="customize-summary-value"> </span>
                              <td className="col customize-summary-value pl-1 text-break">
                                Yes
                              </td>
                            </tr>
                          )}
                          <tr className="param">
                            <td className="customize-summary-details">
                              <strong> </strong>
                            </td>
                            <span className="customize-summary-value"> </span>
                            <td className="col customize-summary-value pl-1 text-break">
                              {" "}
                            </td>
                          </tr>
                          {/* Hidden Export Items End */}
                        </Container>
                        
                        <table className="table-inner gsstable inner multi table-right ">
                        
                            <thead>
                              <tr className="header d-none">
                                {" "}
                                {/* Row header export to Excel hidden */}
                                <th
                                  rowSpan={rowspan + 1}
                                  style={{ height: height }}
                                  className="ath"
                                  title={Capitalize(
                                    data?.data?.row?.headerName
                                  )}
                                >
                                  {Capitalize(data?.data?.row?.headerName)}
                                </th>
                              </tr>

                              {data?.data.col.map((colData, i) => (
                                <>
                                  <tr className="header">
                                    <th colspan={colspan} className="border">
                                      {/* <b> */}
                                      {Capitalize(colData?.headerName)}
                                      {/* </b> */}
                                    </th>

                                    {showTotals && i == 0 && (
                                      <th
                                        className="bb var_value total bold border "
                                        rowSpan={rowspan}
                                      >
                                        Total
                                      </th>
                                    )}
                                  </tr>
                                  <tr className="header">
                                    {replicateArray(
                                      colData.headerValue,
                                      colData.count
                                    ).map((val, j) => (
                                      <>
                                        <th
                                          className="bb var_value border"
                                          colspan={
                                            colspan /
                                            replicateArray(
                                              colData.headerValue,
                                              colData.count
                                            ).length
                                          }
                                        >
                                          {val}
                                        </th>
                                      </>
                                    ))}
                                  </tr>{" "}
                                </>
                              ))}

                              {(showTotals
                                ? data?.data?.row.headerValue
                                : data?.data?.row.headerValue.slice(0, -1)
                              ).map((row, i) => (
                                <>
                                  <tr>
                                    <td
                                      className="br var_value d-none"
                                      rowSpan="1"
                                    >
                                      {/* Row data export to Excel hidden */}
                                      {row}
                                    </td>

                                    {(showTotals
                                      ? data?.data?.counts[i].count
                                      : data?.data?.counts[i].count.slice(0, -1)
                                    ).map((count, j) => (
                                      <td
                                        className={
                                          "var_value text-right border " +
                                          showTotals
                                            ? j ==
                                                data?.data?.counts[i].count
                                                  .length -
                                                  1 ||
                                              i ==
                                                data?.data?.row.headerValue
                                                  .length -
                                                  1
                                              ? "customize-font-weight-bold custom_border"
                                              : "customize-font-weight custom_border"
                                            : "customize-font-weight custom_border"
                                        }
                                      >
                                        <div className="val">
                                          {show == "count" &&
                                            count.toFixed(decimal)}{" "}
                                          {show == "count" &&
                                          j <
                                            data?.data?.counts[i].count.length -
                                              1 &&
                                          i <
                                            data?.data?.row.headerValue.length -
                                              1 ? (
                                            data?.data?.counts[
                                              i
                                            ]?.hasOwnProperty("stdErr") &&
                                            data?.data?.counts[i].stdErr &&
                                            data?.data?.counts[i].stdErr
                                              .length > 0 ? (
                                              <span>
                                                (
                                                {data?.data?.counts[i].stdErr[
                                                  j
                                                ]?.toFixed(decimal)}
                                                )
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                          {show == "row_per" &&
                                            (count
                                              ? (
                                                  (count /
                                                    data?.data?.counts[i].count[
                                                      data?.data?.counts[i]
                                                        .count.length - 1
                                                    ]) *
                                                  100
                                                ).toFixed(decimal)
                                              : 0) + "%"}
                                          {show == "row_per" &&
                                          j <
                                            data?.data?.counts[i].count.length -
                                              1 &&
                                          i <
                                            data?.data?.row.headerValue.length -
                                              1 ? (
                                            data?.data?.counts[
                                              i
                                            ]?.hasOwnProperty("stdErr") &&
                                            data?.data?.counts[i].stdErr &&
                                            data?.data?.counts[i].stdErr
                                              .length > 0 ? (
                                              <span>
                                                (
                                                {(data?.data?.counts[i].stdErr[
                                                  j
                                                ]
                                                  ? (
                                                      (data?.data?.counts[i]
                                                        .stdErr[j] /
                                                        data?.data?.counts[i]
                                                          .stdErr[
                                                          data?.data?.counts[i]
                                                            .stdErr.length - 1
                                                        ]) *
                                                      100
                                                    ).toFixed(decimal)
                                                  : 0) + "%"}
                                                )
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                          {show == "col_per" &&
                                            (count
                                              ? (
                                                  (count / column[j]) *
                                                  100
                                                ).toFixed(decimal)
                                              : 0) + "%"}
                                          {show == "col_per" &&
                                          j <
                                            data?.data?.counts[i].count.length -
                                              1 &&
                                          i <
                                            data?.data?.row.headerValue.length -
                                              1 ? (
                                            data?.data?.counts[
                                              i
                                            ]?.hasOwnProperty("stdErr") &&
                                            data?.data?.counts[i].stdErr &&
                                            data?.data?.counts[i].stdErr
                                              .length > 0 ? (
                                              <span>
                                                (
                                                {(data?.data?.counts[i].stdErr[
                                                  j
                                                ]
                                                  ? (
                                                      (data?.data?.counts[i]
                                                        .stdErr[j] /
                                                        stdColumn[j]) *
                                                      100
                                                    ).toFixed(decimal)
                                                  : 0) + "%"}
                                                )
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                          {show == "total_per" &&
                                            (count
                                              ? (
                                                  (count / finalTotal) *
                                                  100
                                                ).toFixed(decimal)
                                              : 0) + "%"}
                                          {show == "total_per" &&
                                          j <
                                            data?.data?.counts[i].count.length -
                                              1 &&
                                          i <
                                            data?.data?.row.headerValue.length -
                                              1 ? (
                                            data?.data?.counts[
                                              i
                                            ]?.hasOwnProperty("stdErr") &&
                                            data?.data?.counts[i].stdErr &&
                                            data?.data?.counts[i].stdErr
                                              .length > 0 ? (
                                              <span>
                                                (
                                                {(data?.data?.counts[i].stdErr[
                                                  j
                                                ]
                                                  ? (
                                                      (data?.data?.counts[i]
                                                        .stdErr[j] /
                                                        finalStdTotal) *
                                                      100
                                                    ).toFixed(decimal)
                                                  : 0) + "%"}
                                                )
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                          {/*  {(j < data?.data?.counts[i].count.length - 1)? (data?.data?.counts[i]?.hasOwnProperty('stdErr')? (<span>({data?.data?.counts[i].stdErr[j]?.toFixed(decimal)})</span>) : ""):""}  */}
                                        </div>
                                      </td>
                                    ))}
                                  </tr>
                                </>
                              ))}

                              {/* {(showTotals
                        ? data?.data?.row.headerValue
                        : data?.data?.row.headerValue.slice(0, -1)
                      ).map((row, i) => (
                       
                      ))} */}

                              {/* {(showTotals
                        ? data?.data?.row.headerValue
                        : data?.data?.row.headerValue.slice(0, -1)
                      ).map((row, i) => (
                        <tr>
                          
                        </tr>
                      ))} */}
                              {/* <tr>
                    <td className="br var_value total bold center">Total</td>
                  </tr> */}
                            </thead>
                         
                        </table>
                        <table className="param d-none">
                        <tr >
                          <td className="customize-summary-details">
                            <strong> </strong>
                          </td>
                          <span className="customize-summary-value"> </span>
                          <td className="col customize-summary-value pl-1 text-break">
                            {" "}
                          </td>
                        </tr>
                        </table>

                        {/* Citaion to Excel Hidden */}
                        <td colspan={50} className="d-none ">
                          <strong>
                          Davern, Michael; Bautista, Rene; Freese, Jeremy; Herd,Pamela; and Morgan, Stephen L.; General Social Survey 1972-2024. [Machine-readable data file]. Principal Investigator, Michael Davern;
                          Co-Principal Investigators, Rene Bautista, Jeremy Freese, Pamela Herd, and Stephen L. Morgan. Sponsored by National Science Foundation. NORC ed.
                          Chicago: NORC, 2024: NORC at the University of Chicago [producer and distributor].
                          Data accessed from the GSS Data Explorer website at gssdataexplorer.norc.org.
                          This GSS Data Explorer output was created by{" "}
                          {localStorage.getItem("creatorName")} on{" "}
                          {dateFormat(localStorage.getItem("createdAt"))}.
                          </strong>
                        </td>
                      </span>{" "}
                    </table>
                    {/* Export to Excel Table End here */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Container>
        <Container>
          <td></td>
        </Container>
      </>
    );
  }
  const setModalOpen = () => {
    let isOpen = !showError;
    setShowError(isOpen);
  };

  const setClose = () => {};

  const exportExcel = () => {
    console.log("exportExcel called");
    if (id) {
      axios
        .post(`${environment.exportExcel}`, {
          user_id: authDetails.isAdmin ? creatorId : undefined,
          analysisIds: [id],
          extractType: "excel",
        })
        .then((response) => {
          console.log("response===>", response);
          window.open(response.data.objectUrl, "_blank");
        })
        .catch((err) => {
          setShowError(true);
          setErrorMsg("Something went wrong. Try Again!!");
        });
    }
  };

  const exportFile = async () => {
    console.log("export called");
    /* props.history.push('/analysis_summary_export'); */
    /* setIsExport(true); */
    localStorage.setItem("height", JSON.stringify(height));
    localStorage.setItem("showTotals", JSON.stringify(showTotals));
    localStorage.setItem("colspan", JSON.stringify(colspan));
    localStorage.setItem("rowspan", JSON.stringify(rowspan));
    localStorage.setItem("show", JSON.stringify(show));
    localStorage.setItem("decimal", JSON.stringify(decimal));
    localStorage.setItem("column", JSON.stringify(column));
    localStorage.setItem("stdColumn", JSON.stringify(stdColumn));
    localStorage.setItem("finalTotal", JSON.stringify(finalTotal));
    localStorage.setItem("finalStdTotal", JSON.stringify(finalStdTotal));
    localStorage.setItem("creatorName", data?.info?.userName);
    localStorage.setItem("createdAt", data?.info?.created_at);
    localStorage.setItem("analysisSummaryData", JSON.stringify(data));
    //dispatch(setAnalysisExport({creatorName:data?.info?.userName, createdAt: data?.info?.created_at, analysisSummaryData:data}))
    window.open("/analysis_summary_export", "_blank");
  };
  localStorage.setItem("creatorName", data?.info?.userName);
  localStorage.setItem("createdAt", data?.info?.created_at);
  const printPage = async () => {
    console.log("export called");
    /* props.history.push('/analysis_summary_export'); */
    /* setIsExport(true); */
    localStorage.setItem("height", JSON.stringify(height));
    localStorage.setItem("showTotals", JSON.stringify(showTotals));
    localStorage.setItem("colspan", JSON.stringify(colspan));
    localStorage.setItem("rowspan", JSON.stringify(rowspan));
    localStorage.setItem("show", JSON.stringify(show));
    localStorage.setItem("decimal", JSON.stringify(decimal));
    localStorage.setItem("column", JSON.stringify(column));
    localStorage.setItem("stdColumn", JSON.stringify(stdColumn));
    localStorage.setItem("finalTotal", JSON.stringify(finalTotal));
    localStorage.setItem("finalStdTotal", JSON.stringify(finalStdTotal));
    localStorage.setItem("creatorName", data?.info?.userName);
    localStorage.setItem("createdAt", data?.info?.created_at);
    localStorage.setItem("analysisSummaryData", JSON.stringify(data));
    //dispatch(setAnalysisExport({creatorName:data?.info?.userName, createdAt: data?.info?.created_at, analysisSummaryData:data}))
    window.open("/analysis_summary_export?print=true", "_blank");
  };
  return (
    <>
      <CustomModal
        title="Error"
        titleText="Error"
        isOpen={showError}
        setIsOpen={setModalOpen} //callback function when dialog is opened. Calling Child function from Parent
        setClose={setClose}
        inputLabel={errorMsg}
      ></CustomModal>
      <div style={{ display: "none" }}>
        <AnalysisSummaryTemplate
          ref={componentRef}
          isTrends={false}
          isLoggedIn={authDetails.isSignedIn}
          creatorName={data?.info?.userName}
          createdAt={data?.info?.created_at}
          analysisSummaryDetails={analysisSummaryDetails(data)}
          analysisSummaryTbl={analysisSummaryTbl(data)}
        />
      </div>
      {data ? (
        <div className="mt-4">
          <DisplayOptions
            title="Add Comment"
            isOpen={isOpen}
            setIsOpen={setOpenFn} //callback function when dialog is opened. Calling Child function from Parent
            handleSubmit={submitSave}
            btnText="Submit"
            isInput={false}
            inputLabel=""
            comment={commentData}
            isEdit={isEdit}
          ></DisplayOptions>
          <div className="noscroll analysis" id="analysisSummary">
            <div className="gss-center-analysis index ">
              <div className="gss-bold-title ml-4 mt-2">
                <div className="pull-left ">Tabulation Summary</div>

                <div className="clearfix"></div>
              </div>
              {analysisSummaryDetails(data)}

              <div className="ml-4 mt-2 mb-2">
                {data?.message && data?.message}
              </div>
              {data?.data && (
                <>
                  <div className="gss-bold-title">
                    <div className="pull-right pull-right mr-0 mt-2 mb-3 ">
                      <div className="row">
                        <div
                          className="col-4.5"
                          style={{
                            marginRight: 13,
                          }}
                        >
                          <button
                            className="btn btn-gray hb rounded-0 font-weight-bold font-medium"
                            onClick={displayOptions}
                            /* style={{fontFamily: "helveticaneue-bold"}} */
                          >
                            DISPLAY OPTIONS
                          </button>
                        </div>
                        <div className="spacer">&nbsp;</div>
                        <div className="col-1">
                          {/* <ReactToPrint
                            trigger={() => (
                              <button
                                className="btn btn-gray hb rounded-0 font-weight-bold "
                                id="print_link"
                                >
                                <i className="fa fa-print"></i>
                              </button>
                            )}
                            content={() => componentRef.current}
                          /> */}
                          {/* <ReactToPrint
                            trigger={() => (
                              <button
                                className="btn btn-gray hb rounded-0 font-weight-bold "
                                id="print_link"
                                disabled={(widthChk == undefined || widthChk < 909) ? false : true}
                                title={widthChk == undefined || widthChk < 909 ? "" : "Due to too many columns this table cannot be printed"}
                              >
                                <i className="fa fa-print"></i>
                              </button>
                            )}
                            content={() => componentRef.current}
                          /> */}

                          <button
                            className="btn btn-gray hb rounded-0 font-weight-bold "
                            id="print_link"
                            onClick={printPage}
                          >
                            <i className="fa fa-print"></i>
                          </button>
                        </div>

                        <div className="col-2 ml-4">
                          <ExportAnalysis
                            isTrends={false}
                            exportFile={exportFile}
                            exportExcel={exportExcel}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  {analysisSummaryTbl(data)}
                  <br />
                </>
              )}
              {data?.info?.chi && data?.chi}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      ) : (
        <div className="mt-5  justify-content-center w-100">
          {" "}
          <Loader />
        </div>
      )}
    </>
  );
}

export default AnalysisSummary;
