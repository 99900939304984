import { React, useEffect, useState } from "react";
import Axios from "axios";
import { environment } from "../../environment";
import FormCard from "../UI/FormCard/FormCard";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import "./PopularVariable.css";
import { CSVLink, CSVDownload } from "react-csv";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import { CChart } from "@coreui/react-chartjs";
import { CContainer } from "@coreui/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import BootstrapTable from "react-bootstrap-table-next";
import "../PopularVaribales/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "./react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
const PopularVariables = (props) => {
  const [data, setData] = useState([]);
  const [dataR, setDataR] = useState([]);
  const [dataN, setDataN] = useState([]);
  const [isShownNever, setIsShownNever] = useState(false);
  const [isShown500, setIsShown500] = useState(true);
  const [isShownLeast500, setIsShownLeast500] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingR, setLoadingR] = useState(true);
  const [loadingN, setLoadingN] = useState(true);
  const { ExportCSVButton } = CSVExport;

  const MyExportCSV = (props) => {
    const handleClick = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      props.onExport();
    };
    return (
      <Tooltip title="Export">
        <FileDownloadIcon style={{ color: "#50917E" }} onClick={handleClick} />
      </Tooltip>
    );
  };
  const getPopularExtract = () => {
    Axios.get(`${environment.popularVariables}`)
      .then((resp) => {
        if (resp.data) {
          let data1 = resp.data.extracts_variables_Top;
          data1.forEach(function (element, i) {
            element.sr_no = i + 1;
          });
          setData(data1);
          setLoading(false);
          console.log(data, "ssssss");
          let dataRe = resp.data.extracts_variables_Bottom;

          dataRe.forEach(function (element, i) {
            element.sr_no = i + 1;
          });
          setDataR(dataRe);
          setLoadingR(false);
          console.log(dataR, "reverse");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNeverUsedExtract = () => {
    Axios.get(`${environment.NeverUsedExtract}`)
      .then((resp) => {
        if (resp.data) {
          let dataNe = resp.data.unusedextract;

          dataNe.forEach(function (element, i) {
            element.sr_no = i + 1;
          });

          setDataN(dataNe);
          setLoadingN(false);
          console.log(dataR, "reverse");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick500 = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShown500((current) => !current);
    setIsShownLeast500(false);
    setIsShownNever(false);
    // getPopularExtract();
  };
  const handleClickLeast500 = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShownLeast500((current) => !current);
    setIsShown500(false);
    setIsShownNever(false);
    // getPopularExtractR();
  };
  const neverUsed500 = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShownNever((current) => !current);
    setIsShownLeast500(false);
    setIsShown500(false);
    // getNeverUsedExtract();
  };
  useEffect(() => {
    getPopularExtract();
    getNeverUsedExtract();
  }, []);
  console.log(loading, "loadin");

  const columns = [
    { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    { dataField: "variable_id", text: "Variable Id", filter: textFilter() },
    { dataField: "name", text: "Variable Name", filter: textFilter() },
    {
      dataField: "count",
      text: "Frequency of Use ",
      filter: textFilter(),
    },
  ];
  const columnN = [
    { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    { dataField: "id", text: "Variable Id", filter: textFilter() },
    { dataField: "name", text: "Variable Name", filter: textFilter() },
  ];

  return (
    <FormCard title="" header="Popular variables in Extract">
      <form className="row w-500 simple_form form-horizontal mb-4 m-0 ">
        <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center">
          <button className="col-4 button10" onClick={handleClick500}>
            Top 500
          </button>
          <button className="col-4 button10 mx-2" onClick={handleClickLeast500}>
            Bottom 500
          </button>
          <button className="col-4 button10" onClick={neverUsed500}>
            Never Used
          </button>
        </div>

        {isShown500 && (
          <>
            <div className="col-12 d-flex justify-content-end m-0">
              <Tooltip title="Close">
                <CloseIcon className="closeButton" onClick={handleClick500} />
              </Tooltip>
            </div>
            <div className="col-12 d-flex justify-content-center m-0">
              <p className="d-flex align-content-center">
                <strong>Top 500</strong>
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center mb-2">
              {loading && isShown500 ? (
                <CircularProgress
                  disableShrink
                  size="1.2rem"
                  thickness="10"
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
              <div className="row">
                <div className="col-4 d-flex justify-content-start mb-3"></div>

                <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                  <ToolkitProvider
                    bootstrap4
                    keyField="variable_id"
                    columns={columns}
                    data={data}
                    exportCSV={{
                      fileName: "Most_Popular_500_Extract.csv",
                    }}
                  >
                    {(props) => (
                      <div className="row w-100 m-0 p-0">
                        <div className="col-12 p-0 m-0">
                          <MyExportCSV {...props.csvProps} />
                          Export to CSV
                        </div>
                        <div className="col-12 p-0 m-0 fixTableHead">
                          <BootstrapTable
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mt-3 p-0">
              <CContainer fluid>
                <CChart
                  type="bar"
                  height="300"
                  data={{
                    labels: data.map((i) => i.name),
                    datasets: [
                      {
                        label: "Counts: ",
                        fill: true,
                        backgroundColor: "#619080",
                        borderColor: "black",
                        pointBackgroundColor: "rgba(220, 220, 220, 1)",
                        pointBorderColor: "red",
                        data: data.map((i) => i.count),
                      },
                    ],
                  }}
                />
              </CContainer>
            </div>
          </>
        )}
        {isShownLeast500 && (
          <>
            <div className="col-12 d-flex justify-content-end m-0">
              <Tooltip title="Close">
                <CloseIcon
                  className="closeButton"
                  onClick={handleClickLeast500}
                />
              </Tooltip>
            </div>
            <div className="col-12 d-flex justify-content-center m-0">
              <p className="d-flex align-content-center">
                <strong>Bottom 500</strong>
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center mb-2">
              {loadingR && isShownLeast500 ? (
                <CircularProgress
                  disableShrink
                  size="1.2rem"
                  thickness="10"
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
              <div className="row">
                <div className="col-4 d-flex justify-content-start mb-3"></div>

                <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                  <ToolkitProvider
                    bootstrap4
                    keyField="variable_id"
                    columns={columns}
                    data={dataR}
                    exportCSV={{
                      fileName: "Least_Popular_500_Extract.csv",
                    }}
                  >
                    {(props) => (
                      <div className="row w-100 m-0 p-0">
                        <div className="col-12 p-0 m-0">
                          <MyExportCSV {...props.csvProps} />
                          Export to CSV
                        </div>
                        <div className="col-12 p-0 m-0 fixTableHead">
                          <BootstrapTable
                            bootstrap4
                            filter={filterFactory()}
                            {...props.baseProps}
                          />
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mt-3 p-0">
              <CContainer fluid>
                <CChart
                  type="bar"
                  height="300"
                  data={{
                    labels: dataR.map((i) => i.name),
                    datasets: [
                      {
                        label: "Counts: ",
                        fill: true,
                        backgroundColor: "red",
                        borderColor: "black",
                        data: dataR.map((i) => i.count),
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </CContainer>
            </div>
          </>
        )}
        {isShownNever && (
          <>
            <div className="col-12 d-flex justify-content-end m-0">
              <Tooltip title="Close">
                <CloseIcon className="closeButton" onClick={neverUsed500} />
              </Tooltip>
            </div>
            <div className="col-12 d-flex justify-content-center m-0">
              <p className="d-flex align-content-center">
                <strong>Never Used</strong>
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center mb-2">
              {loadingN && isShownNever ? (
                <CircularProgress
                  disableShrink
                  size="1.2rem"
                  thickness="10"
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
              <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                <ToolkitProvider
                  bootstrap4
                  keyField="variable_id"
                  columns={columnN}
                  data={dataN}
                  exportCSV={{
                    fileName: "Never_Used_Variables_Extract.csv",
                  }}
                >
                  {(props) => (
                    <div className="row w-100 m-0 p-0">
                      <div className="col-12 p-0 m-0">
                        <MyExportCSV {...props.csvProps} />
                        Export to CSV
                      </div>
                      <div className="col-12 p-0 m-0 fixTableHead">
                        <BootstrapTable
                          bootstrap4
                          filter={filterFactory()}
                          {...props.baseProps}
                        />
                      </div>
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </>
        )}
      </form>
    </FormCard>
  );
};

export default PopularVariables;
