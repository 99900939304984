import React, { useState } from "react";
import "./QuickDownloads.css";
import axios from "axios";
import { environment } from "../../../environment";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function QuickDownloads() {
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });
  const {
    min_max_year: { min_max_year = [] },
  } = useSelector((state) => state.minmaxYearsReducer);
  const years =
    min_max_year && min_max_year.length > 0 ? min_max_year : [1972, 2018];
  const authDetails = useSelector((state) => state.authReducer);
  const downloadZip = (fileName) => {
    let req = {
      fileType: fileName,
    };

    axios
      .post(environment.downloadEntireData, req)
      .then((response) => {
        window.location.href = response.data.object;
      })
      .catch((err) => {
        if (err.response) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
        } else if (err.request) {
          // alert('Some Error Occurred');
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // client never received a response, or request never left
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // anything else
        }
      });
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };
  const setClose = () => {};
  return (
    <div className="noscroll gss-help quickdownload" id="ws-content">
      <CustomModal
        title={isServerError.variant}
        isOpen={isServerError.status}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>
      <div id="center_content">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div id="welcometab">
              <h1 className="gss-large-title ">2024 Data Files (GSS years 1972-2022)</h1>
              <br />
              <p style={{ fontSize: "13.5px" }}>
                For the most recent version of data available, please see the cumulative files below, or visit&nbsp; 
                <a style={{ color: "#73a492", "font-weight": "normal" }} href="//gss.norc.org"
                >gss.norc.org</a>. 
                &nbsp;To produce a customized download in various formats, including
                SAS, STATA, and SPSS, please start by visiting the variable
                selection pages&nbsp;
                <a
                  style={{ color: "#73a492", "font-weight": "normal" }}
                  href="/variables/vfilter"
                >
                  here
                </a>
                .
              </p>

              <p style={{ fontSize: "13.5px" }}>
                For information about how GSS data are labeled and presented in
                the GSS dataset, view the{" "}
                <a
                style={{ color: "#73a492", "font-weight": "normal" }}
                href="https://gss.norc.org/Documents/codebook/GSS%202022%20Codebook.pdf">
                  GSS Codebook
                </a>
                .
              </p>
              <h4>SAS Format Download</h4>
              <a
                style={{ color: "#73a492", "font-weight": "normal" }}
                href="https://gss.norc.org/Documents/sas/GSS_sas.zip"
              >
                GSS 1972-2022 Cross-Sectional Cumulative Data (Release 3a, April 2024)
              </a>
              <br />
              <br />
              <h4>STATA Format Download</h4>

              <a
                style={{ color: "#73a492", "font-weight": "normal" }}
                href="https://gss.norc.org/documents/stata/GSS_stata.zip"
              >
                GSS 1972-2022 Cross-Sectional Cumulative Data (Release 3a, April 2024)
              </a>
              <br />
              <br /> 
              <h4>SPSS Format Download</h4>
              <a
                style={{ color: "#73a492", "font-weight": "normal" }}
                href="https://gss.norc.org/Documents/spss/GSS_spss.zip"
              >
                GSS 1972-2018 Cross-section Cumulative Data (Release 3, May 2020)
              </a>
              <br />
              
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 d-flex  justify-content-center justify-content-lg-end">
            <div
              className="sidebar gss-help qs-guide"
              style={{
                height: "205px !important",
              }}
            >
              <div className="content">
                <div className="sidebar-box" style={{ "border-bottom": "0px" }}>
                  <div className="h5 font-weight-bold">
                    Want additional GSS datasets?
                  </div>
                  <p>
                    Download &nbsp;
                    <a
                      href={environment.mainSite + "/Get-The-Data"}
                      style={{ color: "#4f977d", "font-weight": "normal" }}
                    >
                      single-year, cross-sectional, and panel datasets
                    </a>
                    &nbsp; on the &nbsp;
                    <a
                      href={environment.mainSite}
                      style={{ color: ":#4f977d", "font-weight": "normal" }}
                    >
                      gss.norc.org
                    </a>{" "}
                    &nbsp; website.
                  </p>
                  <a
                    className="btn btn-dark"
                    style={{ fontSize: "13.5px" }}
                    href={environment.mainSite + "/Get-The-Data"}
                  >
                    GET THE DATA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
