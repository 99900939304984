import React from "react";
import BigButton from "../BigButton/BigButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Col } from "react-bootstrap";
import EnhancedTableCheckbox from "../EnhancedTableCheckbox/EnhancedTableCheckbox";
import ClearIcon from "@material-ui/icons/Clear";
import "./ConfirmModal.css";

const ConfirmModal = ({
  rows,
  title,
  message,
  page,
  rowsPerPage,
  showConfirm,
  ...props
}) => {
  const onSubmit = () => {
    props.onSubmit();
  };

  const onClose = () => {
    props.setShowConfirm(false);
  };

  return (
    <Dialog
      open={showConfirm}
      style={{ overflow: "scroll" }}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      className="tag-model h-auto"
      disableBackdropClick={true}
      fullWidth={true}
    >
      <DialogTitle>
        <Col as="span" className="px-3 py-1 m-0">
          {title}
        </Col>
        <Col as="span" className="px-3 py-1 m-0">
          <ClearIcon className="float-right clear-icon" onClick={onClose} />
        </Col>
      </DialogTitle>
      <DialogContent className="h-auto">
        Are you sure you want to {message}
        <hr />
        <div className={"custom-scroll"}>
          {rows != "" ? (
            <EnhancedTableCheckbox
              dClassName={"modal-table"}
              showPagination={false}
              disableCheckBox={true}
              rows={rows}
              selected={rows.map((n) => n.vID)}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          ) : undefined}
        </div>
      </DialogContent>
      <DialogActions className="">
        <BigButton classname="btn-cancel" onClick={onClose}>
          CANCEL
        </BigButton>
        <BigButton classname="btn-orange btn-save" onClick={onSubmit}>
          SUBMIT
        </BigButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
