import React from 'react';
import { Auth } from "aws-amplify";

import './SocialLoginButton.css';
import GoogleLogo from '../../../Images/googleLogo.png';

const SocialLoginButton = (props) => {
    return (
        <div className={'customize-social-login-btn' + ' ' + (props.providerType == 'Google' ? 'customize-google-btn' : 'customize-facebook-btn')} onClick={() => Auth.federatedSignIn({ provider: props.providerType, role: 'user' })} >
            <div className={'provider-logo' + ' ' + (props.providerType == 'Google' ? 'google-logo' : '')}
            >
                {props.providerType == 'Google' ?
                    <img src={GoogleLogo} className="logo" /> :
                    <i className="fa fa-facebook-square" style={{ fontSize: 19 }}></i>
                }
            </div>
            <div className="social-btn-text">
                Continue with {props.providerType}
            </div>
        </div>
    );
};

export default SocialLoginButton;