import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import { environment } from "../../../environment";
import { useDispatch, useSelector } from "react-redux";
import OrganizationDDL from "../../../Components/UI/OrganizationDDL/OrganizationDDL";
import DepratmentDDL from "../../../Components/UI/DepratmentDDL/DepratmentDDL";
import { setAuth } from "../../../Store/actions";
import axios from "axios";
import {
  checkIsRequired,
  checkIsAllLetter,
  checkIsValidEmail,
  checkPasswordLength,
  checkPasswordCombination,
} from "../../../Shared/helpers";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import Modal from "react-bootstrap/Modal";
import "./UserSettings.css";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const UserSettings = (props) => {
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const [orgArr, setOrgArr] = useState([]);
  const [departArr, setDepartArr] = useState([]);
  const authDetails = useSelector((state) => state.authReducer);
  const [holdOrganizationStatus, setHoldOrganizationStatus] = useState(null);
  const [holdDepartmentStatus, setHoldDepartmentStatus] = useState(null);
  const [disable, setDisabled] = useState(false);
  const [holdUsername, setholdUsername] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [show, setShow] = useState(false);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });
  const [localState, setLocalState] = useState({
    spss: true,
    sas: true,
    stata: true,
    ddi: true,
    excel: true,
    allow_email: false,
    shareYourTags: false,
    user_account: "",
    user_id: "",
    username: "",
    firstname: "",
    last_name: "",
    email: "",
    organization: "",
    otherOrganization: "",
    department: "",
    otherDepartment: "",
    password: "",
    passwordConfirmation: "",
    role: "user",
  });

  const [errors, setErrors] = useState({
    firstname: null,
    username: null,
    email: null,
  });
  const [pwdError, setPwdError] = useState({
    password: null,
    confirmPassword: null,
  });

  useEffect(() => {
    setErrors({
      ...errors,
      firstname: authDetails.firstName != "" ? "" : null,
      email: authDetails.email != "" ? "" : null,
      username: authDetails.isSocialLogin
        ? authDetails.email != ""
          ? ""
          : null
        : authDetails.username != ""
        ? ""
        : null,
    });
    setholdUsername(authDetails.username);
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      axios
        .get(`${process.env.PUBLIC_URL}/config/organisations_og.json`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "",
          },
        })
        .then((res) => {
          let orgDDl = res.data.org.map((d) => d.name);
          setOrgArr(orgDDl);
        });

      axios
        .get(`${process.env.PUBLIC_URL}/config/project_og.json`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "",
          },
        })
        .then((res) => {
          let depDDl = res.data.dep.map((d) => d.name);
          depDDl.sort();
          depDDl.push("Other");
          setDepartArr(depDDl);
        });
    }
  }, []);

  const getSettingsData = () => {
    Axios({
      method: "get",
      url: environment.settingsURL,
    }).then((response) => {
      let data = response.data;
      if (data) {
        if (!localStorage.defaultProjectNo) {
          localStorage.defaultProjectNo = data.workspace_id;
        }

        setLocalState({
          ...localState,
          organization:
            holdOrganizationStatus == ""
              ? ""
              : holdOrganizationStatus == "true"
              ? data.organization
              : "Other",
          otherOrganization:
            holdOrganizationStatus == ""
              ? ""
              : holdOrganizationStatus == "true"
              ? ""
              : data.organization,
          department:
            holdDepartmentStatus == ""
              ? ""
              : holdDepartmentStatus == "true"
              ? data.department
              : "Other",
          otherDepartment:
            holdDepartmentStatus == ""
              ? ""
              : holdDepartmentStatus == "true"
              ? ""
              : data.department,
          user_id: data.user_id,
          allow_email: data.allow_email == 1 ? true : false,
          shareYourTags: data.shareTag == 1 ? true : false,
          firstname: data.first_name,
          last_name: data.last_name,
          username: authDetails.isSocialLogin ? data.email : data.username,
          email: data.email,
        });
      }
    });
  };

  useEffect(() => {
    if (orgArr && orgArr.length > 0) {
      if (
        authDetails.organization != "" &&
        authDetails.organization != undefined &&
        authDetails.organization != null
      ) {
        let isOrgSet = orgArr.includes(authDetails.organization);
        setHoldOrganizationStatus(String(isOrgSet));
      } else {
        setHoldOrganizationStatus("");
      }
    }

    if (departArr && departArr.length > 0) {
      if (
        authDetails.department != "" &&
        authDetails.department != undefined &&
        authDetails.department != null
      ) {
        let isDeptSet = departArr.includes(authDetails.department);
        setHoldDepartmentStatus(String(isDeptSet));
      } else {
        setHoldDepartmentStatus("");
      }
    }
  }, [orgArr, departArr]);

  useEffect(() => {
    if (holdOrganizationStatus == "" || holdDepartmentStatus == "") {
      getSettingsData();
    }
    if (
      (holdOrganizationStatus == "true" || holdOrganizationStatus == "false") &&
      (holdDepartmentStatus == "true" || holdDepartmentStatus == "false")
    ) {
      getSettingsData();
    }
  }, [holdOrganizationStatus, holdDepartmentStatus]);

  const checkDisabled = () => {
    for (var key in errors) if (!(errors[key] == "")) return false;

    return true;
  };

  useEffect(() => {
    if (
      errors.firstname != null ||
      errors.username != null ||
      errors.email != null
    ) {
      setDisabled(!checkDisabled());
    }
  }, [errors.firstname, errors.username, errors.email]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let validationError = {};
    setLocalState({ ...localState, [name]: value });
    if (name == "firstname" || name == "username" /* || name == "email" */) {
      validationError = await checkIsRequired(name, value);
    }
    switch (name) {
      case "firstname":
        if (!validationError.errorStatus) {
          validationError = await checkIsAllLetter(name, value);
        }
        break;
      case "email":
        /* if (!validationError.errorStatus) { */
        validationError = await checkIsValidEmail(name, value);
        /* } */
        break;
      case "passwordConfirmation":
        {
          let passwordErr = { errorMessage: "", errorStatus: false };
          if (
            localState.password !== "" &&
            localState.password.trim() !== "" &&
            localState.password !== undefined
          ) {
            if (value === localState.password) {
              setPwdError({
                ...pwdError,
                confirmPassword: passwordErr.errorMessage,
                password: passwordErr.errorMessage,
              });
            } else {
              setPwdError({
                ...pwdError,
                confirmPassword: "Passwords do not match, Please try again",
                password: passwordErr.errorMessage,
              });
            }
          } else {
            setPwdError({
              ...pwdError,
              confirmPassword: passwordErr.errorMessage,
              password: passwordErr.errorMessage,
            });
          }
        }
        break;
      case "password":
        let passwordErr = { errorMessage: "", errorStatus: false };
        setPwdError({
          ...pwdError,
          confirmPassword: passwordErr.errorMessage,
          password: passwordErr.errorMessage,
        });
        break;
      default:
        break;
    }

    if (name == "firstname" || name == "username" || name == "email") {
      setErrors({ ...errors, [name]: validationError.errorMessage });
    }
  };

  const cancel = () => {
    props.history.push("/user/project");
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };
  const setClose = () => {
    props.history.push("/user/project");
  };
  const handleChangeChecked = ({ target: { checked, name } }) => {
    setLocalState({ ...localState, [name]: checked });
  };

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const onSave = () => {
    setIsSaving(true);
    setDisabled(true);
    let reqBody = {
      user_settings: {
        allow_email: localState.allow_email,
        shareTag: localState.shareYourTags,
        ddi: 1,
        department:
          localState.department == "Other"
            ? localState.otherDepartment
            : localState.department,
        email: localState.email,
        excel: 0,
        first_name: localState.firstname,
        last_name: localState.last_name,
        organization:
          localState.organization == "Other"
            ? localState.otherOrganization
            : localState.organization,
        password: localState.password,
        sas: 1,
        spss: 1,
        stata: 1,
        user_account: "",
        username: holdUsername,
      },
    };
    Axios.post(`${environment.settingsURL}`, reqBody)
      .then((response) => {
        setIsSaving(false);
        setDisabled(false);
        dispatch(
          setAuth({
            token: authDetails.token,
            isSignedIn: authDetails.isSignedIn,
            username: authDetails.username,
            fullName: localState.firstname + " " + localState.last_name,
            department:
              localState.department == "Other"
                ? localState.otherDepartment
                : localState.department,
            organization:
              localState.organization == "Other"
                ? localState.otherOrganization
                : localState.organization,
            email_verified: authDetails.emailVerified,
            email: authDetails.email,
            firstName: localState.firstname,
            lastName: localState.last_name,
            isSocialLogin: authDetails.isSocialLogin,
            createdAt: authDetails.createdAt,
            isAdmin: authDetails.isAdmin,
            runSessionTimeout: false,
          })
        );

        setLocalState({
          ...localState,
          password: "",
          passwordConfirmation: "",
        });
        setErrors({ firstname: null, username: null, email: null });
        setPwdError({ password: null, confirmPassword: null });

        setIsServerError({
          ...isServerError,
          status: true,
          message: "Settings have been successfully updated.",
          variant: "Success",
        });
      })
      .catch((err) => {
        setIsSaving(false);
        setDisabled(false);
        if (err.response) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
        } else if (err.request) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (localState.password === "" || localState.password.trim() === "") &&
      (localState.passwordConfirmation === "" ||
        localState.passwordConfirmation.trim() === "")
    ) {
      setPwdError({ password: "", confirmPassword: "" });
    } else {
      let isError = false;
      let passwordErr = "";
      if (
        (localState.password === "" || localState.password.trim() === "") &&
        (localState.passwordConfirmation !== "" ||
          localState.passwordConfirmation.trim() !== "")
      ) {
        setPwdError({ ...pwdError, password: "Please enter the password" });
        return true;
      }
      if (
        (localState.password !== "" || localState.password.trim() !== "") &&
        (localState.passwordConfirmation === "" ||
          localState.passwordConfirmation.trim() === "")
      ) {
        setPwdError({
          ...pwdError,
          confirmPassword: "Please enter the password confirmation",
        });
        return true;
      }

      passwordErr = await checkPasswordLength("password", localState.password);
      if (passwordErr.errorStatus) {
        isError = true;
        setPwdError({ ...pwdError, password: passwordErr.errorMessage });
      }
      passwordErr = await checkPasswordCombination(
        "password",
        localState.password
      );
      if (passwordErr.errorStatus) {
        isError = true;
        setPwdError({ ...pwdError, password: passwordErr.errorMessage });
      }

      if (localState.password === localState.passwordConfirmation) {
        setPwdError({ ...pwdError, password: passwordErr.errorMessage });
      } else {
        isError = true;
        setPwdError({
          ...pwdError,
          password: passwordErr.errorMessage,
          confirmPassword: "Passwords do not match, Please try again.",
        });
      }
      if (isError) return;
    }
    onSave();
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="customize-modal-header">
          <Modal.Title className="customize-modal-title">
            Tag Sharing Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
          <br />
          <br />
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </Modal.Body>
        <Modal.Footer className="customize-modal-footer">
          <button
            type="button"
            className="gss-btn btn-orange mt-1"
            variant="secondary"
            onClick={handleClose}
          >
            Exit
          </button>
        </Modal.Footer>
      </Modal>
      <CustomModal
        title={isServerError.variant}
        isOpen={isServerError.status}
        setIsOpen={setIsOpen}
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>
      <div></div>
      <FormCard title="User Settings" header="Update your User Settings">
        <form
          id="settings_form"
          className="simple_form form-horizontal"
          name="userSettingsForm"
          onSubmit={handleSubmit}
        >
          <div className="account">
            <div className="setting_header">User Account:</div>

            <div className="form-inputs">
              <div className="form-group string optional first_name form-inline">
                <div className="col-sm-3">
                  <label className="string optional control-label">
                    First name<abbr>*</abbr>
                  </label>
                </div>
                <div className="col-sm-9" style={{ position: "relative" }}>
                  <input
                    className="string optional  form-control customize-input"
                    type="text"
                    name="firstname"
                    id="firstname"
                    value={localState.firstname}
                    onChange={handleChange}
                    disabled={isSaving}
                    autoComplete="off"
                  />
                  {errors.firstname ? (
                    <div className="errorMsg" style={{ position: "absolute" }}>
                      {errors.firstname}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group string optional last_name form-inline">
                <div className="col-sm-3">
                  <label className="string optional control-label">
                    Last name
                  </label>
                </div>
                <div className="col-sm-9">
                  <input
                    className="string optional  form-control customize-input"
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={localState.last_name}
                    onChange={handleChange}
                    disabled={isSaving}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="form-group username form-inline">
                <div className="col-sm-3">
                  <label className="control-label">
                    Username<abbr>*</abbr>
                  </label>
                </div>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control customize-input"
                    name="username"
                    readOnly
                    value={localState.username}
                    //defaultValue={localState.username}
                    onChange={handleChange}
                  />
                  {errors.username ? (
                    <div className="errorMsg">{errors.username}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group form-inline">
                <div className="col-sm-3">
                  <label>
                    Email<abbr>*</abbr>
                  </label>
                </div>
                <div className="col-sm-9">
                  <input
                    type="text"
                    name="email"
                    className="form-control customize-input"
                    readOnly
                    value={localState.email}
                    onChange={handleChange}
                  />
                  {errors.email ? (
                    <div className="errorMsg">{errors.email}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group form-inline">
                <div className="col-sm-3">
                  <label htmlFor="organization">Organization</label>
                </div>
                <div className="col-sm-9">
                  <OrganizationDDL
                    value={localState.organization}
                    defaultValue={localState.organization}
                    displayEmpty
                    onChange={handleChange}
                    disabled={isSaving}
                  />
                </div>
              </div>
              {localState.organization == "Other" ? (
                <>
                  <div className="form-group form-inline ">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="otherOrganization"
                        className="form-control customize-input"
                        value={localState.otherOrganization}
                        onChange={handleChange}
                        disabled={isSaving}
                        autoComplete="off"
                        required="true"
                        placeholder="Your Organisation"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="form-group form-inline">
                <div className="col-sm-3">
                  <label htmlFor="department">Department</label>
                </div>
                <div className="col-sm-9">
                  <DepratmentDDL
                    value={localState.department}
                    defaultValue={localState.department}
                    onChange={handleChange}
                    displayEmpty
                    disabled={isSaving}
                    autoComplete="off"
                  />
                </div>
              </div>
              {localState.department == "Other" ? (
                <>
                  <div className="form-group form-inline ">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="otherDepartment"
                        className="form-control customize-input"
                        value={localState.otherDepartment}
                        onChange={handleChange}
                        disabled={isSaving}
                        autoComplete="off"
                        required="true"
                        placeholder="Provide Your Department"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {authDetails.isSocialLogin ? (
                ""
              ) : (
                <>
                  {" "}
                  <div className="form-group form-inline">
                    <div className="col-sm-3">
                      <label>Password</label>
                    </div>
                    <div className="col-sm-9" style={{ position: "relative" }}>
                      <input
                        className="form-control customize-input"
                        type="password"
                        name="password"
                        value={localState.password}
                        autoComplete="new-password"
                        onChange={handleChange}
                        disabled={isSaving}
                      />
                      {pwdError.password ? (
                        <div
                          className="errorMsg"
                          style={{ position: "absolute" }}
                        >
                          {pwdError.password}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group form-inline ">
                    <div className="col-sm-3">
                      <label>Password Confirmation</label>
                    </div>
                    <div className="col-sm-9" style={{ position: "relative" }}>
                      <input
                        type="password"
                        className="form-control customize-input"
                        name="passwordConfirmation"
                        value={localState.passwordConfirmation}
                        onChange={handleChange}
                        disabled={isSaving}
                      />
                      {pwdError.confirmPassword ? (
                        <div
                          className="errorMsg"
                          style={{ position: "absolute" }}
                        >
                          {pwdError.confirmPassword}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-group string optional first_name form-inline">
                <div className="col-sm-3"></div>
                <div className="col-sm-9">
                  <input
                    className="string optional"
                    type="checkbox"
                    name="shareYourTags"
                    id="shareYourTags"
                    checked={localState.shareYourTags}
                    onChange={handleChangeChecked}
                    disabled={isSaving}
                  />{" "}
                  Share your tags with the GSS team &nbsp;
                  <Tooltip
                    title={
                      <div>
                        Tags let our users to improve our search engine and find
                        relevant content. Consider sharing your tags with the
                        GSS team.
                      </div>
                    }
                  >
                    <InfoIcon
                      fontSize="small"
                      style={{ fontSize: "13px" }}
                      className="plus-icon"
                    />
                  </Tooltip>
                </div>
              </div>
                  <div className="form-group string optional first_name form-inline">
              <div className="col-sm-3 text-center">
                <label>Email Subscription</label>
              </div>
              <div className="col-sm-9">
                <input
                  type="hidden"
                  name="user[allow_email]"
                  id="allow_email"
                  value="0"
                />
                <input
                  type="checkbox"
                  name="allow_email"
                  id="allow_email"
                  className="string optional"
                  checked={localState.allow_email}
                  onChange={handleChangeChecked}
                  disabled={isSaving}
                />
                &nbsp;
                <span>
                  Receive maintenance and announcement emails&nbsp;&nbsp;
                  <Tooltip
                    title={
                      <div>
                        The GSS team will email you a few times a year about new
                        data releases and major updates to the Data Explorer.
                      </div>
                    }
                  >
                    <InfoIcon
                      fontSize="small"
                      style={{ fontSize: "13px" }}
                      className="plus-icon"
                    />
                  </Tooltip>
                </span>
              </div>
            </div>
                </>
              )}
              
            </div>
            <div className="setting_header"></div>
            
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-sm-12 text-sm-center text-md-center col-md-12 col-lg-6 text-lg-right">
              {" "}
              <button
                type="submit"
                name="commit"
                className="submit-btn commit"
                disabled={disable}
              >
                Save
              </button>
            </div>
            <div class="col-sm-12 text-sm-center text-lg-left text-md-center col-md-12 col-lg-6 mt-2">
              <a className=" or-toolbar-item" onClick={cancel}>
                Cancel
              </a>
            </div>
          </div>
        </form>
      </FormCard>
    </div>
  );
};

export default UserSettings;
