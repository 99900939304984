import React, { Component } from "react";

import { Auth } from "aws-amplify";
import "./ProjectCreate.css";
import { NavLink, Redirect } from "react-router-dom";
import Axios from "axios";
import { environment } from "../../../environment";
import StringInput from "../../../Components/UI/InputNew/Input";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import { messageService } from "../../../Shared/Message";
import { connect } from "react-redux";
class ProjectCreate extends Component {
  constructor(props) {
    
    super(props);
    console.log('ProjectCreate---->',this.props)
    this.state = {
      projectName: "",
      projectDesc: "",
      open: true,
      visibility: false,
      showPrivacyPage: true,
      privacyType: "",
      successPage: false,
      mvcpText:"",
      isProject:true
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.createProject = this.createProject.bind(this)
  }

  componentDidMount() {
    messageService.onMessage().subscribe(message => {
      if (message.text == 'move' || message.text == 'copy') {
this.setState({mvcpText:message.text})
    }})
      
  }
  showModal() {
    this.setState({ showModal: true });
  }
  handleClose(e) {
    e.preventDefault();
    this.setState({ open: false });
    if(this.state.isProject)
    this.props.history.push("/user/project");
  }

  handlePrivacy(privacyVal) {
    this.setState({ privacyType: privacyVal });
  }

  changePrivacyType() {
    this.setState({ privacyType: "" });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.projectName) {
      alert("Please enter a project name");
      return;
    }
    this.setState({
      showPrivacyPage: true,
    });

    if (!this.state.successPage) {
      this.createProject();
    } else if (this.state.successPage && this.state.isProject) {
      this.props.history.push("/user/project");
    }
  }

  createProject() {
    let req = {
      name: this.state.projectName,
      description: this.state.projectDesc,
      visibility: this.state.privacyType ? this.state.privacyType : "private",
      do_excel: true,
      do_spss: true,
      do_sas: true,
      do_stata: true,
      do_ddi: true,
    };

    axios({
      method: "post",
      url: `${environment.createProject}`,
      data: req,
    })
      .then((response) => {
        if (response.data.insert_status) {
         
          this.setState({ successPage: true });
          this.setState({isProject: true});
          // messageService.onMessage().subscribe(message => {
            if (this.props.data.newdata) {
           this.setState({isProject: false});
             this.getSearchData(this.props.data.newdata.text,this.props.data.newdata.id , response.data.last_insert_id)
            }

        } else {
          alert(response.data.error_message);
        }
      })
      .catch((error) => {
        alert(error.error_message);
      });
  }

  getSearchData(text,srchId,id){
    let reqBody = {
      "workspace_id":id,
      "id_":  srchId
    };
    let url = text == 'move'? environment.moveCart:environment.copyCart
    axios.post(`${url}`, reqBody)
      .then((resp) => {
        console.log(resp);
        this.props.dispatch({
          type: "NEW_PROJECT",
          payload: [],
        });
       // this.getUserSearch();
      // window.reload()
      })
      .catch((error) => {
        //props.history.push("/error");
        console.log(error);
      });
  }
  handleChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { signedIn } = this.state;
    if (signedIn) {
      return (
        <div>
          <Redirect to="/user/project" />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {this.state.signedIn ? (
            <Redirect to="/user/projects" />
          ) : (
            <Dialog
              fullWidth={true}
              maxWidth="xs"
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="max-width-dialog-title"
              className="signin_modal"
            >
              <DialogTitle id="max-width-dialog-title" className="modal_title">
                {/* THIS TITLE IS DISPLAYED ON CREATE PROJECT FORM */}
                {!this.state.successPage && <div>Create New Project</div>}
                {/* THIS TITLE IS DISPLAYED ON PROJECT CREATION SUCCESS */}
                {this.state.successPage && <div>Your project is ready!</div>}
              </DialogTitle>
              <DialogContent className="modal_content">
                {/* THIS SECTION IS DISPLAYED TO ENTER PROJECT NAME & DESCRIPTION */}
                {!this.state.successPage && (
                  <div>
                    <div className="form-group projectName form-inline">
                      <div className="col-sm-12">
                        <StringInput
                          name="projectName"
                          onChange={this.handleChange}
                          value={this.state.projectName}
                          placeholder="Project Name"
                        />
                      </div>
                    </div>
                    <div className="form-group string optional password form-inline">
                      <div className="col-sm-12">
                        <StringInput
                          name="projectDesc"
                          onChange={this.handleChange}
                          value={this.state.projectDesc}
                          placeholder="Description"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* THIS SECTION IS DISPLAYED ON PROJECT CREATION SUCCESS */}
                {this.state.successPage && (
                  <div className="sharing">
                    <p>
                      Now that your project is set up, you can start saving
                      variables, extracts, and more. Remember that you can
                      modify this projectâ€™s settings by selecting the small gear
                      in the upper right corner of the project page or overview
                      panel.
                    </p>
                  </div>
                )}

                {/* THIS SECTION IS DISPLAYED TO CHOOSE PRIVACY */}
                {this.state.showPrivacyPage && !this.state.successPage && (
                  <div className="sharing">
                    <h4>Project Sharing</h4>
                    <p>
                      Choose whether to keep your project private or share it
                      with others. You are the only person who can modify the
                      settings for this project and you can change the settings
                      at any time.
                    </p>

                    {/* CHANGE PRIVACY SETTING LINK */}
                    {this.state.privacyType != "" && (
                      <a
                        className="change"
                        onClick={this.changePrivacyType.bind(this)}
                        style={{
                          width: "300px",
                          display: "block",
                          fontSize: "12px",
                          fontFamily: "arial",
                          marginBottom: "17px",
                        }}
                      >
                        Change privacy setting
                      </a>
                    )}
                    {/* CHANGE PRIVACY SETTING LINK END */}

                    {/* PRIVATE , SHARED & PUBLIC LINKS */}
                    {(this.state.privacyType == "" ||
                      this.state.privacyType == "private") && (
                      <div
                        className="btn"
                        onClick={this.handlePrivacy.bind(this, "private")}
                      >
                        {" "}
                        <i className="pull-left fa fa-lock"></i>
                        <div className="content pull-left">
                          <div className="title">Private</div>
                          <p>
                            Only you can view, add, and edit items in this
                            project.
                          </p>
                        </div>
                      </div>
                    )}

                    {(this.state.privacyType == "" ||
                      this.state.privacyType == "shared") && (
                      <div
                        className="btn"
                        onClick={this.handlePrivacy.bind(this, "shared")}
                      >
                        {" "}
                        <i className="pull-left fa fa-users"></i>
                        <div className="content pull-left">
                          <div className="title">Shared</div>
                          <p>
                            Invite others to join this project. In shared
                            projects, members can view, add, and edit items in
                            this project.
                          </p>
                        </div>
                      </div>
                    )}

                    {(this.state.privacyType == "" ||
                      this.state.privacyType == "public") && (
                      <div
                        className="btn"
                        onClick={this.handlePrivacy.bind(this, "public")}
                      >
                        {" "}
                        <i className="pull-left fa fa-globe"></i>
                        <div className="content pull-left">
                          <div className="title">Public</div>
                          <p>
                            Allow others to view this project. In public
                            projects, only you can add or edit items, but anyone
                            with a link to your project can view all items.
                          </p>
                        </div>
                      </div>
                    )}
                    {/* PRIVATE , SHARED & PUBLIC LINKS END */}

                    {/* SECTION SHOWN WHEN SHARED IS SELECTED */}
                    {this.state.privacyType == "shared" && (
                      <div className="shared">
                        <div
                          className="invite"
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <h4 style={{ display: "block" }}>Invite Others</h4>{" "}
                          Invite others to join this project. Enter email
                          addresses to send an invitation. Invitees who do not
                          have an account will be asked to register.
                          <div
                            className="mail-link-container"
                            style={{ marginTop: "15px", marginBottom: "7px" }}
                          >
                            {" "}
                            <a
                              href="#"
                              id="email_link_1"
                              style={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                fontSize: "12px",
                                color: "#619080",
                              }}
                            >
                              add email list
                            </a>{" "}
                            <a
                              href="#"
                              id="email_link_2"
                              style={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                fontSize: "12px",
                                color: "#619080",
                                display: "none",
                              }}
                            >
                              add individual emails
                            </a>{" "}
                          </div>
                          <div
                            className="alert-danger hidden"
                            id="invalid-email"
                          >
                            Please enter a valid email address, for example,
                            address@email.com
                          </div>
                          <div className="input-group" id="email-individual">
                            {" "}
                            <span className="input-group-addon fa fa-envelope"></span>
                            <input
                              type="text"
                              name="emails"
                              id="emails"
                              value=""
                              placeholder="Enter email addresses"
                            ></input>{" "}
                          </div>
                          <div
                            className="input-group"
                            id="email-list"
                            style={{ display: "none" }}
                          >
                            {" "}
                            <span
                              className="input-group-addon fa fa-envelope pull-left"
                              style={{ marginRight: "1px" }}
                            ></span>
                            <textarea
                              name="emails2"
                              id="email_list_input"
                              placeholder="Enter a list of emails separated by comma or newline"
                              style={{
                                width: "380px",
                                height: "44px",
                                display: "block",
                                border: "none",
                                boxShadow: "none",
                                paddingTop: "7px",
                              }}
                            ></textarea>{" "}
                            <a
                              style={{
                                backgroundColor: "#6b6b6b",
                                display: "inline-block",
                                color: "white",
                                width: "110px",
                                paddingTop: "6px",
                                textAlign: "center",
                                height: "33px",
                                fontFamily:
                                  "&quot,Helvetica-BC&quot,Helvetica,Arial,Sans-Serif",
                                marginTop: "10px",
                                marginLeft: "330px",
                              }}
                            >
                              ADD LIST
                            </a>{" "}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* SECTION SHOWN WHEN PUBLIC IS SELECTED */}
                    {this.state.privacyType == "public" && (
                      <div className="public">
                        {" "}
                        Share the URL below with others you want to view your
                        project.{" "}
                        <a
                          href="https://gssdataexplorer.norc.org/projects/92402"
                          id="public-url"
                        >
                          https://gssdataexplorer.norc.org/projects/92402
                        </a>{" "}
                        <a
                          className="copy"
                          data-clipboard-target="public-url"
                          href="#"
                        >
                          Copy link
                        </a>{" "}
                      </div>
                    )}
                  </div>
                )}
              </DialogContent>
              <DialogActions className="modal_footer">
                <div className="col-md-6 btn-one" onClick={this.handleSubmit}>
                  <Button
                    classes={{ label: "btn-orange primary-link pull-left" }}
                  >
                    {!this.state.successPage && <span>Create Project</span>}
                    {this.state.successPage && this.state.isProject && <span>Get Started</span>}
                  </Button>
                </div>
                <div className="col-md-6 btn-two" onClick={this.handleClose}>
                  <Button classes={{ label: "pull-right secondary-link" }}>
                    CANCEL
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    data: state.reducer,
   
  };
}

export default connect(mapStateToProps)(ProjectCreate);