import React, { useEffect, useState, useRef } from "react";
import "./VariableDetails.css";
import axios from "axios";
import { environment } from "../../../environment";
import BallotInfo from "../../../Containers/SearchManagement/BallotInfo/BallotInfo";
import YearsTable from "../YearsTable/YearsTable";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
/* import TableHead from '@material-ui/core/TableHead'; */
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
/* import cart from '../../../Images/cart.jpg'; */
import arrow from "../../../Images/back_arrow.jpg";
/* import cart_checked from '../../../Images/cart_checked.jpg'; */
/* import { updateService } from '../../../Shared/UpdateCart' */
import { MenuItem, Select } from "@material-ui/core";
import { connect } from "react-redux";
import ReturnBack from "../../../Components/UI/GSS/ReturnBack/ReturnBack";
import { useDispatch, useSelector } from "react-redux";
import {
  /* getVariables, */ addToExtract,
  removeFromMyGSS,
} from "../../../Store/actions/variable";
import {
  /* NavLink, */ useLocation,
  useParams,
  Link,
  useHistory,
} from "react-router-dom";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { encodeUri } from "../../../Store/utils/common.utils";
import TrendMeasures from "../../TrendsManagement/TrendMeasures/TrendMeasures";
import { isArray } from "lodash";

const VariableDetails = (props) => {
  const dispatch = useDispatch();
  const {
    filtered_years: { filteredYears = [] },
  } = useSelector((state) => state.filteredYearsReducer);
  const {
    min_max_year: { min_max_year = [] },
  } = useSelector((state) => state.minmaxYearsReducer);
  const authDetails = useSelector((state) => state.authReducer);
  /* const search = props.location?.search; */
  const _id = Number(useParams("id").id);
  /* const [isExpanded, setExpanded] = useState(false); */
  const [isAdded, setAdded] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isLimited, setLimited] = useState(true);

  /* const [isFilteredYears, setFilteredYears] = useState(false); */
  const [menuSelected, setMenuSelected] = useState("All Years");
  const [allData, setAllData] = useState({});
  const [forceFilter, setForceFilter] = useState(false);
  const [variables, setVariables] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [variableId, setVariableId] = useState(
    props?.variableId ?? props?.variableId
  );
  const [isServerError, setIsServerError] = useState({
    status: false,
    title: null,
    message: null,
  });
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search); // id=123
  const [measuresArr, setMeasuresArr] = useState();
  const [tableauUrl, setTableauUrl] = useState("");
  const [showcard , setShowcard] = useState();
  const [remark , setRemark] = useState();

  let page = params.get("back"); // 123

  /*Start Comapring variables with Array includes same variable Name */

  let varName2 = measuresArr?.subCategories
    .map((el) => el.subTopic.map((item) => item.varName))
    .flat();
  //  let flatArr=varName2.flat()
  console.log(varName2, "Arr");
  let ArrFilter = [];
  varName2?.map((i) => {
    if (i.includes(variables?.response?.response?.variable_name)) {
      // console.log(i ,'item')
      ArrFilter.push(i);
    }
  });
  console.log(ArrFilter, "item");

  /*End Comapring variables with Array includes same variable Name */

  /*start getting the Matching variable for URL */

  let URL = ArrFilter[0];
  console.log(URL, "url");
  /*End getting the Matching variable for URL */

  console.log(variables?.response?.response?.variable_name, "variable");

  var categoryL = variables?.response?.response?.category;
  console.log(categoryL, "category");

  useEffect(() => {
   if(variables?.response?.response?.trends_flag === true) {
      getMeasures();
    }
  }, [variables]);

  let surQues=variables?.response?.response?.survey_questions[0]?.replace("''","'")

  const getMeasures = () => {
    axios({
      method: "POST",
      url: environment.getMeasures,
      data: {
        category: variables
          ? variables?.response?.response?.category
          : "Gender & Marriage",
      },
    }).then((response) => {
      if (response.data) {
        setMeasuresArr(response.data); /* Popover Measures Data */
      }
    });
  };

 

  /* const filterNumbers = (min, max) => {
    return function (a) { return a >= min && a <= max; };
  } */
  let show_res;
 
  let remark_res;

  
  const getVariablesData = () => {
    let id = "";
    if (props.isAnalysisOrExtract || props.moduleName == "Trends") {
      id = variableId;
    } else {
      id = _id;
    }
    let url =
      environment.getUserVariableByID +
      id +
      `?workspace_id=${parseInt(localStorage.defaultProjectNo)}`; //for guest user
    if (localStorage.isAdmin) {
      url =
        environment.getVariableByID +
        id +
        `?workspace_id=${parseInt(localStorage.defaultProjectNo)}`; //for logged in user
    }

    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        if (response.data) {
          setVariables(response.data);
          setAllData(response.data);
          
          let var_name = response.data.response?.response?.variable_name;
        
          if (var_name == "year" || var_name == "ballot" || var_name == "id_") {
            setForceFilter(true);
          }
           show_res = response.data.response?.response?.show_res.map((i)=>{
            return <li>{i.showcard_text}</li>
          });
          
          console.log(show_res ,'+ show_res_test2')
          remark_res =  response.data.response?.response?.remark_res.map((i)=>{
            return <li> {i.remark_text} </li>
          });
          console.log(remark_res ,' + remark_res_test2')
          setShowcard(show_res);
          setRemark(remark_res);

          let id = "";
          if (props.isAnalysisOrExtract || props.moduleName == "Trends") {
            id = variableId;
          } else {
            id = _id;
          }
          var array = localStorage.varInCart
            ? localStorage.varInCart.split(",")
            : [];
          if (array.find((x) => x == id)) {
            // setAdded(true)
            setSaved(true);
            setShowText(true);
          }
        }
      })
      .catch((error) => {
        //this.props.history.push("/error");

        alert(error);
      });
  };
  // sessionStorage.setItem('varS',variables?.response?.response?.variable_name)
  useEffect(() => {
    
    if (filteredYears.length) {
      if (
        !(
          filteredYears.length == 2 &&
          filteredYears[0] == min_max_year[0] &&
          filteredYears[1] == min_max_year[1]
        )
      )
        setMenuSelected("Filtered Years");
    }
    getVariablesData();
  }, [_id]);
  
  const toggleClick = () => {
    setSaved(!isSaved);
    if (!isSaved) {
      onAddToMyGSSSubmit();
    } else {
      onRemoveFromMyGss();
    }
  };

  const onClickOnMore = () => {
    setLimited(!isLimited);
  };
  /* const displayTags = (data) => {
    let arr = [].concat(data?.subject, data?.module, data?.my_tags, data?.shared_tags)
    return arr.filter(function (val) { if (val) return val; }).join(", ");
  } */

  const onAddToMyGSSSubmit = () => {
    // setShowConfirm(false);

    let id = "";
    if (props.isAnalysisOrExtract || props.moduleName == "Trends") {
      id = variableId;
    } else {
      id = _id;
    }
    let reqObj = {
      cart_items: [id],
      workspace_id: parseInt(localStorage.defaultProjectNo),
      cartType: "analysis",
    };
    dispatch(addToExtract(reqObj))
      .then((res) => {
        if (res?.data?.response.result == "success") {
          setSaved(true);
          setShowText(true);
        } else if (res?.data?.response.result == "error") {
          setSaved(false);
          setShowText(false);
          setIsServerError({
            ...isServerError,
            status: true,
            title: "Error",
            message: res?.data?.response?.message,
          });
        }
      })
      .catch((error) => {
        let msg = "";
        if (error.response) {
          msg = error.response.data;
        } else if (error.request) {
          msg = error.request;
        } else {
          msg = error.message;
        }
        setIsServerError({
          ...isServerError,
          status: true,
          title: "Error",
          message: msg.message
            ? msg.message
            : "Something went wrong. Try Again!!",
        });
      });
    // }
  };

  const onRemoveFromMyGss = () => {
    // setShowConfirm(false);
    let id = "";
    if (props.isAnalysisOrExtract || props.moduleName == "Trends") {
      id = variableId;
    } else {
      id = _id;
    }
    dispatch(removeFromMyGSS([id]))
      .then((res) => {
        if (res?.data?.response.result == "success") {
          setSaved(false);
          setShowText(false);
        }
      })
      .catch((error) => {
        let msg = "";
        if (error.response) {
          msg = error.response.data;
        } else if (error.request) {
          msg = error.request;
        } else {
          msg = error.message;
        }
        setIsServerError({
          ...isServerError,
          status: true,
          title: "Error",
          message: msg.message
            ? msg.message
            : "Something went wrong. Try Again!!",
        });
      });
  };

  const onAddToExtractSubmit = () => {
    // setShowConfirm(false);

    let id = "";
    if (props.isAnalysisOrExtract || props.moduleName == "Trends") {
      id = variableId;
    } else {
      id = _id;
    }
    setAdded(true);
    if (variables?.response?.response?.isAlreadyInExtract == 0) {
      let reqObj = {
        cart_items: [id],
        workspace_id: parseInt(localStorage.defaultProjectNo),
        cartType: "extract",
      };
      dispatch(addToExtract(reqObj))
        .then((res) => {
          if (res?.data?.response.result == "success") {
            setAdded(false);
            let variable_details = { ...variables };
            variable_details.response.response.isAlreadyInExtract = 1;
            setVariables(variable_details);
            setAllData(variable_details);
          }
        })
        .catch((error) => {
          let msg = "";
          if (error.response) {
            msg = error.response.data;
          } else if (error.request) {
            msg = error.request;
          } else {
            msg = error.message;
          }
          setIsServerError({
            ...isServerError,
            status: true,
            title: "Error",
            message: msg.message
              ? msg.message
              : "Something went wrong. Try Again!!",
          });
        });
      // }
    } else {
      dispatch(removeFromMyGSS([id], "extract")).then(() => {
        setAdded(false);
        let variable_details = { ...variables };
        variable_details.response.response.isAlreadyInExtract = 0;
        setVariables(variable_details);
        setAllData(variable_details);
      });
    }
  };

  const handleChange = (event) => {
    setMenuSelected(event.target.value);
    if (event.target.value == "All Years") {
      setVariables(allData);
    }
  };

  /* const backto = () => {
    props.history.goBack();
  } */

  const onClickTabulate = () => {
    setIsSubmitted(true);
    let reqObj = {
      cart_items: [+variables?.response?.response?.variable_id],
      workspace_id: parseInt(localStorage.defaultProjectNo),
      cartType: "analysis",
    };
    dispatch(addToExtract(reqObj))
      .then((res) => {
        setIsSubmitted(false);
        history.push("/createAnalysis");
      })
      .catch((e) => {
        setIsSubmitted(false);
        history.push("/createAnalysis");
      });
  };
  const countBallotInfo = (data) => {
    let count = 0;
    data.map((el) => {
      if (
        el.isQuestionAvailable == "full" ||
        el.isQuestionAvailable == "partial"
      ) {
        count++;
      }
    });
    return count;
  };

  const setIsServerResponseOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };
  console.log(showcard?.length,'+ type')
  const setClose = () => {};
  let remLen= remark?.length !== 0
  return (
    <div>
      <CustomModal
        title={isServerError.title}
        titleText={isServerError.title}
        isOpen={isServerError.status}
        setIsOpen={setIsServerResponseOpen}
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>
      {variables?.response?.response?.variable_name && (
        <div className="noscroll gss-vshow" id="ws-content">
          <div className="gss-menu-bar back-link-bar">
            {props.isAnalysisOrExtract ? (
              <button
                className={"analysis-create"}
                onClick={() => props.setVariableToggel(true)}
              >
                <ReturnBack compName={props.moduleName}></ReturnBack>
              </button>
            ) : page == "variableList" ? (
              <Link className="no-decoration" to="/MyGSS?page=variableList">
                <img src={arrow} alt="" />
                Back To Variable List
              </Link>
            ) : props.moduleName == "Trends" ? (
              <Link
                className="no-decoration"
                onClick={() => props.setVariableToggel(true)}
              >
                <img src={arrow} alt="" />
                Back To Key Trends
              </Link>
            ) : (
              <Link className="no-decoration" to="/variables/vfilter">
                <img src={arrow} alt="" />
                Back To Variable Results
              </Link>
            )}
          </div>
          <br />
          <div className="col-12">
            <div className="var-title">
              <div className="row gss-wide-section-inner">
              <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 var-header ">
                  <div className="gss-title varTitle">
                    {variables?.response?.response?.variable_label}
                  </div>

                  <div className="subject row m-0">
                    <div className="col-lg-4 col-md-6 col-sm-6 p-0">Variable:</div>
                    <div className="subjects col-lg-6 col-md-6 col-sm-6 p-0">
                      {variables?.response?.response?.variable_name}
                    </div>
                  </div>
                  <div className="subject row m-0">
                    <div className="col-lg-4 col-md-6 col-sm-6 p-0">Module:</div>
                    <div className="subjects col-lg-6 col-md-6 col-sm-6 p-0">
                      {variables?.response?.response?.module?.join(", ")}
                    </div>
                  </div>
                  <div className="subject row m-0">
                    <div className="col-lg-4 col-md-6 col-sm-6 p-0">Gss Tags:</div>
                    <div className="subjects col-lg-6 col-md-6 col-sm-6 p-0">
                      {variables?.response?.response?.subject?.join(", ")}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-6 add-cart ">
                  <table>
                    <tr>
                      <td>
                        {authDetails.isSignedIn && !forceFilter && (
                          <button
                            type="button"
                            className="gss-btn w-100 btn-orange  mt-2 mr-2 rounded-0"
                            onClick={toggleClick}
                            variant="secondary"
                          >
                            {!showText ? "Save to MyGSS" : "Remove from MyGSS"}
                          </button>
                        )}
                      </td>
                      <td>
                        {authDetails.isSignedIn && (
                          <button
                            type="button"
                            className="gss-btn btn-secondary   mt-2 w-100 rounded-0"
                            variant="secondary"
                            disabled={isSubmitted}
                            onClick={onClickTabulate}
                          >
                            &nbsp; &nbsp;<i className="fa fa-table"></i>{" "}
                            Tabulate &nbsp; &nbsp;
                          </button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {variables?.response?.response?.trends_flag ===
                          true && (
                          <a
                            href={
                              "/trends?category=" +
                              encodeUri(
                                variables?.response?.response?.category
                              ) +
                              "&measure=" +
                              URL
                            }
                          >
                            <button
                              type="button"
                              className="gss-btn btn-orange w-100 mt-2 mr-2 rounded-0"
                              variant="secondary"
                            >
                              <i
                                class="fa fa-line-chart"
                                aria-hidden="true"
                              ></i>{" "}
                              Key Trends
                            </button>{" "}
                          </a>
                        )}
                      </td>
                      <td>
                        {authDetails.isSignedIn && (
                          <button
                            type="button"
                            className="gss-btn btn-secondary w-100 mt-2 rounded-0 pl-3 pr-4"
                            onClick={onAddToExtractSubmit}
                            variant="secondary"
                            disabled={isAdded}
                          >
                            {!variables?.response?.response?.isAlreadyInExtract
                              ? "Add to Extract"
                              : "Remove From Extract"}
                          </button>
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
               
                <div className="clearfix"></div>
              </div>
            </div>
            <hr />
            <div className="var-survey">
              <div className="gss-wide-section-inner">
                <div className="related-sect">
                  <div className="gss-title">Related Variables</div>
                  <div className="related">
                    {isLimited &&
                    variables?.response?.response?.related_variables ? (
                      <span>
                        {variables?.response?.response?.related_variables
                          .slice(0, 10)
                          .map((data) => (
                            <a href={"/variables/" + data?.id + "/vshow"}>
                              {data.name}
                            </a>
                          ))}{" "}
                      </span>
                    ) : (
                      <span>
                        {variables?.response?.response?.related_variables &&
                          variables?.response?.response?.related_variables.map(
                            (data) => (
                              <a href={"/variables/" + data?.id + "/vshow"}>
                                {data?.name}
                              </a>
                            )
                          )}
                      </span>
                    )}
                    {isLimited ? (
                      <a id="show_all_vars" href="#">
                        <span onClick={onClickOnMore}> more... </span>
                      </a>
                    ) : undefined}
                  </div>
                </div>
                
                <div className="survey-sect">
                  <div className="gss-title quest">
                    Survey Questions
                    <div className="subtitle">
                      Questions associated with this variable:
                    </div>
                  </div>
                  <div className="qtext" style={{ "white-space": "pre-wrap" }}>
                    {variables?.response?.response
                      ? surQues
                      : ""}
                  </div>
                </div>
                
                {showcard?.length !==0 &&
                  <div className="survey-sect">
                  <div className="gss-title quest">
                     {
                      (showcard?.length > 1)?
                      'Showcards': 'Showcard'
                     }
                    <div className="subtitle"> 
                     {showcard}
                    </div>
                  </div>
                </div>
                }
               {remLen &&
                <div className="survey-sect">
                  <div className="gss-title quest">
                    {
                      (remark?.length > 1)?
                      'Remarks': 'Remark'
                    }
                    <div className="subtitle">
                   {remark}
                    </div>
                  </div>
                </div>
                }
                <div className="survey-sect">
                  <div className="gss-title">
                    Year Availability
                    <div className="subtitle">
                      Available in{" "}
                      {countBallotInfo(variables?.response?.response?.years)} of{" "}
                      {variables?.response?.response?.years.length} years total.
                      Hover over a year to see ballot information.
                    </div>
                  </div>
                  <div className="gss-years">
                    <BallotInfo
                      years={variables?.response?.response?.years}
                      isVarDetailPage="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="var-stats gss-section">
              <div className="gss-title">
                {variables?.response?.response?.variable_type == "discrete" ? (
                  <div>
                    <div className="pull-left">Summary by Year</div>
                    {/* <div className="pull-right showing-years">Showing All Years</div> */}
                    <div className="pull-right showing-years">
                      {filteredYears.length && !forceFilter ? (
                        <div className="dropdown pull-right">
                          <span className="mr-2">Filtered Years</span>
                          <ul className="dropdown-menu">
                            <li>Filtered Years</li>
                            <li>All Years</li>
                          </ul>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={menuSelected}
                            onChange={handleChange}
                          >
                            <MenuItem value="Filtered Years">
                              Filtered Years
                            </MenuItem>
                            <MenuItem value="All Years">All Years</MenuItem>
                          </Select>
                        </div>
                      ) : (
                        <div>Showing All Years</div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="clearfix"></div>
              </div>
              <div>
                <YearsTable
                  variables={variables}
                  menuSelected={menuSelected}
                  filteredYears={filteredYears}
                  forceFilter={forceFilter}
                />
              </div>
              <div className="gss-title">Summary Statistics</div>
              <TableContainer style={{ width: 300 }} component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th"> Valid Cases</TableCell>

                      <TableCell scope="row">
                        {
                          variables?.response?.response?.summary_statistics
                            ?.valid_cases
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th"> Missing Cases</TableCell>
                      <TableCell scope="row">
                        {
                          variables?.response?.response?.summary_statistics
                            ?.missing_cases
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    data: state.reducer,
  };
}

VariableDetails.defaultProps = {
  isAnalysisOrExtract: false,
  variableId: "",
  setVariableToggel: () => "",
};

export default connect(mapStateToProps)(VariableDetails);
