const initialState = {    
    trend_name:""
   };
   const trendsReducer = (state = initialState, action) => {
   switch (action.type) {
       case 'SET_TRENDNAME': {            
           const trend_name = action.payload;
                   return {
                       ...state,
                       trend_name
                   }
       }
       default:
           return state;
   }
   };
   
   export default trendsReducer;