import React from 'react'
import './HelpContainer.css'
import Journalists from "../../Images/Journalists.png";
import Instructors from "../../Images/Instructors.png";
import Researchers from "../../Images/Researchers.png";
import { Col, Container, Row } from 'react-bootstrap';
import HelpCard from '../UI/HelpCard/HelpCard';

const HelpContainer = () => {
    let items = [{
        id: 1,
        title: "Researchers",
        details: "GSS Data Explorer offers a comprehensive dataset for all years of the GSS, with tools that help you explore and save variables and analyze data online before extracting it for use in your statistical software package.",
        image: Researchers,
    }, {
        id: 2,
        title: "Instructors",
        details: "The GSS is a major teaching tool at colleges and universities. GSS Data is used by over 400,000 students each year. The GSS Data Explorer allows you to quickly tag data for your classes and easily share them with students. The extract and analysis tools also make it easy to review work in progress.",
        image: Instructors,

    },
    {
        id: 3,
        title: "Journalists",
        details: "The GSS is frequently referenced in leading publications, including the New York Times, the Wall Street Journal, and the Associated Press. GSS Data Explorer allows you to view the range of subjects and questions asked in the GSS, and find reports written about key trends and emerging themes.",
        image: Journalists,
    }
    ]

    return (
        <Container className="text-center">
            <h1 className="pt-5">{`How GSS Can Help You`}</h1>
            <h2 className="work-h2">{`The GSS has been a reliable source of data to help researchers, students, and journalists monitor and explain trends in American behaviors, demographics, and opinions. GSS Data Explorer offers an easier way to explore, analyze, extract, and share GSS data.`}</h2>
            <Row className="help-container">
                {
                    items.map((item) => (
                        <Col key={item.id} sm={12} md={6} lg={4} xl={4}>
                            <HelpCard item={item} key={item.id} />
                        </Col>

                    ))

                }

            </Row>

        </Container>
    )
}

export default HelpContainer;
