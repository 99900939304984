const initialState = {    
    filtered_years:{}
   };
   const filteredYearsReducer = (state = initialState, action) => {
   switch (action.type) {
       case 'SET_FILTERED_YEARS': {            
           const filtered_years = action.payload;
                   return {
                       ...state,
                       filtered_years
                   }
       }
       default:
           return state;
   }
   };
   
   export default filteredYearsReducer;