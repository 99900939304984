const VariableActionTypes = {
    GET_VARIABLES: 'GET_VARIABLES',
    GET_TAG_CATEGORIES: 'GET_TAG_CATEGORIES',
    ADD_TO_TAG: 'ADD_TO_TAG',
    REMOVE_FROM_MYGSS: 'REMOVE_FROM_MYGSS',
    ON_VARIABLE_SELECT: 'ON_VARIABLE_SELECT',
    VARIABLE_REQUEST: 'VARIABLE_REQUEST',
    VARIABLE_LIST_SUCCESS: 'VARIABLE_LIST_SUCCESS',
    VARIABLE_FAIL: 'VARIABLE_FAIL',
    //Create Tag
    TAG_REQUEST: 'TAG_REQUEST',
    TAG_FAIL: 'TAG_FAIL',
    CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
    //Add to Tag
    ADD_TO_TAG_REQUEST: 'ADD_TO_TAG_REQUEST',
    ADD_TO_TAG_FAIL: 'ADD_TO_TAG_FAIL',
    ADD_TO_TAG_SUCCESS: 'ADD_TO_TAG_SUCCESS',
    RESET_VARIABLE: 'RESET_VARIABLE'
};

export default VariableActionTypes;