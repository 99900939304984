import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="container mt-5" id="privacy-policy">
            <div className="row">
                <div className="col">
                    <h3 className="gss-bold-title f-helveticaneue-bold">GSS Data Explorer Site Privacy Policy</h3>
                    <div className="sharing mb-3">Your privacy is important to us. We have developed this privacy policy from industry guidelines and standards, and local, national, and international laws and requirements. All privacy practices and methods described in this policy apply only insofar as permitted by applicable standards, laws, and requirements. This privacy statement relates only to this GSS Data Explorer site. Please visit the privacy statement at norc.org for additional information on NORC’s privacy policy as it relates to other research that NORC conducts.</div>
                    <div className="sharing mb-3">NORC maintains appropriate technical, administrative, and physical safeguards to protect information disclosed to or collected by NORC on the GSS Data Explorer site. NORC reviews, monitors, and evaluates its privacy practices and protection systems on a regular basis. Notwithstanding the foregoing, transmissions over the internet and/or a mobile network are not one hundred percent secure and NORC does not guarantee the security of transmissions. Please be apprised that all internet-based communication is subject to the remote likelihood of tampering from an outside source.</div>
                    <div className="sharing mb-3">In keeping with the highest privacy standards, NORC does not collect any specific information regarding visitors to our GSS Data Explorer site. We do accumulate general information for analytics purposes; however, such does not contain any individual-specific information.</div>
                    <div className="sharing mb-3">Based upon information voluntarily provided by visitors posing requests, we use return email, physical addresses, or phone numbers to address such. This contact information is not used for any other purpose and is not shared with outside parties. To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we receive online. Your personal data may be kept as long as NORC believes it to be current. You may notify NORC if you wish to remove your return contact information from our system. If you have questions or complaints regarding NORC’s privacy practices on GSS Data Explorer site, please contact us via email at <a href="mailto:privacy@norc.org" target="_blank" > privacy@norc.org.</a>&nbsp;You may also send a letter by U.S. Mail to:</div>
                    <div className="sharing mb-3">Chief Privacy Officer<br/>NORC Privacy Compliance Office<br/>55 East Monroe Street, 20th Floor<br/>Chicago, IL 60603</div>
                    <div className="sharing mb-3">We may periodically update this privacy statement to reflect changes to our information practices.</div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;