import React, { useState } from 'react'
import BigButton from '../BigButton/BigButton';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import EnhancedTableCheckbox from '../EnhancedTableCheckbox/EnhancedTableCheckbox';
import CategoryAccordian from '../CategoryAccordian/CategoryAccordian';

import './AddTagModel.css';
import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { addToTag, moveToTag, copyToDiffTag } from '../../../Store/actions/variable';
import { deepCopy } from '../../../Store/utils/variable.utils';
import { useSelector } from 'react-redux';
import { search, removeDuplicate } from "../../../Store/utils/variable.utils"
import ClearIcon from '@material-ui/icons/Clear';

function getModalStyle() {
    return {
        overflow: "scroll"
    };
}

const AddTagModel = ({ rows, selected, page,
    rowsPerPage, addTagModal, isMove, selectedCategory, actionType, ...props }) => {

    const dispatch = useDispatch()
    let variableByCategory = useSelector(state => state.variable.variableByCategory);

    const [rowsCat, setRowsCat] = useState(deepCopy(variableByCategory))

    const [formSubmit, setFormSubmit] = useState(false);
    let [selectedcatType, setSelectedcatType] = useState([])
    let [localVariables, setLocalVariables] = useState([])
    let [isSelected, setIsSelected] = useState(false)
    const rowKeys = Object.keys(rowsCat);

    const onAddToTag = ({ tagId, title }) => {
        let variables = [...deepCopy(rows)];
        let isEdit = false;
        rows.map(row => {
            if (search(title, row.tags_info) === undefined) {
                isEdit = true;
                rowsCat[tagId].variables.push(row);
            }
            return row;
        })
        if (isEdit) {
            setLocalVariables([...variables])
            setIsSelected(!isSelected);
            selectedcatType.push(tagId)
            setSelectedcatType(selectedcatType)
            setRowsCat(rowsCat)
        } else {
            alert("Variables already exist in Tag");
        }
    }

    const removeFromTag = (tagId) => {
        let filtered = removeDuplicate(rowsCat[tagId].variables, rows, "vID");
        rowsCat[tagId].variables = [...filtered]
        setIsSelected(!isSelected);
        setRowsCat(rowsCat);
        let removedItem = selectedcatType.filter(d => d != tagId)
        setSelectedcatType(removedItem)
    }



    const onSubmit = () => {
        let variables = rows.map(data => ({ "id": data.vID, "variable_name": data.name, "label": data.group }))
        if (variables.length === 0 || selectedcatType.length === 0) {
            alert("At least 1 variable and 1 tag should be selected.");
            return;
        }
        setFormSubmit(true);
        let reqObj = { variables, tags: [...selectedcatType] }
        if (actionType == "addToTag") {
            dispatch(addToTag(reqObj)).then(() => {
                afterFormSubmit()
            })
        } else if (actionType == "copyToDiffTag") {
            dispatch(copyToDiffTag(reqObj)).then(() => {
                afterFormSubmit()
            })
        } else if (actionType == "moveToTag") {
            reqObj.source_tag = selectedCategory.id;
            dispatch(moveToTag(reqObj)).then(() => {
                afterFormSubmit()
            })
        }
    }

    const afterFormSubmit = () => {
        props.setSelected([])
        props.setSelectedObj([])
        props?.getVariablelist()
        onClose()
        setFormSubmit(false);
    }

    const onClose = () => {
        props.setSelected([])
        props.setSelectedObj([])
        props.setAddTagModal(false)
    }

    const getCategoryAccordian = (id) => {        
        if ((isMove && (selectedCategory.id !== id)) || (!isMove)) {
            if (id.toLocaleLowerCase() !== "none") {
            return <CategoryAccordian
                key={id}
                isModel={true} rows={rowsCat[id].variables}
                title={rowsCat[id].tagName}
                tagId={id}
                onAddToTag={onAddToTag}
                removeFromTag={removeFromTag}
                selectedcatType={selectedcatType}
                isSelected={isSelected}
            />
            }
        }
        return "";
    }

    return (
        <Dialog
            open={addTagModal}
            style={getModalStyle()}
            maxWidth="xs"
            aria-labelledby="max-width-dialog-title"
            className="tag-model h-auto "
            disableBackdropClick={true}
            fullWidth={true}
            id="add-to-tag"
        >
            <DialogTitle>
                <Col as="span" className="px-3 py-1 m-0"> {
                    (actionType == "addToTag")
                        ? "Add to Tag"
                        : ((actionType == "copyToDiffTag")
                            ? "Copy to Different Tag"
                            : "Move to Tag")
                }</Col>
                <Col as="span" className="px-3 py-1 m-0">
                    <ClearIcon className="float-right clear-icon" onClick={onClose} />
                </Col>

            </DialogTitle>
            <DialogContent className="h-auto">
                <EnhancedTableCheckbox
                    dClassName={"modal-table"}
                    showPagination={false}
                    disableCheckBox={true}
                    rows={rows}
                    selected={selected}
                    page={page}
                    rowsPerPage={rowsPerPage}
                />
                <Col as="h5" className="font-weight-bold p-0 mt-4">Your Tags</Col>
                <div className={'custom-scroll'}>
                    {rowKeys.map(row => getCategoryAccordian(row))}
                </div>
            </DialogContent>
            <DialogActions >
                <BigButton classname="btn-cancel" onClick={onClose}>CANCEL</BigButton>
                <BigButton classname="btn-orange btn-save" onClick={onSubmit} disabled={(formSubmit || selectedcatType.length === 0 || rows.length === 0)}>SAVE CHANGES</BigButton>
            </DialogActions>
        </Dialog>
    )
}

AddTagModel.defaultProps = {
    isMove: false,
    selectedCategory: '',
    actionType: 'addToTag',
    getVariablelist: () => ""
};
export default AddTagModel
