
import React from 'react';
import { Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom'

const CartItem = ({ data, ...props }) => {

    const history = useHistory()
    const variableDetails = (e, id) => {
        e.stopPropagation();
        history.push(`/variables/${id}/vshow`)
    }

    return (
        <li key={data.orignlName}
            className={'var-item'}
            id={data.vID}
        >
            <div className="info ">
                {/* <input className={(data.isTagCategories || props.isPopOver ? 'd-none' : '') + ' mr-3 mt-1'} type="checkbox" checked={data.isChecked} onChange={(event) => props.cartItemAction(event, data.vID)} /> */}
                <div className="px-2">
                    <div className="d-flex">
                        <div className="var-label">
                            <button className={"analysis-create"} onClick={(e) => variableDetails(e, data.vID)}>
                                {data.orignlName}
                            </button>
                            {(data.expanded) ?
                                (data.tags_info.map((s) => (
                                    <Chip
                                        key={s.id}
                                        className="ht-20 ml-2"
                                        label={s.name}
                                        variant="outlined"
                                        title={s.name}
                                    />
                                )))
                                :
                                (data.tags_info.slice(0, 1).map((s) => (
                                    <Chip
                                        key={s.id}
                                        className="ht-20 ml-2"
                                        label={s.name}
                                        variant="outlined"
                                        title={s.name}
                                    />
                                )))
                            }
                        </div>
                        <div>
                            {(data.tags_info.length > 1) ?
                                (<span className="list-group-item rounded-0 border-0 p-0 m-0 text-nowrap">
                                    <button className={"button-to-link"} onClick={() => props.toggleTags(data.vID)}>{(data.expanded) ? "See less" : "+" + (data.tags_info.length - 1) + " More"}</button>
                                </span>) : ""}
                        </div>
                    </div>
                    <div className="var-desc">{data.label}</div>
                </div>

            </div>
        </li>

    );
};

export default CartItem;