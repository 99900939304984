import React from 'react'
import HomeCarousel from '../Carousel/HomeCarousel';
import GSSWorks from '../GSSWorks/GSSWorks';
import HelpContainer from '../HelpContainer/HelpContainer';
import InfoBanner from '../InfoBanner/InfoBanner';
import SearchContainer from '../SearchContainer/SearchContainer';
import './Home.css'

const Home = props => {
    return (
        <div className="home">
            <HomeCarousel />
            <GSSWorks />
            <InfoBanner />
            <SearchContainer />
            <HelpContainer />
        </div>
    )
}



export default Home;
