import React from "react";
import { Col, Row } from "react-bootstrap";
//import BigButton from "../../Components/UI/BigButton/BigButton";
import BigButton from "../../Components/UI/BigButton/BigButton";
//import "./MediaRoom.css";
import "./Extract.css";
import { NavLink } from "react-router-dom";

import Extract_3 from "../../Images/Extract_3.png";
import Extract_4 from "../../Images/Extract_4.jpg";
import Extract_5 from "../../Images/Extract_5.png";
import Extract_6 from "../../Images/Extract_6.png";
import Extract_7 from "../../Images/Extract_7.jpg";

const Extract = () => {
  /* const [data, setData] = useState([]); */

  return (
    <>
      <div className="gss-Extracts">
        {" "}
        <React.Fragment>
          <div>
            <div className="gss-large-title unaligned_title">
              Create Extracts
            </div>
          </div>
        </React.Fragment>
        <div className="my-4 mx-5 ">
          <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
              <div className="Container">
                <p>
                  Extract data customized for your project or download the
                  entire GSS dataset. Multiple output formats let you download
                  data and metadata, metadata only, and control files that allow
                  you to import data directly to SAS, SPSS, Stata, and R.
                </p>
              </div>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">1</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      To extract the entire GSS dataset without creating an
                      account, click on the “Quick Downloads” link at the bottom
                      of the GSS DE website, then choose to download the data in
                      either STATA or SAS format.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">2</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      To create a custom extract, start by adding variables to
                      MyGSS. See the
                      <NavLink className="green-link" to="/search_variables">
                        {" "}
                        Variable Search guide
                      </NavLink>{" "}
                      for instructions.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">3</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Once you have selected variables for analysis, you can
                      begin creating a custom data extract. Start by clicking on
                      “MyGSS” at the top of the page to get to the MyGSS
                      Variables tab.
                    </p>
                    <div>
                      <img
                        src={Extract_3}
                        className="Extracts_media"
                        alt="Extract_3"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">4</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Use the checkboxes on the left to select the variables you
                      would like to include in an extract, then select “Add to
                      Extract” from the Actions dropdown. Click the Submit
                      button.
                    </p>
                    <div>
                      <img
                        src={Extract_4}
                        className="Extracts_media"
                        alt="Extract_4"
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">5</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Next, select the Extracts tab and click “Create Extract.”
                    </p>
                    <div>
                      <img
                        src={Extract_5}
                        className="Extracts_media"
                        alt="Extract_5"
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">6</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>Customize your extract.</p>
                    <ul class="a">
                      <li>
                        Name and describe your extract to help you remember what
                        is included, or what information you are trying to
                        capture.
                      </li>
                      <li>
                        Drag the variables you would like to include in this
                        extract from the box on the right to the “Choose
                        Variables” field.
                      </li>
                      <li>
                        Case selection (optional): If you would like to limit
                        your extract to a subset of the data, drag one or more
                        variables to the “Case Selection” field and use the
                        checkboxes to choose which values will be included, or
                        enter a range.
                      </li>
                      <li>Select your preferred file format(s).</li>
                      <li>Click “Create Extract.”</li>
                    </ul>
                    <div>
                      <img
                        src={Extract_6}
                        className="Extracts_media"
                        alt="Extract_6"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Extracts_circle">7</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Once your extract finishes processing (note: you may need
                      to click the “refresh” button on the screen – not your
                      browser refresh), your completed extract will be available
                      for download in the MyGSS Extracts tab. After you choose
                      your extract of interest by clicking the box to the left
                      of the extract, use the “Actions” drop-down to download
                      your extract to your computer, or click “Export” to upload
                      it to Google Drive or Dropbox.
                    </p>
                    <ul class="a">
                      <li>
                        NOTE: If you choose to download instead of extract, it
                        will download directly to your computer. Depending on
                        your settings, it may move directly to your local files
                        or may reside in your web browser.
                      </li>
                    </ul>
                    <div>
                      <img
                        src={Extract_7}
                        className="Extracts_media"
                        alt="Extract_7"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} lg={4} xl={4}>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and
                  how to use the data.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100">
                  <a style={{ color: "white" }} href="/FAQ">
                    VIEW FAQ
                  </a>
                </BigButton>
              </div>

              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>Submit your question or issue to the experts at GSS.</p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact">ASK AN EXPERT</BigButton>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Extract;
