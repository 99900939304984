import React from 'react'
import './FormCard.css'
const FormCard = (props) => (
       <div className="gss-registration">
              <div className="reg-title">
                     {props.title}
              </div>
              {props.header ? (
                     <>
                     <div className="form-header">
                            <h2>
                                   {props.header}
                            </h2>
                     </div>
                       <div className="form-subheader">
                       <h4>
                              {props.subheader}
                       </h4>
                </div>
                     </>
                     
                     ) : ""}


              {props.children}
       </div>



)

export default FormCard;