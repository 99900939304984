import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../environment";
import "./Trends.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Col, Card, Row } from "react-bootstrap";
import CIVIL from "../../Images/civil.jpg";
import CURRENT from "../../Images/current.jpg";
import GENDER from "../../Images/gender.jpg";
import POLITICS from "../../Images/politics.jpg";
import QUALITY from "../../Images/qualityWorking.jpg";
import RELIGION from "../../Images/religion.jpg";
import { TabContext, TabPanel } from "@material-ui/lab";
import { Box, Tab, Tabs } from "@material-ui/core";
import TrendsDetails from "./TrendsDetails/TrendsDetails";
import arrow from "../../Images/back_arrow.jpg";
import VariableDetails from "../VariableManagement/VariableDetails/VariableDetails";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const Trends = () => {
  const getTrends = () => {
    axios
      .get(`${environment.getTrends}`)
      .then((resp) => {
        let data = resp.data;
        data.map((item) => {
          if (item.Category.toLowerCase().includes("civil")) {
            item.Img = CIVIL;
          }
          if (item.Category.toLowerCase().includes("quality")) {
            item.Img = QUALITY;
          }
          if (item.Category.toLowerCase().includes("gender")) {
            item.Img = GENDER;
          }
          if (item.Category.toLowerCase().includes("politics")) {
            item.Img = POLITICS;
          }
          if (item.Category.toLowerCase().includes("current")) {
            item.Img = CURRENT;
          }
          if (item.Category.toLowerCase().includes("religion")) {
            item.Img = RELIGION;
          }
        });
        setAllCategories(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [showTrendDetails, setShowTrendDetails] = useState(false); /* Hide Show of Trend list and Trend details*/
  const [selCategory, setSelCategory] = useState('');
  const [selSubCategory, setSelSubCategory] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const [tabValue, setTabValue] = useState('');
  /* If Redirect from Facebook or Twitter then these params are used for load of dropdowns , chart & Other Data */
  const [paramsVal, setParamsVal] = useState({});
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  /* end */
  const [variableId, setVariableId] = useState();
  const [variableToggle, setVariableToggle] = useState(true);
  const location = useLocation();
  const clickTrends = (category, subCategory) => {
    setSelCategory(category);
    setSelSubCategory(subCategory);
    setShowTrendDetails(true);
    setTabValue(category);
  }
  const back = () => {
    setShowTrendDetails(false);
  }

  const handleChange = (event, newValue) => {
    setSelCategory(newValue);
    setSelSubCategory('');
    setTabValue(newValue);
  };

  const updateParamsCall = (event, newValue) => {
    setIsInitialLoad(false);
    setParamsVal({
      questionResponse: '',
      breakdown: '',
      varName: ''
    });
  };

  const redirectTo = (varId) => {
    setVariableId(varId)
    setVariableToggle(false);
  }

  useEffect(() => {
    /* Fetching Search Params if redirect from FB or Twitter */
    if (location.search) {
      let params = new URLSearchParams(location.search);
      clickTrends(params.get('category'), '');
      setParamsVal({
        questionResponse: params.get('Measure Category'),
        breakdown: params.get('Breakdown Label'),
        varName: params.get('measure')
      });
    }
    getTrends();
  }, []);

  useEffect(() => {
    if (!variableToggle) {
      setVariableId();
    }
  }, [variableToggle]);

  return (
    <div className='key-trends'>
      {variableToggle ? "" : (
        <VariableDetails isAnalysisOrExtract={false} variableId={variableId} setVariableToggel={setVariableToggle} moduleName="Trends" />
      )}
      <div style={{ display: (variableToggle == false) ? 'none' : "block" }}>
        {
          showTrendDetails && <div className="noscroll gss-vshow" id="ws-content"><div className="gss-menu-bar back-link-bar w-100">
           
            <a onClick={back} className="no-decoration">
            <img src={arrow} alt="" />
              Back To Key Trends
            </a>
          </div>
          <div>
              <div className="gss-large-title unaligned_title">Trends</div>
              <div className="trends-text-container"> 
              For the first time in its 50+ year history, the 2022 General Social Survey included in-person interviews, web self-administered surveys, and phone interviews. The results included in key trends reflect all of these modes except where noted, as certain variables may only be available in one mode. The GSS team is actively conducting research and welcomes feedback from the research community to study mode sensitivity and inform decisions on potential mode-specific statistical adjustments, and as such point estimates and standard errors may shift slightly in future data releases.
              For more information on the 2022 GSS methodology and its implications, please visit &nbsp;<a href="https://gss.norc.org/Get-The-Data">https://gss.norc.org/Get-The-Data</a>
             </div>
            </div>

          </div>
        }
        <div className="gss-trends" id="keyTrends" className={showTrendDetails ? 'd-none' : ''}>
          {" "}
          <React.Fragment>
            <div>
              <div className="gss-large-title unaligned_title">Key Trends</div>
            </div>

            <div className="trends-text-container">
              <div className="w-100">
                <div>
                  For the first time in its 50+ year history, the 2022 General Social Survey included in-person interviews, 
                  web self-administered surveys, and phone interviews. The results included in key trends reflect all of these 
                  modes except where noted, as certain variables may only be available in one mode. The GSS team is actively 
                  conducting research and welcomes feedback from the research community to study mode sensitivity and inform 
                  decisions on potential mode-specific statistical adjustments, and as such point estimates and standard errors 
                  may shift slightly in future data releases.
                </div>
              </div>
              <br />
              <div className="w-100">
              Key Trends is a tool that allows users to easily visualize historical trends of the GSS.To learn more about how to use this tool, click
                <NavLink
                  className="ml-1"
                  to={{
                    pathname: "/viewtrends/",
                  }}
                  
                >

                  here
                </NavLink>
              </div>
            </div>
          </React.Fragment>
          <div className="m-3">
            {allCategories.length > 0 && (
              <Row>
                {allCategories.map((trend) => (
                  <Col sm={12} md={6} lg={6} xl={6}>
                    <Card className="my-3 border-0">
                      <Card.Img src={trend.Img} variant="top" min-height="115" max-width="100%" />
                      <Card.Body className="p-0 pt-2">
                        <Card.Title as="h3" className="text-muted ">
                          {trend.Category}
                        </Card.Title>
                        <Card.Text as="ul">
                          {trend?.SubCategory.map((keys) => (
                            <>
                              <a
                                className="tt-name h6 d-block"
                                onClick={() => clickTrends(trend.Category.trim(), keys)}
                              >
                                {keys}
                              </a>
                            </>
                          ))}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </div>
        {
          showTrendDetails && (
            <>
              <Box
                sx={{ width: "100%", typography: "body1" }}
                className="gss-reports-tab"
              >
                <TabContext value={tabValue}>
                  <Box>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      variant="fullWidth"
                    >
                      {allCategories.map((category) => (
                        <Tab label={category.Category.trim()} value={category.Category.trim()} />
                      ))}
                    </Tabs>
                  </Box>
                  {allCategories.map((category) => (
                    <TabPanel value={category.Category.trim()}>

                      {selCategory && (
                        <TrendsDetails
                          category={selCategory}
                          subCategory={selSubCategory}
                          redirectTo={redirectTo}
                          paramsVal={paramsVal}
                          isInitialLoad={isInitialLoad}
                          updateParamsCall={updateParamsCall}
                        />
                      )}
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>

            </>
          )
        }
      </div>
    </div>
  );
};
export default Trends;