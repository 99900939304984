import React, { useState } from 'react'
import BigButton from '../BigButton/BigButton';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

import './SaveToModel.css';

function getModalStyle() {
    return {
        overflow: "scroll"
    };
}

const SaveToModel = ({ modalShow, ...props }) => {


    const onSubmit = () => {
        props.setToggelSaveToModel(false)
    }

    const onClose = () => {
        props.setToggelSaveToModel(false)
    }

    return (
        <Dialog
            open={modalShow}
            style={getModalStyle()}
            maxWidth="xs"
            aria-labelledby="max-width-dialog-title"
            className="tag-model h-auto"
            disableBackdropClick={true}
            fullWidth={true}
        >
            <DialogTitle>
                <Col as="p" className="font-weight-bold px-3 py-1 m-0">Select where you are saving to</Col>
            </DialogTitle>
            <DialogContent className="h-auto">
                <Row>

                </Row>

            </DialogContent>
            <DialogActions className="">
                <BigButton classname="light-gray" onClick={onClose}>CANCEL</BigButton>
                <BigButton classname="btn-orange" onClick={onSubmit}>CONTINUE</BigButton>
            </DialogActions>
        </Dialog>
    )
}

export default SaveToModel
