import React from "react";

import { Button, Col, Row } from "react-bootstrap";
import "./TrendMeasures.css";
const TrendMeasures = (props) => {
  return (
    <>
      {" "}
      {props.measuresArr && (
        <ul className="list-unstyled gss-autocomplete-dd p-3 overflow-auto trendsmeasure">
          <React.Fragment>
            <div className="row">
              <div className="col-8 font-weight-bold mb-3 font-bigger">
                {" "}
                {props.measuresArr?.trendsCategories}
              </div>
              <div className="col-4 d-flex justify-content-end ">
                <p className="closeButton" onClick={props.closeCat}><i
                  class="fa fa-window-close"
                  onClick={props.closeCat}
                  aria-hidden="true"
                />{' '}Close</p>
              </div>
            </div>
            <Row>
              {props.measuresArr?.subCategories.map((el) => (
                <Col sm={2} md={2} lg={4} xl={4}>
                  <ul className="list-unstyled">
                    {" "}
                    <span className="text-muted mb-1 font-medium font-weight-bold">
                      {" "}
                      {el.name}{" "}
                    </span>
                    {el.subTopic.map((item) => (
                      <li
                        className="mb-1 p-0"
                        onClick={() => props.getMeasureInfo(item, el.name)}
                      >
                        <a className="" style={{ fontSize: 13 }}>
                          {" "}
                          {item.cat}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Col>
              ))}
            </Row>
          </React.Fragment>
        </ul>
      )}
    </>
  );
};

export default TrendMeasures;
