import React, { useState, useEffect } from "react";
import BigButton from "../BigButton/BigButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Row, Col, Form } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { shareTag } from "../../../Store/actions/variable";
import { environment } from "../../../environment";

import "./ShareTagModel.css";
import axios from "axios";

function getModalStyle() {
  return {
    overflow: "scroll",
  };
}

const ShareTagModel = ({ modalShow, category, ...props }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [sharedEmails, setSharedEmails] = useState([]);
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (modalShow) {
      let tag_id = category.id;
      axios({
        method: "POST",
        url: environment.getEmailTags,
        data: { tag_id },
      })
        .then((response) => {
          if (response.data) {
            setSharedEmails(response.data.tag_list);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [modalShow]);

  const checkValidation = () => {
    const regEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/;
    let emailArr = email.split(";");
    if (emailArr.length > 0) {
      let output = emailArr.reduce((acc, curr) => {
        if (!regEmail.test(curr.trim())) {
          acc.push(curr.trim());
        }
        return acc;
      }, []);
      if (output.length > 0) {
        setEmailError(`${output.join()} are invalid`);
        return false;
      } else {
        setEmailError("");
        return true;
      }
    } else {
      return false;
    }
  };

  const onSubmit = () => {
    if (email == "") {
      alert("Invalid data");
      return false;
    }

    /* */
    if (checkValidation()) {
      let reqObj = { tag_id: category.id, emailid: email.replace(",", ";") };
      dispatch(shareTag(reqObj))
        .then(() => {
          setEmailError("");
          props.setModalShow(false);
          props.refreshList();
        })
        .catch((error) => {
          let msg;
          if (error.response) {
            msg = error.response.data;
          } else if (error.request) {
            msg = error.request;
          } else {
            msg = error.error_message;
          }
          if (msg.error_message) {
            setEmailError(msg.error_message);
          } else {
            setEmailError("Something went wrong. Try Again!!");
          }
        });
    }
    /* */
  };

  const onClose = () => {
    props.setModalShow(false);
  };

  const handleEmail = (e) => {
    let val = e.target.value;
    setEmail(val);
    setEmailError("");
  };

  return (
    <Dialog
      open={modalShow}
      style={getModalStyle()}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      className="tag-model h-auto"
      disableBackdropClick={true}
      fullWidth={true}
    >
      <DialogTitle>
        <Col as="p" className="font-weight-bold px-3 py-1 m-0">
          Share Tag
        </Col>
      </DialogTitle>
      <DialogContent className="h-auto">
        <Row>
          <Col sm={5} md={5} lg={5}>
            <strong>Share with Email Addresses:</strong>
          </Col>
          <Col
            sm={10}
            md={10}
            lg={10}
            className="p-0 d-flex justify-content-left  flex-grow-2 align-items-center"
          >
            <Form.Control
              className="ml-3 mb-3"
              type="text"
              value={email}
              onInput={
                handleEmail
              } /* onChange={(e) => setEmail(e.target.value)} */
            />
          </Col>
          <Col sm={5} md={5} lg={5}>
            {emailError && <span className="errText">{emailError}</span>}
          </Col>
          <Col
            sm={10}
            md={10}
            lg={10}
            className="p-0 d-flex justify-content-left  flex-grow-2 align-items-center"
          >
            <Col as="p">
              Add semicolon between email addresses to send to multiple people.
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>This tag is shared with:</strong>
            {sharedEmails.map((d, i) => (
              <div key={i}>{d.email}</div>
            ))}
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="">
        <BigButton classname="light-gray" onClick={onClose}>
          CANCEL
        </BigButton>
        <BigButton
          classname="btn-orange"
          onClick={onSubmit}
          disabled={email == ""}
        >
          SEND
        </BigButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShareTagModel;
