import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
/* import CircularProgress from '@material-ui/core/CircularProgress'; */
import LinearProgress from '@material-ui/core/LinearProgress';
import './ExtractPopover.css'
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';
import { environment } from '../../../environment';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExtractCartList from '../../ExtractManagement/ExtractCartList/ExtractCartList';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import RefreshPopoverContext from '../../../Store/ContextApi/RefreshPopoverContext';
import { ClickAwayListener } from "@material-ui/core";
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 5,
    marginTop: 3,
    backgroundColor: '#fffff',

  },
  colorPrimary: {
    backgroundColor: '#fffff'
  },
  bar: {
    borderColor: '#50917E',
    borderRadius: 5,
    backgroundColor: '#50917E',
  },
}))(LinearProgress);



const Accordion = withStyles({
  root: {
    border: '1px solid #50917E',
    color: '#ffffff',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#50917E',
    color: '#ffffff',
    borderBottom: '1px solid #50917E',
    marginBottom: -1,
    fontSize: '8px',
    minHeight: '15px',
    maxHeight: '25px',

  },

  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: '0px',
    backgroundColor: 'white'
  },
}))(MuiAccordionDetails);



/* const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
}); */

export default function ExtractPopover() {
  /* const classes = useStyles(); */
  const [isLoading, setIsLoading] = useState(false);
  const [extracts, setExtracts] = useState({ queue: [], completed: [] });
  const [error, setError] = useState(null);
 /*  const [completedSelect, setCompledteSelect] = useState([]) */
  const [isCloseLoader, setCloseLoader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [chooseVarList, setChooseVarList] = useState([]);

  const { extractReload } = useContext(RefreshPopoverContext);

  useEffect(() => {
    if (expanded) getExtracts();
  }, [expanded]);

  useEffect(() => {
    getExtracts();
  }, [extractReload]);


  const getExtracts = () => {
    axios({
      method: "POST",
      url: environment.getUserExtracts,
    }).then((response) => {
      if (response.data) {
        setExtracts(response.data.final_response ?? []);
        setIsLoading(false);
        setError(null);
      }
    });
  }


  const closeLoader = (val) => {
    setCloseLoader(val);
  }


  const addAllVariables = (cartArr) => {
    setChooseVarList([...cartArr]);
  };

  const removeExtract = (reqPayload) => {
    let extract_id_list = { extract_id_list: reqPayload };
    axios.post(environment.removeExtract, extract_id_list).then((response) => {
      if (response.status) {
        getExtracts();
      }
    });
  };

  const retryExtract = (extract_id) => {
    let extract_id_obj = { retry_flag: 1, extract_id };
    axios.post(environment.createExtract, extract_id_obj).then((response) => {
      if (response.status) {
        getExtracts();
      }
    });
  };
  const handleClickAway = () => {
    setExpanded(false)
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        aria-controls="panel2bh-content"
        id="panel2bh-header"
        className="text-white"
      >

        <div className="h6 acc-title">
          {expanded ? <RemoveIcon /> : <AddIcon />}
          &nbsp;&nbsp;<small>
            Extracts
            {/* Extracts {extracts.queue.length ? "(" + extracts.queue.length + " Loading in Queue)" : ""} */}
          </small>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="tab-popover">
          <div className="tab-section-1">
            {/* <span className="tab-title">“Military” Processing</span>
            <BorderLinearProgress variant="determinate" value={50} />
            <span className="tab-status pull-right"> Status</span> */}


            {extracts.queue?.slice(0, 1).map((queue, index) => (
              <div className="tab-item-grp" key={queue.extract_id}>
                {(queue.status.toLocaleLowerCase()) === "failed" ? (
                  <>
                    <span className="d-inline-block">
                      <span className="tab-item failed">  {queue.extract_name}</span>
                      <span className="tab-popover-err">  {queue.message}</span>
                    </span>

                    <span className="float-right tab-queue-action">
                      <a
                        style={{ textDecoration: "underline" }}
                        onClick={() => retryExtract(queue.extract_id)}
                      >
                        Retry
                      </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        style={{ textDecoration: "underline" }}
                        onClick={() => removeExtract(queue.extract_id)}
                      >
                        Remove
                      </a>
                    </span>
                  </>

                ) : (
                  (queue.status.toLocaleLowerCase() === "submitted" || queue.status.toLocaleLowerCase() === "started") ?
                    <>
                      <span className="tab-title">“{queue.extract_name}” Processing</span>
                      <BorderLinearProgress className="tab-progress-bar" variant="determinate" value={50} />
                      <span className="tab-status pull-right"> Status</span>
                    </>
                    :
                    <span className="tab-item">  {queue.extract_name}</span>

                )}

              </div>


            ))}
          </div>

          <hr />
          <div className="tab-section-2">
            <span className="completed-tabulation"> Completed Extracts ({extracts.completed?.length})</span>
            {/* <input type="checkbox" /> */}
            <NavLink to="/MyGSS?page=extract" className="tab-view-all pull-right">View All </NavLink>
          </div>
          <div className="tab-section-2">
            {
              (extracts.completed?.length > 0) ?
                extracts.completed?.slice(0, 5).map(data => {
                  return (
                    <div className="tab-item-grp d-flex" key={data.extract_id}>

                      <span className="popover-item">
                        <Link className="no-decoration" to={`/extract_summary/${data.extract_id}?back=extract`}>
                          <span className="tab-item">{data.extract_name} </span>
                        </Link>

                        <span className="subitem mt-1">{data.created_at}</span>
                      </span>
                    </div>
                  )
                })
                :
                ""
            }

          </div>
          <hr />
          {expanded ? <ExtractCartList
            isAnalysisOrExtract={true}
            cartListType="chooseVariables"
            // showAddAll="false"
            addAll={addAllVariables}
            showAddAll="true"
            moduleName="Extract"
            closeLoader={closeLoader}
            isCloseLoader={isCloseLoader}
            setExpanded={setExpanded}
            expanded={expanded}
          /> : ""}

          <hr />
        </div>
      </AccordionDetails>
    </Accordion>
</ClickAwayListener>

  );
}
