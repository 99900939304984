import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import { environment } from '../../../environment';
import { Dropdown } from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import './ExportAnalysis.css';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    ExportAnalysis()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const ExportAnalysis = () => {

    let reqBody = {
      "analysisId": 12346,
      "functionId": 79,
      "extractType": "rscript"
    }

    axios
      .post(`${environment.getExportAnalysis}`, reqBody)
      .then((response) => {
        console.log(response)
      })
  }

  const exportExcel = (e) => {
    e.preventDefault();
    props.exportExcel();
  }

  const exportPng = (e) => {
    e.preventDefault();
    props.exportFile();
  }

  const exportPdf = (e) => {
    e.preventDefault();
    props.exportExcel("pdf");
  }

  const exportCSV = (e) => {
    e.preventDefault();
    props.exportExcel("csv");
  }

  return (
    <Dropdown id="export-dropdown">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={{
        backgroundColor: '#999', borderColor: '#999',
        borderRadius: 0, fontWeight: 'bold'
      }} className="font-medium">
        EXPORT
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ minWidth: '6.5rem', maxWidth: '7.5rem',borderRadius: 0 }} >
        {props.isTrends ? (
          <Dropdown.Item href="" onClick={exportExcel} className={props.isTrends ? 'custom-dropdown-menu' : ''}>Data (Excel)</Dropdown.Item>
        ) : (
          <Dropdown.Item ><ReactHTMLTableToExcel
          id="test-table-xls-button"
          table="table-to-xls"
          filename="processed_excel"
          sheet="processed_excel"
          buttonText="Excel"
         />
</Dropdown.Item>
        )
        }
        {props.isTrends ? (
          <Dropdown.Item href="" onClick={exportCSV} className={props.isTrends ? 'custom-dropdown-menu' : ''}>Data (CSV)</Dropdown.Item>
        ) : (
          <Dropdown.Item href="#" onClick={exportCSV}>
            Webpage
            
        </Dropdown.Item>
        )
        }
        {props.isTrends ? (
          <Dropdown.Item href="" onClick={exportPdf} className={props.isTrends ? 'custom-dropdown-menu' : ''}>Chart (PDF)</Dropdown.Item>
        ) : (
          <Dropdown.Item href="#" onClick={exportPng}>
            Webpage
            
           <Tooltip arrow interactive
                      title={<div>Users often use this option to take a screenshot, and then save the image using their preferred file type(*.PNG, *.JPEG, *.PDF, etc.)</div>}
                    >
                      <InfoIcon fontSize="small" style={{"height":'10px'}} className="plus-icon" />
                    </Tooltip>                   
        </Dropdown.Item>
        )
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}
