import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import "../../ExtractManagement/CreateExtract/CreateExtract.css";
import "./CreateAnalysis.css";
import CartList from "../../CartList/CartList";
import Axios from "axios";
import { environment } from "../../../environment";
import {
  Tooltip,
  FormControl,
  NativeSelect,
  Avatar,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import CaseSelection from "../CaseSelection/CaseSelection";
import VarSelectionDnd from "../VarSelectionDnd/VarSelectionDnd";
import BallotYearsSelect from "./BallotYearsSelect";
import {
  ballotYears,
  CheckIfItemExistsInArr,
  GreenCheckbox,
} from "../../../Store/utils/common.utils";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import SearchPage from "../../SearchManagement/SearchPage/SearchPage";
import VariableDetails from "../../VariableManagement/VariableDetails/VariableDetails";
import { DndProvider } from "react-dnd";
import MultiBackend, {
  TouchTransition,
  MouseTransition,
} from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import ClickedVariableContext from "../../../Store/ContextApi/ClickedVariableContext";
import CustomDragLayer from "../../../Components/UI/CustomDragLayer/CustomDragLayer";
import Loader from "../../../Components/UI/Loader/Loader";
import RefreshPopoverContext from "../../../Store/ContextApi/RefreshPopoverContext";
import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
}));
const CreateAnalysis = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    name: "",
    label: "",
    tabulationType: "",
    stdErr: false,
    exclude: false,
    chi: false,
    weight: "",
    setYearInCol: [],
    years: [],
    weightVar: [],
    isSubmitDisabled: false,
  });
  const [yearsData, setYearsData] = useState();
  const HTML5toTouch = {
    backends: [
      {
        id: "touch",
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  /***** START: Accordian Open Close *****/
  const [accordian, setAccordian] = useState({
    accordian1: true,
    accordian2: false,
    enableAccordian2: true,
  });

  const handleNext = (id) => {
    setAccordian({
      ...accordian,
      ["accordian" + (id + 1)]: true,
      ["accordian" + id]: false,
      ["enableAccordian" + (id + 1)]: false,
    });
  };

  const handleAccordian = (accordianName, expanded) => {
    setAccordian({ ...accordian, [accordianName]: !expanded });
  };
  /***** END: Accordian Open Close end *****/

  const [columnVariable, setColumnVariable] = useState([]);
  const [rowVariable, setRowVariable] = useState([]);
  const [selectedYears, setSelectedYears] = useState(""); //For Multi Tabulations
  const [errors, setErrors] = useState({ row: "", col: "" });
  const [toggelView, setToggelView] = useState(true);
  const [variableToggel, setVariableToggel] = useState(true);
  const [variableId, setVariableId] = useState("");
  const contextValue = {
    variableToggel,
    setVariableToggel,
    variableId,
    setVariableId,
  };
  const [isCloseLoader, setCloseLoader] = useState(false);

  const { analysisReloaded, setAnalysisReloaded } = useContext(
    RefreshPopoverContext
  );

  /******* START: Case Selection Request *******/
  const caseSelRef = React.useRef();
  const getCaseListReq = () => {
    return caseSelRef.current.getCaseListReq();
  };
  /******* END: Case Selection Request *******/
  // const weight_val = values.weightVar
  //   .filter((data) => data.name == "wtsscomp")
  //   .map((e) => e.value);
  // setWeightVal(weight_val);
  // console.log(weight_val, "erty");
  const fetchWeightVar_UAT = () => {
    Axios.get(process.env.PUBLIC_URL + "/config/weight_variables_uat.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      let weight_val = response.data
        .filter((data) => data.name == "wtssps")
        .map((e) => e.value);
      setValues({
        ...values,
        ["weightVar"]: response.data,
        weight: weight_val,
      });
    });
  };

  const fetchWeightVar_QA = () => {
    Axios.get(process.env.PUBLIC_URL + "/config/weight_variables_qa.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      let weight_val = response.data
        .filter((data) => data.name == "wtssps")
        .map((e) => e.value);
      setValues({
        ...values,
        ["weightVar"]: response.data,
        weight: weight_val,
      });
    });
  };
  const fetchWeightVar_PROD = () => {
    Axios.get(process.env.PUBLIC_URL + "/config/weight_variables.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      let weight_val = response.data
        .filter((data) => data.name == "wtssps")
        .map((e) => e.value);
      setValues({
        ...values,
        ["weightVar"]: response.data,
        weight: weight_val,
      });
    });
  };

  /******* START: Years data fetch & select years *******/
  useEffect(() => {
    if (
      values.tabulationType === "MultiCrosstab" &&
      values.years.length === 0
    ) {
      //On Tabulation Type Change
      fetchYearsJson();
    }

    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-uat.norc.org/"
    ) {
      fetchWeightVar_UAT();
    }
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-tst.norc.org/"
    ) {
      fetchWeightVar_QA();
    }
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL ==
      "https://gssdataexplorer.norc.org/"
    ) {
      fetchWeightVar_PROD();
    }
  }, [values.tabulationType]);
  useEffect(() => {
    console.log(process.env.REACT_APP_AWS_SIGN_IN_URL, "envva;ie");
  });
  const fetchYearsJson = async () => {
    let yearsArr = await ballotYears.getBallotYears();
    setYearsData(yearsArr);
  };

  const yearUpdate = (yearsList) => {
    setSelectedYears(yearsList);
  };
  /******* END: Years data fetch & select years *******/

  const handleChange = (prop, isCheckbox) => (event) => {
    setValues({
      ...values,
      [prop]: !isCheckbox ? event.target.value : event.target.checked,
    });
  };

  const setYearInColumn = (list) => {
    //Add Default Year for Cross Tabulation
    setColumnVariable(list);
    setValues({
      ...values,
      ["setYearInCol"]: list,
    });
  };

  const handleTabulationType = (tabulationType, name) => {
    setValues({
      ...values,
      ["tabulationType"]: tabulationType,
      ["name"]: name,
      ["stdErr"]: tabulationType === "MultiCrosstab" ? false : values.stdErr,
      ["chi"]: tabulationType === "MultiCrosstab" ? false : values.chi,
    });
    clearErrors();
    if (tabulationType === "MultiCrosstab") {
      setColumnVariable([]);
      //setValues({ ...values, ['stdErr']: false , ['chi']: false});
    } else {
      if (
        columnVariable.length === 0 ||
        (columnVariable.length > 0 && columnVariable.vID !== 1)
      ) {
        setColumnVariable(values.setYearInCol);
      }
      setSelectedYears("");
    }
    if (rowVariable.length) setRowVariable([]);
  };

  const clearErrors = () => {
    setErrors({ ...errors, ["col"]: "", ["row"]: "" });
  };

  const setColRowVariable = (data, varType, errorMsg) => {
    if (errors.row || errors.col) {
      clearErrors();
    }

    if (errorMsg) {
      setErrors({
        ...errors,
        ["col"]: varType === "columnVariable" ? errorMsg : "",
        ["row"]: varType === "rowVariable" ? errorMsg : "",
      });
      return;
    }

    if (varType === "columnVariable") {
      setColumnVariable(data);
    } else if (varType === "rowVariable") {
      setRowVariable(data);
    }
  };

  const toggleColumnTags = (id) => {
    let filterData = columnVariable.map((d) => {
      if (d.vID === id) {
        d.expanded = !d.expanded;
      }
      return d;
    });
    setColumnVariable(filterData);
  };

  const toggleRowTags = (id) => {
    let filterData = rowVariable.map((d) => {
      if (d.vID === id) {
        d.expanded = !d.expanded;
      }
      return d;
    });
    setRowVariable(filterData);
  };

  const submitAnalysis = () => {
    clearErrors();
    let specificYears = [...selectedYears];
    let caseVars = getCaseListReq();

    var req = {
      wrkId: 123,
      analysisName: values.name,
      analysisLabel: values.label,
      analysisType: values.tabulationType,
      colVar: columnVariable.map((obj) => obj.vID),
      rowVar: rowVariable.map((obj) => obj.vID),
      specificYears:
        values.tabulationType === "MultiCrosstab" && specificYears.length
          ? specificYears
          : undefined,
      caseVars: caseVars,
      wghtVar:
        parseInt(values.weight) === 0 ? undefined : parseInt(values.weight),
      stdErr: values.tabulationType === "MultiCrosstab" ? false : values.stdErr,
      exclude: values.exclude,
      chi:
        values.tabulationType === "MultiCrosstab"
          ? false
          : values.stdErr &&
            (CheckIfItemExistsInArr(columnVariable, 1) ||
              CheckIfItemExistsInArr(rowVariable, 1))
          ? false
          : values.chi,
    };

    setValues({ ...values, ["isSubmitDisabled"]: true });
    Axios({
      method: "POST",
      url: environment.createAnalysis,
      data: req,
    })
      .then((response) => {
        props.history.push("/MyGSS?page=tabulation");
        setAnalysisReloaded(!analysisReloaded);
        // if (response.data) {
        // }
      })
      .catch((err) => {
        setValues({ ...values, ["isSubmitDisabled"]: false });
        if (err.response) {
          alert(err.response.data.message);
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          alert("Some Error Occurred");
          // client never received a response, or request never left
        } else {
          alert("Some Error Occurred");
          // anything else
        }
      });
  };

  const closeLoader = (val) => {
    setCloseLoader(val);
  };

  return (
    <>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <CustomDragLayer />
        <ClickedVariableContext.Provider value={contextValue}>
          {isCloseLoader ? "" : <Loader setHeight={true} />}
          <div
            className="col-12 mt-10 analysis"
            style={{
              display:
                toggelView == false || variableToggel == false
                  ? "none"
                  : "block",
            }}
          >
            <div className="row">
              <div className="col-6 gss-large-title">
                Tabulation&nbsp;
                <Tooltip
                  interactive
                  title={
                    <div>
                      <strong>1.Choose Tabulation Type</strong>
                      <ul>
                        <li>
                          Select which type of tabulation to perform, then click
                          Next
                        </li>
                      </ul>
                      <strong>2.Build Tabulation</strong>
                      <ul>
                        <li>
                          <strong>Name</strong>: Name your tabulation
                        </li>
                        <li>
                          <strong> Select Specific Years</strong>: Use the
                          checkboxes to select which survey years to include in
                          your tabulation.
                        </li>
                        <li>
                          <strong>Variables</strong>: Drag variables from the
                          list on the right to the appropriate variable field.
                        </li>
                        <li>
                          <strong>Case selection (optional)</strong>: If you
                          would like to limit your tabulation to a subset of the
                          data, drag one or more variables to the “Case
                          Selection” field and use the checkboxes to choose
                          which values will be included, or enter a range.
                        </li>
                        <li>
                          <strong>Weight</strong>: Allows you to select which
                          weight to use. The default, WTSSCOMP, works in most
                          cases. Please see the{" "}
                          <a href="/gssweighting">Weighting Help</a> page for
                          more detailed guidance on which weights to use to
                          analyze the full range of GSS samples.
                        </li>
                      </ul>
                    </div>
                  }
                >
                  <InfoIcon
                    style={{ fontSize: "13px", marginRight: "6px" }}
                    fontSize="small"
                    className="plus-icon"
                  />
                </Tooltip>
              </div>
              <div className="col-6">{/* <DownloadGSSDataset /> */}</div>
            </div>

            <div className="gss-subtitle">{environment.analysisSubtitle}</div>
            <Accordion
              defaultExpanded={true}
              expanded={accordian.accordian1}
              onChange={() =>
                handleAccordian("accordian1", accordian.accordian1)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <div>
                    {values.tabulationType === "" ? (
                      <Avatar>1</Avatar>
                    ) : (
                      <Avatar className="checked">
                        <CheckOutlinedIcon />
                      </Avatar>
                    )}
                    <span className="accordian-heading">
                      Choose Tabulation Type
                    </span>
                  </div>
                  {values.tabulationType && (
                    <div className="gss-accordian-rightText">
                      <span className="mr-1">
                        {values.tabulationType === "Crosstab"
                          ? "Cross Tabulation"
                          : "Multi-Level Tabulation"}
                      </span>
                    </div>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="w-100">
                  <a
                    className={
                      "option-button " +
                      (values.tabulationType === "Crosstab" ? "selected" : "")
                    }
                    onClick={(e) =>
                      handleTabulationType("Crosstab", "Cross Tabulation")
                    }
                  >
                    <i className="fa fa-table"></i>
                    <span className="">Cross Tabulation</span>
                  </a>
                  <a
                    className={
                      "option-button " +
                      (values.tabulationType === "MultiCrosstab"
                        ? "selected"
                        : "")
                    }
                    onClick={(e) =>
                      handleTabulationType(
                        "MultiCrosstab",
                        "Multi-Level Tabulation"
                      )
                    }
                  >
                    <i className="fa fa-bars"></i>
                    <span className="">Multi-Level Tabulation</span>
                  </a>
                  <div>
                    <button
                      disabled={values.tabulationType === ""}
                      className="gss-btn btn-orange mt-1"
                      onClick={() => handleNext(1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disabled={accordian.enableAccordian2}
              expanded={accordian.accordian2}
              onChange={() =>
                handleAccordian("accordian2", accordian.accordian2)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <div>
                    {values.name === "" ||
                    values.name.trim() === "" ||
                    columnVariable.length === 0 ||
                    rowVariable.length === 0 ? (
                      <Avatar>2</Avatar>
                    ) : (
                      <Avatar className="checked">
                        <CheckOutlinedIcon />
                      </Avatar>
                    )}
                    <span className="accordian-heading">Build Tabulation</span>
                  </div>
                </Typography>
              </AccordionSummary>
              {/* <p
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  border: "1px solid rgba(0, 0, 0, .125)",
                  padding: "10px",
                  margin: "0 15px 10px",
                }}
              >
                <abbr>Note: </abbr> Crosstabulations in the GSS Data Explorer
                that are constructed using continuous variables that are mostly
                or entirely unlabeled (e.g. those with numeric values, such as
                AGE, RANK, and WORDSUM) are not working correctly. Labels are
                being assigned alphabetically rather than based on values, with
                label-less values placed before labels with values (e.g. 2
                before .d “Don’t know, or 89 “89 or older” after .i
                “inapplicable”). Do not use such crosstabs from the tabulation
                tool without looking at the associate variable page to gauge the
                extent of this error on your findings. We expect this issue to
                be resolved by 9/13/2022.
              </p> */}
              <AccordionDetails>
                <div className="row w-100 m-0">
                  <div className="col-12 mb-2">
                    <TextField
                      id="outlined-basic"
                      inputProps={{ maxLength: 35 }}
                      label="Name"
                      required
                      variant="outlined"
                      className="w-40 d-block"
                      value={values.name}
                      onChange={handleChange("name")}
                    />

                    <TextField
                      id="outlined-basic"
                      label="Label"
                      inputProps={{ maxLength: 35 }}
                      variant="outlined"
                      className="w-40 d-block mt-3"
                      value={values.label}
                      onChange={handleChange("label")}
                    />
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12">
                    {/* MultiCrosstab years section start */}
                    {values.tabulationType === "MultiCrosstab" && (
                      <div className="mb-2">
                        <div className="label-with-border">
                          <label className="mr-2">Select Specific Years</label>
                          <Tooltip
                            interactive
                            arrow
                            title={environment.multiCrossTabYears}
                          >
                            <span>
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="w-100 gss-analysis-years">
                          <BallotYearsSelect
                            yearUpdate={yearUpdate}
                            data={yearsData}
                          />
                        </div>
                        <div className="mt-2 gss-sharp">
                          {environment.colVarLimit}
                        </div>
                      </div>
                    )}
                    {/* MultiCrosstab years section end */}
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 w-50">
                    {/* Column Variable Section start */}

                    <div className="position-relative">
                      <label>
                        {"Column Variable" +
                          (values.tabulationType === "MultiCrosstab"
                            ? "s"
                            : "")}
                      </label>
                      {errors.col != "" && (
                        <span className="error-msg ml-2">{errors.col}</span>
                      )}

                      {/* <i className="fa fa-question-circle"></i> */}
                      {values.tabulationType === "Crosstab" && (
                        <Tooltip
                          arrow
                          interactive
                          title={environment.yearSelected}
                        >
                          <span>
                            <i className="fa fa-question-circle ml-2"></i>
                          </span>
                        </Tooltip>
                      )}
                    </div>

                    <VarSelectionDnd
                      variableList={columnVariable}
                      variableType="columnVariable"
                      maxDropCount={
                        values.tabulationType === "Crosstab" ? 1 : 5
                      }
                      onItemAction={setColRowVariable}
                      rowVariable={rowVariable}
                      toggleTags={toggleColumnTags}
                      module="analysis"
                    ></VarSelectionDnd>
                    <div className="mt-3 position-relative">
                      <label>Row Variable</label>
                      {errors.row != "" && (
                        <span className="error-msg ml-2">{errors.row}</span>
                      )}
                    </div>
                    <VarSelectionDnd
                      variableList={rowVariable}
                      variableType="rowVariable"
                      maxDropCount={1}
                      onItemAction={setColRowVariable}
                      columnVariable={columnVariable}
                      toggleTags={toggleRowTags}
                      module="analysis"
                    ></VarSelectionDnd>
                    <CaseSelection ref={caseSelRef}></CaseSelection>

                    <div className="mt-3">
                      <label className="mr-2">
                        Weight {values.stdErr && <abbr>*</abbr>}
                      </label>
                      <Tooltip
                        arrow
                        interactive
                        title={
                          <div className="gss-tooltip">
                            <span>{environment.weightTextLine1}</span>
                            <a
                              className="font-orange mx-1 font-weight-bold"
                              href="/gssweighting"
                            >
                              Weighting Help
                            </a>
                            <span>{environment.weightTextLine2}</span>
                          </div>
                        }
                      >
                        <span>
                          <i className="fa fa-question-circle"></i>
                        </span>
                      </Tooltip>
                    </div>
                    <div className="w-25 gss-select with-border">
                      <FormControl>
                        <Select
                          value={values.weight}
                          onChange={handleChange("weight")}
                          name="weight"
                          style={{
                            background: "white",
                            padding: "5px",
                            fontSize: "15px",
                          }}
                        >
                          {values.weightVar.map((data) => (
                            <MenuItem value={data.value}>{data.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="my-3 gss-cbox-group">
                      {values.tabulationType == "Crosstab" && (
                        <div>
                          <FormControlLabel
                            control={
                              <GreenCheckbox
                                /* checked={data} */
                                value={
                                  "Generate design-corrected standard errors"
                                }
                                onChange={handleChange("stdErr", "checkbox")}
                              />
                            }
                            label={"Generate design-corrected standard errors"}
                            style={{ cursor: "pointer" }}
                          />
                          <Tooltip
                            arrow
                            interactive
                            title={
                              <div className="gss-tooltip">
                                <span>{environment.weightGenerate}</span>
                                <a className="font-orange mx-1 font-weight-bold">
                                  {environment.weightGenerateAnchor}
                                </a>
                              </div>
                            }
                          >
                            <span>
                              <i className="fa fa-question-circle"></i>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                      <div>
                        {/* <label className="mr-2">
                      <input
                        type="checkbox"
                        onChange={handleChange("exclude", "checkbox")}
                      />{" "}
                      Exclude missing values
                    </label> */}
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              /* checked={data} */
                              value={"Exclude missing values"}
                              onChange={handleChange("exclude", "checkbox")}
                            />
                          }
                          label={"Exclude missing values"}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      {values.tabulationType == "Crosstab" &&
                        !(
                          values.stdErr &&
                          (CheckIfItemExistsInArr(columnVariable, 1) ||
                            CheckIfItemExistsInArr(rowVariable, 1))
                        ) && (
                          <div>
                            {/* <label className="mr-2">
                        <input
                          type="checkbox"
                          onChange={handleChange("chi", "checkbox")}
                        />{" "}
                        Chi-Squared
                      </label> */}
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  /* checked={data} */
                                  //disabled={(values.stdErr && (CheckIfItemExistsInArr(columnVariable, 1) || CheckIfItemExistsInArr(rowVariable, 1)))}
                                  // (columnVariable.some((item)=>item.vID === 1) || columnVariable.some((item)=>item.vID === 1)
                                  value={"Chi-Squared"}
                                  onChange={handleChange("chi", "checkbox")}
                                />
                              }
                              label={"Chi-Squared"}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-6 col-sm-6 w-50 pr-0">
                    <CartList
                      // variablesList={variablesList}
                      isAnalysisOrExtract={true}
                      setToggelView={setToggelView}
                      toggelView={toggelView}
                      cartListType="columnVariable"
                      showAddAll="false"
                      moduleName="Variables"
                      setYearInColumn={setYearInColumn}
                      tabulationType={values.tabulationType}
                      closeLoader={closeLoader}
                      isCloseLoader={isCloseLoader}
                    ></CartList>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="footer border-top p-2">
              <button
                className="gss-btn btn-orange mt-1"
                disabled={
                  values.tabulationType === "" ||
                  values.name === "" ||
                  values.name.trim() === "" ||
                  columnVariable.length === 0 ||
                  rowVariable.length === 0 ||
                  values.isSubmitDisabled ||
                  (values.stdErr && parseInt(values.weight) === 0)
                }
                onClick={submitAnalysis}
              >
                CREATE TABULATION
              </button>

              {/* <button className="gss-btn btn-orange mt-1" onClick={getCaseListReq}>
        check Rew
      </button> */}
              <a
                className="cancel-text"
                onClick={() => {
                  props.history.push("/MyGSS?page=tabulation");
                }}
              >
                CANCEL
              </a>
            </div>
          </div>

          {toggelView ? (
            ""
          ) : (
            <SearchPage
              isAnalysisOrExtract={true}
              setToggelView={setToggelView}
              moduleName="analysis"
            />
          )}

          {variableToggel ? (
            ""
          ) : (
            <VariableDetails
              isAnalysisOrExtract={true}
              variableId={variableId}
              setVariableToggel={setVariableToggel}
              moduleName="analysis"
            />
          )}
        </ClickedVariableContext.Provider>
      </DndProvider>
    </>
  );
};
export default CreateAnalysis;
