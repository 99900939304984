import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { updateService } from "../../../Shared/UpdateCart";
import ReportsContext from "../../../Store/ContextApi/ReportsContext";
import ReportsTabs from "./ReportsTab";
// import ReportsYearDropdown from "./ReportsYearDropdown";
const ReportsYearsRange = (props) => {
  const handleRangeChange = (type) => (e) => {
    const regexp = /^[0-9\b]+$/;
    let arr = [...props.yearRange];
    if (type == "min") {
      arr[0] =
        e.target.value !== "" && regexp.test(e.target.value)
          ? parseInt(e.target.value)
          : "";
    } else {
      arr[1] =
        e.target.value !== "" && regexp.test(e.target.value)
          ? parseInt(e.target.value)
          : "";
    }
    props.setYearRange(arr);
  };

  return (
    <>
      <span className="mr-2 font-weight-bold">Years:</span>
      <span className="mr-2">
        <input
          type="text"
          style={{ width: 50, textAlign: "center", height: 24 }}
          value={props.yearRange[0]}
          onInput={handleRangeChange("min")}
        />
      </span>
      <span className="mr-2 font-weight-bold">to</span>
      <span className="mr-2">
        <input
          type="text"
          style={{ width: 50, textAlign: "center", height: 24 }}
          value={props.yearRange[1]}
          onInput={handleRangeChange("max")}
        />
      </span>
    </>
  );
};

export default ReportsYearsRange;
