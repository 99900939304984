import React, { useState, useEffect, useRef } from "react";
import "./ForgotPassword.css";
import { Auth } from "aws-amplify";
import axios from "axios";
import { environment } from "../../../environment";
import { NavLink } from "react-router-dom";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import { Form } from "react-bootstrap";
import validation from "../../../Components/UI/Validations/Validation";
import { checkIsValidEmail } from "../../../Shared/helpers";

const ForgotPassword = (props) => {
  const firstRender = useRef(true);
  const [disable, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [userState, setUserState] = useState({
    username: "",
    emailAddress: "",
    isConfirmCodeScreen: false,
    code: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLength, setIsLength] = useState(false);
  const [isChar, setIsChar] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const checkDisabled = () => {
    if (!(emailError == "")) return false;

    return true;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setDisabled(!checkDisabled());
  }, [emailError]);

  const handleChange = async (e) => {
    setEmail(e.target.value);
    let emailError = await checkIsValidEmail(e.target.name, e.target.value);
    setEmailError(emailError.errorMessage);
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!userState.isConfirmCodeScreen) {
      if (!userState["emailAddress"]) {
        formIsValid = false;
        errors["emailAddress"] = "Email can't be blank.";
      }

      if (userState["emailAddress"]) {
        formIsValid = validation(
          "email",
          userState["emailAddress"]
        ).errorStatus;

        if (!formIsValid) {
          errors["emailAddress"] = "Email is invalid";
        }
      }
    } else {
      if (!userState["code"]) {
        formIsValid = false;
        errors["code"] = "Code can't be blank.";
      }
      if (!userState["newPassword"]) {
        formIsValid = false;
        errors["newPassword"] = "Password can't be blank.";
      }
      if (userState["newPassword"]) {
        formIsValid = validation(
          "password",
          userState["newPassword"]
        ).errorStatus;

        if (!formIsValid) {
          errors["newPassword"] = "Password is invalid";
        }
      }

      if (!userState["confirmPassword"]) {
        formIsValid = false;
        errors["confirmPassword"] = "Confirm Password can't be blank.";
      }
      if (
        typeof userState["newPassword"] !== "undefined" &&
        typeof userState["confirmPassword"] !== "undefined"
      ) {
        if (userState["newPassword"] !== userState["confirmPassword"]) {
          formIsValid = false;
          errors["confirmPassword"] = "Passwords do not match.";
        }
      }
    }

    setErrors(errors);

    return formIsValid;
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setDisabled(true);
    isValidUser((isValid, status, username, email) => {
      if (isValid && status == "CONFIRMED") {
        Auth.forgotPassword(username)
          .then((data) => {
            setIsSaving(false);
            setDisabled(true);
            setEmail("");
            setEmailError(null);
            setUserState({
              ...userState,
              username: username,
              emailAddress: email,
              isConfirmCodeScreen: true,
            });
          })
          .catch((err) => {
            setIsSaving(false);

            if (err.response) {
              setEmailError(err.response.data.message);
              // client received an error response (5xx, 4xx)
            } else if (err.request) {
              // client never received a response, or request never left
            } else {
              setEmailError(err.message);
              // anything else
            }
          });
      } else if (status == "EXTERNAL_PROVIDER") {
        setIsSaving(false);
        setEmailError("User last logged in either with Facebook or Google");
      } else {
        setIsSaving(false);
        setEmailError("Please confirm your account");
      }
    });
  };

  const clickResetPassword = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    Auth.forgotPasswordSubmit(
      userState.username,
      userState.code,
      userState.newPassword
    )
      .then((data) => {
        alert("Password Changed Successfully");
        props.history.push("/sign_in");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setUserState({
      ...userState,
      [name]: value,
    });
  };

  const handlePassInputChange = ({ target: { name, value } }) => {
    validateTestCases(value);
    setUserState({
      ...userState,
      [name]: value,
    });
  };

  const isValidUser = (forgotPasswordCallback) => {
    axios({
      method: "GET",
      url: environment.getUserByEmail,
      params: {
        email,
      },
    })
      .then((response) => {
        response.data.email = email;
        forgotPasswordCallback(
          response.data.exists,
          response.data.user_status,
          response.data.username,
          response.data.email
        );
      })
      .catch((err) => {
        setIsSaving(false);
        setDisabled(false);
        if (err.response) {
          setEmailError(err.response.data.message);
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };

  const validateTestCases = (value) => {
    setIsLength(!!(value.toString().length >= 8));
    let isValid = value ? validation("password", value).errorStatus : false;
    setIsChar(!!isValid);
  };

  if (!userState.isConfirmCodeScreen) {
    return (
      <div>
        <span className="standard-text create-account-container">
          <NavLink className="create-account-text" to="/user/sign_up">
            Don't have an account? Create one here
          </NavLink>
        </span>
        <FormCard title="Forgot your password?">
          <form
            className="simple_form form-horizontal"
            name="forgetPasswordForm"
            onSubmit={handleForgotPassword}
            noValidate
          >
            <div className="row justify-content-md-center customize-username-height">
              <div className="col-auto customize-label">
                <label htmlFor="email" className="col-form-label">
                  Email Address
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control customize-input"
                  aria-describedby="email"
                  value={email}
                  onChange={handleChange}
                  autoComplete="off"
                  autoFocus
                  disabled={isSaving}
                />
                {emailError ? <div className="errorMsg">{emailError}</div> : ""}
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-auto">
                {isSaving ? (
                  <button type="button" className="gss-btn btn-orange mt-2">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    &nbsp; SENDING...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="gss-btn btn-orange mt-2"
                    variant="secondary"
                    disabled={disable}
                  >
                    SEND RESET INSTRUCTIONS
                  </button>
                )}
              </div>
            </div>
          </form>
        </FormCard>
        <span>
          <NavLink
            className="confirmation-instruction"
            to="/users/confirmation"
            exact
          >
            Didn't receive confirmation instructions?
          </NavLink>
        </span>
      </div>
    );
  } else {
    return (
      <>
        <div>
          <FormCard title="Change Your Password">
            <form
              className="simple_form form-horizontal"
              name="changePasswordForm"
              onSubmit={clickResetPassword}
              noValidate
            >
              <div className="row justify-content-md-center customize-username-height">
                <div className="col-md-4 customize-label">
                  <label htmlFor="code" className="col-form-label">
                    Code
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    name="code"
                    id="code"
                    className="form-control customize-input"
                    aria-describedby="code"
                    value={userState.code}
                    onChange={handleInputChange}
                    autoComplete="off"
                    autoFocus
                  />
                  {errors.code ? (
                    <div className="errorMsg">{errors.code}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-md-center customize-username-height">
                <div className="col-md-4 customize-label">
                  <label htmlFor="newPassword" className="col-form-label">
                    New Password
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    className="form-control customize-input"
                    aria-describedby="newPassword"
                    value={userState.newPassword}
                    onChange={handlePassInputChange}
                    autoComplete="off"
                  />
                  {errors.newPassword ? (
                    <div className="errorMsg">{errors.newPassword}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-md-4 customize-label"></div>
                <div className="col-md-8">
                  <ul className="list-unstyled pass-details">
                    <li>Must Have: </li>
                    <li>
                      <Form.Check
                        inline
                        name="group1"
                        checked={isLength}
                        type={"radio"}
                      />
                      At least 8 characters
                    </li>
                    <li>
                      <Form.Check
                        inline
                        name="group2"
                        checked={isChar}
                        type={"radio"}
                      />
                      Must include
                      <ul>
                        <li>an upper case letter</li>
                        <li>a lower case letter</li>
                        <li>a number</li>
                        <li>a special character </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-md-center customize-username-height">
                <div className="col-md-4 customize-label">
                  <label htmlFor="confirmPassword" className="col-form-label">
                    Confirm Password
                  </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="form-control customize-input"
                    aria-describedby="confirmPassword"
                    value={userState.confirmPassword}
                    onChange={handleInputChange}
                    autoComplete="off"
                  />
                  {errors.confirmPassword ? (
                    <div className="errorMsg">{errors.confirmPassword}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-auto">
                  <button
                    type="submit"
                    className="gss-btn btn-orange mt-2"
                    variant="secondary" /* disabled={disable} */
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </form>
          </FormCard>
          <span>
            <NavLink
              className="confirmation-instruction"
              to="/users/confirmation"
              exact
            >
              Didn't receive confirmation instructions?
            </NavLink>
          </span>
        </div>
      </>
    );
  }
};

export default ForgotPassword;
