import React, { Component, useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
export default function ReportsBallotInfo(props) {
  const [years, setYears] = useState(props.years);
  const [yearsRange, setYearsRange] = useState(props.yearsRange);
  useEffect(() => { 
  }, []);
    return (
    <>
      {props?.years.map((yearInfo, index) => {
        if (props.isRangeSelected == undefined ||
          props.isRangeSelected && props.yearsRange == undefined ||
          !props.isRangeSelected && props.selectedYears == undefined ||
          (props.isRangeSelected && props.yearsRange && yearInfo.year >= props.yearsRange[0] && yearInfo.year <= props.yearsRange[1]) || // For year Range
          (!props.isRangeSelected && props.selectedYears && props.selectedYears.indexOf(yearInfo.year) > -1)) { //Select Specific year
          return (<Tooltip
            title={
              <div>
                <div className="text-center">{yearInfo}</div>
                {/* {yearInfo.map((info) => (
                  <div>
                    <span>Ballot {info}</span>
                  </div>
                ))} */}
                {/* {yearInfo.length === 0 &&
                  yearInfo.isQuestionAvailable != "unavailable" && (
                    <div>No ballot Information.</div>
                  )} */}
              </div>
            }
          >

            <div className="ballot-strips-parent">
              <div
                className={
                  "ballot-strips" + " bg-green " + "ballot-strips-small"
                }
              ></div>
            </div>
          </Tooltip>)
        }
      })}
    </>
  );

}
