const initialState = {    
    usersearches_data:{}
   };
   const userSearchesReducer = (state = initialState, action) => {
   switch (action.type) {
       case 'SET_USERSEARCH': {            
           const usersearches_data = action.payload;
                   return {
                       ...state,
                       usersearches_data
                   }
       }
       default:
           return state;
   }
   };
   
   export default userSearchesReducer;