import { Checkbox, withStyles } from "@material-ui/core";
/* import { Auth } from "aws-amplify"; */
import axios from "axios";
import moment from "moment";
import { environment } from "../../environment";

const { Dropbox } = window;
export const ballotYears = {
  years: [],
  getBallotYears: async () => {
    await axios
      .get(process.env.PUBLIC_URL + "/config/year_range.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      })
      .then((response) => {
        ballotYears.years = Object.keys(response.data.year_available);
      });
    return ballotYears.years;
  },
};

export const dateTimeFormat = (str) => {
  return moment.utc(str).local().format("MM/DD/YYYY hh:mm:ss A");
};

export const dateFormat = (str) => {
  return moment.utc(str).local().format("MM/DD/YYYY");
};

export const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#73a492",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const SignOut = async () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location = "/sign_in";
};

export const ItemTypes = {
  CARD: "card",
};

export const CheckIfItemExistsInArr = (arr, valueToCompare) => {
  return arr.some((rowItem) => rowItem.vID === valueToCompare);
};

export const GetDeepCopy = (val) => {
  return JSON.parse(JSON.stringify(val));
};

export const encodeUri = (str) => {
  str = encodeURIComponent(str);
  if (str.indexOf("%2C") > -1) {
    str = str.split("%2C");
    str = str.join("%5C%2C");
  }
  return str;
};

export const ExportDropbox = (url) => {
  var options = {
    files: [
      // You can specify up to 100 files.
      { url: url, filename: "" },
    ],

    // Success is called once all files have been successfully added to the user's
    // Dropbox, although they may not have synced to the user's devices yet.
    success: function () {
      // Indicate to the user that the files have been saved.
      alert("Success! Files saved to your Dropbox.");
    },

    // Progress is called periodically to update the application on the progress
    // of the user's downloads. The value passed to this callback is a float
    // between 0 and 1. The progress callback is guaranteed to be called at least
    // once with the value 1.
    progress: function (progress) {
      console.log("progress");
    },

    // Cancel is called if the user presses the Cancel button or closes the Saver.
    cancel: function () {
      console.log("Cancelled");
    },

    // Error is called in the event of an unexpected response from the server
    // hosting the files, such as not being able to find a file. This callback is
    // also called if there is an error on Dropbox or if the user is over quota.
    error: function (errorMessage) {
      alert("Some Error Occurred.");
    },
  };
  Dropbox.save(options);
};

export const DownloadMediaDoc = (docId) => {
  let reqObj = {
    document_id: docId,
  };
  axios
    .post(`${environment.downloadMediaDoc}`, reqObj)
    .then((response) => {
      window.open(response.data.document_presigned_url);
    })
    .catch((error) => {
      alert("Some Error Occured");
    });
};
