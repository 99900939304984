import React from 'react'
import { Card } from 'react-bootstrap';
import './HelpCard.css'

const HelpCard = ({ item }) => {
    let { title, details, image } = item;

    return (
        <Card className='my-3 border-0'>
            <Card.Img src={image} variant='top' />
            <Card.Body className="p-0 pt-2">
                <Card.Title as='h4'>
                    {title}
                </Card.Title>
                <Card.Text as='p'>{details}</Card.Text>
            </Card.Body>
        </Card>
    )
}


export default HelpCard
