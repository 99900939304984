import React from 'react';
import gssLogo from '../../../Images/gss.png';
import norcLogo from '../../../Images/NORC.png';
import './AnalysisSummaryTemplate.css';
import { dateFormat } from "../../../Store/utils/common.utils";
import {Container, Table} from 'react-bootstrap';
import norc from '../../../Images/NORC.png';

export class AnalysisSummaryTemplate extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="container" style={{ backgroundColor: 'white' }}>
                <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', padding: "0 2%" }}>
                    <img src={gssLogo} style={{ height: 40 }} alt=""/>
                    <img src={norc} style={{ height: 40 }} alt=""/>
                </div>
                {this.props.isTrends ? (
                    <div className="row mt-2">
                        <div className="col">
                            <p className="m-0 param"><b>{this.props.selectedCategory}:</b><span>{this.props.selectedMeasure}</span></p>
                            <p className="m-0 param"><b>Response:</b><span>{this.props.dropdownvalue}</span></p>
                            <p className="m-0 param"><b>Breakdown:</b><span>{this.props.breakdropdownvalue}</span></p>
                        </div>
                    </div>
                ) : (
                    <div className="noscroll analysis" id="analysisSummary">
                        <div className="gss-center-analysis index ">
                            {this.props.analysisSummaryDetails}
                        </div>
                    </div>
                )}
                {(this.props.isTrends) ? (
                    (!this.props.toggleTable) ?
                        <div className="row">
                            <div className="col">
                                <img src={this.props.url} alt=""/>
                            </div>
                        </div> : (<>{this.props.analysisSummaryTbl} </>)
                ) : (<>{this.props.analysisSummaryTbl} </>)}

                {(this.props.isTrends) ? (
                    <Container className="mt-5">    
                        <Table>
                            {this.props.variableDetails} 
                        </Table>                       
                    </Container>
                ) : ''}

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="row">
                        <div className="col">
                            <p style={{ fontWeight: 'bold' }}>Citation</p>
                            <p>
                            Davern, Michael; Bautista, Rene; Freese, Jeremy; Herd, Pamela; and Morgan, Stephen L.; General Social Survey 1972-2024. [Machine-readable data file]. Principal Investigator, Michael Davern; Co-Principal Investigators, Rene Bautista, Jeremy Freese, Pamela Herd, and Stephen L. Morgan. Sponsored by National Science Foundation. NORC ed. Chicago: NORC, 2024: NORC at the University of Chicago [producer and distributor]. Data accessed from the GSS Data Explorer website at gssdataexplorer.norc.org.
                            This GSS Data Explorer output was created{ this.props.isLoggedIn ? (<> by {this.props.creatorName} </>): ''
                              } on {dateFormat(this.props.createdAt)}.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}








