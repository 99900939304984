import Axios from "axios";
import React, { useState, useEffect } from "react";
import { environment } from "../../../environment";
import ProjectTile from "../ProjectTile/ProjectTile";
import Delete from "../Delete/Delete";
import { NavLink, useHistory } from "react-router-dom";
import "./Projects.css";
import SharedProject from "../SharedProject/SharedProject";
import { updateService } from '../../../Shared/UpdateCart';
import { useDispatch } from "react-redux";
import large_boxes from "../../../Images/big_boxes.jpg"
import Loader from "../../../Components/UI/Loader/Loader";

const Projects = (props) => {

  const [localState, setLocalState] = useState({
    workspaceDetail: {},
    currentProject: ''
  })
  let history = useHistory();
  const dispatch = useDispatch();
  const [isCloseLoader, setCloseLoader] = useState(false);


  useEffect(() => {
    getProjectList();
    updateService.sendAlert('');

  }, [])


  const getProjectList = () => {
    setCloseLoader(false);
    let reqBody = {
      user_id: ""//localStorage.userId,
    };
    console.log('reqBody==>',reqBody);
    Axios.post(`${environment.projectListURL}`, reqBody)
      .then((resp) => {
        
        console.log(resp, resp.data.response);
        //   this.setState({ password: "", passwordConfirmation: "" });
        localStorage.defaultProjectNo = resp.data.curProj ? resp.data.curProj : '';
        try {
          localStorage.defaultProjectName = resp.data.curProj ? resp.data.response.result.find(x => x.workspace_id == resp.data.curProj).workspace_name : '';
        } catch (err) {

        }
        setCloseLoader(true);

        setLocalState({
          ...localState,
          workspaceDetail: resp.data.response.result
        });
        dispatch({
          type: "PROJECT_DATA",
          payload: localState.workspaceDetail,
        });
        history.push("/MyGSS");
      })
      .catch(err => {
        setCloseLoader(true);
        if (err.response) {
          if (err.response.status == 401) {
           // alert(err.response.data.message);
            history.push("/error");
            return;
          } else {
            alert(err.response.data.message);
          }
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  }

  const refreshProjectList = () => {
    this.getProjectList();
  }

  return (
    <div>
      {isCloseLoader ? '' : (<Loader setHeight={true} />)}
      <div className="noscroll project-index invisible" id="ws-content">
        <div className="gss-section">
          <div className="sidebar pull-right">
            <div className="content">
              <div className="sidebar-box">
                <h4>Search, Extract, and Analyze GSS Data</h4>
                <p>
                  As you explore variables and analyze and extract data your
                  work will be saved to the project you last visited, or if
                  you haven't visited any, to the default project that was
                  created with your account.
                  </p>
                <a
                  className="btn btn-orange sidebar-button"
                  href="/variables/vfilter"
                >
                  <div className="fa fa-search"></div>
                    SEARCH VARIABLES
                  </a>
              </div>
              <div className="sidebar-box">
                <p>
                  The GSS 1972-2018 cumulative data file is updated to R3.
                  Click here to view the revision history and how variables
                  may have been impacted.
                  </p>
                <a
                  className="btn btn-orange sidebar-button"
                  href="/pages/show?page=gss%2Frevision_history"
                >
                  REVISION HISTORY
                  </a>
              </div>
              <div className="sidebar-box">
                <h4>Tips to Get the Most Out of Your Project</h4>
                <ul className="fa-ul clearfix">
                  <li>
                    <span className="heading">
                      <div className="circle">
                        <span>1</span>
                      </div>
                        Get started
                      </span>
                    <p className="pal">
                      Create a project here or create one as you add variables
                      to your cart
                      </p>
                  </li>
                  <li>
                    <span className="heading">
                      <div className="circle">
                        <span>2</span>
                      </div>
                        Save time:
                      </span>
                    <p className="pal">
                      You can move or copy extracts to other projects
                      </p>
                  </li>
                  <li>
                    <span className="heading">
                      <div className="circle">
                        <span>3</span>
                      </div>
                        Change settings
                      </span>
                    <p className="pal">At any time you can:</p>
                    <ul className="pal no-pad" style={{ color: "white" }}>
                      <li>Update privacy settings</li>
                      <li>Invite more people to a shared project</li>
                      <li>
                        Add or remove default file formats for data extracts
                        </li>
                    </ul>
                    <p></p>
                  </li>
                </ul>
              </div>
              <div className="sidebar-box" style={{ border: 0 }}>
                <h4>Project Help</h4>
                <p>
                  Find answers to common questions about using project
                  workspaces on our help page.
                  </p>
                <a
                  className="btn btn-orange sidebar-button"
                  href="/pages/show?page=gss%2Fhelp_projects"
                >
                  VIEW HELP
                  </a>
              </div>
            </div>
          </div>
          <div className="gss-center projects pull-left">
            <div
              className="gss-large-title has-projects"
              style={{ color: "#f3901d" }}
            >
              <img src={large_boxes} />
                MyGSS
                <NavLink
                className="btn btn-orange pull-right new_project create_project"
                to="/projectCreate"
              >
                + CREATE A PROJECT
                </NavLink>
            </div>

            {Object.keys(localState.workspaceDetail).map((item, i) => {
              return (
                <ProjectTile
                  {...localState.workspaceDetail[item]}
                  refreshProjectList={refreshProjectList}
                />
              );
            })}
          </div>
          <div className="clearfix" style={{ marginTop: "10px" }}></div>
        </div>
      </div>
    </div>
  );

}

export default Projects;

