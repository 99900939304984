import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { environment } from "../../environment";
import { NavLink } from "react-router-dom";
import gss from "../../Images/gss.png";
/* import { Button, Menu, MenuItem } from "@material-ui/core"; */
import MenuDropdown from "../UI/MenuDropdown/MenuDropdown";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import "./Message.css";

const Header = (props) => {
  const authDetails = useSelector((state) => state.authReducer);

  const [message, setMessage] = useState();
  const [expiryTime, setExpiryTime] = useState();
  const [startTime, setStartTime] = useState();
  const [messageG, setMessageG] = useState();
  const [expiryTimeG, setExpiryTimeG] = useState();
  const [startTimeG, setStartTimeG] = useState();

  useEffect(() => {
    if (
      localStorage.getItem("isSignedIn") == "true" ||
      authDetails.isSignedIn ||
      authDetails.isSocialLogin
    ) {
      getMessage();
    } else getMessageguest();
  }, []);

  const getMessage = () => {
    axios
      .get(`${environment.showMessage}`)
      .then((resp) => {
        let data1 = resp.data.body.message;
        let expiryTime = resp.data.body.expiry;
        let startTime = resp.data.body.start;
        setStartTime(startTime);
        setExpiryTime(expiryTime);
        setMessage(data1);
        console.log(data1, "mddd");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMessageguest = () => {
    axios
      .get(`${environment.showMessageguest}`)
      .then((resp) => {
        let data1G = resp.data.body.message;
        let expiryTimeG = resp.data.body.expiry;
        let startTimeG = resp.data.body.start;
        setStartTimeG(startTimeG);
        setExpiryTimeG(expiryTimeG);
        setMessageG(data1G);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var today = new Date();
  var startFormat = today.toISOString() > startTime;
  var endFormat = today.toISOString() < expiryTime;
  console.log(startFormat, "start");
  console.log(endFormat, "end");

  var startFormatG = today.toISOString() > startTimeG;
  var endFormatG = today.toISOString() < expiryTimeG;
  console.log(startFormatG, "start");
  console.log(endFormatG, "end");

  /* let [value, setValue] = useState(localStorage.getItem("username")); */
  /* let [anchorEl, setAnchorEl] = useState(null); */

  /* var handleChange = ({ target: { value } }) => {
    setValue(value);
  } */

  /* const handleSubmit = (event) => {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  } */

  /* const clickLogout = () => {
    try {
      Auth.signOut({ global: true }).then((data) => {
        localStorage.clear();
        props.history.push("/");
        window.location.reload();
      });
    } catch (error) {
      

    }

    // Collect confirmation code and new password, then
  }; */

  /* const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }; */

  // componentWillUnmount() {
  //   // unsubscribe to ensure no memory leaks
  //   // this.subscription.unsubscribe();
  // }
  return (
    <div>
      <div class="marquee">
        <div class="marqueeContentContainer">
          <p className="m-0 p-2">
            {endFormat && startFormat && (
              <>
                {message != "" && <i className="fa fa-solid fa-bell"></i>}
                {message}
              </>
            )}
            {endFormatG && startFormatG && (
              <>
                {messageG != "" && <i className="fa fa-solid fa-bell"></i>}
                {messageG}
              </>
            )}
          </p>
        </div>
      </div>
      <div id="menu">
        <div className="navbar " role="navigation">
          <div className="row w-100 m-0 p-0 menu-logo-container">
            <div className="col-6  ml-lg-5 mt-4  main-title">
              <NavLink to="/home">
                <img src={gss} alt="" />
              </NavLink>
            </div>
            <div className="col-6 p-0 m-0 user-menu">
              <ul className="nav navbar-nav">
                <li className="light_text">
                  <NavLink to="/about" exact>
                    About
                  </NavLink>
                </li>
                <li className="light_text">
                  <NavLink to="/user/contact/" exact>
                    Contact
                  </NavLink>
                </li>
                {/* {localStorage.getItem("isSignedIn") == "true" ? undefined : (
                  <li className="light_text">
                    <NavLink to="/user/sign_up/">Register</NavLink>{" "}
                  </li>
                )} */}
                {authDetails.isSignedIn ||
                localStorage.isSignedIn === "true" ? undefined : (
                  <li className="light_text">
                    <NavLink to="/user/sign_up/">Register</NavLink>{" "}
                  </li>
                )}
                <li className="light_text">
                  {/* {localStorage.getItem("isSignedIn") == "true" ? (
                    <MenuDropdown name={localStorage.getItem("username")} />
                  ) : (
                    <NavLink className="orange" to="/sign_in">
                      Sign In{" "}
                    </NavLink>
                  )} */}

                  {authDetails.isSignedIn ||
                  localStorage.isSignedIn === "true" ? (
                    <MenuDropdown
                      name={
                        authDetails.isSocialLogin
                          ? authDetails.fullName
                          : authDetails.username
                      }
                    />
                  ) : (
                    <NavLink className="orange" to="/sign_in">
                      Sign In{" "}
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
            <div className="navbar-collapse navlinks">
              <ul className="nav navbar-nav">
                {authDetails.isSignedIn ||
                localStorage.isSignedIn === "true" ? (
                  <li>
                    <NavLink to="/MyGSS" exact>
                      MyGSS
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <NavLink to="/features" exact>
                    Features
                    <Tooltip
                      arrow
                      interactive
                      title={
                        <div>
                          Experience all the ways the GSS Data Explorer connects
                          you to the GSS
                        </div>
                      }
                    >
                      <InfoIcon
                        fontSize="small"
                        style={{ height: "10px" }}
                        className="plus-icon"
                      />
                    </Tooltip>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/mediaRoom" exact>
                    Media Room
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/resources" exact id="helpResourse">
                    Help and Resources
                    <Tooltip
                      arrow
                      interactive
                      title={<div>Learn how to access and use GSS data</div>}
                    >
                      <InfoIcon
                        fontSize="small"
                        style={{ height: "10px" }}
                        className="plus-icon"
                      />
                    </Tooltip>
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://gss.norc.org/Documents/other/Suspected%20Issues%20in%20the%20GSS.pdf"
                    target="_blank"
                    id="helpResourse" rel="noreferrer"
                    style={{textDecoration:'none'}}
                  >
                    Errata
                  </a>
                </li>
                <li className="gray">
                  <span>Explore GSS Data</span>
                  {/* <a href="/variables/vfilter">Search GSS Variables</a> */}
                  <NavLink className="menu-links" to="/variables/vfilter" exact>
                    Search GSS Variables
                  </NavLink>
                </li>
                <li className="gray">
                  {/* <a href="/trends">Key Trends</a> */}
                  <NavLink to="/trends" exact>
                    Key Trends
                  </NavLink>
                </li>
              </ul>
              {/* <div className="gray"></div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="modal basic-modal auto-submit-modal" id="sign_in_modal">
          <div className="modal-dialog">
          <div className="modal-content">
          <div className="modal-header">
          <h4 className="modal-title">Sign In</h4>
          </div>
          </div>
          </div>
          </div> */}
    </div>
  );
};

export default Header;
