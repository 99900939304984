import { React, useEffect, useState } from "react";
import Axios from "axios";
import { environment } from "../../environment";
import FormCard from "../UI/FormCard/FormCard";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Row, Container, Col, Dropdown, Card } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { CChart } from "@coreui/react-chartjs";
import { CContainer } from "@coreui/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import "react-toastify/dist/ReactToastify.css";
// import "../PopularVaribales/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
// import "./react-bootstrap-table2-filter.min.css";
import "./TicketTracker.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import FileSaver from "file-saver";
import { DialogActions } from "@mui/material";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const TicketTracker = (props) => {
  const [statusAlert, setStatusAlert] = useState(false);
  const [type, setType] = useState("success");
  const [title, setTitle] = useState("This is a alert");
  const [getId, setId] = useState();
  const [data, setData] = useState([]);
  const [closedTicket, setClosedTicket] = useState([]);
  const [loggedInData, setLoggedInData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [workNote, setWorkNote] = useState();
  const [Admin, setAdmin] = useState([]);
  const [AdminBy, setAdminBy] = useState([]);
  const [assignAdmin, setAssignAdmin] = useState();
  const [assignBy, setAssignBy] = useState("abcd");
  const [ticketType, setTicketType] = useState("Open");
  const [assignStatus, setAssignStatus] = useState();
  const [assignPriority, setAssignPriority] = useState();
  const [issueTypes, setIssueTypes] = useState();
  const [loadingGSS, setLoadingGSS] = useState(true);
  const [mergedData, setMergedData] = useState();
  const [mergedClosedData, setMergedClosedData] = useState();
  const [displayFilterPop, setdisplay] = useState(false);
  const [remarkLen, setRemarkLen] = useState(5);
  const priority = ["Low", "Medium", "High", "Critical"];
  const issueType = [
    "None",
    "Technical",
    "Data",
    "Media",
    "Suggested Improvement",
    "Other",
  ];
  const [commentText, setCommentText] = useState("");
  const status = [
    "Created",
    "Open",
    "Pending",
    "Awaiting Caller",
    "Work in Progress",
    "Closed Complete",
    "Closed Skipped",
    "Closed Incomplete",
  ];
  const handleHeaderClose = () => {
    setdisplay(false);
    setShowMore(false);
    setRemarkLen(5);
    setCommentText("");
  };
  const { ExportCSVButton } = CSVExport;
  const MyExportCSV = (props) => {
    const handleClick = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      props.onExport();
    };
    return (
      <Tooltip title="Export">
        <FileDownloadIcon style={{ color: "#50917E" }} onClick={handleClick} />
      </Tooltip>
    );
  };
  console.log(
    data?.filter((e) => e.Request_ID == getId)?.map((x) => x.Status),
    "sStatus code============"
  );
  console.log(ticketType, "ticket type============");
  const boxSX = {
    background: "#50917e",
    color: "White",
    marginTop: "10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#117da1",
    },
    width: "100px",
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total mx-2">
      Showing <b>{from}</b> to <b>{to}</b> of <b>{size}</b> Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  const getNewUsers = () => {
    Axios.get(`${environment.getAdminUsers}`)
      .then((resp) => {
        if (resp.data) {
          setAdmin(resp.data.response);
          const adminBy = resp.data.response?.map(({ id, username }) => ({
            idBy: id,
            usernameBy: username,
          }));
          setAdminBy(adminBy);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get(`${environment.getTicketTracker}`)
      .then((resp) => {
        if (resp.data) {
          setData(resp.data.response);

          setLoadingGSS(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get(`${environment.closedTicket}`)
      .then((resp) => {
        if (resp.data) {
          setClosedTicket(resp.data.response);

          setLoadingGSS(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    Axios.get(`${environment.settingsURL}`)
      .then((resp) => {
        if (resp.data) {
          setLoggedInData(resp.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(loggedInData, "loggedInData ////////////////////");
  console.log(mergedData, "mergedData=======================");
  const ApplyTicket = () => {
    const ticketData = {
      Request_ID: getId,
      Assign_to: parseInt(assignAdmin),
      Assigned_by: parseInt(loggedInData.user_id),
      Remark:
        commentText !== ""
          ? `${loggedInData.username} :>> ${commentText} ^Time::-%>^ ${moment(
              new Date()
            ).format("MMMM Do YYYY, h:mm:ss a")}`
          : "",
      Status: assignStatus,
      Priority: assignPriority,
      issueType: issueTypes,
    };

    Axios.post(`${environment.updateTicket}`, ticketData)
      .then((resp) => {
        if (resp.status === 200) {
          toast.success(`Data updated successfully for Request ID ${getId}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setCommentText("");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(`Some error occured`, {
          position: "bottom-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
    setdisplay(false);
    getNewUsers();

    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };
  const changeAdmin = ({ target: { value } }) => {
    setAssignAdmin(value);
  };
  const changeAssignBy = ({ target: { value } }) => {
    setAssignBy(value);
  };
  const changeTicketType = ({ target: { value } }) => {
    setTicketType(value);
  };
  const changeStatus = ({ target: { value } }) => {
    setAssignStatus(value);
  };
  const changePriority = ({ target: { value } }) => {
    setAssignPriority(value);
  };
  const changeIssueType = ({ target: { value } }) => {
    setIssueTypes(value);
  };
  console.log(assignBy, "assignBy===========>>>>>>>>>>>>>>");
  console.log(Admin, "Adminnnnnnnnnn===========>>>>>>>>>>>>>>");
  console.log(assignAdmin, "Assign  Adminnnnnnnnnn===========>>>>>>>>>>>>>>");
  useEffect(() => {
    if (localStorage.isAdmin !== "true") {
      props.history.push("/error");
      return;
    }
  }, []);

  useEffect(() => {
    const dataMerged = data?.map((t1) => ({
      ...t1,
      ...Admin.find((t2) => t2.id == t1.Assign_to),
      ...AdminBy.find((t2) => t2.idBy == t1.Assign_By),
    }));
    setMergedData(dataMerged);

    const dataClosed = closedTicket?.map((t1) => ({
      ...t1,
      ...Admin.find((t2) => t2.id == t1.Assign_to),
      ...AdminBy.find((t2) => t2.idBy == t1.Assign_By),
    }));
    setMergedClosedData(dataClosed);
  }, [Admin, data]);
  useEffect(() => {
    getNewUsers();
  }, [displayFilterPop, ticketType]);
  // useEffect(() => {
  //   setTypeOr(typeOr);
  // }, [typeOr]);
  // console.log(loading, "loadin");

  console.log(
    mergedClosedData,
    "mergedClosedData==============>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
  );
  const selectRow = {
    mode: "radio",
    clickToSelect: true,
  };
  // const getDataFromID = () => {
  //   setId(reqId);
  // };
  useEffect(() => {
    if (displayFilterPop && ticketType == "Open") {
      setAssignStatus(
        mergedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Status)[0]
      );
      setAssignPriority(
        mergedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Priority)[0]
      );
      setAssignAdmin(
        mergedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Assign_to)[0]
      );
      setAssignBy(
        mergedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.usernameBy)
      );
      setIssueTypes(
        mergedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Issue_related_to)
      );
    }
    if (displayFilterPop && ticketType == "Closed") {
      setAssignStatus(
        mergedClosedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Status)[0]
      );
      setAssignPriority(
        mergedClosedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Priority)[0]
      );
      setAssignAdmin(
        mergedClosedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Assign_to)[0]
      );
      setAssignBy(
        mergedClosedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.usernameBy)
      );
      setIssueTypes(
        mergedClosedData
          ?.filter((e) => e.Request_ID == getId)
          ?.map((x) => x.Issue_related_to)
      );
    }
  }, [displayFilterPop]);
  const columnTicket = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    {
      dataField: "Request_ID",
      text: "Request ID",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
      style: { cursor: "pointer" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row);
        const reqId = row.Request_ID;
        return (
          <div>
            <span className="mr-1">
              <div
                onClick={() => {
                  setId(reqId);
                  setdisplay(true);
                }}
                style={{ color: "blue", textDecoration: "underline" }}
              >
                {reqId}
              </div>
            </span>
          </div>
        );
      },
    },
    {
      dataField: "Priority",
      text: "Priority",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "Status",
      text: "Status",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "username",
      text: "Assign To",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" };
      },
    },
    {
      dataField: "Title",
      text: "Title",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "500px", textAlign: "center" };
      },
      style: { textAlign: "start", cursor: "pointer" },
    },
    {
      dataField: "Requested_at",
      text: "Created At",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "300px", textAlign: "center" };
      },
    },
    // {
    //   dataField: "Requested_issue",
    //   text: "Requested Issue",
    //   filter: textFilter(),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "500px", textAlign: "center" };
    //   },
    //   style: { textAlign: "start", cursor: "pointer" },
    // },
    // {
    //   dataField: "First_Name",
    //   text: "First Name",
    //   filter: textFilter(),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "150px", textAlign: "center" };
    //   },
    // },
    // {
    //   dataField: "Last_Name",
    //   text: "Last Name",
    //   filter: textFilter(),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "150px", textAlign: "center" };
    //   },
    // },
    {
      dataField: "Email",
      text: "Email ID",
      filter: textFilter(),
      headerStyle: (colum, colIndex) => {
        return { width: "300px", textAlign: "center" };
      },
    },

    // {
    //   dataField: "remark",
    //   text: "Remark",
    //   filter: textFilter(),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "150px", textAlign: "center" };
    //   },
    // },
    // {
    //   dataField: "usernameBy",
    //   text: "Assign By",
    //   filter: textFilter(),
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "150px", textAlign: "center" };
    //   },
    // },
  ];

  return (
    <>
      <div>
        <Dialog
          // onClose={handleHeaderClose}
          aria-labelledby="customized-dialog-title"
          open={displayFilterPop}
          sx={{ backdropFilter: "blur(7px)", zIndex: "2000000" }}
          // fullScreen
          maxWidth="lg"
          maxHight="lg"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={handleHeaderClose}
            sx={{
              background: "#00838e",
              color: "white",
              height: "auto",
            }}
          >
            <CloseIcon
              onClick={handleHeaderClose}
              sx={{ color: "orange", float: "right" }}
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              Ticket Details
            </Box>{" "}
          </DialogTitle>
          {ticketType === "Open" && (
            <DialogContent dividers>
              <div id="ticketDetails">
                {mergedData
                  ?.filter((fil) => fil.Request_ID === getId)
                  ?.map((val) => (
                    <div className="row p-0 m-0">
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="title col-lg-1 col-xl-1 col-md-12 col-sm-12 p-0 m-0">
                            {" "}
                            #{val?.Request_ID}{" "}
                          </b>
                          <b className="title col-lg-11 col-xl-11 col-md-12 col-sm-12 p-0 m-0">
                            {val?.Title}
                          </b>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">Request ID: </b>
                          <span style={{ color: "red" }}>
                            {val?.Request_ID}
                          </span>
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Issue Type:{" "}
                          </b>

                          <select
                            style={{
                              width: "200px",
                              height: "30px",
                              fontSize: "15px",
                              border: "1px solid black",
                              borderRadius: "10px",
                            }}
                            className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                            onChange={changeIssueType}
                            value={issueTypes}
                          >
                            <option
                              key={"Select"}
                              value={"Select"}
                              style={{
                                fontSize: "12px",
                                width: "100px",
                              }}
                            >
                              {" "}
                              Select...
                            </option>
                            {issueType?.map((item) => (
                              <option
                                key={item}
                                value={item}
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Status:{" "}
                          </b>
                          {status ? (
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                fontSize: "15px",
                                border: "1px solid black",
                                borderRadius: "10px",
                              }}
                              className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                              onChange={changeStatus}
                              value={assignStatus}
                              // defaultSelected={assignStatus[0]}
                            >
                              {status?.map((item) => (
                                <option
                                  key={item}
                                  value={item}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Priority:{" "}
                          </b>
                          {priority ? (
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                fontSize: "15px",
                                border: "1px solid black",
                                borderRadius: "10px",
                              }}
                              className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                              onChange={changePriority}
                              value={assignPriority}
                            >
                              <option
                                key={"Select"}
                                value={"Select"}
                                style={{
                                  fontSize: "12px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                Select...
                              </option>
                              {priority?.map((item) => (
                                <option
                                  key={item}
                                  value={item}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            {" "}
                            Assign to:{" "}
                          </b>
                          {Admin ? (
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                fontSize: "15px",
                                border: "1px solid black",
                                borderRadius: "10px",
                              }}
                              className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                              onChange={changeAdmin}
                              value={assignAdmin}
                            >
                              <option
                                key={"Select"}
                                value={"Select"}
                                style={{
                                  fontSize: "12px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                Select...
                              </option>
                              {Admin?.map((item) => (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.username}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <span className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0"> Assigned By: </span>
         
                          <div>{assignBy}</div>
                    
                        </div>
                      </div> */}
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Issuer Name:{" "}
                          </b>
                          <span>
                            {val?.First_Name} {val?.Last_Name}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Email ID:{" "}
                          </b>
                          <span>{val?.Email}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Contact #:{" "}
                          </b>
                          <span>{val?.Phone_Number}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Source:{" "}
                          </b>
                          <span>{val?.Occupation}</span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-1 col-xl-1 col-md-12 col-sm-12 p-0 m-0">
                            Description:{" "}
                          </b>
                          {val?.Requested_issue?.includes("\n") ? (
                            <span className="col-lg-10 col-xl-10 col-md-12 col-sm-12 p-0 m-0 number">
                              {val?.Requested_issue?.split("\n").map((i) => {
                                return <li>{i}</li>;
                              })}
                            </span>
                          ) : (
                            <span className="col-lg-10 col-xl-10 col-md-12 col-sm-12 p-0 m-0 number">
                              {val?.Requested_issue}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-1 col-xl-1 col-md-12 col-sm-12 p-0 m-0">
                            Work Notes:
                          </b>
                          <span
                            className="col-lg-10 col-xl-10 col-md-12 col-sm-12 p-0 m-0"
                            style={{ color: "black" }}
                          >
                            {val?.remark?.includes("^Time::-%>^") && (
                              <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 mb-2">
                                <div
                                  className="row m-0 p-3"
                                  style={{
                                    background: "#e6f8ff",
                                    borderRadius: "20px",
                                    maxHeight: "500px",
                                    overflow: "auto",
                                  }}
                                >
                                  {val?.remark?.includes("/n/r") ? (
                                    <>
                                      {val?.remark
                                        ?.split(", /n/r")
                                        ?.filter((x) => x !== " ")
                                        ?.slice(-remarkLen)
                                        ?.map((a) => (
                                          <>
                                            <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                              <span style={{ color: "orange" }}>
                                                <i
                                                  class="fa fa-user mx-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {a.split(":>>")[0]}
                                              </span>{" "}
                                              added a comment at{" "}
                                              <b>
                                                {a?.split("^Time::-%>^")?.pop()}
                                              </b>
                                            </span>
                                            <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                              <i
                                                class="fa fa-comment mx-2"
                                                aria-hidden="true"
                                              ></i>
                                              {
                                                a
                                                  ?.split(":>> ")
                                                  ?.pop()
                                                  ?.split("^Time::-%>^")[0]
                                              }
                                            </span>
                                            <hr className="col-12 m-0 mt-2 mb-2 p-0" />
                                          </>
                                        ))}
                                    </>
                                  ) : (
                                    <>
                                      <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                        <span style={{ color: "orange" }}>
                                          <i
                                            class="fa fa-user mx-2"
                                            aria-hidden="true"
                                          ></i>
                                          {val?.remark?.split(":>>")[0]}
                                        </span>{" "}
                                        added a comment at{" "}
                                        <b>
                                          {val?.remark
                                            ?.split("^Time::-%>^")
                                            ?.pop()}
                                        </b>
                                      </span>
                                      <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                        <i
                                          class="fa fa-comment mx-2"
                                          aria-hidden="true"
                                        ></i>
                                        {
                                          val?.remark
                                            ?.split(":>> ")
                                            ?.pop()
                                            ?.split("^Time::-%>^")[0]
                                        }
                                      </span>
                                      <hr className="col-12 m-0 mt-2 mb-2 p-0" />
                                    </>
                                  )}
                                </div>
                                <div className="col-12 m-0 p-2">
                                  {val?.remark?.split(", /n/r")?.length > 5 &&
                                    !showMore && (
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setRemarkLen(
                                            val?.remark?.split(", /n/r")?.length
                                          );
                                          setShowMore(true);
                                        }}
                                      >
                                        All Comments...
                                      </span>
                                    )}
                                  {val?.remark?.split(", /n/r")?.length > 5 &&
                                    showMore && (
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setRemarkLen(5);
                                          setShowMore(false);
                                        }}
                                      >
                                        Latest Five Comments...
                                      </span>
                                    )}
                                </div>
                              </span>
                            )}
                            <textarea
                              style={{
                                width: "100%",
                                padding: "2px",
                                marginTop: "5px",
                                borderRadius: "5px",
                              }}
                              name="commentTextArea"
                              type="text"
                              id="CommentsOrAdditionalInformation"
                              value={commentText}
                              placeholder=" Write your note"
                              onChange={(e) => setCommentText(e.target.value)}
                            ></textarea>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </DialogContent>
          )}
          {ticketType === "Closed" && (
            <DialogContent dividers>
              <div id="ticketDetails">
                {mergedClosedData
                  ?.filter((fil) => fil.Request_ID === getId)
                  ?.map((val) => (
                    <div className="row p-0 m-0">
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="title col-lg-1 col-xl-1 col-md-12 col-sm-12 p-0 m-0">
                            {" "}
                            #{val?.Request_ID}{" "}
                          </b>
                          <b className="title col-lg-11 col-xl-11 col-md-12 col-sm-12 p-0 m-0">
                            {val?.Title}
                          </b>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                      <div className="row m-0 p-0">
                        <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">Request ID: </b>
                        <span style={{ color: "red" }}>
                          {val?.Request_ID}
                        </span>
                      </div>
                    </div> */}
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Issue Type:{" "}
                          </b>

                          <select
                            style={{
                              width: "200px",
                              height: "30px",
                              fontSize: "15px",
                              border: "1px solid black",
                              borderRadius: "10px",
                            }}
                            className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                            onChange={changeIssueType}
                            value={issueTypes}
                          >
                            <option
                              key={"Select"}
                              value={"Select"}
                              style={{
                                fontSize: "12px",
                                width: "100px",
                              }}
                            >
                              {" "}
                              Select...
                            </option>
                            {issueType?.map((item) => (
                              <option
                                key={item}
                                value={item}
                                style={{
                                  fontSize: "12px",
                                }}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Status:{" "}
                          </b>
                          {status ? (
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                fontSize: "15px",
                                border: "1px solid black",
                                borderRadius: "10px",
                              }}
                              className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                              onChange={changeStatus}
                              value={assignStatus}
                              // defaultSelected={assignStatus[0]}
                            >
                              {status?.map((item) => (
                                <option
                                  key={item}
                                  value={item}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Priority:{" "}
                          </b>
                          {priority ? (
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                fontSize: "15px",
                                border: "1px solid black",
                                borderRadius: "10px",
                              }}
                              className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                              onChange={changePriority}
                              value={assignPriority}
                            >
                              <option
                                key={"Select"}
                                value={"Select"}
                                style={{
                                  fontSize: "12px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                Select...
                              </option>
                              {priority?.map((item) => (
                                <option
                                  key={item}
                                  value={item}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            {" "}
                            Assign to:{" "}
                          </b>
                          {Admin ? (
                            <select
                              style={{
                                width: "200px",
                                height: "30px",
                                fontSize: "15px",
                                border: "1px solid black",
                                borderRadius: "10px",
                              }}
                              className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                              onChange={changeAdmin}
                              value={assignAdmin}
                            >
                              <option
                                key={"Select"}
                                value={"Select"}
                                style={{
                                  fontSize: "12px",
                                  width: "100px",
                                }}
                              >
                                {" "}
                                Select...
                              </option>
                              {Admin?.map((item) => (
                                <option
                                  key={item.id}
                                  value={item.id}
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.username}
                                </option>
                              ))}
                            </select>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                      <div className="row m-0 p-0">
                        <span className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0"> Assigned By: </span>
       
                        <div>{assignBy}</div>
                  
                      </div>
                    </div> */}
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Issuer Name:{" "}
                          </b>
                          <span>
                            {val?.First_Name} {val?.Last_Name}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Email ID:{" "}
                          </b>
                          <span>{val?.Email}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Contact #:{" "}
                          </b>
                          <span>{val?.Phone_Number}</span>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-2 col-xl-2 col-md-12 col-sm-12 p-0 m-0">
                            Source:{" "}
                          </b>
                          <span>{val?.Occupation}</span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-1 col-xl-1 col-md-12 col-sm-12 p-0 m-0">
                            Description:{" "}
                          </b>
                          {val?.Requested_issue?.includes("\n") ? (
                            <span className="col-lg-10 col-xl-10 col-md-12 col-sm-12 p-0 m-0 number">
                              {val?.Requested_issue?.split("\n").map((i) => {
                                return <li>{i}</li>;
                              })}
                            </span>
                          ) : (
                            <span className="col-lg-10 col-xl-10 col-md-12 col-sm-12 p-0 m-0 number">
                              {val?.Requested_issue}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-2">
                        <div className="row m-0 p-0">
                          <b className="col-lg-1 col-xl-1 col-md-12 col-sm-12 p-0 m-0">
                            Work Notes:
                          </b>
                          <span
                            className="col-lg-10 col-xl-10 col-md-12 col-sm-12 p-0 m-0"
                            style={{ color: "black" }}
                          >
                            {val?.remark?.includes("^Time::-%>^") && (
                              <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 mb-2">
                                <div
                                  className="row m-0 p-3"
                                  style={{
                                    background: "#e6f8ff",
                                    borderRadius: "20px",
                                    maxHeight: "500px",
                                    overflow: "auto",
                                  }}
                                >
                                  {val?.remark?.includes("/n/r") ? (
                                    <>
                                      {val?.remark
                                        ?.split(", /n/r")
                                        ?.filter((x) => x !== " ")
                                        ?.slice(-remarkLen)
                                        ?.map((a) => (
                                          <>
                                            <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                              <span style={{ color: "orange" }}>
                                                <i
                                                  class="fa fa-user mx-2"
                                                  aria-hidden="true"
                                                ></i>
                                                {a.split(":>>")[0]}
                                              </span>{" "}
                                              added a comment at{" "}
                                              <b>
                                                {a?.split("^Time::-%>^")?.pop()}
                                              </b>
                                            </span>
                                            <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                              <i
                                                class="fa fa-comment mx-2"
                                                aria-hidden="true"
                                              ></i>
                                              {
                                                a
                                                  ?.split(":>> ")
                                                  ?.pop()
                                                  ?.split("^Time::-%>^")[0]
                                              }
                                            </span>
                                            <hr className="col-12 m-0 mt-2 mb-2 p-0" />
                                          </>
                                        ))}
                                    </>
                                  ) : (
                                    <>
                                      <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                        <span style={{ color: "orange" }}>
                                          <i
                                            class="fa fa-user mx-2"
                                            aria-hidden="true"
                                          ></i>
                                          {val?.remark?.split(":>>")[0]}
                                        </span>{" "}
                                        added a comment at{" "}
                                        <b>
                                          {val?.remark
                                            ?.split("^Time::-%>^")
                                            ?.pop()}
                                        </b>
                                      </span>
                                      <span className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                                        <i
                                          class="fa fa-comment mx-2"
                                          aria-hidden="true"
                                        ></i>
                                        {
                                          val?.remark
                                            ?.split(":>> ")
                                            ?.pop()
                                            ?.split("^Time::-%>^")[0]
                                        }
                                      </span>
                                      <hr className="col-12 m-0 mt-2 mb-2 p-0" />
                                    </>
                                  )}
                                </div>
                                <div className="col-12 m-0 p-2">
                                  {val?.remark?.split(", /n/r")?.length > 5 &&
                                    !showMore && (
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setRemarkLen(
                                            val?.remark?.split(", /n/r")?.length
                                          );
                                          setShowMore(true);
                                        }}
                                      >
                                        All Comments...
                                      </span>
                                    )}
                                  {val?.remark?.split(", /n/r")?.length > 5 &&
                                    showMore && (
                                      <span
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setRemarkLen(5);
                                          setShowMore(false);
                                        }}
                                      >
                                        Latest Five Comments...
                                      </span>
                                    )}
                                </div>
                              </span>
                            )}
                            <textarea
                              style={{
                                width: "100%",
                                padding: "2px",
                                marginTop: "5px",
                                borderRadius: "5px",
                              }}
                              name="commentTextArea"
                              type="text"
                              id="CommentsOrAdditionalInformation"
                              value={commentText}
                              placeholder=" Write your note"
                              onChange={(e) => setCommentText(e.target.value)}
                            ></textarea>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </DialogContent>
          )}
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={boxSX}
              onClick={ApplyTicket}
              // disabled={
              //   assignBy === null ||
              //   (assignBy === "" && assignBy === "Select") ||
              //   (assignBy ===
              //    mergedData
              //       ?.filter((e) => e.Request_ID == getId)
              //       ?.map((x) => x.usernameBy) &&
              //     assignBy !== loggedInData.username)
              // }
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <FormCard title="" header="Ticket Tracker">
        <form className="row w-500 simple_form form-horizontal mb-4 m-0 ">
          <>
            <>
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center mb-2">
                {loadingGSS ? (
                  <CircularProgress
                    disableShrink
                    size="1.2rem"
                    thickness="10"
                    sx={{
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                      [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: "round",
                      },
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3">
                {!loadingGSS && (
                  <div className="row w-100 d-flex justify-content-center">
                    <h6>
                      {ticketType === "Open"
                        ? "Total Open Issues:  "
                        : "Total Closed Issues:  "}
                      <strong style={{ color: "red" }}>
                        {ticketType === "Open"
                          ? mergedData?.length
                          : mergedClosedData?.length}
                      </strong>
                    </h6>
                    <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 m-0 p-0 pb-2">
                      {" "}
                      <select
                        style={{
                          width: "200px",
                          height: "30px",
                          fontSize: "15px",
                          border: "1px solid black",
                          borderRadius: "10px",
                        }}
                        className="col-lg-8 col-xl-8 col-md-12 col-sm-12 p-0 m-0"
                        onChange={changeTicketType}
                        value={ticketType}
                      >
                        <option
                          key={"Open"}
                          value={"Open"}
                          style={{
                            fontSize: "12px",
                            width: "100px",
                          }}
                          selected
                        >
                          {" "}
                          Open Tickets
                        </option>
                        <option
                          key={"Closed"}
                          value={"Closed"}
                          style={{
                            fontSize: "12px",
                            width: "100px",
                          }}
                        >
                          {" "}
                          Closed Tickets
                        </option>
                      </select>
                    </div>
                    {data?.length < 1 && closedTicket?.length < 1 ? (
                      <h6 style={{ color: "green" }}>No Ticket !!!</h6>
                    ) : (
                      <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 d-flex justify-content-center mb-4 p-0 ">
                        <ToolkitProvider
                          bootstrap4
                          keyField="variable_id"
                          columns={columnTicket}
                          data={
                            ticketType === "Closed"
                              ? mergedClosedData
                              : mergedData
                          }
                          exportCSV={{
                            fileName: `tickets.csv`,
                          }}
                        >
                          {(props) => (
                            <div className="row w-100 m-0 p-0">
                              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-0 m-0">
                                <MyExportCSV {...props.csvProps} />
                                Export to CSV
                              </div>
                              <Card className="tablescroll">
                                <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 p-0 m-0 fixTableHead">
                                  <BootstrapTable
                                    keyField="Request_ID"
                                    pagination={paginationFactory(options)}
                                    filter={filterFactory()}
                                    {...props.baseProps}
                                  />
                                </div>
                              </Card>
                            </div>
                          )}
                        </ToolkitProvider>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          </>
        </form>
      </FormCard>
    </>
  );
};

export default TicketTracker;
