import React from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { environment } from "../../environment";
import BigButton from "../UI/BigButton/BigButton";

function WeightingHelp() {
  return (
    <>
      <div className="gss-faq m-4">
        <>
          <div>
            <div className="gss-large-title left">Weighting Help</div>
          </div>
        </>
        <div className="my-3">
          <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
              <div
                className="pull-left"
                style={{
                  "margin": "20px",
                  "min-height": "800px",
                }}
              >
                <div className="clearfix"></div>
                <p>
                In the Data Explorer, WTSSCOMP is suitable for the most common analysis across all years. (For more advanced users please see the details on sampling and weights referenced below).  Due to changes in survey methodology, the 2021 GSS introduced a post-stratification weight. WTSSCOMP is a composite weight that combines WTSSALL (applicable to 2018 and before) and WTSSPS (available for 2021).For 2018 and earlier years, WTSSCOMP is based on WTSSALL (accounting for probability of selection, subsampling and number of adults in the household) and for 2021, WTSSCOMP is based on WTSSPS (which is a post-stratification weight designed to match American Community Survey key population controls). Thus, users should be cautious when comparing results from 2018 and previous years versus 2021 onwards in the same analysis.
                </p>
                <p>
                For a summary of weights in the GSS (including WTSSPS, WTSSALL and other weight variables), please see the 2021 GSS codebook, page 29. For a thorough discussion of the GSS Sample Design and weight construction prior to 2021, see the Sampling Design and Weighting methodology report.
                </p>
                
      
                <p>
                  For users interested in generating robust standard errors,
                  please see &nbsp;
                  <a
                    href="/gss_stdError"
                    style={{ color: "#73a492", "font-weight": "normal" }}
                  >
                    Calculating Design-Corrected Standard Errors for the General
                    Social Survey.
                  </a>
                </p>
              </div>
            </Col>
            {/* </div> */}
            <Col sm={12} md={4} lg={4} xl={4}>
              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and
                  how to use the data.
                </p>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  eleType="link"
                  url="/FAQ"
                >
                  VIEW FAQ
                </BigButton>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Does the GSS data set include geocoded information?</h5>
                <p>
                  Learn how to access GSS geocoded and other sensitive data.
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={environment.getSensitiveData}
                >
                  <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100">
                    SENSITIVE GSS DATA
                  </BigButton>
                </a>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>Submit your question or issue to the experts at GSS.</p>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  eleType="link"
                  url="/user/contact/"
                >
                  ASK AN EXPERT
                </BigButton>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default WeightingHelp;
