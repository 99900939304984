import { combineReducers } from 'redux';
import analysisReducer from './analysis';
import authReducer from './auth';
import variableReducer from './variable';
import reducer from '../reducer';
import userSearchesReducer from './usersearches'
import filteredYearsReducer from './filtered_years'
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import extractReducer from './extracts';
import trendsReducer from './trends';
import minmaxYearsReducer from './minmaxyears';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['reducer','analysisReducer']
}

const allReducers = combineReducers({
  variable: variableReducer,
  authReducer,
  reducer,
  analysisReducer,
  userSearchesReducer,
  filteredYearsReducer,
  extractReducer,
  trendsReducer,
  minmaxYearsReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return allReducers(undefined, action)
  }
  return allReducers(state, action)
}

export default persistReducer(persistConfig, rootReducer);