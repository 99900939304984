import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../../environment";
import { connect } from "react-redux";
import "./UserSearchSummary.css";
import { NavLink } from "react-router-dom";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { updateService } from "../../../Shared/UpdateCart";
import {ControllerName} from "../../../Shared/ControllerName"
function UserSearchSummary(props) {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isParent, setParent] = useState(true);
  const [searchId, setSearchId] = useState();
  const [commentData, setCommentData] = useState("");
  const [isEdit, setEdit] = useState(false);
  useEffect(() => {
    getData();
    ControllerName.sendName('User Searches');
    updateService.sendAlert("varData");
  }, []);
  const getData = () => {
    const id = props.location.pathname.split("/user_searches/")[1];
    const search_id = id.substr(0, id.indexOf("/"));
    setSearchId(search_id);
    let reqBody = {
      srch_id: search_id,
    };
    axios
      .post(`${environment.getUserSearchSummary}`, reqBody)
      .then((resp) => {
        setData(resp.data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const addComment = (comment) => {
    setEdit(false);
    if (comment == "parent") {
      setParent(comment);
    } else {
      setParent(comment);
    }
    setIsOpen(true);
  };
  const editComment = (comment) => {
    setCommentData(comment);
    setIsOpen(true);
    setEdit(true);
  };
  const submitSave = (text) => {
    if (text) {
      let url = "";
      let req = {};
      if (isEdit) {
        url = environment.editSearchComment;
        req = {
          content: text,
          id_: commentData.comment_id,
        };
      } else {
        url = environment.addSearchComments;
        req =
          isParent == "parent"
            ? {
                commentable_id: Number(searchId),
                commentable_type: "UserSearch",
                content: text,
              }
            : {
                commentable_id: Number(searchId),
                commentable_type: "UserSearch",
                content: text,
                parent_id: Number(isParent.comment_id),
              };
      }
      axios
        .post(`${url}`, req)
        .then((resp) => {
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const deleteComment = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      axios
        .delete(`${environment.deleteSearchComment}`, {
          data: {
            id_: id,
          },
        })
        .then((response) => {
          getData();
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
          } else {
            // anything else
          }
        });
    } else {
    }
  };
  const setOpenFn = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <CustomModal
        title="Add Comment"
        isOpen={isOpen}
        setIsOpen={setOpenFn} //callback function when dialog is opened. Calling Child function from Parent
        handleSubmit={submitSave}
        btnText="Submit"
        isInput={false}
        inputLabel=""
        comment={commentData}
        isEdit={isEdit}
      ></CustomModal>
      {data.name ? (
        <div className="noscroll analysis" id="ws-content">
          <div className="gss-section">
            <div className="gss-center index pull-left">
              <div className="gss-bold-title">
                <div className="pull-left">User Search Summary</div>
                <div className="clearfix"></div>
              </div>
              <div className="summary">
                <div className="h4 hb">{data.name}</div>
                <hr className="divider" />
                {data?.term ? (
                  <div className="param">
                    <strong> Keyword</strong>
                    <div className="extract-tab">{data?.term}</div>
                  </div>
                ) : (
                  ""
                )}
                {data?.subject ? (
                  <div className="param">
                    <strong> Subjects</strong>
                    <div className="extract-tab">{data?.subject}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="param">
                  <strong>Years Available</strong>
                  <div className="extract-tab">
                    {data?.years[0]} to {data?.years[1]}
                  </div>
                </div>
                <div className="param">
                  <strong>Results</strong>
                  <div className="extract-tab">
                    {data.results ? data.results : 0} variables
                  </div>
                </div>
                <div className="extract_links">
                  <NavLink
                    title="Perform this search"
                    exact
                    to="/variables/vfilter/"
                  >
                    <i className="fa fa-search fa-2x"></i>
                  </NavLink>
                </div>
              </div>
              <div id="comments">
                <h4 className="hb">Comments ({data?.comments?.length})</h4>
                <a
                  className="btn btn-gray hb"
                  data-toggle="modal"
                  data-target="#add_comment"
                  data-backdrop="static"
                  href="#"
                  onClick={(event) => addComment("parent")}
                >
                  Add Comment
                </a>
                {data?.comments?.map((comment) => (
                  <div className="comment">
                    <hr />
                    <div
                      className="text"
                      id="comment-590"
                      style={{ "word-wrap": "break-word" }}
                    >
                      {comment.comment}
                    </div>
                    <div className="info">
                      <b>{comment.userName}</b> &nbsp;
                      {comment.dateTime}
                      <a href="#" onClick={(event) => addComment(comment)}>
                        Reply
                      </a>
                      <a href="#" onClick={(event) => editComment(comment)}>
                        <i className="fa fa-pencil"></i>
                      </a>
                      <a
                        href="#"
                        onClick={(event) => deleteComment(comment.comment_id)}
                      >
                        <i className="fa fa-trash-o"></i>
                      </a>
                    </div>
                    {comment?.childComment.length > 0 ? (
                      <ul className="replies">
                        {comment?.childComment?.map((reply, index) => (
                          <li className="reply">
                            <div
                              className="text"
                              style={{ "word-wrap": "break-word" }}
                              id="comment-592"
                            >
                              {reply.comment}
                            </div>
                            <div className="info">
                              <b>{reply.userName}</b> &nbsp;
                              {reply.dateTime}
                              &nbsp;
                              <a
                                data-toggle="modal"
                                data-target="#add_comment"
                                data-comment-id="590"
                                href="#"
                                onClick={(event) => editComment(reply)}
                              >
                                <i className="fa fa-pencil"></i>
                              </a>
                              <a
                                data-toggle="modal"
                                data-target="#add_comment"
                                data-comment-id="590"
                                href="#"
                                onClick={(event) =>
                                  deleteComment(reply.comment_id)
                                }
                              >
                                <i className="fa fa-trash-o"></i>
                              </a>
                            </div>
                            {index == comment?.childComment.length - 1 ? (
                              ""
                            ) : (
                              <hr />
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.data.reducer,
});
export default connect(mapStateToProps)(UserSearchSummary);
