import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import './AnalysisHistory.css';
import axios from 'axios';
import { environment } from "../../../environment";
import { NavLink, useHistory } from 'react-router-dom';
import { updateService } from '../../../Shared/UpdateCart';
import { useDispatch, useSelector } from "react-redux";
import { setAnalysis } from '../../../Store/actions'
import { makeStyles } from '@material-ui/core';
import { Row } from 'react-bootstrap';
import Loader from '../../../Components/UI/Loader/Loader';
import Pagination from '@material-ui/lab/Pagination';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import CustomModal from '../../../Components/UI/Modal/CustomModal';
import { dateTimeFormat } from '../../../Store/utils/common.utils';
import { getAnalysisExtractStatus } from '../../../Store/utils/variable.utils';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',

    },
    '&.MuiDataGrid-root .MuiDataGrid-colCell:focus-within': {
      outline: 'none',

    }

  }
});
export default function AnalysisHistory(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [initialLoad, setInitialLoad] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tempSearchKeyword, setTempSearchKeyword] = useState('');
  const [status, setStatus] = useState('');
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [loading, setLoading] = useState(false);
  const [isServerError, setIsServerError] = useState({ status: false, message: null, variant: null });
  const authDetails = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    updateService.sendAlert('');
    if (localStorage.isAdmin != 'true' && !(authDetails.isSignedIn || localStorage.isSignedIn === 'true')) {
      history.push("/error");
      return;
    }
 if( localStorage.isAdmin != 'true' && (authDetails.isSignedIn || localStorage.isSignedIn === 'true') ){
      history.push("/MyGSS");
    }

    usersList();
  }, [currentPage, status, min, max,searchKeyword]);


  const handleStatusChange = (event) => {
    setCurrentPage(1)
    setStatus(event.target.value);
  }

  const usersList = () => {
    setLoading(true)
    let createdRange = {}
    if (min || max) {
      createdRange = {
        "min": min ? `${min} 00:00:00` : null,
        "max": max ? `${max} 11:59:59` : null
      }
    } else {
      createdRange = null
    }

    let statusFilter;
    if (status == "None") {
      statusFilter = '';
    } else if (status == 'Pending') {
      statusFilter = 'Submitted';
    } else if (status == 'Completed') {
      statusFilter = 'Finished';
    } else if (status == 'Failed') {
      statusFilter = 'Failed';
    } else if (status == 'In-Progress') {
      statusFilter = 'Started';
    } else {
      statusFilter = '';
    }


    axios({
      method: "POST",
      url: environment.getAnalyses,
      data: {
        "limit": limit,
        "page": currentPage,
        "statusFilter": statusFilter,
        "keyword": searchKeyword,
        "createdRange": createdRange
      },
    }).then((response) => {
      if (response.data) {
        let data = getAnalysisExtractStatus(response.data, "analysis")
        /* let data = response.data.Data.map((row, index) => {
          row.id = row.analysisId;
          if (row.status?.toLowerCase() == 'submitted') {
            row.analysisStatus = 'Pending';
          } else if (row.status?.toLowerCase() == 'finished') {
            row.analysisStatus = 'Completed';
          } else if (row.status?.toLowerCase() == "started") {
            row.analysisStatus = 'In-Progress';
          } else if (row.status?.toLowerCase() == "failed") {
            row.analysisStatus = "Failed";
          } else {
            row.analysisStatus = "";
          }
          return row;
        }); */
        
        setRows(data);
        setTotalPage(response.data.pageCount);
        setTotalRecords(response.data.recordCount);
        setInitialLoad(true);
        setLoading(false)
      }
    })
      .catch(err => {
        if (err.response) {
          //alert(err.response.data.message);
          setIsServerError({ ...isServerError, status: true, message: err.response.data.message, variant: 'Error' });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // alert('Some Error Occurred');
          setIsServerError({ ...isServerError, status: true, message: "Something went wrong. Try Again!!", variant: 'Error' });
          // client never received a response, or request never left
        } else {
          setIsServerError({ ...isServerError, status: true, message: "Something went wrong. Try Again!!", variant: 'Error' });
          // anything else
        }

      })
  }

  const dateMinChange = ({ target: { value } }) => {
    setCurrentPage(1)
    setMin(value);
  };

  const dateMaxChange = ({ target: { value } }) => {
    setCurrentPage(1)
    setMax(value);
  };


  const handleFormSubmit = (event) => {

    event.preventDefault();
    // if (e.key === 'Enter') {
    //   usersList();
    // }
  }


  const handleBlur = (event) => {
    if(event.key == 'Enter')
    if (tempSearchKeyword !== searchKeyword) {
      setCurrentPage(1);
      setSearchKeyword(tempSearchKeyword);
    }

  }

  const handleClearSeach = (e) => {
    setTempSearchKeyword('');
    setCurrentPage(1)
    setSearchKeyword("")
    setMin("")
    setMax("")
  }

  // const handleAnalysis = (data) => {
  //   dispatch(setAnalysis({ analysis_data: data }));
  //   //localStorage.setItem("analysis_data", JSON.stringify(data));
  // }


  const clickPaginationBtn = (e, val) => {
    let setPage = val;
    setCurrentPage(setPage)
    // usersList();
  }

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  }

  const setClose = () => {
  }
  return (
    <div id="analysis_history">
      <CustomModal
        title={isServerError.variant}
        isOpen={isServerError.status}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent          
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>
      <div id="or-main-content">
        <div className="or-grid">
          <div id="mainbar">
            <div className="stitle">
            
    {loading ?<CircularProgress color="inherit" size='1.2rem' thickness='3'/>:<></>}  
    &nbsp;Tabulation History
            </div>
            <div className="or-toolbar or-index">
           {totalRecords?<div className="or-toolbar-item pull-left"><h4 className="mt-2">
             <strong>{totalRecords} Results</strong></h4></div>:''}
              <div className="or-toolbar-item keyword-item">
                <div className="input-group ">
                  {/* <NavLink className="mr-2" exact to="/createAnalysis">Create Analysis</NavLink> */}
                  {/* <form onSubmit={handleFormSubmit} className="selectForm"> */}
                    <input type="text" name="search" className="bar-search form-control" id="filter-text-box" placeholder="Keyword" value={tempSearchKeyword} onChange={(event) => setTempSearchKeyword(event.target.value)} onKeyDown={handleBlur} />
                    &nbsp;
                  {/* </form> */}
                  <div className="input-group-addon">
                    <a className="anchor" onClick={handleClearSeach}><i title="Clear the search" className="filter-clear fa fa-times-circle fa-lg"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gss_material_table">
        {
          initialLoad ? (
            <>
              <TableContainer>
                <Table
                  className={`main-root mb-3`}
                  aria-labelledby="tableTitle"
                  size={'small'}
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left" width="10%">Type</TableCell>
                      <TableCell align="left" width="18%"><div> Status <br />
                        <select name="select" id="select value" value={status} className="custom-select" onChange={handleStatusChange}>
                          <option value="">Select value</option>
                          <option value="Pending">Pending</option>
                          <option value="In-Progress">In-Progress</option>
                          <option value="Completed">Completed </option>
                          <option value="Failed">Failed</option>
                        </select></div></TableCell>
                      <TableCell align="left" width="20%">
                        <span>
                          Last Updated At
                        </span>
                        <input type="date" className="form-control col-11" placeholder="To" value={min} onChange={dateMinChange} />
                        <input type="date" placeholder="To" className="form-control col-11" value={max} onChange={dateMaxChange} />
                      </TableCell>
                      <TableCell align="left" width="20%">Creator</TableCell>
                      <TableCell align="left" width="5%">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {rows
                      .map((row, index) => {

                        return (
                          <>
                            <TableRow
                              hover
                              key={row.analysisId}
                            >
                              <TableCell component="th" scope="row">
                                <strong>
                                  {  row?.status == 'Finished' ?<NavLink
                                    to={'/analysis_summary/' + row.analysisId + "?creatorId=" + row.creatorId}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    // onClick={(e) => handleAnalysis(row)}
                                    title={row.analysisName}
                                    className="gridClickableText text-break"
                                  >
                                    {row.analysisName}
                                  </NavLink>: <span className="grey-text">{row.analysisName}</span>}

                                </strong>
                              </TableCell>
                              <TableCell align="left" width="10%" key={row.type}>{row.type}</TableCell>
                              <TableCell align="left" width="18%" className="text-capitalize">{row.analysisExtractStatus}</TableCell>
                              <TableCell align="left" width="20%"><span className="text-break">{dateTimeFormat(row.updated_at)}</span></TableCell>
                              <TableCell align="left" width="20%"><span className="text-break">{row.creatorName}</span></TableCell>
                              <TableCell align="center" width="5%">
                          {  row?.status == 'Finished' ?   <strong>
                                  <NavLink
                                    to={'/analysis_summary/' + row.analysisId + "?creatorId=" + row.creatorId}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    // onClick={(e) => handleAnalysis(row)}
                                    title={row.view}
                                    className="gridClickableText text-righ"
                                  >
                                    <i className="fa fa-eye fa-lger"></i>
                                  </NavLink> 
                                </strong>:''}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    {(rows.length === 0) ? (
                      <TableRow style={{ height: 33 }}>
                        <TableCell colSpan={6} className="text-center">No Data</TableCell>
                      </TableRow>
                    ) : ""}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="row mx-0 p-0 mb-2 ">
                <div className="col-4 p-0 align-self-center">
                  <strong>
                    {totalPages
                      ? "Page " +
                      currentPage +
                      " of " +
                      totalPages
                      : ""}
                  </strong>
                </div>
                {(rows.length > 0) ? (
                  <div className="col-8 p-0">
                    <Pagination count={totalPages} page={currentPage} onChange={clickPaginationBtn} size="large" />
                  </div>
                ) : ""}
              </div>
            </>
          ) : (
            <Row className="d-flex mt-30 align-items-center justify-content-center w-100"> <Loader /></Row>
          )
        }
      </div>
    </div>

  )

}
