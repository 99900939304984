const initialState = {
    currentUser: {}
}

function reducer(state = initialState, action) {
    switch (action.type) {
       /*  case "SET_USER":
            {
                const currentUser = action.payload;
                return {
                    ...state,
                    currentUser
                }
            } */
        case "USER_DATA":
            {
                const data = action.payload;
                return {
                    ...state,
                    data
                };
            }
        case "VARIABLE_DATA":
            {
                const vardata = action.payload;
                return {
                    ...state,
                    vardata
                };
            }
        case "PROJECT_DATA":
            {
                const prgdata = action.payload;
                return {
                    ...state,
                    prgdata
                };
            }
        /* case "SEARCH_DATA":
            {
                const data = action.payload;
                return {
                    ...state,
                    data
                };
            } */
        case "NEW_PROJECT":
            {
                const newdata = action.payload;
                return {
                    ...state,
                    newdata
                };
            }
        case "YEAR_DATA":
            {
                const yeardata = action.payload;
                return {
                    ...state,
                    yeardata
                };
            }
        default: return state
    }
}

export default reducer