import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { environment } from "../../environment";
import BigButton from "../UI/BigButton/BigButton";
import "./FAQ.css";

function FAQ() {
  const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
  const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2018]
  return (
    <>
      <div className="gss-faq m-4">
        <>
          <div>
            <div className="gss-large-title left">GSS Frequently Asked Questions</div>
          </div>
        </>
           <div className="my-3">
           <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
            <div className="terms-text orange-title">
              How can I use the GSS Data Explorer in my classroom?
            </div>

            <hr className="" />
            <span className="sharing">
            The GSS Data Explorer makes it easier than ever to teach research methods, program evaluation, statistics, or other courses using GSS data. With GSS DE, students can extract GSS data or analyze data online and share them via Google Drive or Dropbox. For more information on how to get started using the DE, including step-by-step guides to searching variables, creating tabulations, and extracting data, please visit our  

              <NavLink
                className="green-link"
                to="/resources"
              >
                {" "}
                Help and Resources
              </NavLink> page.
            </span>
            <br />
            <div className="terms-text orange-title">
              How can I use the Key Trends feature to view trends in GSS data
              over time?
            </div>
            <hr />
            <span className="sharing">

              To learn more about how to use the  <NavLink
                className="green-link"
                to="/trends"
              >
                Key Trends
              </NavLink>  feature, visit the    <NavLink
                className="green-link"
                to="/viewtrends/"
              >
                Quick Start Guide
              </NavLink>. 
              To learn more about the methodology used to develop the visualizations, <NavLink
                className="green-link"
                to="/faq_trends"
              >
                click here
              </NavLink>.
            </span>
            <div className="terms-text orange-title">
              How do I cite the GSS?
            </div>
            <hr />
            <span className="sharing">
              See our citation guidelines{" "}
              <NavLink className="green-link" to="/gsscite">
                here.
              </NavLink>
            </span>
            <div className="terms-text orange-title">
              Do I need to consult the GSS codebook?
            </div>
            <hr />
            <span className="sharing">
              Yes. The brief variable labels you will find on this site give
              only a general guide to variable content. The codebook provides
              the exact wording of questions in the survey, as well as all
              possible responses for each question and the codes assigned to
              them. The codebook also provides full documentation of other
              methodological features of the GSS that users must understand.
              You'll find the codebook in our{" "}
              <a className="green-link" href="/resources">
                Help and Resources
              </a>{" "}
              section.
            </span>
            <div className="terms-text orange-title">
              Do I need to use weights when analyzing GSS data?
            </div>
            <hr />
            <span className="sharing">
              <p>
              Yes, you need to use weights to make your results more accurate; that is, more representative of the U.S. adult population. Due to the changes in the survey methodology for the 2021 GSS (associated to the COVID19 pandemic), the GSS Team does not recommend analyzing unweighted 2021 GSS data. By default, the GSS Data Explorer uses a weight called WTSSPS, which we recommend for most analyses.
            </p>
            <p>
            WTSSCOMP is unique to the GSS Data Explorer and is not included in the GSS cumulative datafile —it is a composite weight that combines the WTSSALL and WTSSPS weights to ease analysis on the Data Explorer. WTSSALL accounts for the design of the GSS (namely, probability of selection, subsampling, number of adults in the household). The WTSSPS weight adds one more step: post-stratification; thus, the GSS results can match population estimates from the American Community Survey on key demographic characteristics. Currently, the WTSSPS weight (post-stratification) is only available for 2021. This means that WTSSCOMP is equal to WTSSALL in years prior to 2021; and equal to WTSSPS in 2021. In other words, WTSSCOMP helps to ensure that there is a single default weight that shows appropriately weighted results for each year. In general, due to changes in survey methodology and weighting methodology care should be taken when comparing 2021 to past years.
            </p>
            </span>
            
            <span className="sharing">
            GSS also provides other weighting variables. For a summary of weights in the GSS, please see the 2021 GSS codebook, page 29. For additional details and technical considerations for advanced users, please consult the 1972-2018 GSS codebook, 
            <a className="green-link" href="/gssReports/2">&nbsp;
            Appendix A.
            </a>
              
            </span>

            <div className="terms-text orange-title">
              Are all response options for a question asked in every year the
              variable is available?
            </div>
            <hr />
            <span className="sharing">
              Not necessarily. Response options can change over time, with
              available response options being added to, or taken away from the
              survey questionnaire. For this reason, a user should verify the
              response options in one year, and compare the same variable across
              the other years you wish to analyze. This will enable you to
              assess whether “Not Applicable”, “Don’t Know”, or other response
              options are applicable to the analysis you plan to create.
            </span>
            <div className="terms-text orange-title">
              Which items are part of the “GSS core”?
            </div>
            <hr />
            <span className="sharing">
              Survey questions in the “GSS replicating core” are regularly
              administered as part of each GSS. Core items include background
              information about respondents (for example: age [AGE], sex [SEX],
              education [EDUC], region of residence [REGION]) and measures of
              attitudes (such as views about gender roles [e.g. FEHOME],
              confidence in institutions [e.g. CONFINAN] or gun control
              [GUNLAW]) or behaviors (such as attendance at religious services
              [ATTEND] or voting in the most recent U.S. Presidential election
              [VOTE68 through VOTE04]). Items are very occasionally added to or
              removed from the GSS core. An especially notable number of items
              were removed from the core after 1994. To see how regularly any
              particular GSS item is measured, consult codebook{" "}
              <NavLink className="green-link" to="/gssReports/2">
                Appendix U: Variable Use by Year/Ballot.
              </NavLink>
            </span>
            <div className="terms-text orange-title">
              How can I learn more about work and working life in the US?
            </div>
            <hr />
            <span className="sharing">
              In addition to the core questions about work status and
              occupation, the National Institute for Occupational Safety and
              Health (NIOSH) and NORC’s General Social Survey developed a module
              assessing the quality of work life in America, starting with the
              2002 GSS survey. This module has been replicated with every other
              GSS (2002, 2006, 2010, 2014, 2018), and is only asked to those who are
              currently working. For more information, visit the{" "}
         
                <a href="https://www.cdc.gov/niosh/topics/stress/qwlquest.html" >
                NIOSH Quality of Worklife</a>&nbsp;page on the Center for Disease Control and Prevention website.
            </span>
            <div className="terms-text orange-title">
              What happened to information on race after 2000?
            </div>
            <hr />
            <span className="sharing">
              Until 2000, the GSS measured race mostly by interviewer
              observation (variable RACE), using categories of white, black, and
              other. If in doubt about how to code a respondent’s race,
              interviewers asked the respondent “what race do you consider
              yourself?” Beginning in 2002, the GSS measured race following the
              procedures used in the decennial Census, asking all respondents
              for a racial self-identification and recording up to three
              mentions. These data are in variables RACECEN1, RACECEN2, and
              RACECEN3 (The same questions also were asked as part of the
              “Multi-Ethnic United States” topical module administered to one of
              the two samples in the 2000 GSS).
            </span>
            <div className="terms-text orange-title">
              Does the GSS data set include geocoded information?
            </div>
            <hr />
            <span className="sharing">
              The public use files available on this site and in other archives
              do not include any geocoded data. However, GSS geographic
              identification code files are made available to researchers under
              special contract with NORC. The GSS takes its promise of anonymity
              to its respondents very seriously and this is the basis for the
              contract process. Under contract, the GSS will provide data on
              state, primary sampling unit, county, and Census tract, but in no
              circumstances will individually identifying information (name,
              address, etc.) be provided.&nbsp;
              <NavLink className="green-link" exact to="/user/contact/">
                Contact us
              </NavLink>{" "}
              for information about obtaining geocoded files, or view the
              document:{" "}
              <a
                target="_blank"
                href="http://gss.norc.org/documents/other/ObtainingGSSSensitiveDataFiles.pdf"
              >
                Obtaining GSS Sensitive Data Files.
              </a>
            </span>
            <div className="terms-text orange-title">
              How can I get additional help with the GSS Data Explorer and the
              data set?
            </div>
            <hr />
            <span className="sharing">
              The GSS Data Explorer includes Quick Start Guides to help you get
              the most out of the tool. It also has information about the GSS,
              including the online codebook, the codebook appendices, and
              published GSS reports. You can access these in our{" "}
              <NavLink className="green-link" to="/resources">
                Help and Resources
              </NavLink>{" "}
              section.
            </span>
            <div className="terms-text orange-title">
              I’m having an issue using the GSS Data Explorer. Can you help me
              with my issue?
            </div>
            <hr />
            <span className="sharing">
              Yes – please complete the{" "}
              <NavLink className="green-link" exact to="/user/contact/">
                online contact form
              </NavLink>{" "}
              or search our{" "}
              <NavLink
                className="green-link"
                exact
                to="/resources"
              >
                Help and Resources
              </NavLink>{" "}
              section, and we’ll do our best to help. Note that if you are
              experiencing technical issues, first check that you are using a
              modern browser such as Chrome, Firefox and Edge. Internet Explorer or
              other older browsers are not supported.
            </span>
            <div className="terms-text orange-title">
              How can I learn more about the GSS and how it is administered?
            </div>
            <hr />
            <span className="sharing">
              For general information about the GSS, including how we design the
              questionnaires, create samples, and interview respondents, visit
              our main GSS site for{" "}
              <a className="green-link" href="http://gss.norc.org/faq">
                general FAQs about the GSS.
              </a>
            </span>
            <br />
            <br />
        
        </Col>
        {/* </div> */}
        <Col sm={12} md={4} lg={4} xl={4}>
              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Need help with the weighting feature?</h5>
                <p>
                Learn about the weights available in the GSS Data Explorer and how best to use them to create your analyses.
                </p>
                <a href="/gssweighting" style={{"color": "white"}}>  <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  

                >
              WEIGHTING HELP
                </BigButton></a>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5 >Does the GSS data set include geocoded information?</h5>
                <p>
                  Learn how to access GSS geocoded and other sensitive data.
                </p>
                <a target="_blank" rel="noreferrer"  href={environment.getSensitiveData}>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                >
                SENSITIVE GSS DATA
                </BigButton>
                </a>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>
                  Submit your question or issue to the experts at GSS.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact/">ASK AN EXPERT</BigButton>
              </div>

              <div className="pt-5 pl-4 pb-4 pr-4 slide" >
                <h5>GSS Cumulative Data {years[0]}-{years[1]}</h5>
                <p>
                  Want the entire GSS dataset to analyze in your own statistical software package without creating an account? Download the cumulative dataset for SPSS or Stata.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/gss_data">QUICK DOWNLOAD</BigButton>
              </div>

            </Col>
            </Row>
            </div>
      </div>
    </>
  );
}

export default FAQ;
