import React from "react";
import './Actions.css';
export default function Actions() {
    return (
    <div className="ml-1 mt-1 d-none">
      <div className="nomargin action_buttons" id="action_buttons">
        <div className="btn-group" data-toggle="buttons">
          <label className="btn btn-gray active">
            <i className="fa fa-download"></i>
            Extract data
          </label>
          <label className="btn btn-gray">
            <i className="fa fa-table"></i>
            Analyze data
          </label>
        </div>   
      </div>
      <div className="clearfix"> </div>
    </div>
  );
}
