import { memo, useEffect } from "react";
import { Chip } from "@material-ui/core";
import { useDrag } from "react-dnd";
import VarInfo from "../../Components/UI/VarInfo/VarInfo";
import { ItemTypes } from "../../Store/utils/common.utils";
import { getEmptyImage } from "react-dnd-html5-backend";

const CartItemDnd = memo(function DraggableBox(props) {
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.CARD,
      id: props._id,
      data: props.data,
      list: props.variablesList
        ? props.variablesList.filter((item) => item.isChecked)
        : "",
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const onClickToggle = () => {
    if (props.tagId) {
      props.toggleTags(props.data.vID, props.tagId);
    } else {
      props.toggleTags(props.data.vID);
    }
  };
  return (
    <li
      key={props.data.orignlName}
      className={"var-item"}
      id={props.data.vID}
      ref={drag}
    >
      <div className="info ">
	<div className="col-2 m-0 p-0 mr-1">
        <div className="row">
          <div className="col-12">
            <input
              className={
                (props.isTagCategories ? "invisible" : "") + " mr-3 mt-1"
              }
              type="checkbox"
              checked={props.data.isChecked}
              onChange={(event) => props.cartItemAction(event, props.data.vID)}
            />
          </div>
          <div className="col-12 var-indicator cart">
            <span
              className={
                props.data.varType === "continuous" ? "continuous" : "discrete"
              }
            ></span>
          </div>
        </div>
	</div>
        {props.isAnalysisOrExtract ? (
          <div className="col-10 m-0 p-0">
            <div className="d-flex">
              <div className="var-label">
                <button className={"analysis-create"}>
                  {props.data.orignlName}
                </button>
                {props.data.expanded
                  ? props.data.tags_info.map((s) => (
                      <Chip
                        key={s.id}
                        className="ht-20 ml-2"
                        label={s.name}
                        variant="outlined"
                        title={s.name}
                      />
                    ))
                  : props.data.tags_info
                      .slice(0, 1)
                      .map((s) => (
                        <Chip
                          key={s.id}
                          className="ht-20 ml-2"
                          label={s.name}
                          variant="outlined"
                          title={s.name}
                        />
                      ))}
              </div>
              <div className="toggle-tags">
                {props.data.tags_info.length > 1 ? (
                  <span className="list-group-item rounded-0 border-0 p-0 m-0 text-nowrap">
                    <button
                      className={"button-to-link"}
                      onClick={onClickToggle}
                    >
                      {props.data.expanded
                        ? "See less"
                        : "+" + (props.data.tags_info.length - 1) + " More"}
                    </button>
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="var-desc">{props.data.label}</div>
          </div>
        ) : (
          props.data && (
            <VarInfo
              data={props.data}
              tagId={props.tagId}
              toggleTags={onClickToggle}
            />
          )
        )}
      </div>
    </li>
  );
});

CartItemDnd.defaultProps = {
  tagId: 0,
};
export default CartItemDnd;
