import React, { useState, useEffect } from "react";
import Axios from "axios";
import { environment } from "../../../environment";
import { Container, Row, Col } from "react-bootstrap";
import BigButton from "../../../Components/UI/BigButton/BigButton";
import UserSearches from "../UserSearches/UserSearches";
import Extracts from "../Extracts/Extracts";
import SaveToModel from "../../../Components/UI/SaveToModel/SaveToModel";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ViewAllVariables from "../ViewAllVariables/ViewAllVariables";
import ViewAllTabulations from "../ViewAllTabulations/ViewAllTabulations";
import { useHistory, useLocation } from "react-router-dom";
import "./DevProjects.css";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { useSelector } from "react-redux";
import "./intro.css";
import { Steps } from "intro.js-react";
import SharedTag from "../SharedTag/SharedTag";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const DevProjects = (props) => {
  const [toggelSaveToModel, setToggelSaveToModel] = useState(false);
  const [loginCount, setLoginCount] = useState();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search); // id=123
  let page = params.get("page"); // 123
  const history = useHistory();

  const handleChange = (event, newValue) => {
    if (page) {
      history.push("/MyGSS");
    }
    setValue(newValue);
  };

  const authDetails = useSelector((state) => state.authReducer);
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    if (page == "tabulation") {
      setValue(3);
    } else if (page == "extract") {
      setValue(4);
    } else if (page == "variableList") {
      setValue(0);
    } else if (page == "usersearch") {
      setValue(2);
    } else if (page == "sharedtag") {
      setValue(1);
    }
  }, [page]);

  const goToSettings = () => {
    history.push("/preferences");
  };
  const [enabled, setEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);
  const introStart = () => {
    setEnabled(true);
  };
  const onExit = () => {
    setEnabled(false);
  };

  const options = [
    {
      showProgress: true,
    },
  ];

  const steps = [
    {
      title: "MyGSS",
      element: "#introduction",
      intro: "Welcome to GSS Data Explorer",
      position: "right",
    },
    {
      title: "Variables",
      element: "#step-1",
      intro:
        " View the variables you’ve added to MyGSS and use the Actions button to add them to extracts or tags",
      position: "right",
    },
    {
      title: "Tag Categories",
      element: "#step-Tag",
      intro:
        "  This section displays the tags which have been created and shared",
      position: "right",
    },
    {
      title: "User Searches",
      element: "#step-2",
      intro: "Easily access and review your saved variable searches",
    },
    {
      title: "Tabulations",
      element: "#step-3",
      intro:
        " Create new tabulations, check progress of tabulations in your queue, and access your saved tabulations here. You can also download your tabulations here or export them to third-party cloud storage services like Google Drive or Dropbox.",
    },
    {
      title: "Extracts",
      element: "#step-4",
      intro:
        "Create new extracts based on variables of your choosing, check progress of extracts in your queue, and access your saved extracts here. You can also download your extracts here or export them to third-party cloud storage services like Google Drive or Dropbox.",
    },
    {
      title: "Help and Resources",
      element: "#helpResourse",
      intro: "For More Information please visit Help and Resources",
      position: "left",
    },
  ];
  useEffect(() => {
    {
      authDetails.isSignedIn || localStorage.isSignedIn === "true"
        ? history.push("/MyGSS")
        : history.push("/home");
    }
  }, []);
  useEffect(() => {
    getUserId();
  }, []);
  const getUserId = () => {
    Axios({
      method: "get",
      url: environment.settingsURL,
    }).then((response) => {
      let data = response.data;
      if (data) {
        var userID = data.user_id;
        var login = {
          id: userID,
        };
      }
      Axios.post(`${environment.tourGuideLoginCount}`, login).then(
        (response) => {
          const dataL = response.data.Total_logins;

          setLoginCount(dataL);
        }
      );
    });
  };
  console.log("setLoginCount", loginCount);
  const loginL = localStorage.getItem("login");
  useEffect(() => {
    if (loginCount < 2 && loginL < 2) {
      localStorage.setItem("login", 2);
      introStart();
    }
  }, [loginCount]);
  return (
    <Container className="mt-5 h-100 my-gss-page">
      <Row>
        <Col xs="12" md="12" lg="12" className="mt-2">
          <Row className="mb-4">
            <Col as="h3">MyGSS</Col>
            <Col style={{ marginTop: "5px" }} className="text-right p-0">
              <BigButton
                onClick={introStart}
                classname="w-auto ml-2 mr-3 mygss-export-btn trans-btn"
              >
                Tour Guide
              </BigButton>
              <BigButton
                onClick={goToSettings}
                classname="w-auto ml-2 mr-3 mygss-export-btn trans-btn"
              >
                Settings
              </BigButton>
            </Col>
          </Row>
          <Steps
            enabled={enabled}
            steps={steps}
            initialStep={initialStep}
            onExit={onExit}
            options={options}
          />
          <Row>
            <div className={classes.root + " mx-2"}>
              <AppBar position="static" color="default">
                <Tabs
                  className="mygss-tabs"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#4f977d",
                    },
                  }}
                  aria-label="full width tabs example"
                >
                  <Tab
                    label={
                      <Tooltip
                        title={
                          <div>
                            View variables you added to MyGSS and take "actions"
                            on them.
                          </div>
                        }
                      >
                        <span id="step-1">
                          Variables{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={
                      <Tooltip
                        title={
                          <div>
                            This section displays the tags which have been
                            created and shared.
                          </div>
                        }
                      >
                        <span id="step-Tag">
                          {" "}
                          Tag Categories{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(1)}
                  />
                  <Tab
                    label={
                      <Tooltip
                        title={
                          <div>
                            This section helps to identify searchable content
                            and view your searches.
                          </div>
                        }
                      >
                        <span id="step-2">
                          {" "}
                          User Searches{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(2)}
                  />
                  <Tab
                    label={
                      <Tooltip
                        title={
                          <div>
                            This section helps to create cross tabulations based
                            on user-selected variables
                          </div>
                        }
                      >
                        <span id="step-3">
                          {" "}
                          Tabulations{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(3)}
                  />
                  <Tab
                    label={
                      <Tooltip
                        title={
                          <div>
                            This section helps to save data extracts based on
                            user-selected variables. You can also upload them to
                            third party cloud storage services, such as Google
                            Drive or Dropbox.
                          </div>
                        }
                      >
                        <span id="step-4">
                          {" "}
                          Extracts{" "}
                          <InfoIcon
                            fontSize="small"
                            className="plus-icon"
                            style={{ height: "13px" }}
                          />
                        </span>
                      </Tooltip>
                    }
                    {...a11yProps(4)}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel
                  component={"span"}
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <ViewAllVariables />
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <SharedTag />
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  <UserSearches key={3} />
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={3}
                  dir={theme.direction}
                >
                  <ViewAllTabulations />
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={4}
                  dir={theme.direction}
                >
                  <Extracts key={5} />
                </TabPanel>
              </SwipeableViews>
            </div>

            {toggelSaveToModel ? (
              <SaveToModel
                modalShow={toggelSaveToModel}
                setToggelSaveToModel={setToggelSaveToModel}
              />
            ) : (
              ""
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DevProjects;
