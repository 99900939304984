import React from 'react';
/* import { withStyles } from '@material-ui/core/styles'; */
import TabulationPopover from '../TabulationPopover/TabulationPopover';
/* import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'; */
import ExtractPopover from '../ExtractPopover/ExtractPopover';
import './CommanPopover.css'


/* const Accordion = withStyles({
  root: {
    border: '1px solid #50917E',
    color: '#ffffff',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion); */

/* const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#50917E',
    color: '#ffffff',
    borderBottom: '1px solid #50917E',
    marginBottom: -1,
    fontSize: '8px',
    minHeight: '15px',
    maxHeight: '25px',

  },

  expanded: {},
})(MuiAccordionSummary); */

/* const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: '0px',
    backgroundColor: 'white'
  },
}))(MuiAccordionDetails); */

export default function CommanPopover() {

  /* const [expanded, setExpanded] = React.useState(false); */

  /* const handleChange = (panel) => (event, isExpanded) => {
    // setExpanded(isExpanded ? panel : false);
  }; */

  return (
    <div className="CommanTab common-popover">
      <TabulationPopover />
      <ExtractPopover />
    </div>
  );
}
