import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import "./DownloadGSSDataset.css";
import axios from "axios";
import { environment } from "../../../environment";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CustomModal from "../../../Components/UI/Modal/CustomModal";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
export default function DownloadGSSDataset() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [values, setValues] = useState({
    spss: false,
    stata: false,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });
  const handleChange = (event) => {

    setValues({ ...values, [event.target.value]: event.target.checked });
  
  };
  const downloadZip = () => {
    if(values.spss || values.stata){
     
    let fileName
    if(values.spss && values.stata){
      fileName = 'SPSS_STATA'
    }else if(values.spss && !values.stata ){
      fileName = 'SPSS'
    }else if(!values.spss && values.stata){
      fileName = 'STATA'
    }else{
      fileName = null
    }
    let req = {
      fileType: fileName,
    };

    axios
      .post(environment.downloadEntireData, req)
      .then((response) => {
        window.location.href = response.data.object;
      })
      .catch((err) => {
        if (err.response) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
        } else if (err.request) {
          // alert('Some Error Occurred');
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // client never received a response, or request never left
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // anything else
        }
      });}
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };
  const setClose = () => {};
  return (
    <div>
      <CustomModal
        title={isServerError.variant}
        isOpen={isServerError.status}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>
      <div className="dataset float-lg-right float-md-right position-relative">
        <div className="title"> 
          <a
            className="download-info gss-dd-target gss-dismiss"
            data-width="320px"
            data-align="center"
            href="#"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            data-original-title=""
            title=""
          >
            <div className="description_title">
              <a href="/gss_data">
              Download the entire GSS dataset
              </a>
            </div>
          </a>
        </div>

        <div className="description">
          GSS datafiles are available in a number of different formats
          <i className="input-group-addon fa fa-download pull-right dwdIcon fa-3x"></i>
        </div>
      </div>
      {/*
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div>
          <div className="popover-content gss ">
            <div className="dd-cart">
              <div className="dd-header">
                <div className="dd-title">
                  <div className="pull-left">
                    Download the entire GSS dataset
                  </div>
                  <a
                    className="pull-right fa fa-times-circle"
                    href="#"
                    onClick={handleClose}
                  ></a>
                  <div className="clearfix"></div>
                </div>
              </div>
              <div className="dd-body narrow">
                <div className="fa-gss-norc-orange bold">
                  Warning! Large File Download
                </div>
                <p>
                  The entire GSS dataset contains over 5000 variables and 40
                  years of data. The filesize is large (over 30 MB) and may take
                  a long time to download. If you have a specific set of data
                  you're interested in, using the Extract tool will allow you to
                  choose the data relevant to your interest and reduce file
                  size.
                </p>
                <p>
                  Select the file format(s) you'd like to receive the data in:
                </p>
                <div className="gss-check-div">
                  <div className="gss-format">
                    <input
                      type="checkbox"
                      name="stata-entire"
                      id="stata-entire"
                      value="stata"
                      onChange={handleChange}
                    />
                    Stata
                  </div>
                  <div className="gss-format">
                    <input
                      type="checkbox"
                      name="spss-entire"
                      id="spss-entire"
                      value="spss"
                      onChange={handleChange}
                    />
                    SPSS
                  </div>
                </div>
              </div>
              <div className="gss-dd-footer">
                <button
                  className="btn btn-orange"
                  id="download-entire-button"
                  href="#"
                  disabled={!values.stata && !values.spss}
                  onClick={downloadZip}
                >
                  Download dataset
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popover>
      */}
    </div>
  );
}
