export const environment = {
  hostNameS3: "http://norcgss-qe.s3-website-us-east-1.amazonaws.com",
  mainSite: "http://gss.norc.org/",
  getSensitiveData:
    "http://gss.norc.org/documents/other/ObtainingGSSSensitiveDataFiles.pdf",
  captchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  departmentsURL: process.env.REACT_APP_USERMGMT_URL + "/departments",
  organizationsURL: process.env.REACT_APP_USERMGMT_URL + "/organizations",
  registerURL: "/user",
  login: "/user/signin",
  contactSnowURL: process.env.REACT_APP_NORMAL_USER_URL + "/service-now", // process.env.REACT_APP_USERMGMT_URL
  settingsURL:
    process.env.REACT_APP_NORMAL_USER_URL +
    "/user" /* process.env.REACT_APP_USERMGMT_URL */,
  tourGuideLoginCount:
    process.env.REACT_APP_NORMAL_USER_URL +
    "/user-login-info" /* process.env.REACT_APP_USERMGMT_URL */,
  viewUserDetails: process.env.REACT_APP_ADMIN_URL + "/user/viewuser",
  updateUserDetails: process.env.REACT_APP_ADMIN_URL + "/user/updateuser",
  deleteUserURL: process.env.REACT_APP_ADMIN_URL + "/user",
  getUserByEmail: process.env.REACT_APP_ADMIN_URL + "/getuserbyemail",
  createUser: process.env.REACT_APP_ADMIN_URL + "/user",
  confirmUser: process.env.REACT_APP_ADMIN_URL + "/confirm",
  adminSendConfirmation: process.env.REACT_APP_ADMIN_URL + "/resend",
  getUsers: process.env.REACT_APP_ADMIN_URL + "/users",
  userDetails: process.env.REACT_APP_ADMIN_URL + "/user/",
  emailList: process.env.REACT_APP_ADMIN_URL + "/email-list",
  downloadEntireData: process.env.REACT_APP_ADMIN_URL + "/download-data",
  isOldUser: process.env.REACT_APP_USERMGMT_URL + "/is-old-user",
  showMessage: process.env.REACT_APP_MESSAGE_URL + "/messaging",
  showMessageguest: process.env.REACT_APP_MESSAGE_URL + "/guest-messaging",
  bannerMessage: process.env.REACT_APP_MESSAGE_URL + "/update-message",
  popularVariables: process.env.REACT_APP_ADMIN_URL + "/popular-variables",
  popularVariablesAnalysis:
    process.env.REACT_APP_ADMIN_URL + "/popular-variables-analysis",
  NeverUsedExtract:
    process.env.REACT_APP_ADMIN_URL + "/unused-extract-variables",
  myGssVar: process.env.REACT_APP_ADMIN_URL + "/get-cart-variables",
  newRegisteredUserCount: process.env.REACT_APP_ADMIN_URL + "/user-report",
  getTicketTracker:
    process.env.REACT_APP_ADMIN_URL + "/user-request-get-tickets",
  getAdminUsers: process.env.REACT_APP_ADMIN_URL + "/get-admin-users",
  updateTicket:
    process.env.REACT_APP_ADMIN_URL + "/user-request-update-tickets",
  postUserRequest:
    process.env.REACT_APP_ADMIN_URL + "/user-request-insert-ticket",
  closedTicket:
    process.env.REACT_APP_ADMIN_URL + "/user-request-get-closed-tickets",
  //project management
  projectListURL: process.env.REACT_APP_PROJMGMT_URL + "/workspace/list",
  deleteProjectURL: process.env.REACT_APP_PROJMGMT_URL + "/workspace/list",
  projectSettingsURL: process.env.REACT_APP_PROJMGMT_URL + "/workspace/",
  createProject:
    process.env.REACT_APP_PROJMGMT_URL + "/workspace/create-project",
  getProjectSummary:
    process.env.REACT_APP_PROJMGMT_URL + "/workspaces/project-summary",
  addSearchComments: process.env.REACT_APP_PROJMGMT_URL + "/workspace/comments",
  getVariableByID:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/variables/details/",
  getUserVariableByID:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/variables/guest-details/",
  getVariablesList: process.env.REACT_APP_USERSRCHMGMT_URL + "/variables",
  getGuestVariablesList:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/guest-variable",
  getFilteredVariables:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/variables/search",
  getGuestFilteredVariables:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/variables/guest-search",
  //search management
  addToCart: process.env.REACT_APP_USERSRCHMGMT_URL + "/cart-item/add",
  removeFromCart: process.env.REACT_APP_USERSRCHMGMT_URL + "/cart-item/remove",
  moveCart:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/move-search",
  copyCart:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/copy-search",
  addUserSearch: process.env.REACT_APP_USERSRCHMGMT_URL + "/user-searches/add ",
  editUserSearch:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/edit-search ",
  getUserSearch:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/user-searches/list-all/",
  getSavedUserSearch:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/saved-usersearch",
  removeMyGSS: process.env.REACT_APP_USERSRCHMGMT_URL + "/remove-mygss",
  getCartItem: process.env.REACT_APP_USERSRCHMGMT_URL + "/cart-item/list-all",
  getVariableTags: process.env.REACT_APP_USERSRCHMGMT_URL + "/variable-tags",
  setCurrentProject:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/workspace/set-project",
  deleteSearchURL:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/delete-search/",
  getUserSearchSummary:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/search-summary",
  deleteSearchComment:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/delete-comment",
  editSearchComment:
    process.env.REACT_APP_USERSRCHMGMT_URL +
    "/workspace/user-searches/edit-comment",
  exportExcel: process.env.REACT_APP_ANALYSIS_URL + "/analysis/export-analysis",
  //Analysis Management
  //process.env.REACT_APP_ANALYSIS_URL+
  getAnalyses: process.env.REACT_APP_ADMIN_URL + "/analysis",
  getAdminAnalysisSummary:
    process.env.REACT_APP_ADMIN_URL + "/analysis/summary",
  getAnalysisSummary: process.env.REACT_APP_ANALYSIS_URL + "/analysis-summary",
  getExportAnalysis: process.env.REACT_APP_ANALYSIS_URL + "/get-extracts",
  createAnalysis: process.env.REACT_APP_ANALYSIS_URL + "/create-analysis",
  getCase:
    process.env.REACT_APP_ANALYSIS_URL + "/create-analysis/get-case?varId=",
  getUserTabulations:
    process.env.REACT_APP_ANALYSIS_URL + "/get-analysis-tabulation",
  removeAnalysis: process.env.REACT_APP_ANALYSIS_URL + "/delete-analysis",
  uploadToDrive: process.env.REACT_APP_STORAGE_URL + "/upload-to-drive",
  //Extract Management
  addCartItem: process.env.REACT_APP_USERSRCHMGMT_URL + "/cart-item/add",
  getExtractCartItems:
    process.env.REACT_APP_USERSRCHMGMT_URL + "/cart-item/list-all",
  getExtracts: process.env.REACT_APP_ADMIN_URL + "/extract-history",
  getUserExtracts: process.env.REACT_APP_EXTRACT_URL + "/get-user-extract",
  getExtractSummary: process.env.REACT_APP_EXTRACT_URL + "/get-extract-summary",
  downloadExtract: process.env.REACT_APP_EXTRACT_URL + "/download-extract",
  removeExtract: process.env.REACT_APP_EXTRACT_URL + "/remove-extract",
  createExtract: process.env.REACT_APP_EXTRACT_URL + "/create-extract",
  //Messages
  yearSelected:
    "Year is selected as the default column variable because most analyses should be conducted by year. Not including a year as a column variable will result in a pooled analysis.",
  multiCrossTabYears:
    "Multi-level Tabulations should be calculated for a single year at a time. Selecting multiple years will result in pooled analysis across years.",
  //Tag Managment Start
  createTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/create-tag",
  deleteTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/delete-tag",
  shareTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/share-tag",
  addToTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/add-to-tag",
  copyToDiffTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/copy-to-diff-tag",
  moveToTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/move-to-diff-tag",
  removeTag: process.env.REACT_APP_USERSRCHMGMT_URL + "/remove-tag",
  /* deleteTag: process.env.REACT_APP_USERSRCHMGMT_URL + '/delete-tag', 
    shareTag: process.env.REACT_APP_USERSRCHMGMT_URL + '/share-tag',*/
  getMyTags: process.env.REACT_APP_USERSRCHMGMT_URL + "/get-my-tags",
  getSharedTags: process.env.REACT_APP_USERSRCHMGMT_URL + "/get-shared-tags",
  getEmailTags: process.env.REACT_APP_USERSRCHMGMT_URL + "/get-email-tags",
  //Tag Managment End
  //Testing API start
  testingDataload: process.env.REACT_APP_ADMIN_URL + "/dataload-autometion",
  testingDataload1:
    process.env.REACT_APP_ADMIN_URL +
    "/dataload-autometion-moduleinfo-groupstable",
  testingDataload2:
    process.env.REACT_APP_ADMIN_URL + "/dataload-automation-gss-stats-info",
  //Testing API end
  //Trends
  getTrends: process.env.REACT_APP_TRENDS_URL + "/get-trends",
  getMeasures: process.env.REACT_APP_TRENDS_URL + "/get-measures",
  getMeasureInfo: process.env.REACT_APP_TRENDS_URL + "/get-trend-var-info",
  getTrendsAbout: process.env.REACT_APP_TRENDS_URL + "/get-trends-about",

  getChartData: process.env.REACT_APP_TRENDS_URL + "/get-chart-data",
  exportTrendsExcel: process.env.REACT_APP_TRENDS_URL + "/export-trends-excel",
  exportTrendsPDF: process.env.REACT_APP_TRENDS_URL + "/export-trends-pdf",
  exportTrendsCSV: process.env.REACT_APP_TRENDS_URL + "/export-trends-csv",
  //Trends End
  //Media
  gssTrendReport: process.env.REACT_APP_REPORTS_URL + "/gss-trend-report",
  reportDatabase: process.env.REACT_APP_REPORTS_URL + "/report-database",
  docsList: process.env.REACT_APP_REPORTS_URL + "/list-documents",
  getMediaCategories: process.env.REACT_APP_REPORTS_URL + "/category-list",
  downloadMediaDoc: process.env.REACT_APP_REPORTS_URL + "/display-document",
  //Media end
  //Text Messages
  contactMsg:
    " Thank you for your question/feedback. You will receive a confirmation email from our support team and we will contact you soon.",
  analysisSubtitle:
    "GSS Data Explorer makes it easy to analyze GSS data. Select variables and then choose an existing expression or build your own. You can download your tabulations as Excel or PDF files or create data extracts.",
  weightTextLine1:
    "The GSS Data Explorer uses the weight WTSSPS by default, as this works for most samples and domains of interest, but you can select different weights. Please see the",
  weightTextLine2:
    "page for more detailed guidance on which weights to use to analyze the full range of GSS samples.",
  cartListQues:
    "Use the checkboxes to select multiple variables to drag and drop together",
  colVarLimit:
    "You can specify a maximum of 5 column variables for multi-level tabulation.",
  weightGenerate:
    "The GSS Data Explorer generates standard errors using the VSTRAT and VPSU variables. Complex Standard Errors are only valid for 1975 onward. For more information on standard error calculation, please see",
  weightGenerateAnchor:
    "Calculating Design-Corrected Standard Errors for the General Social Survey.",
  myGssDefault:
    "The variables here are your default variables. Add more with the Keyword Search to make the most of GSS",
};
