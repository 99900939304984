import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Col, Card, Row, Table } from "react-bootstrap";
import BigButton from "../../Components/UI/BigButton/BigButton";
import CommonReportDatabase from "../../Components/UI/CommonReportDatabase/CommonReportDatabase";
import "./MediaRoom.css";
import { useHistory } from "react-router";
import { environment } from "../../environment";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { DownloadMediaDoc } from "../../Store/utils/common.utils";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const MediaRoom = () => {
  const [value, setValue] = useState();
  const [trendsReport, setTrendsReport] = useState([]);


  const history = useHistory();

  const handleChange = async (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    getGssTrendReport();
  }, []);

  const navigateToSearch = () => {
    history.push(`/variables/vfilter?keyword=` + value);
  };

  const getGssTrendReport = () => {
    axios.get(`${environment.gssTrendReport}`).then((res) => {
      if (res?.data?.trend_doc_list) {
        setTrendsReport(res?.data?.trend_doc_list);
      } else {
        setTrendsReport([]);
      }
    });
  };



  return (
    <>
      <div className="gss-media-room">
        <>
          <div>
            <div className="gss-large-title unaligned_title">Media Room</div>
          </div>
        </>

        <div className="my-3">
          <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
              <p className="left10 details my-1 mx-4 p-1">
                Since 1972, the General Social Survey (GSS) has been monitoring
                societal change, studying the growing complexity of American
                society. It’s one of the most influential studies in the social
                sciences, and is frequently referenced in leading publications,
                including the New York Times, the Wall Street Journal, and the
                Associated Press. The GSS media room provides insight into
                subjects covered in the GSS and access to experts who can help
                you analyze GSS data to answer your key questions.
              </p>
              <div
                className="Container my-1 mx-4 p-4 "
                style={{ backgroundColor: "#515151", color: "#cccccc" }}
              >
                <Typography variant="h5" className="help-quest-title">
                  Explore Questions
                </Typography>
                <p className="help-quest-details">
                  The GSS asks Americans about a range of subjects, including
                  national spending priorities, marijuana use, crime, race
                  relations, and confidence in institutions. Here, you can find
                  the original questions by keyword or by subject.
                </p>

                <Row lg={12}>
                  <Col sm={12} md={12} lg={12} xl={12}>
                    <div className="sub-title">
                      Find original questions by keyword:
                    </div>
                    <Row>
                    <Col sm={8} md={8} lg={8} xl={8}>
                      <input
                        type="text"
                        className="search-text form-control"
                        placeholder="Enter a Keyword"
                        value={value}
                        onChange={handleChange}
                      />
                       </Col>
                       <Col sm={2} md={2} lg={2} xl={2}>
                        <BigButton
                        classname="btn-orange width-unset float-left search-button"
                        onClick={navigateToSearch}
                        >
                        {" "}
                        <i className="fa fa-search"></i>
                      </BigButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              <Row className="m-0 pl-2">
                <Col sm={12} md={6} lg={6} xl={6} className="p-0" >
                  <div
                    className="card my-3 mx-3  border-1"
                    style={{ height: "488px" }}
                  >
                    <div className="card-header" style={{ color: "#73a492" }}>
                      <span className="icon fa fa-book"></span>
                      <NavLink className="green-link" to={"/gssReports/10"}>
                        <b>Recent GSS Trend Reports</b>
                      </NavLink>
                      &nbsp;
                      <Tooltip
                title={
                  <div>
                     Reports analyzing topical trends in GSS data
                  </div>
                }
              >
                <InfoIcon fontSize="small" style={{"height":'14px'}} className="plus-icon" />
              </Tooltip>
                    </div>

                    <div className="card-body">
                      {trendsReport.map((data) => (
                        <p className="help-bottom" key={data.id}>
                          <a
                            className=""
                            onClick={() => DownloadMediaDoc(data.id)}
                          >
                            {data.name}
                          </a>
                        </p>
                      ))}
                      <div className='text-right'>
                        <NavLink className="green-link" to={"/gssReports/10"}>
                          <b>VIEW ALL</b>
                        </NavLink>
                        &nbsp;<Tooltip
                title={
                  <div>
                   View all GSS reports
                  </div>
                }
              >
                <InfoIcon fontSize="small" style={{"height":'14px'}} className="plus-icon" />
              </Tooltip>
                      </div>
                      <br />
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xl={6} className="p-0">
                  <CommonReportDatabase />
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} lg={4} xl={4}>
              <div className=" mx-4 pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Want to learn more about the GSS?</h5>
                <p>
                  Read about the GSS, including its history, principal
                  investigators, and other related surveys, at NORC’s main GSS
                  website.
                </p>
                <a href={environment.mainSite} style={{"color": "white"}}>  <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  

                >
                 MAIN GSS WEBSITE
                </BigButton></a>
              </div>

              <div className=" mx-4 pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and
                  how to use the data.
                </p>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  eleType="link"
                  url="/FAQ"
                >
                  VIEW FAQ
                </BigButton>
              </div>
              <div className="mx-4 pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Does the GSS data set include geocoded information?</h5>
                <p>
                  Learn how to access GSS geocoded and other sensitive data.
                </p>
                <a target="_blank"  href={environment.getSensitiveData}>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                >
                SENSITIVE GSS DATA
                </BigButton>
                </a>
              </div>
              <div className="mx-4 pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>Submit your question or issue to the experts at GSS.</p>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  eleType="link"
                  url="/user/contact/"
                >
                  ASK AN EXPERT
                </BigButton>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default MediaRoom;
