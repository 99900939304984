import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { TextField, Tooltip } from "@material-ui/core";
import { Accordion, Card, Button, Dropdown } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import axios from "axios";
import SelectableContext from "react-bootstrap/SelectableContext";
import Error from "@material-ui/icons/Error";
import { Checkbox } from "@material-ui/core";
import Loader from "../../../Components/UI/Loader/Loader";
import { environment } from "../../../environment";
import ErrorIcon from "@material-ui/icons/Error";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import "./Extracts.css";
import { dateTimeFormat } from "../../../Store/utils/common.utils";
import { useDispatch } from "react-redux";
import { setExtractName } from "../../../Store/actions";
import SaveToCloud from "../../../Components/UI/SaveToCloud/SaveToCloud";
import InfoIcon from "@material-ui/icons/Info";

const Extracts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [extracts, setExtracts] = useState({ queue: [], completed: [] });
  const [rows, setRows] = useState(extracts);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [uploadDataModel, setUploadDataModel] = useState(false);
  const [signedInUser, setSignedInUser] = useState();

  const handleSelectAllClick = () => {
    let holdData = extracts.completed;
    if (!selectAll) {
      const newSelecteds = holdData.map((n) => n.extract_id);
      setSelected(newSelecteds);
      setSelectAll(!selectAll);
      return false;
    }
    setSelectAll(!selectAll);
    setSelected([]);
  };

  const getAllData = (callingAgain = null) => {
    setIsLoading(true);
    axios
      .post(environment.getUserExtracts)
      .then((response) => {
        if (response.data) {
          console.log(response.data.final_response);
          setExtracts(response.data.final_response);
          setRows(response.data.final_response);
          setIsLoading(false);
          setError(null);
          setSelected([]);
          setSelectAll(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.message);
        }
      });
  };

  const removeExtract = (reqPayload) => {
    if (window.confirm("Are you sure you want to remove?")) {
      let extract_id_list = { extract_id_list: reqPayload };
      axios
        .post(environment.removeExtract, extract_id_list)
        .then((response) => {
          console.log(response);
          if (response.status) {
            getAllData();
          }
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
          }
        });
    }
  };

  const retryExtract = (extract_id) => {
    let extract_id_obj = { retry_flag: 1, extract_id };
    axios
      .post(environment.createExtract, extract_id_obj)
      .then((response) => {
        console.log(response);
        if (response.status) {
          getAllData();
        }
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleCheck = (e, id) => {
    let selected_data = [...selected];
    if (e.target.checked) {
      selected_data.push(id);
    } else {
      let index = selected_data.indexOf(id);
      selected_data.splice(index, 1);
    }

    setSelected(selected_data);
  };

  const deleteCheckedCompletedData = () => {
    let extractIds = [...selected];
    if (extractIds?.length > 0) {
      removeExtract(extractIds);
    } else {
      alert("At least 1 Extract needs to be selected");
    }
  };

  const downloadCheckedCompletedData = () => {
    let extract_ids = [...selected];
    if (extract_ids?.length > 0) {
      if (extract_ids?.length > 5) {
        alert("Exceeds the maximum 5 selection limit");
        return;
      }
      axios
        .post(
          environment.downloadExtract,
          { extract_ids }
          // { extract_ids: [67497, 67495] }
        )
        .then((response) => {
          setSelected([]);
          window.location.href = response.data.objectUrl;
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
          }
        });
    } else {
      alert("At least 1 Extract needs to be selected");
    }
  };

  return (
    <Container className="mt-4 h-100">
      <Row>
        <Col className="p-0">
          <span className="float-right mr-5">
            <Button
              onClick={getAllData}
              variant="transperent"
              className="px-2 py-1 mygss-export-btn"
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>&nbsp;Refresh
            </Button>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12" lg="12">
          <AccordionComponent
            title="Queue"
            data={rows?.queue}
            isLoading={isLoading}
            error={error}
            onRetry={getAllData}
            removeExtract={removeExtract}
            retryExtract={retryExtract}
          />
          <AccordionComponent
            title="Completed"
            data={rows?.completed}
            isLoading={isLoading}
            error={error}
            onRetry={getAllData}
            handleCheck={handleCheck}
            selected={selected}
            handleSelectAllClick={handleSelectAllClick}
            selectAll={selectAll}
            deleteCheckedCompletedData={deleteCheckedCompletedData}
            downloadCheckedCompletedData={downloadCheckedCompletedData}
            setUploadDataModel={setUploadDataModel}
          />
        </Col>
      </Row>
      {uploadDataModel && (
        <SaveToCloud
          modalShow={uploadDataModel}
          selected={selected}
          setSelected={setSelected}
          setModalShow={setUploadDataModel}
          setSignedInUser={setSignedInUser}
        />
      )}
    </Container>
  );
};

export default Extracts;

function ContextAwareToggle({ eventKey }) {
  const [expand, setExpand] = React.useState(true);

  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    setExpand(!expand)
  );

  if (expand) {
    return <ExpandMoreIcon onClick={decoratedOnClick} />;
  }
  return <ChevronRightIcon onClick={decoratedOnClick} />;
}

function AccordionComponent(props) {
  const [loader, setLoader] = useState(props.isLoading);
  const [title, setTitle] = useState(props.title);
  const [data, setData] = useState(props.data);
  const [error, setError] = useState(props.error);

  useEffect(() => {
    setLoader(props.isLoading);
    setTitle(props.title);
    setData(props.data);
    setError(props.error);
  }, [props]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{ fontSize: 10 }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  const history = useHistory();

  const createExtract = () => {
    history.push("/createExtract");
  };

  const clickExport = () => {
    if (props.selected?.length > 5) {
      alert("Exceeds the maximum 5 selection limit");
    } else {
      props.setUploadDataModel(true);
    }
  };

  return (
    <Row className="ml-1 mt-2">
      <Accordion defaultActiveKey="0" className="w-100 my-3">
        <ContextAwareToggle eventKey="0"></ContextAwareToggle>
        <span className="grid-heading">{title}</span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span className="grid-heading-cnt">{data?.length} items</span>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {title == "Queue" ? (
          <span className="float-right mr-5">
            <Button
              variant="secondary"
              className="px-2 py-1 mygss-export-btn"
              onClick={createExtract}
            >
              Create Extract
            </Button>
          </span>
        ) : (
          ""
        )}
        {title != "Queue" ? (
          <span className="float-right mr-5">
            <Button
              variant="transperent"
              className="px-2 py-1 mb-1 mygss-export-btn  mr-2"
              disabled={!props.selected?.length}
              onClick={() => clickExport()}
            >
              <Tooltip
                interactive
                title={
                  <div>
                    Allows you to export your completed extracts to third party
                    cloud storage services (Google Drive or Dropbox).
                  </div>
                }
              >
                <InfoIcon
                  style={{ fontSize: "16px", marginRight: "6px" }}
                  fontSize="small"
                  className="plus-icon"
                />
              </Tooltip>
              Export
            </Button>
            <SelectableContext.Provider value={false}>
              <Dropdown className="d-inline-block custom-ddl bp-2">
                <Dropdown.Toggle variant="light" className="mygss-action-btn">
                  <Tooltip
                    interactive
                    title={
                      <div>
                        <strong>Select/Deselect All</strong>
                        <ul>
                          <li>
                            Allows you to select/deselect all the extracts
                            listed.
                          </li>
                        </ul>
                        <strong>Download</strong>
                        <ul>
                          <li>
                            Allows you to download extract/s summary in Excel
                            format.
                          </li>
                        </ul>
                        <strong>Remove from MyGSS</strong>
                        <ul>
                          <li>
                            Allows you to delete extract/s from MyGSS dashboard.
                          </li>
                        </ul>
                      </div>
                    }
                  >
                    <InfoIcon
                      style={{ fontSize: "16px", marginRight: "6px" }}
                      fontSize="small"
                      className="plus-icon"
                    />
                  </Tooltip>
                  <span>Actions</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={props.handleSelectAllClick}>
                    {props.selectAll ? `Deselect All` : `Select All`}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={props.downloadCheckedCompletedData}
                    disabled={!props.selected?.length}
                  >
                    Download
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={props.deleteCheckedCompletedData}
                    disabled={!props.selected?.length}
                  >
                    Remove From MyGSS
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </SelectableContext.Provider>
          </span>
        ) : (
          ""
        )}
        <Accordion.Collapse eventKey="0">
          <Card.Body className="tab-card-body">
            {title == "Queue" ? (
              loader ? (
                <Loader />
              ) : error == "" || error == null ? (
                <>
                  {data?.length > 0 && (
                    <ExtractQueueList
                      data={data}
                      removeExtract={props.removeExtract}
                      retryExtract={props.retryExtract}
                    />
                  )}
                  {data?.length === 0 && (
                    <Col xs="12" md="12" lg="12" className="text-center my-2">
                      <div className="mx-1 p-2 gss-no-data">
                        No extracts in the queue
                      </div>
                    </Col>
                  )}
                </>
              ) : (
                <Container>
                  <Row className="justify-content-md-center">
                    <p>Something went wrong. Try Again!!</p>
                  </Row>
                  <Row className="justify-content-md-center">
                    <button onClick={props.onRetry}>Try Again</button>
                  </Row>
                </Container>
              )
            ) : loader ? (
              <Loader />
            ) : error == "" || error == null ? (
              <>
                {data?.length > 0 && (
                  <ExtractCompletedList
                    data={data}
                    selected={props.selected}
                    handleCheck={props.handleCheck}
                  />
                )}
                {data?.length === 0 && (
                  <Col xs="12" md="12" lg="12" className="text-center my-2">
                    <div className="mx-1 p-2 gss-no-data">No extracts</div>
                  </Col>
                )}
              </>
            ) : (
              <Container>
                <Row className="justify-content-md-center">
                  <p>Something went wrong. Try Again!!</p>
                </Row>
                <Row className="justify-content-md-center">
                  <button onClick={props.onRetry}>Try Again</button>
                </Row>
              </Container>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </Row>
  );
}

function ExtractQueueList(props) {
  const retry = (extract_id) => {
    //call retry extracts api to retry queue data
    props.retryExtract(extract_id);
  };

  const remove = (extract_id) => {
    // console.log("remove row===>", extract_id);
    //call remove extracts api to remove queue data
    // removeExtract([extract_id]);D
    props.removeExtract([extract_id]);
  };

  return (
    <ListGroup>
      {props.data.map((queue, index) => (
        <Container fluid key={index}>
          <Row>
            <Col
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              xl={0.5}
              className="d-flex align-items-center invisible"
            >
              <Checkbox
                inputProps={{ "aria-labelledby": "enhanced-table-checkbox-1" }}
              />
            </Col>
            <Col>
              <ListGroup.Item className="d-flex justify-content-md-between">
                {queue.status.toLocaleLowerCase() === "failed" ? (
                  <>
                    <span className="tab-queue-list-title">
                      {/* {queue.status != 'Processing' ? <Error style={{color:"red"}}/>: <></>} */}
                      <ErrorIcon
                        className="mr-2 align-top cursor-default"
                        style={{ fontSize: "2rem" }}
                      />
                      <span style={{ display: "inline-block" }}>
                        {queue.extract_name}
                        <br />
                        <span className="tab-err-msg">{queue.message}</span>
                      </span>
                    </span>
                    <span className="d-flex align-items-center">
                      <a
                        className="tabulations-actions"
                        style={{ textDecoration: "underline" }}
                        onClick={() => retry(queue.extract_id)}
                      >
                        Retry
                      </a>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        className="tabulations-actions"
                        style={{ textDecoration: "underline" }}
                        onClick={() => remove(queue.extract_id)}
                      >
                        Remove
                      </a>
                    </span>
                  </>
                ) : queue.status.toLocaleLowerCase() === "submitted" ||
                  queue.status.toLocaleLowerCase() === "started" ? (
                  <>
                    <span>
                      <AutorenewIcon
                        style={{
                          backgroundColor: "#B98A13",
                          borderRadius: "50%",
                          color: "white",
                          fontSize: "1.9rem",
                        }}
                        className="mr-2 cursor-default"
                      />
                      {queue.extract_name}
                    </span>
                    <span className="tabulations-actions-process d-flex align-items-center">
                      Processing
                    </span>
                  </>
                ) : (
                  <span>{queue.extract_name}</span>
                )}
              </ListGroup.Item>
            </Col>
          </Row>
        </Container>
      ))}
    </ListGroup>
  );
}

function ExtractCompletedList(props) {
  const [data, setData] = useState(props.data);
  const dispatch = useDispatch();
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const onExtractClick = (name) => {
    dispatch(setExtractName(name));
  };
  return (
    <ListGroup>
      <p
        style={{
          fontSize: "13px",
          fontWeight: "bold",
          border: "1px solid rgba(0, 0, 0, .125)",
          padding: "10px",
          margin: "0 15px 10px"
        }}
      >
        <abbr>Note: </abbr> Newly created extracts will be available here for 14
        days, at which point they will be deleted. If you wish to save your
        extract beyond 14 days, please export it to a location of your choosing
        or save it externally.
      </p>
      {data.map((completed, index) => (
        <Container fluid key={index}>
          <Row>
            <Col
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              xl={0.5}
              className="d-flex align-items-center"
            >
              <Checkbox
                name="extract_completed"
                value={completed.extract_id}
                // defaultChecked={completed.isSelected}
                checked={props.selected.indexOf(completed.extract_id) !== -1}
                onChange={(e) => props.handleCheck(e, completed.extract_id)}
              />
            </Col>
            <Col>
              <ListGroup.Item className="tab-list-group-item">
                <Link
                  className="no-decoration"
                  to={`/extract_summary/${completed.extract_id}?back=extract`}
                  onClick={() => onExtractClick(completed.extract_name)}
                >
                  <span className="tab-list-title">
                    {completed.extract_name}
                  </span>
                </Link>
                <br />
                <span className="tab-list-desc">
                  Created on {dateTimeFormat(completed.created_at)} by{" "}
                  {completed.creator_name}
                </span>
              </ListGroup.Item>
            </Col>
          </Row>
        </Container>
      ))}
    </ListGroup>
  );
}
