import React from 'react';
import { Container, Row } from "react-bootstrap";
import gssLogo from '../../../Images/gss.png';
import norcLogo from '../../../Images/NORC.png';
import { dateFormat } from "../../../Store/utils/common.utils";
import { useSelector } from 'react-redux';
import './AnalysisExport.css';
import { useLocation } from 'react-router';

const AnalysisExport = () => {
    const analysisDetails = useSelector((state) => state.analysisReducer);
    const search = useLocation().search;
    const isPrint = new URLSearchParams(search).get('print');
    const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
    const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2021]
    const CamelCase =(str) =>{
        if(typeof str == 'string')
       { return str.charAt(0).toUpperCase() + str.slice(1).split('(').join(' (');}
       else{
         return str
       }
      }

      const Capitalize= (str)=>{
        // return str.charAt(0).toUpperCase() + str.split('(').join(' (');
        if (str.indexOf('(') > -1){
          return str.split('(')[0].toUpperCase()+' ('+str.split('(')[1]}
      else{
         return str.toUpperCase()
        }
        }

        const replicateArray = (array, n) => {
            // Create an array of size "n" with undefined values
            var arrays = Array.apply(null, new Array(n));
        
            // Replace each "undefined" with our array, resulting in an array of n copies of our array
            arrays = arrays.map(function () {
              return array;
            });
        
            // Flatten our array of arrays
            return [].concat.apply([], arrays);
          };
    return (
    <div className="container-fluid pt-3 pl-0 pr-0" style={{backgroundColor: 'white'}}>
    <div  className= "export" >
        <img src={gssLogo} style={{ height: 45 }} alt=""/>
        <img src={norcLogo} style={{ height: 45 }} alt=""/>
    </div>    
    <div className="noscroll analysis" id="analysisSummary">
        <div className="gss-center-analysis index ">            
        <Container fluid className="analysis_info">
        <Row className="h4 hb" style={{ color: "#666666" }}>
          <div className="col customize-summary-value pl-0">
            <strong>
              {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.analysysName
              ? JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.analysysName
              : analysisDetails?.analysis_data?.analysis_data?.analysisName}
            </strong>
          </div>
          <button className="btn btn-gray hb rounded-0 font-weight-bold mr-3" style={{display:isPrint?"block":"none"}} id="print_link" onClick={()=> window.print()}>
                            <i className="fa fa-print"></i>
                          </button> 
          <div className="extract_links">
            {JSON.parse(localStorage.getItem('analysisSummaryData'))?.status == "Started" ? (
              <span className="action">
                {" "}
                <i
                  className="fa fa-refresh fa-spin"
                  title="The analysis is running."
                ></i>
              </span>
            ) : (
              ""
            )}
          </div>
        </Row>
        <hr />
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.col && (
          <Row className="param">
          <div className="customize-summary-details"><strong>{(analysisDetails?.analysis_data?.analysis_data?.type == 'Crosstab') ? 'Column' : 'Columns'}</strong></div>
                  
          <div className="col customize-summary-value pl-1 text-break">{CamelCase(JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.col)}</div>
        </Row>
        )}
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.row && (
          <Row className="param">
          <div className="customize-summary-details"><strong>Rows</strong></div>
                
          <div className="col customize-summary-value pl-1 text-break">{CamelCase(JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.row)}</div>
        </Row>
        ) }
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.wght_var && (
         <Row className="param">
         <div className="customize-summary-details"><strong>Weight var</strong></div> 
              
         <div className="col customize-summary-value pl-1 text-break">{JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.wght_var.toUpperCase()}</div>
       </Row>
        )}
         {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.caseSelection && (
           <Row className="param">
           <div className="customize-summary-details"><strong>Case selection</strong></div>     
           
           <div className="col customize-summary-value pl-1 text-break">{JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.caseSelection}</div>
         </Row>
        )}
        <Row className="param">
           <div className="customize-summary-details"><strong>Sample design</strong></div>          
         
           <div className="col customize-summary-value pl-1 text-break">{JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.design ? JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.design : "None"}</div>
         </Row>
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.missing && (
          <Row className="param">
          <div className="customize-summary-details"><strong>Exclude missing</strong></div>          
         
          <div className="col customize-summary-value pl-1 text-break">Yes</div>
        </Row>
        )}
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.chi && (
          <Row className="param">
          <div className="customize-summary-details"><strong>Include chi square</strong></div>
                 
          <div className="col customize-summary-value pl-1 text-break">Yes</div>
        </Row>
        )}
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.info?.StdErr && (
         <Row className="param">
         <div className="customize-summary-details"><strong>Include standard errors</strong></div>
              
         <div className="col customize-summary-value pl-1 text-break">Yes</div>
       </Row>
        )}
      </Container>
          
        </div>
    </div> 
    {/* {JSON.parse(localStorage.getItem('analysisSummaryTbl'))(JSON.parse(localStorage.getItem('analysisSummaryData')))} */}
    <Container fluid className="">
        {JSON.parse(localStorage.getItem('analysisSummaryData'))?.data ? (
          <div className="results-container">
            <div className="clearfix">
              <div className="table-holder no-wrap customize-tbl-width">
                <div className="table-inner fixed">
                  <table className="gsstable multi">
                    <thead>
                      <tr className="header">
                          <th style={{ height: JSON.parse(localStorage.getItem('height')) }} className="ath" title={Capitalize(JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row?.headerName)}>
                            {Capitalize(JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row?.headerName)}
                          </th>
                        </tr>
                    </thead>
                    <tbody>
                     
                      {(JSON.parse(localStorage.getItem('showTotals'))
                        ? JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue
                        : JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.slice(0, -1)
                      ).map((row, i) => (
                        <tr>
                          <td className="br var_value" rowSpan="1">
                            {row}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="table-inner">
                  <table className="gsstable inner multi table-right">
                    <thead>
                      {JSON.parse(localStorage.getItem('analysisSummaryData'))?.data.col.map((colData, i) => (
                        <>
                          <tr className="header">
                            <th colSpan={JSON.parse(localStorage.getItem('colspan'))} className="border">
                              <b>{Capitalize(colData?.headerName)}</b>
                            </th>

                            {(JSON.parse(localStorage.getItem('showTotals')) && (i == 0)) && (
                              <th
                                className="bb var_value total bold border"
                                rowSpan={JSON.parse(localStorage.getItem('rowspan'))}
                              >
                                Total
                              </th>
                            )}
                          </tr>
                          <tr className="header">
                            {replicateArray(
                              colData.headerValue,
                              colData.count
                            ).map((val, j) => (
                              <>

                                <th
                                  className="bb var_value border"
                                  colSpan={
                                    JSON.parse(localStorage.getItem('colspan')) /
                                    replicateArray(
                                      colData.headerValue,
                                      colData.count
                                    ).length
                                  }
                                >
                                  {val}
                                </th>
                                
                              </>
                            ))}
                          </tr>{" "}
                        </>
                      ))}
                    </thead>
                    <tbody>
                      {(JSON.parse(localStorage.getItem('showTotals'))
                        ? JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue
                        : JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.slice(0, -1)
                      ).map((row, i) => (
                        <tr>
                          {(JSON.parse(localStorage.getItem('showTotals'))
                            ? JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count
                            : JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.slice(0, -1)
                          ).map((count, j) => (
                            <td className= {"var_value text-right border " + JSON.parse(localStorage.getItem('showTotals'))? (((j == (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.length - 1)) || ( i == (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.length - 1)))?"customize-font-weight-bold": "customize-font-weight") : "customize-font-weight"}>
                              <div className="val">
                                {JSON.parse(localStorage.getItem('show')) == "count" && count.toFixed(JSON.parse(localStorage.getItem('decimal')))}
                                {/* */}
                                {(JSON.parse(localStorage.getItem('show')) == "count" && (j < JSON.parse(localStorage.getItem('analysisSummaryData') )?.data?.counts[i].count.length - 1) && (i < (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.length - 1)))? ((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i]?.hasOwnProperty('stdErr') && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr.length > 0)? (<span>({JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j]?.toFixed(JSON.parse(localStorage.getItem('decimal')))})</span>) : ""):""} 
                                {/* */}

                                {JSON.parse(localStorage.getItem('show')) == "row_per" &&
                                  (count
                                    ? (
                                        (count /
                                            JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count[
                                                JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.length -
                                              1
                                          ]) *
                                        100
                                      ).toFixed(JSON.parse(localStorage.getItem('decimal')))
                                    : 0) + "%"}

                                    {/* */}
                                    {(JSON.parse(localStorage.getItem('show')) == "row_per" && (j < JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.length - 1) && (i < (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.length - 1)))? ((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i]?.hasOwnProperty('stdErr')  && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr.length > 0)? (<span>({(JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j]? ((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j] / JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr.length - 1]) * 100).toFixed(JSON.parse(localStorage.getItem('decimal'))) : 0) + "%"})</span>) : ""):""} 
                                    {/* */}
                                {JSON.parse(localStorage.getItem('show')) == "col_per" &&
                                  (count
                                    ? ((count / JSON.parse(localStorage.getItem('column'))[j]) * 100).toFixed(
                                        JSON.parse(localStorage.getItem('decimal'))
                                      )
                                    : 0) + "%"}

                                     {/* */}
                                     {(JSON.parse(localStorage.getItem('show')) == "col_per" && (j < JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.length - 1) && (i < (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.length - 1)))? ((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i]?.hasOwnProperty('stdErr') && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr.length > 0)? (
                                    <span>(
                                      {(JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j]? ((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j] / JSON.parse(localStorage.getItem('stdColumn'))[j])* 100).toFixed(JSON.parse(localStorage.getItem('decimal'))) : 0) + "%"} 
                                      )</span>) : ""):""} 
                                      {/* */}
                                {JSON.parse(localStorage.getItem('show')) == "total_per" &&
                                  (count
                                    ? ((count / JSON.parse(localStorage.getItem('finalTotal'))) * 100).toFixed(
                                        JSON.parse(localStorage.getItem('decimal'))
                                      )
                                    : 0) + "%"}
                                     {(JSON.parse(localStorage.getItem('show')) == "total_per" && (j < JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.length - 1) && (i < (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.row.headerValue.length - 1)))? (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i]?.hasOwnProperty('stdErr')? (
                                    <span>(
                                      {((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j] && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr && JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr.length > 0)
                                        ? ((JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j] / JSON.parse(localStorage.getItem('finalStdTotal')))* 100).toFixed( JSON.parse(localStorage.getItem('decimal'))) : 0 )+ "%"}
                                      )</span>) : ""):""} 
                                   {/*   {(j < JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].count.length - 1)? (JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i]?.hasOwnProperty('stdErr')? (<span>({JSON.parse(localStorage.getItem('analysisSummaryData'))?.data?.counts[i].stdErr[j]?.toFixed(JSON.parse(localStorage.getItem('decimal')))})</span>) : ""):""} */} 
                                     
                              </div>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Container>
    <div style={{display:'flex', flexDirection:'column'}}>
        <div className="row">
            <div className="col">
                <p style={{ fontWeight: 'bold' }}>Citation</p>
                <p>Davern, Michael; Bautista, Rene; Freese, Jeremy; Herd, Pamela; and Morgan, Stephen L.; General Social Survey 1972-2024. [Machine-readable data file]. Principal Investigator, Michael Davern; Co-Principal Investigators, Rene Bautista, Jeremy Freese, Pamela Herd, and Stephen L. Morgan. Sponsored by National Science Foundation. NORC ed. Chicago: NORC, 2024: NORC at the University of Chicago [producer and distributor]. Data accessed from the GSS Data Explorer website at gssdataexplorer.norc.org. This GSS Data Explorer output was created by {localStorage.getItem('creatorName')} on {dateFormat(localStorage.getItem('createdAt'))}.</p>
            </div>
        </div> 
    </div>
</div>   )
}

export default AnalysisExport;