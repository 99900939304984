import { NavLink } from 'react-router-dom'
import React from 'react'
import { Card } from 'react-bootstrap'
import "./FeatureCard.css"

export const FeatureCard = ({ item }) => {
    let { title, details, image, sectionLink } = item;

    return (
        <div class="row">
            <div class="col-sm-12">
        <Card className=' rounded border-0'>
            <Card.Img src={image} variant='top' className="mx-auto work-img" />

            <Card.Body>
                <NavLink to={`${sectionLink}`}>
                    <Card.Title as='h3'>
                        {title}
                        <div className="right-arrow"></div>
                    </Card.Title>
                </NavLink>

                <Card.Text as='p' dangerouslySetInnerHTML={{ __html: details }} ></Card.Text>
            </Card.Body>
        </Card>
        </div>
        </div>
    )
}
