import React ,{useEffect, useState} from 'react'
/* import PropTypes from 'prop-types' */
import './GSSWorks.css'
import { Container, Col, Row } from 'react-bootstrap'
import { FeatureCard } from '../UI/FeatureCard/FeatureCard'
import search_variables from "../../Images/search_variables.png";
import analyze_data from "../../Images/analyze_data.png";
import extract_data from "../../Images/extract_data.png";
import save_projects from "../../Images/save_projects.png";
import view_trends from "../../Images/view_trends.png";
import {useSelector } from "react-redux";

/* import { NavLink } from 'react-router-dom' */


const GSSWorks = () => {
    const authDetails = useSelector((state) => state.authReducer);

    const analyzeData = localStorage.getItem("isSignedIn") == "true" || authDetails.isSignedIn ||
    authDetails.isSocialLogin ? "/MyGSS?page=tabulation" : "/user/sign_up";

    const extractData = localStorage.getItem("isSignedIn") == "true" || authDetails.isSignedIn ||
    authDetails.isSocialLogin ? "/MyGSS?page=extract" : "/gss_data";

    let items = [{
        id: 1,
        title: "Search Variables",
        details: "Explore GSS questions, variables, and publications by subject, year, or keyword.",
        image: search_variables,
        sectionLink: "/variables/vfilter"
    }, {
        id: 2,
        title: "Analyze Data ",
        details: "Conduct basic analyses without the need for statistical software.<strong>(Account Required)</strong>",
        image: analyze_data,
        sectionLink: analyzeData,
        isLink: true
    },
    {
        id: 3,
        title: "View Trends",
        details: "Quickly view key trends and compare user responses over time with the new visualization feature.",
        image: view_trends,
        sectionLink: "/trends",
        isLink: true
    },
    {
        id: 4,
        title: "Extract Data",
        details: `Extract GSS data that can be read into statistical software packages including STATA, SAS, SPSS, and R. Or, download the entire dataset, without creating an account.`,
        image: extract_data,
        sectionLink: extractData,
        isLink: true
    }
    ]
   
    return (
        <Container className="text-center " style={{marginTop:'5%' }}>
            <Col>
            <h1 className="">{`How the GSS Data Explorer Works`}</h1>
            
            {/* <h2 className="work-h2">{`On this site, you can explore and analyze the GSS data you need, 
        create data visualizations,
         and generate custom extracts to analyze in your statistical program.`}</h2> */}
            <Row >
                
                {
                    items.map((item) => (
                        <Col className='homepage'  key={item.id} sm={12} md={12} lg={4} xl={4}>
                            <FeatureCard  item={item} key={item.id} />
                        </Col>

                    ))

                }
              
            </Row>
            </Col>
        </Container>
    )
}


export default GSSWorks
