import React from "react";
import footer_sprite from '../../Images/footer_sprite.png';
import footer_sprite_1 from '../../Images/footer_sprite_1.png';
import { NavLink } from 'react-router-dom';
import './Footer.css'
function Footer() {
  const divStyle = {
    background: 'url(' + footer_sprite + ') 0 0',
    display: "inline-block",
    backgroundPosition: "0 0",
    width: "58px",
    height: "56px",
    verticalAlign: "middle"
  };

  /* const customRight = {
    position: "absolute",
    bottom: "23px",
    left: "959px"
  }; */

  return (

    <div id="footer">

      <div id="footer_1">
        <div className="left_footer">
          <div className="footer-sprite" style={divStyle}></div>
          <span className="footer_address">1155 E. 60th Street, Chicago, IL 60637</span>
          <span className="footer_contact">
          <a href=" mailto:gsshelp@norc.org" target="_blank" style={{ color: "white", fontWeight: "bold" }}>gsshelp@norc.org</a>
          </span>
        </div>
        <div className="right_footer">
          <a href="http://www.norc.org">
            <div className="footer-sprite">
              <img className="footer-sprite1" src={footer_sprite_1} alt="bg"/>
            </div>
          </a>
        </div>
      </div>
      <div id="footer_2">
        <ul id="footer_links">
          <li><NavLink
              to='/gss_data'
              exact
            >Quick Downloads</NavLink>
            </li>
          <li>
          <NavLink to="/FAQ" exact>FAQ</NavLink>
          </li>
          <li>
            <NavLink to="/terms_and_conditions">Terms and Conditions</NavLink>
          </li>
          <li>
            <NavLink to="/privacy_policy">Privacy</NavLink>
          </li>
          <li>
            <NavLink
              to='/user/contact/'
              exact
            >Contact</NavLink>
          </li>
        </ul>
        <span id="copyright_info">
          © Copyright 2020 NORC at the University of Chicago
    <span>
            <span style={{ "position": "relative", left: "450px", bottom: "11px" }}></span>
          </span>
        </span>
      </div>

    </div>
  );
}

export default Footer;