import React, { useEffect, useRef, useState } from "react";
import DepratmentDDL from "../../../Components/UI/DepratmentDDL/DepratmentDDL";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import OrganizationDDL from "../../../Components/UI/OrganizationDDL/OrganizationDDL";
import {
  checkIsAllLetter,
  checkIsRequired,
  checkPasswordCombination,
  checkPasswordLength,
} from "../../../Shared/helpers";
import ConfirmCode from "../Confirmation/ConfirmCode/ConfirmCode";
import "./UpdateDetails.css";

export default function UpdateDetails(props) {
  const firstRender = useRef(true);
  const [disable, setDisabled] = useState(true);
  const [fields, setFields] = useState({
    firstname: "",
    lastName: "",
    organization: "",
    otherOrganization: "",
    department: "",
    otherDepartment: "",
    password: "",
    passwordConfirmation: "",
    role: "user",
    shareYourTags: true,
    optCommunication: true,
    verified: false,
    resendConfirm: false,
  });
  const [errors, setErrors] = useState({
    firstname: null,
    password: null,
    confirmPassword: null,
  });
  /* const [show, setShow] = useState(false); */
  const [isSaving, setIsSaving] = useState(false);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
  });

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]:
        name == "shareYourTags" || name == "optCommunication"
          ? e.target.checked
          : value,
    });
    let validationError = {};
    if (name == "firstname") {
      validationError = await checkIsRequired(name, value);
    }

    switch (name) {
      case "firstname":
        if (!validationError.errorStatus) {
          validationError = await checkIsAllLetter(name, value);
        }
        break;
      case "password":
        let passwordError = await checkIsRequired(name, value);
        if (passwordError.errorStatus) {
          setErrors({ ...errors, password: passwordError.errorMessage });
        } else {
          passwordError = await checkPasswordLength(name, value);
          if (passwordError.errorStatus) {
            setErrors({ ...errors, password: passwordError.errorMessage });
          } else {
            passwordError = await checkPasswordCombination(name, value);
            if (passwordError.errorStatus) {
              setErrors({ ...errors, password: passwordError.errorMessage });
            } else {
              if (
                fields.passwordConfirmation !== "" &&
                fields.passwordConfirmation !== undefined
              ) {
                if (value === fields.passwordConfirmation) {
                  setErrors({
                    ...errors,
                    password: passwordError.errorMessage,
                    confirmPassword: passwordError.errorMessage,
                  });
                } else {
                  setErrors({
                    ...errors,
                    password: passwordError.errorMessage,
                    confirmPassword:
                      "Passwords do not match, Please try again.",
                  });
                }
              } else {
                setErrors({ ...errors, password: passwordError.errorMessage });
              }
            }
          }
        }
        break;
      case "passwordConfirmation":
        let confirmPasswordError = await checkIsRequired(name, value);
        if (confirmPasswordError.errorStatus) {
          setErrors({
            ...errors,
            confirmPassword: confirmPasswordError.errorMessage,
          });
        } else {
          if (fields.password !== "" && fields.password !== undefined) {
            if (value === fields.password) {
              setErrors({
                ...errors,
                confirmPassword: confirmPasswordError.errorMessage,
              });
            } else {
              setErrors({
                ...errors,
                confirmPassword: "Password do not match, Please try again",
              });
            }
          } else {
            setErrors({
              ...errors,
              confirmPassword: confirmPasswordError.errorMessage,
            });
          }
        }
        break;
      default:
        break;
    }

    if (name == "firstname") {
      setErrors({ ...errors, [name]: validationError.errorMessage });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setDisabled(true);
    updateUserDetails();
  };

  const updateUserDetails = (e) => {};

  const checkDisabled = () => {
    for (var key in errors) if (!(errors[key] == "")) return false;

    return true;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setDisabled(!checkDisabled());
  }, [errors.firstname, errors.password, errors.confirmPassword]);

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };

  if (fields.verified) {
    let tempFields = { ...fields, username: fields.username.trim() };
    return <ConfirmCode field={tempFields} requestFrom={"RequestCode"} />;
  } else {
    return (
      <>
        <CustomModal
          title={"Error"}
          titleText="Error"
          isOpen={isServerError.status}
          setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
          inputLabel={isServerError.message}
        ></CustomModal>
        <div>
          <FormCard title="Update User Details">
            <form
              method="post"
              className="simple_form form-horizontal"
              name="updateUserForm"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="firstname" className="col-form-label">
                    First Name
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="text"
                        name="firstname"
                        id="firstname"
                        className="form-control customize-input"
                        aria-describedby="firstname"
                        value={fields.firstname}
                        onInput={handleChange}
                        autoComplete="off"
                        autoFocus
                        maxLength={35}
                        disabled={isSaving}
                      />
                      {errors.firstname ? (
                        <div className="row">
                          <div className="col errText">{errors.firstname}</div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name
                  </label>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        className="form-control customize-input"
                        aria-describedby="lastName"
                        value={fields.lastName}
                        onInput={handleChange}
                        autoComplete="off"
                        maxLength={35}
                        disabled={isSaving}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="organization" className="col-form-label">
                    Organization
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <OrganizationDDL
                        value={fields.organization}
                        displayEmpty
                        onChange={handleChange}
                        disabled={isSaving}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {fields.organization == "Other" ? (
                <div className="row customize-username-height">
                  <div className="col-xs-12 col-sm-12 col-md-4 customize-label"></div>
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <div className="row">
                      <div className="col-auto">
                        <input
                          type="text"
                          name="otherOrganization"
                          id="otherOrganization"
                          className="form-control customize-input"
                          aria-describedby="otherOrganization"
                          value={fields.otherOrganization}
                          onInput={handleChange}
                          autoComplete="off"
                          maxLength={35}
                          disabled={isSaving}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="department" className="col-form-label">
                    Department
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <DepratmentDDL
                        value={fields.department}
                        onChange={handleChange}
                        displayEmpty
                        disabled={isSaving}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {fields.department == "Other" ? (
                <div className="row customize-username-height">
                  <div className="col-xs-12 col-sm-12 col-md-4 customize-label"></div>
                  <div className="col-xs-12 col-sm-12 col-md-8">
                    <div className="row">
                      <div className="col-auto">
                        <input
                          type="text"
                          name="otherDepartment"
                          id="otherDepartment"
                          className="form-control customize-input"
                          aria-describedby="otherDepartment"
                          value={fields.otherDepartment}
                          onInput={handleChange}
                          autoComplete="off"
                          maxLength={35}
                          disabled={isSaving}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label htmlFor="password" className="col-form-label">
                    New Password
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        className="form-control customize-input"
                        aria-describedby="password"
                        value={fields.password}
                        onInput={handleChange}
                        autoComplete="off"
                        disabled={isSaving}
                      />
                      {errors.password ? (
                        <div className="row">
                          <div className="col errText">{errors.password}</div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row customize-username-height">
                <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                  <label
                    htmlFor="passwordConfirmation"
                    className="col-form-label"
                  >
                    Password Confirmation
                  </label>
                  <abbr title="required">*</abbr>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                  <div className="row">
                    <div className="col-auto">
                      <input
                        type="password"
                        name="passwordConfirmation"
                        id="passwordConfirmation"
                        className="form-control customize-input"
                        aria-describedby="passwordConfirmation"
                        value={fields.passwordConfirmation}
                        onInput={handleChange}
                        autoComplete="off"
                        disabled={isSaving}
                      />
                      {errors.confirmPassword ? (
                        <div className="row">
                          <div className="col errText">
                            {errors.confirmPassword}
                          </div>
                        </div>
                      ) : (
                        <div className="row"></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-3 justify-content-md-center">
                <div className="col-auto">
                  {isSaving ? (
                    <button type="button" className="gss-btn btn-orange mt-1">
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      &nbsp; UPDATING...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="gss-btn btn-orange mt-1"
                      variant="secondary"
                      disabled={disable}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
              </div>
            </form>
          </FormCard>
        </div>
      </>
    );
  }
}
