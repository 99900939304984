import React from "react";
import { Col, Row } from "react-bootstrap";
//import BigButton from "../../Components/UI/BigButton/BigButton";
import BigButton from "../../Components/UI/BigButton/BigButton";
//import "./MediaRoom.css";
import "./SearchVariable.css";
import { NavLink } from "react-router-dom";

import Search_1 from "../../Images/Search_1.png";
import Search_2 from "../../Images/Search_2.png";
import Search_3 from "../../Images/Search_3.png";
import Search_5 from "../../Images/Search_5.jpg";
import Search_5_1 from "../../Images/Search_5.1.jpg";

const SearchVariable = () => {
  /* const [data, setData] = useState([]); */

  return (
    <>
      <div className="gss-Searchs">
        {" "}
        <React.Fragment>
          <div>
            <div className="gss-large-title unaligned_title">
              Search Variables and Add Selected Variables to MyGSS
            </div>
          </div>
        </React.Fragment>
        <div className="my-4 mx-5 ">
          <Row>
            <Col sm={12} md={8} lg={8} xl={8}>
              <div className="Container">
                <p>
                  The GSS DE variable search feature allows you to explore
                  variables, view questions, and see basic frequencies. You can
                  search all variables in the GSS dataset and narrow your
                  results with filters for survey year and subject. As you find
                  variables, you can add them to MyGSS to be extracted or
                  tabulated later.
                </p>
              </div>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Searchs_circle">1</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>Select “Search GSS Variables” at the top of the page.</p>
                  </div>
                  <div>
                    <img
                      src={Search_1}
                      className="Searchs_media"
                      alt="Search_1"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Searchs_circle">2</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      This will take you to the Search page – from here, you
                      can search more than 5,000 GSS variables by keyword,
                      subject, tag, or year. Information on each variable
                      includes name, description, years available, survey
                      question, and summary statistics for the variable. Users
                      can gain a better understanding of the GSS by reviewing
                      the variables and the survey questions asked, as well as
                      search topics of interest. Hover over the green bars to
                      see a variable’s availability by year and which ballots it
                      appeared on. Click on the variable name to see a detailed
                      description.
                    </p>
                  </div>
                  <div>
                    <img
                      src={Search_2}
                      className="Searchs_media"
                      alt="Search_2"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Searchs_circle">3</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      Once you have identified variables of interest, add them
                      to MyGSS by clicking on the button to the right of the
                      variable name labelled “Add to MyGSS.” Once added, you can
                      then use these variables to create tabulations or custom
                      data extracts.
                    </p>
                    <div>
                      <img
                        src={Search_3}
                        className="Searchs_media"
                        alt="Search_3"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Searchs_circle">4</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                      You may also save your current variable search by
                      selecting the “Save” button next to the search bar. Your
                      saved variables and searches will appear in MyGSS
                    </p>
                    
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={12} md={1} lg={1} xl={1}>
                  <div>
                    <span className="Searchs_circle">5</span>
                  </div>
                </Col>
                <Col sm={12} md={11} lg={11} xl={11}>
                  <div>
                    <p>
                    If you want to review your saved variables, you can find all your variables by clicking MyGSS on the upper panel.
                    </p>
                    <div>
                      <img
                        src={Search_5}
                        className="Searchs_media"
                        alt="Search_5"
                      />
                    </div>
                    <p>Finally, make sure the Variables tab is selected to find your full list of saved variables.</p>
                    <div>
                      <img
                        src={Search_5_1}
                        className="Searchs_media"
                        alt="Search_5_1"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm={12} md={4} lg={4} xl={4}>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and
                  how to use the data.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100">
                  <a style={{ color: "white" }} href="/FAQ">
                    VIEW FAQ
                  </a>
                </BigButton>
              </div>

              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>Submit your question or issue to the experts at GSS.</p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact">ASK AN EXPERT</BigButton>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SearchVariable;
