import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Dialog,
  TextareaAutosize,
  InputLabel,
  Select,
  IconButton,
  Checkbox,
  MenuItem,
  FormControlLabel,
  FormControl,
  NativeSelect
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./DisplayOptions.css";
import { GreenCheckbox } from "../../../Store/utils/common.utils";
const DisplayOptions = (props) => {
  const [open, setOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isEdit, SetEdit] = useState('')
  const [show, setShow] = useState('count')
  const [decimal, setDecimal] = useState(0)
  const [isChecked, setIsChecked] = useState(true);
  React.useEffect(() => {
    // component updated Temporary Code
    if (props.isOpen && !open) {
      handleClickOpen();
      // set isOpen as false to prevent erasing your data again.
      props.setIsOpen();
      SetEdit(props.isEdit)
      if (props.isEdit) {
        setInputText(props.comment.comment)
      }
      else {
        setInputText('');
      }
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleTotals = (e) => {
    setIsChecked(!isChecked);
  }

  const handleDecimalChange = (e) => {
    setDecimal(e.target.value)
  }
  const handleShowChange = (e) => {
    setShow(e.target.value)
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      className={"custom-modal " + props.class_name}
    >
      <DialogTitle id="max-width-dialog-title" className="modal_title">
        Display options
        <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
          <i className="fa fa-times"></i>
        </IconButton>
      </DialogTitle>
      <DialogContent className="modal_content">
        {/*  <div className="modal-body" > */}
        <div className="container">
          <div className="row mb-3">
            <div className="col-1 mr-1">
              <label>Show:</label> 
            </div>
            <div className="col-5">
              <div className="gss-select with-border">
                <FormControl>
                  <NativeSelect
                    value={show}
                    onChange={handleShowChange}
                    name="show"
                    id="outlined-age-native-simple"
                  >
                    <option value="count">Count</option>
                    <option value="row_per">Row percent</option>
                    <option value="col_per">Column percent</option>
                    <option value="total_per">Total percent</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
            <div className="col"></div>
          </div>

          <div className="row">
            <div className="col-5">
              <label>Number of decimal places:</label>
            </div>
            <div className="col-5" style={{marginLeft:-30}}>
              <div className="gss-select with-border">
                <FormControl>
                  <NativeSelect
                    value={decimal}
                    onChange={handleDecimalChange}
                    name="Decimal"
                    id="outlined-age-native-simple"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
            {/* <div className="col"></div> */}
          </div>
        </div>



        {/* <Select


            label="show"
            inputProps={{
              name: 'show',
              id: 'outlined-age-native-simple',
            }}
            value={show}
            onChange={handleShowChange}
          >

            <MenuItem value="count">Count</MenuItem>
            <MenuItem value="row_per">Row percent</MenuItem>
            <MenuItem value="col_per">Column percent</MenuItem>
            <MenuItem value="total_per">Total percent</MenuItem>
          </Select> */}
       
        
        
        {/* <Select


            label="Decimal"
            inputProps={{
              name: 'Decimal',
              id: 'outlined-age-native-simple',
            }}
            value={decimal}
            onChange={handleDecimalChange}
          >
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
          </Select> */}



        {/* </div> */}

        <div style={{ "display": "flex" }}>
          <div className="form-check">
            <FormControlLabel
              control={
                <GreenCheckbox
                  checked={isChecked}
                  onChange={handleTotals}

                />
              }
              label="Show totals"

            /* style={{cursor: isLoggedIn? "not-allowed" : "pointer" }} */

            />
          </div>

          {/* <input
          type="checkbox"
          style={{"margin": "5px 4px 0px 15px"}}
          checked={isChecked}
          onChange={handleTotals}       
        />  <label >Show totals</label> */}
        </div>
      </DialogContent>

      <DialogActions className="modal_footer">
        <div className="col-md-6 btn-one" onClick={() => {
          setOpen(false);
          props.handleSubmit(show, decimal, isChecked);

        }}>
          <Button classes={{ label: "btn-orange primary-link" }}>
            Apply
          </Button>
        </div>
        <div className="col-md-6 btn-two" onClick={handleClose}>
          <Button color="#666666">
            CANCEL
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default DisplayOptions;

DisplayOptions.protoTypes = {};
