import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import gssLogo from '../../../Images/gss.png';
import norcLogo from '../../../Images/NORC.png';
import TableauEmbed from '../../TableauContainer/TableauEmbed';

export const TrendsPrint = (props) => {

    /* useEffect(()=>{
        return () => {
            
            localStorage.removeItem('dropdownvalue');
            localStorage.removeItem('breakdropdownvalue');
            localStorage.removeItem('url');
        }
    },[]); */
    const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
    const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2018]
    function loadChart() {
        return (
            <img src={JSON.parse(localStorage.getItem('url'))}/>
        );
    }

    return (
        <div className="container-fluid pt-3"  style={{backgroundColor: 'white'}}>
                <div className="mt-3"  style={{display:'flex', justifyContent:'space-between', padding: "0 2%"}}>
                    <img src={gssLogo} style={{ height: 40 }} />
                    <img src={norcLogo} style={{ height: 40 }} />
                </div>   
                
                <div className="row mt-2">
                    <div className="col">
                        <p className="m-0 param"><b>Response:</b><span>{JSON.parse(localStorage.getItem('dropdownvalue'))}</span></p>
                        <p className="m-0 param"><b>Breakdown:</b><span>{JSON.parse(localStorage.getItem('breakdropdownvalue'))}</span></p>
                    </div>
                </div>  

                <div className="row">
                    <div className="col">
                        {loadChart()}
                    </div>
                </div>                

                <div style={{display:'flex', flexDirection:'column'}}>
                    <div className="row mt-3">
                        <div className="col">
                            <p className="param" style={{ fontWeight: 'bold' }}>Citation</p>
                            <p className="param" >Smith, Tom W., Davern, Michael, Freese, Jeremy, and Morgan, Stephen, General Social Surveys, {years[0]}-{years[1]} [machine-readable data file] /Principal Investigator, Smith, Tom W.; Co-Principal Investigators, Michael Davern, Jeremy Freese, and Stephen Morgan; Sponsored by National Science Foundation. --NORC ed.-- Chicago: NORC, {years[1]}: NORC at the University of Chicago [producer and distributor]. Data accessed from the GSS Data Explorer website at gssdataexplorer.norc.org(http://gssdataexplorer.norc.org)</p>
                        </div>
                    </div> 
                </div>
            </div>             
    )
}