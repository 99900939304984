import React from 'react'
import { Button } from 'react-bootstrap'

import './AccordianButton.css'

const AccordianButton = (props) => {
    return (
        <>
            <Button {...props} >
                {props.children}
            </Button>
        </>
    )
}

export default AccordianButton
