import React, { useState, useEffect } from 'react';
import { Accordion, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

function ContextAwareToggle({ eventKey }) {
    const [expand, setExpand] = useState(false);

    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        setExpand(!expand)
    );

    if (expand) {
        return <ExpandMoreIcon onClick={decoratedOnClick} style={{ color: "#6DA08C" }} />;
    }
    return <ChevronRightIcon onClick={decoratedOnClick} style={{ color: "#6DA08C" }} />;
}

const AssociatedQuestions = (props) => {

    useEffect(() => {
        if (props.isExpanded) {
            const getCollapsedAccordionSections = document.getElementsByClassName(
                "collapse"
            )
            for (let i = 0; i < getCollapsedAccordionSections.length; i++) {
                getCollapsedAccordionSections[i].classList.add("show")
            }
        } else {
            const getCollapsedAccordionSections = document.getElementsByClassName(
                "collapse"
            )
            for (let i = 0; i < getCollapsedAccordionSections.length; i++) {
                getCollapsedAccordionSections[i].classList.remove("show")
            }
        }
    }, [props.isExpanded]);

    return (
        <Accordion>
            <ContextAwareToggle eventKey="0"></ContextAwareToggle>
            <span style={{ color: "#6DA08C" }}>Associated Questions</span>
            <Accordion.Collapse eventKey="0">
                <Card.Body className="tab-card-body">
                    <div className="row">
                        <div className="col">
                            <div className="font-weight-bold">Survey Question</div>
                            <div className="gs-surveyques">
                                {props.variable && props.variable.surveyQuestion.map((question) => (
                                    <React.Fragment>
                                        {question} <br />
                                    </React.Fragment>
                                ))}
                                {(props.variable && (props.variable.surveyQuestion.length == 0 || props.variable.surveyQuestion[0] == null)) && (
                                    <React.Fragment>None</React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Accordion>

    );
};

export default AssociatedQuestions;