import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./CiteGSS.css";

function CiteGSS() {
  const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
  const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2021]
  return (
    <>
      <div className="gss-faq m-4">
        <>
          <div>
            <div className="gss-large-title left">Cite the GSS</div>
          </div>
        </>
        <div className="my-3">
          <Row>
            <Col sm={12} md={9} lg={9} xl={9}>
              <div className="terms-text orange-title">In the Media</div>

              <hr className="" />
              <span className="sharing">
                GSS is a project of NORC at the University of Chicago.<br/><br/>
                 Our name
                is NORC at the University of Chicago for all media,
                communications, and marketing purposes. <br/><br/> The first reference that
                we recommend to reporters is: "The independent research
                organization NORC at the University of Chicago," with subsequent
                references simply NORC. <br/><br/>If you are using information from the
                General Social Survey, the citation we recommend is the
                following: "The General Social Survey (GSS) is a project of the
                independent research organization NORC at the University of
                Chicago, with principal funding from the National Science
                Foundation." Subsequent reference would be simply GSS.
              </span>
              <br />
              <div className="terms-text orange-title">
                In Academic Journals
              </div>
              <hr />
              <span className="sharing">
              Davern, Michael; Bautista, Rene; Freese, Jeremy; Herd, Pamela; and Morgan, Stephen L.; General Social Survey 1972-2024. [Machine-readable data file]. Principal Investigator, Michael Davern; Co-Principal Investigators, Rene Bautista, Jeremy Freese, Pamela Herd, and Stephen L. Morgan. Sponsored by National Science Foundation. NORC ed. Chicago: NORC, 2024: NORC at the University of Chicago [producer and distributor]. Data accessed from the GSS Data Explorer website at  &nbsp;
                <a className="green-link" href="/home">
                gssdataexplorer.norc.org.
                </a>
              </span>

              <br />
            </Col>
            {/* </div> */}
          </Row>
        </div>
      </div>
    </>
  );
}

export default CiteGSS;
