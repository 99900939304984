import { Chip } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { environment } from "../../../environment";
import { CheckIfItemExistsInArr, GetDeepCopy, ItemTypes } from "../../../Store/utils/common.utils";
import VarInfo from "../../../Components/UI/VarInfo/VarInfo";

import "./CaseSelection.css";
import { deepCopy } from "../../../Store/utils/variable.utils";

const CaseSelection = React.forwardRef((props, ref) => {
  const [caseSelList, setCaseSelList] = useState([]);
  const hideShowCaseSelItem = (id, isCloseAll) => {
    const newData = GetDeepCopy(caseSelList).map((item) => {
      if (isCloseAll) {
        return { ...item, isVisible: false };
      } else if (item.vID === id) {
        return { ...item, isVisible: !item.isVisible };
      }
      return item;
    });
    setCaseSelList(newData);
  };

  const addMultipleToDrop = (dropArr) => {
    let droppedArr = JSON.parse(JSON.stringify(dropArr));
    let currDropList = JSON.parse(JSON.stringify(caseSelList));
    let fetchCasesPending = 0;
    droppedArr.map((obj, index) => {
      let droppedObj = JSON.parse(JSON.stringify(obj));
      if (
        caseSelList.length === 0 ||
        !CheckIfItemExistsInArr(caseSelList, droppedObj.vID)
      ) {
        droppedObj.isVisible = false;
        currDropList.push(droppedObj);
        fetchCasesPending++;
        getCase(droppedObj.vID, function (caseResults) {
          fetchCasesPending--;
          var caseVarIndex = currDropList.findIndex((item) => item.vID == droppedObj.vID);
          currDropList[caseVarIndex].caseResults = GetDeepCopy(caseResults);
          currDropList[caseVarIndex].isVisible = true;
          if (fetchCasesPending === 0) {
            setCaseSelList(currDropList);
          }
        });
      }
    });

    // setCaseSelList(currDropList);
  }

  // const addToDroppableArea = (obj) => {
  //   let currDropList = JSON.parse(JSON.stringify(caseSelList));
  //   let droppedObj = JSON.parse(JSON.stringify(obj))
  //   if (
  //     caseSelList.length === 0 ||
  //     !CheckIfItemExistsInArr(caseSelList, droppedObj.vID)
  //   ) {
  //     currDropList.push(droppedObj);
  //     setCaseSelList(currDropList);
  //     getCase(droppedObj.vID, function (caseResults) {
  //       // var caseVarIndex = currDropList.findIndex((item) => item.vID ==droppedObj.vID);
  //       droppedObj.caseResults = GetDeepCopy(caseResults);
  //       droppedObj.isVisible = true;
  //       setCaseSelList([...currDropList, droppedObj]);
  //     });
  //   }
  // };

  const addToDroppableArea = (obj) => {
    let droppedObj = JSON.parse(JSON.stringify(obj));
    if (
      caseSelList.length === 0 ||
      !CheckIfItemExistsInArr(caseSelList, droppedObj.vID)
    ) {
      setCaseSelList([...caseSelList, droppedObj]);
      // Check if Item already exists
      getCase(droppedObj.vID, function (caseResults) {
        droppedObj.caseResults = GetDeepCopy(caseResults);
        droppedObj.isVisible = true;
        setCaseSelList([...caseSelList, droppedObj]);
      });
    }
  };

  const removeFromDroppable = (index) => {
    let caseList = [...caseSelList];
    caseList.splice(index, 1);
    setCaseSelList(caseList);
  };

  const getCase = (id, caseResultFn) => {
    axios
      .get(`${environment.getCase + id}`)
      .then((resp) => {
        let caseVar = JSON.parse(JSON.stringify(resp.data));

        caseVar.var_label.caseVar.map((data) => {
          data.isChecked = false;
        });
        // if (Object.keys(caseVar.var_label.summaryStats).length) {
        //   caseVar.rangeMin = '';
        //   caseVar.rangeMax = '';
        // }
        caseResultFn(caseVar.var_label);
        // resp.data.result['workspace_id']=this.props.workspace_id
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCaseSelect = (event, checkBoxIndex, caseVarindex) => {
    let caseList = GetDeepCopy(caseSelList);
    caseList[caseVarindex].caseResults.caseVar[checkBoxIndex].isChecked = event.target.checked;
    setCaseSelList(caseList);
  }

  const handleRange = (event, rangeInputName, caseVarindex) => {
    let caseList = GetDeepCopy(caseSelList);
    caseList[caseVarindex][rangeInputName] = event.target.value;
    // caseList.map((item, index)=>{
    // })
    setCaseSelList(caseList);
  }

  const handleSetMinMax = (rangeInputName, index, value) => {
    let caseList = GetDeepCopy(caseSelList);
    caseList[index][rangeInputName] = value;
    setCaseSelList(caseList);
  }

  const toggleTags = (id) => {
    let filterData = caseSelList.map(d => {
      if (d.vID === id) {
        d.expanded = !d.expanded;
      }
      return d;
    })
    setCaseSelList(filterData)
  }


  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => {
      if (item.data.isChecked && item.list.length > 1) {
        addMultipleToDrop(item.list);
      } else {
        addToDroppableArea(item.data);
      }
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  React.useImperativeHandle(ref, () => ({
    getCaseListReq() {
      let caseList = [...caseSelList];
      
      let caseSel = [];
      caseList.map((caseObj) => {
        let tempObj = {};
        tempObj.id = caseObj.vID;
        tempObj.name = caseObj.name;
        tempObj.cases = caseObj.caseResults?.caseVar.length
          ? caseObj.caseResults.caseVar
            .filter((data) => data.isChecked == true)
            .map((data) => parseInt(data.case))
          : undefined;
        tempObj.cases = (tempObj.cases && tempObj.cases.length) ? tempObj.cases : undefined;
        if (caseObj.caseResults?.summaryStats && Object.keys(caseObj.caseResults?.summaryStats)?.length) {
          tempObj.caseRange = (caseObj.rangeMax || caseObj.rangeMin || caseObj.rangeMin == 0 || caseObj.rangeMax == 0) ? {} : undefined;
          if (typeof (tempObj.caseRange) == "object") {
            tempObj.caseRange.start = (caseObj.rangeMin || caseObj.rangeMin == 0) ? parseInt(caseObj.rangeMin) : undefined;
            tempObj.caseRange.end = (caseObj.rangeMax || caseObj.rangeMax == 0) ? parseInt(caseObj.rangeMax) : undefined;
          }
        }
        if (typeof (tempObj.cases) !== 'undefined' || typeof (tempObj.caseRange) !== 'undefined') {
          caseSel.push(tempObj);
        }
      });
      return caseSel.length ? caseSel : undefined;
    }
  }));



  return (
    <React.Fragment>
      {/* Case Selection Section start */}
      <div className="font-weight-bold mb-1 mt-3">
        Case Selection (Optional)
      </div>
      <div
        className={"droparea case-selection" + (isOver ? ' add-border' : '')}
        ref={drop}
      >
        {caseSelList.length === 0 && (
          <div className="help text-center">
            <div className="font-weight-bold">
              ADD VARIABLES
            </div>
            Simply drag &amp; drop from variable cart.
          </div>
        )}
        <ul className="dropped-items">
          {caseSelList.map((data, caseVarindex) => (
            <React.Fragment>
              <li className="var-item" key={data.vID} id={data.vID}>
                {data && <VarInfo data={data} toggleTags={toggleTags} />}
                {(data.caseResults != undefined && (Object.keys(data.caseResults.summaryStats).length > 0 || data.caseResults.caseVar.length)) ?
                  (<a
                    className="fa fa-pencil"
                    onClick={(e) => {
                      hideShowCaseSelItem(data.vID);
                    }}
                  ></a>) : ''}
                <a
                  className="fa fa-times-circle remove-var"
                  onClick={(event) => removeFromDroppable(caseVarindex)}
                ></a>
                {data.caseResults != undefined && (


                  <div
                    className="selection-table"
                    style={{ display: data.isVisible ? "block" : "none" }}
                  >
                    {Object.keys(data.caseResults.summaryStats).length > 0 && (
                      <div className="range px-3">
                        <h5>Range</h5>
                        <div className="range-inputs">
                          <div className="from">
                            <input
                              type="number"
                              name={"rangeMin_" + caseVarindex}
                              value={data.rangeMin}
                              onChange={(event) => handleRange(event, 'rangeMin', caseVarindex)}
                            />
                            <a className="use" onClick={(event) => handleSetMinMax('rangeMin', caseVarindex, parseInt(data.caseResults.summaryStats.Minimum))}>
                              Use lowest value
                            </a>
                          </div>
                          <div className="through mx-2">through</div>
                          <div className="to">
                            <input
                              type="number"
                              name={"rangeMax_" + caseVarindex}
                              value={data.rangeMax}
                              onChange={(event) => handleRange(event, 'rangeMax', caseVarindex)}
                            />
                            <a className="use" onClick={(event) => handleSetMinMax('rangeMax', caseVarindex, parseInt(data.caseResults.summaryStats.Maximum))}>
                              Use highest value
                            </a>
                          </div>
                        </div>
                      </div>
                    )}

                    {data.caseResults.caseVar.length > 0 && (
                      <div className="gss_table mt-3">
                        <div className="row headers">
                          <div className="col-4 col-xl-2 col-lg-2"></div>
                          <div className="col-4 col-xl-3 col-lg-3 p-0 m-0" style={{fontSize:"12px"}}>Case</div>
                          <div className="col-4 col-xl-7 col-lg-7 p-0 m-0" style={{fontSize:"12px"}}>Value</div>
                        </div>

                        {data.caseResults.caseVar.map((value, checkBoxIndex) => {
                          return (
                            <div className="row data align-items-center">
                              <div className="col-4 col-xl-2 col-lg-2"><input type="checkbox" onChange={(event) => onCaseSelect(event, checkBoxIndex, caseVarindex)} /></div>
                              <div className="col-4 col-xl-3 col-lg-3 p-0 m-0" style={{fontSize:"12px"}}>{value.case}</div>
                              <div className="col-4 col-xl-7 col-lg-7 p-0 m-0" style={{fontSize:"12px"}}>{value.value}</div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {Object.keys(data.caseResults.summaryStats).length > 0 && (
                      <div className="gss_table mt-3">
                        <div className="row headers">
                          <div className="col">Summary Statistics</div>
                        </div>

                        {Object.entries(data.caseResults.summaryStats).map(
                          ([key, value]) => {
                            return (
                              <div className="row data align-items-center">
                                <div className="col-7">{key}</div>
                                <div className="col-5">{value}</div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}

                  </div>
                )}
              </li>

            </React.Fragment>

          ))}
          {caseSelList.length > 0 && (
            <a onClick={(e) => {
              hideShowCaseSelItem('', true);
            }}> + Add Another Variable</a>
          )}
        </ul>
      </div>
      {/* Case Selection Section end */}
    </React.Fragment>
  );
});
export default CaseSelection;