import React from "react";
import "./FaqTrends.css";
import { Col, Row } from "react-bootstrap";
import { environment } from "../../environment";
import BigButton from "../UI/BigButton/BigButton";
import { useSelector } from "react-redux";
function FaqTrends() {
  const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
  const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2018]
  return (
    <>
      <div className="gss-faq m-4">
      <div>
            <div className="gss-large-title left">   GSS Data Explorer Key Trends Documentation</div>
          </div>
     
        <div className="my-3">
           <Row>
            
            <Col sm={12} md={8} lg={8} xl={8}>
            <div className="terms-text orange-title">
              How were the variables for the Key Trends feature selected?
            </div>
            <hr />
            <div className="terms-text orange-title mb-2">Trend Variables</div>
            <span className="sharing">
              Module topics were selected based on information included in
              previously developed GSS Trend Reports, as those were vetted by
              and deemed topics of interest by the GSS research community.
              Coupled with these reports and an extensive review of all GSS
              data, variables were selected based on those which were: relevant
              to module topics, spanned at least three years, and included
              respondent opinion data. Variables with fewer than three years of
              data and/or variables that did not pertain to module topics were
              excluded. Additionally, several variables required multiple
              adjustments in order to account for measurement variation over
              time and were thus excluded. Response categories were retained for
              most variables. Any variable recodes or adjustments are detailed
              in the footnotes.
              <br />
              <br />
              Future feature releases could include data on additional module
              topics or International Social Survey Program (ISSP) data.
            </span>
            <div className="terms-text orange-title mb-2">
              Breakdown Variables
            </div>
            <span className="sharing">
              Trends may be viewed for all GSS respondents or broken down by
              subgroup. The list of breakdown variables currently available in
              the Key Trends feature includes:
              <br />
            </span>
            <ul className="faq sharing">
              <li>Age (AGE)</li>
              <li>Sex (SEX)</li>
              <li>Race (RACE)</li>
              <li>Hispanic specified (HISPANIC)</li>
              <li>Highest educational degree (DEGREE)</li>
              <li>Labor force status (WRKSTAT)</li>
              <li>Subjective class identification (CLASS)</li>
              <li>Marital status (MARITAL)</li>
              <li>Political affiliation (PARTYID)</li>
              <li>Region of interview (REGION)</li>
              <li>Citizenship status (USCITZN/FUCITZN)</li>
              <li>Condition of health (HEALTH)</li>
            </ul>
            With the exception of Hispanic specified, citizenship status, and
            health, these demographic items are available for all GSS data
            collection years.
            <br />
            <br />
            Note that some GSS variables have been recoded in order to produce
            consistent time series. “Don’t Know” responses to breakdown
            variables were excluded from calculations.
            <br />
            <br />
            Several demographic variables, such as income, required multiple
            adjustments in order to account for measurement variation over time
            and were thus excluded. Other breakdown variables, such as
            subjective class identification (CLASS), highest degree (DEGREE),
            and labor force status (WRKSTAT) may be used as indicators of
            socioeconomic status.
            <br />
            <br />
            Future feature releases could include additional breakdown
            variables.
            <span className="sharing"></span>
            <div className="terms-text orange-title">
              How do I know which GSS variable was used to create the trend
              visualization?
            </div>
            <hr />
            <span className="sharing">
              The GSS variables used to create the trend visualization are
              listed beneath the chart, along with the variable description and
              the original question text. In most cases, the trend data were
              produced directly from the GSS variable. In some cases, GSS
              variables have been recoded, combined, or adjusted in order to
              produce a consistent time series.
              <br />
              <br />
              Please refer to the footnotes in each chart for details about
              variable recodes and adjustments.
            </span>
            <div className="terms-text orange-title">
              Are the trend data weighted?
            </div>
            <hr />
            <span className="sharing">
              The default weight&nbsp;<b>WTSSCOMP</b>&nbsp;was applied for all years. Oversampled black respondents
              (SAMPLE=4,5,7) were excluded to adjust for the oversampling
              procedures in 1982 and 1987. Please refer to the footnotes for
              each trend chart, as these may specify additional weighting
              considerations (e.g., use of FORMWT or WTSSNR) that may be applied
              for more in-depth analyses. See{" "}
              <a className="green-link" href="/gssweighting">
                this page 
              </a>
              &nbsp;for more detail about GSS weighting procedures.
              <br />
              <br />
              Calculations were performed using SAS Software. Due to slight
              variations in how different statistical software calculate weight,
              the estimates presented in this report may differ slightly from
              reported percentages in other GSS publications.
              <br />
              <br />
              For years 1975 onward, variables&nbsp;<b>VSTRAT</b>&nbsp;(for stratum) and&nbsp;<b>VPSU</b>&nbsp;(for primary sampling units) were applied to generate
              design-corrected standard errors. These complex standard errors
              are only valid from 1975 onward. See{" "}
              <a
                className="green-link"
                href="/gss_stdError"
              >
                this page
              </a>{" "}
              for more information.
              <br />
              <br />
              The standard errors are available in the downloadable data files
              and may also be viewed within the visualization by hovering over
              the data point or segment of interest.
            </span>
            <div className="terms-text orange-title">
              Were any data excluded?
            </div>
            <hr />
            <span className="sharing">
              Unless otherwise specified in the chart footnote, “No answer” and
              “Not applicable” values were excluded from calculations.
              <br />
              <br />
              “Don’t Know” responses were excluded from calculations for
              breakdown variables and included in calculations for trend
              variables.
              <br />
              <br />
              Please refer to the footnotes in each chart for additional details
              about exclusions.
            </span>
            <div className="terms-text orange-title">
              How should I interpret the trends for “Don’t Know” or “Other”
              responses?
            </div>
            <hr />
            <span className="sharing">
              Some response options, such as “Don’t Know” or “Other,” aren’t
              selected often enough to provide consistent, reliable data; this
              can produce misleading trend lines.
              <br />
              <br />
              Therefore, when viewing these and other trends, keep the following
              considerations in mind:
              <br />
            </span>
            <ul className="faq">
              <li>
                <b>The context in which the questionnaire was administered:</b>
                In most cases, the “Don’t Know” or “Other” option is not read
                aloud to the respondent and is only therefore selected if the
                respondent volunteers this response. For more information on
                administration procedures, refer to the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="green-link"
                  href="https://gss.norc.org/get-documentation"
                >
                  GSS Cumulative Codebook
                </a>{" "}
                and&nbsp;
                <a className="green-link" href="/gssReports/10">
                  GSS Questionnaires.
                </a>
              </li>
              <li>
                <b>
                  It is more difficult to draw accurate conclusions when there
                  are few observations:
                </b>
                One way of measuring the accuracy of a particular estimate is by
                referring to the standard error value. Standard error measures
                the extent to which an estimate from a sample is likely to
                deviate from the true population. Data points with high standard
                error values relative to the percent estimate are less reliable.
                Take caution when interpreting estimates with a relative
                standard error greater than 30%. To view the standard error
                value associated with a data point, hover your mouse over the
                data point or download the data.
              </li>
            </ul>
            <span className="sharing"></span>
          </Col>
       
        <Col sm={12} md={4} lg={4} xl={4}>
              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Need help with the weighting feature?</h5>
                <p>
                Learn about the weights available in the GSS Data Explorer and how best to use them to create your analyses.
                </p>
                <a href="/gssweighting"  style={{"color": "white"}}>  <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                  

                >
              WEIGHTING HELP
                </BigButton></a>
              </div>

              <div className=" pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Can’t find what you’re looking for?</h5>
                <p>
                  Check out our FAQ Page for more information about the GSS and how to use the data.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/FAQ">VIEW FAQ</BigButton>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5 >Does the GSS data set include geocoded information?</h5>
                <p>
                  Learn how to access GSS geocoded and other sensitive data.
                </p>
                <a target="_blank"  rel="noreferrer"  href={environment.getSensitiveData}>
                <BigButton
                  classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100"
                >
                SENSITIVE GSS DATA
                </BigButton>
                </a>
              </div>
              <div className="pt-5 pl-4 pb-4 pr-4 slide">
                <h5>Still need help?</h5>
                <p>
                  Submit your question or issue to the experts at GSS.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/user/contact/">ASK AN EXPERT</BigButton>
              </div>

              <div className="pt-5 pl-4 pb-4 pr-4 slide" >
                <h5>GSS Cumulative Data {years[0]}-{years[1]}</h5>
                <p>
                  Want the entire GSS dataset to analyze in your own statistical software package without creating an account? Download the cumulative dataset for SPSS or Stata.
                </p>
                <BigButton classname="btn btn-dark hb rounded-0 font-weight-bold mr-1 w-100" eleType="link" url="/gss_data">QUICK DOWNLOAD</BigButton>
              </div>

            </Col>
            </Row>
            </div>
      </div>
    </>
  );
}

export default FaqTrends;
