import React, { useEffect, useState } from "react";
import { GreenCheckbox } from "../../../Store/utils/common.utils";
import { FormControlLabel } from "@material-ui/core";

export default function BallotYearsSelect(props) {

  const [selectedYears, setSelectedYears] = useState(new Set());

  const handleYearsChange = (event) => {
    let value = parseInt(event.target.value);
    if (event.target.checked) {
      setSelectedYears(prev => new Set(prev).add(value));
      //setSelectedYears((prevState) => prevState.add(value));
    } else {
      setSelectedYears(prev => {
        const next = new Set(prev);
  
        next.delete(value);
  
        return next;
      });
     /*  setSelectedYears((prevState) => {
        prevState.delete(value);
        return prevState;
      }); */
    }
   
  };

  useEffect(() => {
    props.yearUpdate(selectedYears);
  }, [ selectedYears ]);

  return (
    <div className="w-100 gss-analysis-years">
    {props.data ?props.data.map((data) => (
    
      <FormControlLabel
              control={
                <GreenCheckbox
                  /* checked={data} */
                  value={data}
                  onChange={handleYearsChange} />
              }
              label={data}
            key={data}
              style={{cursor: "pointer" }}
 
            />
      /* <label className="mr-2">
        <input
          type="checkbox"
          value={data}
          onChange={handleYearsChange}
        />{" "}
        {data}
      </label> */
    )):''}
  </div>
  );
}
