import React, { useEffect, useRef, useState, useContext } from "react";
import SearchBar from "../SearchBar/SearchBar";
import "./SearchPage.css";
import axios from "axios";
import { environment } from "../../../environment";
import { useLocation } from "react-router-dom";
import { messageService } from "../../../Shared/Message";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { updateService } from "../../../Shared/UpdateCart";
import Pagination from "@material-ui/lab/Pagination";
import ReturnBack from "../../../Components/UI/GSS/ReturnBack/ReturnBack";
import { addToExtract } from "../../../Store/actions/variable";
import ConfirmModal from "../../../Components/UI/ConfirmModal/ConfirmModal";
import CreateTagModel from "../../../Components/UI/CreateTagModel/CreateTagModel";
import VariableActionTypes from "../../../Store/types/variable.types";
import AddTagModel from "../../../Components/UI/AddTagModel/AddTagModel";
import SelectedVariablesContext from "../../../Store/ContextApi/SelectedVariablesContext";
import { useSelector, useDispatch } from "react-redux";
import { Row, Form } from "react-bootstrap";
import { setUserSearches } from "../../../Store/actions";
import Loader from "../../../Components/UI/Loader/Loader";
import {
  GetModules,
  GetTagCategories,
} from "../../../Store/utils/variable.utils";
import VariableItems from "../../../Components/VariableItems/VariableItems";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const SearchPage = (props) => {
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [keywordData, setKeywordData] = useState([]);
  const [variables, setVariables] = useState([]);
  const auth = useSelector((state) => state.authReducer);
  const [inputText, setInputText] = useState("");
  const [loader, setLoader] = useState(false);
  const {
    min_max_year: { min_max_year = [] },
  } = useSelector((state) => state.minmaxYearsReducer);
  // homepage-search keyword
  const search = useLocation().search;
  const keywordText = new URLSearchParams(search).get("keyword");
  // End Here
  const {
    usersearches_data: { usersearches_data = {} },
  } = useSelector((state) => state.userSearchesReducer);
  let criteria = {
    keyword: "",
    subjects: [],
    module: [],
    yrmin: "",
    yrmax: "",
    years: [],
    yearBallot: [],
  };
  let my_tags = [];
  let shared_tags = [];
  let localIsSpecific = false;
  if (Object.keys(usersearches_data).length) {
    if (usersearches_data.criteria) {
      criteria = usersearches_data.criteria;

      if (criteria.my_tags && criteria.my_tags.length > 0)
        my_tags = criteria.my_tags.map((d) => d.id);
      if (criteria.shared_tags && criteria.shared_tags.length > 0)
        shared_tags = criteria.shared_tags.map((d) => d.id);
    }
  }
  if (
    criteria.yearBallot &&
    criteria.yearBallot.length > 0 &&
    criteria.years.length > 0
  ) {
    localIsSpecific = true;
  }
  if (keywordText) {
    criteria.keyword = keywordText;
  }

  const [enableSaveSearchBtn, setEnableSaveSearchBtn] = useState(false);
  const [defaultProjNo, setDefaultProjNo] = useState(
    localStorage.defaultProjectNo
      ? parseInt(localStorage.defaultProjectNo)
      : undefined
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]);
  const [isRangeSelected, setIsRangeSelected] = useState(true);
  const [isRangeUpdated, setIsRangeUpdated] = useState();
  const [keyword, setKeyword] = useState(criteria?.keyword ?? "");
  const [finalSubMod, setFinalSubMod] = useState({
    subjects: criteria?.subjects,
    modules: criteria?.module,
  });
  const [finalYourSharedTags, setFinalYourSharedTags] = useState({
    myTags: my_tags,
    sharedTags: shared_tags,
  });
  const [totalVarCount, setTotalVarCount] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [filteredYears, setFilteredYears] = useState(
    criteria?.yrmin && criteria?.yrmax ? [criteria?.yrmin, criteria?.yrmax] : []
  );
  const [years, setYears] = useState(criteria?.years);
  const [selectedMenu, setSelectedMenu] = useState();
  const [yearBallot, setYearBallot] = useState(criteria.yearBallot);
  const [showConfirm, setShowConfirm] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isSpecificBool, setSpecificBool] = useState(localIsSpecific);
  const [isRangeSliderSel, setIsRangeSliderSel] = useState();
  const [loading, setLoading] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);
  const [addTagModal, setAddTagModal] = useState(false);
  const { selected, setSelected, selectedObj, setSelectedObj } = useContext(
    SelectedVariablesContext
  );
  const [showSelectAll, setShowSelectAll] = useState(true);
  const [type, setType] = useState(1);
  const viewBy = [
    { id: 1, name: "Variable List" },
    { id: 2, name: "Tag Categories" },
    { id: 3, name: "Modules" },
  ];
  const [isServerError, setIsServerError] = useState({
    status: false,
    title: null,
    message: null,
  });
  const [getAllModules, setAllModules] = useState({});
  const [getAllTagCategories, setGetAllTagCategories] = useState({});
  const [showViewBy, setShowViewBy] = useState(false);
  const [selectedVar, setSelectedVar] = useState([]);
  const [varPerPage, setVarPage] = useState(25);
  const perPageVar = ({ target: { value } }) => {
    setVarPage(value);
  };
  const isFilterApplied = () => {
    if (
      (filteredYears &&
        filteredYears.length > 0 &&
        filteredYears[0] !== "" &&
        filteredYears[1] !== "") ||
      (yearBallot && yearBallot.length > 0) ||
      keyword ||
      (typeof finalSubMod !== "undefined" &&
        ((finalSubMod.modules && finalSubMod.modules.length > 0) ||
          (finalSubMod.shared_tags && finalSubMod.shared_tags.length > 0) ||
          (finalSubMod.my_tags && finalSubMod.my_tags.length > 0) ||
          (finalSubMod.subjects && finalSubMod.subjects.length > 0)))
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-uat.norc.org/"
    ) {
      axios
        .get(
          process.env.PUBLIC_URL +
            "/config/variable_details_autocomplete_uat.json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          if (!(response.data.var_detail && response.data.var_detail.length)) {
            return;
          }
          if (response.data.var_detail && response.data.var_detail.length > 0) {
            setKeywordData(response.data.var_detail);
          }
        });
    }
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL == "https://gssde-tst.norc.org/"
    ) {
      axios
        .get(
          process.env.PUBLIC_URL +
            "/config/variable_details_autocomplete_qa.json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          if (!(response.data.var_detail && response.data.var_detail.length)) {
            return;
          }
          if (response.data.var_detail && response.data.var_detail.length > 0) {
            setKeywordData(response.data.var_detail);
          }
        });
    }
    if (
      process.env.REACT_APP_AWS_SIGN_IN_URL ==
      "https://gssdataexplorer.norc.org/"
    ) {
      axios
        .get(
          process.env.PUBLIC_URL + "/config/variable_details_autocomplete.json",
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "",
            },
          }
        )
        .then((response) => {
          if (!(response.data.var_detail && response.data.var_detail.length)) {
            return;
          }
          if (response.data.var_detail && response.data.var_detail.length > 0) {
            setKeywordData(response.data.var_detail);
          }
        });
    }
  }, []);

  const getVariablesList = (pageNo) => {
    setLoader(true);
    let searchAPI =
      auth.isSignedIn || localStorage.isSignedIn === "true"
        ? environment.getFilteredVariables
        : environment.getGuestFilteredVariables;
    let variablesAPI =
      auth.isSignedIn || localStorage.isSignedIn === "true"
        ? environment.getVariablesList
        : environment.getGuestVariablesList;
    let isFiltersApplied = isFilterApplied();
    let reqObj = {};
    reqObj.method = isFiltersApplied ? "POST" : "GET";
    reqObj.url = isFiltersApplied ? searchAPI : variablesAPI;
    if (reqObj.method == "GET") {
      reqObj.params = {
        page: pageNo ? pageNo : currentPage,
        limit: parseInt(varPerPage),
        wrk_id: defaultProjNo,
      };
    } else {
      reqObj.data = {
        page: pageNo ? pageNo : currentPage,
        limit: parseInt(varPerPage),
        subjects:
          finalSubMod?.subjects && finalSubMod?.subjects.length
            ? finalSubMod?.subjects
            : null,
        modules:
          finalSubMod?.modules && finalSubMod?.modules.length
            ? finalSubMod?.modules
            : null,
        my_tags:
          finalYourSharedTags?.myTags && finalYourSharedTags?.myTags.length
            ? finalYourSharedTags?.myTags
            : null,
        shared_tags:
          finalYourSharedTags?.sharedTags &&
          finalYourSharedTags?.sharedTags.length
            ? finalYourSharedTags?.sharedTags
            : null,
        yearRange:
          filteredYears && filteredYears.length && !isSpecificBool
            ? filteredYears
            : null,
        years: years && years.length && isSpecificBool ? years : null,
        parameter: selectedMenu && keyword ? selectedMenu : null,
        term: keyword ? keyword : null,
        workspaceId: defaultProjNo ? defaultProjNo : null,
        yearBallot:
          yearBallot && yearBallot.length && isSpecificBool ? yearBallot : null,
      };

      if (keyword && !selectedMenu) {
        reqObj.data.parameter = "all";
      }
      if (
        reqObj.data.yearBallot == null &&
        reqObj.data.years == null &&
        reqObj.data.yearRange == null
      ) {
        let years =
          min_max_year && min_max_year.length > 0 ? min_max_year : [1972, 2018];
        reqObj.data.yearRange = years;
      }
    }

    axios(reqObj)
      .then((response) => {
        let results = response.data;
        localStorage.setItem("varInCart", results.varInCart);
        let variables = results.variables
          .filter((v) => v.name !== "id" && v.name !== "id_")
          .map((variable) => {
            variable.isAdded =
              results.varInCart.indexOf(variable.id) >= 0 ? true : false;
            variable.vID = variable.id;
            variable.label = variable.description;
            variable.orignlName = variable.name;
            variable.tags_info = variable.tag_info;
            return variable;
          });
        setSelectedVar([]);
        setShowSelectAll(true);
        setLoader(false);
        setVariables(variables);
        dispatch({
          type: VariableActionTypes.VARIABLE_LIST_SUCCESS,
          payload: variables,
        });

        if (type == 2) {
          let tagCategoriesdata = GetTagCategories(variables, true);
          setGetAllTagCategories(tagCategoriesdata);
        }

        if (type == 3) {
          let modulesData = GetModules(variables);
          setAllModules(modulesData);
        }

        setTotalVarCount(results.totalVarCount);
        setTotalPage(results.totalPages);
        setIsRangeSelected(
          isRangeSliderSel == undefined ? isRangeSelected : isRangeSliderSel
        );
        updateService.sendAlert("varData");
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      getVariablesList();
      messageService.onMessage().subscribe((message) => {
        if (message.text == "getData") {
          getVariablesList(1);
        }
      });
      return;
    }
    if (currentPage) {
      getVariablesList();
    }
  }, [currentPage]);

  useEffect(() => {
    getVariablesList();
  }, [varPerPage]);

  useEffect(() => {
    return () => {
      dispatch(setUserSearches({}));
    };
  }, []);

  const setOpen = () => {
    setIsOpen(!isOpen);
  };

  const submitSaveSearch = (searchName) => {
    let reqObj;
    if (usersearches_data?.id) {
      reqObj = {
        method: "PUT",
        url: environment.editUserSearch,
        data: {
          user_search_record: {
            usId: usersearches_data?.id,
            name: searchName,
            creator_id: "",
            workspace_id: defaultProjNo,
            data_collection_id: 1,
            sample_id: 1,
            criteria_info: {
              keyword: keyword ? keyword : null,
              year_min:
                filteredYears && filteredYears.length && !isSpecificBool
                  ? filteredYears[0]
                  : null,
              year_max:
                filteredYears && filteredYears.length && !isSpecificBool
                  ? filteredYears[1]
                  : null,
              years: years && years.length && isSpecificBool ? years : null,
              yearBallot:
                yearBallot && yearBallot.length && isSpecificBool
                  ? yearBallot
                  : null,
              subjects:
                finalSubMod?.subjects && finalSubMod?.subjects.length
                  ? finalSubMod?.subjects
                  : null,
              module:
                finalSubMod?.modules && finalSubMod?.modules.length
                  ? finalSubMod?.modules
                  : null,
              my_tags:
                finalYourSharedTags?.myTags &&
                finalYourSharedTags?.myTags.length
                  ? finalYourSharedTags?.myTags
                  : null,
              shared_tags:
                finalYourSharedTags?.sharedTags &&
                finalYourSharedTags?.sharedTags.length
                  ? finalYourSharedTags?.sharedTags
                  : null,
            },
            result_count: totalVarCount,
          },
        },
      };
    } else {
      reqObj = {
        method: "PUT",
        url: environment.addUserSearch,
        data: {
          user_search_record: {
            name: searchName,
            creator_id: "",
            workspace_id: defaultProjNo,
            data_collection_id: 1,
            sample_id: 1,
            criteria_info: {
              keyword: keyword ? keyword : null,
              year_min:
                filteredYears && filteredYears.length && !isSpecificBool
                  ? filteredYears[0]
                  : null,
              year_max:
                filteredYears && filteredYears.length && !isSpecificBool
                  ? filteredYears[1]
                  : null,
              years: years && years.length && isSpecificBool ? years : null,
              yearBallot:
                yearBallot && yearBallot.length && isSpecificBool
                  ? yearBallot
                  : null,
              subjects:
                finalSubMod?.subjects && finalSubMod?.subjects.length
                  ? finalSubMod?.subjects
                  : null,
              module:
                finalSubMod?.modules && finalSubMod?.modules.length
                  ? finalSubMod?.modules
                  : null,
              my_tags:
                finalYourSharedTags?.myTags &&
                finalYourSharedTags?.myTags.length
                  ? finalYourSharedTags?.myTags
                  : null,
              shared_tags:
                finalYourSharedTags?.sharedTags &&
                finalYourSharedTags?.sharedTags.length
                  ? finalYourSharedTags?.sharedTags
                  : null,
            },
            result_count: totalVarCount,
          },
        },
      };
    }
    axios(reqObj)
      .then(({ data: { response } }) => {
        if (response.result == "success") {
          alert(
            `User search was successfully ${
              usersearches_data?.id ? `updated` : `created`
            }.`
          );
        } else {
          alert(response.message);
        }
      })
      .catch((error) => {
        alert(error.error_message);
      });
  };

  const changeView = () => {
    setIsExpanded(!isExpanded);
  };

  const clickPaginationBtn = (e, val) => {
    console.log("val=====>", val);
    let setPage = val;
    setCurrentPage(setPage);
  };

  const addRemoveApiCall = (output, id, action) => {
    let variableArr = [];
    if (id) {
      variableArr.push(parseInt(id));
    } else {
      variableArr = output ? output : [];
    }

    axios({
      method: action === "add" ? "POST" : "DELETE",
      url:
        action === "add"
          ? `${environment.addToCart}`
          : `${environment.removeFromCart}`,
      data: {
        cart_items: variableArr,
        workspace_id: defaultProjNo,
        cartType: "analysis",
        remove_all: action !== "add" ? false : undefined,
      },
    })
      .then((response) => {
        if (id == null) {
          setIsServerError({
            ...isServerError,
            status: true,
            title: response?.data?.response?.message?.includes(
              "Maximum variable limit"
            )
              ? "Error"
              : "Success",
            message: response?.data?.response?.message,
          });
        }
        getVariablesList();
        dispatch({
          type: "VARIABLE_DATA",
          payload: "added",
        });
        updateService.sendAlert("varData");
      })
      .catch((error) => {
        if (id == null) {
          let msg;
          if (error.response) {
            msg = error.response.data;
          } else if (error.request) {
            msg = error.request;
          } else {
            msg = error.message;
          }
          setIsServerError({
            ...isServerError,
            status: true,
            title: "Error",
            message: msg.message
              ? msg.message
              : "Something went wrong. Try Again!!",
          });
        }
      });
  };

  const addRemoveFromCart = (id, action) => {
    let getCheckedIds;
    if (id == null) {
      if (!(selectedVar.length > 0)) {
        return;
      } else {
        getCheckedIds = selectedVar.filter((v) => !v.isAdded).map((i) => i.id);
        if (getCheckedIds.length == 0) {
          setIsServerError({
            ...isServerError,
            status: true,
            title: "Error",
            message: "Variables already added to MyGSS",
          });
          return;
        }
      }
    }

    addRemoveApiCall(getCheckedIds, id, action);
  };

  const changeCheckbox = (item) => {
    let newSelected = [...selectedVar];
    let isSelected = newSelected.findIndex((sv) => sv.id == item.id);
    if (isSelected == -1) {
      newSelected.push(item);
    } else {
      newSelected.splice(isSelected, 1);
    }
    setSelectedVar(newSelected);

    if (type == 1) {
      if (newSelected.length == variables.length) {
        setShowSelectAll(false);
      } else {
        setShowSelectAll(true);
      }
    }
  };

  const handleMyGSS = (id, varName, index, type) => {
    if (!(auth.isSignedIn || localStorage.isSignedIn === "true")) {
      alert("Please login into GSSDE in order to add variables to your cart.");
      return false;
    } else {
      if (varName == "year" || varName == "id" || varName == "ballot") {
        return false;
      }
      addRemoveFromCart(id, type);
    }
  };

  const openSaveSrchDialog = () => {
    if (!(auth.isSignedIn || localStorage.isSignedIn === "true")) {
      alert("Please login into GSSDE in order to save search.");
      return false;
    } else {
      setOpen();
    }
  };

  const setFilters = (filterObj) => {
    let {
      filteredYears,
      isClear,
      isSpecific,
      keyword,
      finalSubMod,
      finalYourSharedTags,
      isRangeUpdated,
      selectedMenu,
      yearBallot,
      years,
      isRangeSliderSel,
    } = filterObj;
    if (selectedMenu == 0) {
      selectedMenu = "";
    } else if (selectedMenu == 1) {
      selectedMenu = "variable";
    } else if (selectedMenu == 2) {
      selectedMenu = "description";
    } else if (selectedMenu == 3) {
      selectedMenu = "question";
    } else if (selectedMenu == 4) {
      selectedMenu = "tags";
    }
    if (
      filteredYears &&
      filteredYears.length > 0 &&
      filteredYears[0] !== "" &&
      filteredYears[1] !== ""
    ) {
      setFilteredYears(filteredYears);
    } else {
      let years =
        min_max_year && min_max_year.length > 0 ? min_max_year : [1972, 2018];
      setFilteredYears(years);
    }
    setSpecificBool(isSpecific);
    setKeyword(keyword);
    console.log("finalSubMod empty", finalSubMod);
    setFinalSubMod(finalSubMod);
    setFinalYourSharedTags(finalYourSharedTags);
    setIsRangeUpdated(isRangeUpdated);
    setCurrentPage(1);
    setSelectedMenu(selectedMenu);
    setYearBallot(yearBallot);
    setYears(years);
    setSelectedYears(years);
    setIsRangeSliderSel(isRangeSliderSel);
    setShowViewBy(isClear ? false : true);
    if (isClear) {
      clearAPI();
    } else {
      setLoading(true);
    }
    setEnableSaveSearchBtn(true);
  };
  useEffect(() => {
    if (loading) {
      getVariablesList(1);
    }
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (type == 2) {
      let tagCategoriesdata = GetTagCategories(variables, true);
      setGetAllTagCategories(tagCategoriesdata);
    } else if (type == 3) {
      let modulesData = GetModules(variables);
      setAllModules(modulesData);
    }
  }, [type]);

  const onAddToExtractSubmit = () => {
    setShowConfirm(false);
    if (selectedVar.length) {
      let filter = selectedVar
        .filter((v) => v.isAlreadyInExtract != 1)
        .map((d) => d.id);
      if (filter.length) {
        let reqObj = {
          cart_items: filter,
          workspace_id: parseInt(localStorage.defaultProjectNo),
          cartType: "extract",
        };
        dispatch(addToExtract(reqObj)).then(() => {
          setShowSelectAll(true);
          setSelectedVar([]);
        });
      } else {
        setShowSelectAll(true);
        setSelectedVar([]);
      }
    }
  };

  const setDynamicAction = (val) => {
    setShowConfirm(true);
    setModalTitle(val);
    if (val == "Add") {
      setModalMessage("Add To Extract");
      return { onSubmit: onAddToExtractSubmit };
    }
  };

  const onAddToExtract = () => {
    if (selectedVar.length > 0) {
      setDynamicAction("Add");
    }
  };

  const actionsObj = {
    Add: onAddToExtractSubmit,
  };

  const clearAPI = () => {
    let variablesAPI =
      auth.isSignedIn || localStorage.isSignedIn === "true"
        ? environment.getVariablesList
        : environment.getGuestVariablesList;

    let reqObj = {};
    reqObj.method = "GET";
    reqObj.url = variablesAPI;
    reqObj.params = {
      page: 1,
      limit: parseInt(varPerPage),
      wrk_id: defaultProjNo,
    };
    axios(reqObj)
      .then((response) => {
        let results = response.data;
        localStorage.setItem("varInCart", results.varInCart);
        let variables = results.variables
          .filter((v) => v.name !== "id" && v.name !== "id_")
          .map((variable) => {
            variable.isAdded =
              results.varInCart.indexOf(variable.id) >= 0 ? true : false;
            variable.vID = variable.id;
            variable.label = variable.description;
            variable.orignlName = variable.name;
            variable.tags_info = variable.tag_info;
            return variable;
          });

        setSelectedVar([]);
        setShowSelectAll(true);
        setVariables(variables);
        dispatch({
          type: VariableActionTypes.VARIABLE_LIST_SUCCESS,
          payload: variables,
        });
        if (type == 2) {
          let tagCategoriesdata = GetTagCategories(variables, true);
          setGetAllTagCategories(tagCategoriesdata);
        }

        if (type == 3) {
          let modulesData = GetModules(variables);
          setAllModules(modulesData);
        }
        setTotalVarCount(results.totalVarCount);
        setTotalPage(results.totalPages);
        setIsRangeSelected(
          isRangeSliderSel == undefined ? isRangeSelected : isRangeSliderSel
        );
        updateService.sendAlert("varData");
      })
      .catch((error) => {});
  };

  const variableMerged = variables
    ?.map((t1) => ({
      ...t1,
      ...keywordData.find((t2) => t2.var_name === t1.name),
    }))
    ?.sort((a, b) => {
      var maxYearA = Math.max.apply(0, a.max_year);

      var maxYearB = Math.max.apply(0, b.max_year);

      if (b.years_count == a.years_count) {
        return maxYearB - maxYearA;
      } else return b.years_count - a.years_count;
    });

  // console.log(variableMerged, "dataMerged");
  // console.log(keywordData, "keydata::::");
  // console.log(variables, "variables::::");
  const handleCreateTag = () => {
    if (selectedVar.length > 0) {
      setCreateTagModal(true);
    }
  };

  const handleAddToTag = () => {
    if (selectedVar.length > 0) {
      setAddTagModal(true);
    }
  };

  const handleSelectionAll = () => {
    let varArr = [...variables];
    setSelectedVar(showSelectAll ? varArr : []);
    setShowSelectAll(!showSelectAll);
  };

  const changeType = ({ target: { value } }) => {
    setSelectedVar([]);
    setShowSelectAll(true);
    setType(value);
  };

  const setIsServerResponseOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };

  const setClose = () => {};

  const toggleTags = (e, id) => {
    e.stopPropagation();

    let filterData = variableMerged.map((d) => {
      if (d.vID === id) {
        d.expanded = !d.expanded;
      }
      return d;
    });
    setVariables(filterData);
  };

  return (
    <div className="gss-search overflow-hidden">
      <CustomModal
        title={"Save Search"}
        isOpen={isOpen}
        setIsOpen={setOpen} //callback function when dialog is opened. Calling Child function from Parent
        handleSubmit={submitSaveSearch}
        btnText="Save"
        isInput={true}
        inputLabel="Name"
        inputText={inputText}
      ></CustomModal>

      <CustomModal
        title={isServerError.title}
        titleText={isServerError.title}
        isOpen={isServerError.status}
        setIsOpen={setIsServerResponseOpen}
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>

      {showConfirm ? (
        <ConfirmModal
          rows={selectedVar}
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          title={modalTitle}
          message={modalMessage}
          onSubmit={actionsObj[modalTitle]}
        />
      ) : (
        ""
      )}

      {createTagModal ? (
        <CreateTagModel
          rows={selectedVar}
          selected={selectedVar.map((obj) => obj.id)}
          modalShow={createTagModal}
          setAddTagModal={setCreateTagModal}
          getVariablelist={getVariablesList}
        />
      ) : (
        ""
      )}
      {addTagModal ? (
        <AddTagModel
          rows={selectedVar}
          selected={selectedVar.map((obj) => obj.id)}
          setSelected={setSelected}
          setSelectedObj={setSelectedObj}
          addTagModal={addTagModal}
          setAddTagModal={setAddTagModal}
          getVariablelist={getVariablesList}
        />
      ) : (
        ""
      )}
      {props.isAnalysisOrExtract ? (
        <>
          <div className="gss-return-back">
            <span
              className="back-btn"
              onClick={() => props.setToggelView(true)}
            >
              <ReturnBack compName={props.moduleName}></ReturnBack>
            </span>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="container mt-3 mb-3 gss-large-title">
        Search Data
        <Tooltip
          interactive
          title={
            <div>
              <strong>Keyword</strong>
              <ul>
                <li>
                  Allows you to search for GSS variables by keyword. You can
                  also limit your search to variable names, variable
                  descriptions, survey question text, or GSS tags using the
                  dropdown menu located above the search bar.
                </li>
              </ul>
              <strong>Years</strong>
              <ul>
                <li>
                  Allows you to narrow a search to specific survey years. Either
                  enter years in the boxes to select a range, or click 'Select
                  Specific Years' to choose years and ballots one by one.
                </li>
              </ul>
              <strong>Filter by</strong>
              <ul>
                <li>
                  Allows you to search for questions on specific topics. Click
                  on the drop-down menu to select tags or modules.
                </li>
              </ul>
              <strong>Filter by Tags</strong>
              <ul>
                <li>
                  Groups variables by tags, which can be user-created or made by
                  the GSS team. Variables may appear multiple times in results
                  if they have multiple tags.
                </li>
              </ul>
              <strong>Variable</strong>
              <ul>
                <li>
                  Click on a variable name to see more information about it.
                </li>
              </ul>
              <strong>Add to MyGSS</strong>
              <ul>
                <li>
                  Allows you to add a single variable to MyGSS for further
                  anaylsis. To add multiple variables at once, select the
                  variables you want to add, then click "Save to MyGSS" instead.
                </li>
              </ul>
              <strong>Years Available</strong>
              <ul>
                <li>
                  Shows the years available for a given variable in green. Hover
                  over the timeline to see the specific years and ballots
                  containing the variable.
                </li>
              </ul>
            </div>
          }
        >
          <InfoIcon
            style={{ height: "13px", marginLeft: "6px" }}
            fontSize="small"
            className="plus-icon"
          />
        </Tooltip>
      </div>
      <SearchBar
        clickSearch={setFilters}
        enableSaveSearchBtn={enableSaveSearchBtn}
        openSaveSrchDialog={openSaveSrchDialog}
        finalSubMod={finalSubMod}
        finalYourSharedTags={finalYourSharedTags}
      ></SearchBar>
      <div className="container">
        <div className="row">
          <div className="col">
            <hr className="mt-0" />
          </div>
        </div>
      </div>

      {loader ? (
        <Row className="d-flex align-items-center justify-content-center w-100">
          <Loader />
        </Row>
      ) : (
        <div className="container">
          <div className="row mb-2">
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 d-flex align-items-center">
              <span className="mr-5">
                <b>{totalVarCount} Results</b>&nbsp;matching criteria
              </span>
              {true && (
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 d-flex align-items-center">
                  <div>
                    <span className="font-small mx-1">
                      <b>View by :</b>
                    </span>
                  </div>
                  <div>
                    <Form.Control
                      lg={2}
                      defaultValue={type}
                      value={type}
                      onChange={changeType}
                      className="hlabel"
                      as="select"
                      // defaultValue="Choose..."
                    >
                      {viewBy.map((viewby) => (
                        <option value={viewby.id}>{viewby.name}</option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              )}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-end align-items-center">
              <button className="gss-btn btn-gray" onClick={changeView}>
                {isExpanded && (
                  <span>
                    <i className="fa fa-compress"></i>Show Collapsed View
                  </span>
                )}
                {!isExpanded && (
                  <span>
                    <i className="fa fa-expand"></i>Show Expanded View
                  </span>
                )}
              </button>
            </div>
          </div>

          {totalPage > 0 && (
            <div className="row mb-1">
              <div className="col-lg-2 col-md-6 col-sm-12 col-xl-2 align-self-center">
                <h4 className="m-0 p-0">
                  {totalPage ? "Page " + currentPage + " of " + totalPage : ""}
                </h4>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-xl-3 align-self-center">
                <b style={{ fontSize: "16px", color: "#666666" }}>
                  Variables Per Page :{" "}
                </b>
                <select
                  onChange={perPageVar}
                  value={varPerPage}
                  style={{
                    fontSize: "14px",
                    padding: "2px",
                    borderRadius: "5px",
                    border: "1px solid #ced4da",
                  }}
                >
                  <option key={25} value={25} selected>
                    25
                  </option>
                  <option key={50} value={50}>
                    50
                  </option>
                  <option key={100} value={100}>
                    100
                  </option>
                  <option key={200} value={200}>
                    200
                  </option>
                </select>
              </div>
              <div className="col-lg-7 col-md-6 col-sm-12 col-xl-7">
                <Pagination
                  count={totalPage}
                  onChange={clickPaginationBtn}
                  size="large"
                  page={currentPage}
                />
              </div>
            </div>
          )}

          {auth.isSignedIn && (
            <div className="row mb-2">
              <div className="col">
                <>
                  <span
                    className="tab-view-all cursor-pointer-hand text-decoration-underline mr-2"
                    onClick={handleSelectionAll}
                  >
                    {showSelectAll ? "Select All" : "Deselect All"}
                  </span>
                  <span className="mr-2">|</span>
                  <span
                    className="tab-view-all cursor-pointer-hand text-decoration-underline mr-2"
                    onClick={handleCreateTag}
                    style={{ opacity: selectedVar.length > 0 ? "" : 0.4 }}
                  >
                    Create Tag
                  </span>
                  <span
                    className="tab-view-all cursor-pointer-hand text-decoration-underline mr-2"
                    onClick={handleAddToTag}
                    style={{ opacity: selectedVar.length > 0 ? "" : 0.4 }}
                  >
                    Add to Tag
                  </span>
                  <span className="mr-2">|</span>
                </>

                <span
                  className="tab-view-all cursor-pointer-hand text-decoration-underline mr-2"
                  onClick={() => addRemoveFromCart(null, "add")}
                  style={{ opacity: selectedVar.length > 0 ? "" : 0.4 }}
                >
                  Save to MyGSS
                  <Tooltip
                    arrow
                    interactive
                    title={
                      <div>
                        Allows you to add multiple variables to MyGSS for
                        further analysis at once.{" "}
                      </div>
                    }
                  >
                    <InfoIcon
                      fontSize="small"
                      style={{ height: "10px" }}
                      className="plus-icon"
                    />
                  </Tooltip>
                </span>
                <span
                  className="tab-view-all cursor-pointer-hand text-decoration-underline mr-2"
                  onClick={onAddToExtract}
                  style={{ opacity: selectedVar.length > 0 ? "" : 0.4 }}
                >
                  Add to Extract
                </span>
              </div>
            </div>
          )}

          {type == 1 && (
            <div className="container gss_table mt-1">
              <VariableItems
                variables={variableMerged}
                selectedVar={selectedVar}
                setSelectedVar={setSelectedVar}
                changeCheckbox={changeCheckbox}
                filteredYears={filteredYears}
                selectedYears={selectedYears}
                isRangeSelected={isRangeSelected}
                handleMyGSS={handleMyGSS}
                isExpanded={isExpanded}
                type={type}
                toggleTags={toggleTags}
              />
            </div>
          )}

          {type == 2 &&
            Object.values(getAllTagCategories).map((value, index) => (
              <>
                <div
                  className={
                    "row  align-items-center" + (index > 0 ? " mt-5" : "")
                  }
                >
                  <div className="col-auto">
                    <span className="tag-category-text">
                      <b>{value.tagName}</b>
                    </span>
                  </div>
                  <div className="col-auto">
                    <span className="font-medium">
                      {value.variables.length}{" "}
                      {"variable" + (value.variables.length > 1 ? "s" : "")}
                    </span>
                  </div>
                </div>
                <div className="tag-created-by w-50 mt-1">
                  {value.createdBy ? value.createdBy : ""}
                </div>
                <div className="container gss_table mt-1">
                  <VariableItems
                    variables={value.variables}
                    selectedVar={selectedVar}
                    setSelectedVar={setSelectedVar}
                    changeCheckbox={changeCheckbox}
                    filteredYears={filteredYears}
                    selectedYears={selectedYears}
                    isRangeSelected={isRangeSelected}
                    handleMyGSS={handleMyGSS}
                    isExpanded={isExpanded}
                    type={type}
                  />
                </div>
              </>
            ))}

          {type == 3 &&
            Object.values(getAllModules).map((value, index) => (
              <>
                <div
                  className={
                    "row  align-items-center" + (index > 0 ? " mt-5" : "")
                  }
                >
                  <div className="col-auto">
                    <span className="tag-category-text">
                      <b>{value.tagName}</b>
                    </span>
                  </div>
                  <div className="col-auto">
                    <span className="font-medium">
                      {value.variables.length}{" "}
                      {"variable" + (value.variables.length > 1 ? "s" : "")}
                    </span>
                  </div>
                </div>
                <div className="container gss_table mt-1">
                  <VariableItems
                    variables={value.variables}
                    selectedVar={selectedVar}
                    setSelectedVar={setSelectedVar}
                    changeCheckbox={changeCheckbox}
                    filteredYears={filteredYears}
                    selectedYears={selectedYears}
                    isRangeSelected={isRangeSelected}
                    handleMyGSS={handleMyGSS}
                    isExpanded={isExpanded}
                    type={type}
                  />
                </div>
              </>
            ))}
        </div>
      )}
    </div>
  );
};

SearchPage.defaultProps = {
  isAnalysisOrExtract: false,
  setToggelView: () => "",
};

export default SearchPage;
