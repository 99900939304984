import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Dialog,
    TextareaAutosize,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import Modal from "react-bootstrap/Modal";
  import "./ShareExtract.css";
  const ShareExtract = (props) => {
    const [open, setOpen] = useState(false);
    const [inputText, setInputText] = useState('');
  const [isEdit,SetEdit] = useState('')
    React.useEffect(() => {
      // component updated Temporary Code
      if(props.isOpen && !open) {
         handleClickOpen();
         // set isOpen as false to prevent erasing your data again.
        props.setIsOpen();
        SetEdit(props.isEdit)
        if(props.isEdit){
        setInputText(props.comment.comment)}
      else{
         setInputText('');
      }
    }});
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={"custom-modal " + props.class_name}
      >
        <DialogTitle id="max-width-dialog-title" className="modal_title">
          Share Extract
        </DialogTitle>
        <DialogContent className="modal_content">
          <div className="modal-body" id="extractShare">
            <form id="share_form" >
              <h5>
                <i className="fa fa-globe"></i>
Public link
</h5>
              <p>Anyone with the link to your extract can view it. They will not have access to make changes.</p>
              <input id="link" readonly="" className="shareLink" type="text" value="https://gssdataexplorer.norc.org/projects/63723/extracts/73431?token=1MVX16kGtxxva9N_pwDa"/>
                <p>You can share the link by entering the email addresses below and pressing the "Send" button.</p>
                <div id="email-input">
                  {/* <div className="alert-success hidden" id="share-success">The link has been successfully sent.</div>
                  <div className="alert-danger hidden" id="invalid-email">Please enter a valid email address; for example, address@email.com</div> */}
                  <div className="input-group" id="email-individual">
                    <span className="input-group-addon fa fa-envelope"></span>
                    <input type="email" name="email" id="emails" value="" placeholder="Enter email addresses" className="tagit-hidden-field" /><ul className="tagit ui-widget ui-widget-content ui-corner-all"><li className="tagit-new">
                    <input type="email" className="ui-widget-content ui-autocomplete-input" autoComplete="off" /></li></ul>
                  </div>

                  <a className="btn btn-dark pull-right" disabled="disabled" href="#">Send</a>
                  <div className="clearfix"></div>
                </div>
</form>

              <h5>
                <i className="fa fa-user"></i>
Collaborate with other people
</h5>
              <p>To allow others to edit or comment on this extract, they must have access to the project it belongs to. You can share the project with others using the project settings dialog.</p>
</div>
        </DialogContent>
  
        <DialogActions className="modal_footer">
          <div className="col-md-6 btn-one" onClick={() => {
            setOpen(false);
            props.handleSubmit(inputText);
            setInputText('');
            }}>
            <Button classes={{ label: "btn-orange primary-link" }}>
           Done
            </Button>
          </div>
          <div className="col-md-6 btn-two">
            {/* <Button onClick={handleClose} classes={{ label: "secondary-link" }}>
              CANCEL
            </Button> */}
          </div>
        </DialogActions>
      </Dialog>
    );
  };
  export default ShareExtract;
  
  ShareExtract.protoTypes = {};
  