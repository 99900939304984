import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
// import Helmet from "react-helmet";
import ReactToPrint from "react-to-print";
import ExportAnalysis from "../../AnalysisManagement/ExportAnalysis/ExportAnalysis";
function ShareButton(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const qus = props.dropdownvalue ? props.dropdownvalue : "";
  const breakdown = props.selectedBreakDown ? props.dropdownvalue : "";
  console.log(props.url, "twitter url");
  return (
    <>
      {/* <Helmet>
        <meta name="description" content=" " data-rh="true"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" data-rh="true"/>
        <meta name="twitter:card" content="summary" data-rh="true" />
        <meta name="twitter:title" content=" " data-rh="true"/>
        <meta name="twitter:description" content=" " data-rh="true" />
        <meta name="twitter:image" content="https://gssdataexplorer.norc.org/static/media/gss.d87acf6d.png" data-rh="true" />
      </Helmet> */}
      <div className="row d-flex justify-content-end mb-2">
        <button
          className="btn btn-gray hb rounded-0 font-weight-bold font-medium mr-1"
          onClick={handleClickOpen}
        >
          SHARE
        </button>
        {/* <Button
          style={{ marginLeft: "10px" }}
          variant="contained"
          onClick={handleClickOpen}
        >
          SHARE
        </Button> */}

        <ReactToPrint
          trigger={() => (
            <button
              className="btn btn-gray hb rounded-0 font-weight-bold mr-1"
              style={{ height: "2.15rem" }}
              disabled={
                props.toggleTable
                  ? props.years && props.years.length > 21
                  : false
              }
              title={
                props.toggleTable
                  ? props.years && props.years.length > 21
                    ? "Due to too many columns this table cannot be printed."
                    : ""
                  : ""
              }
            >
              <i className="fa fa-print"></i>
            </button>
          )}
          content={() => props.componentRef.current}
        />

        {/* <Button
        style={{ marginLeft: "10px" }}
        variant="contained"
        onClick={window.print}
      >
        <LocalPrintshopTwoToneIcon />
      </Button> */}

        <div style={{ display: "inline-block" }} className="mr-1">
          <ExportAnalysis
            isTrends={true}
            exportExcel={props.exportTrendsExcel}
            exportPng={props.exportTrendsExcel}
            componentRef={props.componentRef}
          />
        </div>
        {/* <button
          className="btn btn-gray hb rounded-0 font-weight-bold font-medium mr-1"
          onClick={props.exportTrendsExcel}
          disabled={props.exportFlag}
        >
          EXPORT
        </button> */}

        {/* <Button
          style={{ marginLeft: "10px" }}
          variant="contained"
          onClick={props.exportTrendsExcel}
          disabled={props.exportFlag}
        >
          EXPORT
        </Button> */}
        <button
          className="btn btn-gray hb rounded-0 font-weight-bold font-medium mr-1"
          onClick={() => props.setToggleTable(!props.toggleTable)}
        >
          {props.toggleTable ? "CHART" : "TABLE"}
        </button>
        {/* <Button style={{ marginLeft: '10px' }} variant="contained" onClick={() => props.setToggleTable(!props.toggleTable)}>
          {props.toggleTable ? "CHART" : "TABLE"}
        </Button> */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">{"Share Trends"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FacebookShareButton
                url={props.url}
                quote={
                  "Use this link to check latest Trends:" +
                  "\n" +
                  props.socialMediaUrl
                }
              >
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
              &nbsp; &nbsp;
              <TwitterShareButton
                // title={'Use below link to check latest Trends' + '\n \n' + props.socialMediaUrl}
                // url={props.url}
                title={"Use below link to check latest Trends" + "\n \n"}
                url={props.socialMediaUrl}
              >
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="clearfix" />
    </>
  );
}

export default ShareButton;
