import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { environment } from "../../../environment";
import Popover from "@material-ui/core/Popover";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Container, Row, Col } from "react-bootstrap";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { Chip, Paper, TextField, FormControlLabel } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector } from "react-redux";
import _ from "lodash";
import "./SearchByTags.css";
import { GreenCheckbox } from "../../../Store/utils/common.utils";
import { useLocation } from "react-router-dom";
import ClearFilterContext from "../../../Store/ContextApi/ClearFilterContext";
import { messageService } from "../../../Shared/Message";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 640,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    height: 400,
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
    float: "left",
    listStyle: "none",
    width: "100%",
    height: 38,
    padding: 2,
    margin: 0,
    border: "1px solid #d3d3d3",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  typography: {
    padding: theme.spacing(2),
    width: 400,
  },
}));

export default function SearchByTags({
  finalSubMod,
  finalYourSharedTags = { myTags: [39], sharedTags: [39] },
  selectedTags,
  ...props
}) {
  const { clearBool, setClearBool } = useContext(ClearFilterContext);
  const search = useLocation().search;
  const user_search_id = new URLSearchParams(search).get("user_search_id");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [modules, setModules] = useState([]);
  const [holdModules, setHoldModules] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [holdSubjects, setHoldSubjects] = useState([]);
  const [yourTags, setYourTags] = useState([]); //
  const [holdYourTags, setHoldYourTags] = useState([]);
  const [sharedTags, setSharedTags] = useState([]);
  const [holdSharedTags, setHoldSharedTags] = useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [anchorMoreEl, setAnchorMoreEl] = useState(null);
  const authDetails = useSelector((state) => state.authReducer);
  const [tagsObj, setTagsObj] = useState({});
  const userSearchesDetails = useSelector((state) => state.userSearchesReducer);
  const fetchClearObj = () => {
    let obj = {
      subjects: finalSubMod.subjects ? [...finalSubMod.subjects] : [],
      modules: finalSubMod.modules ? [...finalSubMod.modules] : [],
      my_tags: finalYourSharedTags.myTags
        ? [...finalYourSharedTags.myTags]
        : [],
      shared_tags: finalYourSharedTags.sharedTags
        ? [...finalYourSharedTags.sharedTags]
        : [],
    };
    return obj;
  };
  const [tagsArr, setTagsArr] = useState(fetchClearObj());

  //To clear old filters
  useEffect(() => {
    if (!props.showClearFilter) {
      setTagsArr(fetchClearObj());
      setYourSharedTags({
        myTags: [],
        sharedTags: [],
      });
      setSelectedTagsObj({
        myTagsObj: [],
        sharedTagsObj: [],
      });
    }
  }, [props.showClearFilter]);

  const [yourSharedTags, setYourSharedTags] = useState({
    myTags: [],
    sharedTags: [],
  });

  const [selectedTagsObj, setSelectedTagsObj] = useState({
    myTagsObj: [],
    sharedTagsObj: [],
  });
  useEffect(() => {
    if (authDetails.isSignedIn) {
      getYourTags();
    } else {
      fetchSubjectModules();
    }

    if (
      userSearchesDetails?.usersearches_data?.usersearches_data?.id &&
      user_search_id
    ) {
      viewEditTags();
    }
    messageService.onMessage().subscribe((message) => {
      if (message.text == "clear") {
        props.setSelectedTags(new Set());
        props.setSubMod(fetchClearObj());
        props.setYourSharedTag({
          myTags: [],
          sharedTags: [],
        });
      }
    });
  }, []);

  useEffect(() => {
    if (searchTerm || searchTerm !== "") {
      if (activeIndex == 0) {
        let result = holdYourTags.filter((el) =>
          el.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setYourTags(result);
      } else if (activeIndex == 1) {
        let result = holdSharedTags.filter((el) =>
          el.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSharedTags(result);
      } else if (activeIndex == 2) {
        let result = holdSubjects.filter((el) =>
          el.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSubjects(result);
      } else if (activeIndex == 3) {
        let result = holdModules.filter((el) =>
          el.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setModules(result);
      }
    } else {
      if (activeIndex == 0) {
        setYourTags(holdYourTags);
      } else if (activeIndex == 1) {
        setSharedTags(holdSharedTags);
      } else if (activeIndex == 2) {
        setSubjects(holdSubjects);
      } else if (activeIndex == 3) {
        setModules(holdModules);
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (clearBool) {
      props.setSelectedTags(new Set());
      props.setSubMod(fetchClearObj());
      props.setYourSharedTag({
        myTags: [],
        sharedTags: [],
      });
    }
  }, [clearBool]);

  const viewEditTags = () => {
    let mock_data = userSearchesDetails?.usersearches_data?.usersearches_data;

    tagsArr.modules = mock_data?.criteria?.module
      ? mock_data?.criteria?.module
      : [];
    tagsArr.subjects = mock_data?.criteria?.subjects
      ? mock_data?.criteria?.subjects
      : [];
    if (
      mock_data?.criteria?.my_tags &&
      mock_data?.criteria?.my_tags.length > 0
    ) {
      mock_data?.criteria?.my_tags.map((el) => {
        if (yourSharedTags.myTags.indexOf(el.name) == -1) {
          tagsArr.my_tags.push(el.name);
          yourSharedTags.myTags.push(el.id);
        }
      });
    }
    if (
      mock_data?.criteria?.shared_tags &&
      mock_data?.criteria?.shared_tags.length > 0
    ) {
      mock_data?.criteria?.shared_tags.map((el) => {
        if (yourSharedTags.sharedTags.indexOf(el.id) == -1) {
          tagsArr.shared_tags.push(el.name);
          yourSharedTags.sharedTags.push(el.id);
        }
      });
    }
    let finalarr = [].concat(
      mock_data?.criteria?.subjects,
      mock_data?.criteria?.module,
      tagsArr?.my_tags,
      tagsArr?.shared_tags
    );
    finalarr = finalarr.filter(function (val) {
      return val !== null;
    });
    props.setSelectedTags(new Set(finalarr));
    setTagsObj(tagsArr);
    props.setSubMod(tagsArr);
    props.setYourSharedTag(yourSharedTags);
  };
  const handleMoreClick = (event) => {
    setAnchorMoreEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorMoreEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    getYourTags()
    getSharedTags()
    fetchSubjectModules();
  };

  const handleClose = () => {
    setValue(0);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openMore = Boolean(anchorMoreEl);
  const idMore = openMore ? "simple-popover" : undefined;

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    event.stopPropagation();
    if (value == newValue) {
    }
    if (newValue == "0") {
      authDetails.isSignedIn || localStorage.isSignedIn === "true"
        ? getYourTags()
        : fetchSubjectModules();
    } else if (newValue == "1") {
      authDetails.isSignedIn || localStorage.isSignedIn === "true"
        ? getSharedTags()
        : fetchSubjectModules();
    } else if (newValue == "2" || newValue == "3") {
      fetchSubjectModules();
    }

    setActiveIndex(newValue);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const fetchSubjectModules = () => {
    axios
      .get(process.env.PUBLIC_URL + "/config/subjects_and_modules.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      })
      .then((response) => {
        setModules(response.data?.models);
        setHoldModules(response.data?.models);
        setSubjects(response.data?.subjects);
        setHoldSubjects(response.data?.subjects);
      });
  };
  const getYourTags = () => {
    axios.get(`${environment.getMyTags}`).then((response) => {
      setYourTags(response.data.user_info);
      setHoldYourTags(response.data.user_info);
    });
  };

  const getSharedTags = () => {
    axios.get(`${environment.getSharedTags}`).then((response) => {
      setSharedTags(response.data);
      setHoldSharedTags(response.data);
    });
  };

  const handleTagsChange = (item, title, event) => {
    event.stopPropagation();
    let value = event.target.value;
    if (event.target.checked) {
      if (title == "modules") {
        tagsArr.modules.push(value);
      } else if (title == "subjects") {
        tagsArr.subjects.push(value);
      }
      if (title == "yourTags") {
        tagsArr.my_tags.push(value);
        if (yourSharedTags.myTags.indexOf(item.id) == -1) {
          yourSharedTags.myTags.push(item.id);
          selectedTagsObj.myTagsObj.push(item);
        }
      } else if (title == "sharedTags") {
        tagsArr.shared_tags.push(value);
        if (yourSharedTags.sharedTags.indexOf(item.id) == -1) {
          yourSharedTags.sharedTags.push(item.id);
          selectedTagsObj.sharedTagsObj.push(item);
        }
      }
      setTagsObj(tagsArr);
      props.setSubMod(tagsArr);
      props.setYourSharedTag(yourSharedTags);
      selectedTags.add(value);
      props.setSelectedTags(selectedTags);
    } else {
      selectedTags.delete(value);
      props.setSelectedTags(selectedTags);
      try {
        let obj = tagsArr;
        let yourTagObj = yourSharedTags;
        let selTagsObj = selectedTagsObj;
        if (title == "subjects" && _.includes(obj?.subjects, value)) {
          obj.subjects = _.pull(obj?.subjects, value);
        }
        if (title == "modules" && _.includes(obj?.modules, value)) {
          obj.modules = _.pull(obj?.modules, value);
        }
        if (title == "yourTags" && _.includes(obj?.my_tags, value)) {
          obj.my_tags = _.pull(obj?.my_tags, value);
          yourTagObj.myTags = _.pull(yourTagObj.myTags, item.id);
          selTagsObj.myTagsObj = _.remove(
            selTagsObj.myTagsObj,
            (el) => el.id == item.id
          );
        }

        if (title == "sharedTags" && _.includes(obj?.shared_tags, value)) {
          obj.shared_tags = _.pull(obj?.shared_tags, value);
          yourTagObj.sharedTags = _.pull(yourTagObj.sharedTags, item.id);
          selTagsObj.sharedTagsObj = _.remove(
            selTagsObj.sharedTagsObj,
            (el) => el.id == item.id
          );
        }
        setTagsArr(obj);
        props.setSubMod(obj);
        props.setYourSharedTag(yourTagObj);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleDelete = (chipToDelete) => () => {
    let data = [...selectedTags].filter((chip) => chip != chipToDelete);
    props.setSelectedTags(new Set(data));
    if ([...selectedTags].length - 1 <= 1) {
      if (anchorMoreEl !== null) {
        setAnchorMoreEl(null);
      }
    }
    try {
      let obj = tagsArr;
      let yourTagObj = yourSharedTags;
      let selTagsObj = selectedTagsObj;
      if (_.includes(obj?.subjects, chipToDelete)) {
        obj.subjects = _.pull(obj?.subjects, chipToDelete);
      }
      if (_.includes(obj?.modules, chipToDelete)) {
        obj.modules = _.pull(obj?.modules, chipToDelete);
      }
      if (_.includes(obj?.my_tags, chipToDelete)) {
        obj.my_tags = _.pull(obj?.my_tags, chipToDelete);
        selTagsObj.myTagsObj = _.remove(
          selTagsObj.myTagsObj,
          (item) => item.id == chipToDelete
        );
        yourTagObj.myTags = selTagsObj.myTagsObj.map((user) => user.id);
      }
      if (_.includes(obj?.shared_tags, chipToDelete)) {
        obj.shared_tags = _.pull(obj?.shared_tags, chipToDelete);
        selTagsObj.sharedTagsObj = _.remove(
          selTagsObj.sharedTagsObj,
          (item) => item.id == chipToDelete
        );
        yourTagObj.sharedTags = selTagsObj.sharedTagsObj.map((user) => user.id);
      }
      props.setSubMod(obj);
      props.setYourSharedTag(yourTagObj);
    } catch (e) {
      console.log(e);
    }
  };

  const searchTags = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Paper
        component="ul"
        className={classes.paper + " d-flex justify-content-between"}
        aria-describedby={id}
      >
        {[...selectedTags].length == 0 ? (
          <li style={{ padding: 5, opacity: 0.4 }}>No tag selected</li>
        ) : (
          <>
            {[...selectedTags].map((data, i) => {
              return (
                <>
                  {i < 1 && (
                    <li>
                      <Chip
                        label={data}
                        title={data}
                        onDelete={handleDelete(data)}
                        className="rounded-0 subject-label"
                        style={{
                          backgroundColor: "#73a492",
                          textOverflow: "ellipsis",
                          width: 100,
                        }}
                      />
                    </li>
                  )}
                </>
              );
            })}
            &nbsp;&nbsp;
            <span
              className="mt-2"
              onClick={handleMoreClick}
              style={{ cursor: "pointer" }}
            >
              {" "}
              {[...selectedTags].length > 1 && (
                <i className="fa fa-plus" aria-hidden="true">
                  &nbsp;
                  <strong>{[...selectedTags].length - 1 + " more"}</strong>{" "}
                </i>
              )}
            </span>
          </>
        )}

        <span className="d-flex align-items-center" onClick={handleClick}>
          <ExpandMoreIcon />
        </span>
      </Paper>
      <Popover
        id={idMore}
        open={openMore}
        anchorEl={anchorMoreEl}
        onClose={handleMoreClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          {[...selectedTags].map((data, i) => {
            return (
              <Chip
                label={data}
                title={data}
                onDelete={handleDelete(data)}
                className="rounded-0 subject-label mr-2 mb-2"
                style={{
                  backgroundColor: "#73a492",
                  textOverflow: "ellipsis",
                  width: 100,
                }}
              />
            );
          })}
        </Typography>
      </Popover>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.root}>
          <Container className="1 h-100" id="searchByView">
            <Row className=" d-flex align-items-center">
              <Col className=" flex-grow-1">
                <TextField
                  type="search"
                  variant="outlined"
                  margin="normal"
                  placeholder="Search for tags"
                  onChange={searchTags}
                  value={searchTerm}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>

            <AppBar position="static" color="default">
              <Tabs
                value={value}
                className="searchtags"
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#4f977d",
                  },
                }}
                aria-label="full width tabs example"
              >
                {/* classsName={value == 3 ? 'hiddenTab' : '' */}
                {authDetails.isSignedIn && (
                  <Tab label="Your Tags" {...a11yProps(0)} />
                )}
                {authDetails.isSignedIn && (
                  <Tab label="Shared Tags" {...a11yProps(1)} />
                )}
                <Tab
                  label="GSS Tags"
                  {...a11yProps(authDetails.isSignedIn ? 2 : 0)}
                />
                <Tab
                  label="Modules"
                  {...a11yProps(authDetails.isSignedIn ? 3 : 1)}
                />
              </Tabs>
            </AppBar>
            {authDetails.isSignedIn ? (
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  {yourTags && (
                    <div
                      className="w-100 gss-analysis-years "
                      style={{ columnCount: 3 }}
                    >
                      {Object.keys(yourTags).map((item) => {
                        let cval = yourTags[item].name;
                        return (
                          <div className="form-check">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={
                                    !!(tagsArr.my_tags.indexOf(cval) !== -1)
                                  }
                                  onChange={(event) =>
                                    handleTagsChange(
                                      yourTags[item],
                                      "yourTags",
                                      event
                                    )
                                  }
                                  value={cval}
                                />
                              }
                              label={
                                yourTags[item].name +
                                "(" +
                                yourTags[item].variable_count +
                                ")"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  {sharedTags && (
                    <div
                      className="w-100 gss-analysis-years "
                      style={{ columnCount: 3 }}
                    >
                      {Object.keys(sharedTags).map((item) => {
                        let cval = sharedTags[item].name;
                        return (
                          <div className="form-check">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={
                                    !!(tagsArr.shared_tags.indexOf(cval) !== -1)
                                  }
                                  onChange={(event) =>
                                    handleTagsChange(
                                      sharedTags[item],
                                      "sharedTags",
                                      event
                                    )
                                  }
                                  value={cval}
                                />
                              }
                              label={
                                sharedTags[item].name +
                                "(" +
                                sharedTags[item].variable_count +
                                ")"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  {subjects && (
                    <div
                      className="w-100 gss-analysis-years "
                      style={{ columnCount: 3 }}
                    >
                      {Object.keys(subjects).map((item, index) => {
                        let cval = subjects[item].description;
                        return (
                          <div className="form-check">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={
                                    !!(tagsArr.subjects.indexOf(cval) !== -1)
                                  }
                                  onChange={(event) =>
                                    handleTagsChange(
                                      subjects[item],
                                      "subjects",
                                      event
                                    )
                                  }
                                  value={cval}
                                />
                              }
                              label={
                                subjects[item].description +
                                "(" +
                                subjects[item].count +
                                ")"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                  {modules && (
                    <div
                      className="w-100 gss-analysis-years "
                      style={{ columnCount: 3 }}
                    >
                      {Object.keys(modules).map((item, index) => {
                        let cval = modules[item].description;
                        return (
                          <div className="form-check">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={
                                    !!(tagsArr.modules.indexOf(cval) !== -1)
                                  }
                                  onChange={(event) =>
                                    handleTagsChange(
                                      modules[item],
                                      "modules",
                                      event
                                    )
                                  }
                                  value={cval}
                                />
                              }
                              label={
                                modules[item].description +
                                "(" +
                                modules[item].count +
                                ")"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
              </SwipeableViews>
            ) : (
              <>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  {subjects && (
                    <div
                      className="w-100 gss-analysis-years "
                      style={{ columnCount: 3 }}
                    >
                      {Object.keys(subjects).map((item, index) => {
                        let cval = subjects[item].description;
                        return (
                          <div className="form-check">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={selectedTags.has(cval)}
                                  onChange={(event) =>
                                    handleTagsChange(
                                      subjects[item],
                                      "subjects",
                                      event
                                    )
                                  }
                                  value={cval}
                                />
                              }
                              label={
                                subjects[item].description +
                                "(" +
                                subjects[item].count +
                                ")"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  {modules && (
                    <div
                      className="w-100 gss-analysis-years "
                      style={{ columnCount: 3 }}
                    >
                      {Object.keys(modules).map((item, index) => {
                        let cval = modules[item].description;
                        return (
                          <div className="form-check">
                            <FormControlLabel
                              control={
                                <GreenCheckbox
                                  checked={selectedTags.has(cval)}
                                  onChange={(event) =>
                                    handleTagsChange(
                                      modules[item],
                                      "modules",
                                      event
                                    )
                                  }
                                  value={cval}
                                />
                              }
                              label={
                                modules[item].description +
                                "(" +
                                modules[item].count +
                                ")"
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPanel>
              </>
            )}
          </Container>
        </div>
      </Popover>
    </>
  );
}
