import right_arrow from "../../../Images/right_arrow.jpg";
import small_boxes from "../../../Images/small_boxes.jpg";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import Contact from "../../UserManagement/user-contact-creation/Contact";
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const userNamesById = { 1: "John" };
const DynamicUserBreadcrumb = ({ match }) => (
  <span>{userNamesById[match.params.userId]}</span>
);

// define custom breadcrumbs for certain routes.
// breadcumbs can be components or strings.
const routes = [
  { path: "/users/:userId", breadcrumb: DynamicUserBreadcrumb },
  { path: "/user/contact", breadcrumb: Contact, name: "contact" },
];

//map & render your breadcrumb components however you want.
export default function Breadcrumbs(props) {
  const analysisDetails = useSelector((state) => state.analysisReducer);
  const extractsDetails = useSelector((state) => state.extractReducer);
  const trendsName = useSelector((state) => state.trendsReducer);
  const authDetails = useSelector(state => state.authReducer);
  const search = useLocation().search;
  const backText = new URLSearchParams(search).get('back')
  const [routeText, setRouteText] = useState("");
  const breadcrumbs = useBreadcrumbs(props.data);
  const [isMulti, setMulti] = useState(false);
  const [secondRoute, setSecondRoute] = useState();
  const routeChange = (url, breadcrumb) => {
    let route;
    route = props.data.filter((val) => {
      if (val.path == url) {        
        route = val.name;
        return route;
      }
    });
    if (route.length > 0 && route[0].name != "None") {
      return route[0].name;
    }
  };

  const getTitle = (route) => {
    if (route.key.includes("analysis_summary")) {
      if(analysisDetails?.analysis_data?.analysis_data?.analysisName && !backText){
      return analysisDetails?.analysis_data?.analysis_data?.analysisName;}
      if(backText =='tabulation'){
      return analysisDetails?.analysis_name
      }
    }else if(route.key.includes("extract_summary")){
     
      if(extractsDetails?.extract_data?.data?.name && !backText){
      return extractsDetails?.extract_data?.data?.name;}
      if(backText == "extract") {
        return extractsDetails?.extract_name
      }
     
    }else if(route.key.includes("vtrends")){
        return trendsName?.trend_name
    }
     else {
      return route?.props?.children;
    }
  };

  return (
    <div className="ml-3">
      {breadcrumbs.slice(-1).map(({ match, breadcrumb, arr }) => (
        <div>
          {routeChange(match.path, breadcrumb) && (
            <span key={match.url}>
              <div className="cmd-img pull-left ml-2 mt-2">
                <>
                  {" "}
                  <span>
                  {authDetails.isSignedIn || localStorage.isSignedIn === 'true'? <NavLink to="/MyGSS" style={{ color: "#f3901d" }}>
                      <img src={small_boxes} className="mr-1" />
                      <strong>MY GSS</strong>
                    </NavLink> : <a href="#" style={{ color: "#f3901d" }}>
                      <img src={small_boxes} className="mr-1" />
                      <strong>MY GSS</strong>
                      </a>}
                  </span>
                  {!(backText == 'tabulation' || backText == "extract")  && <>  <img
                    className="small_arrow ml-1"
                    src={right_arrow}
                  />
              
                &nbsp;
                <NavLink to={(routeChange(match.path, breadcrumb) == "Tabulations")? "/analysisHistory" : (routeChange(match.path, breadcrumb) =="Extracts")? "/extractHistory" : match.url} className="text-secondary">
                  {routeChange(match.path, breadcrumb)}
                </NavLink> </>   }
                </>
                {breadcrumb?.props?.children != "None" && (
                  <>
                    <img
                      className="small_arrow ml-1"
                      src={right_arrow}
                    />
                    &nbsp;
                    <NavLink to={match.url} exact className="text-secondary">
                      {getTitle(breadcrumb)}
                    </NavLink>
                  </>
                )}
              </div>
              <div className="clearfix"></div>
            </span>
          )}
        </div>
      ))}
    </div>
  );
}
