import React, { useState } from "react";
import BigButton from "../BigButton/BigButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import EnhancedTableCheckbox from "../EnhancedTableCheckbox/EnhancedTableCheckbox";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { createTag } from "../../../Store/actions/variable";
import ClearIcon from "@material-ui/icons/Clear";
import "./CreateTagModel.css";

function getModalStyle() {
  return {
    overflow: "scroll",
  };
}

const CreateTagModel = ({ rows, selected, modalShow, isCopy, ...props }) => {
  const dispatch = useDispatch();
  const [isSub, setIsSub] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tagname, setTagname] = useState("");

  const onSubmit = () => {
    if (selected.length === 0 || tagname == "") {
      alert("Invalid data");
      return;
    }

    let workplace_id = localStorage.defaultProjectNo
      ? parseInt(localStorage.defaultProjectNo)
      : "";
    let variables = rows.map((data) => ({
      variable_id: data.vID,
      variable_name: data.name,
      label: data.group,
    }));
    let reqObj = { tag: { name: tagname.trim(), workplace_id }, variables };

    setIsSub(true);
    dispatch(createTag(reqObj))
      .then(() => {
        onClose();
        props.getVariablelist();
      })
      .catch((err) => {
        setIsSub(false);
        alert(err);
        return;
      });
  };

  const onTagNameChange = ({ target: { value } }) => {
    var regex = new RegExp("[A-Za-z0-9~`!#$%^&*()_+-]+");
    if (regex.test(value)) {
      setTagname(value);
    } else {
      setTagname("");
    }
  };

  const onClose = () => {
    if (isCopy) {
      props.setSelected([]);
      props.setSelectedObj([]);
    }
    props.setAddTagModal(false);
  };

  return (
    <Dialog
      open={modalShow}
      style={getModalStyle()}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      className="tag-model h-auto"
      disableBackdropClick={true}
      fullWidth={true}
    >
      <DialogTitle>
        <Col as="span" className="px-3 py-1 m-0">
          {isCopy ? "Copy Tag" : "Create Tag"}
        </Col>
        <Col as="span" className="px-3 py-1 m-0">
          <ClearIcon className="float-right clear-icon" onClick={onClose} />
        </Col>
      </DialogTitle>
      <DialogContent className="h-auto">
        <Row>
          <Col className="ml-1 label">Name Tag</Col>
        </Row>
        <Row>
          <Col
            sm={12}
            md={12}
            lg={12}
            className="p-0 d-flex justify-content-left  flex-grow-2 align-items-center"
          >
            <Form.Control
              className="ml-3 mb-3 input"
              type="text"
              maxLength={35}
              placeholder="Enter tag name"
              value={tagname}
              onChange={(e) => onTagNameChange(e)}
            />
          </Col>
        </Row>
        <div className={"custom-scroll mx-ht-350"}>
          <EnhancedTableCheckbox
            rows={rows}
            showPagination={false}
            selected={rows.map((n) => n.vID)}
            page={page}
            rowsPerPage={rowsPerPage}
            disableCheckBox={true}
            setPage={setPage}
          />
        </div>
      </DialogContent>
      <DialogActions className="">
        <BigButton classname="btn-cancel" onClick={onClose}>
          CANCEL
        </BigButton>
        <BigButton
          classname="btn-orange btn-save"
          onClick={onSubmit}
          disabled={isSub || selected.length === 0 || tagname == ""}
        >
          {isCopy ? "COPY TAG" : "CREATE TAG"}
        </BigButton>
      </DialogActions>
    </Dialog>
  );
};

CreateTagModel.defaultProps = {
  isCopy: false,
};

export default CreateTagModel;
