const initialState = {    
    min_max_year:{}
   };
   const minmaxYearsReducer = (state = initialState, action) => {
   switch (action.type) {
       case 'SET_MIN_MAX_YEAR' :{
           const min_max_year = action.payload;
           return {
               ...state,
               min_max_year
           }
       }
       default:
           return state;
   }
   };
   
   export default minmaxYearsReducer;