import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import "./YearDropdown.css";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ClearFilterContext from "../../../../Store/ContextApi/ClearFilterContext";
import { setFiltered, setMinMax } from "../../../../Store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  chipRoot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  typography: {
    padding: theme.spacing(2),
    width: 400,
  },
}));
export default function YearsDropdown(props) {
  const { clearBool, setClearBool } = useContext(ClearFilterContext);
  const classes = useStyles();
  const [data, setYearsData] = useState([]);
  const [holdData, setHoldData] = useState([]);
  const [range, setRange] = useState([]);
  const [rangeSliderValue, setRangeSliderValue] = useState([]);
  const [selected, setSelected] = useState();
  const [isSpecific, setSpecific] = useState(true);
  const [anchorMoreEl, setAnchorMoreEl] = useState(null);
  const search = useLocation().search;
  const user_search_id = new URLSearchParams(search).get("user_search_id");
  const userSearchesDetails = useSelector((state) => state.userSearchesReducer);

  const dispatch = useDispatch();

  const handleMoreClick = (event) => {
    setAnchorMoreEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorMoreEl(null);
  };

  useEffect(() => {
    if (data && data.length == 0) {
      fetchYearsJson();
    }
  }, []);

  useEffect(() => {
    if (clearBool) {
      fetchYearsJson();
      toggleAll(false);
    }
  }, [clearBool]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openMore = Boolean(anchorMoreEl);
  const idMore = openMore ? "simple-popover" : undefined;

  const editYearsData = (data) => {
    let mock_data =
      userSearchesDetails?.usersearches_data?.usersearches_data?.criteria;

    if (mock_data?.yrmin && mock_data?.yrmax) {
      setSpecific(true);
      props.isSpecificYears(true);
      let rangeArr = [mock_data?.yrmin, mock_data?.yrmax];
      let filteredYears = rangeArr;

      dispatch(setFiltered({ filteredYears }));
      setRange(rangeArr);
      setRangeSliderValue(rangeArr);
      props.updateRangeYears(rangeArr);
      setYearsData(data);
      setHoldData(data);
    } else {
      let yearsArray = [];
      data.map((obj) => {
        let ballot = [];
        mock_data?.years.map((year) => {
          if (year == obj.year) {
            obj.isCheckedRowAll = true;
          }
        });

        if (obj.isCheckedRowAll && obj.ballotInfo.length == 0) {
          yearsArray.push(obj.year);
        }
        let filterd = mock_data?.yearBallot.find((d) => obj.year == d.year);
        obj.ballotInfo.map((ballots) => {
          if (filterd && filterd.ballots.indexOf(ballots.ballotInfo) !== -1) {
            ballots.isChecked = true;
          }
          if (ballots.isChecked) {
            ballot.push(ballots.ballotInfo);
          }
          return ballots;
        });
        if (ballot.length) {
          yearsArray.push(obj.year);
        }
      });
      let filteredYears = mock_data.years;

      dispatch(setFiltered({ filteredYears }));
      setYearsData(JSON.parse(JSON.stringify(data)));
      setHoldData(JSON.parse(JSON.stringify(data)));
      props.updateBallot(data);

      setSelected(yearsArray);
      props.updateYears(yearsArray);
      handleClose("close");
      setSpecific(false);
      props.isSpecificYears(false);
      setAnchorEl(null);
    }
  };
  const fetchYearsJson = () => {
    axios
      .get(process.env.PUBLIC_URL + "/config/year_range.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      })
      .then((response) => {
        setSpecific(true);
        setClearBool(false);
        updateJsonData(response.data);
        let min_max_year = [response?.data?.min_year, response?.data?.max_year];
        dispatch(setMinMax({ min_max_year }));
      });
  };

  const updateJsonData = (data) => {
    let arr = [];
    let rangeArr = [data.min_year, data.max_year];
    let filteredYears = rangeArr;
    dispatch(setFiltered({ filteredYears }));
    setRange(rangeArr);
    setRangeSliderValue(rangeArr);
    props.updateRangeYears(rangeArr);
    Object.entries(data.year_available).map(([key, value]) => {
      arr.push({
        year: parseInt(key),
        isCheckedRowAll: false,
        ballotInfo:
          value && value.length > 0
            ? value.map((item) => {
                let obj = {};
                obj.ballotInfo = item;
                obj.isChecked = false;
                return obj;
              })
            : value,
      });
    });
    if (
      userSearchesDetails?.usersearches_data?.usersearches_data?.id &&
      user_search_id
    ) {
      editYearsData(arr);
    } else {
      setYearsData(arr);
      setHoldData(arr);
    }
  };

  const handleClick = (status) => (event) => {
    event.preventDefault();
    if (status) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleSliderChange = (type) => (e) => {
    const regexp = /^[0-9\b]+$/;

    let arr = [...rangeSliderValue];
    if (type == "min") {
      arr[0] =
        e.target.value !== "" && regexp.test(e.target.value)
          ? parseInt(e.target.value)
          : "";
    } else {
      arr[1] =
        e.target.value !== "" && regexp.test(e.target.value)
          ? parseInt(e.target.value)
          : "";
    }
    setRange(arr);
    setRangeSliderValue(arr);
    props.updateRangeYears(arr);
  };

  const toggleAll = (isAllChecked) => {
    const tempArray = JSON.parse(JSON.stringify(data));
    let selArr = [];
    tempArray.map((item, key) => {
      item.isCheckedRowAll = isAllChecked;
      item.ballotInfo.map((childItems) => {
        childItems.isChecked = isAllChecked;
      });
    });
    setYearsData(tempArray);

    if (isAllChecked) {
      tempArray.map((item) => {
        selArr.push(item.year);
      });

      setSelected(selArr);
    } else {
      setSelected([]);
    }

    props.updateBallot(tempArray);
    setYearsData(tempArray);
    setHoldData(tempArray);
  };

  const handleCheckboxChange = (event, parentIndex, childIndex) => {
    const tempArray = JSON.parse(JSON.stringify(holdData));

    if (childIndex === undefined) {
      tempArray[parentIndex].isCheckedRowAll = event.target.checked;
      tempArray[parentIndex].ballotInfo.map((childItems) => {
        childItems.isChecked = event.target.checked;
      });
    } else {
      if (!event.target.checked && tempArray[parentIndex].isCheckedRowAll) {
        tempArray[parentIndex].isCheckedRowAll = false;
      } else if (
        event.target.checked &&
        !tempArray[parentIndex].isCheckedRowAll
      ) {
        let isAllRowCbChecked = true;
        tempArray[parentIndex].ballotInfo.map((childItems, index) => {
          if (!childItems.isChecked && childIndex !== index) {
            isAllRowCbChecked = false;
          }
        });
        tempArray[parentIndex].isCheckedRowAll = isAllRowCbChecked
          ? true
          : false;
      }
      tempArray[parentIndex].ballotInfo[childIndex].isChecked =
        event.target.checked;
    }

    setHoldData(tempArray);
  };

  const backTo = (e) => {
    e.preventDefault();
    setSpecific(true);
    props.isSpecificYears(true);
  };

  const handleCancel = () => {
    setHoldData(JSON.parse(JSON.stringify(data)));
    handleClose("close");
  };

  const handleApply = () => {
    setYearsData(JSON.parse(JSON.stringify(holdData)));
    props.updateBallot(holdData);
    let yearsArray = [];
    holdData.map((obj) => {
      let ballot = [];
      if (obj.isCheckedRowAll && obj.ballotInfo.length == 0) {
        yearsArray.push(obj.year);
      }

      obj.ballotInfo.map((ballots) => {
        if (ballots.isChecked) {
          ballot.push(ballots.ballotInfo);
        }
      });
      if (ballot.length) {
        yearsArray.push(obj.year);
      }
    });
    setSelected(yearsArray);
    props.updateYears(yearsArray);
    handleClose("close");
    setSpecific(false);
    props.isSpecificYears(false);
  };

  const handleDelete = (item, index) => {
    item.isCheckedRowAll = false;
    if (item.ballotInfo.length > 0) {
      item.ballotInfo.map((i) => (i.isChecked = false));
    }
    let arr = data.findIndex((d) => d.year == item.year);
    data[arr] = item;
    setYearsData(data);
    setHoldData(data);
    props.updateBallot(data);
    let yearsArray = [];
    holdData.map((obj) => {
      let ballot = [];
      if (obj.isCheckedRowAll && obj.ballotInfo.length == 0) {
        yearsArray.push(obj.year);
      }

      obj.ballotInfo.map((ballots) => {
        if (ballots.isChecked) {
          ballot.push(ballots.ballotInfo);
        }
      });
      if (ballot.length) {
        yearsArray.push(obj.year);
      }
    });
    setSelected(yearsArray);
    props.updateYears(yearsArray);
    props.checkBallotVal();
    if (
      data.filter(
        (dt) =>
          dt.isCheckedRowAll == true ||
          dt.ballotInfo.some((b) => b.isChecked == true)
      ).length <= 2
    ) {
      if (anchorMoreEl !== null) {
        setAnchorMoreEl(null);
      }
    }

    if (
      data.filter(
        (dt) =>
          dt.isCheckedRowAll == true ||
          dt.ballotInfo.some((b) => b.isChecked == true)
      ).length == 0
    ) {
      setSpecific(true);
    }
  };

  const handleClose = (type) => {
    if (type == "close") {
    } else {
      setHoldData(JSON.parse(JSON.stringify(data)));
    }
    setAnchorEl(null);
  };

  return (
    <>
      {isSpecific && (
        <>
          <span className="mr-2">
            <strong>Years:</strong>
          </span>
          <span className="mr-2">
            <input
              type="text"
              style={{ width: 50, textAlign: "center", height: 24 }}
              value={rangeSliderValue[0]}
              onInput={handleSliderChange("min")}
            />
          </span>
          <span className="mr-2">
            <strong>to</strong>
          </span>
          <span className="mr-2">
            <input
              type="text"
              style={{ width: 50, textAlign: "center", height: 24 }}
              value={rangeSliderValue[1]}
              onInput={handleSliderChange("max")}
            />
          </span>
          <span className="mr-2">
            <a href="#" onClick={handleClick("open")}>
              Select specific years
            </a>
          </span>
        </>
      )}

      {!isSpecific &&
        (data.length ? (
          <>
            <span className="mr-2">
              <strong>Years:</strong>
            </span>
            {data
              .filter(
                (dt) =>
                  dt.isCheckedRowAll == true ||
                  dt.ballotInfo.some((b) => b.isChecked == true)
              )
              .map((item, index) => {
                if (index < 2) {
                  return (
                    <Chip
                      variant="outlined"
                      size="small"
                      key={item.year + index}
                      onDelete={() => handleDelete(item, index)}
                      style={{ borderRadius: 5 }}
                      className="mr-2"
                      label={
                        item.year +
                        (item.ballotInfo.length > 0 ? ":" : "") +
                        item.ballotInfo
                          .filter((ballot) => ballot.isChecked == true)
                          .map((ballotItem) => ballotItem.ballotInfo)
                      }
                    />
                  );
                }
              })}
            <span
              className="mr-2"
              onClick={handleMoreClick}
              style={{ cursor: "pointer" }}
            >
              {data.filter(
                (dt) =>
                  dt.isCheckedRowAll == true ||
                  dt.ballotInfo.some((b) => b.isChecked == true)
              ).length > 2 && (
                <i className="fa fa-plus" aria-hidden="true">
                  &nbsp;
                  <strong>
                    {data.filter(
                      (dt) =>
                        dt.isCheckedRowAll == true ||
                        dt.ballotInfo.some((b) => b.isChecked == true)
                    ).length -
                      2 +
                      " more"}
                  </strong>{" "}
                </i>
              )}
            </span>
            <span className="mr-2">
              <a href="#" onClick={handleClick("open")}>
                Add more
              </a>
            </span>
            <span className="mr-2">
              <a href="" onClick={backTo}>
                Select over multiple years
              </a>
            </span>
          </>
        ) : (
          ""
        ))}

      <Popover
        id={idMore}
        open={openMore}
        anchorEl={anchorMoreEl}
        onClose={handleMoreClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>
          {data
            .filter(
              (dt) =>
                dt.isCheckedRowAll == true ||
                dt.ballotInfo.some((b) => b.isChecked == true)
            )
            .map((item, index) => {
              return (
                <Chip
                  variant="outlined"
                  size="small"
                  onDelete={() => handleDelete(item, index)}
                  style={{ borderRadius: 5 }}
                  className="mr-2"
                  label={
                    item.year +
                    (item.ballotInfo.length > 0 ? ":" : "") +
                    item.ballotInfo
                      .filter((ballot) => ballot.isChecked == true)
                      .map((ballotItem) => ballotItem.ballotInfo)
                  }
                />
              );
            })}
        </Typography>
      </Popover>

      <div
        id="command-bar1"
        className="text-right alt-option"
        style={{
          position: "relative",
          top: "3px",
          left: "37px",
        }}
      >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="gss-dd years">
            <div className="gss-dd-header">
              <div className="close" onClick={handleClose}>
                <i className="fa fa-times"></i>
              </div>
              <b>Select Specific Years...</b>
            </div>
            <div className="gss-dd-body">
              <div className="gss-check-div">
                <div className="ballots">
                  <div className="row">
                    <div className="col-3 pl-0 pr-0 gss-year-noballot">
                      <div className="row font-weight-bold">
                        <div className="col "></div>
                        <br />
                      </div>

                      {holdData.map((item, parentIndex) => {
                        return (
                          item.ballotInfo.length == 0 && (
                            <div className="row">
                              <div className="col">
                                <span className="mr-1">{item.year}</span>
                                <input
                                  className="m-auto"
                                  id={parentIndex + ""}
                                  name={"checbox_" + parentIndex}
                                  type="checkbox"
                                  checked={item.isCheckedRowAll}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, parentIndex)
                                  }
                                />
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                    <div className="col-9">
                      <div className="row font-weight-bold">
                        <div className="col-4">Ballot</div>
                        <div className="col">A</div>
                        <div className="col">B</div>
                        <div className="col">C</div>
                        <div className="col">D</div>
                      </div>
                      {holdData.map((item, parentIndex) => {
                        return (
                          item.ballotInfo.length > 0 && (
                            <div className="row">
                              <div className="col-4">
                                <span className="mr-1">{item.year}</span>
                                <input
                                  className="m-auto"
                                  id={parentIndex + ""}
                                  name={"checbox_" + parentIndex}
                                  type="checkbox"
                                  checked={item.isCheckedRowAll}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, parentIndex)
                                  }
                                />
                              </div>
                              {item.ballotInfo &&
                                item.ballotInfo.length > 0 &&
                                item.ballotInfo.map((ballot, index) => (
                                  <React.Fragment>
                                    <div className="col">
                                      <input
                                        className="m-auto"
                                        id={index + ""}
                                        name={"checbox_" + index}
                                        type="checkbox"
                                        checked={ballot.isChecked}
                                        onChange={(event) =>
                                          handleCheckboxChange(
                                            event,
                                            parentIndex,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </React.Fragment>
                                ))}
                              {item.ballotInfo.length === 3 && (
                                <div className="col"></div>
                              )}
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gss-dd-footer years-footer text-right">
              <button
                type="button"
                className="gss-btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
              &nbsp; &nbsp; &nbsp;
              <button
                type="button"
                className="gss-btn btn-orange"
                onClick={handleApply}
                disabled={
                  holdData.filter(
                    (dt) =>
                      dt.isCheckedRowAll == true ||
                      dt.ballotInfo.some((b) => b.isChecked == true)
                  ).length == 0
                }
              >
                Apply
              </button>
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
}
