import React from 'react'
import "./Features.css";
import FeaturesCard from './FeaturesCard';
import FeatureImg1 from '../../Images/feature-1.jpg';
import FeatureImg2 from '../../Images/feature-2.jpg';
import FeatureImg3 from '../../Images/feature-3.jpg';
import FeatureImg4 from '../../Images/feature-4.jpg';
import FeatureImg6 from '../../Images/feature-6.jpg';
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";


const Features = () => {


    return (
        <>
            
            <div className="gss-features">
                {" "}
                <React.Fragment>
                    <div>
                        <div className="gss-large-title unaligned_title">GSS Features</div>
                      
                    </div>
                </React.Fragment>
            </div>

            <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-12">
            <FeaturesCard img={FeatureImg1}
                icon=""
                mainTitle="Get Started"
                title="GSS Data Explorer makes it easier than ever to use the data collected by the GSS."
                mainText="Anyone can explore variables and questions for the entire dataset but you’ll need to create an account if you want to save variables, analyses, or visualizations. With an account, you can also save collections of data into projects and share your projects with anyone else who has a MyGSS account."
                button="true"
                buttonText="Create an account"
                light="false"

            />
            </div>
             <div class="col-sm-12 col-md-6 col-lg-12">
            <FeaturesCard img={FeatureImg2}
                icon="icons fa fa-search"
                mainTitle=""
                title="Search & Save Variables"
                mainText="Explore variables, view questions, and see basic frequencies, so you can quickly confirm that you’ve found the data you need. You can search all variables in the GSS dataset and narrow your results with filters for survey year and subject. As you find variables, you can collect them in a cart to be extracted or analyzed later."
                button="true"
                buttonText="EXPLORE GSS DATA"
                light="false" />
             </div>
             <div class="col-sm-12 col-md-6 col-lg-12">
            <FeaturesCard img={FeatureImg3}
                icon="icons fa fa-table"
                mainTitle=""
                title="Analyze Data"
                mainText="GSS Data Explorer helps you analyze data with an easy-to-use interface. Analyze variables from your cart or build your own variables. The analysis program applies default weighting for the variables you include in your cross-tabulation but gives you the options to select another available weighting. MyGSS presents your results in table, chart, or graph formats, and allows you to download your cross-tabulations as Excel or PDF files."
                light="true"/>

            <FeaturesCard img={FeatureImg4}
                icon="icons fa fa-download"
                mainTitle=""
                title="Extract Data"
                mainText="Extract data customized for your project or download the entire GSS dataset. Multiple output formats let you download data and metadata, metadata only, and control files that allow you to import data directly to SAS, SPSS, Stata, and R."
                light="false" />
            </div>
            </div>
           

        </>




    )



}

export default Features;