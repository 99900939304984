import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { environment } from '../../../environment';
import { NavLink } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
const CommonReportDatabase = () => {
    const [reportDatabase, setReportDatabase] = useState([]);

    useEffect(() => {
        getReportDatabase();
    }, []);


    const getReportDatabase = () => {
        axios.get(`${environment.reportDatabase}`).then((res) => {
            if (res?.data?.report_database_list) {
                setReportDatabase(res?.data?.report_database_list);
            } else {
                setReportDatabase([]);
            }
        });
    };

    return (
        <div
            className="card my-3 mx-3 border-1"
            style={{ height: "448px;" }}
        >
            <div className="card-header" style={{ color: "#73a492" }}>
                <span className="icon fa fa-file"></span>
                <NavLink className="green-link" to={"/gssReports/1"}>
                    <b>Report Database</b>
                </NavLink>
                &nbsp;
                <Tooltip
                          title={<div>NORC reports that discuss various topics and aspects of GSS (for more recent reports, please visit gss.norc.org)</div>}
                        >
                          <InfoIcon fontSize="small" style={{"height":'14px'}} className="plus-icon" />
                        </Tooltip>
            </div>
            <div className="card-body">
                {reportDatabase.map((data) => (
                    <p key={data.category}>
                        <NavLink
                            className="green-link"
                            to={"/gssReports/" + data.category}
                        >
                            {data.name}
                        </NavLink>
                        <p className="help-bottom">{data.description}</p>
                    </p>
                ))}
            </div>
        </div>
    )
}

export default CommonReportDatabase
