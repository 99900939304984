//******************* */ Ticket Tracker ------------------------- PSL -> NORC  START **********************//


// import React, { useEffect, useRef, useState } from "react";
// import { MenuItem, Select, TextareaAutosize } from "@material-ui/core";
// import { environment } from "../../../environment";
// import Axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
// import FormCard from "../../../Components/UI/FormCard/FormCard";
// import { useHistory } from "react-router";
// import "./Contact.css";
// import CustomModal from "../../../Components/UI/Modal/CustomModal";
// // import { ToastContainer, toast } from "react-toastify";
// import { toast } from "react-toastify";

// import "react-toastify/dist/ReactToastify.css";
// // import "react-toastify/dist/ReactToastify.css";
// function Contact(props) {
//   const [userData, setUserData] = useState({
//     User_ID: "",
//     First_Name: "",
//     Last_Name: "",
//     Email: "",
//     Phone_Number: "",
//     Occupation: "",
//     Title: "",
//     Requested_issue: "",
//     recaptchaToken: "",
//     // Issue_Related_To: "",
//   });
//   const [charLen, setCharLen] = useState(0);
//   const [charLenF, setCharLenF] = useState(0);
//   const [charLenL, setCharLenL] = useState(0);
//   const [charLenE, setCharLenE] = useState(0);
//   const [isSaving, setIsSaving] = useState(false);
//   const [errors, setErrors] = useState({
//     First_Name: null,
//     Last_Name: null,
//     email: null,
//     Occupation: null,
//     Requested_issue: null,
//     // Title: null,
//     // Issue_Related_To: null,
//   });
//   let history = useHistory();
//   const [isCaptcha, setCaptcha] = useState(false);
//   const [show, setShow] = useState(false);
//   const [varient, setVarient] = useState("");
//   const [msg, setMsg] = useState("");
//   const inputRef = useRef();
//   const recaptchaRef = React.createRef();
//   const firstRender = useRef(true);
//   const [disable, setDisabled] = useState(true);
//   const [loggedInData, setLoggedInData] = useState([]);
//   useEffect(() => {
//     if (!userData.verified) {
//       inputRef.current.focus();
//     }
//     Axios.get(`${environment.settingsURL}`)
//       .then((resp) => {
//         if (resp.data) {
//           setLoggedInData(resp.data);
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);
//   useEffect(() => {
//     recaptchaRef.current.reset();
//   }, []);
//   const checkDisabled = () => {
//     for (var key in errors) if (!(errors[key] == "")) return false;
//     return true;
//   };
//   console.log(loggedInData, "loggedIn data");
//   useEffect(() => {
//     if (firstRender.current) {
//       firstRender.current = false;
//       return;
//     }
//     setDisabled(!checkDisabled());
//   }, [
//     errors.First_Name,
//     errors.email,
//     errors.Last_Name,
//     errors.Requested_issue,
//     errors.Occupation,
//     // errors.Title,
//     // errors.Issue_Related_To,
//   ]);

//   const handleChange = async (e) => {
//     const { name, value } = e.target;
//     setUserData({ ...userData, [name]: value });
//     switch (name) {
//       case "First_Name":
//         let firstnameError = await checkIsRequired(name, value);
//         setErrors({ ...errors, First_Name: firstnameError.errorMessage });
//         setCharLenF(value?.length);
//         break;
//       case "Last_Name":
//         let lastnameError = await checkIsRequired(name, value);
//         setErrors({ ...errors, Last_Name: lastnameError.errorMessage });
//         setCharLenL(value?.length);
//         break;
//       case "Occupation":
//         let occupationError = await checkIsRequired(name, value);
//         setErrors({ ...errors, Occupation: occupationError.errorMessage });
//         break;
//       // case "Issue_Related_To":
//       //   let Issue_Related_To_Error = await checkIsRequired(name, value);
//       //   setErrors({
//       //     ...errors,
//       //     Issue_Related_To: Issue_Related_To_Error.errorMessage,
//       //   });
//       //   break;
//       case "Requested_issue":
//         let questionError = await checkIsRequired(name, value);
//         setErrors({ ...errors, Requested_issue: questionError.errorMessage });
//         setCharLen(value?.length);
//         break;
//       // case "Title":
//       //   let TitleError = await checkIsRequired(name, value);
//       //   setErrors({ ...errors, Title: TitleError.errorMessage });
//       //   break;
//       case "Phone_Number":
//         if (isNaN(Number(e.target.value))) {
//           return;
//         }
//         break;
//       case "email":
//         let emailError = await checkIsRequired(name, value);
//         setCharLenE(value?.length);
//         if (emailError.errorStatus) {
//           setErrors({ ...errors, email: emailError.errorMessage });
//         } else {
//           emailError = await checkIsValidEmail(name, value);
//           if (emailError.errorStatus) {
//             setErrors({ ...errors, email: emailError.errorMessage });
//           } else {
//             setErrors({ ...errors, email: emailError.errorMessage });
//           }
//         }
//         break;
//       default:
//         break;
//     }
//   };

//   const onChange = (value) => {
//     setUserData({ ...userData, recaptchaToken: value });

//     if (value && value != "") {
//       setCaptcha(false);
//     }
//   };

//   const handleSubmit = (e) => {
//     setIsSaving(true);
//     e.preventDefault();
//     setShow(false);
//     if (userData.recaptchaToken && userData.recaptchaToken != "") {
//       let reqBody = {
//         User_ID: loggedInData.user_id ? loggedInData.user_id : "Guest",
//         First_Name: userData.First_Name,
//         Last_Name: userData.Last_Name,
//         Email: userData.email,
//         Phone_Number:
//           parseInt(userData.Phone_Number) !== ""
//             ? parseInt(userData.Phone_Number)
//             : null,
//         Occupation: userData.Occupation,
//         Title: userData.Requested_issue?.substring(0, 100),
//         Requested_issue: userData.Requested_issue,
//         recaptchaToken: userData.recaptchaToken,
//         // Issue_Related_To: userData.Issue_Related_To,
//       };
//       Axios.post(environment.postUserRequest, reqBody)
//         .then((resp) => {
//           if (resp.status == 200) {
//             setShow(true);
//             setVarient("Success");
//             setMsg(environment.contactMsg);
//             reset();
//             setIsSaving(false);
//             setDisabled(true);
//             setErrors({
//               First_Name: null,
//               Last_Name: null,
//               email: null,
//               Occupation: null,
//               Requested_issue: null,
//               // Title: null,
//               // Issue_Related_To: null,
//             });
//             // toast.success(`Feedback/Issue has been submitted successfully. `, {
//             //   position: "bottom-right",
//             //   autoClose: 3000,
//             //   hideProgressBar: false,
//             //   closeOnClick: true,
//             //   pauseOnHover: true,
//             //   draggable: true,
//             //   progress: undefined,
//             //   theme: "light",
//             // });
//           }
//         })
//         .catch((err) => {
//           reset();
//           setDisabled(true);
//           setIsSaving(false);
//           setErrors({
//             First_Name: null,
//             Last_Name: null,
//             email: null,
//             Occupation: null,
//             Requested_issue: null,
//             // Title: null,
//             // Issue_Related_To: null,
//           });
//           setVarient("Error");
//           if (err.response) {
//             if (err.response.status) {
//               history.push("/error");
//               return;
//             } else {
//               setShow(true);
//               setMsg(err.response.data.message);
//             }
//             // client received an error response (5xx, 4xx)
//           } else if (err.request) {
//             setShow(true);
//             setMsg("Some Error Occured");
//             // toast.error(`Some error occured. `, {
//             //   position: "bottom-right",
//             //   hideProgressBar: false,
//             //   closeOnClick: true,
//             //   pauseOnHover: true,
//             //   draggable: true,
//             //   progress: undefined,
//             //   theme: "light",
//             // });
//           }
//         });

//       // let reqBodyServiceNow = {
//       //   firstName: userData.First_Name,
//       //   lastName: userData.Last_Name,
//       //   email: userData.email,
//       //   telephone: userData.Phone_Number,
//       //   office: userData.Occupation,
//       //   shortDesc: userData.Requested_issue?.substring(0, 100),
//       //   descriptipn: userData.Requested_issue,
//       //   recaptchaToken: userData.recaptchaToken,
//       // };
//       // Axios.post(environment.contactSnowURL, reqBodyServiceNow)
//       //   .then((resp) => {
//       //     if (resp.status == 200) {
//       //       setShow(true);
//       //       setVarient("Success");
//       //       setMsg(environment.contactMsg);
//       //       reset();
//       //       setDisabled(true);
//       //       setErrors({
//       //         firstName: null,
//       //         lastName: null,
//       //         email: null,
//       //         occupation: null,
//       //         question: null,
//       //       });
//       //     }
//       //   })
//       //   .catch((err) => {
//       //     reset();
//       //     setDisabled(true);
//       //     setErrors({
//       //       firstName: null,
//       //       lastName: null,
//       //       email: null,
//       //       occupation: null,
//       //       question: null,
//       //     });
//       //     setVarient("Error");
//       //     if (err.response) {
//       //       if (err.response.status == 401) {
//       //         history.push("/error");
//       //         return;
//       //       } else {
//       //         setShow(true);
//       //         setMsg(err.response.data.message);
//       //       }
//       //       // client received an error response (5xx, 4xx)
//       //     } else if (err.request) {
//       //       setShow(true);
//       //       setMsg("Some Error Occured");
//       //     } else {
//       //       setShow(true);
//       //       setMsg("Some Error Occured");
//       //     }
//       //   });
//     } else {
//       setCaptcha(true);
//     }
//   };

//   const reset = () => {
//     setUserData({
//       First_Name: "",
//       Last_Name: "",
//       email: "",
//       Phone_Number: "",
//       Occupation: "",
//       Requested_issue: "",
//       recaptchaToken: "",
//       // Title: "",
//       // Issue_Related_To: "",
//     });

//     window.grecaptcha.reset();
//     if (recaptchaRef?.current) recaptchaRef.current.reset();
//   };
//   const setIsOpen = () => {
//     let isOpen = !show;
//     setShow(isOpen);
//   };

//   const setClose = () => {};
//   return (
//     <>
//       <CustomModal
//         title={varient}
//         isOpen={show}
//         setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
//         setClose={setClose}
//         inputLabel={msg}
//       ></CustomModal>

//       <FormCard
//         title="Contact"
//         header="Ask an expert or provide site feedback"
//         subheader="If you don't see a response, you may want to check your email spam folder"
//       >
//         <form
//           method="post"
//           className="simple_form form-horizontal"
//           name="contactForm"
//           onSubmit={handleSubmit}
//         >
//           <div className="">
//             <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="firstname" className="col-form-label">
//                   First Name
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row">
//                   <div className="col-auto">
//                     <input
//                       className="form-control customize-input"
//                       type="text"
//                       name="First_Name"
//                       id="First_Name"
//                       ref={inputRef}
//                       maxLength={255}
//                       value={userData.First_Name}
//                       onChange={handleChange}
//                       style={{
//                         caretColor:
//                           userData.First_Name?.length === 255 ? "red" : "black",
//                       }}
//                     />
//                     <span className="col-12 m-0 p-0 d-flex justify-content-end">
//                       {charLenF} / 255 Characters
//                     </span>
//                     {errors.First_Name ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.First_Name}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="lastname" className="col-form-label">
//                   Last Name
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row">
//                   <div className="col-auto">
//                     <input
//                       className="form-control customize-input"
//                       type="text"
//                       name="Last_Name"
//                       id="Last_Name"
//                       maxLength={255}
//                       value={userData.Last_Name}
//                       onChange={handleChange}
//                       style={{
//                         caretColor:
//                           userData.Last_Name?.length === 255 ? "red" : "black",
//                       }}
//                     />
//                     <span className="col-12 m-0 p-0 d-flex justify-content-end">
//                       {charLenL} / 255 Characters
//                     </span>
//                     {errors.Last_Name ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.Last_Name}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="email" className="col-form-label">
//                   Email
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row">
//                   <div className="col-auto">
//                     <input
//                       type="text"
//                       name="email"
//                       maxLength={255}
//                       className="form-control customize-input"
//                       value={userData.email}
//                       onChange={handleChange}
//                       style={{
//                         caretColor:
//                           userData.email?.length === 255 ? "red" : "black",
//                       }}
//                     />
//                     <span className="col-12 m-0 p-0 d-flex justify-content-end">
//                       {charLenE} / 255 Characters
//                     </span>
//                     {errors.email ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.email}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="Occupation" className="col-form-label">
//                   Occupation
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row">
//                   <div className="col-auto">
//                     <Select
//                       className="form-control customize-input"
//                       name="Occupation"
//                       placeholder="Nothing Selected"
//                       value={userData.Occupation}
//                       displayEmpty
//                       onChange={handleChange}
//                       /*   options={createSelectOptions(
//                 occupationData
//               )} */
//                       theme={(theme) => ({
//                         ...theme,
//                         borderRadius: 0,
//                         colors: {
//                           ...theme.colors,
//                           primary25: "lightgrey",
//                           primary: "#80bdff",
//                         },
//                       })}
//                     >
//                       <MenuItem value=""></MenuItem>
//                       <MenuItem value="Researcher">Researcher</MenuItem>
//                       <MenuItem value="Professor">
//                         Professor/Instructor
//                       </MenuItem>
//                       <MenuItem value="Student">Student</MenuItem>
//                       <MenuItem value="Member of Media">
//                         Member of Media
//                       </MenuItem>
//                       <MenuItem value="Other">Other</MenuItem>

//                       {/* {this.state.occupationData.map((name) => (
//                 <MenuItem key={name} value={name}>
//                   {name}
//                 </MenuItem>
//               ))} */}
//                     </Select>
//                     {errors.Occupation ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.Occupation}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="Phone_Number" className="col-form-label">
//                   Phone Number (optional)
//                 </label>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row">
//                   <div className="col-auto">
//                     <input
//                       type="number"
//                       // max={10}
//                       min={0}
//                       maxLength={11}
//                       className="form-control customize-input"
//                       name="Phone_Number"
//                       value={userData.Phone_Number}
//                       onChange={handleChange}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="Title" className="col-form-label">
//                   Title of the issue
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>

//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row ">
//                   <div className="col-auto">
//                     <TextareaAutosize
//                       className="form-control customize-input"
//                       autoFocus="autofocus"
//                       type="text"
//                       rowsMin={2}
//                       rowsMax={3}
//                       name="Title"
//                       value={userData.Title}
//                       onChange={handleChange}
//                     />
//                     {errors.Title ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.Title}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//             <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="Requested_issue" className="col-form-label">
//                   Ask a Requested_issue or provide feedback
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>

//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row ">
//                   <div className="col-auto">
//                     <TextareaAutosize
//                       className="form-control customize-input placeTextArea"
//                       autoFocus="autofocus"
//                       type="text"
//                       rowsMin={5}
//                       rowsMax={5}
//                       maxLength={4000}
//                       name="Requested_issue"
//                       placeholder="Characters limit 4000"
//                       value={userData.Requested_issue}
//                       onChange={handleChange}
//                       style={{
//                         caretColor:
//                           userData.Requested_issue?.length === 4000
//                             ? "red"
//                             : "black",
//                       }}
//                     />
//                     <span className="col-12 m-0 p-0 d-flex justify-content-end">
//                       {charLen} / 4000 Characters
//                     </span>
//                     {errors.Requested_issue ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.Requested_issue}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                   <div className="col-12 d-flex justify-content-start mt-2">
//                     <ReCAPTCHA
//                       ref={recaptchaRef}
//                       sitekey={environment.captchaSiteKey}
//                       onChange={onChange}
//                     />
//                     {isCaptcha && (
//                       <span className="text-danger row justify-content-center mt-0">
//                         CAPTCHA verification failed, please try again
//                       </span>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {/* <div className="row customize-username-height">
//               <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
//                 <label htmlFor="Issue_Related_To" className="col-form-label">
//                   Issue related module
//                 </label>
//                 <abbr title="required">*</abbr>
//               </div>
//               <div className="col-xs-12 col-sm-12 col-md-8">
//                 <div className="row">
//                   <div className="col-auto">
//                     <Select
//                       className="form-control customize-input"
//                       name="Issue_Related_To"
//                       placeholder="Nothing Selected"
//                       value={userData.Issue_Related_To}
//                       displayEmpty
//                       onChange={handleChange}
        
//                       theme={(theme) => ({
//                         ...theme,
//                         borderRadius: 0,
//                         colors: {
//                           ...theme.colors,
//                           primary25: "lightgrey",
//                           primary: "#80bdff",
//                         },
//                       })}
//                     >
//                       <MenuItem value=""></MenuItem>
//                       <MenuItem value="Extract">Extract</MenuItem>
//                       <MenuItem value=" Analysis">Analysis</MenuItem>
//                       <MenuItem value="Survey Search">Survey Search</MenuItem>
//                     </Select>
//                     {errors.Issue_Related_To ? (
//                       <div className="" style={{ color: "#cc0000" }}>
//                         {errors.Issue_Related_To}
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div> */}

//             {/* <div className="row customize-username-height">
//               <div
//                 className="col-12 d-flex justify-content-center"
//                 style={{ marginTop: "100px" }}
//               >
//                 <div
//                   className={
//                     isCaptcha
//                       ? "reg-captchaError col-auto mt-4 center"
//                       : "col-auto  mt-4"
//                   }
//                   data-theme="light"
//                   data-sitekey="XXXXXXXXXXXXX"
//                 >
//                   <ReCAPTCHA
//                     ref={recaptchaRef}
//                     sitekey={environment.captchaSiteKey}
//                     onChange={onChange}
//                     style={{ width: "50%" }}
//                   />
//                 </div>
//               </div>
//               {isCaptcha && (
//                 <span className="text-danger row justify-content-center mt-0">
//                   CAPTCHA verification failed, please try again
//                 </span>
//               )}
//             </div> */}
//             <div className="col-12 d-flex justify-content-center form-actions center">
//               {isSaving ? (
//                 <button type="button" className="submit-btn commit">
//                   <span
//                     className="spinner-border spinner-border-sm"
//                     role="status"
//                     aria-hidden="true"
//                   ></span>
//                   &nbsp; SUBMITTING...
//                 </button>
//               ) : (
//                 <button
//                   type="submit"
//                   name="commit"
//                   className="submit-btn commit"
//                   disabled={disable}
//                 >
//                   SUBMIT
//                 </button>
//               )}
//             </div>
//           </div>
//         </form>
//       </FormCard>
//     </>
//   );
// }

// export default Contact;

// const regEmail =
//   /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
// export const checkIsValidEmail = (name, value) => {
//   let field = name.charAt(0).toUpperCase() + name.slice(1);

//   let isValid = regEmail.test(value);
//   return {
//     errorMessage: isValid ? "" : `${field} is invalid`,
//     errorStatus: isValid ? false : true,
//   };
// };

// export const checkIsRequired = (name, value) => {
//   if (value === "" || value.trim() === "" || value === undefined) {
//     let field = name.charAt(0).toUpperCase() + name.slice(1);
//     field = field == "FirstName" ? "First Name" : field;
//     field = field == "LastName" ? "Last Name" : field;
//     return {
//       errorMessage: `${field}  is a required field`,
//       errorStatus: true,
//     };
//   } else {
//     return {
//       errorMessage: "",
//       errorStatus: false,
//     };
//   }
// };

//******************** */ Ticket Tracker ------------------------- PSL -> NORC  End ***********************//



import React, { useEffect, useRef, useState } from "react";
import { MenuItem, Select, TextareaAutosize } from "@material-ui/core";
import { environment } from "../../../environment";
import Axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import { useHistory } from "react-router";
import "./Contact.css";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
function Contact(props) {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    occupation: "",
    question: "",
    recaptchaToken: "",
  });

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    occupation: null,
    question: null,
  });
  let history = useHistory();
  const [isCaptcha, setCaptcha] = useState(false);
  const [show, setShow] = useState(false);
  const [varient, setVarient] = useState("");
  const [msg, setMsg] = useState("");
  const inputRef = useRef();
  const recaptchaRef = React.createRef();
  const firstRender = useRef(true);
  const [disable, setDisabled] = useState(true);

  useEffect(() => {
    if (!userData.verified) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    recaptchaRef.current.reset();
}, []);
  const checkDisabled = () => {
    for (var key in errors) if (!(errors[key] == "")) return false;
    return true;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setDisabled(!checkDisabled());
  }, [
    errors.firstName,
    errors.email,
    errors.lastName,
    errors.question,
    errors.occupation,
  ]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    switch (name) {
      case "firstName":
        let firstnameError = await checkIsRequired(name, value);
        setErrors({ ...errors, firstName: firstnameError.errorMessage });
        break;
      case "lastName":
        let lastnameError = await checkIsRequired(name, value);
        setErrors({ ...errors, lastName: lastnameError.errorMessage });
        break;
      case "occupation":
        let occupationError = await checkIsRequired(name, value);
        setErrors({ ...errors, occupation: occupationError.errorMessage });
        break;
      case "question":
        let questionError = await checkIsRequired(name, value);
        setErrors({ ...errors, question: questionError.errorMessage });
        break;
      case "phoneNumber":
        if (isNaN(Number(e.target.value))) {
          return;
        }
        break;
      case "email":
        let emailError = await checkIsRequired(name, value);
        if (emailError.errorStatus) {
          setErrors({ ...errors, email: emailError.errorMessage });
        } else {
          emailError = await checkIsValidEmail(name, value);
          if (emailError.errorStatus) {
            setErrors({ ...errors, email: emailError.errorMessage });
          } else {
            setErrors({ ...errors, email: emailError.errorMessage });
          }
        }
        break;
      default:
        break;
    }
  };

  const onChange = (value) => {
    setUserData({ ...userData, recaptchaToken: value });

    if (value && value != "") {
      setCaptcha(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShow(false);
    if (userData.recaptchaToken && userData.recaptchaToken != "") {
      let reqBody = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        telephone: userData.phoneNumber,
        office: userData.occupation,
        shortDesc: userData.question,
        descriptipn: userData.question,
        recaptchaToken: userData.recaptchaToken,
      };
      Axios.post(environment.contactSnowURL, reqBody)
        .then((resp) => {
          if (resp.status == 200) {
            setShow(true);
            setVarient("Success");
            setMsg(environment.contactMsg);
            reset();
            setDisabled(true);
            setErrors({
              firstName: null,
              lastName: null,
              email: null,
              occupation: null,
              question: null,
            });
          }
        })
        .catch((err) => {
          reset();
          setDisabled(true);
          setErrors({
            firstName: null,
            lastName: null,
            email: null,
            occupation: null,
            question: null,
          });
          setVarient("Error");
          if (err.response) {
            if (err.response.status == 401) {
              history.push("/error");
              return;
            } else {
              setShow(true);
              setMsg(err.response.data.message);
            }
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            setShow(true);
            setMsg("Some Error Occured");
          } else {
            setShow(true);
            setMsg("Some Error Occured");
          }
        });
    } else {
      setCaptcha(true);
    }
  };

  const reset = () => {
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      occupation: "",
      question: "",
      recaptchaToken: "",
    });

    window.grecaptcha.reset();
    if(recaptchaRef?.current)
     recaptchaRef.current.reset();
  };
  const setIsOpen = () => {
    let isOpen = !show;
    setShow(isOpen);
  };

  const setClose = () => {};
  return (
    <>
      <CustomModal
        title={varient}
        isOpen={show}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
        setClose={setClose}
        inputLabel={msg}
      ></CustomModal>

      <FormCard title="Contact" header="Ask an expert or provide site feedback" subheader="If you don't see a response, you may want to check your email spam folder">
        <form
          method="post"
          className="simple_form form-horizontal"
          name="contactForm"
          onSubmit={handleSubmit}
        >
          <div className="">
            <div className="row customize-username-height">
              <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                <label htmlFor="firstname" className="col-form-label">
                  First Name
                </label>
                <abbr title="required">*</abbr>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="row">
                  <div className="col-auto">
                    <input
                      className="form-control customize-input"
                      type="text"
                      name="firstName"
                      id="firstName"
                      ref={inputRef}
                      maxLength={35}
                      value={userData.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName ? (
                      <div className="" style={{ color: "#cc0000" }}>
                        {errors.firstName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row customize-username-height">
              <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                <label htmlFor="lastname" className="col-form-label">
                  Last Name
                </label>
                <abbr title="required">*</abbr>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="row">
                  <div className="col-auto">
                    <input
                      className="form-control customize-input"
                      type="text"
                      name="lastName"
                      id="lastName"
                      maxLength={35}
                      value={userData.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName ? (
                      <div className="" style={{ color: "#cc0000" }}>
                        {errors.lastName}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row customize-username-height">
              <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                <label htmlFor="email" className="col-form-label">
                  Email
                </label>
                <abbr title="required">*</abbr>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="row">
                  <div className="col-auto">
                    <input
                      type="text"
                      name="email"
                      className="form-control customize-input"
                      value={userData.email}
                      onChange={handleChange}
                    />
                    {errors.email ? (
                      <div className="" style={{ color: "#cc0000" }}>
                        {errors.email}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row customize-username-height">
              <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                <label htmlFor="occupation" className="col-form-label">
                  Occupation
                </label>
                <abbr title="required">*</abbr>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="row">
                  <div className="col-auto">
                    <Select
                      className="form-control customize-input"
                      name="occupation"
                      placeholder="Nothing Selected"
                      value={userData.occupation}
                      displayEmpty
                      onChange={handleChange}
                      /*   options={createSelectOptions(
                occupationData
              )} */
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "lightgrey",
                          primary: "#80bdff",
                        },
                      })}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Researcher">Researcher</MenuItem>
                      <MenuItem value="Professor">
                        Professor/Instructor
                      </MenuItem>
                      <MenuItem value="Student">Student</MenuItem>
                      <MenuItem value="Member of Media">
                        Member of Media
                      </MenuItem>
                      <MenuItem value="Other">Other</MenuItem>

                      {/* {this.state.occupationData.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))} */}
                    </Select>
                    {errors.occupation ? (
                      <div className="" style={{ color: "#cc0000" }}>
                        {errors.occupation}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row customize-username-height">
              <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                <label htmlFor="phoneNumber" className="col-form-label">
                  Phone Number (optional)
                </label>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="row">
                  <div className="col-auto">
                    <input
                      type="text"
                      max={10}
                      min={0}
                      maxLength={11}
                      className="form-control customize-input"
                      name="phoneNumber"
                      value={userData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-4 customize-label">
                <label htmlFor="question" className="col-form-label">
                  Ask a question or provide feedback
                </label>
                <abbr title="required">*</abbr>
              </div>
             
              <div className="col-xs-12 col-sm-12 col-md-8">
                <div className="row">
                  <div className="col-auto">
                    <TextareaAutosize
                      className="form-control customize-input"
                      autoFocus="autofocus"
                      type="text"
                      rowsMin={5}
                      rowsMax={5}
                      name="question"
                      value={userData.question}
                      onChange={handleChange}
                    />
                    {errors.question ? (
                      <div className="" style={{ color: "#cc0000" }}>
                        {errors.question}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  </div>
              </div>
            </div>
            <div className="row justify-content-center mb-0">
              <div
                className={
                  isCaptcha
                    ? "reg-captchaError col-auto mt-4 center"
                    : "col-auto  mt-4"
                }
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={environment.captchaSiteKey}
                  onChange={onChange}
                />
              </div>
            </div>
            {isCaptcha && (
              <span className="text-danger row justify-content-center mt-0">
                CAPTCHA verification failed, please try again
              </span>
            )}
          </div>

          <div  className="form-actions center">
            <button
              type="submit"
              name="commit"
              className="submit-btn commit"
              disabled={disable}
            >
              SUBMIT
            </button>
          </div>
        </form>
      </FormCard>
    </>
  );
}

export default Contact;

const regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const checkIsValidEmail = (name, value) => {
  let field = name.charAt(0).toUpperCase() + name.slice(1);

  let isValid = regEmail.test(value);
  return {
    errorMessage: isValid ? "" : `${field} is invalid`,
    errorStatus: isValid ? false : true,
  };
};

export const checkIsRequired = (name, value) => {
  if (value === "" || value.trim() === "" || value === undefined) {
    let field = name.charAt(0).toUpperCase() + name.slice(1);
    field = field == "FirstName" ? "First Name" : field;
    field = field == "LastName" ? "Last Name" : field;
    return {
      errorMessage: `${field}  is a required field`,
      errorStatus: true,
    };
  } else {
    return {
      errorMessage: "",
      errorStatus: false,
    };
  }
};


