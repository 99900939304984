import React from 'react';
import { useSelector } from 'react-redux';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    const { min_max_year: { min_max_year = [] } } = useSelector((state) => state.minmaxYearsReducer);
    const years = min_max_year && min_max_year.length>0 ? min_max_year :[1972,2018]
    return (
        <div className="container mt-5" id="terms-and-conditions">
            <div className="row">
                <div className="col">
                    <h3 className="gss-bold-title f-helveticaneue-bold">Terms and Conditions</h3>
                    <span className="sharing">The following covers the general terms and conditions for use of norc.org and its affiliated web properties.</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h5 className="gss-bold-title f-helveticaneue-bold mb-0">Disclaimer</h5>
                    <span className="sharing">While substantial effort has been dedicated to the review and corroboration of information and materials posted on NORC websites, we cannot guarantee the accuracy and/or timeliness of any and all contents generated by NORC or received from third parties. Any and all warranties and representations are hereby expressly disclaimed; no person may rely upon the contents of NORC websites. NORC would greatly appreciate any advice as to potential errors, website functionality issues, and other matters; such should be directed to: <span><a className="green-text"  href="mailto:commhelp@norc.org">commhelp@norc.org.</a></span></span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h5 className="gss-bold-title f-helveticaneue-bold mb-0">Terms for Accessing GSS Data</h5>
                    <span className="sharing">Please read the following statements before viewing or downloading data.</span>
                    <div className="row mt-3">
                        <div className="col-1">
                            <button type="button" className="number-list">1</button>
                        </div>
                        <div className="col-11">
                            <span className="mb-2 sharing">When using GSS data in publications and presentations, the author should use the following citation:</span>
                            <h5 className="mt-4 mb-0 terms-text">In the Media</h5>
                            <hr/>
                            <span className="sharing">GSS is a project of NORC at the University of Chicago.</span>
                            <span className="sharing">Our name is NORC at the University of Chicago for all media, communications, and marketing purposes.</span>
                            <span className="sharing">The first reference that we recommend to reporters is: "The independent research organization NORC at the University of Chicago," with subsequent references simply NORC.</span>
                            <span className="sharing">If you are using information from the General Social Survey, the citation we recommend is the following: "The General Social Survey (GSS) is a project of the independent research organization NORC at the University of Chicago, with principal funding from the National Science Foundation." Subsequent reference would be simply GSS.</span>

                            <h5 className="mt-4 mb-0 terms-text">In Academic Journals</h5>
                            <hr/>
                            <span className="sharing">Davern, Michael; Bautista, Rene; Freese, Jeremy; Herd, Pamela; and Morgan, Stephen L;, General Social Surveys, {years[0]}-{years[1]} [machine-readable data file] /Principal Investigator, Smith, Tom W.; Co-Principal Investigators, Rene Bautista, Jeremy Freese, Pamela Herd, and Stephen L. Morgan; Sponsored by National Science Foundation. --NORC ed.-- Chicago: NORC, {years[1]}: NORC at the University of Chicago [producer and distributor]. Data accessed from the GSS Data Explorer website at 
                            &nbsp;<a href="/">gssdataexplorer.norc.org</a></span>.
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-1">
                            <button type="button" className="number-list">2</button>
                        </div>
                        <div className="col-11">
                            <span className="sharing">I acknowledge that this website was established to disseminate GSS data and to improve access and increase the use of GSS data files. I will therefore make responsible use of all data, software and documentation obtained from this site.</span>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-1">
                            <button type="button" className="number-list">3</button>
                        </div>
                        <div className="col-11">
                            <span className="sharing">I acknowledge that NORC at the University of Chicago, and all parties associated with the General Social Survey (including the principal investigators), offer the data and documentation "as is" with no warranty and assume no legal liability or responsibility for the completeness, accuracy, or usefulness of the data, or fitness for a particular purpose.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row  mt-3">
                <div className="col">
                    <h5 className="gss-bold-title f-helveticaneue-bold mb-0">Copyright Notice</h5>
                    <span className="sharing">© {(new Date()).getFullYear()} NORC. Except as otherwise noted and/or attributed, all contents of NORC websites is under NORC copyright protection. No part of the contents of NORC websites may be reproduced, stored, or transmitted in any form or by any means, electronic or mechanical, in whole or in any part, without the express written consent of NORC. Requests for permissions should be directed to: commhelp@norc.org. “NORC” and associated graphic images are service marks of NORC and subject to United States and international trademark and other intellectual property protections.</span>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h5 className="gss-bold-title f-helveticaneue-bold mb-0">NORC's Legal Name</h5>
                    <span className="sharing">The legal name of NORC at the University of Chicago is National Opinion Research Center; we are also registered to do business as NORC. NORC is an affiliate of the University of Chicago and has offices located on the University campus, as well as downtown Chicago, the DC Metro area, Atlanta, Boston, and San Francisco. NORC is an independent 501 ( c ) 3 research corporation and is not a part of the University.<br/>Questions should be directed to contracts@norc.org.</span>
                </div>
            </div>
            <div className="row mt-3 mb-5">
                <div className="col">
                    <h5 className="gss-bold-title f-helveticaneue-bold mb-0">Credits</h5>
                    <span className="sharing">All imagery and digital assets on norc.org are owned by NORC or used by permission.</span>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;