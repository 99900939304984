const initialState = {    
    extract_data:{}
   };
   const extractReducer = (state = initialState, action) => {
   switch (action.type) {
       case 'SET_EXTRACT': {            
           const extract_data = action.payload;
                   return {
                       ...state,
                       extract_data
                   }
       }
       case 'SET_EXTRACTNAME':{
           const extract_name = action.payload;
           return{
            ...state,
            extract_name
           }
       }
       default:
           return state;
   }
   };
   
   export default extractReducer;