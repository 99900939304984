import { Auth } from "aws-amplify";
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import FormCard from "../../../../Components/UI/FormCard/FormCard";
import "./ConfirmCode.css";
import { checkIsRequired, setLocalStorage } from "../../../../Shared/helpers";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../../Store/actions";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

export default function ConfirmCode(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [sentText, setSentText] = useState("sent");
  const [isSpinner, setSpinner] = useState(false);
  const checkDisabled = () => {
    if (!(codeError == "")) return false;
    return true;
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    setIsSubmit(!checkDisabled());
  }, [codeError]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setCode(value);
    let codeErr = await checkIsRequired(name, value);
    if (codeErr.errorStatus) {
      setCodeError(codeErr.errorMessage);
    } else {
      let regex = /^\d+$/;
      let codeErr = regex.test(value);
      if (!codeErr) {
        setCodeError("Please enter number only");
      } else {
        setCodeError("");
      }
    }
  };

  const verifyCode = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setIsSubmit(true);
    Auth.confirmSignUp(props.field.username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true,
    })
      .then((data) => {
        setIsSubmit(true);
        setCodeError(null);
        setCode("");
        if (props.requestFrom == "RequestCode") {
          Auth.signIn({
            username: props.field.username,
            password: props.field.password,
          })
            .then((res) => {
              setIsSaving(false);
              dispatch(
                setAuth({
                  token: res.signInUserSession.idToken.jwtToken,
                  isSignedIn: true,
                  username: res.username,
                  fullName:
                    res.signInUserSession.idToken.payload.name +
                    " " +
                    (res.signInUserSession.idToken.payload.family_name
                      ? res.signInUserSession.idToken.payload.family_name
                      : ""),
                  email_verified:
                    res.signInUserSession.idToken.payload.email_verified,
                  email: res.signInUserSession.idToken.payload.email,
                  firstName: res.signInUserSession.idToken.payload.name,
                  lastName: res.signInUserSession.idToken.payload.family_name,
                  department:
                    res.signInUserSession.idToken.payload["custom:departments"],
                  organization:
                    res.signInUserSession.idToken.payload[
                      "custom:organization"
                    ],
                  isSocialLogin:
                    res.signInUserSession.idToken.payload.hasOwnProperty(
                      "identities"
                    ),
                  createdAt:
                    res.signInUserSession.idToken.payload.hasOwnProperty(
                      "identities"
                    )
                      ? res.signInUserSession.idToken.payload.identities[0][
                          "dateCreated"
                        ]
                      : null,
                  isAdmin:
                    res.signInUserSession.accessToken.payload[
                      "cognito:groups"
                    ][0] == "norc-admins",
                  runSessionTimeout: true,
                })
              );
              setLocalStorage(res);
              alert("User successfully confirmed!")
              history.push("/user/project");
            })
            .catch((err) => {
              setIsSaving(false);
            });
        } else {
          setIsSaving(false);
          history.push("/sign_in");
        }
      })
      .catch((err) => {
        setIsSaving(false);
        if (err.message) {
          setIsSubmit(true);
          setCodeError(err.message);
        }
      });
  };
  const resendCode = () => {
    setSpinner(true);
    Auth.resendSignUp(props.field.username).then((data) => {
      setSentText("resent");
      setSpinner(false);
    });
  };
  return (
    <div>
      <FormCard title="Registration">
        <div>
          <form
            onSubmit={verifyCode}
            className="simple_form form-horizontal or-new"
            noValidate
          >
            <div className="row justify-content-md-center">
              <div className="col-auto">
                <p style={{ color: "#000000", fontSize: 18 }}>
                  An email was {sentText} to{" "}
                  <strong>{props.field.email}</strong>
                </p>
              </div>
            </div>
            <div
              className="row justify-content-md-center customize-username-height"
              style={{ alignItems: "center" }}
            >
              <div className="col-auto customize-label">
                <label htmlFor="code" className="col-form-label">
                  Verification Code
                </label>
              </div>
              <div className="col-auto">
                <input
                  type="text"
                  name="code"
                  id="code"
                  className="form-control customize-input"
                  aria-describedby="code"
                  value={code}
                  autoFocus
                  onChange={handleChange}
                  autoComplete="off"
                  disabled={isSaving}
                  maxLength={35}
                />
                {codeError ? <div className="errorMsg">{codeError}</div> : ""}
              </div>
              <Tooltip
                title={
                  <div>
                   Please check your inbox for a verification code. If you did not receive a code, please click "resend".
                  </div>
                }
              >
                <InfoIcon fontSize="small" style={{ fontSize: "13px" }} className="plus-icon" />
              </Tooltip>
            </div>
            <div className="row justify-content-md-center mt-3">
              <div className="col-auto">
                <p>
                  Didn't receive a code? Check your <br /> Spam folder or{" "}
                  <span className="resend-code-text" onClick={resendCode}>
                    Resend the code &nbsp;
                    {isSpinner && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-auto">
                {isSaving ? (
                  <button type="button" className="gss-btn btn-orange mt-1">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    &nbsp; CONFIRMING...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="gss-btn btn-orange mt-1"
                    variant="secondary"
                    disabled={isSubmit}
                  >
                    CONFIRM CODE
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        <span>
          <NavLink
            className="confirmation-instruction"
            to="/users/confirmation"
            exact
          >
            Didn't receive confirmation instructions?
          </NavLink>
        </span>
      </FormCard>
    </div>
  );
}
