import { React, useEffect, useState } from "react";
import Axios from "axios";
import { environment } from "../../environment";
import FormCard from "../../Components/UI/FormCard/FormCard";
import "./BannerMessage.css";
import "../Header/Message.css";
import DatePicker from "react-datepicker";
import "./react-datepicker.css";

const BannerMessage = (props) => {
  const [notice, setNotice] = useState({
    msg_id: "msg1",
    message: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState();
  const [expiryTime, setExpiryTime] = useState();
  const [startTime, setStartTime] = useState();

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    let request_json = {
      msg_id: notice.msg_id,
      message: notice.message,
      start: startDate,
      expiry: endDate,
    };
    Axios.post(`${environment.bannerMessage}`, request_json).then(
      (response) => {
        setNotice({
          msg_id: "msg1",
          message: "",
        });
      }
    );
    getMessage();
    alert("Message will be published at " + startDate);
    const timers = setTimeout(() => {
      window.location.reload();
    }, 1200);
    return () => clearTimeout(timers);
  };

  const messageRemove = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (window.confirm("Are you sure to delete ?")) {
      let request_json = {
        msg_id: notice.msg_id,
        message: "",
        start: "",
        expiry: "",
      };
      Axios.post(`${environment.bannerMessage}`, request_json).then(
        (response) => {
          setNotice({
            msg_id: "msg1",
            message: "",
          });
        }
      );
      alert("Message has been deleted !");
      const timer = setTimeout(() => {
        window.location.reload();
      }, 1200);
      return () => clearTimeout(timer);
      
    }

    getMessage();
  };
  const getMessage = () => {
    Axios.get(`${environment.showMessage}`)
      .then((resp) => {
        let data1 = resp.data.body.message;
        let expiryTime = resp.data.body.expiry;
        let expiryTimeLocal = new Date(expiryTime).toLocaleString();
        let startTime = resp.data.body.start;
        let startTimeLocal = new Date(startTime).toLocaleString();
        setStartTime(startTimeLocal);
        setExpiryTime(expiryTimeLocal);
        setMessage(data1);
        console.log(data1, "mddd");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getMessage();
  }, [handleSubmit]);

  const CustomTimeInput = ({ date, value, onChange }) => (
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{ border: "solid 1px pink" }}
    />
  );

  const handleChange = (event) => {
    setNotice({
      ...notice,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (localStorage.isAdmin != "true") {
      props.history.push("/error");
      return;
    }
  }, []);
  console.log(endDate, "end");
  return (
    <FormCard title="Banner Message" header="Add your message">
      <form
        className="simple_form form-horizontal mb-4"
        onSubmit={handleSubmit}
      >
        <div className="row justify-content-center ">
          <div className="col-6 p-0 m-0 d-flex justify-content-center ">
            <input
              type="text"
              name="message"
              placeholder="Enter a message ( Limit : 1000 Characters )"
              className="message-control  d-flex justify-center"
              value={notice.message}
              onChange={handleChange}
              maxLength="1000"
            />
          </div>

          <div className="col-12  p-0  d-flex justify-content-center">
            <p className="Picker">
              Start Date <abbr title="required"> *</abbr>:{" "}
              <DatePicker
                selected={startDate}
                selectsStart
                startDate={startDate}
                showTimeInput
                customTimeInput={<CustomTimeInput />}
                placeholderText="Select Start Date"
                dateFormat="MMMM d, yyyy h:mmaa"
                endDate={endDate}
                onChange={(date) => setStartDate(date)}
                className="timePicker"
                isClearable={true}
                required
                minDate={new Date()}
              />
            </p>
          </div>
          <div className="col-12 p-0  d-flex justify-content-center">
            <p className="Picker">
              End Date <abbr title="required"> *</abbr>:{" "}
              <DatePicker
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                showTimeInput
                customTimeInput={<CustomTimeInput />}
                placeholderText="Select End Date"
                dateFormat="MMMM d, yyyy h:mmaa"
                minDate={new Date()}
                onChange={(date) => setEndDate(date)}
                className="timePicker"
                isClearable={true}
                required
              />
            </p>
          </div>
          <div className="col-12 p-0 ml-5 mr-5 d-flex justify-content-center">
            <button
              className="gss-btn btn-orange mt-3 mb-3"
              type="submit"
              disabled={localStorage.isAdmin != "true" || notice.message == ""}
            >
              Submit
            </button>
          </div>
          {message !== "" && (
            <>
              <p className="col-12 p-0 m-0 d-flex justify-content-center ">
                <>
                  <p className="d-flex justify-content-center">
                    <strong className="my-auto mr-2">Display Message: </strong>{" "}
                    <span className="messageBox p-2">{message}</span>
                  </p>
                </>
              </p>
              <div className="row-12 p-0 m-0 d-flex justify-content-center ">
                <>
                  <p className=" d-flex justify-content-center">
                    <p className="p-2">
                      <strong>Start Date: </strong>
                      {startTime}
                    </p>
                  </p>

                  <p className=" d-flex justify-content-center">
                    <p className="p-2">
                      <strong>Expiry Date: </strong>
                      {expiryTime}
                    </p>
                  </p>
                </>
              </div>
              <div className="col-12 p-0 ml-5 mr-5 d-flex justify-content-center">
                <button
                  className="gss-btn btn-orange mt-3 mb-3"
                  type="button"
                  disabled={localStorage.isAdmin != "true"}
                  onClick={messageRemove}
                >
                  Delete
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </FormCard>
  );
};

export default BannerMessage;
