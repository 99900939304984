import React, { useState, useEffect } from "react";
import "./AdminUsers.css";
import axios from "axios";
import { environment } from "../../../environment";
import { NavLink, useHistory } from "react-router-dom";
import { updateService } from "../../../Shared/UpdateCart";
import Pagination from "@material-ui/lab/Pagination";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { dateTimeFormat } from "../../../Store/utils/common.utils";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function AdminUsers() {
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [initialLoad, setInitialLoad] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [tempSearchKeyword, setTempSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisable] = useState(false);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });
  let history = useHistory();

  useEffect(() => {
    updateService.sendAlert("");
    if (localStorage.isAdmin != "true") {
      history.push("/error");
      return;
    }
    usersList();
  }, [currentPage,searchKeyword]);

  const usersList = (isClearSearch) => {
    setLoading(true);
    let reqObj = {
      page: currentPage == 0 ? 1 : currentPage,
      limit: limit,
      sortBy: "created_at",
      orderBy: "desc",
      filterBy: isClearSearch ? "" : searchKeyword.toString(),
    };
    axios
      .post(`${environment.getUsers}`, reqObj)
      .then((response) => {
        if (response.data) {
          let users = response.data.list_user.map((user, index) => {
            user.id = index;
            return user;
          });
          setRows(users);
          setTotalPage(response.data.total_pages);
          setTotalUsers(response.data.total_users);
          setInitialLoad(true);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // client never received a response, or request never left
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // anything else
        }
      });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleBlur = (event) => {
    if(event.key=='Enter')
    if (tempSearchKeyword !== searchKeyword) {
      setCurrentPage(1);
      setSearchKeyword(tempSearchKeyword);
    }
  };

  const handleClearSeach = (e) => {
    if (tempSearchKeyword !== "") {
      setTempSearchKeyword("");
      setCurrentPage(1);
      setSearchKeyword("");
    }
  };

  const clickPaginationBtn = (e, val) => {
    let setPage = val;
    setCurrentPage(setPage);
  };

  const downloadUsers = () => {
    setDisable(true);
    axios
      .get(`${environment.emailList}`)
      .then((res) => {
        setDisable(false);
        window.location.href = res.data.objectUrl;
      })
      .catch((err) => {
        setDisable(false);
        if (err.response) {
          //alert(err.response.data.message);
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // alert('Some Error Occurred');
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // client never received a response, or request never left
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // anything else
        }
        //show error and try again button and set isLoading to false and set tabulations and rows to initial value
      });
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };
  const setClose = () => {
    // props.history.push('/user/project');
  };

  return (
    <div>
      <CustomModal
        title={isServerError.variant}
        isOpen={isServerError.status}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
        inputLabel={isServerError.message}
        setClose={setClose}
      ></CustomModal>

      <div id="or-main-content">
        <div className="or-grid">
          <div id="mainbar">
            <div className="stitle">
            {loading ?<CircularProgress color="inherit" size='1.2rem' thickness='3'/>:<></>} 
              &nbsp;Users</div>
            <div className="or-toolbar or-index">
          {totalUsers?    <div className="or-toolbar-item pull-left">
                <h4 className="mt-2">
                  <strong>{totalUsers} Results</strong>
                </h4>
              </div>:''}
              <div className="or-toolbar-item keyword-item">
                <div className="input-group">
                  {/* <input type="text" name="search" className="bar-search form-control" id="filter-text-box" value={this.state.search} placeholder="Keyword" onChange={this.handleChange} onKeyDown={this.searchGrid}/> */}
                  {/* <form onSubmit={handleFormSubmit}> */}
                    <input
                      type="text"
                      name="search"
                      className="bar-search form-control"
                      id="filter-text-box"
                      placeholder="Keyword"
                      value={tempSearchKeyword}
                      onChange={(event) =>
                        setTempSearchKeyword(event.target.value.toString())
                      }
                      onKeyDown={handleBlur}
                      
                    />
                    &nbsp;
                  {/* </form> */}
                  {/* 
               <div className="input-group-addon" onClick={this.clearSearch}>
                  */}
                  <div className="input-group-addon">
                    <a className="anchor" onClick={handleClearSeach}>
                      <i
                        title="Clear the search"
                        className="filter-clear fa fa-times-circle fa-lg"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <span className="or-toolbar-item">
                <span className="or-toolbar-item">
                  <Button
                    disabled={isDisabled}
                    className="green-text"
                    onClick={downloadUsers}
                  >
                    <i className="fa fa-envelope fa-lg"></i>
                    <span className="icon-label">Emails list</span>
                  </Button>
                </span>
                <NavLink className="" exact to="/users/new">
                  <i className="fa fa-plus-circle fa-lg"></i>
                  <span className="icon-label">Create new user</span>
                </NavLink>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="gss_material_table">
        {initialLoad && (
          <>
            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
                className="custom-spinner"
              />
            ) : (
              ""
            )}
            <TableContainer>
              <Table
                className={`main-root mb-3`}
                aria-labelledby="tableTitle"
                size={"small"}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="left">Created At</TableCell>
                    <TableCell align="left">Allow Email</TableCell>
                    <TableCell align="left">Confirmed?</TableCell>
                    <TableCell align="left"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <>
                        <TableRow hover key={row.user_id}>
                          <TableCell component="th" scope="row">
                            <strong>
                              <NavLink
                                to={"/users/" + row.user_id}
                                variant="contained"
                                color="primary"
                                size="small"
                                className="gridClickableText"
                              >
                                {row.fullName}
                              </NavLink>
                            </strong>
                          </TableCell>
                          <TableCell align="left" width="15%">
                            {row.username}
                          </TableCell>
                          <TableCell align="left" width="20%">
                            {row.email}
                          </TableCell>
                          <TableCell align="left" className="text-capitalize">
                            {row.role}
                          </TableCell>
                          <TableCell align="left" width="20%">
                            {dateTimeFormat(row.created_at)}
                          </TableCell>
                          <TableCell align="left" width="10%">
                            {row.allow_email ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="left" width="10%">
                            {row.confirmed ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="left" width="3%">
                            <strong>
                              <NavLink
                                to={"/users/" + row.user_id}
                                variant="contained"
                                color="primary"
                                size="small"
                                className="gridClickableText"
                              >
                                <i className="fa fa-eye fa-lger"></i>
                              </NavLink>
                            </strong>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                  {rows.length === 0 ? (
                    <TableRow style={{ height: 33 }}>
                      <TableCell colSpan={8} className="text-center">
                        No Data
                      </TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="row mx-0 p-0 mb-2 ">
              <div className="col-4 p-0 align-self-center">
                <strong>
                  {totalPages
                    ? "Page " + currentPage + " of " + totalPages
                    : ""}
                </strong>
              </div>
              {rows.length > 0 ? (
                <div className="col-8 p-0">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={clickPaginationBtn}
                    size="large"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
