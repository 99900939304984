import React, { useContext } from 'react'
import { Chip } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import ClickedVariableContext from '../../../Store/ContextApi/ClickedVariableContext';

const VarInfo = (props) => {
    const { setVariableToggel, setVariableId } = useContext(ClickedVariableContext);


    const onClickVariable = (vID) => {
        setVariableId(vID);
        setVariableToggel(false);
    }

    return (<div className={`var-info-comp ` + (props.customClass ? props.customClass : "")} >
        <div className="d-flex">
            <div className="var-label">
                <button className={"analysis-create"} onClick={() => onClickVariable(props.data.vID)}>
                    {props.data.orignlName}
                </button>
                {(props.data.expanded) ?
                    (props.data.tags_info.map((s) => (
                        <Chip
                            key={s.id}
                            className="ht-20 ml-2"
                            label={s.name}
                            variant="outlined"
                            title={s.name}
                        />
                    )))
                    :
                    (props.data.tags_info.slice(0, 1).map((s) => (
                        <Chip
                            key={s.id}
                            className="ht-20 ml-2"
                            label={s.name}
                            variant="outlined"
                            title={s.name}
                        />
                    )))
                }
            </div>
            <div className={(props.tagId) ? "toggle-tags" : ""}>
                {(props.data.tags_info.length > 1) ?
                    (<span className="list-group-item rounded-0 border-0 p-0 m-0 text-nowrap">
                        <button className={"button-to-link"} onClick={() => props.toggleTags(props.data.vID)}>{(props.data.expanded) ? "See less" : "+" + (props.data.tags_info.length - 1) + " More"}</button>
                    </span>) : ""}
            </div>
        </div>
        <div className="var-desc">{props.data.label}</div>
    </div >
    )
}

export default VarInfo;
