import { Card } from "@material-ui/core";
import React, { Component } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default class SharedProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangeSettings: false,
      visibility: this.props.visibility,
      publicUrl:'https://gssdataexplorer.norc.org/projects/'+this.props.user_id
    };

    this.changeSettings = this.changeSettings.bind(this);
    this.changeVisibility = this.changeVisibility.bind(this)
  }

  toggleIndividualEmail(){

  }
  toggleEmailList(){

  }
  changeVisibility(visibility){
      //e.preventDefault();
console.log(visibility);
this.setState({visibility:visibility,isChangeSettings:false})
  }
  changeSettings(e){
    e.preventDefault();
this.setState({isChangeSettings:true})
  }
  render() {
    var sharedDiv = "";
    if (this.state.isChangeSettings) {
      sharedDiv = 
        <div>
          <Card onClick={()=>{this.changeVisibility("private");this.props.changed("private")}}>
            <div
              className="btn selected"
              visibility="private"
              style={{ display: "block" }}
              
            >
              <i className="pull-left fa fa-lock"></i>
              <div className="content pull-left">
                <div className="title">Private</div>
                <p>Only you can view, add, and edit items in this project.</p>
              </div>
            </div>
          </Card>
          <br/>
          <Card onClick={()=>{this.changeVisibility("shared");this.props.changed("shared")}}>
            <div
              className="btn"
              visibility="shared"
              style={{ display: "block" }}
            >
              <i className="pull-left fa fa-users"></i>
              <div className="content pull-left">
                <div className="title">Shared</div>
                <p>
                  Invite others to join this project. In shared projects,
                  members can view, add, and edit items in this project.
                </p>
              </div>
            </div>
          </Card>
          <br/>
          <Card onClick={() =>{this.changeVisibility("public");this.props.changed("public")}}>
            <div
              className="btn"
              visibility="public"
              style={{ display: "block" }}
            >
              <i className="pull-left fa fa-globe"></i>
              <div className="content pull-left">
                <div className="title">Public</div>
                <p>
                  Allow others to view this project. In public projects, only
                  you can add or edit items, but anyone with a link to your
                  project can view all items.
                </p>
              </div>
            </div>
          </Card>
        </div>
      
    } else if (this.state.visibility == "private") {
      sharedDiv = 
        <div>
         
          <Card onClick={() => {this.changeVisibility("private");this.props.changed("private")}}>
            <div
              className="btn selected"
              visibility="private"
              style={{ display: "block" }}
            >
              <i className="pull-left fa fa-lock"></i>
              <div className="content pull-left">
                <div className="title">Private</div>
                <p>Only you can view, add, and edit items in this project.</p>
              </div>
            </div>
          </Card>
        </div>
    
    } else if (this.state.visibility == "shared") {
      sharedDiv = 
        <div>
          <Card onClick={() =>{this.changeVisibility("shared");this.props.changed("shared")}}>
            <div
              className="btn"
              visibility="shared"
              style={{ display: "block" }}
            >
              <i className="pull-left fa fa-users"></i>
              <div className="content pull-left">
                <div className="title">Shared</div>
                <p>
                  Invite others to join this project. In shared projects,
                  members can view, add, and edit items in this project.
                </p>
              </div>
            </div>
          </Card>
          {/* <div className="warning" style={{ display: "block" }}>
            <h5>Warning! Shared users will lose access!</h5>
            <div className="content">
              Making this project Private will prevent current users from
              accessing or adding content to the project.
            </div>
          </div> */}
          <div className="shared" style={{ display: "block" }}>
            <div
              className="invite"
              style={{ "marginTop": "25px", "marginBottom": "25px" }}
            >
              <h4 style={{ display: "block" }}>Invite Others</h4>
              Invite others to join this project. Enter email addresses to send
              an invitation. Invitees who do not have an account will be asked
              to register.
              <div className="mail-link-container">
                <a
                  href="#"
                  id="email_link_1"
                  onclick="toggleEmailList();"
                  className="emailList"
                >
                  add email list
                </a>
                {/* <a
                  href="#"
                  id="email_link_2"
                  onclick="toggleIndividualEmail();"
                >
                  add individual emails
                </a> */}
              </div>
              {/* <div className="alert-danger hidden" id="invalid-email">
                Please enter a valid email address; for example,
                address@email.com
              </div> */}
              <div className="input-group" id="email-individual">
                <span className="input-group-addon fa fa-envelope"></span>
                <input
                  type="text"
                  name="emails"
                  id="emails"
                  value=""
                  placeholder="Enter email addresses"
                />
              </div>
              <div
                className="input-group"
                id="email-list"
                style={{ display: "none" }}
              >
                <span
                  className="input-group-addon fa fa-envelope pull-left"
                  style={{ "margin-right": "1px" }}
                ></span>
                <textarea
                  name="emails2"
                  id="email_list_input"
                  placeholder="Enter a list of emails separated by comma or newline"
                  style={{
                    width: "380px",
                    height: "44px",
                    display: "block",
                    border: "none",
                    "box-shadow": "none",
                    "padding-top": "7px",
                  }}
                ></textarea>
                <a href="#" onclick="addEmailList()" className="addList">
                  ADD LIST
                </a>
              </div>
            </div>
          </div>
        </div>
      
    } else if (this.state.visibility == "public") {
      sharedDiv = 
        <div>
          <Card onClick={()=>{this.changeVisibility("public");this.props.changed("public")}}>
           
            <div
              className="btn"
              visibility="public"
              style={{ display: "block" }}
            >
              <i className="pull-left fa fa-globe"></i>
              <div className="content pull-left">
                <div className="title">Public</div>
                <p>
                  Allow others to view this project. In public projects, only
                  you can add or edit items, but anyone with a link to your
                  project can view all items.
                </p>
              </div>
            </div>
          </Card>
          <div className="public" style={{ display: "block" }}>
            Share the URL below with others you want to view your project.
            <a href="#" id="public-url">
             {this.state.publicUrl}
            </a>
          <br/>
            <CopyToClipboard text={this.state.publicUrl}>
            <a className="copy" data-clipboard-target="public-url" href="#">
              Copy link
            </a>
    </CopyToClipboard>
           
          </div>
        </div>
      
    }
    return (
      <div>
        <div className="sharing" style={{ display: "block" }}>
          <h4 style={{ display: "block" }}>Project Sharing</h4>
          <p>
            Choose whether to keep your project private or share it with others.
            You are the only person who can modify the settings for this project
            and you can change the settings at any time.
          </p>
          { this.state.isChangeSettings ? null: <a className="change" onClick={this.changeSettings} >
            Change privacy setting
          </a>}
          {sharedDiv}

          {/* <input type="hidden" name="workspace[visibility]" id="workspace_visibility" value="private" />
                <input type="hidden" name="removed_emails" value="" className="removed_emails" />
                <input type="hidden" name="from_index" id="from_index" value="1" /> */}
        </div>
      </div>
    );
  }
}
