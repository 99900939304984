import React from 'react';
import DownloadGSSDataset from '../Homepage/DownloadGSSDataset/DownloadGSSDataset';
import ExtractsList from './ExtractsList/ExtractsList'
function ExtractManagement(props){
    return (
       <div>
            {/* <DownloadGSSDataset></DownloadGSSDataset> */}
       <ExtractsList></ExtractsList>
       </div>
    )
}
export default ExtractManagement;