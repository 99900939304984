import React from 'react';
import { Spinner } from 'react-bootstrap';

import './Loader.css';

const Loader = (props) => {
    return (
        <div className={'customize-loader ' + (props.setHeight?'h-100': '')}>
            <Spinner animation="grow" />
        </div>
    );
};

export default Loader;