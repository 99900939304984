import { MenuItem, Select } from "@material-ui/core";

const BreakDownDd = (props) => {
  const handleChange = (event) => {
    props.changeHandler(event.target.value, true);
  };
  return (
    <Select onChange={handleChange} value={props.dropDownValue} variant="outlined">
      {props.dropDownData?.map((option, key) => {
        return (
          <MenuItem name={option.name} key={option.name} value={option.label}>
            {option.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
export default BreakDownDd;
