import * as React from "react";
import { Pagination } from "@material-ui/lab";
import { useState } from "react";
import axios from "axios";
import { environment } from "../../../environment";
import { useEffect } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Spinner } from "react-bootstrap";
import { DownloadMediaDoc } from "../../../Store/utils/common.utils";
import ReportsContext from "../../../Store/ContextApi/ReportsContext";
import { useContext } from "react";
import ReportsBallotInfo from "./ReportsBallotInfo";

export default function ReportsGrid(props) {
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);

  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
    variant: null,
  });

  const { value1, value2, value3 } = useContext(ReportsContext);
  const [inputs, setInputs] = value1;
  const [yearRange, setYearRange] = value2;
  let defaultRange = value3;

  let context = useContext(ReportsContext);
  useEffect(() => {
    if (props.isUpdateGrid) {
      getDocList();
    }
  }, [props.isUpdateGrid]);

  useEffect(() => {
    getDocList();
  }, [currentPage]);

  useEffect(() => {
    if (inputs.isCleared) {
      getDocList();
    }
  }, [inputs]);

  const getDocList = (isClearSearch, keyword, author) => {
    setLoading(true);
    let reqObj = {
      page: currentPage == 0 ? 1 : currentPage,
      limit: limit,
      category: props.categoryId,
      year_range: {
        min: yearRange[0] ? yearRange[0] : defaultRange[0],
        max: yearRange[1] ? yearRange[1] : defaultRange[1],
      },
      keyword: inputs.keyword ? inputs.keyword : "",
      author: inputs.author ? inputs.author : "",
    };

    console.log("reqObj: " + JSON.stringify(reqObj));
    axios
      .post(`${environment.docsList}`, reqObj)
      .then((response) => {
        if (response.data) {
          let docs = response.data;
          // console.log("users",users)
          setRows(docs.doc_list);
          setTotalPage(docs.total_pages);
          setTotalRecords(docs.total_doc);
          setInitialLoad(true);
          setLoading(false);
        }
        props.refreshFlag();
      })
      .catch((err) => {
        if (err.response) {
          //alert(err.response.data.message);
          setIsServerError({
            ...isServerError,
            status: true,
            message: err.response.data.message,
            variant: "Error",
          });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // alert('Some Error Occurred');
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // client never received a response, or request never left
        } else {
          setIsServerError({
            ...isServerError,
            status: true,
            message: "Something went wrong. Try Again!!",
            variant: "Error",
          });
          // anything else
        }
      });
  };

  const clickPaginationBtn = (e, val) => {
    let setPage = val;
    setCurrentPage(setPage);
  };

  return (
    <div className="gss_material_table">
      {true && (
        <>
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
              className="custom-spinner"
            />
          ) : (
            ""
          )}
          <div>
            <span className="mr-5">
              <b className="mr-1">{totalRecords} Results</b>
              <span>matching criteria</span>
            </span>
          </div>
          <div className="row mx-0 p-0 mb-2 ">
            <div className="col-4 p-0 align-self-center">
              <strong>
                {totalPages ? "Page " + currentPage + " of " + totalPages : ""}
              </strong>
            </div>
            {rows.length > 0 ? (
              <div className="col-8 p-0">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={clickPaginationBtn}
                  size="large"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <TableContainer>
            <Table
              className={`main-root mb-3`}
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Authors</TableCell>
                  <TableCell align="left">Applicable Years</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <>
                      <TableRow hover key={row.document_id}>
                        <TableCell component="th" scope="row" width="20%">
                          <strong>
                            <a
                              className="gridClickableText"
                              onClick={() => DownloadMediaDoc(row.document_id)}
                            >
                              {row.name}
                            </a>
                          </strong>
                        </TableCell>
                        <TableCell align="left" width="35%">
                          {row.description}
                        </TableCell>
                        <TableCell align="left" width="15%">
                          {row.authors}
                        </TableCell>
                        <TableCell align="left" className="text-capitalize">
                          <ReportsBallotInfo years={row.applicable_years} />
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
                {rows.length === 0 ? (
                  <TableRow style={{ height: 33 }}>
                    <TableCell colSpan={8} className="text-center">
                      No Data
                    </TableCell>
                  </TableRow>
                ) : (
                  ""
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
