import React from 'react';
import { Col, Card, Row, Table } from "react-bootstrap";
import { useSelector } from 'react-redux';
import BigButton from '../../Components/UI/BigButton/BigButton';
import "./Features.css"




function FeaturesCard(props) {
    const bull = <span className="bullet">•</span>;
    const authDetails = useSelector(state => state.authReducer);
  const fetchUrl=()=>{
    let url="/user/sign_up"
    if(authDetails.isSignedIn || localStorage.isSignedIn === 'true'){
       url= "/MyGSS"
    }
    return url
  }
    return (

        <Card className="border-0">
            <div className={props.light === 'true' ? "light" : props.mainTitle != '' ? "blackBg" : "mainContainer"}>
                
                    <img src={props.img} className="media" />
   

                <div className={props.mainTitle != '' ? "flexRightText" : "flexRight"}>
                    <Card.Title>
                        <p className="textCenter">
                            <i className={props.icon != '' ? props.icon : props.mainTitle}></i>
                            {props.mainTitle}

                            <br />
                            <b className={props.mainTitle != '' ? "mainTitle": ""}>{props.title}</b> <br />
                        </p>
                    </Card.Title>
                    <Card.Text>
                        <p className="card-text textCenter" >
                            {props.mainText}
                        </p>

                        {props.button == 'true' ? <div className="btn-container1">
                            {props.mainTitle != '' ? <BigButton classname="btn-orange" eleType="link" url={fetchUrl}>CREATE AN ACCOUNT</BigButton>
                                : <BigButton classname="btn btn-dark" eleType="link" url="/variables/vfilter">{props.buttonText}</BigButton>}

                        </div> : ''}


                    </Card.Text>

                </div>
            </div>


        </Card>

    );
}
export default FeaturesCard;