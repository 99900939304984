import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./ProjectTile.css";
import DeleteProject from "../DeleteProject/DeleteProject";
import GetProjectSettings from "../ProjectSettings/GetProjectSettings/GetProjectSettings";
import Delete from "../Delete/Delete";
import axios from "axios";
import { environment } from "../../../environment";

export default class ProjectTile extends Component {
  deleteObj = {
    isOpen: true,
    title: "Are You Sure You Want to Delete This Project?",
    message:
      "If you delete this project, you will no longer have access to it. Are you sure you want to remove this project?",
  };
  constructor(props) {
    super(props);
    this.state = {
      isDelete: false,
      isSettings: false,
      settingsData: {},
    };
    this.deleteProject = this.deleteProject.bind(this);
    this.settingsProject = this.settingsProject.bind(this);
    //this.onClick = this.onClick.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    //this.setCurrentProject = this.setCurrentProject.bind(this);
  }

  componentDidMount() {
    // isClosed.subscribe(value =>{
    //     if(value){
    //         console.log("value sun",value)
    //         this.setState({isSettings:!value})
    //     }
    // })
    // console.log(this.props);
  }
  deleteProject() {
    this.setState({ isDelete: true });
  }
  settingsProject() {
    this.setState({ isSettings: true });
  }

  closeDialog() {
    console.log("close event");
    this.setState({ isSettings: false });
    // window.location.reload();
  }

  setCurrProj(workspace_id, workspace_name) {
    if (!workspace_id) {
      return;
    }
    axios({
      method: "POST",
      url: `${environment.setCurrentProject}`,
      data: {
        current_workspace_id: workspace_id,
      },
    })
      .then((response) => {
        localStorage.defaultProjectNo = workspace_id;
        localStorage.defaultProjectName = workspace_name;
        console.log("Current Project updated successfully");
      })
      .catch((err) => {
        if (err.response) {
          alert(err.response.data.message);
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  }
  render() {
    return (
      <div
        className={
          "project-tile " +
          (localStorage.defaultProjectNo == this.props.workspace_id
            ? "default-project"
            : "")
        }
        project_id="88155"
        visibility="private"
      >
        <div className="title">
          <a
            title={this.props.workspace_name}
            onClick={this.setCurrProj.bind(
              this,
              this.props.workspace_id,
              this.props.workspace_name
            )}
          >
            {this.props.workspace_name}{" "}
            {localStorage.defaultProjectNo == this.props.workspace_id && (
              <span style={{ fontSize: "12px", fontStyle: "italic" }}>
                (Current Project)
              </span>
            )}
          </a>
          <div className="visibility-info">
            <i className="fa fa-lock project-icon"></i>
            {this.props.workspace_visibility}
          </div>
          <div
            className="gss-popover gss-dismiss"
            data-original-title=""
            title=""
          >
            <div className="gss-dd-content" id="confirm-delete-project-88155">
              <div
                className="confirm-delete-project gss-dd"
                data-original-title=""
                title=""
              >
                <div className="dd-header">
                  <div className="pull-left">
                    Are You Sure You Want to Delete This Project?
                  </div>
                  <a className="pull-right fa fa-times" href="#"></a>
                </div>
                <div className="dd-body narrow">
                  <div className="fa-gss-norc-orange bold">Warning!</div>
                  <p>
                    If you delete this project, you will no longer have access
                    to it. Are you sure you want to remove this project?
                  </p>
                </div>
                <div className="gss-dd-footer">
                  <a className="btn btn-orange" href="/projects/88155/remove">
                    DELETE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="activity">
          <div className="time pull-left">
            {" "}
            &nbsp;
            {this.props.workspace_description}
            <br />
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="description">
          <em className="last_activity">
            Last activity: &nbsp;
            {this.props.last_activity}
          </em>
          <div className="settings pull-right">
            <span
              className="fa fa-gear"
              title="Project settings"
              onClick={this.settingsProject}
            ></span>
            {this.state.isSettings ? (
              <GetProjectSettings
                {...this.props}
                handleCloseEvent={this.closeDialog}
              />
            ) : undefined}
          </div>
          <div className="square pull-right">
            <NavLink
              to="#"
              className="fa fa-trash-o gss-dd-target gss-dismiss trash_icon"
              id="delete-project"
              onClick={this.deleteProject}
            ></NavLink>

            {this.state.isDelete ? <DeleteProject {...this.deleteObj} /> : null}
            {/* <Delete  onRef={ref => (this.Delete = ref)}/> */}
          </div>
        </div>
        <div
          className="contents"
          onClick={this.setCurrProj.bind(
            this,
            this.props.workspace_id,
            this.props.workspace_name
          )}
        >
          <div className="content-item top br">
            <div className="count">
              <NavLink
                to={
                "/variables/data_cart/"
                }
              >
                {this.props.variable_count}
              </NavLink>
            </div>

            <span className="project_label">Variables</span>
          </div>
          <div className="content-item top br">
            <div className="count">
              <NavLink to={"/user_searches/" + this.props.workspace_id}>
                {this.props.search_count}
              </NavLink>
            </div>

            <span className="project_label">Searches</span>
          </div>
          <div className="content-item top">
            <div className="count">
              <NavLink
                to={
                  "/projects/88155/variables/data_cart/" +
                  this.props.workspace_id
                }
              >
                {this.props.extract_count}
              </NavLink>
            </div>

            <span className="project_label">Extracts</span>
          </div>
          <div className="content-item br">
            <div className="count">
              <NavLink
                to={
                  "/analysisHistory"
                }
              >
                {this.props.analysis_count}
              </NavLink>
            </div>

            <span className="project_label">Analyses</span>
          </div>
          <div className="content-item br">
            <span className="project_label"></span>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}
