import React, { useState, useEffect } from "react";
import axios from "axios";
import { environment } from "../../../environment";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./ExtractSummary.css";
import { NavLink } from "react-router-dom";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { updateService } from "../../../Shared/UpdateCart";
import ShareExtract from "../ShareExtract/ShareExtract";
function ExtractSummary(props) {
  let { id } = useParams();
  const authDetails = useSelector((state) => state.authReducer);
  const extractDetails = useSelector((state) => state.extractReducer);
  const [data, setData] = useState({});
  const [otherParams, setOtherParams] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState("");
  const [isTrying, setIsTrying] = useState(false);

  //const [isOpen, setIsOpen] = useState(false);
  const [isParent, setParent] = useState(true);
  const [searchId, setSearchId] = useState();
  const [commentData, setCommentData] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [isDwldError, setIsDwldError] = useState({
    status: false,
    message: null,
  });

  const getData = (val) => {
    if (val) setIsTrying(true);

    axios
      .post(`${environment.getExtractSummary}`, { extract_id: id })
      .then((response) => {
        setIsLoading(false);
        setIsTrying(false);
        setIsServerError(false);
        setServerErrorMsg("");
        setData(response.data.final_response);
      })
      .catch((error) => {
        let msg;
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          msg = error.response.data;
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          msg = error.request;
        } else {
          // Something happened in setting up the request that triggered an Error
          msg = error.message;
        }
        /* let str = {"ExtractID": 67278, "Extract_name": "bakendtester_shubham1", "exDescription": "updated by lambda update_extract_status", "params": "{'variables': [23, 99], 'years': [1998, 1990, 2019], 'userId': 58806, 'cases': [{'type': 'range', 'id': 1, 'values': {'start': 1988, 'end': 2016}}], 'file_type_list': [1, 2, 3, 4]}", "filename": "bakendtester_shubham1", "id_obj": [{"id": 23, "name": "spwrksta", "label": "Spouse labor force status"}, {"id": 99, "name": "preteen", "label": "Household members 6 thru 12 yrs old"}]};
        if(str.params){
          let newStr = str.params.replace(/\'/g, '"');
          console.log('newStr====>',JSON.parse(newStr));
          setOtherParams(JSON.parse(newStr));
        }
        setIsLoading(false);
        setIsTrying(false);
        setIsServerError(false);
        setServerErrorMsg('');       
        setData(str)    */
        setIsLoading(false);
        setIsTrying(false);
        setIsServerError(true);
        setServerErrorMsg(
          msg.message ? msg.message : "Something went wrong. Try Again!!"
        );
        //show alert for error
      });
  };

  useEffect(() => {
    console.log(extractDetails);
    getData();
    //updateService.sendAlert("varData");
  }, [id]);
  /*  const shareExtract = () => {
   
     setIsOpen(true);
   };
   const editComment = (comment) => {
     setCommentData(comment);
     setIsOpen(true);
     setEdit(true);
   }; */

  /*  const downloadZip =() =>{
     axios
     .get('http://releases.ubuntu.com/12.04.5/ubuntu-12.04.5-alternate-amd64.iso',
         {
             responseType: 'arraybuffer',
             headers: {
                 'Content-Type': 'multipart/form-data',
             }
         }).then((response) => {
          console.log(response)
         })
   } */
  /*  const submitSave = (text) => {
     if (text) {
       let url = "";
       let req = {};
       if (isEdit) {
         url = environment.editSearchComment;
         req = {
           content: text,
           id_: commentData.comment_id,
         };
       } else {
         url = environment.addSearchComments;
         req =
           isParent == "parent"
             ? {
                 commentable_id: Number(searchId),
                 commentable_type: "UserSearch",
                 content: text,
               }
             : {
                 commentable_id: Number(searchId),
                 commentable_type: "UserSearch",
                 content: text,
                 parent_id: Number(isParent.comment_id),
               };
       }
       axios
         .post(`${url}`, req)
         .then((resp) => {
           getData();
         })
         .catch((error) => {
           console.log(error);
         });
     }
   }; */
  /* const deleteComment = (id) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      axios
        .delete(`${environment.deleteSearchComment}`, {
          data: {
            id_: id,
          },
        })
        .then((response) => {
          getData();
        })
        .catch((err) => {
          if (err.response) {
            alert(err.response.data.message);
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            // client never received a response, or request never left
          } else {
            // anything else
          }
        });
    } else {
    }
  };
  const setOpenFn = () => {
    setIsOpen(!isOpen);
  }; */

  const handleDownloadExtract = (e, id) => {
    e.preventDefault();
    let req_obj = {
      extract_ids: [id],
      user_id: authDetails.isAdmin ? data.params?.userId : undefined,
    };
    axios
      .post(`${environment.downloadExtract}`, req_obj)
      .then((response) => {
        window.open(response.data.objectUrl);
      })
      .catch((error) => {
        let msg;
        if (error.response) {
          msg = error.response.data;
          console.log("1===>", msg);
        } else if (error.request) {
          msg = error.request;
          console.log("2nd---->", error.request);
        } else {
          msg = error.message;
          console.log("3rd---->Error", error.message);
        }
        setIsDwldError({
          ...isDwldError,
          status: true,
          message: msg.message
            ? msg.message
            : "Something went wrong. Try Again!!",
        });
      });
  };

  const setIsOpen = () => {
    setIsDwldError({ ...isDwldError, status: !isDwldError.status });
  };
  return (
    <div className="container-fluid mt-4 pl-0 pr-0 pb-0" id="ws-content">
      <CustomModal
        title={"Error"}
        titleText="Error"
        isOpen={isDwldError.status}
        setIsOpen={setIsOpen}
        inputLabel={isDwldError.message}
      ></CustomModal>
      <h3 className="pl-4 gss-bold-title">Extract Summary</h3>

      <div
        className="container-fluid pb-1"
        style={{ backgroundColor: "#ebe8e5" }}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              color: "#F3901D",
            }}
          >
            <Spinner animation="grow" />
          </div>
        ) : isServerError ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              color: "#F3901D",
            }}
          >
            <label>{serverErrorMsg}</label>
            {isTrying ? (
              <button type="button" className="gss-btn btn-orange">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                &nbsp;Fetching...
              </button>
            ) : (
              <button
                type="button"
                className="gss-btn btn-orange"
                onClick={() => getData("tryAgain")}
              >
                Try Again
              </button>
            )}
          </div>
        ) : (
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="pt-3 extract-title-text">{data.Extract_name}</h4>
              {extractDetails?.extract_data?.data?.download_enable ? (
                <a
                  title="Download"
                  href="#"
                  onClick={(e) => handleDownloadExtract(e, id)}
                  className={
                    !data.cur_status?.toLowerCase().includes("finished")
                      ? "not-active"
                      : ""
                  }
                >
                  <i className="fa fa-download fa-lg"></i>
                </a>
              ) : (
                <></>
              )}
            </div>
            {!data.cur_status?.toLowerCase().includes("finished") ? (
              <div className="d-flex">
                <span className="param errText">
                  {data.cur_status?.toLowerCase().includes("submitted")
                    ? "Current status - Pending"
                    : data.cur_status?.toLowerCase().includes("started")
                    ? "Current status - In-Progress"
                    : data.cur_status}
                </span>
              </div>
            ) : undefined}
            <hr />
            <div className="mb-3">
              <p className="mb-0 param">
                <b>Variables:</b>
              </p>
              {data.id_obj && data.id_obj.length > 0
                ? data.id_obj.map((variable, index) => (
                    <div className="row pl-5 pr-5" key={index}>
                      <div className="col-2 param">{variable.name}</div>
                      <div className="col param">{variable.label}</div>
                    </div>
                  ))
                : ""}
            </div>
            <div className="mb-3">
              <p className="mb-0 param">
                <b>Case selection:</b>
              </p>
              <div className="row pl-5 pr-5">
                <div className="col param">{data.caseSelection}</div>
              </div>
              {/* {otherParams.cases && otherParams.cases.length > 0 ? (
                 otherParams.cases.map((caseItem, index)=>(
                <div className="row pl-5 pr-5" key={index}>
                  <div className="col param">{caseItem.name}</div>
                </div>
              ))):""} */}
            </div>

            <div className="mb-3">
              <p className="mb-0 param">
                <b>Years Included:</b>
              </p>
              {data.params?.years && data.params?.years?.length > 0
                ? data.params.years.map((year, index) => (
                    <div className="row pl-5 pr-5" key={index}>
                      <div className="col param">{year}</div>
                    </div>
                  ))
                : ""}
            </div>
            <div className="mb-3">
              <p className="mb-0 param">
                <b>Formats:</b>
              </p>
              {data.params?.file_type_list &&
              data.params?.file_type_list.length > 0
                ? data.params?.file_type_list.map((file, index) => (
                    <div className="row pl-5 pr-5" key={index}>
                      <div className="col param">
                        {file == 1
                          ? "Excel Workbook (data + metadata)"
                          : file == 2
                          ? "Excel Workbook (metadata only)"
                          : file == 3
                          ? "SPSS Control File"
                          : file == 4
                          ? "SAS Control File"
                          : file == 5
                          ? "Stata (Control + System)"
                          : file == 6
                          ? "DDI Version 3.1 Metadata"
                          : file == 7
                          ? "R Script"
                          : file == 11
                          ? "JSON"
                          : ""}
                      </div>
                    </div>
                  ))
                : ""}
            </div>

            {data.params?.label_data ? (
              <div className="mb-3">
                <p className="mb-0 param">
                  <b>Metadata:</b>
                </p>
                <div className="row pl-5 pr-5">
                  <div className="col param">{data.params?.label_data}</div>
                </div>
                {/* {otherParams.cases && otherParams.cases.length > 0 ? (
                   otherParams.cases.map((caseItem, index)=>(
                  <div className="row pl-5 pr-5" key={index}>
                    <div className="col param">{caseItem.name}</div>
                  </div>
                ))):""} */}
              </div>
            ) : (
              /* <div className="mb-3">
                <p className="mb-0 param"><b>Metadata:&nbsp;</b><span>{data.params?.label_data}</span></p>
              </div> */
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}
export default ExtractSummary;
