import { React, useEffect, useState } from "react";
import Axios from "axios";
import { environment } from "../../environment";
import FormCard from "../UI/FormCard/FormCard";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Row, Container, Col, Dropdown, Form } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import { CChart } from "@coreui/react-chartjs";
import { CContainer } from "@coreui/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import BootstrapTable from "react-bootstrap-table-next";
import "../PopularVaribales/react-bootstrap-table2.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "./react-bootstrap-table2-filter.min.css";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import FileSaver from "file-saver";
const NewRegisteredUser = (props) => {
  const [data, setData] = useState([]);
  const [dataD, setDataD] = useState([]);
  const [dataT, setDataT] = useState([]);
  const [dataO, setDataO] = useState([]);
  const [DepData, setDepData] = useState([]);
  const [OrgData, setOrgData] = useState([]);
  const [isShown500, setIsShown500] = useState(true);
  const [isShownDe, setIsShownDe] = useState(false);
  const [isShownOrg, setIsShownOrg] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingD, setLoadingD] = useState(true);
  const [loadingOr, setLoadingOr] = useState(true);
  const [type, setType] = useState(2022);
  const [typeD, setTypeD] = useState("Anthropology");
  const [typeOr, setTypeOr] = useState("Advocacy Organization");
  const { ExportCSVButton } = CSVExport;
  const MyExportCSV = (props) => {
    const handleClick = (e) => {
      if (e && e.preventDefault) {
        e.preventDefault();
      }
      props.onExport();
    };
    return (
      <Tooltip title="Export">
        <FileDownloadIcon style={{ color: "#50917E" }} onClick={handleClick} />
      </Tooltip>
    );
  };
  const ArrayOfYears = () => {
    var max = new Date().getFullYear();
    var min = 2015;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };
  var years = ArrayOfYears();
  console.log(years, "yearsss");

  const DepartmentJSON = () => {
    Axios.get(process.env.PUBLIC_URL + "/config/project_og.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
      },
    }).then((response) => {
      setDepData(response.data.dep);
    });
  };
  const OrganizationJSON = () => {
    Axios.get(process.env.PUBLIC_URL + "/config/organisations_og.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
      },
    }).then((response) => {
      setOrgData(response.data.org);
    });
  };
  console.log(DepData, "depData");
  const getNewUsers = () => {
    Axios.get(`${environment.newRegisteredUserCount}`)
      .then((resp) => {
        if (resp.data) {
          let data1 = resp.data.monthwise.reverse();
          data1.forEach(function (element, i) {
            element.sr_no = i + 1;
          });
          setData(data1);
          setLoading(false);
          let dataDe = resp.data.department_wise.reverse();

          // dataDe.forEach(function (element, i) {
          //   element.sr_no = i + 1;
          // });
          setDataD(dataDe);
          setLoadingD(false);
          console.log(dataD, "reverse Department");
          let totalRegistered = resp.data.Total_users.map(
            (e) => e.Total_Users_Registered
          );
          setDataT(totalRegistered);

          let dataOrga = resp.data.organization_wise.reverse();

          dataOrga.forEach(function (element, i) {
            element.sr_no = i + 1;
          });
          setDataO(dataOrga);
          setLoadingOr(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log(dataD, "dataD");
  const handleClickMonth = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShown500((current) => !current);
    setIsShownDe(false);
    setIsShownOrg(false);
  };
  const handleClickDepartment = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShownDe((current) => !current);
    setIsShown500(false);
    setIsShownOrg(false);
  };
  const handleClickOrganization = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsShownOrg((current) => !current);
    setIsShown500(false);
    setIsShownDe(false);
  };
  useEffect(() => {
    if (localStorage.isAdmin != "true") {
      props.history.push("/error");
      return;
    }
  }, []);
  useEffect(() => {
    getNewUsers();
    setType(type);
    setTypeD(typeD);
    setTypeOr(typeOr);
    DepartmentJSON();
    OrganizationJSON();
  }, []);
  useEffect(() => {
    setTypeD(typeD);
  }, [typeD]);
  useEffect(() => {
    setTypeOr(typeOr);
  }, [typeOr]);
  console.log(loading, "loadin");
  const changeType = ({ target: { value } }) => {
    setType(value);
    // setTypeD(value);
    return;
  };
  const changeTypeD = ({ target: { value } }) => {
    setTypeD(value);
    return;
  };
  const changeTypeO = ({ target: { value } }) => {
    setTypeOr(value);
    return;
  };
  const columns = [
    { dataField: "YEAR", text: "Year", filter: textFilter() },
    { dataField: "Month", text: "Month", filter: textFilter() },
    {
      dataField: "Total_Users_Registered",
      text: "Total Registered Users",
      filter: textFilter(),
    },
  ];
  const columnD = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    { dataField: "YEAR", text: "Year", filter: textFilter() },
    {
      dataField: "Total_Users_Registered_D",
      text: "Total Registered Users",
      filter: textFilter(),
    },
  ];
  const columnDep = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    {
      dataField: "department",
      text: "Department",
      filter: textFilter(),
    },
    { dataField: "YEAR", text: "Year", filter: textFilter() },
    {
      dataField: "Total_Users_Registered_D",
      text: "Total Registered Users",
      filter: textFilter(),
    },
  ];
  const columnO = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    { dataField: "YEAR", text: "Year", filter: textFilter() },
    {
      dataField: "Total_Users_Registered_O",
      text: "Total Registered Users",
      filter: textFilter(),
    },
  ];
  const columnOrg = [
    // { dataField: "sr_no", text: "Sr. No.", filter: textFilter(), sort: true },
    {
      dataField: "organization",
      text: "Organization",
      filter: textFilter(),
    },
    { dataField: "YEAR", text: "Year", filter: textFilter() },
    {
      dataField: "Total_Users_Registered_O",
      text: "Total Registered Users",
      filter: textFilter(),
    },
  ];

  return (
    <FormCard title="" header="New Registered Users">
      <form className="row w-500 simple_form form-horizontal mb-4 m-0 ">
        <div className="col-lg-12 col-xl-12 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center">
          <button  className="col-4 button10" onClick={handleClickMonth}>
            Month wise
          </button>
          <button
            className="col-4 button10 mx-2 "
            onClick={handleClickDepartment}
          >
            Department wise
          </button>
          <button className="col-4 button10" onClick={handleClickOrganization}>
            Organization wise
          </button>
        </div>
        <div className="heading col-12 d-flex justify-content-center p-0 m-0">
          <p>
            Total Registered Users: <strong>{dataT}</strong>
          </p>
        </div>

        {isShown500 && (
          <>
            <div className="col-12 d-flex justify-content-end m-0">
              <Tooltip title="Close">
                <CloseIcon className="closeButton" onClick={handleClickMonth} />
              </Tooltip>
            </div>
            <div className="col-6 d-flex justify-content-left m-0 mb-2">
              <p className="col-3 p-0 m-0">Year Filter:</p>
              <Form.Control
                fullWidth={true}
                defaultValue={type}
                onChange={changeType}
                className="hlabel w-auto col-7"
                as="select"
              >
                <option value="All">All Years</option>
                {years.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Control>
            </div>

            <Form.Row className="mt-2">
              <p className="hlabel p-3"></p>
            </Form.Row>

            <div className="col-12 d-flex justify-content-center mb-2">
              {loading && isShown500 ? (
                <CircularProgress
                  disableShrink
                  size="1.2rem"
                  thickness="10"
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            {type !== "All" ? (
              <>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                      <ToolkitProvider
                        bootstrap4
                        keyField="variable_id"
                        columns={columns}
                        data={data.filter((e) => e.YEAR == type)}
                        exportCSV={{
                          fileName: `New Registered Users ${type}.csv`,
                        }}
                      >
                        {(props) => (
                          <div className="row w-100 m-0 p-0">
                            <div className="col-12 p-0 m-0">
                              <MyExportCSV {...props.csvProps} />
                              Export to CSV
                            </div>
                            <div className="col-12 p-0 m-0 fixTableHead">
                              <BootstrapTable
                                filter={filterFactory()}
                                {...props.baseProps}
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mt-3 p-0">
                  <CContainer fluid>
                    <CChart
                      type="bar"
                      height="300"
                      data={{
                        labels: data
                          .filter((e) => e.YEAR == type)
                          .map((i) => i.Month + " " + i.YEAR),
                        datasets: [
                          {
                            label: "Counts: ",
                            fill: true,
                            backgroundColor: "#619080",
                            borderColor: "black",
                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                            pointBorderColor: "red",
                            data: data
                              .filter((e) => e.YEAR == type)
                              .map((i) => i.Total_Users_Registered),
                          },
                        ],
                      }}
                    />
                  </CContainer>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                      <ToolkitProvider
                        bootstrap4
                        keyField="variable_id"
                        columns={columns}
                        data={data}
                        exportCSV={{
                          fileName: "All New Registered Users Monthwise.csv",
                        }}
                      >
                        {(props) => (
                          <div className="row w-100 m-0 p-0">
                            <div className="col-12 p-0 m-0">
                              <MyExportCSV {...props.csvProps} />
                              Export to CSV
                            </div>
                            <div className="col-12 p-0 m-0 fixTableHead">
                              <BootstrapTable
                                filter={filterFactory()}
                                {...props.baseProps}
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mt-3 p-0">
                  <CContainer fluid>
                    <CChart
                      type="bar"
                      height="300"
                      data={{
                        labels: data.map((i) => i.Month + " " + i.YEAR),
                        datasets: [
                          {
                            label: "Counts: ",
                            fill: true,
                            backgroundColor: "#619080",
                            borderColor: "black",
                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                            pointBorderColor: "red",
                            data: data.map((i) => i.Total_Users_Registered),
                          },
                        ],
                      }}
                    />
                  </CContainer>
                </div>
              </>
            )}
          </>
        )}
        {isShownDe && (
          <>
            <div className="col-12 d-flex justify-content-end m-0">
              <Tooltip title="Close">
                <CloseIcon
                  className="closeButton"
                  onClick={handleClickDepartment}
                />
              </Tooltip>
            </div>
            <div className="col-6 d-flex justify-content-left m-0 mb-2">
              <p className="col-3 p-0 m-0">Department Filter: </p>
              <Form.Control
                fullWidth={true}
                defaultValue={typeD}
                onChange={changeTypeD}
                className="hlabel w-auto col-7"
                as="select"
              >
                <option value="AllD">All Departments</option>
                {DepData.map((e) => (
                  <option key={e.name} value={e.name}>
                    {e.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-12 d-flex justify-content-center m-0">
              <p className="d-flex align-content-center">
                {typeD == "AllD" ? (
                  <strong className="heading">All Departments</strong>
                ) : (
                  <strong className="heading">{typeD}</strong>
                )}
              </p>
            </div>

            <div className="col-12 d-flex justify-content-center mb-2">
              {loadingD && isShownDe ? (
                <CircularProgress
                  disableShrink
                  size="1.2rem"
                  thickness="10"
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            {typeD !== "AllD" ? (
              <>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                      <ToolkitProvider
                        bootstrap4
                        keyField="variable_id"
                        columns={columnD}
                        data={dataD.filter((e) => e.department == typeD)}
                        exportCSV={{
                          fileName: ` ${typeD} Departmentwise New Registered Users.csv`,
                        }}
                      >
                        {(props) => (
                          <div className="row w-100 m-0 p-0">
                            <div className="col-12 p-0 m-0">
                              <MyExportCSV {...props.csvProps} />
                              Export to CSV
                            </div>
                            <div className="col-12 p-0 m-0 fixTableHead">
                              <BootstrapTable
                                filter={filterFactory()}
                                {...props.baseProps}
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mt-3 p-0">
                  <CContainer fluid>
                    <CChart
                      type="bar"
                      height="300"
                      data={{
                        labels: dataD
                          .filter((e) => e.department == typeD)
                          .map((i) => i.YEAR),
                        datasets: [
                          {
                            label: "Counts: ",
                            fill: true,
                            backgroundColor: "#619080",
                            borderColor: "black",
                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                            pointBorderColor: "red",
                            data: dataD
                              .filter((e) => e.department == typeD)
                              .map((i) => i.Total_Users_Registered_D),
                          },
                        ],
                      }}
                    />
                  </CContainer>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 d-flex justify-content-center mb-3 ">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                      <ToolkitProvider
                        bootstrap4
                        keyField="variable_id"
                        columns={columnDep}
                        data={dataD
                          .sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
                          .reverse()}
                        exportCSV={{
                          fileName:
                            "Departmentwise All New Registered Users.csv",
                        }}
                      >
                        {(props) => (
                          <div className="row w-100 m-0 p-0">
                            <div className="col-12 p-0 m-0">
                              <MyExportCSV {...props.csvProps} />
                              Export to CSV
                            </div>
                            <div className="col-12 p-0 m-0 fixTableHead">
                              <BootstrapTable
                                filter={filterFactory()}
                                {...props.baseProps}
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {isShownOrg && (
          <>
            <div className="col-12 d-flex justify-content-end m-0">
              <Tooltip title="Close">
                <CloseIcon
                  className="closeButton"
                  onClick={handleClickOrganization}
                />
              </Tooltip>
            </div>
            <div className="col-6 d-flex justify-content-left m-0 mb-2">
              <p className="col-3 p-0 m-0">Organization Filter: </p>
              <Form.Control
                fullWidth={true}
                defaultValue={typeOr}
                onChange={changeTypeO}
                className="hlabel w-auto col-7"
                as="select"
              >
                <option value="AllO">All Organizations</option>
                {OrgData.map((e) => (
                  <option key={e.name} value={e.name}>
                    {e.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-12 d-flex justify-content-center m-0">
              <p className="d-flex align-content-center">
                {typeOr == "AllO" ? (
                  <strong className="heading">All Organizations</strong>
                ) : (
                  <strong className="heading">{typeOr}</strong>
                )}
              </p>
            </div>

            <div className="col-12 d-flex justify-content-center mb-2">
              {loadingD && isShownDe ? (
                <CircularProgress
                  disableShrink
                  size="1.2rem"
                  thickness="10"
                  sx={{
                    animationDuration: "550ms",
                    position: "absolute",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
              ) : (
                <></>
              )}
            </div>

            {typeOr !== "AllO" ? (
              <>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mb-3 ">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                      <ToolkitProvider
                        bootstrap4
                        keyField="variable_id"
                        columns={columnO}
                        data={dataO.filter((e) => e.organization == typeOr)}
                        exportCSV={{
                          fileName: ` ${typeOr} Organizationwise New Registered Users.csv`,
                        }}
                      >
                        {(props) => (
                          <div className="row w-100 m-0 p-0">
                            <div className="col-12 p-0 m-0">
                              <MyExportCSV {...props.csvProps} />
                              Export to CSV
                            </div>
                            <div className="col-12 p-0 m-0 fixTableHead">
                              <BootstrapTable
                                filter={filterFactory()}
                                {...props.baseProps}
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-sm-12 col-md-12 col-xs-12 d-flex justify-content-center mt-3 p-0">
                  <CContainer fluid>
                    <CChart
                      type="bar"
                      height="300"
                      data={{
                        labels: dataO
                          .filter((e) => e.organization == typeOr)
                          .map((i) => i.YEAR),
                        datasets: [
                          {
                            label: "Counts: ",
                            fill: true,
                            backgroundColor: "#619080",
                            borderColor: "black",
                            pointBackgroundColor: "rgba(220, 220, 220, 1)",
                            pointBorderColor: "red",
                            data: dataO
                              .filter((e) => e.organization == typeOr)
                              .map((i) => i.Total_Users_Registered_O),
                          },
                        ],
                      }}
                    />
                  </CContainer>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 d-flex justify-content-center mb-3 ">
                  <div className="row">
                    <div className="col-12 d-flex justify-content-center mb-4 p-0 ">
                      <ToolkitProvider
                        bootstrap4
                        keyField="variable_id"
                        columns={columnOrg}
                        data={dataO
                          .sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
                          .reverse()}
                        exportCSV={{
                          fileName:
                            "Organizationwise All New Registered Users.csv",
                        }}
                      >
                        {(props) => (
                          <div className="row w-100 m-0 p-0">
                            <div className="col-12 p-0 m-0">
                              <MyExportCSV {...props.csvProps} />
                              Export to CSV
                            </div>
                            <div className="col-12 p-0 m-0 fixTableHead">
                              <BootstrapTable
                                filter={filterFactory()}
                                {...props.baseProps}
                              />
                            </div>
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </form>
    </FormCard>
  );
};

export default NewRegisteredUser;
