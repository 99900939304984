import { Auth } from "aws-amplify";
import Axios from "axios";
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import CustomModal from "../../../../Components/UI/Modal/CustomModal";
import { environment } from "../../../../environment";
import { dateTimeFormat } from "../../../../Store/utils/common.utils";
import "./ViewUserInfo.css";

class viewUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isServerError: false,
      isTrying: false,
      userInfo: {},
      user_id: "",
      auth_provider: "",
      isConfirmed: false,
      firstName: "",
      lastName: "",
      isLoggedIn: false,
      workspaceDetail: {},
      userData: {
        Username: "",
        Email: "",
        Role: "",
      },
      error: {
        status: false,
        message: null,
        variant: null,
      },
    };
    this.getUserDetails = this.getUserDetails.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.toggleSubscribe = this.toggleSubscribe.bind(this);
    this.resendConfirmation = this.resendConfirmation.bind(this);
    this.confirmUser = this.confirmUser.bind(this);
    this.setClose = this.setClose.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
  }

  componentDidMount() {
    if (localStorage.isAdmin != "true") {
      this.props.history.push("/error");
      return;
    }
    this.getUserDetails();
  }

  getUserDetails() {
    this.setState({ isTrying: true });
    Axios.post(`${environment.viewUserDetails}`, {
      userId: this.props.match.params.id,
    })
      .then((response) => {
        let data = response.data;
        let userInfo = data.user_info;
        this.props.dispatch({
          type: "USER_DATA",
          payload: userInfo,
        });
        let userData = {
          Username: "",
          "First Name": "",
          "Last Name": "",
          Email: "",
          Role: "",
          "Sign in count": "",
          "Current sign in at": "",
          "Last sign in at": "",
          "Allow email": "",
          "Confirmed at": "",
          "Confirmation sent at": "",
        };
        Object.keys(userInfo).map((key, i) => {
          if (key == "username") {
            userData["Username"] = userInfo[key];
          } else if (key == "email") {
            userData["Email"] = userInfo[key];
          } else if (key == "role") {
            userData["Role"] = userInfo[key]
              ? userInfo[key][0].toUpperCase() + userInfo[key].substr(1)
              : "";
          } else if (key == "signCount") {
            userData["Sign in count"] = userInfo[key];
          } else if (key == "currentSignAt") {
            userData["Current sign in at"] =
              userInfo[key] == "None"
                ? userInfo[key]
                : dateTimeFormat(userInfo[key]);
          } else if (key == "lastSignAt") {
            userData["Last sign in at"] =
              userInfo[key] == "None"
                ? userInfo[key]
                : dateTimeFormat(userInfo[key]);
          } else if (key == "frstName") {
            userData["First Name"] = userInfo[key];
          } else if (key == "lstName") {
            userData["Last Name"] = userInfo[key];
          } else if (key == "allowEmail") {
            if (userInfo[key] == 1) {
              userData["Allow email"] = "Yes"; //"true";
            } else {
              userData["Allow email"] = "No"; //"false"
            }
          } else if (key == "confirmedAt") {
            userData["Confirmed at"] =
              userInfo[key] == "None"
                ? userInfo[key]
                : dateTimeFormat(userInfo[key]);
          } else if (key == "confirmationSentAt") {
            userData["Confirmation sent at"] =
              userInfo[key] == "None"
                ? userInfo[key]
                : dateTimeFormat(userInfo[key]);
          } else if (key == "userId") {
            userData["user_id"] = userInfo[key];
          }
        });
        this.setState(
          {
            userInfo: userData,
            isLoading: false,
            isTrying: false,
            isServerError: false,
          },
          () => {}
        );
        this.setState(
          {
            isConfirmed: userInfo["confirmed"],
            auth_provider: userInfo["authProvider"],
            firstName: userInfo["frstName"],
            lastName: userInfo["lstName"],
          },
          () => {}
        );
        if (userInfo["username"] == localStorage.username) {
          this.setState({ isLoggedIn: true });
        } else {
          this.setState({ isLoggedIn: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false, isTrying: false });
        if (err.response) {
          let error = {
            status: true,
            message: err.response.data.message
              ? err.response.data.message
              : "Error Occured",
            variant: "Error",
          };
          this.setState({ error: error });
        } else if (err.request) {
          this.setState({ isServerError: true });
        } else {
          this.setState({ isServerError: true });
        }
      });
  }
  deleteUser(e) {
    e.preventDefault();
    if (!this.state.isLoggedIn) {
      if (window.confirm("Are you sure you wish to delete this item?")) {
        Axios.delete(`${environment.deleteUserURL}`, {
          data: { userId: this.state.userInfo.user_id },
        })
          .then((response) => {
            let error = {
              status: true,
              message: "User Deleted Successfully",
              variant: "Success",
            };
            this.setState({ error: error });
          })
          .catch((err) => {
            if (err.response) {
              let error = {
                status: true,
                message: err.response.data.message
                  ? err.response.data.message
                  : "Error Occured",
                variant: "Error",
              };
              this.setState({ error: error });
            } else if (err.request) {
              let error = {
                status: true,
                message: "Something went wrong !",
                variant: "Error",
              };
              this.setState({ error: error });
            } else {
              let error = {
                status: true,
                message: "Something went wrong !",
                variant: "Error",
              };
              this.setState({ error: error });
            }
          });
      }
    }
  }

  toggleSubscribe(e) {
    e.preventDefault();
    let request_json = {
      isEmail: this.state.userInfo["Allow email"] == "Yes" ? false : true,
      role: this.state.userInfo.Role?.toLowerCase(),
      frstName: this.state.firstName,
      lastName: this.state.lastName,
      userId: this.props.match.params.id,
    };

    if (
      window.confirm(
        `${
          this.state.userInfo["Allow email"] == "Yes"
            ? "Unsubscribe"
            : "Subscribe"
        } selected user?`
      )
    ) {
      Axios.post(`${environment.updateUserDetails}`, request_json)
        .then((response) => {
          let data = response.data;
          this.getUserDetails();
          let text = request_json?.isEmail
            ? "User has been successfully subscribed"
            : "User has been successfully unsubscribed";
          alert(text);
        })
        .catch((err) => {
          if (err.response) {
            let error = {
              status: true,
              message: err.response.data.message
                ? err.response.data.message
                : "Error Occured",
              variant: "Error",
            };
            this.setState({ error: error });
            // client received an error response (5xx, 4xx)
          } else if (err.request) {
            let error = {
              status: true,
              message: "Something went wrong !",
              variant: "Error",
            };
            this.setState({ error: error });
            // client never received a response, or request never left
          } else {
            let error = {
              status: true,
              message: "Something went wrong !",
              variant: "Error",
            };
            this.setState({ error: error });
            // anything else
          }
        });
    }
  }

  confirmUser(e) {
    e.preventDefault();
    if (this.state.isConfirmed) {
    } else {
      let req = {
        username: this.state.userInfo.Username,
      };

      if (window.confirm("Confirm this user?")) {
        Axios.post(`${environment.confirmUser}`, req)
          .then((response) => {
            let error = {
              status: true,
              message: "User confirmed successfully.",
              variant: "Success",
            };
            this.setState({ error: error });
          })
          .catch((err) => {
            let error = {
              status: true,
              message: err.message,
              variant: "Error",
            };
            this.setState({ error: error });
          });
      }
    }
  }

  resendConfirmation(e) {
    e.preventDefault();
    if (window.confirm("Resend confirmation?")) {
      try {
        Auth.resendSignUp(this.state.userInfo.Username)
          .then((response) => {
            let error = {
              status: true,
              message: "Confirmation Send",
              variant: "Success",
            };
            this.setState({ error: error });
          })
          .catch((err) => {
            let error = {
              status: true,
              message: "error resending code",
              variant: "Error",
            };
            this.setState({ error: error });
          });
      } catch (err) {
        let error = {
          status: true,
          message: "error resending code ",
          variant: "Error",
        };
        this.setState({ error: error });
      }
    }
  }

  resendConfirmationCode(e) {
    e.preventDefault();
    this.isValidUser(function (isValid) {
      if (isValid) {
        try {
          Auth.resendSignUp(this.state.userInfo.Username).then((data) => {
            this.setState({ isConfirm: true });
          });
        } catch (err) {
          let error = {
            status: true,
            message: "error resending code",
            variant: "Error",
          };
          this.setState({ error: error });
        }
      } else {
        let error = {
          status: true,
          message: "User Not Found",
          variant: "Error",
        };
        this.setState({ error: error });
      }
    });
  }

  isValidUser(resendConfirmationCodeCallback) {
    Axios({
      method: "GET",
      url: environment.getUserByEmail,
      params: {
        email: this.state.userInfo.Email,
      },
    })
      .then((response) => {
        if (response.data.exists) {
          this.setState({
            username: response.data.Username,
          });
        }
        resendConfirmationCodeCallback.call(this, response.data.exists);
      })
      .catch((err) => {
        if (err.response) {
          // alert(err.response.data.message);
          let error = {
            status: true,
            message: err.response.data.message,
            variant: "Error",
          };
          this.setState({ error: error });
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          let error = {
            status: true,
            message: "Something went wrong !",
            variant: "Error",
          };
          this.setState({ error: error });
          // client never received a response, or request never left
        } else {
          let error = {
            status: true,
            message: "Something went wrong !",
            variant: "Error",
          };
          this.setState({ error: error });
          // anything else
        }
      });
  }

  setIsOpen() {
    this.setState(({ error }) => ({
      error: { ...error, status: !this.state.error.status },
    }));
  }
  setClose() {
    this.props.history.replace("/users");
  }
  render() {
    return this.state.isLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#F3901D",
        }}
      >
        <Spinner animation="grow" />
      </div>
    ) : this.state.isServerError ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          color: "#F3901D",
        }}
      >
        <label>Something went wrong. Try Again!!</label>
        {this.state.isTrying ? (
          <button type="button" className="gss-btn btn-orange">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            &nbsp;Fetching...
          </button>
        ) : (
          <button
            type="button"
            className="gss-btn btn-orange"
            onClick={this.getUserDetails}
          >
            Try Again
          </button>
        )}
      </div>
    ) : (
      <div id="maincontent">
        <CustomModal
          title={this.state.error.variant}
          titleText={this.state.error.variant}
          isOpen={this.state.error.status}
          setIsOpen={this.setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
          inputLabel={this.state.error.message}
          setClose={this.setClose}
        ></CustomModal>
        <div className="admin-show view-user col-8" id="mainbar">
          <div className="right or-toolbar mt-2">
            <span className="or-toolbar-item" title="Edit">
              <NavLink to={"/users/new/" + this.state.userInfo.user_id} exact>
                {" "}
                <i className="fa fa-edit fa-lg"></i>
                <span className="icon-label">Edit</span>
              </NavLink>
            </span>
            <span
              className="or-toolbar-item"
              title={
                this.state.isLoggedIn ? "User cannot delete self" : "Delete"
              }
            >
              <a
                onClick={this.deleteUser}
                href=""
                style={{
                  cursor: this.state.isLoggedIn ? "not-allowed" : "pointer",
                }}
              >
                <i className="fa fa-close fa-lg"></i>
                <span className="icon-label">Delete</span>
              </a>
            </span>
            <span className="or-toolbar-item">
              <a
                title={
                  this.state.userInfo["Allow email"] == "Yes"
                    ? "Unsubscribe"
                    : "Subscribe"
                }
                onClick={this.toggleSubscribe}
                href=""
              >
                <i className="fa fa-envelope fa-lg"></i>
              </a>
            </span>

            {this.state.auth_provider == "facebook" ||
            this.state.auth_provider == "google" ||
            this.state.isConfirmed ? (
              ""
            ) : (
              <>
              <span className="or-toolbar-item">
                <a
                  title="Resend Confirmation"
                  onClick={this.resendConfirmation}
                  href=""
                >
                  <i className="fa fa-send fa-lg"></i>
                </a>
              </span>
              <span className="or-toolbar-item">
                <a
                  onClick={this.confirmUser}
                  style={{
                    cursor: this.state.isConfirmed
                      ? "not-allowed"
                      : "pointer",
                  }}
                  title={
                    this.state.isConfirmed ? "Already confirmed" : "Confirm"
                  }
                  href=""
                >
                  <i className="fa fa-check-circle fa-lg"></i>
                  <span className="icon-label">Mark as confirm</span>
                </a>
              </span>
            </>
            )}
          </div>
          <div className="stitle">User {this.state.userInfo.Username}</div>
        </div>

        <div className="admin-show view-user" id="maincenter">
          {Object.keys(this.state.userInfo).map((key, i) =>
            key != "user_id" ? (
              <div className="input" key={i}>
                <label title="">{key}</label>
                <span className="form-string">{this.state.userInfo[key]}</span>
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.reducer,
  };
}

export default connect(mapStateToProps)(viewUserInfo);
