/* src/App.js */
import React, { useEffect, useState } from "react";
import Amplify from "aws-amplify";

import awsExports from "./aws-exports";

import HomePage from "./Containers/Homepage/Homepage";
import "./App.css";
import axios from "axios";
import Loader from "./Components/UI/Loader/Loader";
import { setMinMax } from "./Store/actions";
import { useDispatch } from "react-redux";
Amplify.configure(awsExports);

function App() {

  const [generalSettings, setGeneralSettings] = useState({ timeout: '' });
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getTimeOut();
    fetchYearsJson();
  }, []);
  async function getTimeOut() {
    await axios.get(`${process.env.PUBLIC_URL}/config/generalSettings.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "",
      }
    })
      .then((res) => {
        if (localStorage.idleTimeout == undefined || parseInt(localStorage.idleTimeout) != res.data.sessionTimeout) {
          setGeneralSettings({ ...generalSettings, timeout: res.data.sessionTimeout });
          localStorage.idleTimeout = res.data.sessionTimeout;
        }

      })
  }


  const fetchYearsJson = () => {
    axios
      .get(process.env.PUBLIC_URL + "/config/year_range.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "",
        },
      })
      .then((response) => {
        let min_max_year = [response?.data?.min_year, response?.data?.max_year];
        dispatch(setMinMax({ min_max_year }));
      });
  };

  if (generalSettings.timeout === '' && localStorage.idleTimeout == undefined) {
    return <React.Fragment></React.Fragment>;
  } else {
    return <React.Fragment><HomePage />
      {showLoader ? <Loader /> : ''}
    </React.Fragment>
  }
};
export default App;
