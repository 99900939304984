import React, { useState } from "react";
import EditTagModel from "../../../Components/UI/EditTagModel/EditTagModel";
import ShareTagModel from "../../../Components/UI/ShareTagModel/ShareTagModel";
import AddTagModel from "../../../Components/UI/AddTagModel/AddTagModel";
import CreateTagModel from "../../../Components/UI/CreateTagModel/CreateTagModel";
import CategoryAccordian from "../../../Components/UI/CategoryAccordian/CategoryAccordian";
import { useDispatch } from "react-redux";
import { deleteTag } from "../../../Store/actions/variable";
import "./TagCategories.css";

const TagCategories = ({ searchData, tagCategories, selectAll, ...props }) => {
  const [modalShow, setModalShow] = useState(false);
  const [shareTagModalShow, setShareTagModalShow] = useState(false);
  const [addTagModal, setAddTagModal] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);
  const [category, setCategory] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedObj, setSelectedObj] = useState([]);
  const [isMove, setIsMove] = useState([]);
  const [actionType, setActionType] = useState("addToTag");

  const onEditTag = (id, name) => {
    setCategory({ id, name });
    setModalShow(true);
  };
  const onShareTagModalShowTag = (id, name) => {
    setCategory({ id, name });
    setShareTagModalShow(true);
  };

  const dispatch = useDispatch();

  const onDeleteTag = (tag_id) => {
    dispatch(deleteTag({ tag_id: +tag_id })).then(() => {
      props.getVariablelist();
    });
  };

  const refreshList = () => {
    props.getVariablelist();
  };

  const onCopyToTag = (tagId) => {
    let selectTag = {};
    tagCategories.forEach(([key, value]) => {
      if (key === tagId) {
        selectTag = value;
      }
    });
    const newSelecteds = selectTag.variables.map((n) => n.vID);
    const selectedObj = selectTag.variables.map((n) => n);
    setSelected(newSelecteds);
    setSelectedObj(selectedObj);
    setCreateTagModal(true);
  };

  return (
    <>
      {tagCategories.map(([key, value]) => {
        return (
          <CategoryAccordian
            key={key}
            rows={value.variables}
            onEditTag={onEditTag}
            onShareTagModalShowTag={onShareTagModalShowTag}
            onDeleteTag={onDeleteTag}
            title={value.tagName}
            tagId={key}
            selectAll={selectAll}
            isThisTagShared={value.isThisTagShared}
            isSharedTag={value.isSharedTag}
            createdBy={value.createdBy}
            onCopyToTag={onCopyToTag}
          />
        );
      })}

      {shareTagModalShow ? (
        <ShareTagModel
          modalShow={shareTagModalShow}
          category={category}
          setModalShow={setShareTagModalShow}
          refreshList={refreshList}
        />
      ) : (
        ""
      )}

      {modalShow ? (
        <EditTagModel
          modalShow={modalShow}
          category={category}
          selected={selected}
          selectedObj={selectedObj}
          setSelected={setSelected}
          setSelectedObj={setSelectedObj}
          setModalShow={setModalShow}
          setAddTagModal={setAddTagModal}
          setIsMove={setIsMove}
          setActionType={setActionType}
          getVariablelist={props.getVariablelist}
        />
      ) : (
        ""
      )}

      {addTagModal ? (
        <AddTagModel
          rows={selectedObj}
          selected={selected}
          setSelected={setSelected}
          addTagModal={addTagModal}
          setAddTagModal={setAddTagModal}
          setSelectedObj={setSelectedObj}
          isMove={isMove}
          selectedCategory={category}
          actionType={actionType}
          getVariablelist={props.getVariablelist}
        />
      ) : (
        ""
      )}

      {createTagModal && (
        <CreateTagModel
          isCopy={true}
          rows={selectedObj}
          selected={selected}
          modalShow={createTagModal}
          setAddTagModal={setCreateTagModal}
          setSelected={setSelected}
          setSelectedObj={setSelectedObj}
          getVariablelist={props.getVariablelist}
        />
      )}
    </>
  );
};

TagCategories.defaultProps = {
  selectAll: false,
};

export default TagCategories;
