import React from "react";

function Workspaces() {
  return (
    <div>
      <div className="or-grid grid-controls" id="maincontent">
        <div id="mainbar">
          <div id="index_help_panel"></div>
          <div className="item-count">
            <span id="nrec_workspaces"></span>
          </div>
          <div className="stitle">
            Workspaces
            <small>
              User: <a href="/users/9">Felicia LeClere</a>
            </small>
          </div>
          <div className="or-toolbar or-index">
            <div className="or-toolbar-item keyword-item">
              <div className="input-group">
                <input
                  type="text"
                  name="term"
                  id="term"
                  className="bar-search form-control"
                  placeholder="Keyword"
                  data-ref="workspaces"
                  data-gridsearch="workspaces"
                />
                <div className="input-group-addon">
                  <i
                    title="Clear the search"
                    className="filter-clear fa fa-times-circle fa-lg"
                  ></i>
                </div>
              </div>
            </div>
            <span className="or-toolbar-item">
              <a
                title="Settings and Export"
                data-or="menu"
                data-ormenu='[{"icon":"columns","title":"Select Columns","data":{"cmd":"dialog","loadUrl":"/workspaces/preferred_columns?col_type=grid\u0026grid_name=workspaces","width":800,"ajaxSubmit":true,"title":"Select Columns"}},{"icon":"repeat","title":"Reset Columns","data":{"cmd":"post","url":"/workspaces/preferred_columns?col_type=grid\u0026grid_name=workspaces\u0026reset=true"}},{"icon":"filter","title":"Add Filters","data":{"cmd":"dialog","loadUrl":"/workspaces/preferred_columns?col_type=filter\u0026grid_name=workspaces","width":800,"ajaxSubmit":true,"title":"Select Columns for Filters"}},{"icon":"file-excel-o","title":"Export Excel","data":{"cmd":"nav","url":"/users/9/workspaces.xlsx"}},{"icon":"file-pdf-o","title":"Export PDF","data":{"cmd":"nav","url":"/users/9/workspaces.pdf"}},{"icon":"print","title":"Print","data":{"cmd":"nav","url":"/users/9/workspaces?print=1"}}]'
                className=""
                href="#"
              >
                <i className="fa fa-menu6 fa-2x"></i>
                <span className="icon-label"></span>
              </a>
            </span>
          </div>
        </div>
        <div id="maincenter">
          <table
            className="or-grid cell-border compact"
            data-col-model='[{"name":"name","data":"name","title":"NAME","width":150,"type":"html","orderable":true,"orderSequence":["desc","asc",""],"className":null},{"name":"label","data":"label","title":"LABEL","width":350,"type":"string","orderable":true,"orderSequence":["desc","asc",""],"className":null},{"name":"description","data":"description","title":"DESCRIPTION","width":250,"type":"string","orderable":true,"orderSequence":["desc","asc",""],"className":null}]'
            data-grid-col-filter="null"
            data-grid-data='[{"name":"\u003cspan className=\"name_link\"\u003e\u003ca href=\"/users/9/workspaces/9\"\u003eDefault project\u003c/a\u003e\u003c/span\u003e","label":"","description":null,"DT_RowId":"9"},{"name":"\u003cspan className=\"name_link\"\u003e\u003ca href=\"/users/9/workspaces/28660\"\u003enew project \u003c/a\u003e\u003c/span\u003e","label":"","description":"project for review ","DT_RowId":"28660"}]'
            data-grid-name="workspaces"
            data-grid-sort="[]"
            data-grid-type="local"
            id="workspaces"
          ></table>
        </div>
      </div>
    </div>
  );
}

export default Workspaces;
