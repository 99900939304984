import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import arrow from "../../../Images/back_arrow.jpg";
import { BrowserRouter, Link, NavLink, Route, withRouter, useLocation, useParams } from "react-router-dom";
import Trends from "../Trends";
import TrendsCard from "../TrendsCard";
import ShareButton from "../TrendsShare/ShareButton";
import { environment } from "../../../environment";
import "./TrendsData.css";
import TrendsDetails from "../TrendsDetails/TrendsDetails";
import VariableDetails from "../../VariableManagement/VariableDetails/VariableDetails";
import { setTrendName } from "../../../Store/actions";
import { useDispatch } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography> {children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
}));

const TrendsData = (props) => {
  const theme = useTheme();
  const classes = useStyles();  
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [selectedCategory, setCategory] = useState("");
  const [selectedSubCategory, setSubCategory] = useState("");
  const [tableauUrl, setTableauUrl] = useState();
  const [allCategory, setAllCategories] = useState();
  const [variableToggle, setVariableToggle] = useState(true);
  const [variableId, setVariableId] = useState();
  const location = useLocation();
  let { id } = useParams();
  const [flag, setFlag] = useState(false);
  const [questionResponse, setQuestionResponse] = useState("");
  const [breakdown, setBreakdown] = useState("");
  const [varName, setVarName] = useState("");

  const getTrends = () => {
    axios
      .get(`${environment.getTrends}`)
      .then((resp) => {
        let data = resp.data;
        setAllCategories(data);        
        const index = data.findIndex((element) => element.Category.trim() == selectedCategory);
        if(index > -1) {
          setTableauUrl(data[index]["TableauUrl"]);
          setValue(index);
        }        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props?.location?.state?.category) {
      setCategory(props.location.state.category.trim());
      setSubCategory(props.location.state.subCategory.trim());
      setTableauUrl(props.location.state.tableauUrl);
      setAllCategories(JSON.parse(props.location.state.parentData));
    } else {
      setCategory(id.trim());
      setFlag(true);
    }
  }, []);

  useEffect(()=>{
    if(flag){
      getTrends();
      const params = new URLSearchParams(location.search);
      let question_response = params.get('Measure Category');
      let breakdown = params.get('Breakdown Label');
      let var_name = params.get('measure');
      setQuestionResponse(question_response);
      setBreakdown(breakdown);
      setVarName(var_name? var_name : "")
      setFlag(false);
    }
  },[flag])

  useEffect(() => {
    if (selectedCategory == "Gender & Marriage") {
      setValue(0);
    } else if (selectedCategory == "Current Affairs") {
      setValue(1);
    } else if (selectedCategory == "Civil Liberties") {
      setValue(2);
    } else if (selectedCategory == "Politics") {
      setValue(3);
    } else if (selectedCategory == "Quality of Working Life") {
      setValue(4);
    } else if (selectedCategory == "Religion & Spirituality") {
      setValue(5);
    }
    dispatch(setTrendName(selectedCategory))
    if(!variableToggle) {
      setVariableId();
    }
  }, [selectedCategory, variableToggle]);

  const setTabIndex = (index) => {
    setCategory(allCategory[index].Category.trim());
    setTableauUrl(allCategory[index].TableauUrl);    
  };
  const handleChange = (event, index) => {
    setTabIndex(index);
    setSubCategory("");
    setValue(index);
  };

  const handleChangeIndex = (index) => {
    setTabIndex(index);
    setValue(index);
  };

  const redirectTo = (varId) => {
    setVariableId(varId)
    setVariableToggle(false);    
  }

  return (
    <>
    {variableToggle ? "": (
      <VariableDetails isAnalysisOrExtract={false} variableId={variableId} setVariableToggel={setVariableToggle} moduleName="Trends" />
    )}

      <Container className="mt-5 my-gss-page" id="trends-data" style={{ display: (variableToggle == false) ? 'none' : "block" }}>
          <Row>
            <Col>          
              <div className="gss-menu-bar back-link-bar pl-2">
                <Link className="no-decoration" to='/trends'>
                  <img src={arrow} alt=""/>
                  Back {/* To Trends */}
                </Link>
              </div>           
            </Col>
          </Row>
          <Row className="mt-3">
            <Col as="h3" className="gss-bold-title">Trends</Col>
          </Row>

          <Row className="gss-trends-details">
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  className="mygss-tabs trends-tabs"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#4f977d",
                    },
                  }}
                  aria-label="full width tabs example"
                  id="trends-tabs"
                >
                  <Tab label="Gender & Marriage" {...a11yProps(0)} />
                  <Tab label="Current Affairs" {...a11yProps(1)} />
                  <Tab label="Civil Liberties" {...a11yProps(2)} />
                  <Tab label="Politics" {...a11yProps(3)} />
                  <Tab label="Quality of Working Life" {...a11yProps(4)} className="rm-tab-padding" />
                  <Tab label="Religion & Spirituality" {...a11yProps(5)} className="rm-tab-padding" />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel
                  component={"span"}
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  {selectedCategory && (
                    <TrendsDetails
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      tableauUrl={tableauUrl}
                      redirectTo={redirectTo}
                      questionResponse={questionResponse}
                      breakdown={breakdown}
                      varName={varName}
                    />
                  )}
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  {selectedCategory && (
                    <TrendsDetails
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      tableauUrl={tableauUrl}
                      redirectTo={redirectTo}
                      questionResponse={questionResponse}
                      breakdown={breakdown}
                      varName={varName}
                    />
                  )}
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  {selectedCategory && (
                    <TrendsDetails
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      tableauUrl={tableauUrl}
                      redirectTo={redirectTo}
                      questionResponse={questionResponse}
                      breakdown={breakdown}
                      varName={varName}
                    />
                  )}
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={3}
                  dir={theme.direction}
                >
                  {selectedCategory && (
                    <TrendsDetails
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      tableauUrl={tableauUrl}
                      redirectTo={redirectTo}
                      questionResponse={questionResponse}
                      breakdown={breakdown}
                      varName={varName}
                    />
                  )}
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={4}
                  dir={theme.direction}
                >
                  {selectedCategory && (
                    <TrendsDetails
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      tableauUrl={tableauUrl}
                      redirectTo={redirectTo}
                      questionResponse={questionResponse}
                      breakdown={breakdown}
                      varName={varName}
                    />
                  )}
                </TabPanel>
                <TabPanel
                  component={"span"}
                  value={value}
                  index={5}
                  dir={theme.direction}
                >
                  {selectedCategory && (
                    <TrendsDetails
                      category={selectedCategory}
                      subCategory={selectedSubCategory}
                      tableauUrl={tableauUrl}
                      redirectTo={redirectTo}
                      questionResponse={questionResponse}
                      breakdown={breakdown}
                      varName={varName}
                    />
                  )}
                </TabPanel>
              </SwipeableViews>
            </div>
          </Row>
    </Container> 
    </>
    /* )   */  
  );
};
export default TrendsData;
