import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./TabulationPopover.css";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import { environment } from "../../../environment";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import RefreshPopoverContext from "../../../Store/ContextApi/RefreshPopoverContext";
import { Link } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";
const BorderLinearProgress = withStyles((theme) => ({
  root: {},
  colorPrimary: {
    backgroundColor: "#fffff",
  },
  bar: {
    borderColor: "#50917E",
    borderRadius: 5,
    backgroundColor: "#50917E",
  },
}))(LinearProgress);

const Accordion = withStyles({
  root: {
    border: "1px solid #50917E",
    color: "#ffffff",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#50917E",
    color: "#ffffff",
    borderBottom: "1px solid #50917E",
    marginBottom: -1,
    fontSize: "8px",
    minHeight: "15px",
    maxHeight: "25px",
  },

  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    paddingTop: "0px",
    backgroundColor: "white",
  },
}))(MuiAccordionDetails);

export default function TabulationPopover() {
  const [tabulations, setTabulations] = useState({ queue: [], completed: [] });
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const { analysisReloaded } = useContext(RefreshPopoverContext);

  const history = useHistory();

  useEffect(() => {
    if (expanded) getAllData();
  }, [expanded]);

  useEffect(() => {
    getAllData();
  }, [analysisReloaded]);

  const getAllData = () => {
    axios.get(environment.getUserTabulations).then(
      (response) => {
        if (response.data) {
          setTabulations(response.data.final_response);
          setError(null);
        }
      },
      (error) => {
        setTabulations([]);
        setError(error);
      }
    );
  };

  const redirectToAnalysisSummary = (e, data) => {
    data.analysisId = data.function_ID;
    data.creatorName = data.creator_name;
    history.push(
      `/analysis_summary/${data.function_ID}?creatorId=${data.creatorId}&back=tabulation`
    );
  };

  const removeAnalysis = (reqPayload) => {
    let analysis_id_list = { ids: reqPayload };
    axios
      .post(environment.removeAnalysis, analysis_id_list)
      .then((response) => {
        if (response.status) {
          getAllData();
        }
      });
  };

  const retryAnalysis = (id) => {
    let analysis_id_obj = { retry_flag: true, id };
    axios.post(environment.createAnalysis, analysis_id_obj).then((response) => {
      if (response.status) {
        getAllData();
      }
    });
  };

  const onCreateClick = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
    history.push(`/createAnalysis`);
  };

  const handleClickAway = () => {
    setExpanded(false)
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
    <Accordion
      disableSpacing="true"
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        className="text-white"
      >
        <div className="h6 acc-title">
          {expanded ? <RemoveIcon /> : <AddIcon />}
          &nbsp;&nbsp;<small>Tabulations</small>
        </div>{" "}
      </AccordionSummary>
      <AccordionDetails>
        <div className="tab-popover">
          <div className="tab-section-1">
            {tabulations.queue &&
              tabulations.queue.slice(0, 1).map((queue, index) => (
                <div className="tab-item-grp" key={queue.function_ID}>
                  <>
                    {queue.status.toLocaleLowerCase() === "failed" ? (
                      <>
                        <span className="d-inline-block">
                          <span className="tab-item failed">
                            {" "}
                            {queue.Function_name}
                          </span>
                          <br />
                          <span className="tab-popover-err">
                            {" "}
                            {queue.error}
                          </span>
                        </span>
                        <span className="float-right tab-queue-action">
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={() => retryAnalysis(queue.function_ID)}
                          >
                            Retry
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={() => removeAnalysis(queue.function_ID)}
                          >
                            Remove
                          </a>
                        </span>
                      </>
                    ) : queue.status.toLocaleLowerCase() === "submitted" ||
                      queue.status.toLocaleLowerCase() === "started" ? (
                      <>
                        <span className="tab-title">
                          “{queue.Function_name}” Processing
                        </span>
                        <BorderLinearProgress
                          className="tab-progress-bar"
                          variant="determinate"
                          value={50}
                        />
                        <span className="tab-status pull-right"> Status</span>
                      </>
                    ) : (
                      <span className="tab-item"> {queue.Function_name}</span>
                    )}
                  </>
                </div>
              ))}

            <br />
            <span
              className="create-new-tabulat cursor-pointer-hand"
              onClick={(e) => onCreateClick(e)}
            >
              + Create New Tabulation
            </span>
          </div>

          <hr style={{ backgroundColor: "#50917E" }} />
          <div className="tab-section-2">
            <div>
              <span className="completed-tabulation">
                {" "}
                Completed Tabulations ({tabulations?.completed?.length})
              </span>
              <NavLink
                to="/MyGSS?page=tabulation"
                className="tab-view-all pull-right"
              >
                View All{" "}
              </NavLink>
            </div>
            <div>
              {tabulations.completed &&
                tabulations.completed.slice(0, 5).map((completed, index) => (
                  <div className="tab-item-grp" key={completed.function_ID}>
                    <Link
                      className="no-decoration"
                      to={`/analysis_summary/${completed.function_ID}?creatorId=${completed.creatorId}&back=tabulation`}
                    >
                      <span
                        className="tab-item"
                        onClick={(e) => redirectToAnalysisSummary(e, completed)}
                      >
                        {" "}
                        {completed.Function_name}
                      </span>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
    </ClickAwayListener>
  );
}
