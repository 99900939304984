import React, { useState } from "react";
import "./CartList.css";
import { NavLink, useHistory } from "react-router-dom";
import { Chip, FormControl, NativeSelect, Tooltip } from "@material-ui/core";
import { useEffect } from "react";
import axios from "axios";
import { environment } from "../../environment";
// import TagCategories from "./TagCategories/TagCategories";
// import { useDrag } from "react-dnd";
import CartItemDnd from "../Dnd/CartItemDnd";
// import { GetTagCategories } from "../../Store/utils/variable.utils";
// import Loader from "../../Components/UI/Loader/Loader";
import { Dropdown } from "react-bootstrap";
const CartList = ({ toggelView, ...props }) => {
  useEffect(() => {
    if (toggelView) getCartListData();
  }, [toggelView]);

  const [searchText, setSearchText] = useState("");
  const [variablesList, setVariablesList] = useState([]);
  const [tagCategoriesList, setTagCategories] = useState([]);
  const history = useHistory();
  const [viewBy, setViewBy] = useState("0");

  useEffect(() => {
    if (viewBy === "0") {
      let vl = [...variablesList];
      vl.map((item) => {
        item.isChecked = false;
      });

      setVariablesList(vl);
    }
  }, [viewBy]);

  const handleChange = (prop) => (event) => {
    setViewBy(event.target.value);
  };

  const getCartListData = () => {
    if (props.isCloseLoader) {
      props.closeLoader(false);
    }
    let reqBody = {
      workspace_id: localStorage.defaultProjectNo
        ? parseInt(localStorage.defaultProjectNo)
        : "",
      year_info: 0,
      cartType: props.moduleName == "Extract" ? "extract" : "analysis",
      isTagInfoRequired: 0,
    };
    axios
      .post(`${environment.getCartItem}`, reqBody)
      .then((resp) => {
        // console.log(resp.data.response);
        if (resp.data.response.hasOwnProperty("cart_items")) {
          let cartItemsArr = [...resp.data.response.cart_items];
          if (props.moduleName != "Extract") {
            let index = cartItemsArr.findIndex(
              (item) => item.orignlName == "id"
            );
            if (index > -1) {
              cartItemsArr.splice(index, 1);
            }
          }
          cartItemsArr.map((obj) => {
            obj.isChecked = false;
          });
          setVariablesList(JSON.parse(JSON.stringify(cartItemsArr)));
          if (props.tabulationType === "") {
            props.setYearInColumn(resp.data.response.cart_items.slice(0, 1));
          }
          // setTagCategories(GetTagCategories(JSON.parse(JSON.stringify(cartItemsArr))));
        }
      })
      .catch((error) => {
        //props.history.push("/error");
        console.log(error);
      })
      .finally(() => {
        props.closeLoader(true);
      });
  };

  const clickSearchMore = () => {
    props.setToggelView(false);
  };

  const cartItemAction = (event, variableId) => {
    let tempList = [...variablesList];
    let index = tempList.findIndex((item) => item.vID == variableId);
    tempList[index].isChecked = event.target.checked;
    setVariablesList(tempList);
  };

  const toggleTags = (id) => {
    let filterData = variablesList.map((d) => {
      if (d.vID === id) {
        d.expanded = !d.expanded;
      }
      return d;
    });
    setVariablesList(filterData);
  };

  return (
    <div>
      <div className="row w-100 var-indicator mb-1">
        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 ">
          <span className="continuous"></span>
          <span>Continuous</span>
        </div>
        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12">
          <span className="discrete"></span>
          <span>Discrete</span>
        </div>
      </div>
      <div className="cart var-drag">
        <div className="row m-0 p-0 header p-2">
          {/* <div className="fa fa-shopping-cart"></div> */}
          <div className="heading-16 col-lg-5 col-lg-5 col-md-5 col-sm-12 p-0">
            <span>{props.moduleName} </span>({variablesList?.length})
          </div>
          {props.showAddAll == "true" && props.numLimit > 0 ? (
            <div className=" col-lg-7 col-lg-7 col-md-7 col-sm-12 p-0 d-flex justify-content-end">
              <p
                style={{ cursor: "pointer", fontSize: "16px" }}
                id="dropdown-basic-custom"
                className="link add-all font-weight-bold "
                onClick={() => props.addAny(variablesList)}
              >
                Add first {props.numLimit} variables
              </p>
            </div>
          ) : (
            <div className=" col-lg-7 col-lg-7 col-md-7 col-sm-12 p-0 d-flex justify-content-end">
              <p
                style={{ cursor: "pointer", fontSize: "16px" }}
                id="dropdown-basic"
                className="link add-all font-weight-bold "
                onClick={() => props.addAll(variablesList)}
              >
                Add all variables
              </p>
            </div>
          )}
        </div>
        <div className="search p-2">
          <input
            type="text"
            name="search_cart"
            id="search_cart"
            className="search_cart"
            value={searchText}
            placeholder={
              viewBy === "0"
                ? "Search through variables"
                : "Search through tags"
            }
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
          <span className="fa fa-search"></span>

          <Tooltip arrow title={environment.cartListQues}>
            <span>
              <i className="fa fa-question-circle pl-1"></i>
            </span>
          </Tooltip>
        </div>
        <div>
          {/* {props.moduleName != 'Extract' && (
            <div className="row mb-1 view-by px-2">
              <div className="col-4">
                <div className="pt-1 heading-14">View by:</div>
              </div>
              <div className="col-8 p-0 gss-select with-border">
                <FormControl>
                  <NativeSelect
                    value={viewBy}
                    onChange={handleChange("viewBy")}
                    name="viewBy"
                  >
                    <option value="0">Variable List</option>
                    <option value="1">Tag Categories</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </div>
          )} */}
          {viewBy === "0" && (
            <div className="contents">
              <ul className="drag-item">
                {variablesList
                  ?.filter(
                    (obj) =>
                      searchText == "" ||
                      (obj &&
                        obj.label
                          ?.toLowerCase()
                          ?.indexOf(searchText.toLowerCase()) > -1) ||
                      obj.orignlName
                        ?.toLowerCase()
                        ?.indexOf(searchText.toLowerCase()) > -1
                  )
                  .map((data, index) => (
                    <React.Fragment>
                      <CartItemDnd
                        data={data}
                        variablesList={variablesList}
                        cartItemAction={cartItemAction}
                        index={index}
                        toggleTags={toggleTags}
                      />
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          )}

          {/* {
            viewBy === '1' && (
              <TagCategories tagCategoriesList={tagCategoriesList} searchText={searchText}></TagCategories>
            )
          } */}
        </div>
      </div>
      <div className="cart-footer">
        <a onClick={clickSearchMore}>+ Search for More Variables</a>
      </div>
    </div>
  );
};

CartList.defaultProps = {
  isAnalysisOrExtract: false,
  setToggelView: () => "",
  setVariableId: () => "",
  setVariableToggel: () => "",
};

export default CartList;
