import React, { Component, useEffect } from "react";
import "./ErrorPage.css";
import axios from "axios";
import { Auth } from "aws-amplify";

export default function ErrorPage(props) {

  useEffect(() => {
    // setTimeout(() => {
      try {
        Auth.signOut({ global: true })
          .then((data) => {
            localStorage.clear();
            props.history.push("/sign_in");
           // window.location.reload();
          });
      } catch (error) {
        localStorage.clear();
        props.history.push("/sign_in");
      }
    // }, 1000);
  }, [])

  return (
    <div className="form-parent">
      <h2>
        Unauthorized
          </h2>
    </div>
  );

}
