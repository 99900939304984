import { FormControlLabel } from "@material-ui/core";
import Axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { environment } from "../../../environment";
import FormCard from "../../../Components/UI/FormCard/FormCard";
import TextField from "@material-ui/core/TextField";

import "./AdminCreateUser.css";
import {
  checkIsRequired,
  checkIsAllLetter,
  checkIsValidEmail,
  checkSpace,
  checkPasswordLength,
  checkPasswordCombination,
} from "../../../Shared/helpers";
import CustomModal from "../../../Components/UI/Modal/CustomModal";
import { GreenCheckbox } from "../../../Store/utils/common.utils";

function AdminCreateUser(props) {
  let { id } = useParams();
  const [holdUserRole, setHoldUserRole] = useState();
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    role: "user",
    checkedValue: false,
    send_confirmation: false,
  });
  const [errors, setErrors] = useState({
    firstname: null,
    username: null,
    email: null,
  });

  const [pwdError, setPwdError] = useState({
    password: null,
    confirmPassword: null,
  });
  const firstRender = useRef(true);
  const [disabled, setDisabled] = useState(true);
  const reducerDetails = useSelector((state) => state.reducer);
  const [isServerError, setIsServerError] = useState({
    status: false,
    message: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (id) {
      if (reducerDetails.data) {
        setUserData({
          ...userData,
          role: reducerDetails.data.role,
          username: reducerDetails.data.username.trim(),
          firstname: reducerDetails.data.frstName,
          lastname: reducerDetails.data.lstName,
          email: reducerDetails.data.email.trim(),
          checkedValue: reducerDetails.data.role == "admin" ? true : false,
        });
        setHoldUserRole(reducerDetails.data.role);
        setErrors({
          ...errors,
          username: reducerDetails.data.username != "" ? "" : null,
          email: reducerDetails.data.email != "" ? "" : null,
          firstname: reducerDetails.data.frstName != "" ? "" : null,
        });

        if (reducerDetails.data.username == localStorage.username) {
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      }
    }
  }, []);

  const checkDisabled = () => {
    for (var key in errors) if (!(errors[key] == "")) return false;

    return true;
  };

  useEffect(() => {
    if (firstRender.current && !id) {
      firstRender.current = false;
      return;
    }

    setDisabled(!checkDisabled());
  }, [errors.username, errors.email, errors.firstname]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
    let validationError = {};
    if (name != "lastname" && name != "email") {
      validationError = await checkIsRequired(name, value);
    }
    switch (name) {
      case "firstname":
        if (!validationError.errorStatus) {
          validationError = await checkIsAllLetter(name, value);
        }
        break;
      case "username":
        if (!validationError.errorStatus) {
          validationError = await checkSpace(name, value);
        }
        break;
      case "email":
        validationError = await checkIsValidEmail(name, value);
        break;
      case "passwordConfirmation":
        {
          let passwordErr = { errorMessage: "", errorStatus: false };
          if (
            userData.password !== "" &&
            userData.password.trim() !== "" &&
            userData.password !== undefined
          ) {
            if (value === userData.password ) {
              setPwdError({
                ...pwdError,
                confirmPassword: passwordErr.errorMessage,
                password: passwordErr.errorMessage,
              });
            } else {
              setPwdError({
                ...pwdError,
                confirmPassword: "Passwords do not match, Please try again",
                password: passwordErr.errorMessage,
              });
            }
          } else {
            setPwdError({
              ...pwdError,
              confirmPassword: passwordErr.errorMessage,
              password: passwordErr.errorMessage,
            });
          }
        }
        break;
        case "password":
          let passwordErr = { errorMessage: "", errorStatus: false };
          setPwdError({
            ...pwdError,
            confirmPassword: passwordErr.errorMessage,
            password: passwordErr.errorMessage,
          });
          break;
        default:
          break;
    }

    if (name != "lastname") {
      setErrors({ ...errors, [name]: validationError.errorMessage });
    }
  };

  useEffect(() => {
    if (localStorage.isAdmin != "true") {
      props.history.push("/error");
      return;
    }
  }, []);

  const handleCheck = (e) => {
    setUserData({
      ...userData,
      checkedValue: e.target.checked,
      role: e.target.checked ? "admin" : "user",
    });
  };

  const onSubmit = () => {
    setIsSaving(true);
    setDisabled(true);
    if (id) {
      let request_json = {
        role: holdUserRole == userData.role ? undefined : userData.role,
        frstName: userData.firstname,
        lastName: userData.lastname,
        userId: id,
        isEmail: reducerDetails.data.allowEmail,
        password: userData.password,
        passwordConfirmation:userData.passwordConfirmation ,
      };
      Axios.post(`${environment.updateUserDetails}`, request_json)
        .then((response) => {
          setIsSaving(false);
          setDisabled(true);
          setErrors({ username: null, email: null, firstname: null });
          setUserData({
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            role: "user",
            checkedValue: false,
            send_confirmation: false,
          });
        
          setErrors({ firstname: null, username: null, email: null });
          setPwdError({ password: null, confirmPassword: null });
          history.push("/users/" + id);
          alert('User Details Submitted Successfully !')
        })
        .catch((error) => {
          setIsSaving(false);
          setDisabled(false);
          let msg;
          if (error.response) {
            msg = error.response.data;
          } else if (error.request) {
            msg = error.request;
          } else {
            msg = error.message;
          }
          setIsServerError({
            ...isServerError,
            status: true,
            message: msg.message
              ? msg.message
              : "Something went wrong. Try Again!!",
          });
        });
    } else {
      let req = {
        username: userData.username.trim(),
        email_verified: false,
        email: userData.email.trim(),
        password: userData.password,
        role: userData.role,
        user_attrs: {
          name: userData.firstname,
          family_name: userData.lastname,
        },
      };

      Axios.post(`${environment.createUser}`, req)
        .then((response) => {
          setIsSaving(false);
          setDisabled(true);
          setErrors({ username: null, email: null, firstname: null });
          setUserData({
            firstname: "",
            lastname: "",
            username: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            role: "user",
            checkedValue: false,
            send_confirmation: false,
          });
          history.push("/users");
        })
        .catch((error) => {
          setIsSaving(false);
          setDisabled(false);
          let msg;
          if (error.response) {
            let data = error.response.data;
            if (data.message.includes("UserLambdaValidationException")) {
              data = data.message.slice(
                data.message.indexOf("{"),
                data.message.indexOf("}") + 1
              );
              msg = { message: JSON.parse(data).username };
            } else if (
              data.message.includes("UsernameExistsException") ||
              data.message.includes("InvalidParameterException")
            ) {
              data = data.message.slice(
                data.message.indexOf(":") + 1,
                data.message.length - 1
              );
              msg = { message: data };
            } else {
              msg = data;
            }
          } else if (error.request) {
            msg = error.request;
          } else {
            msg = error.message;
          }
          setIsServerError({
            ...isServerError,
            status: true,
            message: msg.message
              ? msg.message
              : "Something went wrong. Try Again!!",
          });
        });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (userData.password === "" || userData.password.trim() === "") &&
      (userData.passwordConfirmation === "" ||
      userData.passwordConfirmation.trim() === "")
    ) {
      setPwdError({ password: "", confirmPassword: "" });
    } else {
      let isError = false;
      let passwordErr = "";
      if (
        (userData.password === "" || userData.password.trim() === "") &&
        (userData.passwordConfirmation !== "" ||
        userData.passwordConfirmation.trim() !== "")
      ) {
        setPwdError({ ...pwdError, password: "Please enter the new password" });
        return true;
      }
      if (
        (userData.password !== "" || userData.password.trim() !== "") &&
        (userData.passwordConfirmation === "" ||
        userData.passwordConfirmation.trim() === "")
      ) {
        setPwdError({
          ...pwdError,
          confirmPassword: "Please enter the password confirmation",
        });
        return true;
      }

      passwordErr = await checkPasswordLength("password", userData.password);
      if (passwordErr.errorStatus) {
        isError = true;
        setPwdError({ ...pwdError, password: passwordErr.errorMessage });
      }
      passwordErr = await checkPasswordCombination(
        "password",
        userData.password
      );
      if (passwordErr.errorStatus) {
        isError = true;
        setPwdError({ ...pwdError, password: passwordErr.errorMessage });
      }

      if (userData.password === userData.passwordConfirmation) {
        setPwdError({ ...pwdError, password: passwordErr.errorMessage });
      } else {
        isError = true;
        setPwdError({
          ...pwdError,
          password: passwordErr.errorMessage,
          confirmPassword: "Passwords do not match, Please try again.",
        });
      }
      if (isError) return;
    }
    onSubmit();
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   onSubmit();
  // };

  const backto = (e) => {
    e.preventDefault();
    if (isSaving) {
    } else {
      props.history.goBack();
    }
  };

  const setIsOpen = () => {
    setIsServerError({ ...isServerError, status: !isServerError.status });
  };

  return (
    <div className="mb-3">
      <CustomModal
        title={"Error"}
        titleText="Error"
        isOpen={isServerError.status}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
        inputLabel={isServerError.message}
      ></CustomModal>
      <FormCard title={id ? "Edit User" : "Add User"}>
        <form
          className="simple_form form-horizontal"
          name="createUserForm"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <TextField
                id="outlined-basic"
                label="First Name"
                required
                variant="outlined"
                value={userData.firstname}
                onInput={handleChange}
                name="firstname"
                autoComplete="off"
                inputProps={{ maxLength: 35 }}
                disabled={isSaving}
                autoFocus
              />
              {errors.firstname ? (
                <div className="errText">{errors.firstname}</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <TextField
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                value={userData.lastname}
                onInput={handleChange}
                name="lastname"
                autoComplete="off"
                inputProps={{ maxLength: 35 }}
                disabled={isSaving}
              />
            </div>
          </div>
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <TextField
                id="outlined-basic"
                label="Username"
                required
                variant="outlined"
                value={userData.username}
                onInput={handleChange}
                name="username"
                autoComplete="off"
                disabled={id ? true : isSaving ? true : false}
                inputProps={{ maxLength: 35 }}
              />
              {errors.username ? (
                <div className="errText">{errors.username}</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <TextField
                id="outlined-basic"
                label="Email"
                required
                variant="outlined"
                value={userData.email}
                onInput={handleChange}
                name="email"
                autoComplete="off"
                disabled={id ? true : isSaving ? true : false}
                inputProps={{ maxLength: 100 }}
              />
              {errors.email ? (
                <div className="errText">{errors.email}</div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <TextField
                id="outlined-basic"
                label="New Password"
                type="password"
                variant="outlined"
                autoFocus
                autoComplete="off"
                value={userData.password}
                onInput={handleChange}
                name="password"
                inputProps={{ maxLength: 35 }}
                disabled={isSaving}
              />
             {pwdError.password ? (
                        <div
                          className="errorMsg"
                          style={{ position: "absolute" }}
                        >
                          {pwdError.password}
                        </div>
                      ) : (
                        ""
                      )}
            </div>
          </div>
          {(userData.password !=="" || userData.passwordConfirmation !=="") &&
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <TextField
                id="outlined-basic"
                label="Confirm Password"
                type="password"
                variant="outlined"
                value={userData.passwordConfirmation}
                onInput={handleChange}
                name="passwordConfirmation"
                autoComplete="off"
              
                inputProps={{ maxLength: 35 }}
                disabled={isSaving}
              
             
              />
             {pwdError.confirmPassword ? (
                        <div
                          className="errorMsg"
                          style={{ position: "absolute" }}
                        >
                          {pwdError.confirmPassword}
                        </div>
                      ) : (
                        " "
                      )}
            </div>
          </div>
          }
          <div className="row justify-content-center customize-row-height">
            <div className="col-md-8 col-lg-5">
              <div className="row">
                <div className="col-auto">
                  <label htmlFor="Role" className="col-form-label">
                    Role:
                  </label>
                </div>
                <div className="col-auto align-self-center">
                  <div className="form-check">
                    <FormControlLabel
                      disabled={isLoggedIn}
                      control={
                        <GreenCheckbox
                          checked={userData.checkedValue}
                          onChange={handleCheck}
                          id="Admin"
                          name="Admin"
                        />
                      }
                      label="Admin"
                      style={{ cursor: isLoggedIn ? "not-allowed" : "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center ">
            <div className="col-auto">
              {isSaving ? (
                <button type="button" className="gss-btn btn-orange mt-1">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  &nbsp;
                  {id ? "Updating..." : "Saving..."}
                </button>
              ) : (
                <button
                  type="submit"
                  className="gss-btn btn-orange mt-1"
                  disabled={disabled}
                >
                  {id ? "Update" : "Save"}
                </button>
              )}
            </div>
            <div className="col-auto align-self-center">
              <a href="" onClick={backto}>
                Cancel
              </a>
            </div>
          </div>
        </form>
      </FormCard>
    </div>
  );
}

export default AdminCreateUser;
