import React, { useState } from 'react'
import { Col, Container } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import BigButton from '../UI/BigButton/BigButton'
import './SearchContainer.css'

const SearchContainer = () => {
   const [value,setValue] = useState()
   const history = useHistory()

    const handleChange = async (e) => {
        setValue(e.target.value);
    }

   const navigateToSearch = () =>{
       if(value!=null){
            history.push(`/variables/vfilter?keyword=`+value)
       }else{
           alert("Please enter search terms")
       }
    }

        var input = document.getElementById("search");
        var btn = document.getElementById("myBtn")
    
    input?.addEventListener("keyup", function(event) {  
     if (event.keyCode === 13) {   
        event.preventDefault();     
        btn?.click();
    }
    });
    return (
        <Container className="d-flex flex-column text-center bg-lighrt-dark" >
            <Col as="h1" className="text-white mt-4">
                Available GSS Data
            </Col>
            <Col className="search-text p-2">
                The GSS dataset contains more than 5000 variables with data on a wide range of subjects:
            </Col>
            <>
                <Col className="form-group p-2">
                    <Col className="input-group justify-content-center align-items-center h-100">
                        <input id="search" type="text" className="form-control col-6" placeholder="Search by Keyword (education, health)" value ={value} onChange={handleChange} />
                        <BigButton  id="myBtn" classname="btn-orange width-unset float-left" onClick={navigateToSearch}>  <i className="fa fa-search"></i></BigButton>
                    </Col>
                </Col>
            </>
            <Col className="search-text mb-4">
                Learn more about the GSS, including its history, principal investigators,
                and other related surveys, at
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="green-link"
                  href="https://gss.norc.org/"
                > NORC’s main GSS website. </a>
            </Col>
        </Container>
    )
}

export default SearchContainer
