export const groupByCategory = (variableItems) => {
  let types = [
    { id: 24, name: "GenZ" },
    { id: 23, name: "Home Life" },
    { id: 26, name: "Tag3 test" },
  ];
  let resObj = {};
  types.map((type) => {
    return variableItems.map((variableItem) => {
      if (!resObj[`${type.id}-${type.name}`]) {
        return resObj[`${type.id}-${type.name}`] = [];
      }
      if (search(type.name, variableItem.tags_info) !== undefined) {
        return resObj[`${type.id}-${type.name}`].push(variableItem);
      }
    });
  });

  return resObj;
};

export const search = (nameKey, myArray) => {
  for (var i = 0; i < myArray.length; i++) {
    if (myArray[i].name === nameKey) {
      return myArray[i];
    }
  }
};

export const addItemsToTag = (variableItems, variableItemsToAdd) => {
  let data = merge(variableItems, variableItemsToAdd, "variable");
  return data;
};

export const removeItemsFromTag = (variableItems, variableItemsToAdd) => {
  let data = removeDuplicate(variableItems, variableItemsToAdd, "variable");
  return data;
};

export const setSelectedVariableArr = (s_variables, item) => {
  if (s_variables.indexOf(item) !== -1) {
    s_variables.push(item);
  }
  return s_variables;
};

export const deepCopy = (src) => {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === "object") {
        target[key] = deepCopy(v);
      } else {
        target[key] = v;
      }
    } else {
      target[key] = v;
    }
  }

  return target;
};

export const removeDuplicate = (a, b, prop) => {
  var reduced = a.filter(
    (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
  );
  return reduced;
};

const merge = (a, b, prop) => {
  var reduced = a.filter(
    (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
  );
  return reduced.concat(b);
};

// const onClickVariable = (vID) => {
//     props.setVariableId(vID);
//     props.setVariableToggel(false);
// }

export const GetTagCategories = (variableList, isSearch) => {
  let tagCategories = {
    "none": {
          tagName: 'Untagged',
          variables: []
    } 
  };
  variableList.map((variable) => {
    if (variable.tags_info.length) {
      variable.tags_info.map((tag) => {
        if (!tagCategories.hasOwnProperty(tag.id)) {
          tagCategories[tag.id] = {
            tagName: tag.name,
            variables: [],
            isThisTagShared: tag.isThisTagShared,
            isSharedTag: tag.shared_tag,
          };

          if (isSearch) {
            tagCategories[tag.id].createdBy =
              tag.shared_tag === 0
                ? "Tag created by you"
                : "Tag shared with you";
          } else {
            tagCategories[tag.id].createdBy = tag.full_name;
          }
        }
        tagCategories[tag.id].variables.push(variable);
      });
    }

    if (isSearch) {
      // if(variable.module.length){
      //     tagCategories = getTagsForModuleSubjects(variable.module, tagCategories, variable);
      // }
      if (variable.subject.length) {
        tagCategories = getTagsForModuleSubjects(
          variable.subject,
          tagCategories,
          variable
        );
      }
    }

    if(!variable.tags_info.length && !variable.subject?.length) {
          if (variable.orignlName !== 'id_') {
            tagCategories["none"].variables.push(variable);
        }
    } 
  });
  
  if(tagCategories["none"]["variables"].length > 0) {
    let holdData = {...tagCategories["none"]};
    delete tagCategories["none"];
    holdData["tagName"] = "Untagged Variable" + (holdData["variables"]?.length > 1 ? 's':'');
    tagCategories["none"] = holdData;
  }else {
    delete tagCategories["none"];
  } 
  
  return tagCategories;
};

const getTagsForModuleSubjects = (arr, tags, variable) => {
  let tagCategories = { ...tags };
  arr.map((tag) => {
    if (!tagCategories.hasOwnProperty(tag)) {
      tagCategories[tag] = {
        tagName: tag,
        variables: [],
        createdBy: "Tag created by GSS Team",
      };
    }
    tagCategories[tag].variables.push(variable);
  });
  return tagCategories;
};

export const GetModules = (variableList) => {
  let moduleList = {
    "none": {
          tagName: 'Untagged',
          variables: []
    } 
  };
  variableList.map((variable) => {
    if (variable.module.length) {
      moduleList = getTagsForModuleSubjects(
        variable.module,
        moduleList,
        variable
      );
    }else {
      if (variable.orignlName !== 'id_') {
        moduleList["none"].variables.push(variable);
      } 
    }
  });

  if(moduleList["none"]["variables"].length > 0) {
    let holdData = {...moduleList["none"]};
    delete moduleList["none"];
    holdData["tagName"] = "Untagged Variable" + (holdData["variables"]?.length > 1 ? 's':'');
    moduleList["none"] = holdData;
  }else {
    delete moduleList["none"];
  } 

  return moduleList;
};

export const defaultVariables = ["_id", "year", "ballot"];

export const getAnalysisExtractStatus = (resp, type) => {
  let data = resp.Data.map((row) => {
    if (type === "analysis") {
      row.id = row.analysisId;
    } else {
      row.id = row.id;
    }

    if (row.status?.toLowerCase() === "submitted") {
      row.analysisExtractStatus = "Pending";
    } else if (row.status?.toLowerCase() === "finished") {
      row.analysisExtractStatus = "Completed";
    } else if (row.status?.toLowerCase() === "started") {
      row.analysisExtractStatus = "In-Progress";
    } else if (row.status?.toLowerCase() === "failed") {
      row.analysisExtractStatus = "Failed";
    } else {
      row.analysisExtractStatus = "";
    }
    return row;
  });

  return data;
};

export const mergeAnalysisData = (state, analysis_data) => {
  let newState = {};
  newState = { ...state.analysis_data };
  newState[analysis_data.analysisId] = { ...analysis_data }
  return newState
}

export const removeAnalysisData = (state, analysisId) => {
  let newState = {};
  newState = { ...state.analysis_data };
  if (newState[analysisId]) {
    delete newState[analysisId]
  }
  return newState
}

  // export const VarInfo = ((data) =><div>111</div>);

