import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import CustomModal from "../../../Components/UI/Modal/CustomModal";

export default function Logout(props) {
  useEffect(() => {
    logout();
  }, []);
  /**
   * to clear session from browser
   */
  const clearSession = () => {
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      window.location = "/sign_in";
    }, 5000);
  };

  const logout = async () => {
    try {
      await Auth.signOut({ global: true })
        .then((data) => {
          console.log("XXXXXXXXXXX: Logout Successful");
          clearSession();
        })
        .catch((err) => {
          console.log("XXXXXXXXXXX: Logout Failed");
          clearSession();
        })
        .finally(() => {});
    } catch (error) {
      console.log("catch XXXXXXXXXXX: Logout Failed");
      clearSession();
    }
  };

  const setIsOpen = () => {};

  return (
    <div className="form-parent">
      <CustomModal
        title={"Error"}
        titleText="Session Expired"
        isOpen={true}
        setIsOpen={setIsOpen} //callback function when dialog is opened. Calling Child function from Parent
        inputLabel="Kindly Login Again"
        btnText="Ok"
      ></CustomModal>
    </div>
  );
}
