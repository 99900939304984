import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import "./CreateExtract.css";
import CartList from "../../CartList/CartList";
import Dnd from "../../../Components/UI/Dnd/Dnd";
import Axios from "axios";
import { environment } from "../../../environment";
import DownloadGSSDataset from "../../Homepage/DownloadGSSDataset/DownloadGSSDataset";
import CaseSelection from "../../AnalysisManagement/CaseSelection/CaseSelection";
import VarSelectionDnd from "../../AnalysisManagement/VarSelectionDnd/VarSelectionDnd";
import BallotYearsSelect from "../../AnalysisManagement/CreateAnalysis/BallotYearsSelect";
import { ballotYears, GreenCheckbox } from "../../../Store/utils/common.utils";
import {
  Avatar,
  FormControl,
  NativeSelect,
  FormControlLabel,
} from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { DndProvider } from "react-dnd";
import MultiBackend, {
  TouchTransition,
  MouseTransition,
} from "react-dnd-multi-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomDragLayer from "../../../Components/UI/CustomDragLayer/CustomDragLayer";
import SearchPage from "../../SearchManagement/SearchPage/SearchPage";
import VariableDetails from "../../VariableManagement/VariableDetails/VariableDetails";
import ClickedVariableContext from "../../../Store/ContextApi/ClickedVariableContext";
import RefreshPopoverContext from "../../../Store/ContextApi/RefreshPopoverContext";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
}));
export default function CreateExtract(props) {
  const classes = useStyles();
  const [values, setValues] = useState({
    extractName: "",
    label: "",
    isAllYearsToggle: true,
    years: [],
    isSubmitDisabled: false,
  });
  const {
    min_max_year: { min_max_year = [] },
  } = useSelector((state) => state.minmaxYearsReducer);
  const years =
    min_max_year && min_max_year.length > 0 ? min_max_year : [1972, 2021];
  const HTML5toTouch = {
    backends: [
      {
        id: "touch",
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };
  const [accordian, setAccordian] = useState({
    accordian1: true,
    accordian2: false,
    accordian3: false,
    enableAccordian2: true,
    enableAccordian3: true,
  });

  const [variablesList, setVariablesList] = useState([]);
  const [chooseVarList, setChooseVarList] = useState([
    {
      vID: 1,
      name: "year",
      orignlName: "year",
      label: "GSS year for this respondent",
      varType: "continuous",
      cartId: "",
      cartCreatedAt: "",
      cartUpdatedAt: "",
      cartType: "analysis",
      tags_info: [],
      isAlreadyInExtract: 1,
    },
    {
      vID: 2,
      name: "id_",
      orignlName: "id",
      label: "Respondent id number",
      varType: "continuous",
      cartId: "",
      cartCreatedAt: "",
      cartUpdatedAt: "",
      cartType: "analysis",
      tags_info: [],
      isAlreadyInExtract: 1,
    },
    {
      vID: 5574,
      name: "ballot",
      orignlName: "ballot",
      label: "Ballot used for interview",
      varType: "discrete",
      cartId: "",
      cartCreatedAt: "",
      cartUpdatedAt: "",
      cartType: "analysis",
      tags_info: [],
      isAlreadyInExtract: 1,
    },
  ]);
  const [errors, setErrors] = useState({ chooseVarErr: "" });
  const [selectedYears, setSelectedYears] = useState("");
  const [toggelView, setToggelView] = useState(true);
  const [variableToggel, setVariableToggel] = useState(true);
  const [variableId, setVariableId] = useState("");
  const contextValue = {
    variableToggel,
    setVariableToggel,
    variableId,
    setVariableId,
  };
  const [num, setNum] = useState("");
  const SessionDataStorage = (e) => {
    e.preventDefault();
    sessionStorage.setItem("num", num);
    console.log(num);
    var btn1 = document.getElementById("dropdown-basic-custom");
    document.addEventListener("keypress", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        btn1?.click();
      }
    });
  };
  const [isCloseLoader, setCloseLoader] = useState(false);
  const { extractReload, setExtractReload } = useContext(RefreshPopoverContext);
  const case1 = chooseVarList.map((obj) => obj.vID).length;
  const [fileFormat, setFileFormat] = useState([
    {
      id: 1,
      name: "Excel Workbook (data + metadata)",
      isChecked: false,
      isDisabled: false,
    },
    {
      id: 2,
      name: "Excel Workbook (metadata only)",
      isChecked: false,
      isDisabled: false,
    },
    { id: 3, name: "SPSS Control File", isChecked: false, isDisabled: false },
    { id: 4, name: "SAS Control File", isChecked: false, isDisabled: false },
    {
      id: 5,
      name: "Stata (Control + System)",
      isChecked: false,
      isDisabled: false,
    },
    {
      id: 6,
      name: "DDI Version 3.1 Metadata",
      isChecked: false,
      isDisabled: false,
    },
    { id: 7, name: "R Script", isChecked: false, isDisabled: false },
    { id: 11, name: "JSON", isChecked: false, isDisabled: false },
  ]);
  const [metaDataField, setMetaDataField] = useState(0);
  const fetchYearsJson = async () => {
    let yearsArr = await ballotYears.getBallotYears();
    setValues({ ...values, ["years"]: yearsArr });
  };

  useEffect(() => {
    if (values.years.length === 0) {
      fetchYearsJson();
    }
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeNumber = (e) => {
    if (e.target?.value > 6000 || e.target?.value?.length > 4) {
      alert("Number should not exceed 6000");
      return false;
    }
    if (e.target?.value < 1 && e.target?.value != "") {
      alert("Number should be atleast 1");
      return false;
    }
    setNum(e.target?.value);
  };
  const addAnyVariables = (cartArr) => {
    // let finalCartArr = [...cartArr];
    // setChooseVarList(finalCartArr);
    let finalCartArr = [...cartArr];
    // limit = 500;

    if (finalCartArr && finalCartArr.length > num) {
      setChooseVarList(finalCartArr.splice(0, num));
      alert("The first " + num + " variables are added.");
    } else {
      let finalCartArr = [...cartArr];
      setChooseVarList(finalCartArr);
      alert("All variables are added.");
    }
  };
  const addAllVariables = (cartArr) => {
    // let finalCartArr = [...cartArr];
    // setChooseVarList(finalCartArr);
    let finalCartArr = [...cartArr];
    let limit = 6000;

    if (finalCartArr && finalCartArr.length > limit) {
      setChooseVarList(finalCartArr.splice(0, limit));

      alert("The first " + limit + " variables are added.");
    } else {
      let finalCartArr = [...cartArr];
      setChooseVarList(finalCartArr);
      alert("All variables are added.");
    }
  };

  const removeVariables = (cartArr) => {
    // let finalCartArr = [...cartArr];
    // setChooseVarList(finalCartArr);
    let finalCartArr = [...cartArr],
      limit = 0;
    setChooseVarList(finalCartArr.splice(0, limit));
    alert("All variables are removed.");
  };

  const handleAccordian = (accordianName, expanded) => {
    setAccordian({ ...accordian, [accordianName]: !expanded });
  };

  const handleNext = (id) => {
    setAccordian({
      ...accordian,
      ["accordian" + (id + 1)]: true,
      ["accordian" + id]: false,
      ["enableAccordian" + (id + 1)]: false,
    });
  };

  const clearErrors = () => {
    setErrors({ ...errors, ["chooseVarErr"]: "" });
  };

  const yearUpdate = (yearsList) => {
    setSelectedYears(yearsList);
  };

  const setColRowVariable = (data, varType, errorMsg) => {
    if (errors.chooseVarErr) {
      clearErrors();
    }

    if (errorMsg) {
      setErrors({
        ...errors,
        ["chooseVarErr"]: errorMsg,
      });
    } else {
      setChooseVarList(data);
    }
  };

  /******* START: Case Selection Request *******/
  const caseSelRef = React.useRef();
  const getCaseListReq = () => {
    return caseSelRef.current.getCaseListReq();
  };
  /******* END: Case Selection Request *******/
  let Arr1 = [1, 2, 5574];
  const submitExtract = () => {
    //clearErrors();
    let fileFormatIds = fileFormat.reduce((ids, file) => {
      if (file.isChecked && !file.isDisabled) {
        ids.push(file.id);
      }
      return ids;
    }, []);
    let specificYears = [...selectedYears];
    let caseVars = getCaseListReq();
    let req = {
      wrkId: 123,
      extractName: values.extractName,
      extractLabel: values.label,
      variables: chooseVarList.map((obj) => obj.vID),
      specificYears:
        !values.isAllYearsToggle && specificYears && specificYears.length
          ? specificYears
          : undefined,
      caseVars: caseVars,
      fileType: fileFormatIds,
      metadata:
        fileFormatIds.includes(1) || fileFormatIds.includes(2)
          ? parseInt(metaDataField)
          : undefined,
    };
    setValues({ ...values, ["isSubmitDisabled"]: true });
    Axios({
      method: "POST",
      url: environment.createExtract,
      data: req,
    })
      .then((response) => {
        props.history.push("/MyGSS?page=extract");
        setExtractReload(!extractReload);
      })
      .catch((err) => {
        setValues({ ...values, ["isSubmitDisabled"]: false });
        if (err.response) {
          alert(err.response.data.message);
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };

  const handleFileFormatChange = (e, ind) => {
    const fileData = [...fileFormat];

    fileData[ind]["isChecked"] = e.target.checked;
    if (ind === 0) {
      fileData[ind + 1]["isChecked"] = fileData[ind]["isChecked"] ? true : true;
      fileData[ind + 1]["isDisabled"] = fileData[ind]["isChecked"]
        ? true
        : false;
    } else if (ind === 6) {
      fileData[ind - 2]["isChecked"] = fileData[ind]["isChecked"] ? true : true;
      fileData[ind - 2]["isDisabled"] = fileData[ind]["isChecked"]
        ? true
        : false;
    }

    /* if ((!fileFormat[0]['isChecked']) || (!fileFormat[1]['isChecked'])) {
      setMetaDataField('');
    } */
    setFileFormat(fileData);
  };

  const handleMetaData = (e) => {
    setMetaDataField(e.target.value);
  };

  const closeLoader = (val) => {
    setCloseLoader(val);
  };

  const toggleTags = (id) => {
    let filterData = chooseVarList.map((d) => {
      if (d.vID === id) {
        d.expanded = !d.expanded;
      }
      return d;
    });
    setChooseVarList(filterData);
  };

  useEffect(() => {
    if (case1 >= 2200) {
      fileFormat[0] = {
        id: 1,
        name: "Excel Workbook (data + metadata)",
        isChecked: false,
        isDisabled: true,
      };
      fileFormat[1] = {
        id: 2,
        name: "Excel Workbook (metadata only)",
        isChecked: false,
        isDisabled: true,
      };
    }
    if (case1 < 2200) {
      fileFormat[0] = {
        id: 1,
        name: "Excel Workbook (data + metadata)",
        isChecked: false,
        isDisabled: false,
      };
      fileFormat[1] = {
        id: 2,
        name: "Excel Workbook (metadata only)",
        isChecked: false,
        isDisabled: false,
      };
    }
    if (fileFormat[0].isDisabled === false) {
      setFileFormat([...fileFormat]);
    }
  }, [case1]);

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <CustomDragLayer />
      <ClickedVariableContext.Provider value={contextValue}>
        <div
          className="create-extract col-12 mt-10"
          style={{
            display:
              toggelView == false || variableToggel == false ? "none" : "block",
          }}
        >
          <div className="row">
            <div className="col-lg-10 col-sm-12 col-md-12 gss-large-title">
              Create Extract&nbsp;
              <Tooltip
                title={
                  <div>
                    <strong>Build Extract</strong>
                    <ul>
                      <li>
                        <strong> Name</strong>: Name your Extract.
                      </li>
                      <li>
                        <strong>Description</strong>: Describe your extract to
                        help you remember what is included, or what information
                        you are trying to capture.
                      </li>
                      <li>
                        <strong>Variables</strong>: Drag variables from your
                        cart on the right to the appropriate variable field. You
                        will get all data available for those variables, for all
                        years where data are available.{" "}
                      </li>
                      <li>
                        <strong>Continuous, Discrete</strong>: Most GSS
                        variables are discrete and have a few, specific,
                        possible values. Continuous variables have many more
                        values, and behave differenlyt in statistical analysis.{" "}
                      </li>
                      <li>
                        <strong>Case selection (optional)</strong>: If you would
                        like to limit your analysis to a subset of the data,
                        drag one or more variables to the "Case Selection" field
                        and use the checkboxes or ranges to choose which values
                        will be included. You can select on as many variables as
                        you want.
                      </li>
                      <li>
                        <strong>Select Specific Years</strong>: Use the
                        checkboxes to select which survey years to include in
                        your analysis
                      </li>
                      <li>
                        <strong>File Format</strong>: Select the desired
                        format(s) for your output. If either Excel metadata
                        option is selected, you must also select the desired
                        format for the metadata extract. If you would like an R
                        extract, you must also select Stata (Control + System).
                      </li>
                    </ul>
                  </div>
                }
              >
                <InfoIcon
                  fontSize="small"
                  style={{ height: "13px" }}
                  className="plus-icon"
                />
              </Tooltip>
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12">
              <DownloadGSSDataset />
            </div>
          </div>

          <div className="gss-subtitle">
            GSS Data Explorer allows you to extract just the data you need for
            use in your own statistical software package. Please note that
            larger extracts may take longer to process, particularly for
            extracts in excess of 500 variables. In addition, please note that
            you may need to hit the "refresh" button to see the status of your
            extract once it has finished processing.
          </div>
          {/* <div className="gss-subtitle">
            <abbr>Note: </abbr>
            <strong>
              Please note that we have discovered an issue with the .dat files
              for extracts, which affects all formats for extracts except for
              Excel. We are actively investigating this issue and will have a
              solution in place soon.
            </strong>
          </div> */}
          <Accordion
            defaultExpanded={true}
            expanded={accordian.accordian1}
            onChange={() => handleAccordian("accordian1", accordian.accordian1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                <div>
                  {values.extractName === "" ? (
                    <Avatar>1</Avatar>
                  ) : (
                    <Avatar className="checked">
                      <CheckOutlinedIcon />
                    </Avatar>
                  )}
                  <span className="accordian-heading">Name Your Extract</span>
                </div>
                {values.extractName && (
                  <div className="gss-accordian-rightText">
                    <span className="mr-1">{values.extractName}</span>
                  </div>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div className="mb-3">
                  The name of your extract will be also be the name of the file
                  you download.
                </div>
                <div>
                  <div className={classes.root} noValidate autoComplete="off">
                    <TextField
                      id="outlined-basic"
                      label="Extract Name"
                      variant="outlined"
                      className="w-40 d-block"
                      value={values.extractName}
                      autoFocus
                      required
                      onChange={handleChange("extractName")}
                      inputProps={{ maxLength: 35 }}
                    />
                    <textarea
                      rows="4"
                      placeholder="Description"
                      className="w-100 mt-2"
                    />
                  </div>
                </div>
                <button
                  id={1}
                  disabled={values.extractName === ""}
                  className="gss-btn btn-orange mt-1"
                  onClick={() => handleNext(1)}
                >
                  Next
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={accordian.enableAccordian2}
            expanded={accordian.accordian2}
            onChange={() => handleAccordian("accordian2", accordian.accordian2)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading + " d-flex w-100"}>
                <div>
                  {values.extractName === "" ||
                  values.extractName.trim() === "" ||
                  chooseVarList.length === 0 ? (
                    <Avatar>2</Avatar>
                  ) : (
                    <Avatar className="checked">
                      <CheckOutlinedIcon />
                    </Avatar>
                  )}
                  <span className="accordian-heading">Build Extract</span>
                </div>
                {chooseVarList.length > 0 && chooseVarList.length <= 50 && (
                  <div className="gss-accordian-var">
                    <span className="mr-1 text-break">
                      {chooseVarList.map((list) => list.name).join(", ")}
                    </span>
                    {/* <a className="font-weight-bold">EDIT</a> */}
                  </div>
                )}
                {chooseVarList.length > 50 && (
                  <div
                    className="gss-accordian-rightText1"
                    title={chooseVarList.map((list) => list.name).join(", ")}
                  >
                    <span className="mr-1">
                      {chooseVarList.length + " Variables"}
                    </span>
                    {/* <a className="font-weight-bold">EDIT</a> */}
                  </div>
                )}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row w-100 m-0">
                <div className="col-12 ml-3 w-100 mb-2 p-0">
                  Drag and drop variables from your cart.
                </div>
                {errors.chooseVarErr != "" && (
                  <div className="col-12 error-msg">{errors.chooseVarErr}</div>
                )}
                <div className="col-lg-8 col-md-6 col-sm-6 w-50">
                  <div className="row position-relative">
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                      <label>Choose Variables</label>
                    </div>
                    <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 text-right">
                      <a
                        style={{ color: "#73a492", fontWeight: "bold" }}
                        onClick={() => removeVariables(variablesList)}
                      >
                        {" "}
                        <i
                          className="fa fa-times"
                          style={{ fontSize: "14px", color: "red" }}
                        ></i>{" "}
                        Remove All{" "}
                      </a>
                    </div>
                  </div>
                  <VarSelectionDnd
                    variableList={chooseVarList}
                    variableType="chooseVarList"
                    maxDropCount={6000}
                    // maxDropCount={500}
                    onItemAction={setColRowVariable}
                    module="extract"
                    toggleTags={toggleTags}
                  ></VarSelectionDnd>
                  <CaseSelection ref={caseSelRef}></CaseSelection>
                </div>
                <div className="col-lg-4  col-md-6 col-sm-6 w-50">
                  <div className="App">
                    <form onSubmit={SessionDataStorage}>
                      <p>
                        <span className="paraCart">
                          Add any number of variables :{" "}
                        </span>
                        <input
                          id="input_num"
                          className="limitValue"
                          type="number"
                          placeholder=" Limit 6000"
                          value={num}
                          onChange={handleChangeNumber}
                        />
                      </p>
                    </form>
                  </div>
                  <CartList
                    isAnalysisOrExtract={true}
                    setToggelView={setToggelView}
                    toggelView={toggelView}
                    cartListType="chooseVariables"
                    addAll={addAllVariables}
                    addAny={addAnyVariables}
                    numLimit={num}
                    showAddAll="true"
                    moduleName="Extract"
                    closeLoader={closeLoader}
                    isCloseLoader={isCloseLoader}
                  ></CartList>
                </div>
                <button
                  id={2}
                  disabled={chooseVarList.length === 0}
                  className="gss-btn btn-orange ml-3 mt-2"
                  onClick={() => handleNext(2)}
                >
                  Next
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={accordian.enableAccordian3}
            expanded={accordian.accordian3}
            onChange={() => handleAccordian("accordian3", accordian.accordian3)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={classes.heading}>
                <div>
                  {values.extractName === "" ||
                  values.extractName.trim() === "" ||
                  (chooseVarList.length === 0
                    ? true
                    : values.isAllYearsToggle
                    ? !fileFormat.filter(function (e) {
                        return e.isChecked === true;
                      }).length > 0
                    : !(selectedYears.size && selectedYears.size > 0) ||
                      !fileFormat.filter(function (e) {
                        return e.isChecked === true;
                      }).length > 0) ? (
                    <Avatar>3</Avatar>
                  ) : (
                    <Avatar className="checked">
                      <CheckOutlinedIcon />
                    </Avatar>
                  )}
                  <span className="accordian-heading">
                    Choose output options
                  </span>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <label>Years Included</label>
                <a
                  className="toggle-years ml-2"
                  onClick={() => {
                    if (!values.isAllYearsToggle) {
                      yearUpdate([]);
                    }
                    setValues({
                      ...values,
                      ["isAllYearsToggle"]: !values.isAllYearsToggle,
                    });
                  }}
                >
                  {values.isAllYearsToggle ? (
                    <span>Select certain years</span>
                  ) : (
                    <span>Select all years</span>
                  )}
                </a>
                <div className="all-years">
                  {values.isAllYearsToggle ? (
                    <div className="all-available">
                      All available years ({years[0]}-{years[1]})
                    </div>
                  ) : (
                    <div className="w-50">
                      <BallotYearsSelect
                        yearUpdate={yearUpdate}
                        data={values.years}
                      />
                    </div>
                  )}
                </div>
                <div className="font-weight-bold">
                  File Format{"    "}
                  <span
                    style={{
                      marginTop: "0.5rem",
                      fontWeight: "normal",
                      marginLeft: "0.2rem",
                    }}
                  >
                    <abbr title="required"> *</abbr>Select one or more of the
                    following options:
                  </span>
                </div>

                <div className="formats">
                  {fileFormat.map((file, index) => (
                    <div className="form-check pl-0" key={index}>
                      <FormControlLabel
                        disabled={file.isDisabled}
                        control={
                          <GreenCheckbox
                            checked={file.isChecked}
                            onChange={(e) => handleFileFormatChange(e, index)}
                          />
                        }
                        label={file.name}
                        style={{
                          cursor: "pointer",
                          opacity: file.isDisabled ? 0.5 : "",
                        }}
                      />
                    </div>
                  ))}
                </div>
                {fileFormat[0]["isChecked"] || fileFormat[1]["isChecked"] ? (
                  <div className="excel-options">
                    <div className="font-weight-bold">Excel output options</div>
                    <div className="form-group row ml-0">
                      <label htmlFor="excel_format" className="mr-1">
                        Metadata Fields
                      </label>
                      <div className="gss-select with-border">
                        <FormControl>
                          <NativeSelect
                            name="excel_format"
                            id="excel_format"
                            onChange={handleMetaData}
                            value={metaDataField}
                          >
                            <option value={0}>Label</option>
                            <option value={3}>Value</option>
                            {/* <option value={1}>Question Text</option>
                            <option value={2}>Label &amp; Question Text</option> */}
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="footer border-top p-2">
            <button
              className="gss-btn btn-orange mt-1"
              disabled={
                values.isSubmitDisabled ||
                values.extractName === "" ||
                values.extractName.trim() === "" ||
                (chooseVarList.length === 0
                  ? true
                  : values.isAllYearsToggle
                  ? !fileFormat.filter(function (e) {
                      return e.isChecked === true;
                    }).length > 0
                  : !(selectedYears.size && selectedYears.size > 0) ||
                    !fileFormat.filter(function (e) {
                      return e.isChecked === true;
                    }).length > 0)
              }
              onClick={submitExtract}
            >
              CREATE EXTRACT
            </button>
            <a
              className="cancel-text"
              onClick={() => {
                props.history.push("/MyGSS?page=extract");
              }}
            >
              CANCEL
            </a>
          </div>
        </div>
        {toggelView ? (
          ""
        ) : (
          <SearchPage
            isAnalysisOrExtract={true}
            setToggelView={setToggelView}
            moduleName="extract"
          />
        )}

        {variableToggel ? (
          ""
        ) : (
          <VariableDetails
            isAnalysisOrExtract={true}
            variableId={variableId}
            setVariableToggel={setVariableToggel}
            moduleName="extract"
          />
        )}
      </ClickedVariableContext.Provider>
    </DndProvider>
  );
}
